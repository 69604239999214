class AdTypes {}

AdTypes.regular_ad = {
  id: 0,
  title: "Regular",
  name: "Regular",
  text: "Regular",
  selected: false,
  supportingProviders: [1, 9],
  isDynamicCreative: false,
}

AdTypes.dynamic_ad = {
  id: 1,
  title: "Dynamic",
  name: "Dynamic",
  text: "Dynamic",
  selected: false,
  supportingProviders: [9],
  isDynamicCreative: true,
}

AdTypes.discovery_ad = {
  id: 3,
  title: "Discovery",
  name: "Discovery",
  text: "Discovery",
  selected: false,
  supportingProviders: [91],
  isDynamicCreative: true,
}

AdTypes.display_ad = {
  id: 4,
  title: "Display",
  name: "Display",
  text: "Display",
  selected: false,
  supportingProviders: [91],
  isDynamicCreative: true,
}
AdTypes.pmax = {
  id: 5,
  title: "Pmax",
  name: "Pmax",
  text: "Pmax",
  selected: false,
  supportingProviders: [91],
  isDynamicCreative: false,
}

AdTypes.idToType = {
  0: AdTypes.regular_ad,
  1: AdTypes.dynamic_ad,
  3: AdTypes.discovery_ad,
  4: AdTypes.display_ad,
  5: AdTypes.pmax,
}

export default AdTypes
