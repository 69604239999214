import React from "react"
import { connect } from "react-redux"
import { navigationDrawerStateChange } from "../navigationBarActions"
import { Close } from "@material-ui/icons"
import ActivitiesDrawer from "../navigationDrawers/activities"
import DrawerTypes from "../../common/consts/drawerTypes"
import { eventsTracker } from "../../api/eventsTracker"

class DrawerContainer extends React.Component {
  closeDrawer = () => {
    this.props.dispatch(navigationDrawerStateChange(false, this.props.drawerType))

    eventsTracker.trackDrawerClose(this.props.drawerType)
  }

  render() {
    let body = null

    switch (this.props.drawerType) {
      case DrawerTypes.ACTIVITIES:
        window.Intercom("trackEvent", "activities-drawer-opened")
        body = <ActivitiesDrawer />
        break
      default:
        body = null
        break
    }
    return (
      <div className={"d-flex flex-column"}>
        <span className="close" onClick={this.closeDrawer}>
          <Close className="close-drawer-icon" />
        </span>
        {body}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    drawerIsOpen: state.navigationBar.drawer.isOpen,
    drawerType: state.navigationBar.drawer.drawerType,
  }
}

export default connect(mapStateToProps)(DrawerContainer)
