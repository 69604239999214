class CampaignActionTypes {}
CampaignActionTypes.RESET_CAMPAIGNS_PAGE_DATES = "RESET_CAMPAIGNS_PAGE_DATES"
CampaignActionTypes.FETCH_CAMPAIGNS_RESPONSE_V2 = "FETCH_CAMPAIGNS_RESPONSE_V2"
CampaignActionTypes.PAUSE_CAMPAIGN_RESPONSE = "PAUSE_CAMPAIGN_RESPONSE"
CampaignActionTypes.START_CAMPAIGN_ERROR_RESPONSE = "START_CAMPAIGN_ERROR_RESPONSE"
CampaignActionTypes.ACTIVE_CAMPAIGN_RESPONSE = "ACTIVE_CAMPAIGN_RESPONSE"
CampaignActionTypes.STOP_CAMPAIGN_ERROR_RESPONSE = "STOP_CAMPAIGN_ERROR_RESPONSE"
CampaignActionTypes.CAMPAIGNS_LIST_LOADING = "CAMPAIGNS_LIST_LOADING_V2"
CampaignActionTypes.CAMPAIGN_STATUS_LOADING = "CAMPAIGN_STATUS_LOADING"
CampaignActionTypes.CAMPAIGN_BID_LOADING = "CAMPAIGN_BID_LOADING"
CampaignActionTypes.CHANGE_BID_RESPONSE = "CHANGE_BID_RESPONSE"
CampaignActionTypes.CHANGE_BID_ERROR_RESPONSE = "CHANGE_BID_ERROR_RESPONSE"
CampaignActionTypes.CHANGE_ROAS_RESPONSE = "CHANGE_ROAS_RESPONSE_V2"
CampaignActionTypes.CHANGE_ROAS_ERROR_RESPONSE = "CHANGE_ROAS_ERROR_RESPONSE_V2"
CampaignActionTypes.GET_RPM_RESPONSE = "GET_RPM_RESPONSE"
CampaignActionTypes.CAMPAIGNS_SORT_BY = "CAMPAIGNS_SORT_BY"
CampaignActionTypes.FILTER_CAMPAIGNS = "FILTER_CAMPAIGNS_V2"
CampaignActionTypes.UPDATE_CAMPAIGNS_PAGE = "UPDATE_CAMPAIGNS_PAGE"
CampaignActionTypes.RESET_CAMPAIGNS_PAGINATION = "RESET_CAMPAIGNS_PAGINATION"
CampaignActionTypes.FILTER_CAMPAIGNS_BY_STATUS = "FILTER_CAMPAIGNS_BY_STATUS"
CampaignActionTypes.CAMPAIGN_MARK_SELECTED = "CAMPAIGN_MARK_SELECTED"
CampaignActionTypes.CAMPAIGN_MARK_ALL_SELECTED = "CAMPAIGN_MARK_ALL_SELECTED"
CampaignActionTypes.CLEAR_CAMPAIGNS_FILTER = "CLEAR_CAMPAIGNS_FILTER_V2"
CampaignActionTypes.CAMPAIGN_TOTAL_BUDGET_LOADING = "CAMPAIGN_TOTAL_BUDGET_LOADING"
CampaignActionTypes.CHANGE_TOTAL_BUDGET_RESPONSE = "CHANGE_TOTAL_BUDGET_RESPONSE"
CampaignActionTypes.CHANGE_TOTAL_BUDGET_ERROR_RESPONSE = "CHANGE_TOTAL_BUDGET_ERROR_RESPONSE"
CampaignActionTypes.CAMPAIGN_DAILY_BUDGET_LOADING = "CAMPAIGN_DAILY_BUDGET_LOADING"
CampaignActionTypes.BULK_CAMPAIGNS_DAILY_BUDGET_LOADING = "BULK_CAMPAIGNS_DAILY_BUDGET_LOADING"
CampaignActionTypes.BULK_CAMPAIGNS_TOTAL_BUDGET_LOADING = "BULK_CAMPAIGNS_TOTAL_BUDGET_LOADING"
CampaignActionTypes.BULK_CAMPAIGNS_BID_LOADING = "BULK_CAMPAIGNS_BID_LOADING"
CampaignActionTypes.CHANGE_DAILY_BUDGET_RESPONSE = "CHANGE_DAILY_BUDGET_RESPONSE"
CampaignActionTypes.CHANGE_DAILY_BUDGET_ERROR_RESPONSE = "CHANGE_DAILY_BUDGET_ERROR_RESPONSE"
CampaignActionTypes.UPDATE_CAMPAIGNS_SUMMARY_RPM_RESPONSE = "UPDATE_CAMPAIGNS_SUMMARY_RPM_RESPONSE"
CampaignActionTypes.UPDATE_CAMPAIGNS_SUMMARY_REVENUE_RESPONSE = "UPDATE_CAMPAIGNS_SUMMARY_REVENUE_RESPONSE"
CampaignActionTypes.CAMPAIGNS_DATEPICKER_DATES_SELECTED = "CAMPAIGNS_DATEPICKER_DATES_SELECTED"
CampaignActionTypes.ROLLBACK_CAMPAIGNS_PAGE = "ROLLBACK_CAMPAIGNS_PAGE"
CampaignActionTypes.CAMPAIGN_RPM_LOADING = "CAMPAIGN_RPM_LOADING"
CampaignActionTypes.CAMPAIGN_REVENUE_LOADING = "CAMPAIGN_REVENUE_LOADING"
CampaignActionTypes.SHOULD_UPDATE_CAMPAIGNS_PAGE = "SHOULD_UPDATE_CAMPAIGNS_PAGE"
CampaignActionTypes.USER_GENERATED_REPORT_ON_CAMPAIGNS_PAGE = "USER_GENERATED_REPORT_ON_CAMPAIGNS_PAGE"
CampaignActionTypes.USER_GENERATED_REPORT_MARK_AS_VIEWED = "USER_GENERATED_REPORT_MARK_AS_VIEWED"
CampaignActionTypes.BULK_STOP_CAMPAIGNS_RESPONSE = "BULK_STOP_CAMPAIGNS_RESPONSE"
CampaignActionTypes.BULK_STOP_CAMPAIGNS_ERROR_RESPONSE = "BULK_STOP_CAMPAIGNS_ERROR_RESPONSE"
CampaignActionTypes.BULK_START_CAMPAIGNS_RESPONSE = "BULK_START_CAMPAIGNS_RESPONSE"
CampaignActionTypes.BULK_START_CAMPAIGNS_ERROR_RESPONSE = "BULK_START_CAMPAIGNS_ERROR_RESPONSE"
CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BUDGET_ERROR_RESPONSE = "BULK_EDIT_CAMPAIGNS_BUDGET_ERROR_RESPONSE"
CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BUDGET_RESPONSE = "BULK_EDIT_CAMPAIGNS_BUDGET_RESPONSE"
CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BID_RESPONSE = "BULK_EDIT_CAMPAIGNS_BID_RESPONSE"
CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BID_ERROR_RESPONSE = "BULK_EDIT_CAMPAIGNS_BID_ERROR_RESPONSE"
CampaignActionTypes.BULK_EDIT_CAMPAIGNS_TARGETING_RESPONSE = "BULK_EDIT_CAMPAIGNS_TARGETING_RESPONSE"
CampaignActionTypes.BULK_UPDATE_CAMPAIGN_TAGS = "BULK_UPDATE_CAMPAIGN_TAGS_V2"
CampaignActionTypes.BULK_SMART_ACTIVATION_CAMPAIGNS_RESPONSE = "BULK_SMART_ACTIVATION_CAMPAIGNS_RESPONSE"
CampaignActionTypes.BULK_ACTIVATE_CAMPAIGNS_LOADING = "BULK_ACTIVATE_CAMPAIGNS_LOADING"
CampaignActionTypes.BULK_ACTIVATE_CAMPAIGNS_ERROR_RESPONSE = "BULK_ACTIVATE_CAMPAIGNS_ERROR_RESPONSE"
CampaignActionTypes.BULK_CAMPAIGN_STATUS_LOADING = "BULK_CAMPAIGN_STATUS_LOADING"
CampaignActionTypes.UPDATE_ITEMS_PER_PAGE = "UPDATE_ITEMS_PER_PAGE"
CampaignActionTypes.HANDLE_HIDE_CAMPAIGNS_V2_PAGE = "HANDLE_HIDE_CAMPAIGNS_V2_PAGE"
CampaignActionTypes.SET_CAMPAIGNS_REPORT_TYPE = "SET_CAMPAIGNS_REPORT_TYPE"
export default CampaignActionTypes
