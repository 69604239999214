import React from "react"
import Moment from "moment"
import { connect } from "react-redux"
import { Button, CircularProgress } from "@material-ui/core"
import NumberFormat from "../../../common/components/numberFormat"
import { getMaximumBidByConversionName, hasRoasEnabled } from "../../../utils/campaignUtilsV2"
import { campaignBidLoading, changeBidAsync } from "../../campaignActions"
import { eventsTracker } from "../../../api/eventsTracker"
import Consts from "../../../app/consts"
import { getProviderBidStepSize } from "../../../utils/providerUtils"
import { webStorage } from "../../../api/webStorage"
import WebStorageConsts from "../../../common/consts/webStorageConsts"
import CampaignsConsts from "../../campaignsConsts"

class CampaignManualBid extends React.Component {
  OUTDATED_DATA_WARNING = "You are about to change CPC based on outdated data"

  constructor(props) {
    super(props)

    this.state = {
      maximumBid: 0,
      localBid: 0,
      localBidInUSD: 0,
      isRealtimeData: false,
      disableSaveButton: false,
      bidStepperDelta: Consts.STEPPER_STEP_REALTIME_BID_SUB_CENT,
      isLoading: false,
      isBidNeedsValidation: false,
      isBigBidClickedOnce: false,
      currency: Consts.DEFAULT_CURRENCY,
    }
  }

  stepperPlus = (callback) => {
    window.Intercom("trackEvent", "bid-increased")
    let localBid = parseFloat(
      (this.state.localBid + this.state.bidStepperDelta).toFixed(this.state.bidStepperDelta.countDecimals())
    )
    let localBidInUSD = parseFloat(localBid.toFixed(3))

    this.setState(
      {
        localBid,
        localBidInUSD,
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  stepperMinus = (callback) => {
    window.Intercom("trackEvent", "bid-decreased")
    let localBid = parseFloat(
      (this.state.localBid - this.state.bidStepperDelta).toFixed(this.state.bidStepperDelta.countDecimals())
    )
    let localBidInUSD = parseFloat(localBid.toFixed(3))

    this.setState(
      {
        localBid,
        localBidInUSD,
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  stepperClick = (type) => {
    if (this.state.isBigBidClickedOnce) {
      this.setState({
        isBigBidClickedOnce: false,
      })
    }
    switch (type) {
      case "plus":
        this.stepperPlus()
        break
      case "minus":
        this.stepperMinus()
        break
      default:
        break
    }
  }

  handleKeyPress = (event) => {
    if (!event.key) {
      return
    }

    switch (event.key) {
      case "ArrowUp":
        this.stepperPlus()
        break
      case "ArrowDown":
        this.stepperMinus()
        break
      default:
        return
    }
  }

  focusBidChangeInput = () => {
    let input = document.querySelector(".bid-change-box input")

    if (input) {
      input.focus()

      // A workaround to set the text cursor at the last character position
      input.value = input.value + " "
      setTimeout(() => {
        input.value = input.value.trim()
      }, 0)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.currency === Consts.DEFAULT_CURRENCY && this.state.currency !== Consts.DEFAULT_CURRENCY) {
      let localBidInUSD = parseFloat(this.props.campaign.bid.toFixed(3))

      this.setState({
        localBid: parseFloat(localBidInUSD.toFixed(this.state.bidStepperDelta.countDecimals())),
        localBidInUSD,
      })
    }
    if (
      this.state.localBid > this.props.campaign.bid * Consts.BIG_MULTIPLIER_VALIDATION &&
      !this.state.isBidNeedsValidation
    ) {
      this.setState({
        isBidNeedsValidation: true,
      })
    }
    if (
      !(this.state.localBid > this.props.campaign.bid * Consts.BIG_MULTIPLIER_VALIDATION) &&
      this.state.isBidNeedsValidation
    ) {
      this.setState({
        isBidNeedsValidation: false,
      })
    }
  }

  componentWillMount() {
    let isRealtimeData = false
    let maximumBid = getMaximumBidByConversionName(this.props.campaign.conversion_name, this.props.campaign.provider_id)

    if (
      this.props.datePickerDates.startDate.isSame(this.props.datePickerDates.endDate, "day") &&
      this.props.datePickerDates.startDate.isSame(Moment(), "day")
    ) {
      isRealtimeData = true
    }

    let localBid = parseFloat(
      (this.props.campaign.bid ? this.props.campaign.bid : 0).toFixed(this.state.bidStepperDelta.countDecimals())
    )
    let localBidInUSD = parseFloat(localBid.toFixed(3))

    this.setState({
      maximumBid,
      isRealtimeData,
      localBid,
      localBidInUSD,
      bidStepperDelta: getProviderBidStepSize(this.props.campaign.provider_id),
    })
  }

  componentDidMount() {
    this.focusBidChangeInput()
  }

  isSaveBidButtonDisabled = () => {
    if (
      this.state.localBidInUSD > this.state.maximumBid ||
      this.state.localBid.countDecimals() > getProviderBidStepSize(this.props.campaign.provider_id).countDecimals() ||
      this.state.localBid < 0 ||
      this.state.disableSaveButton ||
      (this.props.campaign.provider_id !== CampaignsConsts.FACEBOOK_PROVIDER_ID && this.state.localBid === 0)
    ) {
      return true
    }

    return false
  }

  saveBidChangeButton = () => {
    let bidValidationText
    if (this.state.isBidNeedsValidation) {
      if (this.state.maximumBid > this.state.localBid) bidValidationText = Consts.BIG_CHANGE_VALIDATION_TEXT
      else bidValidationText = `${Consts.BID_CHANGE_VALIDATION_TEXT_MAX} ($${this.state.maximumBid})`
    }
    let disabledSaveButton = (
      <>
        <Button disabled className="round-button green">
          Save
        </Button>
        <div className="big-change-text">{bidValidationText}</div>
      </>
    )
    if (this.isSaveBidButtonDisabled()) {
      return disabledSaveButton
    }
    return (
      <>
        <Button className="round-button green" onClick={(event) => this.saveChangeBidForm(event)}>
          Save
        </Button>
        <div className="big-change-text">{bidValidationText}</div>
      </>
    )
  }

  changeBid = (event) => {
    event.preventDefault() // Preventing form submission (which is the default form behaviour when "enter" is pressed)
    if (!this.props.isSaveBidAllowed) {
      return false
    }

    this.props.dispatch(campaignBidLoading(this.props.campaign))
    this.props.dispatch(
      changeBidAsync(this.props.campaign, this.props.campaign.bid, this.state.localBid, this.state.localBidInUSD)
    )
    this.props.closePopup()

    eventsTracker.trackBidChanged(this.props.campaign.tracking_code, this.props.campaign.bid, this.state.localBid)
  }

  saveChangeBidForm = (event) => {
    event.preventDefault()

    if (this.state.isBidNeedsValidation && !this.state.isBigBidClickedOnce) {
      this.setState({
        isBigBidClickedOnce: true,
      })
      return
    }

    if (
      parseFloat(
        (this.props.campaign.bid ? this.props.campaign.bid : 0).toFixed(this.state.bidStepperDelta.countDecimals())
      ) !== this.state.localBidInUSD
    ) {
      if (this.state.isRealtimeData || this.props.campaignPopupOpen) {
        this.changeBid(event)
      } else if (webStorage.get(WebStorageConsts.STORAGE_KEYS.SHOW_OUTDATED_WARNING) === "1") {
        this.props.showWarning(
          this.OUTDATED_DATA_WARNING,
          () => {
            this.changeBid(event)
            webStorage.set(WebStorageConsts.STORAGE_KEYS.SHOW_OUTDATED_WARNING, 0)
          },
          event
        )
      } else {
        this.changeBid(event)
        this.props.closePopup()
      }
    } else {
      this.props.closePopup()
    }
  }

  handleBidChange = (values) => {
    const { formattedValue, value, floatValue } = values

    let localBid = null

    if (this.state.isBigBidClickedOnce) {
      this.setState({
        isBigBidClickedOnce: false,
      })
    }
    if (floatValue.toString() === value) {
      localBid = floatValue
    }
    // When value (which is string) ends with zero - it won't be equal to the float value (since float value will never end with zero)
    // For example: "0.010" !== "0.01"
    else if (value[value.length - 1] === "0") {
      // If the float value is not zero - we would like to update the value of the local bid
      if (floatValue !== 0) {
        localBid = floatValue
      }

      // If the float value equals to zero - we would like to disable the save button
      // We won't update the local bid in order to avoid change of "0.0" to "0"
      else {
        this.setState({
          disableSaveButton: true,
        })
      }
    }
    // If the user deleted all the characters in this popup - we will set the bid to zero and it will change the
    // save button to be disabled
    else if (value === "") {
      localBid = 0
    }

    if (localBid !== null) {
      this.setState({
        localBid,
        localBidInUSD: parseFloat(localBid.toFixed(3)),
        disableSaveButton: false,
      })
    }
  }

  render() {
    if (!this.props.isChangeBidAllowed || this.props.shouldShowWarning) {
      return null
    }

    if (this.state.isLoading) {
      return (
        <div className="bid-change-box loading open d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={30} />
        </div>
      )
    }

    let roasEnabled = hasRoasEnabled(this.props.campaign)
    let isRoasBoxOpen = roasEnabled || this.props.localRoasStatus
    let localCurrency = ""
    let localCurrencySymbol = "$"
    let bidInUSDindicator = null

    if (this.state.currency !== Consts.DEFAULT_CURRENCY) {
      localCurrency = this.state.currency.split("-")[1]

      if (Consts.CURRENCY_SYMBOLS[localCurrency]) {
        localCurrencySymbol = Consts.CURRENCY_SYMBOLS[localCurrency]
      }

      bidInUSDindicator = (
        <div className="value-in-usd-indicator">
          <NumberFormat displayType="text" value={this.state.localBidInUSD} prefix="$" suffix=" USD" />
        </div>
      )
    }

    return (
      <>
        <form
          className={
            "bid-change-box " +
            (isRoasBoxOpen ? "" : "open ") +
            (this.props.isSaveBidAllowed ? "" : "save-bid-disabled ") +
            (this.state.currency !== Consts.DEFAULT_CURRENCY ? "with-currency " : "") +
            (this.state.isBidNeedsValidation ? "big-bid-change " : "")
          }
          onSubmit={
            this.isSaveBidButtonDisabled()
              ? (event) => event.preventDefault()
              : (event) => this.saveChangeBidForm(event)
          }
        >
          <div className="bid-change-box-title d-flex align-items-center">
            <div className="text">Manual Bid</div>
          </div>
          <div className="d-flex">
            <div className="stepper-button" onClick={() => this.stepperClick("minus")}>
              −
            </div>
            <NumberFormat
              value={this.state.localBid}
              displayType="input"
              prefix={localCurrencySymbol}
              suffix={localCurrency ? " " + localCurrency : ""}
              onKeyDown={this.handleKeyPress}
              onValueChange={this.handleBidChange}
              className="textbox"
            />
            <div className="stepper-button" onClick={() => this.stepperClick("plus")}>
              +
            </div>
          </div>
          {bidInUSDindicator}
          {this.props.isSaveBidAllowed ? (
            <div className="d-flex flex-column save-button-wrapper">{this.saveBidChangeButton()}</div>
          ) : null}
        </form>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sites: state.app.sites,
    siteIdToSiteObject: state.app.siteIdToSiteObject,
    campaignPopupOpen: state.campaignPopupV2.isOpen,
    datePickerDates: {
      startDate: state.campaignsV2.datePickerDates.startDate,
      endDate: state.campaignsV2.datePickerDates.endDate,
    },
  }
}

export default connect(mapStateToProps)(CampaignManualBid)
