import Moment from "moment"
import ActionTypes from "../../common/actions/actionTypes"
import { isNullOrUndefined } from "../../utils/funcUtils"
import "moment-timezone"
import { appActive, appInactive } from "../../common/actions/commonActions"
import { store } from "../store"
import AdminPageActionTypes from "../../admin/adminPageActionTypes"
import SettingsPageActionTypes from "../../settings/settingsPageActionTypes"
import ProfilePageActionTypes from "../../settings/sections/profile/profilePageActionTypes"

// Setting default timezone (UTC) for the entire application
Moment.tz.setDefault("Etc/UTC")

const appInitialState = {
  currentUser: null,
  countries: null,
  sites: null,
  providers: null,
  networkToSites: null,
  siteIdToSiteObject: null,
  siteIdToNetwork: null,
  allResourceLoaded: false,
  notifications: [],
  isBrowserTabActive: true,
  providerIdToProvider: null,
}

window.onfocus = function () {
  store.dispatch(appActive())
}

window.onblur = function () {
  store.dispatch(appInactive())
}

function appReducer(state = appInitialState, action) {
  if (isNullOrUndefined(state) || isNullOrUndefined(action)) {
    return []
  }

  switch (action.type) {
    case ActionTypes.ALL_RESOURCES_LOADED:
      let siteIdToNetwork = new Map()
      let siteIdToSiteObject = new Map()
      let providerIdToProvider = new Map()
      let userNetworksToModules = new Map()
      let providers = []

      Object.keys(action.user.network_to_modules).forEach((networkId) => {
        let currentNetworkId = parseInt(networkId)
        userNetworksToModules.set(currentNetworkId, new Set(action.user.network_to_modules[currentNetworkId]))
      })

      action.sites.forEach((site) => {
        siteIdToNetwork.set(site.id, site.network)
        siteIdToSiteObject.set(site.id, site)
      })

      Object.values(action.providersList).forEach((provider) => {
        providers.push(provider)
      })

      providers.forEach((provider) => {
        providerIdToProvider.set(provider.id, provider)
      })

      return Object.assign({}, state, {
        siteIdToNetwork,
        siteIdToSiteObject,
        providerIdToProvider,
        countries: action.countries,
        languages: action.languages,
        sites: action.sites,
        providers: providers,
        conversionEvents: action.conversionEvents,
        providersList: action.providersList,
        countryGroups: action.countryGroups,
        networkToSites: action.sites.group((s) => s.network_id),
        allResourceLoaded: true,
        currentUser: Object.assign({}, action.user, {
          userNetworksToModules,
        }),
      })

    case ActionTypes.NOTIFICATION_ADD:
      var notifications = state.notifications.slice()
      notifications.push({
        message: action.message,
        notificationType: action.notificationType,
        getAdditionalNotificationElement: action.getAdditionalNotificationElement,
      })

      return Object.assign({}, state, {
        notifications,
      })

    case ActionTypes.NOTIFICATION_REMOVE:
      var notifications = state.notifications.slice()
      notifications = notifications.filter((item) => {
        return item != action.item
      })

      return Object.assign({}, state, {
        notifications,
      })

    case ActionTypes.CLEAR_NOTIFICATIONS:
      var notifications = state.notifications.slice()

      // Clear notifications with specific type, and keep app-refresh notification if it exists
      if (action.notificationType) {
        notifications = notifications.filter((notification) => {
          return (
            notification.notificationType !== action.notificationType || notification.notificationType === "app-refresh"
          )
        })
      } else {
        // Keeping the app-refresh notification, it is mandatory.
        notifications = notifications.filter((notification) => {
          return notification.notificationType === "app-refresh"
        })
      }

      return Object.assign({}, state, {
        notifications,
      })

    case ActionTypes.RESET_APPLICATION:
      return appInitialState

    case ProfilePageActionTypes.UPDATE_CURRENT_USER_RESPONSE:
      return Object.assign({}, state, {
        currentUser: Object.assign({}, state.currentUser, action.user),
      })

    case ActionTypes.APP_ACTIVE:
      return Object.assign({}, state, {
        isBrowserTabActive: true,
      })

    case ActionTypes.APP_INACTIVE:
      return Object.assign({}, state, {
        isBrowserTabActive: false,
      })

    case ActionTypes.APP_CURRENT_VERSION:
      return Object.assign({}, state, {
        currentVersion: action.currentVersion,
      })

    case SettingsPageActionTypes.ADD_PROVIDER_PAGE:
    case SettingsPageActionTypes.DELETE_PROVIDER_PAGE:
      var sites = state.sites.slice()
      var networkToSites = state.networkToSites.slice()

      sites = sites.map((site) => {
        if (site.id === action.siteId) {
          let providerPages = site.provider_pages.slice()

          if (action.type === SettingsPageActionTypes.ADD_PROVIDER_PAGE) {
            providerPages.push({
              site_id: action.siteId,
              provider_id: action.providerId,
              page_id: action.pageId,
              page_name: action.pageName,
              page_language: action.pageLanguage,
            })
          } else if (action.type === SettingsPageActionTypes.DELETE_PROVIDER_PAGE) {
            providerPages = providerPages.filter((facebookPage) => facebookPage.page_id !== action.pageId)
          }

          site = {
            ...site,
            provider_pages: providerPages,
          }

          let networkId = state.siteIdToNetwork.get(action.siteId).id
          networkToSites = networkToSites.map((network) => {
            if (parseInt(network.key) === networkId) {
              let sitesData = network.data.map((siteData) => {
                if (siteData.id === action.siteId) {
                  siteData = { ...siteData, provider_pages: providerPages }
                }

                return siteData
              })

              network = { ...network, data: sitesData }
            }

            return network
          })
        }

        return site
      })

      return Object.assign({}, state, {
        sites,
        networkToSites,
      })

    default:
      return state
  }
}

export { appReducer }
