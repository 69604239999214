import React from "react"
import Cell from "./cell"
import { markSelectedRow } from "../../../utils/domUtils"

export default class Row extends React.Component {
  getStickyCells = () => {
    if (this.props.stickyCells && this.props.stickyCells.length > 0) {
      let stickyCells = []

      this.props.stickyCells.forEach((cell, index) => {
        if (!this.props.includeNotVisible && cell.isVisible === false) {
          return
        }

        if (!cell.hasOwnProperty("cellSize") && this.props.columnIndexToSize) {
          cell.cellSize = this.props.columnIndexToSize[index]
        }

        stickyCells.push(
          <Cell key={"sticky-" + index} {...cell} sort={cell.sortable !== false ? this.props.sort : null} />
        )
      })

      return <div className="sticky-column">{stickyCells}</div>
    }

    return null
  }

  getCells = () => {
    let stickyCellsLength = this.props.stickyCells ? this.props.stickyCells.length : 0
    return this.props.cells.map((cell, index) => {
      if (!this.props.includeNotVisible && cell.isVisible === false) {
        return
      }

      if (!cell.hasOwnProperty("cellSize") && this.props.columnIndexToSize) {
        cell.cellSize = this.props.columnIndexToSize[index + stickyCellsLength]
      }

      return <Cell key={index} {...cell} sort={this.props.sort} />
    })
  }

  render() {
    let stickyCells = this.getStickyCells()
    let cells = this.getCells()
    let row = (
      <div
        className={"table-row " + this.props.cssClasses.join(" ")}
        onClick={this.props.allowRowSelection && !this.props.wrapper ? markSelectedRow : null}
        {...this.props.containerProps}
      >
        {stickyCells}
        {cells}
      </div>
    )

    if (this.props.wrapper) {
      return this.props.wrapper(row, "table-row", { onClick: markSelectedRow })
    }

    return row
  }
}

Row.defaultProps = {
  // Container props will contain only known HTML attributes (className, data-tip, data-class, onClick...).
  // We will add those props by spreading this object
  containerProps: {},

  // Sticky cells who should stay at the same place - on the left side of the table
  stickyCells: [],

  // Cells on the right side of the table (after the sticky cells)
  cells: [],

  // By default, clicking on a row should mark it. this option will prevent it in
  // special cases (necessary for summary row, realtime rows...)
  allowRowSelection: true,

  // Additional css classes to support special designs
  cssClasses: [],

  // Whether to include cells that have the isVisible prop set to false (usually useful for exporting)
  includeNotVisible: false,
}
