import Specification from "./Specification"

export class TrueSpec extends Specification {
  constructor() {
    super()
  }

  isSatisfied() {
    return true
  }
}

export class FalseSpec extends Specification {
  constructor() {
    super()
  }

  isSatisfied() {
    return false
  }
}
