import React from "react"
import NumberFormat from "./numberFormat"
import { connect } from "react-redux"
import { Button, CircularProgress, Popover } from "@material-ui/core"
import { isNullOrUndefined } from "../../utils/funcUtils"
import { stopPropagationIfParentIsSelected } from "../../utils/domUtils"
import Consts from "../../app/consts"
import { getCurrencySymbol, getMaximumBudgetByConversionName } from "../../utils/campaignUtilsV2"

class StepperPopup extends React.Component {
  STEPPER_DEFAULT_STEP = 1
  DEFAULT_VALUE = 0
  DEFAULT_DECIMAL_SCALE = 3
  DEFAULT_PREFIX = "$"
  DEFAULT_SUFFIX = ""

  constructor(props) {
    super(props)

    this.state = {
      maximumBudget: getMaximumBudgetByConversionName(
        this.props.campaign?.conversion_name,
        this.props.campaign?.provider_id
      ),
      popupAnchorEl: null,
      popupOpen: false,
      localValue: 0,
      localValueInUSD: 0,
      stepSize: this.STEPPER_DEFAULT_STEP,
      isLoading: false,
      isBigBudgetClickedOnce: false,
      isMultiplierValidation: false,
    }
  }

  openPopup = (event) => {
    stopPropagationIfParentIsSelected(event)

    this.setState({
      popupOpen: true,
      popupAnchorEl: event.currentTarget,
      localValue: parseFloat(
        this.props.startingValue ? this.props.startingValue.toFixed(3) : this.DEFAULT_VALUE.toFixed(3)
      ),
      localValueInUSD: parseFloat(
        this.props.startingValue ? this.props.startingValue.toFixed(3) : this.DEFAULT_VALUE.toFixed(3)
      ),
      stepSize: this.props.stepSize ? this.props.stepSize : this.STEPPER_DEFAULT_STEP,
    })
  }

  closePopup = () => {
    this.setState({ popupOpen: false })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.popupOpen ||
      this.state.popupOpen !== nextState.popupOpen ||
      this.props.isLoading !== nextProps.isLoading ||
      this.props.startingValue !== nextProps.startingValue
    )
  }

  componentDidUpdate() {
    if (
      this.state.localValue > this.props.startingValue * Consts.BIG_MULTIPLIER_VALIDATION &&
      !this.state.isMultiplierValidation
    ) {
      this.setState({
        isMultiplierValidation: true,
      })
    }
    if (
      !(this.state.localValue > this.props.startingValue * Consts.BIG_MULTIPLIER_VALIDATION) &&
      this.state.isMultiplierValidation
    ) {
      this.setState({
        isMultiplierValidation: false,
      })
    }
  }

  handleValueChange = (values) => {
    const { value, floatValue } = values
    if (this.state.isBigBudgetClickedOnce) {
      this.setState({
        isBigBudgetClickedOnce: false,
      })
    }
    if (floatValue.toString() == value) {
      this.setState({
        localValue: floatValue,
        localValueInUSD: parseFloat(floatValue.toFixed(0)),
      })
    }
  }

  changeValue = (event) => {
    event.preventDefault() // Preventing form submission (which is the default form behaviour when "enter" is pressed)

    if (this.state.isMultiplierValidation && !this.state.isBigBudgetClickedOnce) {
      this.setState({
        isBigBudgetClickedOnce: true,
      })
      return
    }
    if (
      parseFloat(this.props.startingValue ? this.props.startingValue.toFixed(3) : this.DEFAULT_VALUE.toFixed(3)) !==
      this.state.localValueInUSD
    ) {
      if (this.props.onSubmitClicked) {
        this.props.onSubmitClicked(this.state.localValue, this.state.localValueInUSD)
      }
    }
    this.closePopup()
  }

  stepperClick = (type) => {
    if (this.state.isBigBudgetClickedOnce) {
      this.setState({
        isBigBudgetClickedOnce: false,
      })
    }
    switch (type) {
      case "plus":
        this.stepperPlus()
        break
      case "minus":
        this.stepperMinus()
        break
      default:
        break
    }
  }

  stepperPlus = () => {
    let localValue = parseFloat((this.state.localValue + this.state.stepSize).toFixed(3))

    this.setState({
      localValue,
      localValueInUSD: parseFloat(localValue.toFixed(0)),
    })
  }

  stepperMinus = () => {
    let localValue = parseFloat((this.state.localValue - this.state.stepSize).toFixed(3))

    this.setState({
      localValue,
      localValueInUSD: parseFloat(localValue.toFixed(0)),
    })
  }

  handleKeyPress = (event) => {
    if (!event.key) {
      return
    }

    switch (event.key) {
      case "ArrowUp":
        this.stepperPlus()
        break
      case "ArrowDown":
        this.stepperMinus()
        break
      default:
        return
    }
  }

  popupOnEnter = () => {
    if (!this.popupElement) {
      return
    }

    let input = this.popupElement.querySelector("input")

    if (!input) {
      return
    }

    input.focus()

    // A workaround to set the text cursor at the last character position
    let val = input.value
    input.value = val + " "
    setTimeout(() => {
      input.value = val
    }, 0)
  }

  saveValueButton = (isExceededMaxBudget) => {
    let budgetValidationText
    if (isExceededMaxBudget) {
      budgetValidationText = (
        <div className="big-change-text">
          {`${Consts.BUDGET_CHANGE_VALIDATION_TEXT_MAX} ($${this.state.maximumBudget})`}
        </div>
      )
    } else if (this.state.isMultiplierValidation) {
      budgetValidationText = <div className="big-change-text">{Consts.BIG_CHANGE_VALIDATION_TEXT}</div>
    }
    return (
      <>
        <Button
          className="round-button green"
          disabled={isExceededMaxBudget}
          onClick={(event) => this.changeValue(event)}
        >
          Save
        </Button>
        {budgetValidationText ? budgetValidationText : null}
      </>
    )
  }

  hasCurrency = () => {
    return this.props.currency && this.props.currency !== Consts.DEFAULT_CURRENCY
  }

  render() {
    let hasCurrency = this.hasCurrency()
    let loaderDiv = (
      <div className="loading-indicator">
        <CircularProgress size={20} />
      </div>
    )
    let stepperLoadingIndicator = this.props.isLoading ? loaderDiv : null
    let decimalScale = !isNullOrUndefined(this.props.decimalScale)
      ? this.props.decimalScale
      : this.DEFAULT_DECIMAL_SCALE
    let prefix = !isNullOrUndefined(this.props.prefix) ? this.props.prefix : this.DEFAULT_PREFIX
    let suffix = !isNullOrUndefined(this.props.suffix) ? this.props.suffix : this.DEFAULT_SUFFIX
    let currency = ""
    let currencySymbol = "$"

    if (hasCurrency) {
      currency = this.props.currency.split("-")[1]
      currencySymbol = getCurrencySymbol(currency)
    }

    let valueInUSDindicator = null

    if (hasCurrency) {
      valueInUSDindicator = (
        <div className="value-in-usd-indicator">
          <NumberFormat
            value={this.state.localValueInUSD}
            displayType={"text"}
            thousandSeparator={true}
            prefix={prefix}
            suffix={suffix + " USD"}
          />
        </div>
      )
    }

    const isExceededMaxBudget = this.state.localValue > this.state.maximumBudget
    let changeValueBox = (
      <form
        className={
          "stepper-box open " + (this.state.isMultiplierValidation || isExceededMaxBudget ? "big-budget-change " : "")
        }
        onSubmit={(event) => {
          this.changeValue(event)
        }}
      >
        <div className="d-flex ">
          <div className="stepper-button" onClick={() => this.stepperClick("minus")}>
            −
          </div>
          <NumberFormat
            value={this.state.localValue}
            displayType={"input"}
            prefix={hasCurrency ? currencySymbol : prefix}
            suffix={suffix + (hasCurrency ? " " + currency : "")}
            onKeyDown={(event) => this.handleKeyPress(event)}
            onValueChange={this.handleValueChange}
            className="textbox"
          />
          <div className="stepper-button" onClick={() => this.stepperClick("plus")}>
            +
          </div>
        </div>
        {valueInUSDindicator}
        <div className="d-flex flex-column save-button-wrapper">{this.saveValueButton(isExceededMaxBudget)}</div>
      </form>
    )

    let popupContent = null

    if (this.state.isLoading) {
      popupContent = (
        <div className={"stepper-popup "}>
          <div className="stepper-box open loading">
            <CircularProgress className="loader" size={30} />
          </div>
        </div>
      )
    } else {
      popupContent = (
        <div
          className={"stepper-popup " + (hasCurrency ? "with-currency " : "")}
          ref={(popupElement) => (this.popupElement = popupElement)}
        >
          {changeValueBox}
        </div>
      )
    }

    let stepperPopup = (
      <Popover
        open={this.state.popupOpen}
        anchorEl={this.state.popupAnchorEl}
        onClose={this.closePopup}
        classes={{ paper: "stepper-popup-wrapper" }}
        anchorOrigin={{
          vertical: -22,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onEntered={this.popupOnEnter}
        getContentAnchorEl={null}
        onClick={(event) => event.stopPropagation()}
      >
        {popupContent}
      </Popover>
    )

    return (
      <div className={"general-stepper "}>
        <div
          onClick={this.props.isAllowedToChange ? this.openPopup : null}
          className={this.props.isAllowedToChange ? "clickable" : ""}
        >
          <NumberFormat
            value={this.props.startingValue}
            displayType={"text"}
            decimalScale={decimalScale}
            prefix={prefix}
            suffix={suffix}
            thousandSeparator={true}
          />
        </div>
        <div className="stepper-underline" />
        {stepperPopup}
        {stepperLoadingIndicator}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(StepperPopup)
