import React from "react"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import { CircularProgress, Divider, TextField, Switch } from "@material-ui/core"
import Dropzone from "react-dropzone"
import CreationValidator, { FACEBOOK_THUMBNAIL_RATIO_ERROR } from "../creationValidator"
import Xcreatives from "../../resources/svgs/Xcreatives.svg"
import WhiteCheckmark from "../../resources/images/WhiteCheckmark.png"
import WarningIcon from "../../resources/svgs/WarningIcon.svg"
import { clearNotifications, notificationAdd } from "../../common/actions/commonActions"
import CropIcon from "../../resources/svgs/CropIcon.svg"
import ZoomIcon from "../../resources/svgs/ZoomIcon.svg"
import AddImageIcon from "../../resources/svgs/AddImageIcon.svg"
import DropImageIcon from "../../resources/svgs/DropImageIcon.svg"
import CropCreativeDialog from "./cropCreativeDialog"
import CreativeIndexAndActions from "./creativeIndexAndActions"
import { eventsTracker } from "../../api/eventsTracker"
import ArrowGalleryIcon from "../../resources/svgs/ArrowGalleryIcon.svg"
import { isNullOrUndefined } from "../../utils/funcUtils"
import CampaignsConsts from "../../campaignsV2/campaignsConsts"
import Videoicon from "../../resources/svgs/VideoIcon.svg"

class Creative extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      text: this.props.creative.text,
      description: this.props.creative.description,
      headline: this.props.creative.headline,
      isValid: false,
      thumbnail: this.props.creative.thumbnail,
      isCropOpen: false,
      cropDefaultTab: null,
      validationErrors: new Map(),
      validationWarnings: new Map(),
      isVideo: this.props.creative.is_video,
    }
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // the initiation of the creative sets the state in the constructor. Since there is an option to update the
    // creatives through the global state and the state is set only at the creation we need to update the state..
    if (this.props.creative.text !== prevProps.creative.text) {
      this.setState({
        text: this.props.creative.text,
        description: this.props.creative.description,
        headline: this.props.creative.headline,
        isValid: prevState.isValid,
        thumbnail: this.props.creative.thumbnail,
        isCropOpen: prevState.isCropOpen,
        cropDefaultTab: prevState.cropDefaultTab,
        validationErrors: prevState.validationErrors,
        validationWarnings: prevState.validationWarnings,
        isVideo: this.props.creative.is_video,
      })
    }
  }

  openCropDialog = (cropDefaultTab = null) => {
    this.setState({
      isCropOpen: true,
      cropDefaultTab: cropDefaultTab,
    })

    eventsTracker.trackCreativeClicked("Crop")
  }

  getThumbnailElement = (isStaticCreative, getInputProps, openBrowserUpload) => {
    let existingThumbnailActions = null
    let cropIcon = null

    if (this.props.allowThumbnailCrop && !this.state?.isVideo) {
      cropIcon = (
        <div
          className="existing-thumbnail-action-button clickable crop-thumbnail"
          onClick={() => this.openCropDialog()}
        >
          <CropIcon width="12" height="11" />
        </div>
      )
    }

    if (!isStaticCreative) {
      existingThumbnailActions = (
        <div className="existing-thumbnail-actions">
          <div
            className="existing-thumbnail-action-button clickable remove-thumbnail"
            onClick={this.handleThumbnailRemoval}
          >
            <Xcreatives width="8" height="8" />
          </div>
          <a
            className="existing-thumbnail-action-button clickable view-thumbnail"
            onClick={() => {
              eventsTracker.trackCreativeClicked("Open in a new window")
            }}
            target="_blank"
            href={this.state.thumbnail}
          >
            <ZoomIcon width="10" height="10" />
          </a>
          {cropIcon}
        </div>
      )
    }

    if (this.state.thumbnail && !this.props.creative.isThumbnailBeingUploaded) {
      if (isStaticCreative) {
        return (
          <a
            className={"thumbnail clickable" + (isStaticCreative ? " static-thumbnail" : "")}
            href={this.state.thumbnail}
            target="_blank"
          >
            {this.state?.isVideo ? (
              <>
                <div className="video-icon">
                  <Videoicon />
                </div>
                <iframe src={this.state.thumbnail} frameborder="0"></iframe>
              </>
            ) : (
              <img src={this.state.thumbnail} />
            )}
            {existingThumbnailActions}
          </a>
        )
      }

      return (
        <div className={"thumbnail" + (isStaticCreative ? " static-thumbnail" : "")}>
          {this.state?.isVideo ? (
            <>
              <div className="video-icon">
                <Videoicon />
              </div>
              <iframe src={this.state.thumbnail} frameborder="0"></iframe>
            </>
          ) : (
            <img src={this.state.thumbnail} />
          )}
          {existingThumbnailActions}
        </div>
      )
    } else {
      if (this.props.creative.isThumbnailBeingUploaded) {
        return (
          <div className="thumbnail uploading">
            <CircularProgress className="loader" size={30} />
          </div>
        )
      } else {
        return (
          <>
            <div className="thumbnail drop">
              <input {...getInputProps()} />
              <div className="no-image">
                <AddImageIcon />

                <div className="thumbnail-actions">
                  <Divider classes={{ root: "thumbnail-divider" }} />
                  <div className="thumbnail-button" onClick={openBrowserUpload}>
                    <span>
                      <ArrowGalleryIcon />
                      Upload
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    }
  }

  handleLocalFileDrop = (files) => {
    this.file = files[0]
    this.props.dispatch(this.props.updateThumbnailLoadingStatus(this.props.creative.id, true, this.props.groupIndex))
    this.props.thumbnailUpdateFunc(this.props.creative.id, this.file, null, null, null, this.props.groupIndex)
    this.setState({
      isVideo: this.file.type.includes("video"),
    })
  }

  handleCropPerSource = (files, sourceKey, cropDetails, selectedRatio) => {
    this.file = files[0]
    this.props.dispatch(this.props.updateThumbnailLoadingStatus(this.props.creative.id, true, this.props.groupIndex))
    this.props.thumbnailUpdateFunc(
      this.props.creative.id,
      this.file,
      sourceKey,
      cropDetails,
      selectedRatio,
      this.props.groupIndex
    )
  }

  handleDrop = (event, rejected) => {
    // This function will be called regardless if dropped file was accepted or rejected
    // Thus, we would like to make sure that we won't upload invalid file
    if (Array.isArray(rejected) && rejected.length > 0 && rejected[0].preview) {
      this.handleThumbnailRemoval()
      return
    }

    // The following conditions were created in order to recognize when the dropped file
    // came from local directory or from the gallery
    if (event.dataTransfer && event.dataTransfer.types[0] === "Files") {
      this.handleLocalFileDrop(event.dataTransfer.files)
    } else if (event[0]) {
      this.handleLocalFileDrop(event)
    }
  }

  handleThumbnailRemoval = () => {
    this.setState({
      thumbnail: null,
    })

    this.props.thumbnailRemoveFunc(this.props.creative.id, this.props.groupIndex)
    eventsTracker.trackCreativeClicked("Delete")
  }

  validateCreative = (creative) => {
    if (this.props.campaignSources) {
      let { errors: validationErrors, warnings: validationWarnings } = CreationValidator.isCreativeValid(
        creative,
        this.props.campaignSources
      )

      let isValid = validationErrors.size === 0
      this.setState({
        isValid: isValid,
        validationErrors: validationErrors,
        validationWarnings: validationWarnings,
      })
    }
  }

  handleChange = (name, event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  updateCreativeInStore = () => {
    this.props.creativeUpdateFunc(
      this.props.creative.id,
      this.state.text,
      this.state.description,
      this.state.headline,
      this.state.callToAction,
      this.props.groupIndex
    )
  }

  componentWillMount() {
    if (!this.props.static) {
      this.validateCreative({
        id: this.props.creative.id,
        text: this.state.text,
        description: this.state.description,
        headline: this.state.headline,
        thumbnail: this.props.creative.thumbnail,
        thumbnailErrors: this.props.creative.thumbnailErrors,
        callToAction: this.state.callToAction,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.static) {
      this.validateCreative({
        id: this.props.creative.id,
        text: this.state.text,
        description: this.state.description,
        headline: this.state.headline,
        thumbnail: nextProps.creative.thumbnail,
        thumbnailErrors: nextProps.creative.thumbnailErrors,
        callToAction: this.state.callToAction,
      })

      if (nextProps.creative.thumbnail && nextProps.creative.thumbnail !== this.props.creative.thumbnail) {
        this.props.dispatch(this.props.updateThumbnailLoadingStatus(nextProps.creative.id, false))
        this.setState({
          thumbnail: nextProps.creative.thumbnail,
        })
      }
    }
  }

  handleDropRejected = (args) => {
    let file = args[0]

    this.props.dispatch(clearNotifications())

    if (
      this.props.selectedSource &&
      this.props.selectedSource.imageSizeValidationMB &&
      file.size > this.props.selectedSource.imageSizeValidationMB * 1000000
    ) {
      this.props.dispatch(
        notificationAdd("Image size must be smaller than " + this.props.selectedSource.imageSizeValidationMB + "MB")
      )
    } else {
      this.props.dispatch(notificationAdd("Invalid image type"))
    }
  }

  getRelevantSourcesForCropDialog = () => {
    let sources = {}
    Object.keys(this.props.campaignSources.sources).forEach((sourceKey) => {
      let source = this.props.campaignSources.sources[sourceKey]

      if (source.selected) {
        sources[sourceKey] = Object.assign({}, source)
      }
    })

    return sources
  }

  generateCreativeEditable = () => {
    let descriptionBox = null
    let headlineBox = null
    let descriptionPlaceholder = this.props.campaignSources.sources.Taboola.selected
      ? "Description (Optional)"
      : "Description..."

    if (this.props.showDescription || CreationValidator.shouldShowDescription(this.props.campaignSources)) {
      descriptionBox = (
        <span className="creative-description">
          <TextField
            onChange={(event) => this.handleChange("description", event)}
            onBlur={() => this.updateCreativeInStore()}
            classes={{ root: "creative-textfield creative-multiline" }}
            placeholder={descriptionPlaceholder}
            value={this.state.description}
            helperText={(
              CreationValidator.getDescriptionMaxCharsAllowed(this.props.campaignSources) -
              this.state.description.length
            ).toString()}
            InputProps={{
              inputProps: {
                maxLength: String(CreationValidator.getDescriptionMaxCharsAllowed(this.props.campaignSources)),
                className: "description-text-area",
              },
            }}
            multiline={true}
          />
        </span>
      )
    }
    if (CreationValidator.shouldShowHeadline(this.props.campaignSources)) {
      headlineBox = (
        <span className="creative-description">
          <TextField
            onChange={(event) => this.handleChange("headline", event)}
            onBlur={() => this.updateCreativeInStore()}
            classes={{ root: "creative-textfield creative-multiline" }}
            placeholder="Headline..."
            value={this.state.headline}
            helperText={(
              CreationValidator.getHeadlineMaxCharsAllowed(this.props.campaignSources) - this.state.headline.length
            ).toString()}
            InputProps={{
              inputProps: {
                maxLength: String(CreationValidator.getHeadlineMaxCharsAllowed(this.props.campaignSources)),
                className: "description-text-area",
              },
            }}
            multiline={true}
          />
        </span>
      )
    }

    let creativeErrors = null
    let creativeWarnings = null
    let creativeIndexAndActions = null
    let cropCreativeDialog = null
    let errorsList = []
    let warningsList = []

    if (this.state.validationErrors.size > 0) {
      this.state.validationErrors.forEach((error) => {
        if (this.props.validationErrorsVisible) {
          errorsList.push(error)
        }
      })
    }

    if (this.state.validationWarnings.size > 0) {
      // Warnings are always visible
      this.state.validationWarnings.forEach((warning) => {
        warningsList.push(warning)
      })
    }

    if (errorsList.length > 0) {
      creativeErrors = (
        <ul className="creative-errors flex-column">
          {errorsList.map((error, index) => {
            return (
              <li className="d-flex text" key={index}>
                {error}
              </li>
            )
          })}
        </ul>
      )
    }

    if (warningsList.length > 0) {
      creativeWarnings = (
        <ul className="creative-warnings flex-column">
          {warningsList.map((warning, index) => {
            switch (warning) {
              case FACEBOOK_THUMBNAIL_RATIO_ERROR:
                warning = (
                  <div>
                    Image ratio is different than Facebook's recommended ratio of 1:1 to 1.91:1, please{" "}
                    <span className="link" onClick={() => this.openCropDialog("Facebook")}>
                      crop the image
                    </span>
                    .
                  </div>
                )
                break
            }

            return (
              <li className="d-flex text" key={index}>
                {warning}
              </li>
            )
          })}
        </ul>
      )
    }

    creativeIndexAndActions = (
      <CreativeIndexAndActions
        index={this.props.index}
        id={this.props.creative.id}
        creativeCloneFunc={this.props.creativeCloneFunc}
        creativeRemoveFunc={this.props.creativeRemoveFunc}
        creativesAmount={this.props.creatives.length}
        groupIndex={this.props.groupIndex}
      />
    )

    if (this.props.allowThumbnailCrop) {
      cropCreativeDialog = (
        <CropCreativeDialog
          image={this.props.creative.thumbnail}
          isOpen={this.state.isCropOpen}
          cropDefaultTab={this.state.cropDefaultTab}
          updatePopupState={this.updateIsCropOpen}
          updateImageFunc={this.handleCropPerSource}
          isThumbnailBeingUploaded={this.props.creative.isThumbnailBeingUploaded}
          sources={this.getRelevantSourcesForCropDialog()}
          croppedImagesPerSource={this.props.creative.croppedImagesPerSource}
          thumbnailType={this.props.creative.thumbnailType}
        />
      )
    }

    let creativeProps = {}

    let fileMaxSizeBytes = Infinity

    if (this.props.selectedSource && this.props.selectedSource.imageSizeValidationMB) {
      fileMaxSizeBytes = this.props.selectedSource.imageSizeValidationMB * 1000000
    }

    let fileTypeAccept = "image/jpeg,image/jpg,image/tiff,image/gif,image/png"
    if (this.props.selectedSource.id === CampaignsConsts.FACEBOOK_PROVIDER_ID) {
      fileTypeAccept = `${fileTypeAccept},video/mp4,video/mov,video/gif`
      fileMaxSizeBytes = Infinity
    }

    return (
      <div
        className={
          "creative-container " + (!isNullOrUndefined(this.props.groupIndex) ? "creative-group-container" : "")
        }
      >
        <div className="creative" {...creativeProps}>
          {
            <>
              <div
                className={
                  "creative-valid d-flex justify-content-center align-items-center " +
                  (this.state.isValid ? "visible" : "")
                }
              >
                <img src={WhiteCheckmark} />
              </div>
              <div
                className={
                  "creative-invalid " + (!this.state.isValid && this.props.validationErrorsVisible ? "visible" : "")
                }
              >
                <WarningIcon width="27" height="24" />
              </div>
            </>
          }
          {creativeIndexAndActions}
          <Dropzone
            onDrop={this.handleDrop}
            accept={fileTypeAccept}
            maxSize={fileMaxSizeBytes}
            multiple={false}
            onDropRejected={this.handleDropRejected}
            onClick={(evt) => evt.preventDefault()}
            disabled={Boolean(this.state.thumbnail || this.props.creative.isThumbnailBeingUploaded)}
          >
            {({ getRootProps, getInputProps, isDragActive, open: openBrowserUpload }) => {
              let dropView = null

              if (isDragActive) {
                dropView = (
                  <div className="active-drag">
                    <div className="drop-container">
                      <DropImageIcon className="drop-image" />
                      Drop to attach image
                    </div>
                  </div>
                )
              }

              return (
                <div className="creative-details" {...getRootProps()}>
                  {dropView}
                  {this.getThumbnailElement(false, getInputProps, openBrowserUpload)}
                  <div className="creative-texts">
                    <span className="creative-title">
                      <TextField
                        onChange={(event) => this.handleChange("text", event)}
                        onBlur={() => this.updateCreativeInStore()}
                        classes={{ root: "creative-textfield" }}
                        value={this.state.text}
                        placeholder="Title..."
                        helperText={(
                          CreationValidator.getTitleMaxCharsAllowed(this.props.campaignSources) - this.state.text.length
                        ).toString()}
                        InputProps={{
                          inputProps: {
                            maxLength: String(CreationValidator.getTitleMaxCharsAllowed(this.props.campaignSources)),
                          },
                        }}
                      />
                    </span>
                    {descriptionBox}
                    {headlineBox}
                  </div>
                  {creativeWarnings}
                  {creativeErrors}
                </div>
              )
            }}
          </Dropzone>
        </div>
        {cropCreativeDialog}
      </div>
    )
  }

  generateCreativeStatic() {
    let descriptionBox = null
    let headlineBox = null
    let creativeStatic = null

    if (this.props.showDescription || CreationValidator.shouldShowDescription(this.props.campaignSources)) {
      descriptionBox = <div className="multiline">{this.state.description}</div>
    }
    if (CreationValidator.shouldShowHeadline(this.props.campaignSources)) {
      headlineBox = <div className="multiline">{this.state.headline}</div>
    }

    creativeStatic = (
      <div className="creative-static">
        {this.getThumbnailElement(true)}
        <div className="d-flex flex-column texts">
          <div className="font-bold creative-title">{this.state.text}</div>
          {descriptionBox}
          {headlineBox}
        </div>
      </div>
    )

    return creativeStatic
  }

  updateIsCropOpen = (status) => {
    this.setState({ isCropOpen: status })
  }

  render() {
    if (this.props.static) {
      return this.generateCreativeStatic()
    } else {
      return this.generateCreativeEditable()
    }
  }
}

function mapStateToProps(state, ownProps) {
  let selectedSource = null

  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
    let source = state.campaignCreationWizard.campaignSources.sources[sourceKey]

    if (source.selected) {
      selectedSource = source
    }
  })

  return {
    selectedSource,
    hasAutoGeneratePermission: state.campaignCreationWizard.campaignCreatives.hasAutoGeneratePermission,
    articleUrl: state.campaignCreationWizard.campaignSettings.articleUrl,
  }
}

export default connect(mapStateToProps)(Creative)
