class BidTypes {}

BidTypes.lowest_cost = {
  id: 1,
  name: "Lowest Cost",
  value: 1,
  selected: false,
  supportingProviders: [9, 91],
}

BidTypes.bid_cap = {
  id: 3,
  name: "Bid Cap",
  value: 3,
  selected: false,
  supportingProviders: [9],
}

BidTypes.roas = {
  id: 4,
  name: "ROAS",
  value: 4,
  selected: false,
  supportingProviders: [9, 91],
  defaultBid: 120,
}

BidTypes.cost_cap = {
  id: 5,
  name: "Cost Cap",
  value: 5,
  selected: false,
  supportingProviders: [9, 91, 102],
}

BidTypes.clicks = {
  // for old campaigns that have linked bid type 0 //Clicks
  id: 0,
  name: "clicks",
  value: 0,
  selected: false,
  supportingProviders: [],
}

BidTypes.idToType = {
  0: BidTypes.clicks, // need to remove this after all campaigns are updated
  1: BidTypes.lowest_cost,
  3: BidTypes.bid_cap,
  4: BidTypes.roas,
  5: BidTypes.cost_cap,
}

BidTypes.getBidTypesByProvider = function (providerId) {
  return Object.values(BidTypes.idToType).filter((bidType) => bidType.supportingProviders.includes(providerId))
}

export default BidTypes
