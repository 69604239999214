import React from "react"
import Moment from "moment"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import { disableInfiniteScrollHandler, handleInfiniteScroll } from "../../../utils/domUtils"
import Consts from "../../../app/consts"
import Table from "../../../common/components/table/table"
import AutomationsConsts from "../../../automations/automationsConsts"
import { automationActionHistorySortBy, automationPopupActionHistoryShowMore } from "../../automationPopupActions"
import { buildActionHistoryDisplay } from "../../../utils/actionHistoryUtils"
import { generateCampaignUrl } from "../../../utils/campaignUtilsV2"

class AutomationPopupActionHistoryList extends React.Component {
  columns = AutomationsConsts.LIST_COLUMNS.actionHistoryColumns

  sortItemsBy = (column) => {
    this.props.dispatch(automationActionHistorySortBy(column))
  }

  getMoreItemsIfNeeded = () => {
    let scroller = document.querySelector(".campaign-popup-container")
    const offsetFix = 50
    if (scroller.scrollTop + scroller.offsetHeight >= scroller.scrollHeight - offsetFix) {
      if (this.props.actionHistory.length >= this.props.itemsPerPage * this.props.currentPage) {
        this.props.dispatch(automationPopupActionHistoryShowMore())
      }
    }
  }

  handleItemsInfiniteScroll = () => {
    handleInfiniteScroll(".campaign-popup-container", this.getMoreItemsIfNeeded)
  }

  disableItemsInfiniteScrollHandler = () => {
    disableInfiniteScrollHandler(".campaign-popup-container", this.getMoreItemsIfNeeded)
  }

  componentDidUpdate() {
    this.disableItemsInfiniteScrollHandler()
    this.handleItemsInfiniteScroll()
    ReactTooltip.rebuild()
  }

  componentDidMount() {
    this.disableItemsInfiniteScrollHandler()
    this.handleItemsInfiniteScroll()
    ReactTooltip.rebuild()
  }

  componentWillUnmount() {
    this.disableItemsInfiniteScrollHandler()
  }

  getHeader = () => {
    let columns = this.columns
    return {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.time.fieldName,
          value: columns.time.title,
          cellSize: columns.time.cellSize,
          cssClasses: columns.time.cssClasses,
          clickable: true,
        },
        {
          fieldName: columns.campaign.fieldName,
          value: columns.campaign.title,
          cellSize: columns.campaign.cellSize,
          cssClasses: columns.campaign.cssClasses,
          clickable: true,
        },
        {
          fieldName: columns.action.fieldName,
          value: columns.action.title,
          cellSize: columns.action.cellSize,
          cssClasses: columns.action.cssClasses,
          clickable: false,
        },
      ],
    }
  }

  getRow = (item) => {
    let columns = this.columns

    let row = {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.time.fieldName,
          value: (
            <span>{Moment(item.created_at, Consts.SERVER_DATETIME_FORMAT).format(Consts.CLIENT_DATETIME_FORMAT)}</span>
          ),
          cssClasses: columns.time.cssClasses,
          cellSize: columns.time.cellSize,
        },
        {
          fieldName: columns.campaign.fieldName,
          value: (
            <a className="automation-history-campaign-link" href={generateCampaignUrl(item, true)} target="_blank">
              {item.campaign_name}
            </a>
          ),
          cellSize: columns.campaign.cellSize,
          cssClasses: columns.campaign.cssClasses,
        },
        {
          fieldName: columns.action.fieldName,
          value: buildActionHistoryDisplay(item, "actionHistory"),
          cellSize: columns.action.cellSize,
          cssClasses: columns.action.cssClasses,
        },
      ],
    }

    row = {
      cssClasses: [],
      stickyCells: [],
      cells: row.cells,
    }

    return row
  }

  getRows = (usePagination = true) => {
    let rows = []
    let lastIndex = this.props.actionHistory.length

    if (this.props.actionHistory.length > 0) {
      if (usePagination) {
        lastIndex = Math.min(this.props.itemsPerPage * this.props.currentPage, this.props.actionHistory.length)
      }

      for (let i = 0; i < lastIndex; i++) {
        let item = this.props.actionHistory[i]
        rows.push(this.getRow(item))
      }
    }

    return rows
  }

  getAllRows = () => {
    return this.getRows(false)
  }

  render() {
    let rows = this.getRows(true)
    let header = this.getHeader()

    return (
      <Table
        header={header}
        rows={rows}
        sort={{
          sortBy: this.props.sortBy,
          sortDirection: this.props.sortDirection,
          sortHandler: this.sortItemsBy,
        }}
        scrolling={{
          containerClass: ".campaign-popup-container",
          includeNavigationBar: false,
        }}
        cssClasses={["campaign-popup-list"]}
        getAllRows={this.getAllRows}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    actionHistory: state.automationPopup.actionHistory.dataForTable,
    sortBy: state.automationPopup.actionHistory.sortBy,
    sortDirection: state.automationPopup.actionHistory.sortDirection,
    itemsPerPage: state.automationPopup.actionHistory.itemsPerPage,
    currentPage: state.automationPopup.actionHistory.currentPage,
    automation: state.automationPopup.automation,
    datePickerDates: {
      startDate: state.automationPopup.datePickerDates.startDate,
      endDate: state.automationPopup.datePickerDates.endDate,
    },
  }
}

export default connect(mapStateToProps)(AutomationPopupActionHistoryList)
