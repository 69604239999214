import axios from "axios"
import Moment from "moment"
import CampaignServiceV2 from "../api/campaignServiceV2"
import CampaignsConsts from "./campaignsConsts"
import { notificationAdd } from "../common/actions/commonActions"
import WebStorageConsts from "../common/consts/webStorageConsts"
import RequestsService from "../api/requestsService"
import Consts from "../app/consts"
import { webStorage } from "../api/webStorage"
import CampaignActionTypes from "./campaignActionTypes"
import { serializeFilters } from "../utils/funcUtils"

export function fetchCampaignsResponse(campaigns, isLoading, hasCampaigns) {
  return {
    type: CampaignActionTypes.FETCH_CAMPAIGNS_RESPONSE_V2,
    campaigns,
    isLoading,
    hasCampaigns,
  }
}

export function campaignsListLoading() {
  return { type: CampaignActionTypes.CAMPAIGNS_LIST_LOADING, isLoading: true }
}

export function filterCampaignsByStatus(status, isUpdatingCampaignsNames = false) {
  return { type: CampaignActionTypes.FILTER_CAMPAIGNS_BY_STATUS, currentFilterStatus: status, isUpdatingCampaignsNames }
}

export function rollbackCampaignsPage() {
  return { type: CampaignActionTypes.ROLLBACK_CAMPAIGNS_PAGE }
}

export function pauseCampaignResponse(campaign, lastModified, lastModifiedAction) {
  return {
    type: CampaignActionTypes.PAUSE_CAMPAIGN_RESPONSE,
    campaign,
    isCampaignStatusLoading: false,
    status: CampaignsConsts.CAMPAIGN_STATUS_PAUSED,
    lastModified,
    lastModifiedAction,
  }
}

export function stopCampaignErrorResponse(campaign, serverStatus) {
  return {
    type: CampaignActionTypes.STOP_CAMPAIGN_ERROR_RESPONSE,
    campaign,
    isCampaignStatusLoading: false,
    status: serverStatus,
  }
}

export function activeCampaignResponse(campaign, lastModified, lastModifiedAction, activationDate) {
  return {
    type: CampaignActionTypes.ACTIVE_CAMPAIGN_RESPONSE,
    campaign,
    isCampaignStatusLoading: false,
    status: CampaignsConsts.CAMPAIGN_STATUS_RUNNING,
    lastModified,
    lastModifiedAction,
    activationDate,
  }
}

export function startCampaignErrorResponse(campaign, serverStatus) {
  return {
    type: CampaignActionTypes.START_CAMPAIGN_ERROR_RESPONSE,
    campaign,
    isCampaignStatusLoading: false,
    status: serverStatus,
  }
}

export function bulkStopCampaignsResponse(campaigns, summary) {
  return { type: CampaignActionTypes.BULK_STOP_CAMPAIGNS_RESPONSE, campaigns, summary }
}

export function bulkStopCampaignsErrorResponse(campaigns, error) {
  return { type: CampaignActionTypes.BULK_STOP_CAMPAIGNS_ERROR_RESPONSE, campaigns, error }
}

export function bulkStartCampaignsResponse(campaigns, summary) {
  return { type: CampaignActionTypes.BULK_START_CAMPAIGNS_RESPONSE, campaigns, summary }
}

export function bulkStartCampaignsErrorResponse(campaigns, error) {
  return { type: CampaignActionTypes.BULK_START_CAMPAIGNS_ERROR_RESPONSE, campaigns, error }
}

export function bulkEditCampaignsBudgetResponse(campaigns, summary) {
  return { type: CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BUDGET_RESPONSE, campaigns, summary }
}

export function bulkEditCampaignsBudgetErrorResponse(campaigns, error) {
  return { type: CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BUDGET_ERROR_RESPONSE, campaigns, error }
}

export function bulkEditCampaignsBidResponse(campaigns, summary) {
  return { type: CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BID_RESPONSE, campaigns, summary }
}

export function bulkEditCampaignsBidErrorResponse(campaigns, error) {
  return { type: CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BID_ERROR_RESPONSE, campaigns, error }
}

export function bulkEditCampaignsTargetingResponse(campaigns) {
  return { type: CampaignActionTypes.BULK_EDIT_CAMPAIGNS_TARGETING_RESPONSE, campaigns }
}

export function bulkCampaignStatusLoading(campaigns, isCampaignStatusLoading) {
  return { type: CampaignActionTypes.BULK_CAMPAIGN_STATUS_LOADING, campaigns, isCampaignStatusLoading }
}

export function bulkUpdateCampaignTagsResponse(campaigns) {
  return { type: CampaignActionTypes.BULK_UPDATE_CAMPAIGN_TAGS, campaigns }
}

export function bulkActivateCampaignsResponse(campaigns, summary) {
  return { type: CampaignActionTypes.BULK_SMART_ACTIVATION_CAMPAIGNS_RESPONSE, campaigns, summary }
}

export function bulkActivateCampaignsLoading(campaigns, isLoading) {
  return { type: CampaignActionTypes.BULK_ACTIVATE_CAMPAIGNS_LOADING, campaigns, isLoading }
}

export function bulkActivateCampaignsErrorResponse(campaigns, error) {
  return { type: CampaignActionTypes.BULK_ACTIVATE_CAMPAIGNS_ERROR_RESPONSE, campaigns, error }
}

export function campaignStatusLoading(campaign) {
  return { type: CampaignActionTypes.CAMPAIGN_STATUS_LOADING, campaign, isCampaignStatusLoading: true }
}

export function campaignBidLoading(campaign) {
  return { type: CampaignActionTypes.CAMPAIGN_BID_LOADING, campaign, isCampaignBidLoading: true }
}

export function campaignTotalBudgetLoading(campaign) {
  return { type: CampaignActionTypes.CAMPAIGN_TOTAL_BUDGET_LOADING, campaign, isCampaignTotalBudgetLoading: true }
}

export function campaignDailyBudgetLoading(campaign) {
  return { type: CampaignActionTypes.CAMPAIGN_DAILY_BUDGET_LOADING, campaign, isCampaignDailyBudgetLoading: true }
}

export function bulkCampaignDailyBudgetLoading(campaigns) {
  return {
    type: CampaignActionTypes.BULK_CAMPAIGNS_DAILY_BUDGET_LOADING,
    campaigns,
    isCampaignDailyBudgetLoading: true,
  }
}

export function bulkCampaignTotalBudgetLoading(campaigns) {
  return {
    type: CampaignActionTypes.BULK_CAMPAIGNS_TOTAL_BUDGET_LOADING,
    campaigns,
    isCampaignTotalBudgetLoading: true,
  }
}

export function bulkCampaignsBidLoading(campaigns) {
  return { type: CampaignActionTypes.BULK_CAMPAIGNS_BID_LOADING, campaigns, isCampaignBidLoading: true }
}

export function sortBy(campaignsSortBy) {
  return { type: CampaignActionTypes.CAMPAIGNS_SORT_BY, sortBy: campaignsSortBy }
}

function getCampaignLastModification(campaign) {
  return campaign.last_modified
}

function getCampaignLastModificationAction(campaign) {
  return campaign.last_modified_action
}

export function changeBidResponse(campaign, bid, lastModified, lastModifiedAction) {
  return {
    type: CampaignActionTypes.CHANGE_BID_RESPONSE,
    campaign,
    isCampaignBidLoading: false,
    bid,
    lastModified,
    lastModifiedAction,
  }
}

export function changeRoasResponse(campaign, roas, lastModified, lastModifiedAction) {
  return {
    type: CampaignActionTypes.CHANGE_ROAS_RESPONSE,
    campaign,
    isCampaignBidLoading: false,
    roas,
    lastModified,
    lastModifiedAction,
  }
}

export function changeRoasErrorResponse(campaign, roas) {
  return {
    type: CampaignActionTypes.CHANGE_ROAS_ERROR_RESPONSE,
    campaign,
    isCampaignBidLoading: false,
    roas: roas,
    lastModified: getCampaignLastModification(campaign),
    lastModifiedAction: getCampaignLastModificationAction(campaign),
  }
}

export function changeBidErrorResponse(campaign, bid) {
  return {
    type: CampaignActionTypes.CHANGE_BID_ERROR_RESPONSE,
    campaign,
    isCampaignBidLoading: false,
    bid,
    lastModified: getCampaignLastModification(campaign),
    lastModifiedAction: getCampaignLastModificationAction(campaign),
  }
}

export function fetchCampaignsReportAsync(networkCode, startDate, endDate) {
  return (dispatch) => {
    RequestsService.cancelRequest("campaigns-list-request")
    return CampaignServiceV2.getCampaignsReport(networkCode, startDate, endDate)
      .then((result) => {
        dispatch(fetchCampaignsResponse(result.data.report, false, result.data.has_campaigns))
      })
      .catch((error) => {
        if (error) {
          dispatch(fetchCampaignsResponse({}, false, false))
          dispatch(notificationAdd(CampaignsConsts.CAMPAIGN_REPORT_ERROR_MESSAGE))
        }
      })
  }
}

export function campaignsDatePickerDatesSelected(startDate, endDate, dateType, showLastHour) {
  webStorage.set(WebStorageConsts.STORAGE_KEYS.SHOW_OUTDATED_WARNING, 1)
  return {
    type: CampaignActionTypes.CAMPAIGNS_DATEPICKER_DATES_SELECTED,
    startDate,
    endDate,
    dateType,
    showLastHour,
  }
}

export function pauseCampaignAsync(campaign) {
  return (dispatch) => {
    CampaignServiceV2.pauseCampaign(campaign.campaign_id)
      .then((result) => {
        dispatch(
          pauseCampaignResponse(campaign, result.data.last_modified, JSON.parse(result.data.last_modified_action))
        )
      })
      .catch((error) => {
        dispatch(stopCampaignErrorResponse(campaign, error.response.data.status))
      })
  }
}

export function activeCampaignAsync(campaign) {
  return (dispatch) => {
    CampaignServiceV2.activeCampaign(campaign.campaign_id)
      .then((result) => {
        dispatch(
          activeCampaignResponse(
            campaign,
            result.data.last_modified,
            JSON.parse(result.data.last_modified_action),
            result.data.activation_date
          )
        )
      })
      .catch((error) => {
        dispatch(startCampaignErrorResponse(campaign, error.response.data.message))
      })
  }
}

function bulkUpdateCampaigns(campaigns, bulkUpdateService, bulkSuccessAction, bulkErrorAction, loadingAction) {
  return (dispatch) => {
    // Because of some flask limitations, we can't start/stop campaigns in a parallel way on the server, these start/stop
    // requests are being made one by one for each campaign that we send, so if we send a lot of campaigns then the request
    // might be timed out.
    // The solution is sending a couple of parallel requests with a small amount of campaigns in each of them,
    // this way we're doing it in a "semi-parallel" way.
    let requests = []
    let campaignsChunks = campaigns.slice()

    if (loadingAction) {
      dispatch(loadingAction(campaigns, true))
    }

    while (campaignsChunks.length > 0) {
      requests.push(bulkUpdateService(campaignsChunks.splice(0, Consts.BULK_CAMPAIGNS_PER_REQUEST)))
    }

    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          let summary = {}

          responses.forEach((response) => {
            summary = { ...summary, ...response.data.summary }
          })

          dispatch(bulkSuccessAction(campaigns, summary))
          let unsuccessfulStatusChanges = Object.values(summary).filter(
            (statusChangeItem) => !statusChangeItem.success
          ).length
          let statusChangeAttempts = Object.values(summary).length
          let successCount = statusChangeAttempts - unsuccessfulStatusChanges
          let partialSuccessesCount = Object.values(summary).filter((item) => item.partial_success).length

          if (unsuccessfulStatusChanges > 0) {
            if (partialSuccessesCount > 0) {
              let notificationText = ""

              if (successCount > 0) {
                notificationText = `${successCount} out of ${statusChangeAttempts} campaigns were updated successfully, `
              }

              notificationText += `${
                partialSuccessesCount - successCount
              } campaigns could only be partially updated (check Activity)`

              dispatch(notificationAdd(notificationText, "campaigns-bulk-status-change-warning"))
            } else {
              dispatch(
                notificationAdd(
                  unsuccessfulStatusChanges + " out of " + statusChangeAttempts + " campaigns couldn't be updated",
                  "campaigns-bulk-status-change-error"
                )
              )
            }
          }
        })
      )
      .catch((error) => {
        if (bulkErrorAction) {
          dispatch(bulkErrorAction(campaigns, error))
        }
      })
  }
}

export function bulkStopCampaignsAsync(campaigns) {
  return bulkUpdateCampaigns(
    campaigns,
    CampaignServiceV2.bulkStopCampaigns,
    bulkStopCampaignsResponse,
    bulkStopCampaignsErrorResponse,
    bulkCampaignStatusLoading
  )
}

export function bulkStartCampaignsAsync(campaigns) {
  return bulkUpdateCampaigns(
    campaigns,
    CampaignServiceV2.bulkStartCampaigns,
    bulkStartCampaignsResponse,
    bulkStartCampaignsErrorResponse,
    bulkCampaignStatusLoading
  )
}

export function bulkEditCampaignsDailyBudgetAsync(campaigns, action, value, valueInUSD = value) {
  return bulkUpdateCampaigns(
    campaigns,
    (campaigns) => CampaignServiceV2.bulkChangeCampaignsBudget(campaigns, action, value, "daily budget"),
    bulkEditCampaignsBudgetResponse,
    bulkEditCampaignsBudgetErrorResponse,
    bulkCampaignDailyBudgetLoading
  )
}

export function bulkEditCampaignsTotalBudgetAsync(campaigns, action, value, valueInUSD = value) {
  return bulkUpdateCampaigns(
    campaigns,
    (campaigns) => CampaignServiceV2.bulkChangeCampaignsBudget(campaigns, action, value, "total budget"),
    bulkEditCampaignsBudgetResponse,
    bulkEditCampaignsBudgetErrorResponse,
    bulkCampaignTotalBudgetLoading
  )
}

export function bulkChangeBidAsync(campaigns, action, value, valueInUSD = value) {
  return bulkUpdateCampaigns(
    campaigns,
    (campaigns) => CampaignServiceV2.bulkChangeBid(campaigns, action, value),
    bulkEditCampaignsBidResponse,
    bulkEditCampaignsBidErrorResponse,
    bulkCampaignsBidLoading
  )
}

export function bulkUpdateTagsAsync(campaigns, operation, selectedTags) {
  if (operation === "Add") {
    return bulkUpdateCampaigns(
      campaigns,
      (campaigns) => CampaignServiceV2.bulkAddTags(campaigns, selectedTags),
      bulkUpdateCampaignTagsResponse
    )
  } else if (operation === "Remove") {
    return bulkUpdateCampaigns(
      campaigns,
      (campaigns) => CampaignServiceV2.bulkRemoveTags(campaigns, selectedTags),
      bulkUpdateCampaignTagsResponse
    )
  }
}

export function bulkActivateCampaignsAsync(campaignsToActivationData) {
  return bulkUpdateCampaigns(
    campaignsToActivationData,
    (campaignsToActivationData) => CampaignServiceV2.bulkActivateCampaigns(campaignsToActivationData),
    bulkActivateCampaignsResponse,
    bulkActivateCampaignsErrorResponse,
    bulkActivateCampaignsLoading
  )
}

export function changeBidAsync(campaign, currentBid, desiredBid, desiredBidInUSD = desiredBid) {
  return (dispatch) => {
    CampaignServiceV2.changeBid(campaign.campaign_id, currentBid, desiredBid)
      .then((result) => {
        dispatch(
          changeBidResponse(
            campaign,
            desiredBidInUSD,
            result.data.last_modified,
            JSON.parse(result.data.last_modified_action)
          )
        )
      })
      .catch((error) => {
        dispatch(changeBidErrorResponse(campaign, campaign.bid))
      })
  }
}

export function changeRoasAsync(campaign, currentRoas, desiredRoas) {
  return (dispatch) => {
    CampaignServiceV2.changeRoas(campaign.campaign_id, currentRoas, desiredRoas)
      .then((result) => {
        dispatch(
          changeRoasResponse(
            campaign,
            desiredRoas,
            result.data.last_modified,
            JSON.parse(result.data.last_modified_action)
          )
        )
      })
      .catch((error) => {
        dispatch(changeRoasErrorResponse(campaign, currentRoas))
      })
  }
}

export function changeTotalBudgetResponse(campaign, totalBudget, lastModified, lastModifiedAction) {
  return {
    type: CampaignActionTypes.CHANGE_TOTAL_BUDGET_RESPONSE,
    campaign,
    isCampaignTotalBudgetLoading: false,
    total_budget: totalBudget,
    lastModified,
    lastModifiedAction,
  }
}

export function changeTotalBudgetErrorResponse(campaign) {
  return {
    type: CampaignActionTypes.CHANGE_TOTAL_BUDGET_ERROR_RESPONSE,
    campaign,
    isCampaignTotalBudgetLoading: false,
    total_budget: campaign.total_budget,
    lastModified: getCampaignLastModification(campaign),
    lastModifiedAction: getCampaignLastModificationAction(campaign),
  }
}

export function changeTotalBudgetAsync(campaign, newTotalBudget, newTotalBudgetInUSD = newTotalBudget) {
  return (dispatch) => {
    dispatch(campaignTotalBudgetLoading(campaign))
    CampaignServiceV2.editCampaignV2(campaign.campaign_id, {
      total_budget: newTotalBudget,
      total_budget_usd: newTotalBudgetInUSD,
    })
      .then((result) => {
        dispatch(
          changeTotalBudgetResponse(
            campaign,
            newTotalBudgetInUSD,
            result.data.last_modified,
            JSON.parse(result.data.last_modified_action)
          )
        )
      })
      .catch((error) => {
        dispatch(changeTotalBudgetErrorResponse(campaign))
      })
  }
}

export function changeDailyBudgetResponse(campaign, dailyBudget, result, lastModified, lastModifiedAction) {
  return {
    type: CampaignActionTypes.CHANGE_DAILY_BUDGET_RESPONSE,
    campaign,
    isCampaignDailyBudgetLoading: false,
    daily_budget: dailyBudget,
    lastModified,
    lastModifiedAction,
  }
}

export function changeDailyBudgetErrorResponse(campaign) {
  return {
    type: CampaignActionTypes.CHANGE_DAILY_BUDGET_ERROR_RESPONSE,
    campaign,
    isCampaignDailyBudgetLoading: false,
    daily_budget: campaign.daily_budget,
    lastModified: getCampaignLastModification(campaign),
    lastModifiedAction: getCampaignLastModificationAction(campaign),
  }
}

export function changeDailyBudgetAsync(campaign, newDailyBudget, newDailyBudgetUSD = newDailyBudget) {
  return (dispatch) => {
    dispatch(campaignDailyBudgetLoading(campaign))
    CampaignServiceV2.editCampaignV2(campaign.campaign_id, {
      daily_budget: newDailyBudget,
      daily_budget_usd: newDailyBudgetUSD,
    })
      .then((result) => {
        dispatch(
          changeDailyBudgetResponse(
            campaign,
            newDailyBudgetUSD,
            result,
            result.data.last_modified,
            JSON.parse(result.data.last_modified_action)
          )
        )
      })
      .catch((error) => {
        dispatch(changeDailyBudgetErrorResponse(campaign))
      })
  }
}

export function resetCampaignsPagination() {
  return { type: CampaignActionTypes.RESET_CAMPAIGNS_PAGINATION }
}

export function filterCampaigns(filters) {
  return (dispatch) => {
    let serializedFilters = serializeFilters(filters)

    webStorage.set(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_FILTERS, JSON.stringify(serializedFilters))
    dispatch({ type: CampaignActionTypes.FILTER_CAMPAIGNS, filters })
    dispatch(resetCampaignsPagination())
  }
}

export function updateCampaignsPage(currentPage) {
  return { type: CampaignActionTypes.UPDATE_CAMPAIGNS_PAGE, currentPage }
}

export function campaignMarkSelected(campaign, event) {
  return { type: CampaignActionTypes.CAMPAIGN_MARK_SELECTED, campaign, selected: !campaign.selected, event }
}

export function campaignMarkAllSelected() {
  return { type: CampaignActionTypes.CAMPAIGN_MARK_ALL_SELECTED }
}

export function clearCampaignsFilter() {
  return { type: CampaignActionTypes.CLEAR_CAMPAIGNS_FILTER }
}

export function shouldUpdateCampaignV2Page(shouldUpdate) {
  return { type: CampaignActionTypes.SHOULD_UPDATE_CAMPAIGNS_PAGE, shouldUpdate }
}

export function handleHideCampaignsV2Page(hideClicked) {
  return { type: CampaignActionTypes.HANDLE_HIDE_CAMPAIGNS_V2_PAGE, hideClicked }
}

export function userGeneratedReportOnCampaignsPage(startDate, endDate) {
  return { type: CampaignActionTypes.USER_GENERATED_REPORT_ON_CAMPAIGNS_PAGE, startDate, endDate }
}

export function setCampaignsReportType(reportType, overrideIsGeneratedReport = false) {
  return {
    type: CampaignActionTypes.SET_CAMPAIGNS_REPORT_TYPE,
    reportType,
    overrideIsGeneratedReport: overrideIsGeneratedReport,
  }
}

export function resetCampaignsPageDates() {
  return { type: CampaignActionTypes.RESET_CAMPAIGNS_PAGE_DATES }
}

export function updateItemsPerPage(itemsPerPage) {
  return (dispatch) => {
    dispatch({ type: CampaignActionTypes.UPDATE_ITEMS_PER_PAGE, itemsPerPage })
    dispatch(resetCampaignsPagination())
  }
}
