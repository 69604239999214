import React from "react"
import DuplicateIcon from "../../resources/svgs/DuplicateIcon.svg"
import Xicon from "../../resources/svgs/Xicon.svg"

export default class CreativeIndexAndActions extends React.Component {
  render() {
    return (
      <div className="creative-id">
        <span>{"#" + this.props.index}</span>

        <div className="creative-hover-actions">
          {!this.props.isThumbnailBeingUploaded ? (
            <span>
              <DuplicateIcon
                className="clickable duplicate-creative-button"
                onClick={() => this.props.creativeCloneFunc(this.props.id, this.props.groupIndex)}
              />
            </span>
          ) : null}
          {this.props.creativesAmount > 1 ? ( // The "X" icon is only visible if there's more than one creative
            <span>
              <Xicon
                width="12"
                height="12"
                className="clickable remove-creative-button"
                onClick={() => this.props.creativeRemoveFunc(this.props.id, this.props.groupIndex)}
              />
            </span>
          ) : null}
        </div>
      </div>
    )
  }
}
