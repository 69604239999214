import SubSourcesConsts from "./subSourcesConsts"
import subSourceActionTypes from "./subSourceActionTypes"
import SubSourceService from "../api/subSourceService"

export function pauseSubSourceResponse(subSource) {
  return {
    type: subSourceActionTypes.PAUSE_SUB_SOURCE_RESPONSE,
    subSource,
    isStatusLoading: false,
    status: SubSourcesConsts.SUB_SOURCE_STATUS_PAUSED,
  }
}

export function pauseSubSourceErrorResponse(subSource) {
  return {
    type: subSourceActionTypes.PAUSE_SUB_SOURCE_ERROR_RESPONSE,
    subSource,
    isStatusLoading: false,
    status: SubSourcesConsts.SUB_SOURCE_STATUS_RUNNING,
  }
}

export function activateSubSourceResponse(subSource) {
  return {
    type: subSourceActionTypes.ACTIVATE_SUB_SOURCE_RESPONSE,
    subSource,
    isStatusLoading: false,
    status: SubSourcesConsts.SUB_SOURCE_STATUS_RUNNING,
  }
}

export function activateSubSourceErrorResponse(subSource) {
  return {
    type: subSourceActionTypes.ACTIVATE_SUB_SOURCE_ERROR_RESPONSE,
    subSource,
    isStatusLoading: false,
  }
}

export function subSourceStatusLoading(subSource) {
  return { type: subSourceActionTypes.SUB_SOURCE_STATUS_LOADING, subSource, isStatusLoading: true }
}

export function subSourceBidLoading(subSource) {
  return { type: subSourceActionTypes.SUB_SOURCE_BID_LOADING, subSource, isBidLoading: true }
}

export function changeSubSourceBidResponse(subSource, bid) {
  return {
    type: subSourceActionTypes.CHANGE_SUB_SOURCE_BID_RESPONSE,
    subSource,
    isBidLoading: false,
    bid,
  }
}

export function changeSubSourceBidErrorResponse(subSource, bid) {
  return {
    type: subSourceActionTypes.CHANGE_SUB_SOURCE_BID_ERROR_RESPONSE,
    subSource,
    isBidLoading: false,
    bid,
  }
}

export function pauseSubSourceAsync(campaign, subSource) {
  return (dispatch) => {
    SubSourceService.pauseSubSource(campaign.campaign_id, subSource.sub_source)
      .then(() => {
        dispatch(pauseSubSourceResponse(subSource))
      })
      .catch(() => {
        dispatch(pauseSubSourceErrorResponse(subSource))
      })
  }
}

export function activateSubSourceAsync(campaign, subSource) {
  return (dispatch) => {
    SubSourceService.activateSubSource(campaign.campaign_id, subSource.sub_source)
      .then(() => {
        dispatch(activateSubSourceResponse(subSource))
      })
      .catch(() => {
        dispatch(activateSubSourceErrorResponse(subSource))
      })
  }
}

export function changeSubSourceBidAsync(campaign, subSource, desiredBid) {
  return (dispatch) => {
    SubSourceService.changeSubSourceBid(campaign.campaign_id, subSource.sub_source, subSource.bid, desiredBid)
      .then(() => {
        dispatch(changeSubSourceBidResponse(subSource, desiredBid))
      })
      .catch(() => {
        dispatch(changeSubSourceBidErrorResponse(subSource, subSource.bid))
      })
  }
}
