import React from "react"
import { connect } from "react-redux"
import { CircularProgress, ListItem, Menu, MenuItem } from "@material-ui/core"
import PlayIcon from "../../resources/svgs/PlayIcon.svg"
import PauseIcon from "../../resources/svgs/PauseIcon.svg"
import InactiveIcon from "../../resources/svgs/InactiveIcon.svg"
import DeleteIcon from "../../resources/svgs/DeleteIcon.svg"
import CampaignsConsts from "../campaignsConsts"
import { campaignStatusLoading, activeCampaignAsync, pauseCampaignAsync } from "../campaignActions"
import ModuleTypes from "../../common/consts/moduleTypes"
import AuthApi from "../../api/authService"
import { stopPropagationIfParentIsSelected } from "../../utils/domUtils"
import Consts from "../../app/consts"
import ReactDOMServer from "react-dom/server"
import { isNullOrEmpty } from "../../utils/funcUtils"
import { notificationAdd } from "../../common/actions/commonActions"

class CampaignActionsMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuAnchorEl: null,
      menuOpen: false,
    }
  }

  getCampaignStatusIcon = () => {
    var statusIcon = null
    let status_info = ""
    let status_tool_tip = this.props.campaign.status
    let total_ads = 0
    if (this.props.campaign.ads_status) {
      total_ads =
        this.props.campaign.ads_status.ERROR +
        this.props.campaign.ads_status.ACTIVE +
        this.props.campaign.ads_status.PAUSED +
        this.props.campaign.ads_status.REVIEW +
        this.props.campaign.ads_status.OTHER
      status_tool_tip = (
        <ul className="tags-list" key="tags-list">
          {Object.entries(this.props.campaign.ads_status).map(([key, value]) => {
            return (
              <li key={value} style={{ color: value ? "black" : "gray" }}>
                • {value} {CampaignsConsts.ADS_STATUS_LIST[key]}
              </li>
            )
          })}
        </ul>
      )
      if (total_ads === 0 && this.props.campaign.ads_status.DELETED) {
        return (
          <div
            data-tip={ReactDOMServer.renderToStaticMarkup(status_tool_tip)}
            data-class="tooltip-custom type-inactive no-click shrink-tip"
          >
            <InactiveIcon className="campaign-status-icon" />{" "}
          </div>
        )
      }

      // If all ads are approved then Green status
      if (this.props.campaign.ads_status.ERROR === 0 && this.props.campaign.ads_status.OTHER === 0) {
        this.props.campaign.status === CampaignsConsts.CAMPAIGN_STATUS_PAUSED
          ? (status_info = " gray")
          : (status_info = " green")
      } else {
        // If all ads rejected then Red status
        if (this.props.campaign.ads_status.ERROR === total_ads) {
          status_info = " red"
        }
        // If some ads are approved, others in any other status (e.g. rejected, error) then Orange
        else {
          status_info = " orange"
        }
      }
    }
    switch (this.props.campaign.status) {
      case CampaignsConsts.CAMPAIGN_STATUS_RUNNING:
        statusIcon = (
          <div
            className="status_tags_popup"
            data-tip={ReactDOMServer.renderToStaticMarkup(status_tool_tip)}
            data-class="tooltip-custom tags-tooltip"
            data-place="right"
          >
            <PlayIcon className={"campaign-status-icon play-icon" + status_info} />
            {this.props.statusTextVisible ? (
              <span className={status_info}>{CampaignsConsts.CAMPAIGN_STATUS_NAMES.ACTIVE}</span>
            ) : null}
          </div>
        )
        break
      case CampaignsConsts.CAMPAIGN_STATUS_PAUSED:
        statusIcon = (
          <div
            className="status_tags_popup"
            data-tip={ReactDOMServer.renderToStaticMarkup(status_tool_tip)}
            data-class="tooltip-custom tags-tooltip"
            data-place="right"
          >
            <PauseIcon className={"campaign-status-icon pause-icon" + status_info} />
            {this.props.statusTextVisible ? (
              <span className={status_info}>{CampaignsConsts.CAMPAIGN_STATUS_NAMES.PAUSED}</span>
            ) : null}
          </div>
        )
        break
      case CampaignsConsts.CAMPAIGN_STATUS_DELETED:
        statusIcon = (
          <div
            className="status_tags_popup"
            data-tip={ReactDOMServer.renderToStaticMarkup(status_tool_tip)}
            data-class="tooltip-custom tags-tooltip"
            data-place="right"
          >
            <DeleteIcon className="campaign-status-icon" />
            {this.props.statusTextVisible ? (
              <span className={status_info}>{CampaignsConsts.CAMPAIGN_STATUS_NAMES.DELETED}</span>
            ) : null}
          </div>
        )
        break
      case CampaignsConsts.CAMPAIGN_STATUS_REJECTED:
        statusIcon = (
          <div
            className="status_tags_popup"
            data-tip={ReactDOMServer.renderToStaticMarkup(status_tool_tip)}
            data-class="tooltip-custom tags-tooltip"
            data-place="right"
          >
            <InactiveIcon className="campaign-status-icon rejected-icon" />
            {this.props.statusTextVisible ? (
              <span className={status_info}>{CampaignsConsts.CAMPAIGN_STATUS_NAMES.WITH_ISSUES}</span>
            ) : null}
          </div>
        )
        break
      default:
        statusIcon = null
    }
    return statusIcon
  }

  getCampaignActionMenuItems = () => {
    var menuItems = null
    switch (this.props.campaign.status) {
      case CampaignsConsts.CAMPAIGN_STATUS_RUNNING:
      case CampaignsConsts.CAMPAIGN_STATUS_CAPPED:
        menuItems = [
          <ListItem key={1} className="current-campaign-status">
            <PlayIcon className="play-icon green" />
            <span className="green">Running</span>
          </ListItem>,
          <MenuItem key={3} className="campaign-actions-menu-item" onClick={this.pauseCampaign}>
            <PauseIcon />
            <span>Pause</span>
          </MenuItem>,
        ]
        break

      case CampaignsConsts.CAMPAIGN_STATUS_PAUSED:
        menuItems = [
          <ListItem key={1} className="current-campaign-status">
            <PauseIcon />
            <span>Paused</span>
          </ListItem>,
          <MenuItem key={2} className="campaign-actions-menu-item" onClick={this.activeCampaign}>
            <PlayIcon />
            <span>Run</span>
          </MenuItem>,
        ]
        break

      default:
        menuItems = null
    }

    return menuItems
  }

  pauseCampaign = () => {
    let campaign = this.props.campaign
    this.props.dispatch(campaignStatusLoading(campaign))
    this.props.dispatch(pauseCampaignAsync(campaign))
    this.closeMenu()
  }

  activeCampaign = () => {
    let campaign = this.props.campaign
    if (campaign.archived) {
      this.props.dispatch(notificationAdd("Campaign status couldn't be change because Article is archived"))
    } else {
      this.props.dispatch(campaignStatusLoading(campaign))
      this.props.dispatch(activeCampaignAsync(campaign))
    }

    this.closeMenu()
  }

  openMenu = (event) => {
    stopPropagationIfParentIsSelected(event)
    this.setState({ menuOpen: true, menuAnchorEl: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  render() {
    let isClickable = false
    let campaignActionsMenu = null
    let campaignStatus = this.getCampaignStatusIcon()
    let loaderDiv = (
      <div className="loading-indicator">
        <CircularProgress size={20} />
      </div>
    )
    let campaignStatusLoadingIndicator = this.props.campaign.isCampaignStatusLoading ? loaderDiv : null

    if (
      !Consts.PROVIDERS_THAT_CANT_CHANGE_STATUS.includes(this.props.campaign.provider_id) &&
      AuthApi.hasModule(ModuleTypes.CAMPAIGN_STATUS_CHANGE) &&
      !isNullOrEmpty(this.props.campaign.status) &&
      ![CampaignsConsts.CAMPAIGN_STATUS_REJECTED, CampaignsConsts.CAMPAIGN_STATUS_DELETED].includes(
        this.props.campaign.status
      )
    ) {
      isClickable = true
      campaignActionsMenu = (
        <Menu
          id="campaign-actions-menu"
          classes={{ paper: "default-menu campaign-actions-menu" }}
          anchorEl={this.state.menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: -16,
          }}
          open={this.state.menuOpen}
          onClose={this.closeMenu}
          onClick={(event) => event.stopPropagation()}
        >
          {this.getCampaignActionMenuItems()}
        </Menu>
      )
    }

    return (
      <div className={"campaign-actions" + (isClickable ? " clickable" : "")}>
        <div aria-owns={this.state.open ? "campaign-actions-menu" : null} aria-haspopup="true" onClick={this.openMenu}>
          {campaignStatus}
        </div>
        {campaignActionsMenu}
        {campaignStatusLoadingIndicator}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(CampaignActionsMenu)
