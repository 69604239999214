class subSourceActionTypes {}
subSourceActionTypes.PAUSE_SUB_SOURCE_RESPONSE = "PAUSE_SUB_SOURCE_RESPONSE"
subSourceActionTypes.PAUSE_SUB_SOURCE_ERROR_RESPONSE = "PAUSE_SUB_SOURCE_ERROR_RESPONSE"
subSourceActionTypes.ACTIVATE_SUB_SOURCE_ERROR_RESPONSE = "ACTIVATE_SUB_SOURCE_ERROR_RESPONSE"
subSourceActionTypes.ACTIVATE_SUB_SOURCE_RESPONSE = "ACTIVATE_SUB_SOURCE_RESPONSE"
subSourceActionTypes.SUB_SOURCE_STATUS_LOADING = "SUB_SOURCE_STATUS_LOADING"
subSourceActionTypes.SUB_SOURCE_BID_LOADING = "SUB_SOURCE_BID_LOADING"
subSourceActionTypes.CHANGE_SUB_SOURCE_BID_RESPONSE = "CHANGE_SUB_SOURCE_BID_RESPONSE"
subSourceActionTypes.CHANGE_SUB_SOURCE_BID_ERROR_RESPONSE = "CHANGE_SUB_SOURCE_BID_ERROR_RESPONSE"
export default subSourceActionTypes
