class NetworkTypes {}

NetworkTypes.ALL_NETWORKS = {
  id: 10000,
  name: "All Networks",
  link: "AllNetworks",
  isNetworkGroupType: false,
  code: "ALL",
}

NetworkTypes.INTERNAL = {
  id: 10001,
  name: "Internal",
  link: "Internal",
  isNetworkGroupType: true,
  code: "INTERNAL",
}

NetworkTypes.PODS = {
  id: 10002,
  name: "Partners",
  link: "Pods",
  isNetworkGroupType: true,
  code: "PARTNERS",
}

NetworkTypes.idToType = {
  [NetworkTypes.ALL_NETWORKS.id]: NetworkTypes.ALL_NETWORKS,
  [NetworkTypes.INTERNAL.id]: NetworkTypes.INTERNAL,
  [NetworkTypes.PODS.id]: NetworkTypes.PODS,
}

NetworkTypes.linkToType = {
  [NetworkTypes.ALL_NETWORKS.link]: NetworkTypes.ALL_NETWORKS,
  [NetworkTypes.INTERNAL.link]: NetworkTypes.INTERNAL,
  [NetworkTypes.PODS.link]: NetworkTypes.PODS,
}

NetworkTypes.SPECIAL_NETWORK_IDS = Object.keys(NetworkTypes.idToType).map((networkTypeId) => parseInt(networkTypeId))

// Customer type networks are all the special networks except for "all networks"
NetworkTypes.NETWORK_GROUP_TYPE_IDS = NetworkTypes.SPECIAL_NETWORK_IDS.filter(
  (networkId) => NetworkTypes.idToType[networkId].isNetworkGroupType
)

NetworkTypes.SPECIAL_NETWORK_LINKS = Object.values(NetworkTypes.idToType).map((network) => network.link)

NetworkTypes.SPECIAL_NETWORK_CODES = Object.values(NetworkTypes.idToType).map((network) => network.code)

export default NetworkTypes
