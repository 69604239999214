import React from "react"
import { getAllURLParameters, isNullOrUndefined } from "../../utils/funcUtils"

class QueryParametersPasser extends React.Component {
  constructor() {
    super()

    if (!isNullOrUndefined(window.opener)) {
      // redirect parent window to the same page with additional querystring params from popup.
      let redirectData = {
        redirectData: getAllURLParameters(),
      }

      window.opener.postMessage(redirectData, window.opener.origin)

      // closing the current popup
      window.close()
    }
  }

  render() {
    return <div></div>
  }
}

export default QueryParametersPasser
