import Highcharts from "highcharts/highstock"
import merge from "deepmerge"
import HC_more from "highcharts/highcharts-more"
import dumbbell from "highcharts/modules/dumbbell"
import { insertAfter } from "./domUtils"

HC_more(Highcharts)
dumbbell(Highcharts)

export function generateChart(chartId, moreOptions) {
  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  })

  let chartObject = {
    chart: {
      height: 267,
      type: "line",
      plotBorderColor: "#efefef",
      plotBorderWidth: 2,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        day: "%b %e",
      },
      labels: {
        style: {
          color: "#999999",
          fontSize: "10px",
        },
      },
      tickWidth: 0,
      lineWidth: 0,
      minorTickLength: 0,
    },
    tooltip: {
      shared: true,
      style: {
        color: "#666666",
        fontSize: "13px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.14)",
      },
      backgroundColor: "white",
      borderWidth: 0,
      borderRadius: 6,
      headerFormat: '<span style="font-size: 10px;color:#999999">{point.key}</span><br/>',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {point.y} {series.name}<br/>',
    },
    legend: {
      layout: "vertical",
      navigation: {
        animation: true,
        arrowSize: 12,
        enabled: false,
        style: undefined,
      },
      align: "right",
      verticalAlign: "top",
      floating: false,
      y: -12,
      padding: 15,
      itemStyle: {
        color: "#666666",
        fontSize: "12px",
        fontWeight: "400",
      },
      itemHiddenStyle: {
        color: "#666666",
      },
      itemMarginBottom: 2,
      symbolWidth: 0,
      symbolHeight: 0,
      itemCheckboxStyle: {
        width: "12px",
        height: "12px",
      },
    },
  }

  chartObject = merge(chartObject, moreOptions)
  return Highcharts.chart(chartId, chartObject)
}

export function generateChartSeries(
  title,
  axisName,
  visible,
  moreOptions,
  { onLegendItemClickCallback = null, showCheckBox = true } = {}
) {
  let series = {
    name: title,
    events: {
      show: function (event) {
        if (event.target && event.target.checkbox && !event.target.checkbox.checked) {
          event.target.select()
          onLegendItemClick(event, onLegendItemClickCallback)
        }
      },
      hide: function (event) {
        if (event.target && event.target.checkbox && event.target.checkbox.checked) {
          event.target.select()
          onLegendItemClick(event, onLegendItemClickCallback)
        }
      },
      checkboxClick: function (event) {
        if (event.checked === true) {
          event.target.show()
        } else {
          event.target.hide()
        }
      },
    },
    yAxis: axisName,
    visible: visible,
    selected: visible,
    showCheckbox: showCheckBox,
    marker: {
      enabled: false,
    },
  }

  return Object.assign({}, series, moreOptions)
}

export function positionCheckboxes() {
  Highcharts.wrap(Highcharts.Legend.prototype, "positionCheckboxes", function (p, scrollOffset) {
    let alignAttr = this.group.alignAttr,
      translateY,
      clipHeight = this.clipHeight || this.legendHeight

    if (alignAttr) {
      translateY = alignAttr.translateY
      Highcharts.each(this.allItems, function (item) {
        let checkbox = item.checkbox,
          bBox = item.legendItem.getBBox(true),
          top

        if (checkbox) {
          top = translateY + checkbox.y + (scrollOffset || 0) + 3

          let leftStyle = alignAttr.translateX + item.checkboxOffset + checkbox.x - 40 - bBox.width + "px"
          let topStyle = top + 1 + "px"

          Highcharts.css(checkbox, {
            left: leftStyle,
            top: topStyle,
            display: top > translateY - 6 && top < translateY + clipHeight - 6 ? "" : "none",
          })

          // For every checkbox, we're adding a span that is inserted immediately after it that simulates a custom
          // color and checkmark style.
          let customCheckboxStyleElement = document.createElement("span")
          customCheckboxStyleElement.classList.add("custom-checkbox-style")
          customCheckboxStyleElement.style.left = leftStyle
          customCheckboxStyleElement.style.top = topStyle
          customCheckboxStyleElement.style.backgroundColor = item.color
          customCheckboxStyleElement.style.borderColor = item.color
          customCheckboxStyleElement.innerHTML = "✔"
          insertAfter(customCheckboxStyleElement, checkbox)
        }
      })
    }
  })
}

export function addRoundedCornerProps() {
  Highcharts.wrap(Highcharts.seriesTypes.column.prototype, "translate", function (proceed) {
    const options = this.options
    const topMargin = options.topMargin || 0
    const bottomMargin = options.bottomMargin || 0

    proceed.call(this)

    Highcharts.each(this.points, function (point) {
      if (
        options.borderRadiusTopLeft ||
        options.borderRadiusTopRight ||
        options.borderRadiusBottomRight ||
        options.borderRadiusBottomLeft
      ) {
        const w = point.shapeArgs.width
        const h = point.shapeArgs.height
        const x = point.shapeArgs.x
        const y = point.shapeArgs.y

        let radiusTopLeft = Highcharts.relativeLength(options.borderRadiusTopLeft || 0, w)
        let radiusTopRight = Highcharts.relativeLength(options.borderRadiusTopRight || 0, w)
        let radiusBottomRight = Highcharts.relativeLength(options.borderRadiusBottomRight || 0, w)
        let radiusBottomLeft = Highcharts.relativeLength(options.borderRadiusBottomLeft || 0, w)

        const maxR = Math.min(w, h) / 2

        radiusTopLeft = radiusTopLeft > maxR ? maxR : radiusTopLeft
        radiusTopRight = radiusTopRight > maxR ? maxR : radiusTopRight
        radiusBottomRight = radiusBottomRight > maxR ? maxR : radiusBottomRight
        radiusBottomLeft = radiusBottomLeft > maxR ? maxR : radiusBottomLeft

        point.dlBox = point.shapeArgs

        point.shapeType = "path"
        point.shapeArgs = {
          d: [
            "M",
            x + radiusTopLeft,
            y + topMargin,
            "L",
            x + w - radiusTopRight,
            y + topMargin,
            "C",
            x + w - radiusTopRight / 2,
            y,
            x + w,
            y + radiusTopRight / 2,
            x + w,
            y + radiusTopRight,
            "L",
            x + w,
            y + h - radiusBottomRight,
            "C",
            x + w,
            y + h - radiusBottomRight / 2,
            x + w - radiusBottomRight / 2,
            y + h,
            x + w - radiusBottomRight,
            y + h + bottomMargin,
            "L",
            x + radiusBottomLeft,
            y + h + bottomMargin,
            "C",
            x + radiusBottomLeft / 2,
            y + h,
            x,
            y + h - radiusBottomLeft / 2,
            x,
            y + h - radiusBottomLeft,
            "L",
            x,
            y + radiusTopLeft,
            "C",
            x,
            y + radiusTopLeft / 2,
            x + radiusTopLeft / 2,
            y,
            x + radiusTopLeft,
            y,
            "Z",
          ],
        }
      }
    })
  })
}

// Adding new design properties to highchart's column chart in order to allow rounded columns on specific corners,
// and doing it once for the entire application
addRoundedCornerProps()

export function chartAxisStyleUpdater(chart, callback) {
  let newLabelProps = { style: { color: "#999999", fontSize: "10px", whiteSpace: "nowrap" } }

  chart.yAxis.forEach((line) => {
    let currentLabelProps = line.userOptions.labels
    if (currentLabelProps) {
      line.update({ labels: merge(currentLabelProps, newLabelProps) })
    }
  })

  if (typeof callback === "function") {
    callback()
  }
}

let selectionIndex = 2

export function onLegendItemClick(event, onLegendItemClickCallback) {
  let chart = event.target.chart
  let currYAxis = chart.yAxis.filter((yAxisItem) => {
    if (yAxisItem.userOptions.id === event.target.yAxis.userOptions.id) {
      return yAxisItem
    }
  })[0]

  if (event.target.visible) {
    currYAxis.update({ visible: true, opposite: false, selectionIndex: ++selectionIndex })
  }

  let visibleYAxesBySelectionOrder = chart.yAxis
    .filter((yAxisItem) => {
      if (yAxisItem.series[0].visible) {
        return yAxisItem
      }
    })
    .sort((a, b) => b.userOptions.selectionIndex - a.userOptions.selectionIndex)

  if (visibleYAxesBySelectionOrder.length > 0) {
    // Setting current to not visible
    if (visibleYAxesBySelectionOrder.length === 1) {
      visibleYAxesBySelectionOrder[0].update({ visible: true, opposite: false })
      visibleYAxesBySelectionOrder[0].update({
        labels: merge(visibleYAxesBySelectionOrder[0].userOptions.labels, {
          align: "left",
          x: 3,
          y: -3,
        }),
      })
    } else {
      visibleYAxesBySelectionOrder[0].update({ visible: true, opposite: false })
      visibleYAxesBySelectionOrder[0].update({
        labels: merge(visibleYAxesBySelectionOrder[0].userOptions.labels, {
          align: "left",
          x: 3,
          y: -3,
        }),
      })
      visibleYAxesBySelectionOrder[1].update({ visible: true, opposite: true })
      visibleYAxesBySelectionOrder[1].update({
        labels: merge(visibleYAxesBySelectionOrder[1].userOptions.labels, {
          align: "right",
          x: -3,
          y: -3,
        }),
      })

      if (visibleYAxesBySelectionOrder.length >= 2) {
        for (let i = 2; i < visibleYAxesBySelectionOrder.length; i++) {
          visibleYAxesBySelectionOrder[i].update({ visible: false })
        }
      }
    }

    chartAxisStyleUpdater(chart, onLegendItemClickCallback)
  }
}
