import React from "react"
import { TextField } from "@material-ui/core"

export default class CampaignName extends React.Component {
  render() {
    let isFieldDisabled = !this.props.campaignDetailsState.siteId

    return (
      <div
        className={
          "text-field campaign-name-input " +
          (this.props.campaignNameValidationErrors ? "error " : "") +
          (isFieldDisabled ? "disabled " : "") +
          (this.props.campaignNameValidationWarnings && !this.props.campaignNameValidationErrors ? "warning" : "")
        }
      >
        <TextField
          label="Campaign name"
          InputProps={{
            className: "text-field-line",
          }}
          InputLabelProps={{
            classes: { shrink: "text-field-color" },
          }}
          placeholder="Campaign name"
          disabled={isFieldDisabled}
          value={this.props.campaignDetailsState.campaignName}
          onChange={(event) => this.props.handleChange("campaignName", event)}
          margin="normal"
        />
        {this.props.campaignNameValidationErrors}
        {this.props.campaignNameValidationWarnings}
      </div>
    )
  }
}
