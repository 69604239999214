import React from "react"
import PlusIcon from "../../resources/svgs/PlusIcon.svg"
import { isNullOrUndefined } from "../../utils/funcUtils"

export default class CreativeCreationButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedSourcesWithoutVideos: [],
    }
  }

  componentWillMount() {
    if (this.props.sources) {
      this.setState({
        selectedSourcesWithoutVideos: Object.keys(this.props.sources).filter((sourceKey, index) => {
          return this.props.sources[sourceKey].selected
        }),
      })
    }
  }

  render() {
    return (
      <div
        className={"add-creative clickable " + (!isNullOrUndefined(this.props.groupIndex) ? "small" : "")}
        onClick={() => this.props.addCreative(this.props.groupIndex)}
      >
        <span>
          <PlusIcon className="plus-icon" />
        </span>
      </div>
    )
  }
}
