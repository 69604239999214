import React from "react"
import { connect } from "react-redux"
import { Chip } from "@material-ui/core"
import { Close, KeyboardArrowDown } from "@material-ui/icons"
import GenericDropdown from "../../../../common/components/genericDropdown"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import Consts, { MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE } from "../../../../app/consts"
import {
  notificationAdd,
  validateMaxSelectedTagsPerCampaign,
  validateMaxTagsPerCampaign,
  validateTagText,
} from "../../../../common/actions/commonActions"

class TagsDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      anchorEl: null,
      selectedIndexesArr: [],
      selected: false,
    }
  }

  reselectSelectedTags = () => {
    if (this.props.networkTags) {
      let selectedTags = this.props.campaignTargetingGroup.selectedTags.map((tag) => tag.name)
      let selectedIndexesArr = []

      this.props.networkTags.forEach((networkTag, index) => {
        if (selectedTags.includes(networkTag.name)) {
          selectedIndexesArr.push(index)
        }
      })

      this.setState({
        selectedIndexesArr,
      })
    }
  }

  componentDidMount() {
    this.reselectSelectedTags()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.siteId !== prevProps.siteId &&
      this.props.siteId &&
      this.props.networkTags !== prevProps.networkTags
    ) {
      this.setState({
        selectedIndexesArr: [],
      })
    } else if (
      JSON.stringify(prevProps.campaignTargetingGroup.selectedTags) !==
      JSON.stringify(this.props.campaignTargetingGroup.selectedTags)
    ) {
      this.reselectSelectedTags()
    }
  }

  openDropdown = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      selected: true,
    })
  }

  closeDropdown = () => {
    this.setState({ open: false })
  }

  createTag = (tagName) => {
    tagName = tagName.trim()

    if (tagName.length > 0) {
      // Validations
      if (validateTagText(tagName)) {
        return
      }

      if (validateMaxSelectedTagsPerCampaign(this.props.campaignTargetingGroup.selectedTags)) {
        this.props.dispatch(notificationAdd(MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE))
        return
      }

      this.closeDropdown()
      let isSelectedTag =
        this.props.campaignTargetingGroup.selectedTags.filter((tag) => {
          return tag.name === tagName
        }).length > 0

      // Check if the tag was already selected
      if (!isSelectedTag) {
        let selectedIndex = null

        // Check if the tag is unique in the current network
        this.props.networkTags.forEach((tag, index) => {
          if (tag.name === tagName) {
            selectedIndex = index
          }
        })

        if (selectedIndex !== null) {
          if (!this.state.selectedIndexesArr.includes(selectedIndex)) {
            // If this tag already exists in the current network - select the right checkbox
            this.onOptionSelected([...this.state.selectedIndexesArr, selectedIndex])
          }
        } else {
          let selectedTags = [...this.props.campaignTargetingGroup.selectedTags, { name: tagName, isNew: true }]

          if (this.props.onChange) {
            this.props.onChange({ selectedTags })
          } else {
            this.props.dispatch(updateTargetingGroup(this.props.groupIndex, { selectedTags }))
          }
        }
      }
    }
  }

  onOptionSelected = (selectedIndexesArr) => {
    let newTags = this.props.campaignTargetingGroup.selectedTags.filter((tag) => {
      return tag.isNew
    })
    let selectedTags = []
    selectedIndexesArr.forEach((selectedIndex) => {
      let selectedTag = this.props.networkTags.find((tag, index) => {
        return selectedIndex === index
      })

      if (selectedTag) {
        selectedTags.push(selectedTag)
      }
    })

    if (validateMaxTagsPerCampaign([...selectedTags, ...newTags])) {
      this.props.dispatch(notificationAdd(MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE))
      return
    }

    this.setState({ selectedIndexesArr })

    if (this.props.onChange) {
      this.props.onChange({ selectedTags: [...selectedTags, ...newTags] })
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, { selectedTags: [...selectedTags, ...newTags] }))
    }
  }

  removeSelectedTag = (tagName) => {
    let selectedTags = this.props.campaignTargetingGroup.selectedTags.filter((selectedTag) => {
      return selectedTag.name !== tagName
    })

    let selectedIndexesArr = this.state.selectedIndexesArr.filter((index) => {
      return this.props.networkTags[index].name !== tagName
    })

    this.setState({ selectedIndexesArr })

    if (this.props.onChange) {
      this.props.onChange({ selectedTags })
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, { selectedTags }))
    }
  }

  clearAllTags = () => {
    this.setState({ selectedIndexesArr: [] })

    if (this.props.onChange) {
      this.props.onChange({ selectedTags: [] })
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, { selectedTags: [] }))
    }
  }

  render() {
    let chips = []
    this.props.campaignTargetingGroup.selectedTags.forEach((tag) => {
      chips.push(
        <Chip
          classes={{ root: "chip-item" }}
          label={tag.name}
          key={tag.name}
          deleteIcon={<Close className="delete-icon" />}
          onDelete={() => {
            this.removeSelectedTag(tag.name)
          }}
        />
      )
    })
    return (
      <div className="tags-wrapper tags-selection">
        <GenericDropdown
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          header="Tags"
          allowMultiple
          allowAutoSuggest
          minItemsForAutosuggest={0}
          autoSuggestPlaceholder="Search tags or create new..."
          onEnterPressCallback={this.createTag}
          openDropdownCallback={this.openDropdown}
          closeDropdownCallback={this.closeDropdown}
          selectOptionCallback={this.onOptionSelected}
          options={this.props.networkTags}
          selectedIndexes={this.state.selectedIndexesArr}
          buttonClassName={"tags-button" + (this.state.selected ? " selected" : "")}
          headerClassName={
            "tags-header" + (this.props.campaignTargetingGroup.selectedTags.length > 0 ? " selected" : "")
          }
          menuClassName="tags-menu"
          allowScrollPaging
          itemsPerPage={Consts.TAGS_ITEMS_PER_PAGE}
          searchErrorCallback={validateTagText}
        />
        <KeyboardArrowDown className="arrow" />
        <div className="tags-chips-container chips-container flex-wrap d-flex">
          {chips}
          {chips.length > 0 ? (
            <div className="clear-tags clickable d-flex align-items-center" onClick={this.clearAllTags}>
              Remove all tags
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    networkTags: ownProps.networkTags ? ownProps.networkTags : state.campaignCreationWizard.networkTags,
    siteId: state.campaignCreationWizard.campaignSettings.siteId,
  }
}

export default connect(mapStateToProps)(TagsDropdown)
