import React from "react"
import { connect } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import CampaignPopupPerformanceByDeviceChart from "./charts/performanceByDeviceChart"
import CampaignPopupPerformanceByDeviceList from "./lists/performanceByDeviceList"
import {
  campaignPopupLoading,
  fetchCampaignPerformanceByDeviceAsync,
  resetCampaignPopupTable,
} from "../campaignPopupActions"
import RequestsService from "../../api/requestsService"

class CampaignPopupPerformanceByDevice extends React.Component {
  componentDidMount() {
    this.getPerformanceByDevice()
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      JSON.stringify(prevProps.datePickerDates.startDate) !== JSON.stringify(this.props.datePickerDates.startDate) ||
      JSON.stringify(prevProps.datePickerDates.endDate) !== JSON.stringify(this.props.datePickerDates.endDate)
    ) {
      this.getPerformanceByDevice()
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetCampaignPopupTable("performanceByDevice"))
    RequestsService.cancelRequest("performance-by-device-request")
  }

  getPerformanceByDevice = () => {
    RequestsService.cancelRequest("performance-by-device-request")
    this.props.dispatch(campaignPopupLoading())
    this.props.dispatch(
      fetchCampaignPerformanceByDeviceAsync(
        this.props.campaign,
        this.props.datePickerDates.startDate,
        this.props.datePickerDates.endDate
      )
    )
  }

  render() {
    let loadingIndicator = null
    let countriesChartData = []
    let performanceByDevice = null

    if (this.props.isLoading) {
      loadingIndicator = (
        <div className="campaign-popup-loading d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      countriesChartData = this.props.performanceByDevice.slice(
        (this.props.currentPage - 1) * this.props.itemsPerPage,
        this.props.currentPage * this.props.itemsPerPage
      )

      performanceByDevice = (
        <div className="campaign-popup-performance-by-device">
          <CampaignPopupPerformanceByDeviceChart data={countriesChartData} campaign={this.props.campaign} />
          <CampaignPopupPerformanceByDeviceList />
        </div>
      )
    }

    return (
      <>
        {loadingIndicator}
        {performanceByDevice}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    isLoading: state.campaignPopupV2.isLoading,
    performanceByDevice: state.campaignPopupV2.performanceByDevice.dataForTable,
    itemsPerPage: state.campaignPopupV2.performanceByDevice.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceByDevice.currentPage,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByDevice)
