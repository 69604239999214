import Specification from "./Specification"
import { FilterItemSpec } from "./filterItemSpec"
import { FalseSpec, TrueSpec } from "./generalSpecs"

export class FilterCategorySpec extends Specification {
  constructor(filter) {
    super()
    let incluseSpec = filter.includeFilters.length === 0 ? new TrueSpec() : new FalseSpec()
    let excluseSpec = new TrueSpec()

    filter.includeFilters.forEach((filterItem) => {
      incluseSpec = incluseSpec.or(new FilterItemSpec(filterItem))
    })

    filter.excludeFilters.forEach((filterItem) => {
      excluseSpec = excluseSpec.and(new FilterItemSpec(filterItem).not())
    })

    this.spec = incluseSpec.and(excluseSpec)
  }

  isSatisfied(candidate) {
    return this.spec.isSatisfied(candidate)
  }
}
