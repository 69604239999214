let CampaignsCreationConsts = {
  CREATION_WIZARD_STEPS: {
    sources: 1,
    targeting: 2,
    creatives: 3,
    launch: 4,
  },

  CREATION_WIZARD_STEP_NAMES: {
    sources: "Sources",
    targeting: "Targeting",
    creatives: "Creatives",
    launch: "Launch",
  },

  OUTBRAIN_THUMBNAIL_BASE_WIDTH: 1200,
  OUTBRAIN_THUMBNAIL_BASE_HEIGHT: 800,

  FACEBOOK_THUMBNAIL_LANDSCAPE_BASE_WIDTH: 580,
  FACEBOOK_THUMBNAIL_LANDSCAPE_BASE_HEIGHT: 280,
  FACEBOOK_THUMBNAIL_SQUARE_BASE_WIDTH: 320,
  FACEBOOK_THUMBNAIL_SQUARE_BASE_HEIGHT: 320,

  FACEBOOK_THUMBNAIL_VIDEO: 1080,

  FACEBOOK_POSITIONS: [
    "facebook_positions",
    "instagram_positions",
    "audience_network_positions",
    "messenger_positions",
  ],
  FACEBOOK_PLACEMENTS: [
    {
      key: "facebook_positions",
      position: "facebook_positions",
      name: "Facebook positions",
      platform: "facebook",
      isTitle: true,
      isGroupTitle: true,
    },
    {
      key: "feed",
      name: "Facebook News Feed",
      platform: "facebook",
      position: "facebook_positions",
      isVisible: false,
    },
    {
      key: "right_hand_column",
      name: "Facebook Right Column (Desktop)",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "instant_article",
      name: "Facebook Instant Articles (Mobile)",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "marketplace",
      name: "Facebook Marketplace",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "video_feeds",
      name: "Facebook Video Feeds (Mobile)",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "story",
      name: "Facebook Stories (Mobile)",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "search",
      name: "Facebook Search Results (Mobile Only)",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "instream_video",
      name: "Facebook In-Stream Videos",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "groups_feed",
      name: "Facebook Groups Feed (Mobile)",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "facebook_reels",
      name: "Facebook Reels (Mobile)",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "facebook_reels_overlay",
      name: "Facebook Overlay Ads In Reels (Mobile)",
      platform: "facebook",
      position: "facebook_positions",
    },
    {
      key: "instagram_positions",
      position: "instagram_positions",
      name: "Instagram positions",
      platform: "instagram",
      isTitle: true,
      isGroupTitle: true,
    },
    {
      key: "stream",
      name: "Instagram Feed (Mobile)",
      platform: "instagram",
      position: "instagram_positions",
    },
    {
      key: "story",
      name: "Instagram Stories (Mobile)",
      platform: "instagram",
      position: "instagram_positions",
    },
    {
      key: "explore",
      name: "Instagram Explore (Mobile)",
      platform: "instagram",
      position: "instagram_positions",
    },
    {
      key: "reels",
      name: "Instagram Reels (Mobile)",
      platform: "instagram",
      position: "instagram_positions",
    },
    {
      key: "audience_network_positions",
      position: "audience_network_positions",
      name: "Audience network positions",
      platform: "audience_network",
      isTitle: true,
      isGroupTitle: true,
    },
    {
      key: "classic",
      name: "Audience Network Native, Banner and Interstitial (Mobile)",
      platform: "audience_network",
      position: "audience_network_positions",
    },
    {
      key: "instream_video",
      name: "Audience Network In-Stream Videos",
      platform: "audience_network",
      position: "audience_network_positions",
    },
    {
      key: "rewarded_video",
      name: "Audience Network Rewarded Videos (Mobile)",
      platform: "audience_network",
      position: "audience_network_positions",
    },
    {
      key: "messenger_positions",
      position: "messenger_positions",
      name: "Messenger positions",
      platform: "messenger",
      isTitle: true,
      isGroupTitle: true,
    },
    {
      key: "messenger_home",
      name: "Messenger Inbox (Mobile)",
      platform: "messenger",
      position: "messenger_positions",
    },
    {
      key: "story",
      name: "Messenger Stories (Mobile)",
      platform: "messenger",
      position: "messenger_positions",
    },
  ],

  TABOOLA_THUMBNAIL_BASE_WIDTH: 600,
  TABOOLA_THUMBNAIL_BASE_HEIGHT: 400,

  MIN_NUMBER_OF_ITEMS_WITH_AUTOSUGGEST: 5,
  MIN_NUMBER_OF_ITEMS_FOR_SELECT_ALL: 2,

  TABOOLA_AUDIENCE_PARTNERS_ERROR_MESSAGE:
    "There was a problem fetching audience partners for this campaign, please try again",
  CHECK_DUPLICATE_TRACKING_CODES_ERROR_MESSAGE:
    "There was a problem while checking duplicate tracking codes, please try again",

  GOOGLE_ADS_THUMBNAIL_LANDSCAPE_BASE_WIDTH: 600,
  GOOGLE_ADS_THUMBNAIL_LANDSCAPE_BASE_HEIGHT: 314,

  GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_WIDTH: 300,
  GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_HEIGHT: 300,

  GOOGLE_ADS_THUMBNAIL_PORTRAIT_BASE_WIDTH: 480,
  GOOGLE_ADS_THUMBNAIL_PORTRAIT_BASE_HEIGHT: 600,

  PINTEREST_THUMBNAIL_BASE_WIDTH: 1000,
  PINTEREST_THUMBNAIL_BASE_HEIGHT: 1000,
  PINTEREST_THUMBNAIL_RECOMMEND_HEIGHT: 1500,

  DISPLAY_THUMBNAIL_DIMENSIONS: [
    { width: 300, height: 600 },
    { width: 728, height: 90 },
    { width: 300, height: 250 },
    { width: 320, height: 100 },
  ],
}

CampaignsCreationConsts.CROP_RATIOS = {
  Outbrain: {
    default: {
      title: "",
      minWidth: CampaignsCreationConsts.OUTBRAIN_THUMBNAIL_BASE_WIDTH,
      minHeight: CampaignsCreationConsts.OUTBRAIN_THUMBNAIL_BASE_HEIGHT,
    },
  },
  Facebook: {
    landscape: {
      title: "1.91:1",
      minWidth: CampaignsCreationConsts.FACEBOOK_THUMBNAIL_LANDSCAPE_BASE_WIDTH,
      minHeight: CampaignsCreationConsts.FACEBOOK_THUMBNAIL_LANDSCAPE_BASE_HEIGHT,
    },
    square: {
      title: "1:1",
      minWidth: CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_WIDTH,
      minHeight: CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_HEIGHT,
    },
  },
  Taboola: {
    landscape: {
      title: "16:9",
      minWidth: CampaignsCreationConsts.TABOOLA_THUMBNAIL_BASE_WIDTH,
      minHeight: CampaignsCreationConsts.TABOOLA_THUMBNAIL_BASE_HEIGHT,
    },
  },
  GoogleAds: {
    landscape: {
      title: "1.91:1",
      minWidth: CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_LANDSCAPE_BASE_WIDTH,
      minHeight: CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_LANDSCAPE_BASE_HEIGHT,
      isDiscoveryOnly: false,
    },
    square: {
      title: "1:1",
      minWidth: CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_WIDTH,
      minHeight: CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_HEIGHT,
      isDiscoveryOnly: false,
    },
    portrait: {
      title: "4:5",
      minWidth: CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_PORTRAIT_BASE_WIDTH,
      minHeight: CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_PORTRAIT_BASE_HEIGHT,
      isDiscoveryOnly: true,
    },
  },
  Pinterest: {
    square: {
      title: "1:1",
      minWidth: CampaignsCreationConsts.PINTEREST_THUMBNAIL_BASE_WIDTH,
      minHeight: CampaignsCreationConsts.PINTEREST_THUMBNAIL_BASE_HEIGHT,
    },
    landscape: {
      title: "2:3",
      minWidth: CampaignsCreationConsts.PINTEREST_THUMBNAIL_BASE_WIDTH,
      minHeight: CampaignsCreationConsts.PINTEREST_THUMBNAIL_RECOMMEND_HEIGHT,
    },
  },
}

CampaignsCreationConsts.FACEBOOK_POSITION_KEY_TO_POSITION = CampaignsCreationConsts.FACEBOOK_PLACEMENTS.reduce(
  (acc, curr) => {
    acc[`${curr.position}-${curr.key}`] = curr
    return acc
  },
  {}
)

Object.freeze(CampaignsCreationConsts)
export default CampaignsCreationConsts
