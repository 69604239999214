import axios from "axios"
import UserService from "../../api/userService"
import CountryService from "../../api/countryService"
import SiteService from "../../api/siteService"
import ProviderService from "../../api/providerService"
import CampaignServiceV2 from "../../api/campaignServiceV2"
import {
  createInitialResourcesLoaded,
  customInterfacesLoadedV2,
  savedFiltersLoaded,
  mixpanelIdentifyUser,
} from "../../common/actions/commonActions"
import ModuleTypes from "../../common/consts/moduleTypes"
import { objToMap } from "../../utils/funcUtils"
import LanguageService from "../../api/languageService"
import { serverUpdatesDispatcher } from "../../api/serverUpdatesDispatcher"
import SavedFiltersService from "../../api/savedFiltersService"
import AuthService from "../../api/authService"

export function fetchInitialResources() {
  return (dispatch) => {
    return axios
      .all([
        UserService.getCurrentuser(),
        CountryService.getCountries(),
        LanguageService.getLanguages(),
        SiteService.getAll(),
        SavedFiltersService.getSavedFilters(),
        CampaignServiceV2.getConversionEvents(),
        ProviderService.getProviders(),
        CountryService.getCountry_groups(),
      ])
      .then(
        axios.spread(function (
          user,
          countries,
          languages,
          sites,
          savedFilters,
          conversionEvents,
          providersList,
          countryGroups
        ) {
          // Enabling third-party plugins (intercom, datadog, mixpanel)
          window.Intercom("shutdown")
          window.Intercom("boot", {
            app_id: "pffh16sk",
            name: user.data.display_name,
            email: user.data.email,
            user_hash: user.data.intercom_user_hash,
          })

          window.intercomSettings = {
            pubplus_user_id: user.data.id,
            networks: Array.from(new Set(sites.data.map((site) => site.network.name))).toString(),
            roles: Array.from(new Set(user.data.user_roles.map((role) => role.role.name))).toString(),
          }

          mixpanelIdentifyUser(user.data.id)

          // Add user information to the Datadog RUM Session
          window.DD_RUM &&
            window.DD_RUM.setUser({
              id: user.data.id,
              name: user.data.display_name,
              email: user.data.email,
            })

          // now dispatch the result.
          dispatch(
            createInitialResourcesLoaded(
              user.data,
              countries.data,
              languages.data,
              sites.data,
              conversionEvents.data,
              providersList.data,
              countryGroups.data
            )
          )

          // user interface V2
          if (AuthService.hasModule(ModuleTypes.CAMPAIGN_MANAGEMENT)) {
            UserService.getCustomInterfaceV2().then((result) => {
              let customInterfacesV2 = objToMap(result.data, ["success"])
              dispatch(customInterfacesLoadedV2(customInterfacesV2))
            })
          }

          dispatch(savedFiltersLoaded(savedFilters.data.saved_filters))

          serverUpdatesDispatcher.connect()
        })
      )
  }
}
