class CampaignCreationActionTypes {}
CampaignCreationActionTypes.OPEN_CAMPAIGN_CREATION_WIZARD = "OPEN_CAMPAIGN_CREATION_WIZARD"
CampaignCreationActionTypes.CLOSE_CAMPAIGN_CREATION_WIZARD = "CLOSE_CAMPAIGN_CREATION_WIZARD"
CampaignCreationActionTypes.CHANGE_WIZARD_STEP = "CHANGE_WIZARD_STEP"
CampaignCreationActionTypes.ADD_CREATIVE = "ADD_CREATIVE"
CampaignCreationActionTypes.ADD_CREATIVES_GROUP = "ADD_CREATIVES_GROUP"
CampaignCreationActionTypes.UPDATE_THUMBNAIL_RESPONSE = "UPDATE_THUMBNAIL_RESPONSE"
CampaignCreationActionTypes.UPDATE_DYNAMIC_THUMBNAIL_RESPONSE = "UPDATE_DYNAMIC_THUMBNAIL_RESPONSE"
CampaignCreationActionTypes.UPDATE_THUMBNAIL_PER_SOURCE_RESPONSE = "UPDATE_THUMBNAIL_PER_SOURCE_RESPONSE"
CampaignCreationActionTypes.UPDATE_THUMBNAIL_PER_RATIO_RESPONSE = "UPDATE_THUMBNAIL_PER_RATIO_RESPONSE"
CampaignCreationActionTypes.VALIDATE_THUMBNAIL = "VALIDATE_THUMBNAIL"
CampaignCreationActionTypes.VALIDATE_DYNAMIC_THUMBNAIL = "VALIDATE_DYNAMIC_THUMBNAIL"
CampaignCreationActionTypes.LOAD_THUMBNAILS = "LOAD_THUMBNAILS"
CampaignCreationActionTypes.REMOVE_THUMBNAIL = "REMOVE_THUMBNAIL"
CampaignCreationActionTypes.REMOVE_DYNAMIC_THUMBNAIL = "REMOVE_DYNAMIC_THUMBNAIL"
CampaignCreationActionTypes.UPDATE_CREATIVE = "UPDATE_CREATIVE"
CampaignCreationActionTypes.UPDATE_DYNAMIC_CREATIVE = "UPDATE_DYNAMIC_CREATIVE"
CampaignCreationActionTypes.REMOVE_CREATIVE = "REMOVE_CREATIVE"
CampaignCreationActionTypes.CLONE_CREATIVE = "CLONE_CREATIVE"
CampaignCreationActionTypes.GET_CREATIVES = "GET_CREATIVES"
CampaignCreationActionTypes.GET_CREATIVES_RESPONSE = "GET_CREATIVES_RESPONSE"
CampaignCreationActionTypes.UPDATE_SOURCE = "UPDATE_SOURCE"
CampaignCreationActionTypes.UPDATE_STATUS = "UPDATE_STATUS"
CampaignCreationActionTypes.UPDATE_CAMPAIGN_DETAILS = "UPDATE_CAMPAIGN_DETAILS"
CampaignCreationActionTypes.LAUNCHING_CAMPAIGNS = "LAUNCHING_CAMPAIGNS"
CampaignCreationActionTypes.UPDATE_THUMBNAIL_IS_LOADING = "UPDATE_THUMBNAIL_IS_LOADING"
CampaignCreationActionTypes.UPDATE_DYNAMIC_THUMBNAIL_IS_LOADING = "UPDATE_DYNAMIC_THUMBNAIL_IS_LOADING"
CampaignCreationActionTypes.UPDATE_RECENT_COUNTRIES = "UPDATE_RECENT_COUNTRIES"
CampaignCreationActionTypes.UPDATE_RECENT_LANGUAGES = "UPDATE_RECENT_LANGUAGES"
CampaignCreationActionTypes.OPEN_WIZARD_WITH_STATE = "OPEN_WIZARD_WITH_STATE"
CampaignCreationActionTypes.UPDATE_SITE_PROVIDER_ACCOUNTS = "UPDATE_SITE_PROVIDER_ACCOUNTS"
CampaignCreationActionTypes.RESET_SITE_PROVIDER_ACCOUNTS = "RESET_SITE_PROVIDER_ACCOUNTS"
CampaignCreationActionTypes.UPDATE_SELECTED_TAGS = "UPDATE_SELECTED_TAGS"
CampaignCreationActionTypes.UPDATE_DUPLICATE_REVIEW_WARNING_STATE = "UPDATE_DUPLICATE_REVIEW_WARNING_STATE"
CampaignCreationActionTypes.UPDATE_TARGETING_GROUP = "UPDATE_TARGETING_GROUP"
CampaignCreationActionTypes.RESET_TARGETING_GROUPS = "RESET_TARGETING_GROUPS"
CampaignCreationActionTypes.ADD_TARGETING_GROUP = "ADD_TARGETING_GROUP"
CampaignCreationActionTypes.TOGGLE_TARGETING_GROUP_OPEN = "TOGGLE_TARGETING_GROUP_OPEN"
CampaignCreationActionTypes.REMOVE_TARGETING_GROUP = "REMOVE_TARGETING_GROUP"
CampaignCreationActionTypes.REMOVE_CREATIVES_GROUP = "REMOVE_CREATIVES_GROUP"
CampaignCreationActionTypes.DUPLICATE_TARGETING_GROUP = "DUPLICATE_TARGETING_GROUP"
CampaignCreationActionTypes.DUPLICATE_CREATIVES_GROUP = "DUPLICATE_CREATIVES_GROUP"
CampaignCreationActionTypes.TOGGLE_CREATIVES_GROUP_OPEN = "TOGGLE_CREATIVES_GROUP_OPEN"

export default CampaignCreationActionTypes
