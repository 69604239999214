import React from "react"
import { alpha2ToAlpha3, alpha3ToAlpha2, getName, registerLocale } from "i18n-iso-countries"
import enCountries from "i18n-iso-countries/langs/en.json"
import { isNullOrEmpty } from "./funcUtils"
import Consts from "../app/consts"

// Our system uses 'United States' instead of 'United States of America' so we override it in the locale json
// We're also using 'Great Britain' instead of 'United Kingdom'
// All other country locale overrides should also be here
enCountries.countries.US = "United States"
enCountries.countries.GB = "Great Britain"

// Registering the countries locale
registerLocale(enCountries)

export function getCountryOrGroupIcon(countries, countryGroupName, countryGroupCode) {
  let countryFlag = null
  let tooltipText = null
  if (isNullOrEmpty(countries) && isNullOrEmpty(countryGroupCode)) {
    countryFlag = "ww"
    tooltipText = "World Wide"
  } else {
    if (isNullOrEmpty(countryGroupCode)) {
      countryFlag = countries.toString()
      tooltipText = getCountryTextV2(countryFlag).replaceAll(",", ", ")
    } else {
      countryFlag = "country-group"
      tooltipText = countryGroupName
    }
    // in case that we have a list of countries we want to show it in the tooltip.
    if (isNullOrEmpty(countryGroupCode) && !isNullOrEmpty(countries) && countries.length > 1) {
      countryFlag = "ww"
    }
  }

  return (
    <div
      data-class="tooltip-custom type-icon-info shrink-tip tooltip-text-align-center"
      className={"flag flag-" + countryFlag.toLowerCase()}
      data-tip={tooltipText}
    >
      {isNullOrEmpty(countryGroupCode) ? null : <span>{countryGroupCode}</span>}
    </div>
  )
}
export function getCountryTextV2(countryCode) {
  if (isNullOrEmpty(countryCode)) return Consts.WORLDWIDE_COUNTRY_NAME
  if (countryCode.length > 1 && typeof countryCode !== "string") return Consts.MULTIPLE_COUNTRY_NAME
  let countryText = getName(countryCode.toString(), "en")
  if (isNullOrEmpty(countryText)) {
    // Don't know this country, return the code
    return countryCode
  }
  return countryText
}

export function getCountryText(countryCode) {
  let countryText

  if (isNullOrEmpty(countryCode)) {
    countryText = "World Wide"
  } else {
    countryText = getName(countryCode, "en")
    if (isNullOrEmpty(countryText)) {
      // Don't know this country, return the code
      return countryCode
    }
  }

  return countryText
}

export function getCountryCode(countryName) {
  // Searching the countries object key by the value
  let countryAlpha2Code = Object.keys(enCountries.countries).find((key) =>
    enCountries.countries[key].startsWith(countryName)
  )
  if (isNullOrEmpty(countryAlpha2Code)) {
    // Don't know this country, return the name
    return countryName
  }

  return alpha2ToAlpha3(countryAlpha2Code)
}

export function isWorldWideCountry(countryCode, campaignStatus) {
  // If it's untracked campaign and its country code is empty - It's a worldwide campaign
  return isNullOrEmpty(countryCode) && campaignStatus !== 5
}
