class PlatformTypes {
  static DESKTOP = { name: "Desktop", value: "desktop" }
  static MOBILE = { name: "Mobile", value: "mobile" }
  static TABLET = { name: "Tablet", value: "tablet" }
  static UNKNOWN = { name: "Unknown", value: "unknown" }
  static MULTIPLE_DEVICES = { name: "Multiple devices", value: "multiple_devices" }
  static ANDROID_TABLET = { name: "Android Tablet", value: "android_tablet" }
  static ANDROID_MOBILE = { name: "Android Mobile", value: "android_mobile" }
  static IPAD = { name: "iPad", value: "ipad" }
  static IPHONE = { name: "iPhone", value: "iphone" }

  static getValueToNameMapping() {
    const mapping = {}
    for (const key in this) {
      if (this[key].value) {
        mapping[this[key].value] = this[key].name
      }
    }
    return mapping
  }
}

PlatformTypes.deviceToName = PlatformTypes.getValueToNameMapping()
PlatformTypes.iosPlatforms = [PlatformTypes.IPAD.value, PlatformTypes.IPHONE.value]
PlatformTypes.androidPlatforms = [PlatformTypes.ANDROID_MOBILE.value, PlatformTypes.ANDROID_TABLET.value]

PlatformTypes.platforms = new Set([
  PlatformTypes.DESKTOP.value,
  PlatformTypes.MOBILE.value,
  PlatformTypes.TABLET.value,
  PlatformTypes.UNKNOWN.value,
  PlatformTypes.MULTIPLE_DEVICES.value,
  PlatformTypes.ANDROID_TABLET.value,
  PlatformTypes.ANDROID_MOBILE.value,
  PlatformTypes.IPAD.value,
  PlatformTypes.IPHONE.value,
])

PlatformTypes.idToPlatform = {
  0: PlatformTypes.UNKNOWN.value,
  1: PlatformTypes.DESKTOP.value,
  2: PlatformTypes.MOBILE.value,
  3: PlatformTypes.TABLET.value,
}

PlatformTypes.strBidToPlatform = {
  default_mobile_bid: PlatformTypes.MOBILE.name,
  default_desktop_bid: PlatformTypes.DESKTOP.name,
  default_multi_device_bid: PlatformTypes.MULTIPLE_DEVICES.name,
}

export default PlatformTypes
