class OsTypes {}

OsTypes.sub_platforms = {
  // Desktop
  macos: {
    displayName: "MacOS",
    bulkDisplayName: "MacOS only",
    name: "macos",
  },
  windows: {
    displayName: "Windows",
    bulkDisplayName: "Windows only",
    name: "windows",
  },
  linux: {
    displayName: "Linux",
    bulkDisplayName: "Linux only",
    name: "linux",
  },
  linux_and_windows: {
    displayName: "Linux And Windows",
    name: "linux_and_windows",
  },
  all: {
    displayName: "All",
    name: "all",
  },
  web_and_android: {
    displayName: "Web & Android",
    name: "web_and_android",
  },
  ios: {
    displayName: "iOS",
    bulkDisplayName: "iOS only",
    name: "ios",
  },
  android: {
    displayName: "Android",
    bulkDisplayName: "Android only",
    name: "android",
  },
  both: {
    displayName: "Both (iOS + Android)",
    name: "both",
  },
  iPad: { displayName: "iPad", name: "ipad" },
  android_mobile: { displayName: "Android Mobile", name: "android_mobile" },
  android_tablet: { displayName: "Android Tablet", name: "android_tablet" },
  web: { displayName: "Web", name: "web" },
  web_mobile: { displayName: "Web Mobile", name: "web_mobile" },
  iphone: { displayName: "iPhone", name: "iphone" },
}

OsTypes.nameToDisplayName = {
  [OsTypes.sub_platforms.ios.name]: OsTypes.sub_platforms.ios.displayName,
  [OsTypes.sub_platforms.android.name]: OsTypes.sub_platforms.android.displayName,
  [OsTypes.sub_platforms.both.name]: OsTypes.sub_platforms.both.displayName,
  [OsTypes.sub_platforms.macos.name]: OsTypes.sub_platforms.macos.displayName,
  [OsTypes.sub_platforms.windows.name]: OsTypes.sub_platforms.windows.displayName,
  [OsTypes.sub_platforms.linux.name]: OsTypes.sub_platforms.linux.displayName,
  [OsTypes.sub_platforms.linux_and_windows.name]: OsTypes.sub_platforms.linux_and_windows.displayName,
  [OsTypes.sub_platforms.all.name]: OsTypes.sub_platforms.all.displayName,
  [OsTypes.sub_platforms.iPad.name]: OsTypes.sub_platforms.iPad.displayName,
  [OsTypes.sub_platforms.iphone.name]: OsTypes.sub_platforms.iphone.displayName,
  [OsTypes.sub_platforms.web_mobile.name]: OsTypes.sub_platforms.web_mobile.displayName,
  [OsTypes.sub_platforms.web.name]: OsTypes.sub_platforms.web.displayName,
  [OsTypes.sub_platforms.android_mobile.name]: OsTypes.sub_platforms.android_mobile.displayName,
  [OsTypes.sub_platforms.android_tablet.name]: OsTypes.sub_platforms.android_tablet.displayName,
  [OsTypes.sub_platforms.web_and_android.name]: OsTypes.sub_platforms.web_and_android.displayName,
}

OsTypes.nameToBulkDisplayName = {
  [OsTypes.sub_platforms.ios.name]: OsTypes.sub_platforms.ios.bulkDisplayName,
  [OsTypes.sub_platforms.android.name]: OsTypes.sub_platforms.android.bulkDisplayName,
  [OsTypes.sub_platforms.macos.name]: OsTypes.sub_platforms.macos.bulkDisplayName,
  [OsTypes.sub_platforms.windows.name]: OsTypes.sub_platforms.windows.bulkDisplayName,
  [OsTypes.sub_platforms.linux.name]: OsTypes.sub_platforms.linux.bulkDisplayName,
  [OsTypes.sub_platforms.web.name]: OsTypes.sub_platforms.web.displayName,
  [OsTypes.sub_platforms.iphone.name]: OsTypes.sub_platforms.iphone.displayName,
  [OsTypes.sub_platforms.web_mobile.name]: OsTypes.sub_platforms.web_mobile.displayName,
  [OsTypes.sub_platforms.android_mobile.name]: OsTypes.sub_platforms.android_mobile.displayName,
  [OsTypes.sub_platforms.android_tablet.name]: OsTypes.sub_platforms.android_tablet.displayName,
  [OsTypes.sub_platforms.iPad.name]: OsTypes.sub_platforms.iPad.displayName,
}

OsTypes.types = {
  ios: {
    name: "iOS",
  },
  android: {
    name: "Android",
  },
  mac_os: {
    name: "macOS",
  },
  windows: {
    name: "Windows",
  },
  linux: {
    name: "Linux",
  },
  ipad_os: {
    name: "iPadOS",
  },
}

OsTypes.typeToName = {}
for (const type in OsTypes.types) {
  OsTypes.typeToName[type] = OsTypes.types[type].name
}

OsTypes.typeToNameList = Object.keys(OsTypes.types).map((type) => ({
  name: OsTypes.types[type].name,
  value: type,
}))

OsTypes.multipleOs = "Multiple operating systems"

export default OsTypes
