class AutomationPopupActionTypes {}
AutomationPopupActionTypes.AUTOMATION_POPUP_OPEN = "AUTOMATION_POPUP_OPEN"
AutomationPopupActionTypes.AUTOMATION_POPUP_DATA_LOADED = "AUTOMATION_POPUP_DATA_LOADED"
AutomationPopupActionTypes.AUTOMATION_POPUP_CLOSE = "AUTOMATION_POPUP_CLOSE"
AutomationPopupActionTypes.AUTOMATION_POPUP_DATES_SELECTED = "AUTOMATION_POPUP_DATES_SELECTED"
AutomationPopupActionTypes.AUTOMATION_POPUP_FETCH_ACTION_HISTORY_RESPONSE =
  "AUTOMATION_POPUP_FETCH_ACTION_HISTORY_RESPONSE"
AutomationPopupActionTypes.AUTOMATION_POPUP_ACTION_HISTORY_SORT_BY = "AUTOMATION_POPUP_ACTION_HISTORY_SORT_BY"
AutomationPopupActionTypes.AUTOMATION_POPUP_ACTION_HISTORY_SHOW_MORE = "AUTOMATION_POPUP_ACTION_HISTORY_SHOW_MORE"
AutomationPopupActionTypes.AUTOMATION_POPUP_LOADING = "AUTOMATION_POPUP_LOADING"
AutomationPopupActionTypes.AUTOMATION_POPUP_RESET_DATES = "AUTOMATION_POPUP_RESET_DATES"

export default AutomationPopupActionTypes
