import React from "react"
import "../resources/styles/main.scss"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import { withRouter } from "react-router"
import ReactGA from "react-ga"
import NavigationBar from "../navigationBar/navigationBar"
import AdminNavigationBar from "../admin/components/adminNavigationBar"
import { fetchInitialResources } from "./actions/appActions"
import { store } from "./store"
import Notifications from "../common/components/notifications"
import CampaignPopupV2 from "../campaignPopupV2/campaignPopup"
import CampaignCreationWizard from "../campaignCreation/campaignCreationWizard"
import Footer from "../footer/footer"
import { renderAppTree } from "./index"
import { notificationAdd, appCurrentVersion } from "../common/actions/commonActions"
import AppService from "../api/appService"
import Consts from "./consts"
import { isAuthenticated } from "../utils/authUtils"
import AutomationPopup from "../automationPopup/automationPopup"
import AutomationCreation from "../automationCreation/automationCreationPopup"

const CLIENT_ERROR_MESSAGE = "Oops! Something went wrong"
const OUTDATED_VERSION_MESSAGE = "You're running an outdated version of PubPlus"
const MAX_CLIENT_ERROR_MESSAGES = 3

// app component
class App extends React.Component {
  constructor(props) {
    super(props)

    this.isLoadingInitialResources = false
  }

  checkClientVersion = (currentVersion) => {
    AppService.getClientVersion(currentVersion).then((response) => {
      // Only on this specific error message from s3 (access denied) we can identify that we have an outdated version,
      // other status codes (like network error for example) will be ignored.
      if (response && response.data) {
        let versionJson = response.data

        if (versionJson.version !== currentVersion) {
          store.dispatch(notificationAdd(OUTDATED_VERSION_MESSAGE, "app-refresh"))
          clearInterval(this.versionCheckIntervalId)
        }
      }
    })
  }

  // This is a global client error handler. It was added after we upgraded to React 16 because since that version,
  // React unmounts the entire application tree when there's a JS error, so the whole app won't be in a corrupted state.
  // We will try to re-render the application tree by ourselves, it will only depend on Redux state so it won't cause
  // any problems.
  componentDidCatch(error, info) {
    if (process.env.NODE_ENV !== "development") {
      let numOfClientErrorMessages = this.props.notifications.filter(
        (item) => item.message == CLIENT_ERROR_MESSAGE
      ).length
      if (numOfClientErrorMessages >= MAX_CLIENT_ERROR_MESSAGES) {
        console.error("REACHED MAXIMUM NUMBER OF CLIENT ERRORS, POSSIBLE INFINITE RE-RENDERING LOOP!!!")

        return
      }

      renderAppTree()
      store.dispatch(notificationAdd(CLIENT_ERROR_MESSAGE))
    }
  }

  componentDidMount() {
    if (process.env.ENV !== "dev") {
      // Extracting the src of the app.js file, this file's hash changes in every new version
      // a different hash means a different (newer) version.
      let currentVersionSrc = Array.from(document.querySelectorAll("script")).filter((script) =>
        script.src.includes("js/app")
      )[0].src
      let versionSrcParts = currentVersionSrc.split("/")
      let currentVersion = versionSrcParts[versionSrcParts.length - 1]

      this.versionCheckIntervalId = setInterval(() => {
        this.checkClientVersion(currentVersion)
      }, Consts.CLIENT_VERSION_CHECK_INTERVAL)

      // Set app current version in redux
      store.dispatch(appCurrentVersion(currentVersion))
    }

    if (process.env.ENV === "prod") {
      // Google analytics (only on production)
      ReactGA.initialize("UA-109216158-3")
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.key !== this.props.location.key &&
      nextProps.location.state &&
      (nextProps.location.state.isCampaignPopupV2 || nextProps.location.state.isAutomationPopup)
    ) {
      // At this point, we've recognized through the location that we're in a campaign popup.
      // We save the children that are currently open (meaning: the current "Page" that the router was on, it could be
      // any page that the campaign popup is accessible from) and put them in previousChildren for later usage.
      this.previousChildren = this.props.children
    }
  }

  componentWillUnmount() {
    clearInterval(this.versionCheckIntervalId)
  }

  // render
  render() {
    let navigationComponent = null
    let content = null
    let footer = null

    let isCampaignPopupV2 = Boolean(
      this.props.location.state && this.props.location.state.isCampaignPopupV2 && this.previousChildren
    )

    let isAutomationPopup = Boolean(
      this.props.location.state && this.props.location.state.isAutomationPopup && this.previousChildren
    )

    if (isAuthenticated()) {
      if (this.props.allResourceLoaded) {
        if (this.props.isAdminPage) {
          content = this.props.children
          navigationComponent = <AdminNavigationBar />
        } else {
          navigationComponent = <NavigationBar />
          content = (
            <>
              {isCampaignPopupV2 || isAutomationPopup ? this.previousChildren : this.props.children}
              <CampaignPopupV2 isOpen={isCampaignPopupV2} />
              <AutomationPopup isOpen={isAutomationPopup} />
              <CampaignCreationWizard />
              <AutomationCreation />
            </>
          )
        }

        footer = <Footer />
      } else {
        if (!this.isLoadingInitialResources) {
          this.isLoadingInitialResources = true

          store.dispatch(fetchInitialResources()).then((result) => {
            this.isLoadingInitialResources = false
          })
        }

        content = (
          <div className="page-preloader">
            <div className="preloader-preview-area">
              <div className="ball-pulse-sync">
                <div className="animation-ball" />
                <div className="animation-ball" />
                <div className="animation-ball" />
              </div>
            </div>
          </div>
        )
      }
    } else {
      content = this.props.children
    }

    return (
      <>
        <div className={"app-content" + (this.props.isAdminPage ? " dark-theme" : "")}>
          {navigationComponent}
          {content}
          <Notifications />
          <ReactTooltip type="info" html={true} effect="solid" place="top" className="tooltip-custom" />
        </div>
        {footer}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    allResourceLoaded: state.app.allResourceLoaded,
    isAdminPage: ownProps.location.pathname.startsWith("/sudo"),
    notifications: state.app.notifications,
  }
}

const connectedApp = connect(mapStateToProps)(App)
export default withRouter(connectedApp)
