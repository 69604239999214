import React from "react"
import { Button, CircularProgress } from "@material-ui/core"
import CampaignsCreationConsts from "../campaignCreationConsts"
import {
  addCreativeAction,
  cloneCreative,
  removeCreative,
  removeDynamicThumbnail,
  removeThumbnail,
  updateCreative,
  updateDynamicCreative,
  updateDynamicThumbnailAsync,
  updateDynamicThumbnailLoadingStatus,
  updateThumbnailAsync,
  updateThumbnailLoadingStatus,
  updateThumbnailPerRatioAsync,
  updateThumbnailPerSourceAsync,
  updateThumbnailWithUrl,
  addCreativesGroup,
  onImagesUploaded,
} from "../campaignCreationActions"
import CreationValidator from "../creationValidator"
import { clearNotifications } from "../../common/actions/commonActions"
import ThumbnailTypes from "../../common/consts/thumbnailTypes"
import { eventsTracker } from "../../api/eventsTracker"
import CreativeCreationButton from "./creativeCreationButton"
import CreativesList from "./creativesList"
import DynamicCreative from "./dynamic/dynamicCreative"
import { connect } from "react-redux"
import RequestsService from "../../api/requestsService"
import CreativesGroup from "./creativesGroup"
import PlusIcon from "../../resources/svgs/PlusIcon.svg"
import { isNullOrUndefined } from "../../utils/funcUtils"
import WarningIconSmall from "../../resources/svgs/WarningIconSmall.svg"

class CampaignCreationCreatives extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      validationErrorsVisible: false,
    }
  }

  validateAllCreativeThumbnails = () => {
    this.props.campaignCreatives.creatives.forEach((creative) => {
      CreationValidator.validateCreativeThumbnail(creative, creative.thumbnail)
    })
  }

  componentDidMount() {
    if (!this.isDynamicCreative() && !this.isCreativesGroup()) {
      if (this.props.campaignCreatives.creatives.length === 0) {
        this.addCreative()
      } else {
        // Re-checking all the creatives when we enter this step (the user might have selected some sources on the
        // previous state that can change the way we need to handle the image
        this.validateAllCreativeThumbnails()
      }
    } else if (this.isCreativesGroup()) {
      if (this.props.campaignCreatives.creativesGroups.length === 0) {
        this.props.dispatch(addCreativesGroup())
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Re-checking all the creatives when the user is currently on the step and all of his creatives (while duplicating
    // the campaign) are loaded into the page
    if (prevProps.campaignCreatives.isFetchingCreatives !== this.props.campaignCreatives.isFetchingCreatives) {
      this.validateAllCreativeThumbnails()
    }
  }

  moveBack = () => {
    this.props.dispatch(clearNotifications())
    this.props.onNextClickedFunc(CampaignsCreationConsts.CREATION_WIZARD_STEPS.targeting)

    eventsTracker.trackCampaignCreationStep(CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.targeting)
  }

  moveNext = () => {
    this.props.dispatch(clearNotifications())
    this.props.onNextClickedFunc(CampaignsCreationConsts.CREATION_WIZARD_STEPS.launch)
    RequestsService.cancelRequest("auto-generate-text")

    eventsTracker.trackCampaignCreationStep(CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.launch)
  }

  addCreative = (groupIndex = null) => {
    this.props.dispatch(addCreativeAction(groupIndex))
  }

  onThumbnailUpdated = (id, file, sourceKey = null, cropDetails = null, selectedRatio = null, groupIndex = null) => {
    let updateThumbnailService = () => updateThumbnailAsync(id, file, groupIndex)

    if (sourceKey && cropDetails && selectedRatio) {
      updateThumbnailService = () =>
        updateThumbnailPerSourceAsync(id, sourceKey, file, cropDetails, selectedRatio, groupIndex)
    }

    this.props.dispatch(updateThumbnailLoadingStatus(id, true, groupIndex))
    this.props.dispatch(updateThumbnailService()).then(() => {
      this.props.dispatch(updateThumbnailLoadingStatus(id, false, groupIndex))
      let creative
      if (isNullOrUndefined(groupIndex)) {
        creative = this.props.campaignCreatives.creatives.filter((c) => c.id == id)[0]
      } else {
        creative = this.props.campaignCreatives.creativesGroups[groupIndex].creatives.filter((c) => c.id == id)[0]
      }
      CreationValidator.validateCreativeThumbnail(creative, creative.thumbnail, groupIndex)
    })
  }

  onDynamicThumbnailUpdated = (
    id,
    file,
    cropDetails = null,
    selectedRatio = null,
    thumbnailTypeId = null,
    groupIndex = null
  ) => {
    let updateThumbnailService = () => updateDynamicThumbnailAsync(id, file, thumbnailTypeId, groupIndex)
    let thumbnailAttributes = null
    if (cropDetails && selectedRatio) {
      updateThumbnailService = () =>
        updateThumbnailPerRatioAsync(id, file, thumbnailTypeId, cropDetails, selectedRatio, groupIndex)
    }

    this.props.dispatch(updateDynamicThumbnailLoadingStatus(id, true, thumbnailTypeId, groupIndex))
    this.props.dispatch(updateThumbnailService()).then(() => {
      this.props.dispatch(updateDynamicThumbnailLoadingStatus(id, false, thumbnailTypeId, groupIndex))
      if (thumbnailTypeId === ThumbnailTypes.RESPONSIVE_THUMBNAIL.id) {
        thumbnailAttributes = isNullOrUndefined(groupIndex)
          ? this.props.campaignCreatives.dynamicCreative.thumbnails[id]
          : this.props.campaignCreatives.creativesGroups[groupIndex].creatives[0].thumbnails[id]
      } else if (thumbnailTypeId === ThumbnailTypes.DISPLAY_THUMBNAIL.id) {
        thumbnailAttributes = this.props.campaignCreatives.dynamicCreative.displayThumbnails[id]
      }
      CreationValidator.validateDynamicCreativeThumbnail(thumbnailAttributes, id, thumbnailTypeId, groupIndex)
    })
  }

  onUploadFunc = (acceptedFiles, thumbnailTypeId, selectedSource, groupIndex = null) => {
    let thumbnailAttributes = null
    let ids = []
    let allThumbnailsIds = []
    acceptedFiles.forEach((file) => {
      ids = []
      this.props.campaignCreatives.dynamicCreative.thumbnails.forEach((thumbnail, index) => {
        if (
          isNullOrUndefined(thumbnail.thumbnail) &&
          ids.length < Object.keys(selectedSource.cropDetails).length &&
          !allThumbnailsIds.includes(index) // prevent uploading the same location image twice
        ) {
          ids.push(index)
          this.props.dispatch(updateDynamicThumbnailLoadingStatus(index, true, thumbnailTypeId, groupIndex))
        }
      })
      allThumbnailsIds = allThumbnailsIds.concat(ids)
      this.props.dispatch(onImagesUploaded(file, thumbnailTypeId, selectedSource, ids, groupIndex)).then(() => {
        allThumbnailsIds.forEach((id) => {
          if (thumbnailTypeId === ThumbnailTypes.RESPONSIVE_THUMBNAIL.id) {
            thumbnailAttributes = isNullOrUndefined(groupIndex)
              ? this.props.campaignCreatives.dynamicCreative.thumbnails[id]
              : this.props.campaignCreatives.creativesGroups[groupIndex].creatives[0].thumbnails[id]
          } else if (thumbnailTypeId === ThumbnailTypes.DISPLAY_THUMBNAIL.id) {
            thumbnailAttributes = this.props.campaignCreatives.dynamicCreative.displayThumbnails[id]
          }
          CreationValidator.validateDynamicCreativeThumbnail(thumbnailAttributes, id, thumbnailTypeId, groupIndex)
        })
      })
    })
  }

  onThumbnailRemoved = (id, groupIndex = null) => {
    this.props.dispatch(removeThumbnail(id, groupIndex))
  }

  onDynamicThumbnailRemoved = (id, thumbnailTypeId, groupIndex = null) => {
    this.props.dispatch(removeDynamicThumbnail(id, thumbnailTypeId, groupIndex))
  }

  onCreativeUpdated = (id, text, description, headline, callToAction, groupIndex = null) => {
    this.props.dispatch(updateCreative(id, text, description, headline, callToAction, groupIndex))
  }

  onDynamicCreativeUpdated = (id, fieldType, text, groupIndex = null) => {
    this.props.dispatch(updateDynamicCreative(id, fieldType, text, groupIndex))
  }

  onDynamicCreativeGroupsUpdated = (id, fieldType, text, groupIndex = null) => {
    this.props.dispatch(updateDynamicCreative(id, fieldType, text, groupIndex))
  }

  onCreativeRemoved = (id, groupIndex = null) => {
    this.props.dispatch(removeCreative(id, groupIndex))
  }

  onCreativeCloned = (id, groupIndex = null) => {
    this.props.dispatch(cloneCreative(id, groupIndex))
  }

  getValidationErrors = () => {
    if (this.isCreativesGroup()) {
      // validation for dynamic or regular creatives groups
      return CreationValidator.validateCreativesGroupsStep(this.props.campaignCreatives, this.props.campaignSources)
    } else if (this.isDynamicCreative()) {
      return CreationValidator.isDynamicCreativeValid(
        this.props.campaignCreatives.dynamicCreative,
        this.props.campaignSources
      )
    } else {
      return CreationValidator.validateCreativesStep(this.props.campaignCreatives, this.props.campaignSources)
    }
  }

  handleNextClick = () => {
    this.props.dispatch(clearNotifications())
    this.setState(
      {
        validationErrorsVisible: true,
      },
      () => {
        let validationErrors = this.getValidationErrors()

        if (validationErrors.size === 0) {
          this.moveNext()
        }
      }
    )
  }

  isDynamicCreative() {
    //  add here option for dynamic creative for discovery
    return this.props.sourceData.isDynamicCreative === true
  }

  isDynamicCreativesGroup() {
    return this.props.sourceData.adTypes.filter((adType) => adType.selected)[0]?.isDynamicCreative
  }

  isCreativesGroup() {
    return this.props.sourceData.isCreativesGroup === true
  }

  renderAddCreativeGroupButton = () => {
    return (
      <div className="add-creative selection clickable add-creative-group">
        <span>
          <PlusIcon className="plus-icon" />
        </span>
        <div className="ad-type-selection" onClick={() => this.props.dispatch(addCreativesGroup())}>
          Add new group
        </div>
      </div>
    )
  }

  generateValidationError = (validationErrors, field) => {
    let error = validationErrors.get(field)

    if (!this.state.validationErrorsVisible) {
      return null
    }

    if (error) {
      return (
        <div className="validation-error">
          <WarningIconSmall />
          <span className="text">{validationErrors.get(field)}</span>
        </div>
      )
    }

    return null
  }

  render() {
    let creativesContent
    if (this.isCreativesGroup()) {
      let creativesGroups = []
      let validationErrors = this.getValidationErrors()
      const isCanBeDeleted = this.props.campaignCreatives.creativesGroups.length > 1
      this.props.campaignCreatives.creativesGroups.forEach((creativeGroup, index) => {
        let creativesGroupValidationErrors = validationErrors.get(`creativesGroup${index}`)
        creativesGroups.push(
          <CreativesGroup
            key={index}
            creativesGroup={creativeGroup}
            groupIndex={index}
            validationErrors={creativesGroupValidationErrors}
            validationErrorsVisible={this.state.validationErrorsVisible}
            campaignSources={this.props.campaignSources}
            onCreativeUpdated={this.onCreativeUpdated}
            onCreativeRemoved={this.onCreativeRemoved}
            onCreativeCloned={this.onCreativeCloned}
            onThumbnailUpdated={this.onThumbnailUpdated}
            onThumbnailRemoved={this.onThumbnailRemoved}
            updateThumbnailWithUrl={updateThumbnailWithUrl}
            updateThumbnailLoadingStatus={updateThumbnailLoadingStatus}
            addCreative={this.addCreative}
            allowThumbnailCrop
            isCanBeDeleted={isCanBeDeleted}
            isDynamicCreative={this.isDynamicCreativesGroup()}
            creativeUpdateFunc={this.onDynamicCreativeUpdated}
            thumbnailUpdateFunc={this.onDynamicThumbnailUpdated}
            thumbnailRemoveFunc={this.onDynamicThumbnailRemoved}
          />
        )
      })
      let creativesGroupsValidationErrors = this.generateValidationError(validationErrors, "creativesGroups")
      creativesContent = (
        <div className="creatives-list">
          {creativesGroups}
          {this.renderAddCreativeGroupButton()}
          {creativesGroupsValidationErrors}
        </div>
      )
    } else if (this.isDynamicCreative()) {
      let dynamicCreative = this.props.campaignCreatives.dynamicCreative
      creativesContent = (
        <div className="creatives-list dynamic-creatives-list">
          <DynamicCreative
            dynamicCreative={dynamicCreative}
            key={"dynamicCreative"}
            validationErrorsVisible={this.state.validationErrorsVisible}
            creativeUpdateFunc={this.onDynamicCreativeUpdated}
            thumbnailUpdateFunc={this.onDynamicThumbnailUpdated}
            onUploadFunc={this.onUploadFunc}
            thumbnailRemoveFunc={this.onDynamicThumbnailRemoved}
            updateThumbnailLoadingStatus={updateDynamicThumbnailLoadingStatus}
            allowThumbnailCrop
            sourceData={this.props.sourceData}
            campaignSources={this.props.campaignSources}
            groupIndex={null}
          />
        </div>
      )
    } else {
      creativesContent = (
        <div>
          <div className="creatives-list ">
            <CreativesList
              creatives={this.props.campaignCreatives.creatives}
              campaignSources={this.props.campaignSources}
              validationErrorsVisible={this.state.validationErrorsVisible}
              onCreativeUpdated={this.onCreativeUpdated}
              onCreativeRemoved={this.onCreativeRemoved}
              onCreativeCloned={this.onCreativeCloned}
              onThumbnailUpdated={this.onThumbnailUpdated}
              onThumbnailRemoved={this.onThumbnailRemoved}
              updateThumbnailWithUrl={updateThumbnailWithUrl}
              updateThumbnailLoadingStatus={updateThumbnailLoadingStatus}
              allowThumbnailCrop
            />
          </div>

          <CreativeCreationButton addCreative={this.addCreative} sources={this.props.campaignSources.sources} />
        </div>
      )
    }

    if (this.props.campaignCreatives.isFetchingCreatives) {
      creativesContent = (
        <div className="creatives-loading d-flex justify-content-center align-items-center">
          <CircularProgress size={40} />
        </div>
      )
    }

    return (
      <div className={"" + (this.isCreativesGroup() ? "campaign-creation-targeting creatives-groups" : "")}>
        <div className="step-title">Add creative variations</div>
        {creativesContent}

        <div className="creation-wizard-navigation">
          <Button variant="contained" color="primary" className="round-button gray back-button" onClick={this.moveBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="round-button green next-button"
            disabled={this.props.campaignCreatives.isFetchingCreatives}
            onClick={this.handleNextClick}
          >
            Next
          </Button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let sourceData = {}
  let selectedSourceKey = null

  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
    if (state.campaignCreationWizard.campaignSources.sources[sourceKey].selected) {
      selectedSourceKey = sourceKey
      sourceData = state.campaignCreationWizard.campaignSources.sources[sourceKey]
    }
  })

  return {
    campaignSettings: state.campaignCreationWizard.campaignSettings,
    campaignTargetingGroups: state.campaignCreationWizard.campaignTargetingGroups,
    campaignCreatives: state.campaignCreationWizard.campaignCreatives,
    campaignSources: state.campaignCreationWizard.campaignSources,
    wasDuplicateReviewWarningShown: state.campaignCreationWizard.wasDuplicateReviewWarningShown,
    sourceKey: selectedSourceKey,
    sourceData,
  }
}

export default connect(mapStateToProps)(CampaignCreationCreatives)
