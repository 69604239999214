import axios from "axios"
import Consts from "../app/consts"
import RequestsService from "./requestsService"

export default class AutomationService {
  static getNetworkAutomations(networkCode) {
    return axios.get(Consts.BASE_API_URL + "/api/automations/" + networkCode + "/rules", {
      cancelToken: RequestsService.cancelToken("automations-list-request"),
    })
  }

  static getSingleAutomation(ruleId) {
    return axios.get(Consts.BASE_API_URL + "/api/automations/rule/" + ruleId + "/data", {
      cancelToken: RequestsService.cancelToken("automation-data-request"),
    })
  }

  static getAutomationActionHistory(ruleId, fromDate, toDate) {
    return axios.get(Consts.BASE_API_URL + "/api/automations/rule/" + ruleId + "/actions", {
      cancelToken: RequestsService.cancelToken("action-history-request"),
      params: {
        from_date: fromDate.format(Consts.SERVER_DATE_FORMAT),
        to_date: toDate.format(Consts.SERVER_DATE_FORMAT),
      },
    })
  }

  static pauseAutomation(ruleId) {
    return axios.put(Consts.BASE_API_URL + "/api/automations/rule/" + ruleId + "/pause")
  }

  static activateAutomation(ruleId, networkCode) {
    return axios.put(Consts.BASE_API_URL + "/api/automations/rule/" + ruleId + "/activate", {
      network_code: networkCode,
    })
  }

  static createAutomation(networkCode, creationData) {
    return axios.post(Consts.BASE_API_URL + "/api/automations/" + networkCode + "/rule", {
      rule_creation_data: creationData,
    })
  }

  static editAutomation(ruleId, editData) {
    return axios.put(Consts.BASE_API_URL + "/api/automations/rule/" + ruleId + "/edit", {
      edit_data: editData,
    })
  }
}
