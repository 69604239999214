import React from "react"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import Moment from "moment"
import { DayPickerRangeController, isInclusivelyBeforeDay, DayPickerSingleDateController } from "react-dates"
import Presets from "../../consts/presets"
import { deserializeDateFilterPreset } from "../../../utils/funcUtils"
import OptionsDropdownMenu from "./menus/optionsDropdownMenu"
import FilterTypes from "../../consts/filterTypesV2"

const DATEPICKER_INPUT_DATE_FORMAT = "DD/MM/YYYY"
const START_DATE = "startDate"
const END_DATE = "endDate"

class FilterDatePicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this.getInitialDatesState(this.props),
    }
  }

  componentDidMount() {
    this.props.updatePositionFunction()
  }

  componentDidUpdate(prevProps) {
    // reset the state to be aline with the global state...
    // Should remove all this and use only the global state...
    if (
      this.props.startDate !== prevProps.startDate &&
      (Presets.FILTER_PRESETS.includes(prevProps.startDate) ||
        prevProps.filter.filterOperator === FilterTypes.filterOperators.NOT_EXIST)
    ) {
      this.setState(this.getInitialDatesState(this.props))
    }
  }

  getInitialDatesState = (props) => {
    let values = deserializeDateFilterPreset(props.startDate, props.endDate)
    if (!values.firstValue._isAMomentObject) values.firstValue = Moment().startOf("day")
    if (!values.secondValue._isAMomentObject) values.secondValue = Moment().startOf("day")
    return {
      startDate: values.firstValue,
      endDate: values.secondValue,
      startDateInput: values.firstValue.format(DATEPICKER_INPUT_DATE_FORMAT),
      endDateInput: values.secondValue.format(DATEPICKER_INPUT_DATE_FORMAT),
      focusedInput: START_DATE,
      datePreset: Presets.FILTER_PRESETS.includes(props.startDate) ? props.startDate : Presets.CUSTOM,
    }
  }

  isDayOutsideRange = (day) => {
    let today = Moment()

    return !isInclusivelyBeforeDay(day, today)
  }

  onDatepickerInputChange = (event, field) => {
    let value = event.target.value
    let newDate = Moment(value, DATEPICKER_INPUT_DATE_FORMAT)

    if (!newDate.isValid() || newDate > Moment() || newDate < Moment().subtract("3", "years")) {
      newDate = this.state[field]
    }

    let updatedState = {
      [field]: newDate,
      [field + "Input"]: newDate.format(DATEPICKER_INPUT_DATE_FORMAT),
    }

    if (field === "endDate" && newDate < this.state.startDate) {
      updatedState.startDate = newDate
      updatedState.startDateInput = newDate.format(DATEPICKER_INPUT_DATE_FORMAT)
    } else if (field === "startDate" && newDate > this.state.endDate) {
      updatedState.endDate = newDate
      updatedState.endDateInput = newDate.format(DATEPICKER_INPUT_DATE_FORMAT)
    }

    this.setState(updatedState, () => this.props.onDateChange(this.state.startDate, this.state.endDate))
  }

  handleInputChange = (event, field) => {
    this.setState({
      [field]: event.target.value,
    })
  }

  blurOnEnterKey = (event) => {
    if (event.key === "Enter") {
      event.target.blur()
    }
  }

  generateCalendarAddons = () => {
    return (
      <div>
        <div className="datepicker-top-inputs d-flex align-items-center visible">
          <input
            className="start-date-input"
            value={this.state.startDateInput}
            onBlur={(event) => this.onDatepickerInputChange(event, "startDate")}
            onChange={(event) => this.handleInputChange(event, "startDateInput")}
            onKeyPress={this.blurOnEnterKey}
          />
          {!this.props.isSingleValue ? (
            <input
              className="end-date-input"
              value={this.state.endDateInput}
              onBlur={(event) => this.onDatepickerInputChange(event, "endDate")}
              onChange={(event) => this.handleInputChange(event, "endDateInput")}
              onKeyPress={this.blurOnEnterKey}
            />
          ) : null}
        </div>
      </div>
    )
  }

  onDatesChange = (datesObj) => {
    let state = this.state

    // This is part of the "resetting" logic which means that on every odd click, the selection will always be startDate
    state.startDate = datesObj.startDate
    if (state.focusedInput === "startDate") {
      state.endDate = datesObj.endDate
    } else {
      state.endDate = state.startDate
    }

    state.startDateInput = state.startDate.format(DATEPICKER_INPUT_DATE_FORMAT)
    state.endDateInput = state.endDate.format(DATEPICKER_INPUT_DATE_FORMAT)
    this.setState({ state })
    this.props.onDateChange(state.startDate, state.endDate)
  }

  onDateChange = (date) => {
    let state = this.state

    state.startDate = date
    state.startDateInput = state.startDate.format(DATEPICKER_INPUT_DATE_FORMAT)

    this.setState({ state })
    this.props.onDateChange(date, Moment())
  }

  onFocusChange = () => {
    let state = this.state
    state.focusedInput = state.focusedInput === START_DATE ? END_DATE : START_DATE
    this.setState({ state })
  }

  onPresetSelected = (preset) => {
    this.setState({ datePreset: preset }, () => {
      if (preset !== Presets.CUSTOM) {
        this.props.onPresetSelected(preset)
      }
    })
  }

  getPresetsMenu = () => {
    let presets

    if (
      [FilterTypes.filterOperators.BETWEEN, FilterTypes.filterOperators.NOT_BETWEEN].includes(
        this.props.filter.filterOperator
      )
    ) {
      presets = [...Presets.RANGE_DATES_PRESETS, Presets.CUSTOM]
    } else if (this.props.filter.filterOperator === FilterTypes.filterOperators.NOT_EXIST) {
      presets = [Presets.CUSTOM]
    } else {
      presets = [...Presets.SINGLE_DATE_PRESETS, Presets.CUSTOM]
    }

    return (
      <OptionsDropdownMenu
        currentValue={this.state.datePreset}
        options={presets.map((preset) => {
          return {
            name: Presets.PRESETS.get(preset).text,
            value: preset,
          }
        })}
        selectionCallback={(newIndex) => {
          this.onPresetSelected(presets[newIndex])
        }}
      />
    )
  }

  render() {
    let item = null

    if (
      this.state.datePreset === Presets.CUSTOM &&
      this.props.filter.filterOperator !== FilterTypes.filterOperators.NOT_EXIST
    ) {
      if (this.props.isSingleValue) {
        item = (
          <DayPickerSingleDateController
            date={this.state.startDate}
            onDateChange={this.onDateChange}
            onFocusChange={this.onFocusChange}
            renderCalendarInfo={() => this.generateCalendarAddons()}
            isOutsideRange={(day) => this.isDayOutsideRange(day)}
            daySize={27}
            numberOfMonths={1}
            initialVisibleMonth={() => Moment()}
            navNext={<KeyboardArrowRight />}
            navPrev={<KeyboardArrowLeft />}
            hideKeyboardShortcutsPanel
            noBorder
          />
        )
      } else {
        item = (
          <DayPickerRangeController
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={this.onDatesChange}
            focusedInput={this.state.focusedInput}
            onFocusChange={this.onFocusChange}
            renderCalendarInfo={() => this.generateCalendarAddons()}
            isOutsideRange={(day) => this.isDayOutsideRange(day)}
            minimumNights={0}
            daySize={27}
            numberOfMonths={1}
            initialVisibleMonth={() => Moment()}
            navNext={<KeyboardArrowRight />}
            navPrev={<KeyboardArrowLeft />}
            hideKeyboardShortcutsPanel
            noBorder
          />
        )
      }
    }

    return (
      <div key="date-picker" className="datepicker-wrapper">
        {this.getPresetsMenu()}
        {item}
      </div>
    )
  }
}

export default FilterDatePicker
