class AdvancedSearchActionTypes {}
AdvancedSearchActionTypes.IS_DRAWER_OPEN = "IS_DRAWER_OPEN"
AdvancedSearchActionTypes.SET_TEMP_FILTERS = "SET_TEMP_FILTERS"
AdvancedSearchActionTypes.SET_ADVANCED_SEARCH_VIEW = "SET_ADVANCED_SEARCH_VIEW"
AdvancedSearchActionTypes.GET_ADVANCED_SEARCH_REPORT_RESPONSE = "GET_ADVANCED_SEARCH_REPORT_RESPONSE"
AdvancedSearchActionTypes.SET_ADVANCED_SEARCH_CREATORS = "SET_ADVANCED_SEARCH_CREATORS"
AdvancedSearchActionTypes.ADVANCED_SEARCH_CAMPAIGN_MARK_SELECTED = "ADVANCED_SEARCH_CAMPAIGN_MARK_SELECTED"
AdvancedSearchActionTypes.ADVANCED_SEARCH_CAMPAIGN_MARK_ALL_SELECTED = "ADVANCED_SEARCH_CAMPAIGN_MARK_ALL_SELECTED"
AdvancedSearchActionTypes.ADVANCED_SEARCH_RESET_PAGE = "ADVANCED_SEARCH_RESET_PAGE"
export default AdvancedSearchActionTypes
