import React from "react"
import Consts from "../app/consts"
import CampaignsConsts from "../campaignsV2/campaignsConsts"
import { genericCompareFunc, isNullOrUndefined } from "../utils/funcUtils"
import { generateCampaignKey, overrideCampaignAttributes } from "../utils/campaignUtilsV2"
import CampaignPopupActionTypes from "./campaignPopupActionTypes"
import CampaignActionTypes from "../campaignsV2/campaignActionTypes"
import ActionTypes from "../common/actions/actionTypes"
import TableConsts from "../common/components/table/tableConsts"
import { getCountryTextV2 } from "../utils/countryUtils"
import { getEndDate, getStartDate, CUSTOM, SEVEN_DAYS } from "../common/components/datePickerV2"
import SubSourceActionTypes from "../subSources/subSourceActionTypes"
import PlatformTypes from "../common/consts/platformTypes"

const campaignsPopupInitialState = {
  campaign: null,
  userConfirmationBeforeLeaving: false,
  datePickerDates: {
    text: "7 Days",
    startDate: getStartDate(SEVEN_DAYS),
    endDate: getEndDate(SEVEN_DAYS),
    showLastHour: true,
    selected: true,
    dateType: SEVEN_DAYS,
  },
  section: "Main",
  networkTags: [],
  mainSubSection: "Day",
  performanceByDay: {
    dataForChart: [],
    dataForTable: [],
    sortBy: CampaignsConsts.LIST_COLUMNS.date.fieldName,
    sortDirection: TableConsts.DESC,
    summaryRow: null,
    hasData: false,
    currentPage: 1,
    itemsPerPage: 30,
  },
  performanceByHour: {
    dataForChart: [],
    dataForTable: [],
    sortBy: CampaignsConsts.LIST_COLUMNS.time.fieldName,
    sortDirection: TableConsts.DESC,
    summaryRow: null,
    hasData: false,
  },
  performanceBySubSource: {
    dataForTable: [],
    hasData: false,
    currentPage: 1,
    sortDirection: TableConsts.DESC,
    sortBy: CampaignsConsts.LIST_COLUMNS.data_columns.visits.fieldName,
    itemsPerPage: 10,
    summaryRow: null,
  },
  performanceByCountry: {
    dataForTable: [],
    hasData: false,
    currentPage: 1,
    sortDirection: TableConsts.DESC,
    sortBy: CampaignsConsts.LIST_COLUMNS.data_columns.visits.fieldName,
    itemsPerPage: 10,
    summaryRow: null,
  },
  performanceByItem: {
    dataForTable: [],
    hasData: false,
    currentPage: 1,
    sortDirection: TableConsts.DESC,
    sortBy: CampaignsConsts.LIST_COLUMNS.impressions.fieldName,
    itemsPerPage: 10,
    summaryRow: null,
  },
  performanceByFunnel: {
    dataForTable: [],
    hasData: false,
    currentPage: 1,
    sortDirection: TableConsts.DESC,
    sortBy: CampaignsConsts.LIST_COLUMNS.unique_search_impressions.fieldName,
    itemsPerPage: 10,
    summaryRow: null,
  },
  performanceByKeyword: {
    totelPerDay: {},
    dataForTable: {},
    hasData: false,
    currentPage: 1,
    sortDirection: TableConsts.DESC,
    sortBy: CampaignsConsts.LIST_COLUMNS.keyword.fieldName,
    itemsPerPage: 10,
    summaryRow: null,
  },
  performanceByDevice: {
    dataForTable: [],
    hasData: false,
    currentPage: 1,
    sortDirection: TableConsts.DESC,
    sortBy: CampaignsConsts.LIST_COLUMNS.data_columns.visits.fieldName,
    itemsPerPage: 10,
    summaryRow: null,
  },
  performanceByBrowser: {
    dataForTable: [],
    hasData: false,
    currentPage: 1,
    sortDirection: TableConsts.DESC,
    sortBy: CampaignsConsts.LIST_COLUMNS.data_columns.visits.fieldName,
    itemsPerPage: 10,
    summaryRow: null,
  },
  bidHistory: {
    dataForChart: [],
    dataForTable: [],
    sortBy: CampaignsConsts.LIST_COLUMNS.time.fieldName,
    sortDirection: TableConsts.DESC,
    itemsPerPage: 30,
    currentPage: 1,
    hasData: false,
  },
  isLoading: false,
}

function campaignPopupReducerV2(state = campaignsPopupInitialState, action) {
  if (state == undefined || action == undefined) {
    return []
  }

  let calcSummaryRow = (campaigns) => {
    let summaryRow = {}
    let totals = {
      visits: 0,
      daily_budget: 0,
      total_budget: 0,
      page_views: 0,
      results: 0,
      ctr: 0,
      clicks: 0,
      roi: 0,
      daily_spent: 0,
      revenue: 0,
      cost_per_click: 0,
      visit_value: 0,
      profit: 0,
      pages_per_visit: 0,
      bounce_rate: 0,
      bounces: 0,
      impressions: 0,
      avg_bid: 0,
      rpm: 0,
      results_rate: 0,
      visit_duration: 0,
      ecom_yielding_clicks: 0,
      revenue_ecom: 0,
      affiliate_rpc: 0,
      affiliate_item_rpm: 0,
      affiliate_orders: 0,
      affiliate_order_rate: 0,
      rsoc_yielding_clicks: 0,
      revenue_rsoc: 0,
      rpc: 0,
      click_outs: 0,
      click_out_rate: 0,
      ads_revenue: 0,
      website_impressions: 0,
      keyword_impressions: 0,
      keyword_impression_rate: 0,
      searches_rate: 0,
      searches_funnel_rpm: 0,
      searches: 0,
      orders: 0,
      item_rpm: 0,
      click_value: 0, // item click value for the performance by item popup
      estimated_revenue: 0,
      average_order_fee: 0,
      order_rate: 0,
    }

    campaigns.forEach((campaign) => {
      totals.visits += isNaN(campaign.visits) ? 0 : campaign.visits
      totals.clicks += isNaN(campaign.clicks) ? 0 : campaign.clicks
      totals.revenue += isNaN(campaign.revenue) ? 0 : campaign.revenue
      totals.daily_budget += campaign.daily_budget
      totals.total_budget += campaign.total_budget
      totals.impressions += isNaN(campaign.impressions) ? 0 : campaign.impressions
      totals.daily_spent += isNaN(campaign.daily_spent) ? 0 : campaign.daily_spent
      totals.page_views += isNaN(campaign.page_views) ? 0 : campaign.page_views
      totals.results += isNaN(campaign.results) ? 0 : campaign.results
      totals.bounces += isNaN(campaign.bounces) ? 0 : campaign.bounces
      totals.visit_duration += isNaN(campaign.visit_duration) ? 0 : campaign.visit_duration
      totals.ecom_yielding_clicks += isNaN(campaign.ecom_yielding_clicks) ? 0 : campaign.ecom_yielding_clicks
      totals.affiliate_orders += isNaN(campaign.affiliate_orders) ? 0 : campaign.affiliate_orders
      totals.revenue_ecom += isNaN(campaign.revenue_ecom) ? 0 : campaign.revenue_ecom
      totals.rsoc_yielding_clicks += isNaN(campaign.rsoc_yielding_clicks) ? 0 : campaign.rsoc_yielding_clicks
      totals.revenue_rsoc += isNaN(campaign.revenue_rsoc) ? 0 : campaign.revenue_rsoc
      totals.click_outs += isNaN(campaign.click_outs) ? 0 : campaign.click_outs
      totals.click_out_rate += isNaN(campaign.click_out_rate) ? 0 : campaign.click_out_rate
      totals.ads_revenue += isNaN(campaign.ads_revenue) ? 0 : campaign.ads_revenue
      totals.website_impressions += isNaN(campaign.website_impressions) ? 0 : campaign.website_impressions
      totals.keyword_impressions += isNaN(campaign.keyword_impressions) ? 0 : campaign.keyword_impressions
      totals.keyword_impression_rate += isNaN(campaign.keyword_impression_rate) ? 0 : campaign.keyword_impression_rate
      totals.searches_rate += isNaN(campaign.searches_rate) ? 0 : campaign.searches_rate
      totals.searches_funnel_rpm += isNaN(campaign.searches_funnel_rpm) ? 0 : campaign.searches_funnel_rpm
      totals.searches += isNaN(campaign.searches) ? 0 : campaign.searches
      totals.orders += isNaN(campaign.orders) ? 0 : campaign.orders
      totals.estimated_revenue += isNaN(campaign.estimated_revenue)
        ? 0
        : campaign.estimated_revenue
      totals.average_order_fee += isNaN(campaign.average_order_fee) ? 0 : campaign.average_order_fee
    })

    summaryRow.visits = totals.visits
    summaryRow.clicks = totals.clicks
    summaryRow.revenue = totals.revenue
    summaryRow.page_views = totals.page_views
    summaryRow.daily_spent = totals.daily_spent
    summaryRow.roi = isFinite(totals.revenue / totals.daily_spent) ? totals.revenue / totals.daily_spent : 0
    summaryRow.profit = totals.revenue - totals.daily_spent
    summaryRow.cost_per_click = isFinite(totals.daily_spent / totals.clicks) ? totals.daily_spent / totals.clicks : 0
    summaryRow.visit_cost = totals.daily_spent / totals.visits
    summaryRow.visit_value = isFinite(totals.revenue / totals.visits) ? totals.revenue / totals.visits : 0
    summaryRow.pages_per_visit = isFinite(totals.page_views / totals.visits) ? totals.page_views / totals.visits : 0
    summaryRow.ctr = totals.clicks / totals.impressions
    summaryRow.results = totals.results
    summaryRow.cost_per_result = isFinite(totals.daily_spent / totals.results) ? totals.daily_spent / totals.results : 0
    summaryRow.bounce_rate = isFinite(totals.bounces / totals.visits) ? totals.bounces / totals.visits : 0
    summaryRow.daily_budget = totals.daily_budget
    summaryRow.total_budget = totals.total_budget
    summaryRow.rpm = isFinite(totals.ads_revenue / totals.website_impressions)
      ? (totals.ads_revenue / totals.website_impressions) * 1000
      : 0
    summaryRow.results_rate = isFinite(totals.results / totals.clicks) ? totals.results / totals.clicks : 0
    summaryRow.visit_duration = totals.visit_duration
    summaryRow.ecom_yielding_clicks = totals.ecom_yielding_clicks
    summaryRow.affiliate_orders = totals.affiliate_orders,
    summaryRow.affiliate_rpc = isFinite(totals.revenue_ecom / totals.ecom_yielding_clicks) ? totals.revenue_ecom / totals.ecom_yielding_clicks : 0
    summaryRow.affiliate_item_rpm = isFinite(totals.revenue_ecom * 1000.0 / totals.page_views) ? totals.revenue_ecom * 1000.0 / totals.page_views : 0
    summaryRow.affiliate_order_rate = isFinite(totals.affiliate_orders / totals.ecom_yielding_clicks) ? totals.affiliate_orders / totals.ecom_yielding_clicks : 0
    summaryRow.revenue_ecom = totals.revenue_ecom
    summaryRow.rsoc_yielding_clicks = totals.rsoc_yielding_clicks
    summaryRow.revenue_rsoc = totals.revenue_rsoc
    summaryRow.rpc = isFinite(totals.revenue_rsoc / totals.rsoc_yielding_clicks)
      ? totals.revenue_rsoc / totals.rsoc_yielding_clicks
      : 0
    summaryRow.click_outs = totals.click_outs
    summaryRow.impressions = totals.impressions
    summaryRow.click_out_rate = totals.click_outs / totals.impressions
    summaryRow.website_impressions = totals.website_impressions
    summaryRow.keyword_impressions = totals.keyword_impressions
    summaryRow.keyword_impression_rate = isFinite(totals.keyword_impressions / totals.visits)
      ? totals.keyword_impressions / totals.visits
      : 0
    summaryRow.searches_rate = totals.searches / totals.visits
    summaryRow.searches_funnel_rpm = isFinite(totals.revenue_rsoc / totals.keyword_impressions)
      ? totals.revenue_rsoc / totals.keyword_impressions
      : 0
    summaryRow.searches = totals.searches
    summaryRow.orders = totals.orders
    summaryRow.estimated_revenue = totals.estimated_revenue
    summaryRow.order_rate = isFinite(totals.orders / totals.clicks)
      ? totals.orders / totals.clicks
      : 0
    summaryRow.item_rpm = isFinite(totals.estimated_revenue / totals.impressions)
      ? (totals.estimated_revenue / totals.impressions) * 1000
      : 0
      summaryRow.click_value = isFinite(totals.estimated_revenue / totals.clicks)
      ? (totals.estimated_revenue / totals.clicks)
      : 0
    
    return summaryRow
  }

  // If the popup doesn't have a campaign yet, the only valid actions are 'CAMPAIGN_POPUP_OPEN' and 'CAMPAIGN_POPUP_DATA_LOADED'
  if (
    !state.campaign &&
    ![
      CampaignPopupActionTypes.CAMPAIGN_POPUP_OPEN_V2,
      CampaignPopupActionTypes.CAMPAIGN_POPUP_DATA_LOADED_V2,
      CampaignPopupActionTypes.CAMPAIGN_POPUP_RESET_DATES_V2,
    ].includes(action.type)
  ) {
    return state
  }

  // If the campaign popup is open and the action contains campaign object, we want to make sure
  // that it's still the same campaign to avoid sync problems
  if (action.campaign && action.campaign.key !== state.campaign.key) {
    return state
  }

  let addSubSourcesProps = (subSources) => {
    if (subSources) {
      return subSources.map((subSource) => {
        // Add the additional properties
        subSource.isStatusLoading = false
        subSource.isBidLoading = false
        subSource.selected = false

        return subSource
      })
    } else {
      return null
    }
  }

  switch (action.type) {
    case CampaignPopupActionTypes.CAMPAIGN_POPUP_OPEN_V2:
      var mainSubSection = campaignsPopupInitialState.mainSubSection

      if (action.forceTab !== null) {
        mainSubSection = action.forceTab
      }

      return Object.assign({}, state, {
        datePickerDates: {
          text: "7 Days",
          startDate: getStartDate(SEVEN_DAYS),
          endDate: getEndDate(SEVEN_DAYS),
          showLastHour: true,
          dateType: SEVEN_DAYS,
        },
        campaign: action.campaignData,
        mainSubSection,
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_DATA_LOADED_V2:
      var campaign = action.campaignData
      campaign.key = campaign.campaign_id
      overrideCampaignAttributes(campaign)

      return Object.assign({}, state, {
        campaign,
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_CLOSE_V2:
      return Object.assign({}, campaignsPopupInitialState)

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_EDIT:
      return Object.assign({}, state, {
        campaign: action.campaign,
        section: "Edit",
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_MAIN_SUB_SECTION:
      return Object.assign({}, state, {
        mainSubSection: action.subSection,
        performanceByDay: campaignsPopupInitialState.performanceByDay,
        bidHistory: campaignsPopupInitialState.bidHistory,
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_DATES_SELECTED:
      var mainSubSection = state.mainSubSection
      var endDate = action.dateType === CUSTOM ? action.endDate : getEndDate(action.dateType)
      var startDate = action.dateType === CUSTOM ? action.startDate : getStartDate(action.dateType)

      return Object.assign({}, state, {
        datePickerDates: {
          startDate: startDate,
          endDate: endDate,
          dateType: action.dateType,
          showLastHour: action.showLastHour,
        },
        mainSubSection,
      })

    case CampaignActionTypes.CHANGE_BID_RESPONSE:
    case CampaignActionTypes.CHANGE_BID_ERROR_RESPONSE:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          bid: action.bid,
          isCampaignBidLoading: action.isCampaignBidLoading,
        }),
      })

    case CampaignActionTypes.CHANGE_ROAS_RESPONSE:
    case CampaignActionTypes.CHANGE_ROAS_ERROR_RESPONSE:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          roas: action.roas,
        }),
        isCampaignBidLoading: action.isCampaignBidLoading,
      })

    case CampaignPopupActionTypes.UPDATE_CAMPAIGN_TAGS:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          tags: action.campaignTags,
        }),
      })

    case CampaignActionTypes.CAMPAIGN_STATUS_LOADING:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          isCampaignStatusLoading: action.isCampaignStatusLoading,
        }),
      })

    case CampaignActionTypes.CAMPAIGN_BID_LOADING:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          isCampaignBidLoading: action.isCampaignBidLoading,
        }),
      })

    case CampaignActionTypes.STOP_CAMPAIGN_ERROR_RESPONSE:
    case CampaignActionTypes.ACTIVE_CAMPAIGN_RESPONSE:
    case CampaignActionTypes.PAUSE_CAMPAIGN_RESPONSE:
    case CampaignActionTypes.START_CAMPAIGN_ERROR_RESPONSE:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          status: action.status,
          isCampaignStatusLoading: action.isCampaignStatusLoading,
        }),
      })

    case CampaignActionTypes.CAMPAIGN_RPM_LOADING:
      var performanceByDayTable = state.performanceByDay.dataForTable.slice()

      return Object.assign({}, state, {
        performanceByDay: Object.assign({}, state.performanceByDay, {
          dataForTable: performanceByDayTable.map((item) => {
            if (item.date == action.startDate.format(Consts.SERVER_DATE_FORMAT)) {
              return Object.assign({}, item, {
                isCampaignRpmLoading: true,
              })
            }

            return item
          }),
        }),
      })

    case CampaignActionTypes.GET_RPM_RESPONSE:
      var performanceByDayTable = state.performanceByDay.dataForTable.slice()
      return Object.assign({}, state, {
        performanceByDay: Object.assign({}, state.performanceByDay, {
          dataForTable: performanceByDayTable.map((item) => {
            if (item.date == action.startDate.format(Consts.SERVER_DATE_FORMAT)) {
              return Object.assign({}, item, {
                rpmData: action.rpmData,
                isCampaignRpmLoading: false,
              })
            }

            return item
          }),
        }),
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_FETCH_CAMPAIGN_PERFORMANCE_BY_DAY_RESPONSE_V2:
      var performanceByDayData = Object.keys(action.performanceByDay).map((key) => {
        var oneDay = Object.assign({}, action.performanceByDay[key])
        oneDay.key = generateCampaignKey(oneDay)
        oneDay.date = key
        oneDay.rpmData = []
        oneDay.isCampaignRpmLoading = false
        oneDay.selected = false

        return oneDay
      })

      var fieldNameToSortBy = campaignsPopupInitialState.performanceByDay.sortBy
      var summaryRow = calcSummaryRow(performanceByDayData)

      return Object.assign({}, state, {
        performanceByDay: Object.assign({}, state.performanceByDay, {
          dataForChart: performanceByDayData
            .slice()
            .sort((b, a) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceByDay.sortDirection
              )
            ),
          // Same data, but we need to make a difference so that the table sorting won't cause a chart render
          dataForTable: performanceByDayData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceByDay.sortDirection
              )
            ),
          sortBy: campaignsPopupInitialState.performanceByDay.sortBy,
          sortDirection: campaignsPopupInitialState.performanceByDay.sortDirection,
          summaryRow,
          hasData: performanceByDayData.length !== 0,
          currentPage: 1,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.UPDATE_PERFORMANCE_BY_DAY_PAGE:
      return Object.assign({}, state, {
        performanceByDay: Object.assign({}, state.performanceByDay, {
          currentPage: action.currentPage,
        }),
      })

    case CampaignPopupActionTypes.UPDATE_CAMPAIGN_POPUP_TABLE_PAGE:
      return Object.assign({}, state, {
        [action.tableName]: Object.assign({}, state[action.tableName], {
          currentPage: action.currentPage,
        }),
      })

    case CampaignPopupActionTypes.RESET_CAMPAIGN_POPUP_TABLE:
      return Object.assign({}, state, {
        [action.tableName]: campaignsPopupInitialState[action.tableName],
      })

    case CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_COUNTRY_RESPONSE:
      var fieldNameToSortBy = campaignsPopupInitialState.performanceByCountry.sortBy
      var performanceByCountryData = action.performanceByCountry.map((country) => {
        return { ...country, name: getCountryTextV2(country.country) }
      })
      var summaryRow = calcSummaryRow(performanceByCountryData)

      return Object.assign({}, state, {
        performanceByCountry: Object.assign({}, state.performanceByCountry, {
          dataForTable: performanceByCountryData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceByCountry.sortDirection
              )
            ),
          sortBy: campaignsPopupInitialState.performanceByCountry.sortBy,
          sortDirection: campaignsPopupInitialState.performanceByCountry.sortDirection,
          summaryRow,
          hasData: performanceByCountryData.length !== 0,
          currentPage: 1,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_ITEM_RESPONSE:
      var fieldNameToSortBy = campaignsPopupInitialState.performanceByItem.sortBy
      var performanceByItemData = action.performanceByItem
      var summaryRow = calcSummaryRow(performanceByItemData)
      return Object.assign({}, state, {
        performanceByItem: Object.assign({}, state.performanceByItem, {
          dataForTable: performanceByItemData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceByItem.sortDirection
              )
            ),
          sortBy: campaignsPopupInitialState.performanceByItem.sortBy,
          sortDirection: campaignsPopupInitialState.performanceByItem.sortDirection,
          summaryRow,
          hasData: performanceByItemData.length !== 0,
          itemsPerPage: 25,
          currentPage: 1,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_FUNNEL_RESPONSE:
      var fieldNameToSortBy = campaignsPopupInitialState.performanceByFunnel.sortBy
      var performanceByFunnelData = action.performanceByFunnel
      var summaryRow = calcSummaryRow(performanceByFunnelData)
      return Object.assign({}, state, {
        performanceByFunnel: Object.assign({}, state.performanceByFunnel, {
          dataForTable: performanceByFunnelData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceByFunnel.sortDirection
              )
            ),
          sortBy: campaignsPopupInitialState.performanceByFunnel.sortBy,
          sortDirection: campaignsPopupInitialState.performanceByFunnel.sortDirection,
          summaryRow,
          hasData: performanceByFunnelData.length !== 0,
          currentPage: 1,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_KEYWORD_RESPONSE:
      var fieldNameToSortBy = campaignsPopupInitialState.performanceByKeyword.sortBy
      var performanceByKeywordData = action.performanceByKeyword
      var totelPerDay = action.totelPerDay
      var summaryRow = action.summaryRow
      return Object.assign({}, state, {
        performanceByKeyword: Object.assign({}, state.performanceByKeyword, {
          totelPerDay,
          dataForTable: performanceByKeywordData,
          sortBy: campaignsPopupInitialState.performanceByKeyword.sortBy,
          sortDirection: campaignsPopupInitialState.performanceByKeyword.sortDirection,
          summaryRow,
          hasData: Object.keys(performanceByKeywordData).length !== 0,
          currentPage: 1,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_DEVICE_RESPONSE:
      var fieldNameToSortBy = campaignsPopupInitialState.performanceByDevice.sortBy
      var performanceByDeviceData = action.performanceByDevice.map((performanceByDevice) => {
        const deviceName = PlatformTypes.deviceToName[performanceByDevice.device] || PlatformTypes.UNKNOWN.name
        return { ...performanceByDevice, name: deviceName }
      })
      var summaryRow = calcSummaryRow(performanceByDeviceData)

      return Object.assign({}, state, {
        performanceByDevice: Object.assign({}, state.performanceByDevice, {
          dataForTable: performanceByDeviceData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceByDevice.sortDirection
              )
            ),
          sortBy: campaignsPopupInitialState.performanceByDevice.sortBy,
          sortDirection: campaignsPopupInitialState.performanceByDevice.sortDirection,
          summaryRow,
          hasData: performanceByDeviceData.length !== 0,
          currentPage: 1,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_BROWSER_RESPONSE:
      var performanceByBrowserData = action.performanceByBrowser
      var fieldNameToSortBy = campaignsPopupInitialState.performanceByBrowser.sortBy
      var summaryRow = calcSummaryRow(performanceByBrowserData)

      return Object.assign({}, state, {
        performanceByBrowser: Object.assign({}, state.performanceByBrowser, {
          dataForTable: performanceByBrowserData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceByBrowser.sortDirection
              )
            ),
          sortBy: campaignsPopupInitialState.performanceByBrowser.sortBy,
          sortDirection: campaignsPopupInitialState.performanceByBrowser.sortDirection,
          summaryRow,
          hasData: performanceByBrowserData.length !== 0,
          currentPage: 1,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.FETCH_CAMPAIGN_PERFORMANCE_BY_SUB_SOURCE_RESPONSE_V2:
      var performanceBySubSourceData = action.performanceBySubSource
      var fieldNameToSortBy = campaignsPopupInitialState.performanceBySubSource.sortBy
      var summaryRow = calcSummaryRow(performanceBySubSourceData)

      performanceBySubSourceData = addSubSourcesProps(performanceBySubSourceData)

      return Object.assign({}, state, {
        performanceBySubSource: Object.assign({}, state.performanceBySubSource, {
          dataForTable: performanceBySubSourceData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceBySubSource.sortDirection
              )
            ),
          sortBy: campaignsPopupInitialState.performanceBySubSource.sortBy,
          sortDirection: campaignsPopupInitialState.performanceBySubSource.sortDirection,
          summaryRow,
          hasData: performanceBySubSourceData.length !== 0,
          currentPage: 1,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_FETCH_CAMPAIGN_BID_HISTORY_RESPONSE:
      var bidHistoryData = action.bidHistory
        .filter((bidHistoryItem) => {
          return !(
            bidHistoryItem.type === "bid_change" &&
            bidHistoryItem.username === "N/A" &&
            bidHistoryItem.decision === "N/A"
          )
        })
        .map((bidHistoryItem, index) => {
          var item = Object.assign({}, bidHistoryItem)
          item.key = index
          item.pps = item.page_views / item.sessions
          item.rpmData = item.provider_rpms
          item.roi = item.current_roi
          item.selected = false
          return item
        })
      var fieldNameToSortBy = campaignsPopupInitialState.bidHistory.sortBy

      return Object.assign({}, state, {
        bidHistory: Object.assign({}, state.bidHistory, {
          dataForChart: bidHistoryData
            .slice()
            .filter((item) => item.type === "bid_change")
            .sort((b, a) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.bidHistory.sortDirection
              )
            ),
          dataForTable: bidHistoryData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.bidHistory.sortDirection
              )
            ),
          sortBy: action.firstRequest ? campaignsPopupInitialState.bidHistory.sortBy : state.bidHistory.sortBy,
          sortDirection: action.firstRequest
            ? campaignsPopupInitialState.bidHistory.sortDirection
            : state.bidHistory.sortDirection,
          currentPage: action.firstRequest ? 1 : state.bidHistory.currentPage,
          hasData: bidHistoryData.length !== 0,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_SORT_PERFORMANCE_LIST:
      var sortDirection = TableConsts.DESC

      if (
        state[action.performanceListName].sortBy == action.sortBy &&
        state[action.performanceListName].sortDirection == TableConsts.DESC
      ) {
        sortDirection = TableConsts.ASC
      }
      var fieldNameToSortBy = action.sortBy
      return Object.assign({}, state, {
        [action.performanceListName]: Object.assign({}, state[action.performanceListName], {
          sortBy: action.sortBy,
          sortDirection,
          dataForTable: state[action.performanceListName].dataForTable
            .slice()
            .sort((a, b) => genericCompareFunc(a[fieldNameToSortBy], b[fieldNameToSortBy], sortDirection)),
        }),
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_BID_HISTORY_SORT_BY:
      var sortDirection = TableConsts.DESC

      if (state.bidHistory.sortBy == action.sortBy && state.bidHistory.sortDirection == TableConsts.DESC) {
        sortDirection = TableConsts.ASC
      }
      var fieldNameToSortBy = action.sortBy

      return Object.assign({}, state, {
        bidHistory: Object.assign({}, state.bidHistory, {
          sortBy: action.sortBy,
          sortDirection,
          dataForTable: state.bidHistory.dataForTable
            .slice()
            .sort((a, b) => genericCompareFunc(a[fieldNameToSortBy], b[fieldNameToSortBy], sortDirection)),
          currentPage: 1,
        }),
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_BID_HISTORY_SHOW_MORE:
      return Object.assign({}, state, {
        bidHistory: Object.assign({}, state.bidHistory, {
          currentPage: state.bidHistory.currentPage + 1,
        }),
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_LOADING_V2:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      })

    case CampaignPopupActionTypes.UPDATE_USER_CONFIRMATION_BEFORE_LEAVING:
      return Object.assign({}, state, {
        userConfirmationBeforeLeaving: action.userConfirmationBeforeLeaving,
      })

    case CampaignActionTypes.CAMPAIGN_TOTAL_BUDGET_LOADING:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          isCampaignTotalBudgetLoading: action.isCampaignTotalBudgetLoading,
        }),
      })

    case CampaignActionTypes.CHANGE_TOTAL_BUDGET_RESPONSE:
    case CampaignActionTypes.CHANGE_TOTAL_BUDGET_ERROR_RESPONSE:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          total_budget: action.total_budget,
          isCampaignTotalBudgetLoading: action.isCampaignTotalBudgetLoading,
        }),
      })

    case CampaignActionTypes.CAMPAIGN_DAILY_BUDGET_LOADING:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          isCampaignDailyBudgetLoading: action.isCampaignDailyBudgetLoading,
        }),
      })

    case CampaignActionTypes.CHANGE_DAILY_BUDGET_RESPONSE:
    case CampaignActionTypes.CHANGE_DAILY_BUDGET_ERROR_RESPONSE:
      return Object.assign({}, state, {
        campaign: Object.assign({}, state.campaign, {
          daily_budget: action.daily_budget,
          isCampaignDailyBudgetLoading: action.isCampaignDailyBudgetLoading,
        }),
      })

    case ActionTypes.RESET_NETWORK_TAGS_V2:
      return Object.assign({}, state, {
        networkTags: campaignsPopupInitialState.networkTags,
      })

    case ActionTypes.NETWORK_TAGS_RESPONSE_V2:
      // The tags of the current campaign
      let localCampaignTags = []

      // The tags of the current network
      let networkTags = action.networkTags ? action.networkTags : []

      if (!isNullOrUndefined(state.campaign.tags)) {
        localCampaignTags = state.campaign.tags.map((tag) => {
          return tag.name
        })
      }

      // action.localTags  are the tags of the current report (all the user's filter options in campaigns page)
      // We will unite network tags, current campaign tags and the tags from the current report in order
      // to avoid missing tags caused by necessary cache update (which happens every 20 minutes)
      networkTags = new Set([...networkTags, ...action.localTags, ...localCampaignTags])
      networkTags = Array.from(networkTags).map((tag) => {
        return { name: tag }
      })

      return Object.assign({}, state, {
        networkTags,
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_FETCH_CAMPAIGN_PERFORMANCE_BY_HOUR_RESPONSE_V2:
      var performanceByHourData = Object.keys(action.performanceByHour).map((key) => {
        var oneHour = Object.assign({}, action.performanceByHour[key])
        oneHour.key = generateCampaignKey(oneHour)
        oneHour.date = key
        oneHour.rpmData = []
        oneHour.isCampaignRpmLoading = false
        oneHour.selected = false
        return oneHour
      })

      var summaryRow = calcSummaryRow(performanceByHourData)
      var fieldNameToSortBy = campaignsPopupInitialState.performanceByHour.sortBy
      return Object.assign({}, state, {
        performanceByHour: Object.assign({}, state.performanceByHour, {
          summaryRow,
          dataForChart: performanceByHourData
            .slice()
            .sort((b, a) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceByHour.sortDirection
              )
            ),
          // Same data, but we need to make a difference so that the table sorting won't cause a chart render
          dataForTable: performanceByHourData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                campaignsPopupInitialState.performanceByHour.sortDirection
              )
            ),
          sortBy: campaignsPopupInitialState.performanceByHour.sortBy,
          sortDirection: campaignsPopupInitialState.performanceByHour.sortDirection,
          hasData: performanceByHourData.length !== 0,
        }),
        isLoading: false,
      })

    case CampaignPopupActionTypes.CAMPAIGN_POPUP_RESET_DATES_V2:
      return Object.assign({}, state, {
        datePickerDates: {
          text: "7 Days",
          startDate: getStartDate(SEVEN_DAYS),
          endDate: getEndDate(SEVEN_DAYS),
          showLastHour: true,
          dateType: SEVEN_DAYS,
        },
      })

    case SubSourceActionTypes.PAUSE_SUB_SOURCE_RESPONSE:
    case SubSourceActionTypes.ACTIVATE_SUB_SOURCE_RESPONSE:
    case SubSourceActionTypes.PAUSE_SUB_SOURCE_ERROR_RESPONSE:
    case SubSourceActionTypes.ACTIVATE_SUB_SOURCE_ERROR_RESPONSE:
      return {
        ...state,
        performanceBySubSource: {
          ...state.performanceBySubSource,
          dataForTable: state.performanceBySubSource.dataForTable.map((item) => {
            if (item.sub_source === action.subSource.sub_source) {
              return {
                ...item,
                status: action.status,
                isStatusLoading: action.isStatusLoading,
              }
            }
            return item
          }),
        },
      }

    case SubSourceActionTypes.SUB_SOURCE_STATUS_LOADING:
      return {
        ...state,
        performanceBySubSource: {
          ...state.performanceBySubSource,
          dataForTable: state.performanceBySubSource.dataForTable.map((item) => {
            if (item.sub_source === action.subSource.sub_source) {
              return {
                ...item,
                isStatusLoading: action.isStatusLoading,
              }
            }
            return item
          }),
        },
      }

    case SubSourceActionTypes.SUB_SOURCE_BID_LOADING:
      return {
        ...state,
        performanceBySubSource: {
          ...state.performanceBySubSource,
          dataForTable: state.performanceBySubSource.dataForTable.map((item) => {
            if (item.sub_source === action.subSource.sub_source) {
              return {
                ...item,
                isBidLoading: action.isBidLoading,
              }
            }
            return item
          }),
        },
      }

    case SubSourceActionTypes.CHANGE_SUB_SOURCE_BID_RESPONSE:
    case SubSourceActionTypes.CHANGE_SUB_SOURCE_BID_ERROR_RESPONSE:
      return {
        ...state,
        performanceBySubSource: {
          ...state.performanceBySubSource,
          dataForTable: state.performanceBySubSource.dataForTable.map((item) => {
            if (item.sub_source === action.subSource.sub_source) {
              return {
                ...item,
                bid: action.bid,
                isBidLoading: action.isBidLoading,
              }
            }
            return item
          }),
        },
      }

    default:
      return state
  }
}

export { campaignPopupReducerV2 }
