import { hot } from "react-hot-loader/root"
import React from "react"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import CreationValidator from "../../creationValidator"
import WhiteCheckmark from "../../../resources/images/WhiteCheckmark.png"
import WarningIcon from "../../../resources/svgs/WarningIcon.svg"
import ThumbnailTypes from "../../../common/consts/thumbnailTypes"
import Thumbnail from "./thumbnail"
import Text from "./text"
import { isNullOrUndefined } from "../../../utils/funcUtils"
import ImageUpload from "./imageUpload"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"

class DynamicCreative extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isValid: false,
      validationErrors: CreationValidator.isDynamicCreativeValid(
        this.props.dynamicCreative,
        this.props.campaignSources
      ),
    }
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.dynamicCreative) !== JSON.stringify(prevProps.dynamicCreative) ||
      this.props.validationErrorsVisible !== prevProps.validationErrorsVisible
    ) {
      this.validateCreative(this.props.dynamicCreative, this.props.campaignSources)
    }
  }

  validateCreative = () => {
    if (this.props.campaignSources) {
      let validationErrors = CreationValidator.isDynamicCreativeValid(
        this.props.dynamicCreative,
        this.props.campaignSources
      )

      let isValid = validationErrors.size === 0
      this.setState({
        isValid: isValid,
        validationErrors: validationErrors,
      })
    }
  }

  handleChange = (name, event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  getCreativeErrors = () => {
    let creativeErrors = null
    const errorsList = Array.from(this.state.validationErrors.values())
    if (errorsList.length > 0 && this.props.validationErrorsVisible) {
      creativeErrors = (
        <ul className="creative-errors dynamic-errors flex-column">
          {errorsList.map((error, index) => {
            return (
              <li className="d-flex text" key={index}>
                {error}
              </li>
            )
          })}
        </ul>
      )
    }
    return creativeErrors
  }

  getValidFieldIcon = (field) => {
    let isFieldValid = isNullOrUndefined(this.state.validationErrors.get(field))
    return (
      <>
        <div
          className={
            "creative-valid d-flex justify-content-center align-items-center " + (isFieldValid ? "visible" : "")
          }
        >
          <img src={WhiteCheckmark} />
        </div>
        <div className={"creative-invalid " + (!isFieldValid && this.props.validationErrorsVisible ? "visible" : "")}>
          <WarningIcon width="27" height="24" />
        </div>
      </>
    )
  }

  generateCreativeEditable = () => {
    let fileMaxSizeBytes = Infinity
    let displayThumbnailDiv = null
    let longHeadlineDiv = null
    let primaryTextsDiv = null
    let multipleUpload = null
    if (this.props.selectedSource?.imageSizeValidationMB) {
      fileMaxSizeBytes = this.props.selectedSource.imageSizeValidationMB * 1000000
    }
    let fileTypeAccept = "image/jpeg,image/jpg,image/tiff,image/gif,image/png"
    if (this.props.selectedSource.id === CampaignsConsts.FACEBOOK_PROVIDER_ID) {
      fileTypeAccept = `${fileTypeAccept},video/mp4,video/mov,video/gif`
    }

    let thumbnails = this.props.dynamicCreative.thumbnails.map((thumbnailAttributes, index) => {
      return (
        <Thumbnail
          key={`thumbnail-${index}`}
          index={index}
          accept={fileTypeAccept}
          maxSize={fileMaxSizeBytes}
          multiple={false}
          disabled={Boolean(thumbnailAttributes.thumbnail || thumbnailAttributes.isThumbnailBeingUploaded)}
          thumbnailAttributes={thumbnailAttributes}
          isThumbnailBeingUploaded={thumbnailAttributes.isThumbnailBeingUploaded}
          thumbnailRemoveFunc={this.props.thumbnailRemoveFunc}
          thumbnailUpdateFunc={this.props.thumbnailUpdateFunc}
          updateThumbnailLoadingStatus={this.props.updateThumbnailLoadingStatus}
          allowThumbnailCrop={this.props.allowThumbnailCrop}
          campaignSources={this.props.campaignSources}
          validationErrorsVisible={this.props.validationErrorsVisible}
          thumbnailTypeId={ThumbnailTypes.RESPONSIVE_THUMBNAIL.id}
          groupIndex={this.props.groupIndex}
          cropDetails={this.props.selectedSource.cropDetails}
        />
      )
    })
    if (this.props.dynamicCreative.displayThumbnails?.length > 0) {
      let displayThumbnails = this.props.dynamicCreative.displayThumbnails.map((thumbnailAttributes, index) => {
        return (
          <Thumbnail
            key={`thumbnail-${index}`}
            index={index}
            accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
            maxSize={fileMaxSizeBytes}
            multiple={false}
            disabled={Boolean(thumbnailAttributes.thumbnail || thumbnailAttributes.isThumbnailBeingUploaded)}
            thumbnailAttributes={thumbnailAttributes}
            isThumbnailBeingUploaded={thumbnailAttributes.isThumbnailBeingUploaded}
            thumbnailRemoveFunc={this.props.thumbnailRemoveFunc}
            thumbnailUpdateFunc={this.props.thumbnailUpdateFunc}
            updateThumbnailLoadingStatus={this.props.updateThumbnailLoadingStatus}
            allowThumbnailCrop={this.props.allowThumbnailCrop}
            campaignSources={this.props.campaignSources}
            validationErrorsVisible={true}
            thumbnailTypeId={ThumbnailTypes.DISPLAY_THUMBNAIL.id}
            groupIndex={null}
          />
        )
      })
      displayThumbnailDiv = (
        <>
          <span>Display ads</span>
          <div className="text-container dynamic">
            <div className="thumbnails no-border display-ads">
              {this.getValidFieldIcon("displayThumbnails")}
              {displayThumbnails}
            </div>
          </div>
        </>
      )
      longHeadlineDiv = (
        <>
          <span> Long Headline</span>
          <div className="text-container dynamic">
            {this.getValidFieldIcon("longHeadline")}
            <Text
              key={`long-headline`}
              index={0}
              creativeUpdateFunc={this.props.creativeUpdateFunc}
              inputClassName="text-area"
              text={this.props.dynamicCreative.longHeadline[0]}
              maxCharsAllowed={CreationValidator.getLongHeadlineMaxCharsAllowed(this.props.campaignSources)}
              placeHolder={`Long Headline`}
              type={"longHeadline"}
              groupIndex={null}
            />
          </div>
        </>
      )
    }

    if (this.props.selectedSource?.primaryTextsAllowed) {
      primaryTextsDiv = (
        <>
          <span> Primary Texts</span>
          <div className="text-container dynamic">
            {this.getValidFieldIcon("primaryTexts")}
            {this.props.dynamicCreative.primaryTexts.map((primaryText, index) => {
              return (
                <Text
                  key={`primary-text-${index}`}
                  index={index}
                  creativeUpdateFunc={this.props.creativeUpdateFunc}
                  inputClassName="text-area"
                  text={this.props.dynamicCreative.primaryTexts[index]}
                  maxCharsAllowed={Infinity}
                  placeHolder={`Primary Text ${index + 1}`}
                  type={"primaryTexts"}
                  groupIndex={this.props.groupIndex}
                />
              )
            })}
          </div>
        </>
      )
    }

    let headlines = this.props.dynamicCreative.headlines.map((headline, index) => {
      return (
        <Text
          key={`headline-${index}`}
          index={index}
          creativeUpdateFunc={this.props.creativeUpdateFunc}
          inputClassName="text-area"
          text={this.props.dynamicCreative.headlines[index]}
          maxCharsAllowed={CreationValidator.getHeadlineMaxCharsAllowed(this.props.campaignSources)}
          placeHolder={`Headline ${index + 1}`}
          type={"headlines"}
          groupIndex={this.props.groupIndex}
        />
      )
    })

    let descriptions = this.props.dynamicCreative.descriptions.map((description, index) => {
      return (
        <Text
          key={`description-${index}`}
          index={index}
          creativeUpdateFunc={this.props.creativeUpdateFunc}
          inputClassName="text-area"
          text={this.props.dynamicCreative.descriptions[index]}
          maxCharsAllowed={CreationValidator.getDescriptionMaxCharsAllowed(this.props.campaignSources)}
          placeHolder={`Description ${index + 1}`}
          type={"descriptions"}
          groupIndex={this.props.groupIndex}
        />
      )
    })

    const maxImagesToUpload = this.props.dynamicCreative.thumbnails.filter((thumbnail) =>
      isNullOrUndefined(thumbnail.thumbnail)
    ).length

    if (this.props.selectedSource.id === CampaignsConsts.GOOGLE_PROVIDER_ID) {
      multipleUpload = (
        <>
          <ImageUpload
            onUpload={this.props.onUploadFunc}
            maxFiles={parseInt(maxImagesToUpload / Object.keys(this.props.selectedSource.cropDetails).length)} // Maximum number of files to upload
            accept="image/jpeg,image/jpg,image/gif,image/png"
            thumbnailTypeId={ThumbnailTypes.RESPONSIVE_THUMBNAIL.id}
            selectedSource={this.props.selectedSource}
            groupIndex={this.props.groupIndex}
            dynamicCreative={this.props.dynamicCreative}
          />
        </>
      )
    }

    return (
      <div className="creative-container">
        <>
          <div className="main">
            <span>Responsive Thumbnails</span>
            <div className="text-container dynamic main">
              <div className="thumbnails no-border">
                {this.getValidFieldIcon("thumbnails")}
                {thumbnails}
              </div>
              {multipleUpload}
            </div>
            {primaryTextsDiv}
            <span> Headlines</span>
            <div className="text-container dynamic">
              {this.getValidFieldIcon("headlines")}
              {headlines}
            </div>
            {longHeadlineDiv}
            <span>Descriptions</span>
            <div className="text-container dynamic">
              {this.getValidFieldIcon("descriptions")}
              {descriptions}
            </div>
            {displayThumbnailDiv}
          </div>
        </>
        {this.getCreativeErrors()}
      </div>
    )
  }
  render() {
    return this.generateCreativeEditable()
  }
}

function mapStateToProps(state, ownProps) {
  let selectedSource = null

  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
    let source = state.campaignCreationWizard.campaignSources.sources[sourceKey]

    if (source.selected) {
      selectedSource = source
    }
  })

  return {
    selectedSource,
  }
}

let connectedComponent = connect(mapStateToProps)(DynamicCreative)
export default hot(connectedComponent)
