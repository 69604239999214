import axios from "axios"
import Consts from "../app/consts"
import RequestsService from "./requestsService"

export default class CampaignCreationService {
  static getValidThumbnailUrl(fileName, contentType, path = "") {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/creative/signed_url", {
      params: {
        file_name: fileName,
        content_type: contentType,
        path: path,
      },
    })
  }

  static getCreatives(campaign) {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/campaign/" + campaign.campaign_id + "/creatives", {
      cancelToken: RequestsService.cancelToken("campaign-creatives-request"),
    })
  }

  static uploadCreativeToS3(url, file) {
    var options = {
      headers: {
        "Content-Type": file.type,
        should_include_authorization: false,
      },
    }

    return axios.put(url, file, options)
  }

  static fetchCreativeThumbnailWithCrops(path, cropDetails) {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/creative/create_multiple_crops", {
      params: {
        path: path,
        cropDetails: cropDetails,
      },
    })
  }

  static launchCampaignsCreation(campaignCreationRequests) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaign_management/create_campaigns", {
      campaign_creation_requests: campaignCreationRequests,
    })
  }

  static launchBulkCampaignsCreation(campaignCreationRequests) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/campaign_management/bulk_create_campaigns", {
      campaign_creation_requests: campaignCreationRequests,
    })
  }

  static getImageByUrl(url) {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/get_image", {
      params: {
        url: url,
      },
      responseType: "arraybuffer",
    })
  }

  static getNetworkTargetingGroupPresets(networkId, providerId = null) {
    let path = "/api/integrations/network_targeting_group_presets/" + networkId

    if (providerId) {
      path += "/" + providerId
    }

    return axios.get(Consts.BASE_API_URL + path)
  }

  static deleteNetworkTargetingGroupPreset(networkId, groupId) {
    return axios.delete(
      Consts.BASE_API_URL + "/api/integrations/network_targeting_group_presets/" + networkId + "/delete/" + groupId
    )
  }

  static addNetworkTargetingGroupPreset(networkId, providerId, targetingJson) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/network_targeting_group_presets/" + networkId + "/add", {
      providerId,
      targetingJson,
    })
  }

  static updateNetworkTargetingGroupPreset(groupId, networkId, providerId, targetingJson) {
    return axios.post(
      Consts.BASE_API_URL + "/api/integrations/network_targeting_group_presets/" + networkId + "/update/" + groupId,
      {
        providerId,
        targetingJson,
      }
    )
  }

  static getPostAssetsByURL(postUrl) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/assets", {
      post_url: postUrl,
    })
  }

  static getAutoGenertaedText(articleUrl, networkCode) {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/creatives/auto_generate_text", {
      cancelToken: RequestsService.cancelToken("auto-generate-text"),
      params: {
        network_code: networkCode,
        article_url: articleUrl,
      },
    })
  }

  static getArticleDetails(articleUrl, networkCode) {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/article_details", {
      params: {
        network_code: networkCode,
        article_url: articleUrl,
      },
    })
  }

  static generateCampaignCreationRequests(creationData) {
    return axios.post(
      Consts.BASE_API_URL + "/api/integrations/campaign_management/generate_creation_requests",
      {
        campaign_creation_data: creationData,
      },
      {
        cancelToken: RequestsService.cancelToken("generate-creation-requests"),
      }
    )
  }

  static generateBulkCampaignCreationRequests(creationData) {
    return axios.post(
      Consts.BASE_API_URL + "/api/integrations/campaign_management/generate_bulk_creation_requests",
      {
        campaign_creation_data: creationData,
      },
      {
        cancelToken: RequestsService.cancelToken("generate-creation-requests"),
      }
    )
  }
}
