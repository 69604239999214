import React from "react"
import { getSortIcon } from "../../../utils/tableUtils"
import PropTypes from "prop-types"

export default class Cell extends React.Component {
  render() {
    let additionalCssClasses = []

    if (this.props.clickable) {
      additionalCssClasses.push("clickable")
    }

    if (this.props.cellSize) {
      additionalCssClasses.push(this.props.cellSize)
    }

    additionalCssClasses = [...additionalCssClasses, ...this.props.cssClasses]

    return (
      <div
        key={this.props.fieldName}
        className={"cell " + additionalCssClasses.join(" ")}
        onClick={
          this.props.sort
            ? () => {
                this.props.sort.sortHandler(this.props.fieldName)
              }
            : null
        }
        {...this.props.containerProps}
      >
        {this.props.value}
        {this.props.sort && this.props.showSortIcon
          ? getSortIcon(this.props.fieldName, this.props.sort.sortBy, this.props.sort.sortDirection)
          : null}
      </div>
    )
  }
}

Cell.propTypes = {
  // The key of the cell. try to avoid indexes as keys.
  fieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // Css classes to implement on a single call
  cssClasses: PropTypes.arrayOf(PropTypes.string),

  // Cell size must be one of the supported sizes. By default, cell size is small.
  cellSize: PropTypes.oneOf([
    "x-small",
    "slimmer-small",
    "small",
    "small-medium",
    "medium",
    "medium-large",
    "large",
    "x-large",
    "large-filler",
  ]),
}

Cell.defaultProps = {
  // Container props will contain only known HTML attributes (className, data-tip, data-class, onClick...).
  // We will add those props by spreading this object
  containerProps: {},

  // Props that cannot only implemented by a spread operation
  // (attributes that should be handled differently / merge with other props without override them)
  fieldName: "", // The key of the div
  clickable: false,
  cellSize: "small", // x-small / small / medium / large / large-filler
  cssClasses: [],
  showSortIcon: true,
}
