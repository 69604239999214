import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import NumberFormat from "../../../../common/components/numberFormat"
import BidTypes from "../../../../common/consts/bidTypes"

const InitialBidAndBudget = (props) => {
  const [initialBid, setInitialBid] = useState(props.campaignTargetingGroup.initialBid)
  const [initialBudget, setInitialBudget] = useState(props.campaignTargetingGroup.initialBudget)
  const [bidType, setBidType] = useState(props.bidType)
  const [initialBudgetInputIsDisabled, setInitialBudgetInputIsDisabled] = useState(false)
  useEffect(() => {
    setInitialBid(props.campaignTargetingGroup.initialBid)
  }, [props.campaignTargetingGroup.initialBid])

  useEffect(() => {
    setInitialBudget(props.campaignTargetingGroup.initialBudget)
  }, [props.campaignTargetingGroup.initialBudget])

  useEffect(() => {
    setBidType(props.bidType)
    let initialBid = null
    switch (props.bidType) {
      case BidTypes.roas.value:
      case BidTypes.lowest_cost.value:
        setInitialBudgetInputIsDisabled(true)
        break
      default:
        setInitialBid(props.campaignTargetingGroup.initialBid)
        setInitialBudgetInputIsDisabled(false)
        break
    }
    setInitialBid(initialBid)
    props.dispatch(updateTargetingGroup(props.groupIndex, { initialBid }))
  }, [props.bidType])

  const onChangeEvent = (value, type) => {
    const updatedValues = type === "bid" ? { initialBid: value } : { initialBudget: value }

    if (props.onChange) {
      props.onChange(updatedValues)
    } else {
      props.dispatch(updateTargetingGroup(props.groupIndex, updatedValues))
    }
    type === "bid" ? setInitialBid(value) : setInitialBudget(value)
  }

  return (
    <div>
      <div className="d-flex bid-budget-row">
        <div
          className={
            "text-field bid-budget-field" +
            (initialBudgetInputIsDisabled ? " disabled " : "") +
            (props.initialBidValidationErrors ? "error " : "")
          }
        >
          <div className="creation-generic-dropdown-label">Initial Bid</div>
          <NumberFormat
            value={(bidType === BidTypes.roas.value && BidTypes.roas.defaultBid) || initialBid}
            displayType={"input"}
            onValueChange={(values) => onChangeEvent(values.value, "bid")}
            className="number-text-field limit"
            decimalScale={2}
            thousandSeparator
            prefix={(props.bidType === BidTypes.roas.name && "%") || "$"}
          />
          {props.initialBidValidationErrors}
        </div>
        <div className={"text-field bid-budget-field" + (props.initialBudgetValidationErrors ? "error " : "")}>
          <div className="creation-generic-dropdown-label">Initial Budget</div>
          <NumberFormat
            value={initialBudget}
            displayType={"input"}
            onValueChange={(values) => onChangeEvent(values.value, "budget")}
            className="number-text-field limit"
            decimalScale={0}
            thousandSeparator
            prefix={"$"}
          />
          {props.initialBudgetValidationErrors}
        </div>
      </div>
      <span style={{ color: "#999999" }}>Leave Initial bid and budget fields empty to set the default values </span>
    </div>
  )
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(InitialBidAndBudget)
