import React from "react"
import Moment from "moment"
import { connect } from "react-redux"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import { campaignPopupSortPerformanceList } from "../../campaignPopupActions"
import Consts from "../../../app/consts"
import Table from "../../../common/components/table/table"
import { isUnknownCampaignTrafficSource } from "../../../utils/campaignUtilsV2"
import CampaignROI from "../../../common/components/campaignRoi"
import TableConsts from "../../../common/components/table/tableConsts"
import { isNullOrUndefined } from "../../../utils/funcUtils"
import articlesTypeConsts from "../../../articles/components/articlesType"

class CampaignPopupPerformanceByHourList extends React.Component {
  columns = CampaignsConsts.LIST_COLUMNS

  sortHoursBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, "performanceByHour"))
  }

  getHeader = () => {
    let columns = this.columns

    return {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.date.fieldName,
          value: columns.date.title,
          clickable: true,
        },
        {
          fieldName: "visits",
          value: columns.data_columns.visits.title,
          containerProps: {
            "data-tip": columns.data_columns.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.clicks.fieldName,
          value: columns.data_columns.clicks.title,
          containerProps: {
            "data-tip": columns.data_columns.clicks.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.profit.fieldName,
          value: columns.data_columns.profit.title,
          containerProps: {
            "data-tip": columns.data_columns.profit.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.roi.fieldName,
          value: columns.data_columns.roi.title,
          containerProps: {
            "data-tip": columns.data_columns.roi.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.dailySpent.fieldName,
          value: columns.data_columns.dailySpent.title,
          containerProps: {
            "data-tip": columns.data_columns.dailySpent.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.revenue.fieldName,
          value: columns.data_columns.revenue.title,
          containerProps: {
            "data-tip": columns.data_columns.revenue.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.rpc.fieldName,
          value: columns.data_columns.rpc.title,
          containerProps: {
            "data-tip": columns.data_columns.rpc.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.rsocYieldingClicks.fieldName,
          value: columns.data_columns.rsocYieldingClicks.title,
          containerProps: {
            "data-tip": columns.data_columns.rsocYieldingClicks.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.keywordImpressions.fieldName,
          value: columns.data_columns.keywordImpressions.title,
          containerProps: {
            "data-tip": columns.data_columns.keywordImpressions.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.keywordImpressionRate.fieldName,
          value: columns.data_columns.keywordImpressionRate.title,
          containerProps: {
            "data-tip": columns.data_columns.keywordImpressionRate.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.searchesRate.fieldName,
          value: columns.data_columns.searchesRate.title,
          containerProps: {
            "data-tip": columns.data_columns.searchesRate.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.searchesFunnelRpm.fieldName,
          value: columns.data_columns.searchesFunnelRpm.title,
          containerProps: {
            "data-tip": columns.data_columns.searchesFunnelRpm.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "cost_per_click",
          value: columns.data_columns.costPerClick.title,
          containerProps: {
            "data-tip": columns.data_columns.costPerClick.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: "visit_value",
          value: "Visit Value",
          containerProps: {
            "data-tip": "<div><strong>Visit Value</strong> is the total revenue measured per average visit</div>",
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.ecomYieldingClicks.fieldName,
          value: columns.data_columns.ecomYieldingClicks.title,
          containerProps: {
            "data-tip": columns.data_columns.ecomYieldingClicks.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.data_columns.revenueEcom.fieldName,
          value: columns.data_columns.revenueEcom.title,
          containerProps: {
            "data-tip": columns.data_columns.revenueEcom.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.data_columns.affiliateRpc.fieldName,
          value: columns.data_columns.affiliateRpc.title,
          containerProps: {
            "data-tip": columns.data_columns.affiliateRpc.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.data_columns.affiliateItemRpm.fieldName,
          value: columns.data_columns.affiliateItemRpm.title,
          containerProps: {
            "data-tip": columns.data_columns.affiliateItemRpm.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.data_columns.affiliateOrders.fieldName,
          value: columns.data_columns.affiliateOrders.title,
          containerProps: {
            "data-tip": columns.data_columns.affiliateOrders.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.data_columns.affiliateOrderRate.fieldName,
          value: columns.data_columns.affiliateOrderRate.title,
          containerProps: {
            "data-tip": columns.data_columns.affiliateOrderRate.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.data_columns.pagesPerVisit.fieldName,
          value: columns.data_columns.pagesPerVisit.title,
          containerProps: {
            "data-tip": columns.data_columns.pagesPerVisit.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.bounceRate.fieldName,
          value: columns.data_columns.bounceRate.title,
          containerProps: {
            "data-tip": columns.data_columns.bounceRate.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.rpm.fieldName,
          value: columns.data_columns.rpm.title,
          containerProps: {
            "data-tip": columns.data_columns.rpm.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.results.fieldName,
          value: columns.data_columns.results.title,
          containerProps: {
            "data-tip": columns.data_columns.results.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.costPerResult.fieldName,
          value: columns.data_columns.costPerResult.title,
          containerProps: {
            "data-tip": columns.data_columns.costPerResult.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.resultsRate.fieldName,
          value: columns.data_columns.resultsRate.title,
          containerProps: {
            "data-tip": columns.data_columns.resultsRate.tooltip,
          },
          clickable: true,
        },
      ],
    }
  }

  getSummaryRow = (isUnknownTrafficSource) => {
    let columns = this.columns.data_columns
    let summary = this.props.summaryRow
    let campaignRoiValue = TableConsts.EMPTY_CELL

    if (!isUnknownTrafficSource) {
      campaignRoiValue = <CampaignROI roi={summary.roi} siteId={this.props.campaign.site_id} />
    }
    return {
      cssClasses: ["summary-row"],
      stickyCells: [],
      cells: [
        {
          fieldName: "total",
          value: "Total",
        },
        {
          fieldName: columns.visits.fieldName,
          value: columns.visits.defaultFormat(summary.visits),
        },
        {
          fieldName: columns.clicks.fieldName,
          value: columns.clicks.defaultFormat(summary.clicks),
        },
        {
          fieldName: columns.profit.fieldName,
          value: columns.profit.defaultFormat(summary.profit),
        },
        {
          fieldName: columns.roi.fieldName,
          value: campaignRoiValue,
        },
        {
          fieldName: "Spend",
          value: isNullOrUndefined(summary.daily_spent)
            ? TableConsts.EMPTY_CELL
            : columns.dailySpent.defaultFormat(summary.daily_spent),
        },
        {
          fieldName: "Revenue",
          value: isNullOrUndefined(summary.revenue)
            ? TableConsts.EMPTY_CELL
            : columns.revenue.defaultFormat(summary.revenue),
        },
        {
          fieldName: columns.rpc.fieldName,
          value: columns.rpc.defaultFormat(summary.rpc),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.rsocYieldingClicks.fieldName,
          value: columns.rsocYieldingClicks.defaultFormat(summary.rsoc_yielding_clicks),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.keywordImpressions.fieldName,
          value: columns.keywordImpressions.defaultFormat(summary.keyword_impressions),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.keywordImpressionRate.fieldName,
          value: columns.keywordImpressionRate.defaultFormat(summary.keyword_impression_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.searchesRate.fieldName,
          value: columns.searchesRate.defaultFormat(summary.searches_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.searchesFunnelRpm.fieldName,
          value: columns.searchesFunnelRpm.defaultFormat(summary.searches_funnel_rpm),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.costPerClick.fieldName,
          value: columns.costPerClick.defaultFormat(summary.cost_per_click),
        },
        {
          fieldName: columns.visitValue.fieldName,
          value: columns.visitValue.defaultFormat(summary.visit_value),
        },
        {
          fieldName: columns.ecomYieldingClicks.fieldName,
          value: columns.ecomYieldingClicks.defaultFormat(summary.ecom_yielding_clicks),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.revenueEcom.fieldName,
          value: columns.revenueEcom.defaultFormat(summary.revenue_ecom),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.affiliateRpc.fieldName,
          value: columns.affiliateRpc.defaultFormat(summary.affiliate_rpc),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.affiliateItemRpm.fieldName,
          value: columns.affiliateItemRpm.defaultFormat(summary.affiliate_item_rpm),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.affiliateOrders.fieldName,
          value: columns.affiliateOrders.defaultFormat(summary.affiliate_orders),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.affiliateOrderRate.fieldName,
          value: columns.affiliateOrderRate.defaultFormat(summary.affiliate_order_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.pagesPerVisit.fieldName,
          value: columns.pagesPerVisit.defaultFormat(summary.pages_per_visit),
          cssClasses: ["summary-row"],
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.bounceRate.fieldName,
          value: columns.bounceRate.defaultFormat(summary.bounce_rate),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "rpm",
          value: isNullOrUndefined(summary.rpm) ? TableConsts.EMPTY_CELL : columns.rpm.defaultFormat(summary.rpm),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.results.fieldName,
          value: isNullOrUndefined(summary.results)
            ? TableConsts.EMPTY_CELL
            : columns.results.defaultFormat(summary.results),
        },
        {
          fieldName: columns.costPerResult.fieldName,
          value: isNullOrUndefined(summary.cost_per_result)
            ? TableConsts.EMPTY_CELL
            : columns.costPerResult.defaultFormat(summary.cost_per_result),
        },
        {
          fieldName: columns.resultsRate.fieldName,
          value: isNullOrUndefined(summary.results_rate)
            ? TableConsts.EMPTY_CELL
            : columns.resultsRate.defaultFormat(summary.results_rate),
        },
      ],
    }
  }

  getRow = (hour) => {
    let columns = this.columns.data_columns
    return {
      stickyCells: [],
      cells: [
        {
          fieldName: "date",
          value: (
            <>
              <span>
                {Moment(hour.date).format(Consts.CLIENT_SHORT_TIME_WITHOUT_SECONDS_FORMAT)}-
                {Moment(hour.date).add(1, "hour").format(Consts.CLIENT_SHORT_TIME_WITHOUT_SECONDS_FORMAT)}
              </span>
              <span className="week-day">
                {Moment(hour.date, Consts.SERVER_DATE_FORMAT).format(Consts.CLIENT_DATE_FORMAT)}
              </span>
            </>
          ),
          cssClasses: ["date-cell", "font-semibold"],
        },
        {
          fieldName: "visits",
          value: isNullOrUndefined(hour.visits) ? TableConsts.EMPTY_CELL : columns.visits.defaultFormat(hour.visits),
        },
        {
          fieldName: "clicks",
          value: isNullOrUndefined(hour.clicks) ? TableConsts.EMPTY_CELL : columns.clicks.defaultFormat(hour.clicks),
        },
        {
          fieldName: "estimated_profit",
          value: (
            <>{isNullOrUndefined(hour.profit) ? TableConsts.EMPTY_CELL : columns.profit.defaultFormat(hour.profit)}</>
          ),
        },
        {
          fieldName: "roi",
          value: isNullOrUndefined(hour.roi) ? (
            TableConsts.EMPTY_CELL
          ) : (
            <CampaignROI roi={hour.roi} siteId={this.props.campaign.site_id} />
          ),
          rawValue: hour.roi,
          cssClasses: ["font-bold"],
        },
        {
          fieldName: "daily_spent",
          rawValue: hour.daily_spent,
          value: isNullOrUndefined(hour.daily_spent)
            ? TableConsts.EMPTY_CELL
            : columns.dailySpent.defaultFormat(hour.daily_spent),
        },
        {
          fieldName: "revenue",
          rawValue: hour.revenue,
          value: isNullOrUndefined(hour.revenue) ? TableConsts.EMPTY_CELL : columns.revenue.defaultFormat(hour.revenue),
        },
        {
          fieldName: "rpc",
          rawValue: hour.rpc,
          value: isNullOrUndefined(hour.rpc) ? TableConsts.EMPTY_CELL : columns.rpc.defaultFormat(hour.rpc),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "rsoc_yielding_clicks",
          rawValue: hour.rsoc_yielding_clicks,
          value: isNullOrUndefined(hour.rsoc_yielding_clicks)
            ? TableConsts.EMPTY_CELL
            : columns.rsocYieldingClicks.defaultFormat(hour.rsoc_yielding_clicks),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.keywordImpressions.fieldName,
          rawValue: hour.keyword_impressions,
          value: isNullOrUndefined(hour.keyword_impressions)
            ? TableConsts.EMPTY_CELL
            : columns.keywordImpressions.defaultFormat(hour.keyword_impressions),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.keywordImpressionRate.fieldName,
          rawValue: hour.keyword_impression_rate,
          value: isNullOrUndefined(hour.keyword_impression_rate)
            ? TableConsts.EMPTY_CELL
            : columns.keywordImpressionRate.defaultFormat(hour.keyword_impression_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.searchesRate.fieldName,
          rawValue: hour.searches_rate,
          value: isNullOrUndefined(hour.searches_rate)
            ? TableConsts.EMPTY_CELL
            : columns.searchesRate.defaultFormat(hour.searches_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.searchesFunnelRpm.fieldName,
          rawValue: hour.searches_funnel_rpm,
          value: isNullOrUndefined(hour.searches_funnel_rpm)
            ? TableConsts.EMPTY_CELL
            : columns.searchesFunnelRpm.defaultFormat(hour.searches_funnel_rpm),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "cost_per_click",
          value: columns.costPerClick.defaultFormat(hour.cost_per_click),
        },
        {
          fieldName: "visit_value",
          value: isNullOrUndefined(hour.visit_value)
            ? TableConsts.EMPTY_CELL
            : columns.visitValue.defaultFormat(hour.visit_value),
        },
        {
          fieldName: "ecom_yielding_clicks",
          rawValue: hour.ecom_yielding_clicks,
          value: isNullOrUndefined(hour.ecom_yielding_clicks)
            ? TableConsts.EMPTY_CELL
            : columns.ecomYieldingClicks.defaultFormat(hour.ecom_yielding_clicks),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: "revenue_ecom",
          rawValue: hour.revenue_ecom,
          value: isNullOrUndefined(hour.revenue_ecom)
            ? TableConsts.EMPTY_CELL
            : columns.revenueEcom.defaultFormat(hour.revenue_ecom),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.affiliateRpc.fieldName,
          rawValue: hour.affiliate_rpc,
          value: isNullOrUndefined(hour.affiliate_rpc)
            ? TableConsts.EMPTY_CELL
            : columns.affiliateRpc.defaultFormat(hour.affiliate_rpc),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.affiliateItemRpm.fieldName,
          rawValue: hour.affiliate_item_rpm,
          value: isNullOrUndefined(hour.affiliate_item_rpm)
            ? TableConsts.EMPTY_CELL
            : columns.affiliateItemRpm.defaultFormat(hour.affiliate_item_rpm),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.affiliateOrders.fieldName,
          rawValue: hour.affiliate_orders,
          value: isNullOrUndefined(hour.affiliate_orders)
            ? TableConsts.EMPTY_CELL
            : columns.affiliateOrders.defaultFormat(hour.affiliate_orders),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.affiliateOrderRate.fieldName,
          rawValue: hour.affiliate_order_rate,
          value: isNullOrUndefined(hour.affiliate_order_rate)
            ? TableConsts.EMPTY_CELL
            : columns.affiliateOrderRate.defaultFormat(hour.affiliate_order_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.pagesPerVisit.fieldName,
          value: isNullOrUndefined(hour.pages_per_visit)
            ? TableConsts.EMPTY_CELL
            : columns.pagesPerVisit.defaultFormat(hour.pages_per_visit),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "bounce_rate",
          value: isNullOrUndefined(hour.bounce_rate)
            ? TableConsts.EMPTY_CELL
            : columns.bounceRate.defaultFormat(hour.bounce_rate),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "rpm",
          value: isNullOrUndefined(hour.rpm) ? TableConsts.EMPTY_CELL : columns.rpm.defaultFormat(hour.rpm),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "results",
          value: isNullOrUndefined(hour.results) ? TableConsts.EMPTY_CELL : columns.results.defaultFormat(hour.results),
        },
        {
          fieldName: "cost_per_result",
          value: isNullOrUndefined(hour.cost_per_result)
            ? TableConsts.EMPTY_CELL
            : columns.costPerResult.defaultFormat(hour.cost_per_result),
        },
        {
          fieldName: "results_rate",
          value: isNullOrUndefined(hour.results_rate)
            ? TableConsts.EMPTY_CELL
            : columns.resultsRate.defaultFormat(hour.results_rate),
        },
      ],
    }
  }

  render() {
    let rows = []
    let isUnknownTrafficSource = isUnknownCampaignTrafficSource(this.props.campaign)
    let header = this.getHeader()

    if (this.props.performanceByHour.length > 0) {
      rows.push(this.getSummaryRow(isUnknownTrafficSource))

      for (let i = 0; i < this.props.performanceByHour.length; i++) {
        let hour = this.props.performanceByHour[i]
        rows.push(this.getRow(hour))
      }
    }

    return (
      <Table
        header={header}
        rows={rows}
        sort={{
          sortBy: this.props.sortBy,
          sortDirection: this.props.sortDirection,
          sortHandler: this.sortHoursBy,
        }}
        scrolling={{
          containerClass: ".campaign-popup-container",
          includeNavigationBar: false,
        }}
        isExportableToCsv
        cssClasses={["campaign-popup-list"]}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    performanceByHour: state.campaignPopupV2.performanceByHour.dataForTable,
    sortBy: state.campaignPopupV2.performanceByHour.sortBy,
    sortDirection: state.campaignPopupV2.performanceByHour.sortDirection,
    summaryRow: state.campaignPopupV2.performanceByHour.summaryRow,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    reportType: state.campaignsV2.reportType,
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByHourList)
