import React from "react"
import ReactCrop from "react-image-crop"
import { Button, CircularProgress, Dialog } from "@material-ui/core"
import WarningIcon from "../../resources/svgs/WarningIcon.svg"
import CampaignsCreationConsts from "../campaignCreationConsts"
import CampaignCreationService from "../../api/campaignCreationService"
import Xicon from "../../resources/svgs/Xicon.svg"
import CircleCheckmark from "../../resources/svgs/CircleCheckmark.svg"
import { gcd } from "../../utils/mathUtils"

export default class CropCreativeDialog extends React.Component {
  constructor(props) {
    super(props)

    this.initialState = {
      crop: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      isImageLoading: true,
      imageSrc: null,
      minWidth: 0,
      minHeight: 0,
      maxWidth: null,
      maxHeight: null,
      minWidthRatio: 0,
      minHeightRatio: 0,
      imageNaturalHeight: 1,
      imageNaturalWidth: 1,
      selectedSource: null,
      sourceCropRatios: [],
      selectedRatio: null,
      cropIsActive: false,
    }

    this.state = {
      ...this.initialState,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isOpen === false && nextProps.isOpen === true) {
      let selectedSource = Object.keys(this.props.sources)[0]

      if (nextProps.cropDefaultTab) {
        selectedSource = nextProps.cropDefaultTab
      }

      this.selectSource(selectedSource)

      CampaignCreationService.getImageByUrl(nextProps.image).then((response) => {
        let url = URL.createObjectURL(new Blob([response.data]))
        this.setState({
          isImageLoading: false,
          imageSrc: url,
        })
      })
    }
  }

  getCropDimensionLimits = (sourceKey, selectedRatio = null) => {
    let cropDimensions = {
      minWidth: 0,
      minHeight: 0,
      maxWidth: null,
      maxHeight: null,
    }

    let sourceCropRatios = CampaignsCreationConsts.CROP_RATIOS[sourceKey]

    if (sourceCropRatios) {
      let ratio = Object.values(sourceCropRatios)[0]

      if (selectedRatio) {
        ratio = sourceCropRatios[selectedRatio]
      }

      cropDimensions.minWidth = ratio.minWidth
      cropDimensions.minHeight = ratio.minHeight
    }

    return cropDimensions
  }

  closePopup = () => {
    this.props.updatePopupState(false)
    this.setState(this.initialState)
  }

  onCropChange = (crop, pc) => {
    this.setState({ crop })
  }

  onCropComplete = (crop, pc) => {
    if (pc.height < this.state.minHeight || pc.width < this.state.minWidth) {
      crop = this.resetCropDimensions(this.state.imageNaturalHeight, this.state.imageNaturalWidth)
    }

    this.setState({ crop: Object.assign({}, this.state.crop, crop) })
  }

  onImageLoaded = (image) => {
    let crop = {}

    if (this.props.croppedImagesPerSource.hasOwnProperty(this.state.selectedSource)) {
      crop = this.state.crop
    } else if (image.naturalHeight >= this.state.minHeight && image.naturalWidth >= this.state.minWidth) {
      crop = this.resetCropDimensions(image.naturalHeight, image.naturalWidth)
    }

    this.setState(
      {
        minWidthRatio: (this.state.minWidth / image.naturalWidth) * 100,
        minHeightRatio: (this.state.minHeight / image.naturalHeight) * 100,
        maxWidthRatio: this.state.maxWidth ? (this.state.maxWidth / image.naturalWidth) * 100 : 100,
        maxHeightRatio: this.state.maxHeight ? (this.state.maxHeight / image.naturalHeight) * 100 : 100,
        imageNaturalWidth: image.naturalWidth,
        imageNaturalHeight: image.naturalHeight,
        crop: crop,
      },
      () => {
        // Once the image is loaded, making another call to selectSource to pick the correct selectedCropRatio because the first / selected
        // one might be disabled (image might turn up to be too small)
        this.selectSource(this.state.selectedSource)
      }
    )
  }

  resetCropDimensions = (naturalHeight, naturalWidth) => {
    let crop = {}

    crop.height = (this.state.minHeight * 100.0) / naturalHeight
    crop.width = (this.state.minWidth * 100.0) / naturalWidth

    crop.aspect = this.state.minWidth / this.state.minHeight
    // If the width divided by the aspect ratio is greater than or equal to the height
    // This means the image is wider than the target aspect ratio
    if (naturalWidth / crop.aspect >= naturalHeight) {
      crop.height = 100
      // Calculate the width of the crop based on the height and aspect ratio
      crop.width = (naturalHeight * crop.aspect * 100) / naturalWidth
    } else {
      // If the width divided by the aspect ratio is less than the height
      // This means the image is taller than the target aspect ratio
      crop.width = 100
      // Calculate the height of the crop based on the width and aspect ratio
      crop.height = ((naturalWidth / crop.aspect) * 100) / naturalHeight
    }

    // Calculate the crop's x and y positions to center the crop within the image
    crop.x = (100 - crop.width) / 2
    crop.y = (100 - crop.height) / 2

    return crop
  }

  save = () => {
    let image = new Image()

    image.crossOrigin = "Anonymous"
    image.src = this.state.imageSrc

    this.getCroppedImg(image, this._imageCropper.getPixelCrop(this.state.crop), "Placeholder").then(this.saveCreative)
  }

  saveCreative = (file) => {
    this.props.updateImageFunc([file], this.state.selectedSource, this.state.crop, this.state.selectedRatio)

    if (Object.keys(this.props.sources).length <= 1) {
      this.closePopup()
    }
  }

  getCroppedImg = (image, pixelCrop, fileName) => {
    let newHeight = pixelCrop.height
    let newWidth = pixelCrop.width

    // How cropping for locked ratios is done:
    // We let the user scale the image however he wants, and then we calculate how many times we can fit the crop ratio
    // inside that size.
    // In order to do that, we calculate the GCD of the width and the height in order to see the minimum "jump" size,
    // and then we calculate how many "times" we can make the jump.
    //
    // Example 1:
    // Yahoo ratio is 1200x627
    // gcd(1200, 627) = 3
    // widthJump = 1200/3 = 400
    // heightJump = 627/3 = 209
    // This means that the possible crop sizes are: 1200x627, 1600x836, 2000x1045, 2400x1254 etc..
    // If the user crops his image to a size of 1723x900:
    // The image will be scaled down to 1600x836
    //

    let ratioGcd = gcd(this.state.minWidth, this.state.minHeight)
    let widthJump = this.state.minWidth / ratioGcd
    let heightJump = this.state.minHeight / ratioGcd

    let timesWidth = pixelCrop.width / widthJump
    let timesHeight = pixelCrop.height / heightJump
    let times = Math.max(ratioGcd, Math.floor(timesHeight), Math.floor(timesWidth))

    newWidth = times * widthJump
    newHeight = times * heightJump

    const canvas = document.createElement("canvas")
    canvas.width = newWidth
    canvas.height = newHeight
    const ctx = canvas.getContext("2d")

    ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, newWidth, newHeight)

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        file.name = fileName
        resolve(file)
      }, "image/jpeg")
    })
  }

  selectSource = (sourceKey, selectedCropRatio = null) => {
    let sourceCropRatios = CampaignsCreationConsts.CROP_RATIOS[sourceKey]

    // Out of all the available crop options for the source, we automatically select the first available one (which is not disabled)
    // If there are no options, we "select" the first visible one (it won't be really selected because it's disabled, the user will see
    // a message saying that he can't crop for that source since the image is too small.
    let availableCropRatios = Object.keys(sourceCropRatios).filter(
      (cropRatioKey) => !this.isCropRatioDisabled(sourceCropRatios, cropRatioKey)
    )
    let selectedRatio = availableCropRatios.length > 0 ? availableCropRatios[0] : Object.keys(sourceCropRatios)[0]

    // If we are opening the crop dialog for a source that was already cropped, we are setting the crop dimensions
    // and the selected ratio exactly how they were when the user cropped the image.
    //
    // One more check is being made to see if the selected ratio actually exists for the source, this can happen if
    // we are duplicating a campaign from activities and the ratio that existed back then no longer exists for the
    // source.
    // For example: if we had only one ratio named 'default' for Twitter and the user cropped by it, and several
    // days later the user tries to duplicate that campaign but Twitter now has 'landscape' and 'square' (no 'default')
    // so we can no longer select 'default' since it doesn't exist and we have to throw the crop data away
    let loadExistingCropDetails =
      this.props.croppedImagesPerSource.hasOwnProperty(sourceKey) &&
      !selectedCropRatio &&
      sourceCropRatios.hasOwnProperty([this.props.croppedImagesPerSource[sourceKey].selectedRatio])

    if (loadExistingCropDetails) {
      selectedRatio = this.props.croppedImagesPerSource[sourceKey].selectedRatio
    } else {
      if (selectedCropRatio) {
        selectedRatio = selectedCropRatio
      }
    }

    let cropDimensionLimits = this.getCropDimensionLimits(sourceKey, selectedRatio)

    this.setState(
      {
        selectedSource: sourceKey,
        sourceCropRatios: sourceCropRatios,
        selectedRatio: selectedRatio,
        minWidth: cropDimensionLimits.minWidth,
        minHeight: cropDimensionLimits.minHeight,
        maxWidth: cropDimensionLimits.maxWidth,
        maxHeight: cropDimensionLimits.maxHeight,
        minWidthRatio: (cropDimensionLimits.minWidth / this.state.imageNaturalWidth) * 100,
        minHeightRatio: (cropDimensionLimits.minHeight / this.state.imageNaturalHeight) * 100,
        maxWidthRatio: cropDimensionLimits.maxWidth
          ? (cropDimensionLimits.maxWidth / this.state.imageNaturalWidth) * 100
          : 100,
        maxHeightRatio: cropDimensionLimits.maxHeight
          ? (cropDimensionLimits.maxHeight / this.state.imageNaturalHeight) * 100
          : 100,
      },
      () => {
        if (loadExistingCropDetails) {
          this.setState({
            crop: Object.assign({}, this.props.croppedImagesPerSource[sourceKey].cropDetails),
          })
        } else {
          this.setState({
            crop: this.resetCropDimensions(this.state.imageNaturalHeight, this.state.imageNaturalWidth),
          })
        }
      }
    )
  }

  getSourceSelection = () => {
    let sourceSelection
    let sourceKeys = Object.keys(this.props.sources)

    if (sourceKeys.length > 1) {
      sourceSelection = (
        <div className="source-selection">
          {sourceKeys.map((sourceKey) => {
            let croppedIndicator = null

            if (this.props.croppedImagesPerSource.hasOwnProperty(sourceKey)) {
              croppedIndicator = <CircleCheckmark className="check-mark" />
            }

            return (
              <div
                className={"source-tab clickable " + (this.state.selectedSource === sourceKey ? "selected" : "")}
                key={sourceKey}
                onClick={() => this.selectSource(sourceKey)}
              >
                {croppedIndicator} {sourceKey}
              </div>
            )
          })}
        </div>
      )
    }

    return sourceSelection
  }

  isCropRatioDisabled = (sourceCropRatios, cropRatioKey) => {
    return (
      sourceCropRatios[cropRatioKey].minWidth > this.state.imageNaturalWidth ||
      sourceCropRatios[cropRatioKey].minHeight > this.state.imageNaturalHeight
    )
  }

  getRatioSelection = () => {
    let ratioSelection
    let options = []
    let sourceCropRatiosKeys = Object.keys(this.state.sourceCropRatios)

    if (sourceCropRatiosKeys.length > 1) {
      sourceCropRatiosKeys.forEach((cropRatioKey) => {
        let isDisabled = this.isCropRatioDisabled(this.state.sourceCropRatios, cropRatioKey)

        options.push(
          <Button
            key={cropRatioKey}
            className={
              "ratio-button round-button " +
              cropRatioKey +
              " " +
              (this.state.selectedRatio === cropRatioKey ? "selected blue" : "gray")
            }
            disabled={isDisabled}
            onClick={() => this.selectSource(this.state.selectedSource, cropRatioKey)}
          >
            <span className="ratio-icon" />
            {this.state.sourceCropRatios[cropRatioKey].title}
          </Button>
        )
      })
    }

    ratioSelection = <div className="ratio-selection">{options}</div>

    return ratioSelection
  }

  isCropValid = () => {
    let isSameAsPreviousCrop =
      this.props.croppedImagesPerSource[this.state.selectedSource] &&
      this.props.croppedImagesPerSource[this.state.selectedSource].cropDetails.width === this.state.crop.width &&
      this.props.croppedImagesPerSource[this.state.selectedSource].cropDetails.height === this.state.crop.height &&
      this.props.croppedImagesPerSource[this.state.selectedSource].cropDetails.x === this.state.crop.x &&
      this.props.croppedImagesPerSource[this.state.selectedSource].cropDetails.y === this.state.crop.y

    if (isSameAsPreviousCrop) {
      return false
    }

    return true
  }

  getCropWidth = () => {
    return Math.round((this.state.imageNaturalWidth * this.state.crop.width) / 100.0)
  }

  getCropHeight = () => {
    return Math.round((this.state.imageNaturalHeight * this.state.crop.height) / 100.0)
  }

  onDragStart = () => {
    if (this._imageCropper.state.cropIsActive) {
      this.setState({ cropIsActive: true })
    }
  }

  onDragEnd = () => {
    setTimeout(() => {
      this.setState({ cropIsActive: false })
    }, 500)
  }

  onBackdropClick = () => {
    if (!this.state.cropIsActive) {
      this.closePopup()
    }
  }

  render() {
    let sourceSelection = null
    let ratioSelection = null
    let error = null
    let cropDimensions = null
    let cropButton = null
    let cropWidth = this.getCropWidth()
    let cropHeight = this.getCropHeight()
    let isImageTooSmall =
      this.state.imageNaturalHeight < this.state.minHeight || this.state.imageNaturalWidth < this.state.minWidth
    let isImageEqualsCropSize =
      this.state.imageNaturalHeight === this.state.minHeight && this.state.imageNaturalWidth === this.state.minWidth
    let isCropDisabled =
      !this.state.crop.width ||
      !this.state.crop.height ||
      this.state.isImageLoading ||
      cropHeight < this.state.minHeight ||
      cropWidth < this.state.minWidth ||
      isImageTooSmall ||
      isImageEqualsCropSize ||
      !this.isCropValid()

    if (this.props.isThumbnailBeingUploaded) {
      cropButton = (
        <div className="save-crop d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      cropButton = (
        <Button
          disabled={isCropDisabled}
          className="round-button blue save-crop"
          classes={{ disabled: "disabled-save-crop" }}
          onClick={this.save}
        >
          Crop
        </Button>
      )
    }

    if (!this.state.isImageLoading && this.state.imageSrc !== "") {
      sourceSelection = this.getSourceSelection()

      if (Object.keys(this.state.sourceCropRatios).length > 1) {
        ratioSelection = this.getRatioSelection()
      }

      if (isImageTooSmall) {
        error = (
          <div className="d-flex error">
            <div className="icon">
              <WarningIcon width="20" height="17" />
            </div>
            <div className="text">The image is too small to crop for {this.state.selectedSource}</div>
          </div>
        )
      } else if (isImageEqualsCropSize) {
        error = (
          <div className="d-flex error">
            <div className="icon">
              <WarningIcon width="20" height="17" />
            </div>
            <div className="text">The image size is equals to minimum crop size for {this.state.selectedSource}</div>
          </div>
        )
      } else {
        cropDimensions = (
          <div className="crop-dimensions">
            {cropWidth}x{cropHeight}
          </div>
        )
      }
    }

    return (
      <Dialog
        open={this.props.isOpen}
        BackdropProps={{
          classes: { root: "campaign-popup-backdrop" },
        }}
        onClose={this.closePopup}
        onBackdropClick={this.onBackdropClick}
        disableBackdropClick
        classes={{ paper: "crop-creative-popup", root: "campaign-popup-container" }}
      >
        <div className="d-flex flex-column dialog-container">
          <Xicon className="exit-button clickable" width="14" height="14" onClick={this.closePopup} />
          <div className="crop-title">Crop image</div>
          {sourceSelection}
          {ratioSelection}
          <div className="d-flex justify-content-center">
            {this.state.isImageLoading ? (
              <div className="d-flex align-items-center loader-container">
                <CircularProgress className="loader" size={40} />
              </div>
            ) : (
              <ReactCrop
                ref={(elem) => {
                  this._imageCropper = elem
                }}
                src={this.state.imageSrc ? this.state.imageSrc : ""}
                crop={this.state.crop}
                onChange={this.onCropChange}
                onComplete={this.onCropComplete}
                onImageLoaded={this.onImageLoaded}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                disabled={isImageTooSmall}
                minHeight={this.state.minHeightRatio}
                minWidth={this.state.minWidthRatio}
                maxHeight={this.state.maxHeightRatio}
                maxWidth={this.state.maxWidthRatio}
                crossorigin={"Anonymous"}
                style={{
                  width: "fit-content",
                  height: "fit-content",
                  maxHeight: 610,
                  maxWidth: 610,
                  backgroundColor: "transparent",
                }}
                imageStyle={{
                  maxWidth: "100%",
                  maxHeight: "inherit",
                }}
              />
            )}
          </div>
          {cropDimensions}
          {error}
          <div className="d-flex align-items-center justify-content-center buttons-container">
            <Button className="round-button gray done" onClick={this.closePopup}>
              Done
            </Button>
            {cropButton}
          </div>
        </div>
      </Dialog>
    )
  }
}
