import React, { useState, useEffect, useRef } from "react"
import Dropzone from "react-dropzone"
import AddMultipleImagesIcon from "../../../resources/svgs/AddMultipleImagesIcon.svg"
import DropImageIcon from "../../../resources/svgs/DropImageIcon.svg"
import ArrowGalleryIcon from "../../../resources/svgs/ArrowGalleryIcon.svg"
import { CircularProgress } from "@material-ui/core"
import { notificationAdd } from "../../../common/actions/commonActions"

function ImageUpload(props) {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const isLoadingThumbnail =
      props.dynamicCreative.thumbnails.filter((thumbnail) => thumbnail.isThumbnailBeingUploaded === true).length > 0

    if (isLoadingThumbnail !== isLoading) {
      setLoading(isLoadingThumbnail)
    }
  }, [props.dynamicCreative.thumbnails])

  const onDrop = (acceptedFiles) => {
    // Check if the total number of files does not exceed the maximum limit
    if (acceptedFiles.length <= props.maxFiles) {
      setLoading(true)
      props.onUpload(acceptedFiles, props.thumbnailTypeId, props.selectedSource, props.groupIndex)
    } else {
      this.props.dispatch(notificationAdd("Exceeded maximum file limit."))
    }
  }

  return (
    <Dropzone
      onDrop={onDrop}
      accept={props.accept}
      multiple={true}
      minSize={0}
      maxFiles={props.maxFiles}
      disabled={isLoading}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isLoading ? (
            <div className="thumbnail dynamic-thumbnail multiple-uploaded">
              <CircularProgress className="loader" size={40} />
            </div>
          ) : isDragActive ? (
            <div className="multiple-uploaded-active-drag">
              <div className="drop-container">
                <DropImageIcon className="drop-image" />
                <span>Drop to attach image</span>
              </div>
            </div>
          ) : (
            <>
              <div className="thumbnail multiple-uploaded">
                <input {...getInputProps()} />
                <div className="no-image">
                  <AddMultipleImagesIcon className="drop-image" />
                  <span className="drop-text">Drop images</span>
                  <div className="thumbnail-actions">
                    <div className="thumbnail-button">
                      <span>
                        <ArrowGalleryIcon />
                        Upload ({props.maxFiles} images remaining)
                      </span>
                    </div>
                  </div>
                </div>
                <span> </span>
              </div>
            </>
          )}
        </div>
      )}
    </Dropzone>
  )
}

export default ImageUpload
