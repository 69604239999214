import AutomationCreationActionTypes from "./automationCreationActionTypes"

export function openAutomationCreationPopup() {
  return { type: AutomationCreationActionTypes.OPEN_AUTOMATION_CREATION_POPUP }
}

export function closeAutomationCreationPopup() {
  return { type: AutomationCreationActionTypes.CLOSE_AUTOMATION_CREATION_POPUP }
}

export function createAutomationResponse(creationData) {
  return { type: AutomationCreationActionTypes.CREATE_AUTOMATION_RESPONSE, creationData }
}

export function editAutomationResponse(ruleId, editData) {
  return { type: AutomationCreationActionTypes.EDIT_AUTOMATION_RESPONSE, ruleId, editData }
}

export function openAutomationEditPopup(automation) {
  return (dispatch) => {
    dispatch({
      type: AutomationCreationActionTypes.OPEN_AUTOMATION_CREATION_POPUP,
      isOpen: true,
      isEdit: true,
      automation,
    })
  }
}

export function openAutomationDuplicatePopup(automation) {
  return (dispatch) => {
    dispatch({
      type: AutomationCreationActionTypes.OPEN_AUTOMATION_CREATION_POPUP,
      isOpen: true,
      isDuplicate: true,
      automation,
    })
  }
}
