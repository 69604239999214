import React from "react"
import { connect } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import CampaignPopupPerformanceByFunnelList from "./lists/performanceByFunnelList"
import CampaignPopupPerformanceByFunnelChart from "./charts/performanceByFunnelChart"
import {
  campaignPopupLoading,
  fetchCampaignPerformanceByFunnelAsync,
  resetCampaignPopupTable,
} from "../campaignPopupActions"
import RequestsService from "../../api/requestsService"

class CampaignPopupPerformanceByFunnel extends React.Component {
  componentDidMount() {
    this.getPerformanceByFunnel()
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      JSON.stringify(prevProps.datePickerDates.startDate) !== JSON.stringify(this.props.datePickerDates.startDate) ||
      JSON.stringify(prevProps.datePickerDates.endDate) !== JSON.stringify(this.props.datePickerDates.endDate)
    ) {
      this.getPerformanceByFunnel()
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetCampaignPopupTable("performanceByFunnel"))
    RequestsService.cancelRequest("performance-by-funnel-request")
  }

  getPerformanceByFunnel = () => {
    RequestsService.cancelRequest("performance-by-funnel-request")
    this.props.dispatch(campaignPopupLoading())
    this.props.dispatch(
      fetchCampaignPerformanceByFunnelAsync(
        this.props.campaign,
        this.props.datePickerDates.startDate,
        this.props.datePickerDates.endDate
      )
    )
  }

  render() {
    let loadingIndicator = null
    let funnelChartData = []
    let performanceByFunnel = null

    if (this.props.isLoading) {
      loadingIndicator = (
        <div className="campaign-popup-loading d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      funnelChartData = this.props.performanceByFunnel.slice(
        (this.props.currentPage - 1) * this.props.itemsPerPage,
        this.props.currentPage * this.props.itemsPerPage
      )

      performanceByFunnel = (
        <div className="campaign-popup-performance-by-Funnel">
          <CampaignPopupPerformanceByFunnelChart data={funnelChartData} campaign={this.props.campaign} />
          <CampaignPopupPerformanceByFunnelList />
        </div>
      )
    }

    return (
      <>
        {loadingIndicator}
        {performanceByFunnel}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    isLoading: state.campaignPopupV2.isLoading,
    performanceByFunnel: state.campaignPopupV2.performanceByFunnel.dataForTable,
    itemsPerPage: state.campaignPopupV2.performanceByFunnel.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceByFunnel.currentPage,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByFunnel)
