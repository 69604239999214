import React from "react"
import { Link } from "react-router"
import { generateAutomationUrl } from "../utils/automationUtils"

export default class AutomationPopupLink extends React.Component {
  render() {
    let linkProps = { ...this.props }
    let returnTo = this.props.returnTo ? this.props.returnTo : window.location.pathname
    // We don't need the automation prop inside the <Link>, we only use it to generate the automation url
    delete linkProps.automation

    return (
      <Link
        to={{ pathname: generateAutomationUrl(this.props.automation), state: { isAutomationPopup: true, returnTo } }}
        {...linkProps}
      >
        {this.props.children}
      </Link>
    )
  }
}
