import React from "react"
import { connect } from "react-redux"
import { TextField } from "@material-ui/core"
import { updateTargetingGroup } from "../../../campaignCreationActions"

class NameSuffix extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      nameSuffix: props.campaignTargetingGroup.nameSuffix,
    }

    if (props.onChange) {
      this.onChangeEvent = (value) => props.onChange({ nameSuffix: value })
    } else {
      this.onChangeEvent = (value) =>
        this.props.dispatch(updateTargetingGroup(this.props.groupIndex, { nameSuffix: value }))
    }
  }

  render() {
    return (
      <div className={"text-field " + (this.props.nameSuffixValidationErrors ? "error " : "")}>
        <TextField
          label="Name suffix"
          InputProps={{
            className: "text-field-line",
          }}
          InputLabelProps={{
            classes: { shrink: "text-field-color" },
          }}
          placeholder="Name suffix"
          value={this.props.campaignTargetingGroup.nameSuffix}
          onChange={(event) => this.onChangeEvent(event.target.value)}
          margin="normal"
        />
        {this.props.nameSuffixValidationErrors}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(NameSuffix)
