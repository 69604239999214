import React from "react"
import { MenuItem } from "@material-ui/core"
import { eventsTracker } from "../../api/eventsTracker"
import { redirectToLogin } from "../../utils/routingUtils"

// Home page component
export default class LogoutButton extends React.Component {
  // render
  render() {
    return (
      <MenuItem
        button
        className="logout-button not-link"
        onClick={() => {
          eventsTracker.trackLogout()
          redirectToLogin()
        }}
      >
        Log out
      </MenuItem>
    )
  }
}
