import React from "react"
import { connect } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import ExportIcon from "../../resources/svgs/Export.svg"
import { downloadFile } from "../../utils/funcUtils"
import { notificationAdd } from "../actions/commonActions"

class ExportButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
    }
  }

  download = () => {
    if (this.props.downloadService) {
      this.setState({ isLoading: true })

      this.props
        .downloadService()
        .then((result) => {
          downloadFile(result.data, this.props.fileName)
        })
        .catch((error) => {
          this.props.dispatch(notificationAdd("An error occurred while exporting the table"))
        })
        .finally(() => this.setState({ isLoading: false }))
    } else if (this.props.downloadFunction) {
      this.props.downloadFunction()
    }
  }

  render() {
    let icon = null
    if (this.state.isLoading) {
      icon = <CircularProgress className="loader icon" size={7} />
    } else {
      icon = <ExportIcon className="icon" />
    }

    let buttonText = "Export table"
    if (this.props.buttonText) {
      buttonText = this.props.buttonText
    }

    return (
      <button disabled={this.state.isLoading} className="export-button clickable" onClick={this.download}>
        {buttonText}
        {icon}
      </button>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(ExportButton)
