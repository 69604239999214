import React from "react"
import { connect } from "react-redux"
import Xicon from "../../resources/svgs/Xicon.svg"
import AutomationPopupMainTop from "./automationPopupMainTop"
import AutomationPopupActionHistory from "./actionHistory"

class AutomationPopupMain extends React.Component {
  render() {
    return (
      <div className="campaign-popup-main">
        <Xicon
          className="campaign-popup-exit-button clickable"
          width="16"
          height="16"
          onClick={this.props.closeFunction}
        />
        <AutomationPopupMainTop />
        <AutomationPopupActionHistory />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    automation: state.automationPopup.automation,
  }
}

export default connect(mapStateToProps)(AutomationPopupMain)
