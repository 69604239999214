import React from "react"
import Consts from "../app/consts"
import { isNullOrUndefined } from "./funcUtils"
import UntrackedProvider from "../resources/svgs/providers/UntrackedProvider.svg"
import Taboola from "../resources/svgs/providers/Taboola.svg"
import Revcontent from "../resources/svgs/providers/Revcontent.svg"
import Contentad from "../resources/svgs/providers/Contentad.svg"
import Outbrain from "../resources/svgs/providers/Outbrain.svg"
import Facebook from "../resources/svgs/providers/Facebook.svg"
import Powerinbox from "../resources/svgs/providers/Powerinbox.svg"
import Keywee from "../resources/svgs/providers/Keywee.svg"
import GoogleAds from "../resources/svgs/providers/GoogleAds.svg"
import Pinterest from "../resources/svgs/providers/Pinterest.svg"
import Yahoo from "../resources/svgs/providers/Yahoo.svg"

export function getProviderData(campaign, providers) {
  let provider = getProviderById(campaign.provider_id, providers)
  if (campaign.provider_id !== Consts.UNKNOWN_PROVIDER_ID) {
    return provider
  } else {
    let campaignName = campaign.utm_source
    if (campaign.utm_medium) {
      campaignName = campaignName + "-" + campaign.utm_medium
    }
    return Object.assign({}, provider, {
      name: campaignName,
    })
  }
}

export function getProviderById(providerId, providers) {
  providerId = parseInt(providerId)
  let provider = providers.filter((provider) => {
    return providerId === provider.id
  })
  return provider[0]
}

export function getProviderByName(providerName, providers) {
  return providers.filter((provider) => {
    return provider.name === providerName
  })[0]
}

export function getProviderColor(providers, providerName) {
  if (providerName === "total") {
    return "#000000"
  }

  let provider = getProviderByName(providerName, providers)

  if (provider) {
    return provider.color
  }
  return "#000000"
}

export function getProviderName(providers, providerId) {
  let provider = getProviderById(providerId, providers)
  return isNullOrUndefined(provider) ? "" : provider.name
}

export function getProviderIcon(providers, providerId) {
  let providerIcons = {
    Taboola: Taboola,
    Revcontent: Revcontent,
    "Content.ad": Contentad,
    Outbrain: Outbrain,
    Facebook: Facebook,
    Powerinbox: Powerinbox,
    Keywee: Keywee,
    GoogleAds: GoogleAds,
    Pinterest: Pinterest,
    Yahoo: Yahoo,
  }

  let providerName = getProviderName(providers, providerId)

  if (providerIcons.hasOwnProperty(providerName)) {
    let ProviderIcon = providerIcons[providerName]
    return <ProviderIcon data-class="tooltip-custom type-icon-info shrink-tip" data-tip={providerName} />
  } else {
    return <UntrackedProvider />
  }
}

export function getProviderBidStepSize(providerId) {
  let stepSize = Consts.STEPPER_STEP_REALTIME_BID_SUB_CENT

  if (Consts.PROVIDERS_WITH_FULL_CENT.includes(providerId)) {
    stepSize = Consts.STEPPER_STEP_REALTIME_BID_FULL_CENT
  }

  return stepSize
}

export function bulkGetProviderBidStepSize(campaigns) {
  let bidStepSize = Consts.STEPPER_STEP_REALTIME_BID_SUB_CENT

  for (let i = 0; i < campaigns.length; i++) {
    if (getProviderBidStepSize(campaigns[i].provider_id) === Consts.STEPPER_STEP_REALTIME_BID_FULL_CENT) {
      bidStepSize = Consts.STEPPER_STEP_REALTIME_BID_FULL_CENT
      break
    }
  }

  return bidStepSize
}

export function getProviderCode(providerId) {
  let idToProviderCode = { 1: "tb", 4: "ob", 9: "fb", 11: "tw", 91: "ga", 100: "tk", 102: "pi" }
  return idToProviderCode[providerId]
}
