import React from "react"
import GenericDropdown from "../../../common/components/genericDropdown"
import { connect } from "react-redux"
import { updateSource } from "../../campaignCreationActions"
import { isNullOrEmpty } from "../../../utils/funcUtils"

class PagesDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: [],
      selectedIndexesArr: [],
    }
  }

  renderMenuItems(isNewSite) {
    let site = this.props.campaignDetailsState.siteId
      ? this.props.sites.filter((s) => s.id === this.props.campaignDetailsState.siteId)[0]
      : null

    if (site) {
      this.setState(
        {
          menuItems: site.provider_pages.map((page) => {
            return { ...page, name: page.page_name }
          }),
        },
        () => {
          if (isNullOrEmpty(this.props.selectedSource.selectedPage)) {
            // If there is more than 1 page connected the site, a page will be selected automatically (with the same logic from the bulk auto page selection)
            let selectedLanguages = this.props.campaignDetailsState.languages.filter((lang) => lang.selected)
            let indexPage = 0
            if (selectedLanguages.length > 0) {
              selectedLanguages = selectedLanguages[0].name
              indexPage = this.state.menuItems.findIndex((page) => page.page_language === selectedLanguages)
            }
            this.handleMenuClick([indexPage !== -1 ? indexPage : 0])
          } else if (isNewSite) {
            let newSourceData = Object.assign({}, this.props.selectedSource, {
              selectedPage: null,
            })

            this.props.dispatch(updateSource(this.props.sourceKey, newSourceData))
          }
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.sourceKey !== prevProps.sourceKey ||
      this.props.campaignDetailsState.siteId !== prevProps.campaignDetailsState.siteId ||
      this.props.campaignDetailsState.networkCode !== prevProps.campaignDetailsState.networkCode
    ) {
      this.renderMenuItems(true)
    }
  }

  componentWillMount() {
    this.renderMenuItems(false)
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (index) => {
    this.setState({
      selectedIndexesArr: index,
    })

    let newSourceData = Object.assign({}, this.props.selectedSource, {
      selectedPage: this.state.menuItems[index[0]],
    })

    this.props.dispatch(updateSource(this.props.sourceKey, newSourceData))

    this.handleMenuClose()
  }

  render() {
    let isFieldDisabled = !this.props.campaignDetailsState.siteId
    let buttonClassName = "creation-generic-dropdown-button "
    let header = "Choose Page"
    let site = this.props.campaignDetailsState.siteId
      ? this.props.sites.filter((s) => s.id === this.props.campaignDetailsState.siteId)[0]
      : null

    if (
      (this.props.selectedSource.selectedPage || this.state.menuItems.length === 1) &&
      site &&
      this.state.menuItems[0] &&
      site.id === this.state.menuItems[0].site_id
    ) {
      buttonClassName += "selected"
      header = this.props.selectedSource.selectedPage
        ? this.props.selectedSource.selectedPage.name
        : this.state.menuItems[0].name
    }

    return (
      <div
        className={
          "text-field page-selection " +
          (this.props.pageValidationErrors ? "error " : "") +
          (isFieldDisabled ? "disabled " : "")
        }
      >
        <div className="creation-generic-dropdown-label">Page</div>
        <GenericDropdown
          header={header}
          selectOptionCallback={(index) => {
            this.handleMenuClick(index)
          }}
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowAutoSuggest={false}
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection={false}
          buttonClassName={buttonClassName}
          menuClassName={"creation-generic-dropdown-menu"}
        />
        {this.props.pageValidationErrors}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sites: state.app.sites,
  }
}

export default connect(mapStateToProps)(PagesDropdown)
