import React from "react"
import { connect } from "react-redux"
import DatePicker from "../../common/components/datePickerV2"
import { automationPopupDatesSelected } from "../automationPopupActions"
import AutomationOptionsMenu from "../../automations/components/automationOptionsMenu"
import AutomationActionsMenu from "../../automations/components/automationActionsMenu"
import { buildDescriptiveActionChangeText, buildConditionChipItems } from "../../utils/automationUtils"
import AutomationsConsts from "../../automations/automationsConsts"

class AutomationPopupMainTop extends React.Component {
  constructor(props) {
    super(props)
  }

  buildInformationBar = (automation) => {
    const DescriptiveActionChange = buildDescriptiveActionChangeText(
      automation.actionType,
      automation.actionValue,
      automation.valueType
    )
    const frequency = AutomationsConsts.FREQUENCY_VALUES[automation.frequency]

    return (
      <>
        <div>
          <li>{DescriptiveActionChange}</li>
          <li>{frequency}</li>
        </div>
      </>
    )
  }

  render() {
    const informationBar = this.buildInformationBar(this.props.automation)
    const conditionTags = buildConditionChipItems(this.props.automation, false, "condition-popup-tag")

    return (
      <div>
        <div className="campaign-popup-main-top">
          <div className="d-flex justify-content-between">
            <div className="campaign-title">{this.props.automation.name}</div>
            <div className="campaign-datepicker d-flex align-items-center">
              <DatePicker
                startDate={this.props.datePickerDates.startDate}
                endDate={this.props.datePickerDates.endDate}
                showLastHour={this.props.datePickerDates.showLastHour}
                dateType={this.props.datePickerDates.dateType}
                allowLastHour={false}
                allowLast3Hours={false}
                allowSevenDays={true}
                allowAllTime={true}
                allowToday={true}
                onDatesSelected={(startDate, endDate, dateType, showLastHour) => {
                  this.props.dispatch(automationPopupDatesSelected(startDate, endDate, dateType, showLastHour))
                }}
              />
              <div className="campaign-options-menu-button d-flex align-items-center justify-content-center clickable">
                <AutomationOptionsMenu
                  automation={this.props.automation}
                  sourceComponent={"Automation popup"}
                  showExportButton={false}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <ul className="d-flex flex-row campaign-details-list">
              <li>
                <AutomationActionsMenu statusTextVisible={true} automation={this.props.automation} />
              </li>
              {informationBar}
            </ul>
            <div className="campaign-tags">{conditionTags}</div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isOpen: state.automationPopup.isOpen,
    automation: state.automationPopup.automation,
    datePickerDates: {
      startDate: state.automationPopup.datePickerDates.startDate,
      endDate: state.automationPopup.datePickerDates.endDate,
      showLastHour: state.automationPopup.datePickerDates.showLastHour,
      dateType: state.automationPopup.datePickerDates.dateType,
    },
  }
}

export default connect(mapStateToProps)(AutomationPopupMainTop)
