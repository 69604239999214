import React from "react"
import { connect } from "react-redux"
import { Radio } from "@material-ui/core"
import { resetTargetingGroups, updateSource } from "../campaignCreationActions"
import CampaignDetails from "./campaignDetails"

class Source extends React.Component {
  selectSource = (event) => {
    event.stopPropagation()

    let newSourceData = Object.assign({}, this.props.sourceData, {
      selected: !this.props.sourceData.selected, // toggling selected
    })

    this.props.dispatch(updateSource(this.props.sourceKey, newSourceData))
    this.props.dispatch(resetTargetingGroups())
  }

  render() {
    return (
      <div className="d-flex">
        <div className={"campaign-creation-source " + (this.props.sourceData.selected ? "selected" : "")}>
          <label className="source clickable">
            <Radio
              checked={this.props.sourceData.selected}
              classes={{ root: "checkbox", checked: "checked" }}
              name={"campaign-creation-source"}
              value="selected"
              onChange={this.selectSource}
            />
            <span style={{ color: this.props.providerColor }}>{this.props.sourceKey}</span>
          </label>
          <div className={"source-settings " + (this.props.sourceData.selected ? "open" : "")}>
            <CampaignDetails
              sourceKey={this.props.sourceKey}
              sourceData={this.props.sourceData}
              campaignDetailsState={this.props.campaignDetailsState}
              handleChange={this.props.handleChange}
              onBlur={this.props.onBlur}
              updateSiteDetailsInState={this.props.updateSiteDetailsInState}
              selectProviderAccount={this.props.selectProviderAccount}
              handleLanguageSelection={this.props.handleLanguageSelection}
              providerAccounts={this.props.providerAccounts}
              handleNetworkSelection={this.props.handleNetworkSelection}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    siteIdToSiteObject: state.app.siteIdToSiteObject,
    siteId: state.campaignCreationWizard.campaignSettings.siteId,
    networkId: state.campaignCreationWizard.campaignSettings.networkId,
  }
}

export default connect(mapStateToProps)(Source)
