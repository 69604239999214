import { applyMiddleware, compose, createStore } from "redux"
import { routerMiddleware, syncHistoryWithStore } from "react-router-redux"
import { browserHistory } from "react-router"
import freeze from "redux-freeze"
import thunk from "redux-thunk"
import { reducers } from "./reducers/index"
import throttle from "./middlewares/throttle"

// add the middlewares
let middlewares = []

// middlewares.push(log); // USE ONLY FOR DEBUG
// add the router middleware
middlewares.push(routerMiddleware(history))
middlewares.push(throttle)
middlewares.push(thunk)

// add the freeze dev middleware
if (process.env.NODE_ENV !== "production") {
  middlewares.push(freeze)
}

// apply the middleware
let middleware = applyMiddleware(...middlewares)

// add the redux dev tools, to production too.
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__())
}

// create the store
const store = createStore(reducers, middleware)
const history = syncHistoryWithStore(browserHistory, store)

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept("./reducers", () => {
    const nextRootReducer = require("./reducers/index").reducers
    store.replaceReducer(nextRootReducer)
  })
}

// export
export { store, history }
