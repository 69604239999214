import axios from "axios"
import Consts from "../app/consts"

export default class SettingsService {
  static getCampaignSettings(networkId) {
    return axios.get(Consts.BASE_API_URL + "/api/administration/network_settings", {
      params: {
        network_id: networkId,
      },
    })
  }

  static saveCampaignSettings(networkId, settings) {
    return axios.post(Consts.BASE_API_URL + "/api/administration/network_settings", {
      network_id: networkId,
      hide_inactive_sites: settings.hideInactiveSites,
    })
  }

  static addProviderPage(siteId, providerId, pageId, pageName, pageLanguage) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/administration/provider_pages/add", {
      site_id: siteId,
      provider_id: providerId,
      page_id: pageId,
      page_name: pageName,
      page_language: pageLanguage,
    })
  }

  static deleteProviderPage(siteId, providerId, pageId) {
    return axios.delete(
      Consts.BASE_API_URL +
        "/api/integrations/administration/provider_pages/delete/" +
        siteId +
        "/" +
        providerId +
        "/" +
        pageId
    )
  }

  static getBlockedSubsourcesList(networkId) {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/administration/" + networkId + "/blocked_subsources/")
  }

  static updateBlockedSubsourcesList(networkId, blockedSubsourcesList) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/administration/blocked_subsources", {
      network_id: networkId,
      blocked_subsources: blockedSubsourcesList,
    })
  }

  static addProviderSubAccounts(providerId, networkId, siteIds, subAccountIds) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/administration/add_provider_sub_accounts", {
      provider_id: providerId,
      network_id: networkId,
      site_ids: siteIds,
      sub_account_ids: subAccountIds,
    })
  }

  static updateAmazonItems() {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/ecom/update_all_ecom_items")
  }

  static updateAmazonTableFromCsv(urls) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/ecom/update_amazon_table_from_csv", {
      urls: urls,
    })
  }
}
