import React from "react"
import { connect } from "react-redux"
import { CircularProgress, ListItem, Menu, MenuItem } from "@material-ui/core"
import PlayIcon from "../../resources/svgs/PlayIcon.svg"
import PauseIcon from "../../resources/svgs/PauseIcon.svg"
import ModuleTypes from "../../common/consts/moduleTypes"
import AuthApi from "../../api/authService"
import { stopPropagationIfParentIsSelected } from "../../utils/domUtils"
import { isNullOrEmpty } from "../../utils/funcUtils"
import AutomationsConsts from "../automationsConsts"
import { activateAutomationAsync, automationStatusLoading, pauseAutomationAsync } from "../automationActions"

class AutomationActionsMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuAnchorEl: null,
      menuOpen: false,
    }
  }

  getAutomationStatusIcon = () => {
    let statusIcon = null

    switch (this.props.automation.status) {
      case AutomationsConsts.AUTOMATION_STATUS_RUNNING:
        statusIcon = (
          <div className="status_tags_popup">
            <PlayIcon className={"campaign-status-icon play-icon green"} />
            {this.props.statusTextVisible ? (
              <span className="green">
                {AutomationsConsts.AUTOMATION_STATUS_NAMES[AutomationsConsts.AUTOMATION_STATUS_RUNNING]}
              </span>
            ) : null}
          </div>
        )
        break
      case AutomationsConsts.AUTOMATION_STATUS_PAUSED:
        statusIcon = (
          <div className="status_tags_popup">
            <PauseIcon className={"campaign-status-icon pause-icon gray"} />
            {this.props.statusTextVisible ? (
              <span className="gray">
                {AutomationsConsts.AUTOMATION_STATUS_NAMES[AutomationsConsts.AUTOMATION_STATUS_PAUSED]}
              </span>
            ) : null}
          </div>
        )
        break
      default:
        statusIcon = null
    }
    return statusIcon
  }

  getAutomationActionMenuItems = () => {
    let menuItems = null
    switch (this.props.automation.status) {
      case AutomationsConsts.AUTOMATION_STATUS_RUNNING:
        menuItems = [
          <ListItem key={1} className="current-campaign-status">
            <PlayIcon className="play-icon green" />
            <span className="green">Running</span>
          </ListItem>,
          <MenuItem key={3} className="campaign-actions-menu-item" onClick={this.pauseAutomation}>
            <PauseIcon />
            <span>Pause</span>
          </MenuItem>,
        ]
        break

      case AutomationsConsts.AUTOMATION_STATUS_PAUSED:
        menuItems = [
          <ListItem key={1} className="current-campaign-status">
            <PauseIcon />
            <span>Paused</span>
          </ListItem>,
          <MenuItem key={2} className="campaign-actions-menu-item" onClick={this.activateAutomation}>
            <PlayIcon />
            <span>Run</span>
          </MenuItem>,
        ]
        break

      default:
        menuItems = null
    }

    return menuItems
  }

  pauseAutomation = () => {
    const automation = this.props.automation
    this.props.dispatch(automationStatusLoading(automation))
    this.props.dispatch(pauseAutomationAsync(automation))
    this.closeMenu()
  }

  activateAutomation = () => {
    const automation = this.props.automation
    this.props.dispatch(automationStatusLoading(automation))
    this.props.dispatch(activateAutomationAsync(automation))
    this.closeMenu()
  }

  openMenu = (event) => {
    stopPropagationIfParentIsSelected(event)
    this.setState({ menuOpen: true, menuAnchorEl: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  render() {
    let isClickable = false
    let automationActionsMenu = null
    let automationStatus = this.getAutomationStatusIcon()
    let loaderDiv = (
      <div className="loading-indicator">
        <CircularProgress size={20} />
      </div>
    )
    let automationStatusLoadingIndicator = this.props.automation.isAutomationStatusLoading ? loaderDiv : null

    if (
      AuthApi.hasModule(ModuleTypes.AUTOMATION_STATUS_CHANGE) &&
      !isNullOrEmpty(this.props.automation.status) &&
      this.props.automation.is_default !== true
    ) {
      isClickable = true
      automationActionsMenu = (
        <Menu
          id="campaign-actions-menu"
          classes={{ paper: "default-menu campaign-actions-menu" }}
          anchorEl={this.state.menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: -16,
          }}
          open={this.state.menuOpen}
          onClose={this.closeMenu}
          onClick={(event) => event.stopPropagation()}
        >
          {this.getAutomationActionMenuItems()}
        </Menu>
      )
    }

    return (
      <div className={"campaign-actions" + (isClickable ? " clickable" : "")}>
        <div aria-owns={this.state.open ? "campaign-actions-menu" : null} aria-haspopup="true" onClick={this.openMenu}>
          {automationStatus}
        </div>
        {automationActionsMenu}
        {automationStatusLoadingIndicator}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(AutomationActionsMenu)
