import axios from "axios"

let requests = {}
let CancelToken = axios.CancelToken

export default class RequestsService {
  static cancelRequest(cancelToken) {
    if (requests[cancelToken]) {
      requests[cancelToken]()
    }
  }

  static cancelToken(requestName) {
    return new CancelToken((c) => {
      requests[requestName] = c
    })
  }
}
