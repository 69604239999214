import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../common/components/genericDropdown"
import AutomationCreationConsts from "../../automationCreationConsts"
import Consts from "../../../app/consts"
import { getProviderBidStepSize } from "../../../utils/providerUtils"
import { isNullOrUndefined } from "../../../utils/funcUtils"

class ActionTypeDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: props.actionTypeItems,
      selectedIndexesArr: [],
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (index, changeToDefaultValues = true) => {
    this.setState({
      selectedIndexesArr: index,
    })

    let selectedActionType = this.state.menuItems[index]
    this.props.handleSelect("selectedActionType", selectedActionType)

    // Handle cases of switching to other options to default values
    if (changeToDefaultValues) {
      const defaultValuesByActionType = {
        [Consts.ACTION_TYPE_BUDGET]: {
          selectedActionOperation: AutomationCreationConsts.ACTION_OPERATIONS[0],
          selectedValueType: AutomationCreationConsts.VALUE_TYPES[0],
          actionValue: AutomationCreationConsts.DEFAULT_DAILY_BUDGET_VALUE,
          actionLimit: AutomationCreationConsts.DEFAULT_DAILY_BUDGET_LIMIT,
        },
        [Consts.ACTION_TYPE_BID]: {
          selectedActionOperation: AutomationCreationConsts.ACTION_OPERATIONS[0],
          selectedValueType: AutomationCreationConsts.VALUE_TYPES[0],
          actionValue: getProviderBidStepSize(this.props.automationDetailsState.selectedSource?.providerId),
          actionLimit: AutomationCreationConsts.DEFAULT_BID_LIMIT,
        },
        [Consts.ACTION_TYPE_STATUS]: {
          selectedActionOperation: null,
          selectedValueType: null,
          actionValue: null,
          actionLimit: null,
        },
        [Consts.ACTION_TYPE_SUB_SOURCE_BID]: {
          selectedActionOperation: AutomationCreationConsts.ACTION_OPERATIONS[0],
          selectedValueType: AutomationCreationConsts.VALUE_TYPES[0],
          actionValue: getProviderBidStepSize(this.props.automationDetailsState.selectedSource?.providerId),
          actionLimit: AutomationCreationConsts.DEFAULT_BID_LIMIT,
        },
        [Consts.ACTION_TYPE_SUB_SOURCE_BLOCK]: {
          selectedActionOperation: null,
          selectedValueType: null,
          actionValue: "BLOCK",
          actionLimit: null,
        },
      }

      this.props.handleSelectMultiple(defaultValuesByActionType[selectedActionType.actionType])
    }
    this.handleMenuClose()
  }

  componentDidMount() {
    if (this.props.automationDetailsState.selectedActionType) {
      const selectedIndex = this.state.menuItems.findIndex(
        (item) => item.actionType === this.props.automationDetailsState.selectedActionType.actionType
      )
      if (selectedIndex !== -1) {
        this.handleMenuClick([selectedIndex], false)
      }
    } else {
      this.setState({ selectedIndexesArr: [] })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.actionTypeItems) !== JSON.stringify(this.props.actionTypeItems)) {
      this.setState({
        menuItems: this.props.actionTypeItems,
      })
    }
    if (
      prevProps.automationDetailsState.selectedActionType &&
      isNullOrUndefined(this.props.automationDetailsState.selectedActionType)
    ) {
      this.setState({ selectedIndexesArr: [] })
    }
  }

  render() {
    let buttonClassName = "creation-generic-dropdown-button "
    let header = "Choose action"
    if (this.state.selectedIndexesArr.length > 0) {
      header = this.state.menuItems[this.state.selectedIndexesArr[0]].name
      buttonClassName += "darker-text "
    }

    return (
      <div className={"text-field " + (this.props.actionTypeValidationErrors ? "error " : "")}>
        <div className="creation-generic-dropdown-label">Action Type</div>
        <GenericDropdown
          header={header}
          selectOptionCallback={(index) => {
            this.handleMenuClick(index)
          }}
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowAutoSuggest={false}
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection={false}
          buttonClassName={buttonClassName}
          menuClassName={"creation-generic-dropdown-menu"}
        />
        {this.props.actionTypeValidationErrors}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(ActionTypeDropdown)
