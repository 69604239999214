import React from "react"
import { Snackbar } from "@material-ui/core"
import { connect } from "react-redux"
import { notificationRemove } from "../actions/commonActions"
import ErrorTriangle from "../../resources/images/ErrorTriangle.png"
import Refresh from "../../resources/svgs/Refresh.svg"
import { shouldUpdateCampaignV2Page, handleHideCampaignsV2Page } from "../../campaignsV2/campaignActions"
import { navigationDrawerStateChange } from "../../navigationBar/navigationBarActions"
import DrawerTypes from "../consts/drawerTypes"
import { eventsTracker } from "../../api/eventsTracker"

class Notifications extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  close = (item) => {
    this.props.dispatch(notificationRemove(item))
  }

  refresh = () => {
    window.location.reload()
  }

  getUpdatedCampaignsV2Report = (item) => {
    this.props.dispatch(shouldUpdateCampaignV2Page(true))
    this.close(item)
  }

  handleHide = (item) => {
    this.props.dispatch(handleHideCampaignsV2Page(true))
    this.close(item)
  }

  showActivityDrawer = (notificationItem) => {
    this.props.dispatch(navigationDrawerStateChange(true, DrawerTypes.ACTIVITIES))
    this.close(notificationItem)
  }

  render() {
    let snackbars = []
    let headerHeight = 60
    let itemHeight = 46
    let marginBetweenItems = 10
    this.props.notifications.forEach((item, index) => {
      let isOpen = true
      let notificationContent = null

      switch (item.notificationType) {
        case "error":
          let getAdditionalNotificationElementFunc = item.getAdditionalNotificationElement ? (
            <span onClick={() => this.close(item)}>{item.getAdditionalNotificationElement()}</span>
          ) : null
          notificationContent = (
            <>
              <span className="message">
                <img src={ErrorTriangle} className="icon" />
                {item.message}
              </span>
              {getAdditionalNotificationElementFunc}
              <span className="action" onClick={() => this.close(item)}>
                Hide
              </span>
            </>
          )
          break

        case "app-refresh":
          notificationContent = (
            <>
              <span className="message">{item.message}</span>
              <span className="refresh" onClick={this.refresh}>
                <Refresh />
                Refresh
              </span>
            </>
          )
          break

        case "campaignsV2-refresh":
          notificationContent = (
            <>
              <span className="message">{item.message}</span>
              <div className="d-flex">
                <span
                  className="refresh"
                  onClick={() => {
                    eventsTracker.trackRefreshClicked(item.message)
                    this.getUpdatedCampaignsV2Report(item)
                  }}
                >
                  <Refresh />
                  Refresh
                </span>
                <span
                  className="action"
                  onClick={() => {
                    eventsTracker.trackHideRefreshMessage(item.message)
                    this.handleHide(item)
                  }}
                >
                  Hide
                </span>
              </div>
            </>
          )
          break

        case "campaigns-bulk-status-change-error":
        case "campaigns-bulk-status-change-warning":
          notificationContent = (
            <>
              <span className="message">
                <img src={ErrorTriangle} className="icon" />
                {item.message}
              </span>
              <span className="refresh" onClick={() => this.showActivityDrawer(item)}>
                Show
              </span>
              <span className="action" onClick={() => this.close(item)}>
                Hide
              </span>
            </>
          )
          break
      }

      snackbars.push(
        <Snackbar
          classes={{ root: "notification " + item.notificationType }}
          key={index}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isOpen}
          style={{ top: headerHeight + marginBetweenItems + index * (itemHeight + marginBetweenItems) }}
        >
          <div className="notification-content">{notificationContent}</div>
        </Snackbar>
      )
    })

    return <div>{snackbars}</div>
  }
}

function mapStateToProps(state, ownProps) {
  return {
    notifications: state.app.notifications,
    campaignsReportLastUpdate: state.campaignsV2.lastUpdate,
  }
}

export default connect(mapStateToProps)(Notifications)
