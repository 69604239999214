import React from "react"
import CampaignsCreationConsts from "../../campaignCreation/campaignCreationConsts"
import { CircularProgress, Menu, MenuItem, TextField } from "@material-ui/core"
import { Close, KeyboardArrowDown } from "@material-ui/icons"
import Autosuggest from "react-autosuggest"
import { focusParentWithClass } from "../../utils/funcUtils"
import SearchIcon from "../../resources/svgs/SearchIcon.svg"
import PropTypes from "prop-types"

function getSuggestionValue(suggestion) {
  return suggestion
}

function renderSuggestionsContainer(options) {
  return null
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return null
}

export default class GenericDropdown extends React.Component {
  constructor() {
    super()

    this.state = {
      search: "",
      suggestions: [],
      allSuggestions: [],
      currentMenu: [],
      currentPage: 1,
    }

    this.rootMenuItems = []
    this.menuItemsToHtml = new Map()
  }

  componentWillMount() {
    this.initRootMenu()
  }

  handleSuggestionsClearRequested = () => {
    let arr = this.initSuggestions()

    this.setState(
      {
        suggestions: arr,
        allSuggestions: arr,
      },
      () => {
        if (this.state.search.length === 0) {
          this.setState({
            search: "",
            currentMenu: this.rootMenuItems,
          })
        }
      }
    )
  }

  handleClearSearchFilter = () => {
    this.setState(
      {
        suggestions: this.state.allSuggestions,
      },
      () => {
        this.setState({
          search: "",
          currentMenu: this.rootMenuItems,
        })
      }
    )
  }

  handleChange = (event) => {
    let search = event.target.value
    this.setState({
      search: search,
    })
  }

  showSuggestionsMenu = () => {
    let items = []
    let menuItemsToHtml = this.menuItemsToHtml

    this.state.suggestions.forEach((suggestion) => {
      items.push(menuItemsToHtml.get(suggestion))
    })

    return items
  }

  handleSuggestionsFetchRequested = (input) => {
    this.setState(
      {
        suggestions: this.getSuggestions(input.value),
      },
      () => {
        this.setState({
          currentMenu: this.showSuggestionsMenu(),
        })
      }
    )
  }

  renderInput = (inputProps) => {
    const { autoFocus, value, ref } = inputProps
    let icon = null

    if (this.state.search !== "") {
      icon = (
        <span onClick={this.handleClearSearchFilter}>
          <Close className="clear-filter-text" />
        </span>
      )
    } else {
      icon = (
        <span>
          <SearchIcon className={"search-icon"} />
        </span>
      )
    }

    return (
      <div className={"d-flex align-items-center text-field-wrapper"}>
        <TextField
          id="generic-dropdown-search-text-field"
          classes={{ root: "text-field" }}
          autoFocus={autoFocus}
          value={value}
          inputRef={ref}
          InputProps={{
            inputProps,
          }}
        />
        {icon}
      </div>
    )
  }

  getSuggestions = (value) => {
    if (value !== undefined) {
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length

      if (inputLength === 0) {
        return this.state.allSuggestions
      }

      let suggestions = this.state.allSuggestions.filter((suggestion) => {
        return suggestion.toLowerCase().includes(inputValue)
      })

      return suggestions
    }
  }

  initSuggestions = () => {
    return Array.from(this.menuItemsToHtml.keys())
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.options.length >= this.props.minItemsForAutosuggest && this.props.allowAutoSuggest) {
      if (this.props.options !== prevProps.options || this.props.selectedIndexes !== prevProps.selectedIndexes) {
        this.initRootMenu()
        this.setState({
          currentMenu: this.rootMenuItems,
          allSuggestions: this.initSuggestions(),
        })

        if (this.state.search !== "") {
          this.handleSuggestionsFetchRequested({ value: this.state.search })
        }
      }
    }

    if (this.props.open) {
      if (this.props.open !== prevProps.open) {
        this.handleClearSearchFilter()
      } else if (!this.props.isLoading && this.props.isLoading !== prevProps.isLoading) {
        this.setState({
          allSuggestions: this.initSuggestions(),
        })
      }
    }
  }

  initRootMenu = () => {
    if (this.props.options.length >= this.props.minItemsForAutosuggest && this.props.allowAutoSuggest) {
      let menuItemsToHtml = new Map()

      this.props.options.forEach((option, index) => {
        let checkbox =
          this.props.allowMultiple && !option.isTitle ? (
            <input type="checkbox" checked={this.props.selectedIndexes.includes(index)} value={index} readOnly={true} />
          ) : null
        let image = option.hasOwnProperty("imageLink") ? (
          <div className="generic-dropdown-image">
            <img className="post-pages-order-image" src={option.imageLink} />
          </div>
        ) : null

        menuItemsToHtml.set(
          option.name,
          <MenuItem
            className={"default-menu-item " + (option.optionClassName ? option.optionClassName : "")}
            key={index}
            disabled={option.isTitle}
            selected={!this.props.allowMultiple ? this.props.selectedIndexes.includes(index) : false}
            onClick={(event) => this.selectOption(index)}
          >
            {checkbox} {image}{" "}
            <div className="default-menu-item-text" title={option.name}>
              {option.name}
            </div>
          </MenuItem>
        )
      })

      this.menuItemsToHtml = menuItemsToHtml
      this.rootMenuItems = Array.from(menuItemsToHtml.values())
    }
  }

  selectOption = (index) => {
    let selectedIndexes = this.props.selectedIndexes.slice()

    if (selectedIndexes.includes(index)) {
      if (selectedIndexes.length > 1 || this.props.allowNoSelection) {
        selectedIndexes = selectedIndexes.filter((item) => item !== index)
      }
    } else {
      if (this.props.allowMultiple) {
        selectedIndexes.push(index)
      } else {
        selectedIndexes = [index]
      }
    }
    this.props.selectOptionCallback(selectedIndexes)
  }

  handleFocusChange = (event) => {
    focusParentWithClass(event, "react-autosuggest-input-menu-item")
  }

  openDropdown = (event) => {
    this.props.openDropdownCallback(event)
  }

  areAllOptionsSelected = () => {
    let selectableOptions = this.props.options.filter((option) => !option.isTitle)

    return selectableOptions.length === this.props.selectedIndexes.length
  }

  selectAllOptions = () => {
    // If all the options are selected - send an empty array in order to toggle off all the checkboxes
    let selectedArr = []

    if (!this.areAllOptionsSelected()) {
      // If some of the options are not selected - send an array with all the indexes [0...options.length] in order to mark all the checkboxes as selected
      let selectableOptionsIndexes = this.props.options
        .map((option, index) => {
          return { ...option, optionIndex: index }
        })
        .filter((option) => !option.isTitle)
        .map((option) => option.optionIndex)
      selectedArr = selectableOptionsIndexes
    }

    this.props.selectOptionCallback(selectedArr)
  }

  onEnterPress = (event) => {
    if (this.props.onEnterPressCallback && event.key === "Enter") {
      this.props.onEnterPressCallback(this.state.search)
      this.setState({
        search: "",
      })
    }
  }

  scrollPagingHandler = (event) => {
    let target = event.target

    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      })
    }
  }

  render() {
    let menuContent = []
    let selectAllMenuItem = null
    let emptyOptionMenuItem = null
    let additionalMenuClasses = ""
    let loader = null

    if (!this.props.isLoading) {
      if (
        this.props.allowMultiple &&
        this.props.allowSelectAll &&
        this.props.options.length >= this.props.minItemsForSelectAll &&
        this.state.search === ""
      ) {
        selectAllMenuItem = (
          <MenuItem className="default-menu-item" key={"All"} onClick={this.selectAllOptions}>
            <input type="checkbox" checked={this.areAllOptionsSelected()} readOnly={true} />
            {"All"}
          </MenuItem>
        )
      }

      if (!this.props.allowMultiple && this.props.showEmptyOption) {
        emptyOptionMenuItem = (
          <MenuItem className="default-menu-item" key={"Empty"} onClick={() => this.props.selectOptionCallback([])}>
            {this.props.emptyOptionText}
          </MenuItem>
        )
      }

      // If there's only one option, we don't show the dropdown and the only option will be chosen as default
      if (this.props.options.length <= 1 && !this.props.alwaysShowDropdown) {
        return null
      }

      if (this.props.options.length >= this.props.minItemsForAutosuggest && this.props.allowAutoSuggest) {
        additionalMenuClasses += "has-autosuggest"

        menuContent = [
          <MenuItem key="auto-suggest" classes={{ root: "react-autosuggest-input-menu-item" }}>
            <Autosuggest
              renderInputComponent={this.renderInput}
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
              renderSuggestionsContainer={renderSuggestionsContainer}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={{
                autoFocus: true,
                placeholder: this.props.autoSuggestPlaceholder,
                value: this.state.search,
                onChange: this.handleChange,
                onKeyDown: this.handleFocusChange,
                onKeyPress: this.onEnterPress,
              }}
            />
          </MenuItem>,
          ...this.state.currentMenu,
        ]

        if (selectAllMenuItem) {
          menuContent.splice(1, 0, selectAllMenuItem)
        } else if (emptyOptionMenuItem) {
          menuContent.splice(1, 0, emptyOptionMenuItem)
        }
      } else {
        if (selectAllMenuItem) {
          menuContent.push(selectAllMenuItem)
        } else if (emptyOptionMenuItem) {
          menuContent.push(emptyOptionMenuItem)
        }

        this.props.options.forEach((option, index) => {
          let checkbox =
            this.props.allowMultiple && !option.isTitle ? (
              <input
                type="checkbox"
                checked={this.props.selectedIndexes.includes(index)}
                value={index}
                readOnly={true}
              />
            ) : null

          menuContent.push(
            <MenuItem
              className={"default-menu-item " + (option.optionClassName ? option.optionClassName : "")}
              disabled={option.isTitle}
              key={index}
              selected={!this.props.allowMultiple ? this.props.selectedIndexes.includes(index) : false}
              onClick={(event) => this.selectOption(index)}
              classes={this.props.menuItemClasses}
            >
              {checkbox}{" "}
              <div className="default-menu-item-text" title={option.name}>
                {option.name}
              </div>
            </MenuItem>
          )
        })
      }
    } else {
      loader = (
        <div className="d-flex justify-content-center">
          <CircularProgress size={20} />
        </div>
      )
    }

    if (this.props.allowScrollPaging) {
      // If paging is enabled, render only the correct amount of items
      menuContent = menuContent.filter((option, index) => {
        return index < this.state.currentPage * this.props.itemsPerPage
      })
    }

    // Error Validations
    let searchError = null
    if (this.state.search && this.props.searchErrorCallback) {
      searchError = this.props.searchErrorCallback(this.state.search)
    }

    if (this.props.maxOptionsSelectedError) {
      menuContent.splice(1, 0, this.props.maxOptionsSelectedError)
    }

    return (
      <>
        <div
          className={"generic-dropdown-selection fit-content clickable " + this.props.buttonClassName}
          onClick={this.openDropdown}
        >
          <span className={this.props.headerClassName}>{this.props.header}</span>
          <KeyboardArrowDown className="arrow" />
        </div>
        <Menu
          open={this.props.open}
          anchorEl={this.props.anchorEl}
          transformOrigin={this.props.transformOrigin}
          onClose={this.props.closeDropdownCallback}
          classes={{
            paper:
              "default-menu generic-dropdown-popup creation-dropdown " +
              additionalMenuClasses +
              " " +
              this.props.menuClassName,
          }}
          onEntered={() => {
            let textField = document.querySelector("#generic-dropdown-search-text-field")
            if (textField) {
              textField.focus()
            }

            if (this.props.allowScrollPaging) {
              // Registering the scroll handler for paging and resetting the current page when popup opens
              this.setState({
                currentPage: 1,
              })

              document.querySelector(".generic-dropdown-popup").addEventListener("scroll", this.scrollPagingHandler)
            }
          }}
          onExiting={() => {
            if (this.props.allowScrollPaging) {
              // Unregistering scroll event on popup close to prevent dandling events (optimization)
              document.querySelector(".generic-dropdown-popup").removeEventListener("scroll", this.scrollPagingHandler)
            }
          }}
          getContentAnchorEl={null}
        >
          {loader}
          {searchError ? [menuContent[0], searchError] : menuContent}
          {this.props.menuCustomAdditionalElements}
        </Menu>
      </>
    )
  }
}

GenericDropdown.propTypes = {
  selectOptionCallback: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      image: PropTypes.string,
      isTitle: PropTypes.bool,
      optionClassName: PropTypes.string,
    })
  ),
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  selectedIndexes: PropTypes.arrayOf(PropTypes.number),
  openDropdownCallback: PropTypes.func,
  closeDropdownCallback: PropTypes.func,
  alwaysShowDropdown: PropTypes.bool,
  allowAutoSuggest: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  buttonClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  minItemsForAutosuggest: PropTypes.number,
  minItemsForSelectAll: PropTypes.number,
  showEmptyOption: PropTypes.bool,
  emptyOptionText: PropTypes.string,
  menuCustomAdditionalElements: PropTypes.arrayOf(PropTypes.element),
  onEnterPressCallback: PropTypes.func,
  autoSuggestPlaceholder: PropTypes.string,
  headerClassName: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  isLoading: PropTypes.bool,
  menuItemClasses: PropTypes.object,
  allowScrollPaging: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  searchErrorCallback: PropTypes.func,
  maxOptionsSelectedError: PropTypes.element,
}

GenericDropdown.defaultProps = {
  buttonClassName: "",
  menuClassName: "",
  alwaysShowDropdown: true,
  allowAutoSuggest: true,
  allowMultiple: false,
  minItemsForAutosuggest: CampaignsCreationConsts.MIN_NUMBER_OF_ITEMS_WITH_AUTOSUGGEST,
  minItemsForSelectAll: CampaignsCreationConsts.MIN_NUMBER_OF_ITEMS_FOR_SELECT_ALL,
  allowNoSelection: true,
  allowSelectAll: false,
  showEmptyOption: false,
  emptyOptionText: "",
  header: "",
  menuCustomAdditionalElements: null,
  autoSuggestPlaceholder: "Search",
  headerClassName: "",
  transformOrigin: { vertical: -37, horizontal: 0 },
  isLoading: false,
  menuItemClasses: {},
  allowScrollPaging: false,
  itemsPerPage: 30,
}
