import React, { useState, useEffect } from "react"
import { Chip, TextField } from "@material-ui/core"
import { Close } from "@material-ui/icons"

const KeywordChipInput = ({
  selectedKeywords,
  label,
  placeholder,
  description,
  handleChange,
  articleKeywords,
  campaignNameValidationErrors,
  campaignNameValidationWarnings,
}) => {
  const [keywords, setKeywords] = useState(selectedKeywords || [])
  const [inputText, setInputText] = useState("")
  const [isInputFocused, setIsInputFocused] = useState(false)

  useEffect(() => {
    setKeywords(selectedKeywords || [])
  }, [selectedKeywords])

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputText.trim() !== "") {
      const newKeywords = inputText
        .split(",")
        .map((keyword) => keyword.trim())
        .filter((keyword) => keyword !== "" && !keywords.includes(keyword))
      const updatedKeywords = [...keywords, ...newKeywords]
      setKeywords(updatedKeywords)
      handleChange("selectedKeywords", null, updatedKeywords)
      setInputText("")
    }
  }

  const handleDelete = (keywordToDelete) => {
    const updatedKeywords = keywords.filter((keyword) => keyword !== keywordToDelete)
    setKeywords(updatedKeywords)
    handleChange("selectedKeywords", null, updatedKeywords)
  }

  const handleRemoveAll = () => {
    setKeywords([])
    handleChange("selectedKeywords", null, [])
  }

  return (
    <div
      className={
        "text-field campaign-name-input " +
        (campaignNameValidationErrors ? "error " : "") +
        (campaignNameValidationWarnings && !campaignNameValidationErrors ? "warning" : "")
      }
    >
      <TextField
        label={label}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        InputProps={{
          className: "text-field-line",
          endAdornment: inputText === "" && isInputFocused && (
            <div style={{ display: "flex", justifyContent: "end", width: "90%", opacity: "0.5" }}>
              <small>Press enter to add</small>
            </div>
          ),
        }}
        placeholder={placeholder}
        InputLabelProps={{
          classes: { shrink: "text-field-color" },
        }}
        value={inputText}
        onChange={(event) => setInputText(event.target.value)}
        onKeyDown={handleKeyDown}
        margin="normal"
      />
      {keywords.length === 0 && <small style={{ opacity: "0.5" }}>{description}</small>}
      <div className="chips-container flex-wrap d-flex">
        {keywords.map((keyword, index) => (
          <Chip
            data-tip={articleKeywords.includes(keyword) ? "From Article" : "Manually Added"}
            data-class="tooltip-custom type-icon-info shrink-tip"
            classes={{ root: "chip-item" }}
            key={keyword}
            label={keyword}
            deleteIcon={<Close className="delete-icon" />}
            onDelete={() => handleDelete(keyword)}
          />
        ))}
        {keywords.length > 0 ? (
          <div className="clear-tags clickable d-flex align-items-center" onClick={handleRemoveAll}>
            Remove all keywords
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default KeywordChipInput
