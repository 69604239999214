import React from "react"
import FilterTypes from "../../../consts/filterTypesV2"
import Moment from "moment/moment"

export class ValueFilter {
  constructor() {}

  static getMenu = (filter) => {
    return (mainFilterComponent) => {
      if (
        mainFilterComponent.state.filter.filterValueType === FilterTypes.filterValueTypes.NUMBER ||
        mainFilterComponent.state.filter.filterValueType === FilterTypes.filterValueTypes.ID
      ) {
        return mainFilterComponent.showNumberValueMenu()
      } else {
        return mainFilterComponent.showDateValueMenu()
      }
    }
  }

  static getSuggestions = (mainFilterComponent, filter) => {
    let items = []

    if (!filter.showFilter || filter.showFilter()) {
      items.push({
        name: filter.filterName,
        key: filter.filterType,
        filter: {
          filterName: filter.filterName,
          handleClick: () => {
            let dateValues = {}

            if (filter.filterValueType === FilterTypes.filterValueTypes.DATE) {
              dateValues["primaryValue"] = Moment().startOf("day")
              dateValues["secondaryValue"] = Moment().startOf("day")
            }
            mainFilterComponent.setState(
              {
                ...dateValues,
                search: "",
                filter: Object.assign({}, mainFilterComponent.state.filter, filter, {
                  filterOperator: filter.defaultOperator,
                }),
              },
              () => mainFilterComponent.pushMenu(filter.getMenu(filter))
            )
          },
        },
      })
    }

    return items
  }
}
