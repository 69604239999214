let articlesTypeConsts = {
  affiliation: {
    title: "Affiliation",
    name: "affiliation",
    tag: "aff",
  },

  ads_for_search: {
    title: "Search",
    name: "ads_for_search",
    tag: "srch",
  },
  content: {
    title: "Content",
    name: "content",
    tag: "cnt",
  },
}
Object.freeze(articlesTypeConsts)
export default articlesTypeConsts
