import ActivityService from "../api/activityService"
import ActivityStatus from "../common/consts/activityStatus"
import NotificationsService from "../api/notificationsService"
import CampaignCreationService from "../api/campaignCreationService"
import { openWizardFromExistingState } from "../campaignCreation/campaignCreationActions"
import { eventsTracker } from "../api/eventsTracker"
import NavigationBarActionsTypes from "./navigationBarActionTypes"
import { store } from "../app/store"

export function navigationDrawerStateChange(openDrawer, drawerComponent = null) {
  return { type: NavigationBarActionsTypes.NAVIGATION_DRAWER_STATE_CHANGE, openDrawer, drawerComponent }
}

export function getAllActivitiesResponse(activities) {
  return { type: NavigationBarActionsTypes.GET_ACTIVITIES, activities }
}

export function getAllActivitiesAsync(fromTime = null) {
  return (dispatch) => {
    ActivityService.getActivities(fromTime).then((result) => {
      dispatch(getAllActivitiesResponse(result.data.activities))
    })
  }
}

export function showMoreActivitiesResponse(activities) {
  return { type: NavigationBarActionsTypes.SHOW_MORE_ACTIVITIES, activities }
}

export function dismissLocalActivity(activityId) {
  return { type: NavigationBarActionsTypes.DISMISS_ACTIVITY, activityId }
}

export function dismissAllLocalActivities() {
  return { type: NavigationBarActionsTypes.DISMISS_ACTIVITIES }
}

export function setLocalActivitiesPending(activityIds) {
  return { type: NavigationBarActionsTypes.SET_ACTIVITIES_PENDING, activityIds }
}

export function showMoreActivitiesAsync(lastActivityTime) {
  return (dispatch) => {
    ActivityService.showMoreActivities(lastActivityTime).then((result) => {
      dispatch(showMoreActivitiesResponse(result.data.activities))
    })
  }
}

export function dismissActivity(activityId) {
  return (dispatch) => {
    dispatch(dismissLocalActivity(activityId))
    ActivityService.updateActivity(activityId, ActivityStatus.DISMISSED)
  }
}

export function dismissAllActivities() {
  return (dispatch) => {
    dispatch(dismissAllLocalActivities())
    ActivityService.dismissAllActivities()
  }
}

export function retryActivityWithMinderRequest(activityId, requestId) {
  return (dispatch) => {
    dispatch(setLocalActivitiesPending([activityId]))
    ActivityService.updateActivity(activityId, ActivityStatus.PENDING).then((result) => {
      ActivityService.retryActivityWithMinderRequest(requestId).then((result) => {})
    })
  }
}

export function retryActivity(activityId) {
  return (dispatch) => {
    dispatch(setLocalActivitiesPending([activityId]))
    ActivityService.updateActivity(activityId, ActivityStatus.PENDING).then(() => {
      ActivityService.retryActivity(activityId)
    })
  }
}

export function retryMultipleActivities(activityIds, minderRequestIds) {
  return (dispatch) => {
    dispatch(setLocalActivitiesPending(activityIds))
    ActivityService.retryMultipleActivities(activityIds, minderRequestIds).then((result) => {})
  }
}

export function dismissAdminNotification(adminNotification) {
  return { type: NavigationBarActionsTypes.DISMISS_ADMIN_NOTIFICATION, adminNotification }
}

export function getAllNotificationsResponse(userId, adminNotifications) {
  return { type: NavigationBarActionsTypes.GET_NOTIFICATIONS, userId, adminNotifications }
}

export function getAllNotifications({ userId = null, fromTime = null, networkId } = {}) {
  return (dispatch) => {
    NotificationsService.getAllNotifications(fromTime, networkId).then((result) => {
      dispatch(getAllNotificationsResponse(userId, result.data.admin_notifications))
    })
  }
}

export function drawerLoading(isLoading) {
  return { type: NavigationBarActionsTypes.DRAWER_LOADING, isLoading }
}

export function getAdminNotificationsResponse(adminNotifications) {
  return { type: NavigationBarActionsTypes.GET_ADMIN_NOTIFICATIONS_RESPONSE, adminNotifications }
}

export function getAdminNotifications(scope) {
  return (dispatch) => {
    NotificationsService.getAdminNotifications(scope).then((result) => {
      dispatch(getAdminNotificationsResponse(result.data))
    })
  }
}
