import React from "react"
import { connect } from "react-redux"
import Moment from "moment"
import Consts from "../../../app/consts"
import { chartAxisStyleUpdater, generateChart, generateChartSeries } from "../../../utils/chartUtils"
import ChartColors from "../../../common/consts/chartColors"
import EmptyChartIndicator from "../../../common/components/emptyChartIndicator"

const verticalAxes = {
  "actions-axis": {
    title: { text: "" },
    selectionIndex: 1,
    id: "actions-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value:,.0f}" },
    maxPadding: 0.1,
  },
}

class AutomationPopupActionHistoryChart extends React.Component {
  render() {
    let emptyChartIndicator = null

    if (!this.props.hasData) {
      emptyChartIndicator = <EmptyChartIndicator />
    }

    return (
      <div className="campaign-popup-chart-wrapper">
        {emptyChartIndicator}
        <div className="bid-history-chart campaign-popup-chart" id="action-history" />
      </div>
    )
  }

  drawChart = () => {
    let generateSeries = (title, dataItem, multiplyDataBy, axisName, visible, color, moreOptions) => {
      let moreChartOptions = Object.assign({}, moreOptions, {
        data: this.props.actionHistory.map((item) => {
          return [Moment(item.hour, Consts.SERVER_DATETIME_FORMAT).valueOf(), item[dataItem] * multiplyDataBy]
        }),
        color: color,
      })

      return generateChartSeries(title, axisName, visible, moreChartOptions)
    }

    let xAxisArr = [
      generateSeries("", "actions", 1, "actions-axis", true, ChartColors.BY_HOUR_SESSIONS, { type: "column" }),
    ]
    let yAxisArr = [verticalAxes["actions-axis"]]

    let chartSpecificOptions = {
      tooltip: {
        dateTimeLabelFormats: {
          day: "%A, %b %e, %H:%M",
        },
        useHTML: true,
        followPointer: true,
        outside: true,
        distance: 40,
        hideDelay: 0,
      },
      chart: {
        marginTop: 1,
        marginLeft: 1,
        marginRight: 1,
        zoomType: "xy",
      },
      legend: {
        enabled: false,
      },
      yAxis: yAxisArr,
      series: xAxisArr,
      xAxis: {
        minPadding: 0.05,
        maxPadding: 0.05,
        tickInterval: 3600 * 1000, // one hour
        tickWidth: 1,
        minRange: 1,
        tickmarkPlacement: "on",
        tickLength: 5,
        labels: {
          formatter() {
            let label = this.axis.defaultLabelFormatter.call(this)

            if (new Date(this.value).getUTCHours() === 0) {
              return `<span style="font-weight: 800; color: #777">${label}</span>`
            }

            return label
          },
        },
      },
    }

    let chart = generateChart("action-history", chartSpecificOptions)
    chartAxisStyleUpdater(chart)
  }

  componentDidMount() {
    this.drawChart()
  }

  componentDidUpdate() {
    this.drawChart()
  }
}

function mapStateToProps(state, ownProps) {
  return {
    hasData: state.automationPopup.actionHistory.hasData,
    actionHistory: state.automationPopup.actionHistory.dataForChart,
  }
}

export default connect(mapStateToProps)(AutomationPopupActionHistoryChart)
