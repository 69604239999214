import React from "react"
import { connect } from "react-redux"
import { Dialog, CircularProgress } from "@material-ui/core"
import { withRouter } from "react-router"
import { automationPopupClose, automationPopupDataLoaded, resetAutomationPopupDates } from "./automationPopupActions"
import AutomationPopupMain from "./components/automationPopupMain"
import { notificationAdd } from "../common/actions/commonActions"
import { eventsTracker } from "../api/eventsTracker"
import AutomationService from "../api/automationService"

class AutomationPopup extends React.Component {
  isCurrentlyClosingPopup = false

  componentDidMount() {
    if (!this.props.automation && this.props.isOpen) {
      // Automation popup will load on mount only if we're in a direct automation popup route
      // (browsing directly to /automation)
      this.isCurrentlyClosingPopup = false

      this.getAutomationData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let isAutomationPopupLocation = Boolean(this.props.location.state && this.props.location.state.isAutomationPopup)

    if (!prevProps.isOpen && this.props.isOpen) {
      // Automation popup is open
      this.isCurrentlyClosingPopup = false

      if (isAutomationPopupLocation) {
        if (this.props.automation) {
          eventsTracker.trackAutomationPopupOpened(this.props.automation.name)
        } else {
          this.getAutomationData()
        }
      }
    } else if (prevProps.isOpen && !this.props.isOpen && !this.isCurrentlyClosingPopup) {
      // Automation popup is closed by moving through history (browser's back button)
      this.props.dispatch(automationPopupClose(false))
    }
  }

  getAutomationData = () => {
    const ruleId = this.props.routeParams.ruleId

    AutomationService.getSingleAutomation(ruleId)
      .then((result) => {
        this.props.dispatch(automationPopupDataLoaded(result.data.rule))
      })
      .catch((error) => {
        if (error) {
          this.props.dispatch(notificationAdd("There was an error loading the rule data"))
          this.closePopup()
        }
      })
  }

  closePopup = () => {
    if (!this.isCurrentlyClosingPopup) {
      this.isCurrentlyClosingPopup = true
      this.props.dispatch(automationPopupClose())
    }
  }

  render() {
    let popupContent

    if (this.props.automation) {
      popupContent = <AutomationPopupMain closeFunction={this.closePopup} />
    } else {
      popupContent = (
        <div className="d-flex align-items-center justify-content-center" style={{ flex: 1 }}>
          <CircularProgress className="loader" size={40} />
        </div>
      )
    }

    return (
      <Dialog
        fullScreen
        open={this.props.isOpen}
        BackdropProps={{
          classes: { root: "campaign-popup-backdrop" },
        }}
        onClose={this.closePopup}
        onEnter={() => {
          this.props.dispatch(resetAutomationPopupDates())
        }}
        classes={{ paper: "campaign-popup", root: "campaign-popup-container" }}
      >
        {popupContent}
      </Dialog>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    automation: state.automationPopup.automation,
    datePickerDates: {
      startDate: state.automationPopup.datePickerDates.startDate,
      endDate: state.automationPopup.datePickerDates.endDate,
    },
    routeParams: ownProps.params,
  }
}

const connectedAutomationPopup = connect(mapStateToProps)(AutomationPopup)
export default withRouter(connectedAutomationPopup)
