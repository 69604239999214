import axios from "axios"
import Consts from "../app/consts"

export default class CountryService {
  static getCountries() {
    return axios.get(Consts.BASE_API_URL + "/api/countries")
  }

  static getRecentCountries() {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/countries/recent_countries")
  }

  static increaseRecentCountries(countryCodes) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/countries/increase_recent_countries", {
      countries_codes: countryCodes,
    })
  }

  static getCountry_groups() {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/campaign_management/country_groups")
  }
}
