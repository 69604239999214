class draftsActionTypes {}
draftsActionTypes.FETCH_DRAFTS_RESPONSE = "FETCH_DRAFTS_RESPONSE"
draftsActionTypes.RESET_DRAFTS_DATES = "RESET_DRAFTS_DATES"
draftsActionTypes.DRAFTS_LIST_LOADING = "DRAFTS_LIST_LOADING"
draftsActionTypes.DRAFTS_DATEPICKER_DATES_SELECTED = "DRAFTS_DATEPICKER_DATES_SELECTED"
draftsActionTypes.ROLLBACK_DRAFTS_PAGE = "ROLLBACK_DRAFTS_PAGE"
draftsActionTypes.UPDATE_ITEMS_PER_PAGE = "UPDATE_ITEMS_PER_PAGE"
draftsActionTypes.RESET_DRAFTS_PAGINATION = "RESET_DRAFTS_PAGINATION"
draftsActionTypes.UPDATE_DRAFTS_PAGE = "UPDATE_DRAFTS_PAGE"
draftsActionTypes.SHOULD_UPDATE_DRAFTS_PAGE = "SHOULD_UPDATE_DRAFTS_PAGE"
draftsActionTypes.UPDATE_FILTER_DRAFTS = "UPDATE_FILTER_DRAFTS"
draftsActionTypes.UPDATE_DRAFT_STATUS_TO_IN_PROGRESS = "UPDATE_DRAFT_STATUS_TO_IN_PROGRESS"
draftsActionTypes.RESET_DRAFTS_FOR_RETRY = "RESET_DRAFTS_FOR_RETRY"
export default draftsActionTypes
