import React from "react"
import { connect } from "react-redux"
import WarningIconSmall from "../../resources/svgs/WarningIconSmall.svg"
import AutomationName from "./fields/automationName"
import SourceDropdown from "./dropdowns/sourceDropdown"
import ActionTypeDropdown from "./dropdowns/actionTypeDropdown"
import ValueTypeDropdown from "./dropdowns/valueTypeDropdown"
import ActionValue from "./fields/actionValue"
import Condition from "./condition"
import TimeFrameDropdown from "./dropdowns/timeFrameDropdown"
import FrequencyDropdown from "./dropdowns/frequencyDropdown"
import Consts from "../../app/consts"
import AutomationCreationConsts from "../automationCreationConsts"
import StatusActionDropdown from "./dropdowns/statusActionDropdown"
import NetworkDropdown from "./dropdowns/networkDropdown"
import ModuleTypes from "../../common/consts/moduleTypes"
import { isNullOrUndefined } from "../../utils/funcUtils"
import OperationTypeDropdown from "./dropdowns/operationTypeDropdown"
import ActionLimit from "./fields/actionLimit"
import CampaignsConsts from "../../campaignsV2/campaignsConsts"
import automationCreationConsts from "../automationCreationConsts"

class AutomationCreationDetails extends React.Component {
  generateValidationError = (validationErrors, field) => {
    let error = validationErrors.get(field)

    if (!this.props.automationDetailsState.validationErrorsVisible) {
      return null
    }

    if (error) {
      return (
        <div className="validation-error">
          <WarningIconSmall />
          <span className="text">{validationErrors.get(field)}</span>
        </div>
      )
    }

    return null
  }

  render() {
    let validationErrors = new Map()
    let automationNameValidationErrors = null
    let actionTypeValidationErrors = null
    let conditionsValidationErrors = null
    let statusActionValidationErrors = null
    let actionValueValidationErrors = null
    let actionLimitValidationErrors = null
    let networkValidationErrors = null
    const selectedActionType = this.props.automationDetailsState.selectedActionType?.actionType
    const subSourceActionTypes = AutomationCreationConsts.SUB_SOURCE_ACTION_TYPES.map((item) => item.actionType)
    const isTaboolaSelected =
      this.props.automationDetailsState.selectedSource?.providerId === CampaignsConsts.TABOOLA_PROVIDER_ID
    const actionTypeItems = isTaboolaSelected
      ? [...automationCreationConsts.ACTION_TYPES, ...automationCreationConsts.SUB_SOURCE_ACTION_TYPES]
      : automationCreationConsts.ACTION_TYPES
    const conditionFilterTypes = subSourceActionTypes.includes(selectedActionType)
      ? AutomationCreationConsts.CONDITIONS_SUB_SOURCE_FILTERS
      : AutomationCreationConsts.CONDITIONS_FILTERS

    if (this.props.automationDetailsState.validationErrorsVisible) {
      validationErrors = this.props.validateAutomationCreation(this.props.automationDetailsState)
      automationNameValidationErrors = this.generateValidationError(validationErrors, "automationName")
      actionTypeValidationErrors = this.generateValidationError(validationErrors, "selectedActionType")
      conditionsValidationErrors = this.generateValidationError(validationErrors, "conditions")
      statusActionValidationErrors = this.generateValidationError(validationErrors, "statusActionValue")
      actionValueValidationErrors = this.generateValidationError(validationErrors, "actionValue")
      actionLimitValidationErrors = this.generateValidationError(validationErrors, "actionLimit")
      networkValidationErrors = this.generateValidationError(validationErrors, "selectedNetwork")
    }
    const networksDropdown =
      this.props.networks.length > 1 ? (
        <NetworkDropdown
          handleSelect={this.props.handleSelect}
          networkValidationErrors={networkValidationErrors}
          networks={this.props.networks}
          currentNetwork={this.props.currentNetwork}
          selectedNetwork={this.props.automationDetailsState.selectedNetwork}
        />
      ) : null
    const automationSettingsView = (
      <>
        <AutomationName
          automationDetailsState={this.props.automationDetailsState}
          handleChange={this.props.handleChange}
          automationNameValidationErrors={automationNameValidationErrors}
          reduceMargin={!isNullOrUndefined(networksDropdown)}
        />
        <SourceDropdown
          automationDetailsState={this.props.automationDetailsState}
          handleSelect={this.props.handleSelect}
          handleSelectMultiple={this.props.handleSelectMultiple}
        />
        <div className="automation-group">
          <div className="automation-group-title">
            <span>Action</span>
          </div>
          <div className={"automation-group-fields"}>
            <ActionTypeDropdown
              automationDetailsState={this.props.automationDetailsState}
              handleSelect={this.props.handleSelect}
              handleSelectMultiple={this.props.handleSelectMultiple}
              actionTypeValidationErrors={actionTypeValidationErrors}
              actionTypeItems={actionTypeItems}
            />
            {selectedActionType === Consts.ACTION_TYPE_STATUS ? (
              <StatusActionDropdown
                automationDetailsState={this.props.automationDetailsState}
                handleSelect={this.props.handleSelect}
                statusActionValidationErrors={statusActionValidationErrors}
              />
            ) : null}
            {![Consts.ACTION_TYPE_STATUS, Consts.ACTION_TYPE_SUB_SOURCE_BLOCK].includes(selectedActionType) &&
            !isNullOrUndefined(selectedActionType) ? (
              <>
                <div className={"automation-action-value-fields flex-row"}>
                  <OperationTypeDropdown
                    automationDetailsState={this.props.automationDetailsState}
                    handleSelect={this.props.handleSelect}
                    onSelectionChange={this.props.onSelectionChange}
                  />
                  <ValueTypeDropdown
                    automationDetailsState={this.props.automationDetailsState}
                    handleSelect={this.props.handleSelect}
                  />
                  <ActionValue
                    automationDetailsState={this.props.automationDetailsState}
                    onValueChange={this.props.handleActionValueChange}
                    validationErrors={actionValueValidationErrors}
                  />
                </div>
                <ActionLimit
                  automationDetailsState={this.props.automationDetailsState}
                  handleSelect={this.props.handleSelect}
                  validationErrors={actionLimitValidationErrors}
                />
              </>
            ) : null}
          </div>
        </div>

        <div className="automation-group">
          <div className="automation-group-title">
            <span>Conditions</span>
          </div>
          <div className={"automation-group-fields"}>
            <Condition
              filterCallback={this.props.handleFilters}
              filters={this.props.automationDetailsState.conditions}
              filterTypes={conditionFilterTypes}
              conditionsValidationErrors={conditionsValidationErrors}
            />
            <TimeFrameDropdown
              automationDetailsState={this.props.automationDetailsState}
              handleSelect={this.props.handleSelect}
            />
          </div>
        </div>

        <div className="automation-group">
          <div className="automation-group-title">
            <span>Scheduling</span>
          </div>
          <div className={"automation-group-fields"}>
            <FrequencyDropdown
              automationDetailsState={this.props.automationDetailsState}
              handleSelect={this.props.handleSelect}
            />
          </div>
        </div>
      </>
    )

    return (
      <div className="automation-creation-details">
        {networksDropdown}
        {automationSettingsView}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let ownedNetworkKeys = []

  state.app.currentUser.userNetworksToModules.forEach((rolesForNetwork, networkKey) => {
    if (rolesForNetwork.has(ModuleTypes.AUTOMATION_CREATION)) {
      ownedNetworkKeys.push(networkKey)
    }
  })

  const ownedNetworks = state.app.networkToSites.filter((network) => ownedNetworkKeys.includes(parseInt(network.key)))

  return {
    isOpen: state.automationCreation.isOpen,
    currentNetwork: state.navigationBar.currentNetwork,
    networks: state.app.currentUser.is_admin ? state.app.networkToSites : ownedNetworks,
  }
}

export default connect(mapStateToProps)(AutomationCreationDetails)
