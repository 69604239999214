import { notificationAdd } from "../common/actions/commonActions"
import RequestsService from "../api/requestsService"
import { history } from "../app/store"
import AutomationPopupActionTypes from "./automationPopupActionTypes"
import AutomationService from "../api/automationService"
import AutomationsConsts from "../automations/automationsConsts"

export function automationPopupOpen(automationData = null, forceTab = null) {
  window.Intercom("trackEvent", "automation-popup-opened")

  return { type: AutomationPopupActionTypes.AUTOMATION_POPUP_OPEN, automationData, forceTab }
}

export function automationPopupDataLoaded(automationData) {
  return { type: AutomationPopupActionTypes.AUTOMATION_POPUP_DATA_LOADED, automationData }
}

export function automationPopupClose(returnToPreviousPage = true) {
  RequestsService.cancelRequest("automation-data-request")

  if (returnToPreviousPage) {
    let locationState = history.getCurrentLocation().state

    if (locationState && locationState.returnTo) {
      // Returning the user to the route he was on before opening the automation popup, we also push a location state
      // object to indicate that we're returning from the automation popup in order to prevent the window scroll reset
      // that is being made by the router.
      history.push({ pathname: locationState.returnTo, state: { isReturningFromAutomationPopup: true } })
    } else {
      // If there's no location state, it means that the user browsed directly to the automation popup page and now we
      // must send him back to his default page.
      // We want the user to be able to click "back" in the browser to browse back to the campaign popup page, so we're
      // pushing a new state '/' and then the router will take care of where he needs to be.
      history.push("/")
    }
  }

  return { type: AutomationPopupActionTypes.AUTOMATION_POPUP_CLOSE }
}

export function automationPopupDatesSelected(startDate, endDate, dateType, showLastHour) {
  return {
    type: AutomationPopupActionTypes.AUTOMATION_POPUP_DATES_SELECTED,
    startDate,
    endDate,
    dateType,
    showLastHour,
  }
}

export function automationPopupFetchActionHistoryResponse(actionHistory, firstRequest, reduxStore) {
  return {
    type: AutomationPopupActionTypes.AUTOMATION_POPUP_FETCH_ACTION_HISTORY_RESPONSE,
    isLoading: false,
    firstRequest,
    actionHistory,
    reduxStore,
  }
}

export function automationActionHistorySortBy(sortBy) {
  return { type: AutomationPopupActionTypes.AUTOMATION_POPUP_ACTION_HISTORY_SORT_BY, sortBy }
}

export function automationPopupActionHistoryShowMore() {
  return { type: AutomationPopupActionTypes.AUTOMATION_POPUP_ACTION_HISTORY_SHOW_MORE }
}

export function automationPopupLoading() {
  return { type: AutomationPopupActionTypes.AUTOMATION_POPUP_LOADING, isLoading: true }
}

export function automationPopupFetchActionHistoryAsync(automation, fromDate, toDate, firstRequest) {
  return (dispatch, getState) => {
    return AutomationService.getAutomationActionHistory(automation.id, fromDate, toDate)
      .then((result) => {
        return dispatch(automationPopupFetchActionHistoryResponse(result.data, firstRequest, getState()))
      })
      .catch((error) => {
        if (error) {
          dispatch(automationPopupFetchActionHistoryResponse([], false, getState()))
          dispatch(notificationAdd(AutomationsConsts.AUTOMATIONS_ERROR_MESSAGE))
        }
      })
  }
}

export function automationPopupCancelRequest(requestCancelToken) {
  RequestsService.cancelRequest(requestCancelToken)
}

export function resetAutomationPopupDates() {
  return { type: AutomationPopupActionTypes.AUTOMATION_POPUP_RESET_DATES }
}
