import dashboardActionTypes from "./dashboardActionTypes"
import { getEndDate, getStartDate, LAST_30_DAYS } from "../common/components/datePickerV2"
import Moment from "moment"

const dashboardState = {
  datePickerDates: {
    startDate: getStartDate(LAST_30_DAYS),
    endDate: getEndDate(LAST_30_DAYS),
    showLastHour: false,
    isGeneratedReport: false,
    dateType: LAST_30_DAYS,
  },
  isToday: false,
  lastUpdate: null,
  dashboardReport: [],
  dashboardChart: [],
  isHourlyReport: false,
}

let addCampaignsProps = (campaigns) => {
  return campaigns
    ? Object.keys(campaigns).map((key) => {
        // For each campaign, we add client entities (key and isLoading flag)
        var campaign = Object.assign({}, campaigns[key])
        campaign.key = key
        return campaign
      })
    : null
}

function dashboardReducer(state = dashboardState, action) {
  if (state == undefined || action == undefined) {
    return []
  }
  switch (action.type) {
    case dashboardActionTypes.RESET_DASHBOARD_DATES:
      return Object.assign({}, state, {
        datePickerDates: dashboardState.datePickerDates,
      })

    case dashboardActionTypes.DASHBOARD_DATEPICKER_DATES_SELECTED:
      let today = Moment()
      let isToday = false

      if (today.isSame(action.startDate, "day")) {
        isToday = true
      }

      return Object.assign({}, state, {
        datePickerDates: {
          startDate: action.startDate,
          endDate: action.endDate,
          isGeneratedReport: false,
        },
        isToday,
      })

    case dashboardActionTypes.FETCH_DASHBOARD_RESPONSE:
      return Object.assign({}, state, {
        dashboardReport: addCampaignsProps(action.campaigns),
        summaryRow: action.summaryRow,
        hasCampaigns: action.hasCampaigns,
        hasData: action.hasCampaigns,
        dashboardChart: addCampaignsProps(action.dashboardChart),
        lastUpdate: Moment().unix(),
        isLoading: false,
        isHourlyReport: action.isHourlyReport,
      })

    case dashboardActionTypes.DASHBOARD_LIST_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      })

    case dashboardActionTypes.ROLLBACK_DASHBOARD_PAGE:
      return Object.assign({}, state, {
        isLoading: false,
        hasCampaigns: true,
        dashboardReport: [],
        dashboardChart: [],
        isHourlyReport: false,
        isNextResponseUserGeneratedReport: false,
      })

    default:
      return state
  }
}
export { dashboardReducer }
