import { store } from "../app/store"
import { validateDynamicThumbnail, validateThumbnail } from "./campaignCreationActions"
import CampaignsCreationConsts from "./campaignCreationConsts"
import { isNullOrEmpty, isNullOrUndefined } from "../utils/funcUtils"
import { range } from "../utils/numberUtils"
import CampaignsConsts from "../campaignsV2/campaignsConsts"
import Consts from "../app/consts"
import ThumbnailTypes from "../common/consts/thumbnailTypes"
import AdTypes from "../common/consts/adTypes"
import { getMaximumBidByConversionName, getMaximumBudgetByConversionName } from "../utils/campaignUtilsV2"
import BidTypes from "../common/consts/bidTypes"

export const THUMBNAIL_LOADING = "THUMBNAIL_LOADING"
export const FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR = "FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR"
export const FACEBOOK_THUMBNAIL_RATIO_ERROR = "FACEBOOK_THUMBNAIL_RATIO_ERROR"
export const FACEBOOK_VIDEO_MAX_SIZE_ERROR = "FACEBOOK_VIDEO_MAX_SIZE_ERROR"
export const FACEBOOK_VIDEO_MIN_LENGTH_ERROR = "FACEBOOK_VIDEO_MIN_LENGTH_ERROR"
export const FACEBOOK_VIDEO_RATIO_ERROR = "FACEBOOK_VIDEO_RATIO_ERROR"
export const GOOGLE_ADS_THUMBNAIL_MIN_SIZE_ERROR = "GOOGLE_ADS_THUMBNAIL_MIN_SIZE_ERROR"
export const GOOGLE_ADS_THUMBNAIL_RATIO_ERROR = "GOOGLE_ADS_THUMBNAIL_RATIO_ERROR"
export const GOOGLE_ADS_DISPLAY_THUMBNAIL_RATIO_ERROR = "GOOGLE_ADS_DISPLAY_THUMBNAIL_RATIO_ERROR"
export const TABOOLA_THUMBNAIL_MIN_SIZE_ERROR = "TABOOLA_THUMBNAIL_MIN_SIZE_ERROR"
export const TABOOLA_THUMBNAIL_RATIO_ERROR = "TABOOLA_THUMBNAIL_RATIO_ERROR"
export const PINTEREST_THUMBNAIL_MIN_SIZE_ERROR = "PINTEREST_THUMBNAIL_MIN_SIZE_ERROR"
export const PINTEREST_THUMBNAIL_RATIO_ERROR = "PINTEREST_THUMBNAIL_RATIO_ERROR"
export const GOOGLE_ADS_DUPLICATE_CROP_ERROR = "GOOGLE_ADS_DUPLICATE_CROP_ERROR"

export let VALID_CAMPAIGN_CHARS = new Set(range(32, 126)) // Valid campaign chars are ascii range 32-126
VALID_CAMPAIGN_CHARS.delete(37) // Without %
VALID_CAMPAIGN_CHARS.delete(38) // Without &
VALID_CAMPAIGN_CHARS.delete(43) // Without +
VALID_CAMPAIGN_CHARS.delete(47) // Without /

export default class CreationValidator {
  static isEmpty(field) {
    return isNullOrEmpty(field) || field.trim() == ""
  }

  static validateCampaignDetailsStep(
    campaignDetails,
    domainNames,
    selectedSourceKey,
    campaignSources,
    siteIdToSiteObject
  ) {
    let errors = new Map()

    if (isNullOrUndefined(campaignDetails)) {
      return errors
    }

    // Campaign name
    if (this.isEmpty(campaignDetails.name)) {
      errors.set("campaignName", "Campaign name is required")
    } else {
      let invalidChars = campaignDetails.name.split("").filter((char) => {
        return !VALID_CAMPAIGN_CHARS.has(char.charCodeAt(0))
      })

      if (invalidChars.length > 0) {
        errors.set("campaignName", "Your campaign name includes unsupported characters: " + invalidChars.join(""))
      }
    }

    // Article URL
    if (this.isEmpty(campaignDetails.articleUrl)) {
      errors.set("articleUrl", "Article URL is required")
    } else if (campaignDetails.possibleSitesForDomain.length > 1 && isNullOrEmpty(campaignDetails.siteId)) {
      errors.set("network", "Please select a network")
    } else if (
      !domainNames.some((d) => campaignDetails.articleUrl.toLowerCase().includes(d)) ||
      isNullOrEmpty(campaignDetails.siteId)
    ) {
      errors.set("articleUrl", "The URL must be one of the predefined sites")
    } else if (campaignDetails.articleIsArchived) {
      errors.set("articleUrl", "Article is archived")
    } else {
      let domainName = siteIdToSiteObject.get(campaignDetails.siteId).domain_name
      let articleUrlAllowedPrefixes = [
        domainName,
        "https://" + domainName,
        "http://" + domainName,
        "www." + domainName,
        "https://www." + domainName,
        "http://www." + domainName,
        "https://ng." + domainName,
        "http://ng." + domainName,
        "www.ng." + domainName,
        "https://www.ng." + domainName,
        "http://www.ng." + domainName,
      ]
      let articleUrlNotAllowedDuplicates = ["http", "www", "://"]

      if (campaignDetails.articleUrl.includes("admin.")) {
        errors.set("articleUrl", 'Article URL can\'t lead to an "admin" sub-domain')
      } else if (campaignDetails.articleUrl.includes("?")) {
        errors.set("articleUrl", "Please add URL without any UTM parameters (e.g. ?utm_campaign)")
      } else if (
        articleUrlAllowedPrefixes.every(
          (articleUrlAllowedPrefix) => !campaignDetails.articleUrl.startsWith(articleUrlAllowedPrefix)
        )
      ) {
        errors.set("articleUrl", "Article URL can't lead to a sub-domain")
      } else if (
        !Consts.URL_REGEX.test(campaignDetails.articleUrl) ||
        articleUrlNotAllowedDuplicates.some(
          (articleUrlNotAllowedDuplicate) => campaignDetails.articleUrl.split(articleUrlNotAllowedDuplicate).length > 2
        )
      ) {
        errors.set("articleUrl", "Article URL is invalid")
      }
    }

    // Facebook page
    if (selectedSourceKey === "Facebook") {
      if (!campaignSources.sources.Facebook.selectedPage) {
        errors.set("page", "Please select page")
      }
    }

    // Ad Type
    if (campaignSources.sources[selectedSourceKey].adTypes.filter((adType) => adType.selected).length === 0) {
      errors.set("adType", "Please select ad type")
    }

    return errors
  }

  static validateTargetingGroupFields = (targetingGroup, selectedSourceKey, isPreset = false) => {
    let targetingGroupFieldsErrors = new Map()
    let selectedCampaignTypes = targetingGroup.campaignTypes.filter((campaignType) => campaignType.selected)

    // bid type / campaign types
    if (selectedCampaignTypes.length === 0 && !isPreset) {
      targetingGroupFieldsErrors.set("bidType", "Bid type is required")
    }

    // Devices (platforms)
    if (!Object.keys(targetingGroup.platforms).some((p) => targetingGroup.platforms[p].selected) && !isPreset) {
      targetingGroupFieldsErrors.set("sources", "Please select a platform for the source")
    }

    // Countries
    const isEmptyCountriesAllowed = ["Facebook", "GoogleAds", "Pinterest"].includes(selectedSourceKey)
    if (
      !isEmptyCountriesAllowed &&
      targetingGroup.selectedCountries.length === 0 &&
      targetingGroup.selectedCountryGroups.length === 0 &&
      !isPreset
    ) {
      targetingGroupFieldsErrors.set("countries", "Please select at least one country")
    }

    let isRoas = targetingGroup.campaignTypes.some((type) => type.id === BidTypes.roas.id && type.selected)

    // Conversion Event
    if (!isRoas && targetingGroup.selectedConversionEvents.length === 0) {
      targetingGroupFieldsErrors.set("conversionEvents", "Please select a conversion event")
    }

    let counvertionName =
      targetingGroup.selectedConversionEvents.length > 0 ? targetingGroup.selectedConversionEvents[0]?.name : null

    // initial bid
    if (!isRoas && !isNullOrEmpty(targetingGroup.initialBid)) {
      if (targetingGroup.initialBid > getMaximumBidByConversionName(counvertionName, targetingGroup.id)) {
        targetingGroupFieldsErrors.set("initialBid", "Bid is higher than the maximum allowed bid")
      }
    }
    // initial budget
    if (!isNullOrEmpty(targetingGroup.initialBudget)) {
      if (targetingGroup.initialBudget > getMaximumBudgetByConversionName(counvertionName, targetingGroup.id)) {
        targetingGroupFieldsErrors.set("initialBudget", "Budget is higher than the maximum allowed budget")
      }
    }

    // Name suffix
    let invalidChars = targetingGroup.nameSuffix.split("").filter((char) => {
      return !VALID_CAMPAIGN_CHARS.has(char.charCodeAt(0))
    })

    if (invalidChars.length > 0) {
      targetingGroupFieldsErrors.set(
        "nameSuffix",
        "Your name suffix includes unsupported characters: " + invalidChars.join("")
      )
    }

    return targetingGroupFieldsErrors
  }

  static validateTargetingGroupPreset(targetingGroupPreset, selectedProvider) {
    let targetingGroupPresetErrors = new Map()

    if (targetingGroupPreset.groupName.trim().length === 0) {
      targetingGroupPresetErrors.set("groupName", "Please enter the group name")
    }

    if (selectedProvider) {
      let targetingGroupFieldsErrors = CreationValidator.validateTargetingGroupFields(
        targetingGroupPreset,
        selectedProvider.name,
        true
      )
      targetingGroupPresetErrors = new Map([...targetingGroupPresetErrors, ...targetingGroupFieldsErrors])
    } else {
      targetingGroupPresetErrors.set("provider", "Please choose a source")
    }

    // Validate that the user chose at least one more option other than the group name, this is to prevent empty groups
    if (targetingGroupPresetErrors.size === 0) {
      if (
        targetingGroupPreset.campaignTypes.every((type) => !type.selected) &&
        targetingGroupPreset.nameSuffix.trim().length === 0 &&
        Object.values(targetingGroupPreset.platforms).every((platform) => !platform.selected) &&
        targetingGroupPreset.selectedCountries.length === 0 &&
        targetingGroupPreset.selectedCountryGroups.length === 0 &&
        targetingGroupPreset.selectedTags.length === 0 &&
        (selectedProvider.id !== CampaignsConsts.FACEBOOK_PROVIDER_ID ||
          !targetingGroupPreset.selectedPlacements ||
          targetingGroupPreset.selectedPlacements.length === 0)
      ) {
        targetingGroupPresetErrors.set(
          "groupPreset",
          "Please fill in at least one targeting group field (other than source)"
        )
      }
    }

    return targetingGroupPresetErrors
  }

  static validateTargetingStep(campaignSettings, campaignSources, campaignTargetingGroups, providerIdToProvider) {
    let errors = new Map()
    let selectedSourceKey = Object.keys(campaignSources.sources).filter(
      (sourceKey) => campaignSources.sources[sourceKey].selected
    )[0]

    if (campaignTargetingGroups.length === 0) {
      errors.set("targetingGroups", "Please add at least one targeting group")
      return errors
    }

    // Check all targeting groups
    campaignTargetingGroups.forEach((targetingGroup, index) => {
      let targetingGroupErrors = new Map()

      // Provider account
      if (!targetingGroup.providerAccount) {
        targetingGroupErrors.set("providerAccount", "Please select a provider account")
      }

      let targetingGroupFieldsErrors = CreationValidator.validateTargetingGroupFields(targetingGroup, selectedSourceKey)
      targetingGroupErrors = new Map([...targetingGroupErrors, ...targetingGroupFieldsErrors])

      if (targetingGroupErrors.size > 0) {
        errors.set(`targetingGroup${index}`, targetingGroupErrors)
      }
    })

    return errors
  }

  static validateSourcesStep(campaignSources) {
    let errors = new Map()
    let selectedSources = Object.values(campaignSources.sources).filter((s) => s.selected)

    if (selectedSources.length === 0) {
      errors.set("sources", "Please select a source")
    }

    return errors
  }

  static validateCreativesStep(campaignCreatives, campaignSources) {
    let errors = new Map()
    let creativesErrorsCount = 0

    campaignCreatives.creatives.forEach((creative) => {
      creativesErrorsCount += this.isCreativeValid(creative, campaignSources).errors.size
    })

    if (isNullOrUndefined(campaignCreatives) || campaignCreatives.creatives.length === 0) {
      errors.set("creatives", "Please add some creatives")
    } else if (creativesErrorsCount > 0) {
      errors.set("creatives", "There were some problems with your creatives")
    }

    return errors
  }

  static validateCreativesGroupsStep(campaignCreatives, campaignSources) {
    let errors = new Map()

    if (isNullOrUndefined(campaignCreatives.creativesGroups) || campaignCreatives.creativesGroups.length === 0) {
      errors.set("creativesGroups", "Please add some creative groups")
      return errors
    }

    // Check all creatives groups
    campaignCreatives.creativesGroups.forEach((creativesGroup, index) => {
      let creativesErrorsCount = 0
      creativesGroup.creatives.forEach((creative) => {
        creativesErrorsCount +=
          creative?.type === AdTypes.dynamic_ad.id
            ? this.isDynamicCreativeValid(creative, campaignSources).size
            : this.isCreativeValid(creative, campaignSources).errors.size
      })

      if (creativesErrorsCount > 0) {
        errors.set(`creativesGroup${index}`, "There were some problems with your creatives")
      }
    })

    return errors
  }

  static getTitleMaxCharsAllowed(campaignSources) {
    if (isNullOrEmpty(campaignSources)) {
      return 600
    }

    let titleMaxChars = 150
    // google title: 40, facebook: unlimited , Taboola & Pinterest: 150
    // Validation goes from the longest title to the shortest.
    // For example: if we selected both facebook (600) and google (40) then the maximum will be 40 because of google.
    let unlimitedTitleSources = [campaignSources.sources.Facebook].filter((s) => !isNullOrUndefined(s))
    let regularTitleSources = [campaignSources.sources.Taboola, campaignSources.sources.Pinterest].filter(
      (s) => !isNullOrUndefined(s)
    )
    if (unlimitedTitleSources.some((s) => s.selected)) {
      // basically there is no limitation but c'mon...
      titleMaxChars = 600
    }

    if (regularTitleSources.some((s) => s.selected)) {
      titleMaxChars = 100
    }
    return titleMaxChars
  }

  static getDescriptionMaxCharsAllowed(campaignSources) {
    let DescriptionMaxChars = 150
    if (isNullOrEmpty(campaignSources)) {
      return DescriptionMaxChars
    }

    let googleAdsSource = [campaignSources.sources.GoogleAds].filter((s) => !isNullOrUndefined(s))
    let taboolaSource = [campaignSources.sources.Taboola].filter((s) => !isNullOrUndefined(s))
    let pinterestSource = [campaignSources.sources.Pinterest].filter((s) => !isNullOrUndefined(s))

    if (googleAdsSource.some((s) => s.selected)) {
      DescriptionMaxChars = 90
    }

    if (taboolaSource.some((s) => s.selected)) {
      DescriptionMaxChars = 250
    }

    if (pinterestSource.some((s) => s.selected)) {
      DescriptionMaxChars = 500
    }

    return DescriptionMaxChars
  }

  static getLongHeadlineMaxCharsAllowed(campaignSources) {
    let longHeadlineMaxChars = 150
    if (isNullOrEmpty(campaignSources)) {
      return longHeadlineMaxChars
    }

    let googleAdsSource = [campaignSources.sources.GoogleAds].filter((s) => !isNullOrUndefined(s))
    if (googleAdsSource.some((s) => s.selected)) {
      longHeadlineMaxChars = 90
    }

    return longHeadlineMaxChars
  }

  static getHeadlineMaxCharsAllowed(campaignSources) {
    let headlineMaxChars = 255
    if (isNullOrEmpty(campaignSources)) {
      return headlineMaxChars
    }
    let googleAdsSource = [campaignSources.sources.GoogleAds].filter((s) => !isNullOrUndefined(s))
    if (googleAdsSource.some((s) => s.selected)) {
      headlineMaxChars = 30
    }

    return headlineMaxChars
  }

  static doesDuplicateCropExist(thumbnailAttributes) {
    const originalCropType = Object.keys(thumbnailAttributes.croppedImagePerRatios)[0]
    const { thumbnails } = store.getState().campaignCreationWizard.campaignCreatives.dynamicCreative
    const cropsByOriginalImage = {}

    thumbnails.forEach(({ thumbnail, croppedImagePerRatios }) => {
      const cropType = Object.keys(croppedImagePerRatios)[0]

      if (thumbnail && cropType) {
        if (!cropsByOriginalImage[thumbnail]) {
          cropsByOriginalImage[thumbnail] = []
        }
        cropsByOriginalImage[thumbnail].push(cropType)
      }
    })

    const cropsForOriginalImage = cropsByOriginalImage[thumbnailAttributes.thumbnail] || []
    const duplicateCount = cropsForOriginalImage.filter((crop) => crop === originalCropType).length
    return duplicateCount > 1
  }

  static isThumbnailValid(thumbnailAttributes) {
    let errors = new Set()
    let warnings = new Set()

    if (thumbnailAttributes.thumbnailErrors.length > 0) {
      if (thumbnailAttributes.thumbnailErrors.includes(THUMBNAIL_LOADING)) {
        errors.add("Please wait until the image is loaded")
      } else {
        thumbnailAttributes.thumbnailErrors.forEach((thumbnailError) => {
          switch (thumbnailError) {
            case GOOGLE_ADS_THUMBNAIL_MIN_SIZE_ERROR:
              errors.add("The image is smaller than the required minimum size (301x301), please upload a larger image.")
              break
            case GOOGLE_ADS_DISPLAY_THUMBNAIL_RATIO_ERROR:
              errors.add("Please upload display ads with valid ratio.")
              break
            case GOOGLE_ADS_THUMBNAIL_RATIO_ERROR:
              warnings.add(GOOGLE_ADS_THUMBNAIL_RATIO_ERROR)
              break
            case FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR:
              errors.add("Image is smaller than Facebook's recommended size, please upload a larger image.")
              break
            case FACEBOOK_THUMBNAIL_RATIO_ERROR:
              warnings.add("FACEBOOK_THUMBNAIL_RATIO_ERROR")
              break
            case FACEBOOK_VIDEO_MAX_SIZE_ERROR:
              errors.add("Low Video Resolution. Please upload a video with a minimum resolution of 1080 x 1080 pixels.")
              break
            case FACEBOOK_VIDEO_MIN_LENGTH_ERROR:
              errors.add("Video Length. Keep your video between 1 second and 2 minutes.")
              break
            case FACEBOOK_VIDEO_RATIO_ERROR:
              errors.add(
                "Unsupported Aspect Ratio. Please ensure your video has an aspect ratio between 1.91:1 and 1:1."
              )
              break
            case GOOGLE_ADS_DUPLICATE_CROP_ERROR:
              const cropName = Object.keys(thumbnailAttributes.croppedImagePerRatios)[0]
              errors.add(
                `A creative with the same crop ${cropName} already exists. Please replace the image or change the crop.`
              )
              break
          }
        })
      }
    }

    return {
      errors,
      warnings,
    }
  }

  static isCreativeValid(creative, campaignSources) {
    let errors = new Map()
    let warnings = new Map()

    if (isNullOrEmpty(campaignSources)) {
      return {
        errors,
        warnings,
      }
    }

    let maxTitleLength = this.getTitleMaxCharsAllowed(campaignSources)
    let maxDescriptionLength = this.getDescriptionMaxCharsAllowed(campaignSources)
    let maxHeadlineLength = this.getHeadlineMaxCharsAllowed(campaignSources)

    if (isNullOrEmpty(creative.text)) {
      errors.set("title", "Please enter the creative title")
    } else if (creative.text.length > maxTitleLength) {
      errors.set("title", "The creative title is too long")
    }

    if (this.shouldShowDescription(campaignSources)) {
      if (isNullOrEmpty(creative.description) & !campaignSources.sources.Taboola.selected) {
        errors.set("description", "Please enter the creative description")
      } else if (creative.description.length > maxDescriptionLength) {
        errors.set("description", "The description is too long")
      }
    }

    if (this.shouldShowHeadline(campaignSources)) {
      if (creative.headline) {
        if (creative.headline.length > maxHeadlineLength) {
          errors.set("headline", "The headline is too long")
        } else if (creative.headline.length === 0) {
          errors.set("headline", "Please enter the creative headline")
        }
      }
    }

    if (isNullOrEmpty(creative.thumbnail)) {
      errors.set("thumbnail", "Please upload an image")
    } else if (creative.thumbnailErrors.length !== 0) {
      // If the creative's thumbnail is not valid, we're setting an error message to be shown for the creative.
      // It's possible to have the error message always be shown (using the pattern: {alwaysVisible: true, errorMessage: "message"})
      // In some cases we want the error message to have a link inside it that will trigger some action, so we return only the error code
      // so that the creative will generate that message by itself.
      if (creative.thumbnailErrors.includes(THUMBNAIL_LOADING)) {
        errors.set("thumbnail", "Please wait until the image is loaded")
      } else {
        creative.thumbnailErrors.forEach((thumbnailError) => {
          switch (thumbnailError) {
            case FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR:
              warnings.set(
                "facebook-thumbnail",
                "Image is smaller than Facebook's recommended size, please upload a larger image."
              )
              break
            case FACEBOOK_THUMBNAIL_RATIO_ERROR:
              warnings.set("facebook-thumbnail", FACEBOOK_THUMBNAIL_RATIO_ERROR)
              break
            case TABOOLA_THUMBNAIL_MIN_SIZE_ERROR:
              errors.set(
                "taboola-thumbnail",
                "Image is smaller than Taboola's recommended size (600x400), please upload a larger image."
              )
              break
            case TABOOLA_THUMBNAIL_RATIO_ERROR:
              warnings.set("taboola-thumbnail", TABOOLA_THUMBNAIL_RATIO_ERROR)
              break
          }
        })
      }
    }

    return {
      errors,
      warnings,
    }
  }

  static isDynamicCreativeValid(creative, campaignSources) {
    function validateDisplayThumbnails(thumbnails, mustHaveCrops) {
      const thumbnailsCrops = thumbnails.map(
        (thumbnail) => Object.getOwnPropertyNames(thumbnail.croppedImagePerRatios)[0]
      )
      return mustHaveCrops.every((crop) => thumbnailsCrops.includes(crop))
    }

    let selectedSourceKey = Object.keys(campaignSources.sources).filter(
      (sourceKey) => campaignSources.sources[sourceKey].selected
    )[0]

    let thumbnails = creative.thumbnails
    let displayThumbnails = creative.displayThumbnails
    let adTypeId = campaignSources.sources[selectedSourceKey].adTypes.filter((adType) => adType.selected)[0].id

    let errors = new Map()

    if (isNullOrEmpty(campaignSources)) {
      return errors
    }

    if (creative.headlines.every((headline) => isNullOrEmpty(headline))) {
      errors.set("headlines", "Please enter a headline")
    }

    if (creative.descriptions.every((description) => isNullOrEmpty(description))) {
      errors.set("descriptions", "Please enter a description")
    }

    if (thumbnails.every((thumbnailAttributes) => isNullOrEmpty(thumbnailAttributes.thumbnail))) {
      errors.set("thumbnails", "Please upload an image")
    }

    if (
      thumbnails.some((thumbnailAttributes) => {
        return thumbnailAttributes.thumbnailErrors.some((error) =>
          [THUMBNAIL_LOADING, GOOGLE_ADS_THUMBNAIL_MIN_SIZE_ERROR, FACEBOOK_THUMBNAIL_RATIO_ERROR].includes(error)
        )
      })
    ) {
      errors.set("thumbnails", "There were some problems with the thumbnails")
    }

    if (!isNullOrEmpty(adTypeId)) {
      if (adTypeId === AdTypes.display_ad.id) {
        if (creative.longHeadline?.every((longHeadline) => isNullOrEmpty(longHeadline))) {
          errors.set("longHeadline", "Please enter a longHeadline")
        }
        let mustHaveCrops = ["landscape", "square"]
        if (!validateDisplayThumbnails(thumbnails, mustHaveCrops)) {
          errors.set("thumbnails", "Please include 1:1.91 and 1:1 crops")
        }
      } else if (adTypeId === AdTypes.discovery_ad.id) {
        let mustHaveCrops = ["square"]
        if (!validateDisplayThumbnails(thumbnails, mustHaveCrops)) {
          errors.set("thumbnails", "Please include 1:1 crop")
        }
        if (adTypeId === AdTypes.dynamic_ad.id) {
          if (creative.primaryTexts.every((primaryText) => isNullOrEmpty(primaryText))) {
            errors.set("primaryText", "Please enter a primary text")
          }
          if (creative.headlines.every((headline) => isNullOrEmpty(headline))) {
            errors.set("headlines", "Please enter a headline")
          }
          if (creative.descriptions.every((description) => isNullOrEmpty(description))) {
            errors.set("descriptions", "Please enter a description")
          }
        }
      }
    }

    if (displayThumbnails?.length > 0) {
      if (
        displayThumbnails?.some((thumbnailAttributes) => {
          return thumbnailAttributes.thumbnailErrors.some((error) =>
            [THUMBNAIL_LOADING, GOOGLE_ADS_DISPLAY_THUMBNAIL_RATIO_ERROR].includes(error)
          )
        })
      ) {
        errors.set("displayThumbnails", "There were some problems with the display thumbnails")
      }
    }
    return errors
  }

  static validateCreativeThumbnailAccordingToDimensionsAndRatio(
    img,
    minWidth,
    minHeight,
    minRatio,
    maxRatio,
    allowedRatioDeviation,
    minSizeErrorCode,
    ratioErrorCode
  ) {
    let width = minWidth
    let height = minHeight
    let imageRatio = img.width / img.height

    if (img.width < width || img.height < height) {
      return minSizeErrorCode
    }
    if (imageRatio < minRatio * (1 - allowedRatioDeviation) || imageRatio > maxRatio * (1 + allowedRatioDeviation)) {
      return ratioErrorCode
    }
    return true
  }

  static validateCreativeThumbnail(creative, thumbnail, groupIndex) {
    if (isNullOrEmpty(thumbnail)) {
      store.dispatch(validateThumbnail(creative.id, [], groupIndex))
      return
    }

    // Starting validation, setting thumbnail to loading to the user won't be able to advance while the image is being
    // loaded in which we can't check the sizes.
    store.dispatch(validateThumbnail(creative.id, [THUMBNAIL_LOADING], groupIndex))
    let reduxStore = store.getState()

    let sourcesToValidate = {
      Facebook: reduxStore.campaignCreationWizard.campaignSources.sources.Facebook,
      Taboola: reduxStore.campaignCreationWizard.campaignSources.sources.Taboola,
      Pinterest: reduxStore.campaignCreationWizard.campaignSources.sources.Pinterest,
    }

    let facebookSource = sourcesToValidate.Facebook
    let taboolaSource = sourcesToValidate.Taboola
    let pinterestSource = sourcesToValidate.Pinterest

    let validationResults = []

    if (thumbnail.endsWith(".mp4")) {
      let video = document.createElement("video")

      video.onloadedmetadata = () => {
        store.dispatch(validateThumbnail(creative.id, validationResults, groupIndex))
      }

      video.src = thumbnail
    } else {
      // If the user cropped his original image using our crop tool, we immediately assume that the crop is valid for the
      // cropped source.
      // Otherwise, we validate the original uploaded images for Facebook if they were selected.
      let img = new Image()

      img.onload = () => {
        if (
          !isNullOrUndefined(facebookSource) &&
          facebookSource.selected &&
          !creative.croppedImagesPerSource.hasOwnProperty("Facebook")
        ) {
          // Facebook validation
          // https://www.facebook.com/business/ads-guide/image
          // Documentation test: minimum dimensions: 600x600, desired ratio: 1.0 to 1.91 with a 3% allowed deviation
          // Current test: minimum dimensions: 320x320 (portrait)
          // or: 580x280 with a desired ratio of 0 to 3 with a 0% allowed deviation
          //
          // Note: Facebook's documentation seem to be inaccurate so we made our own assumptions on the minimum sizes and ratios
          // based on analysts trying and succeeding/failing to create campaigns.
          let facebookResult = this.validateCreativeThumbnailAccordingToDimensionsAndRatio(
            img,
            CampaignsCreationConsts.FACEBOOK_THUMBNAIL_LANDSCAPE_BASE_WIDTH,
            CampaignsCreationConsts.FACEBOOK_THUMBNAIL_LANDSCAPE_BASE_HEIGHT,
            0,
            3,
            0,
            FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR,
            FACEBOOK_THUMBNAIL_RATIO_ERROR
          )

          if (facebookResult !== true) {
            facebookResult = this.validateCreativeThumbnailAccordingToDimensionsAndRatio(
              img,
              CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_WIDTH,
              CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_HEIGHT,
              CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_WIDTH /
                CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_HEIGHT,
              CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_WIDTH /
                CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_HEIGHT,
              0,
              FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR,
              FACEBOOK_THUMBNAIL_RATIO_ERROR
            )
          }

          validationResults.push(facebookResult)
        }

        if (
          !isNullOrUndefined(taboolaSource) &&
          taboolaSource.selected &&
          !creative.croppedImagesPerSource.hasOwnProperty("Taboola")
        ) {
          // Taboola validation
          // https://help.taboola.com/hc/en-us/articles/115006880507-Title-and-Thumbnail-Best-Practices
          // Current test: Taboola supports minimum 600x400 images (ratio validation is not needed, therefore the
          // allowed deviation is 1 (100%))
          let taboolaResult = this.validateCreativeThumbnailAccordingToDimensionsAndRatio(
            img,
            CampaignsCreationConsts.TABOOLA_THUMBNAIL_BASE_WIDTH,
            CampaignsCreationConsts.TABOOLA_THUMBNAIL_BASE_HEIGHT,
            CampaignsCreationConsts.TABOOLA_THUMBNAIL_BASE_WIDTH /
              CampaignsCreationConsts.TABOOLA_THUMBNAIL_BASE_HEIGHT,
            CampaignsCreationConsts.TABOOLA_THUMBNAIL_BASE_WIDTH /
              CampaignsCreationConsts.TABOOLA_THUMBNAIL_BASE_HEIGHT,
            1,
            TABOOLA_THUMBNAIL_MIN_SIZE_ERROR,
            TABOOLA_THUMBNAIL_RATIO_ERROR
          )

          validationResults.push(taboolaResult)
        }
        if (
          !isNullOrUndefined(pinterestSource) &&
          pinterestSource.selected &&
          !creative.croppedImagesPerSource.hasOwnProperty("Pinterest")
        ) {
          // pinterest validation
          // https://help.pinterest.com/en/business/article/pinterest-product-specs
          // Current test: Pinterest supports minimum 1000*1000 images
          let pinterestResult = this.validateCreativeThumbnailAccordingToDimensionsAndRatio(
            img,
            CampaignsCreationConsts.PINTEREST_THUMBNAIL_BASE_WIDTH,
            CampaignsCreationConsts.PINTEREST_THUMBNAIL_BASE_HEIGHT,
            CampaignsCreationConsts.PINTEREST_THUMBNAIL_BASE_WIDTH /
              CampaignsCreationConsts.PINTEREST_THUMBNAIL_BASE_HEIGHT,
            CampaignsCreationConsts.PINTEREST_THUMBNAIL_BASE_WIDTH /
              CampaignsCreationConsts.PINTEREST_THUMBNAIL_RECOMMEND_HEIGHT,
            1,
            PINTEREST_THUMBNAIL_MIN_SIZE_ERROR,
            PINTEREST_THUMBNAIL_RATIO_ERROR
          )

          validationResults.push(pinterestResult)
        }

        // Filtering out successful image validation so we will only be left with the errors.
        let validationErrors = validationResults.filter((result) => result !== true)
        store.dispatch(validateThumbnail(creative.id, validationErrors, groupIndex))
      }

      img.src = thumbnail
    }
  }

  static validateVideoCreativeThumbnail(video) {
    const errors = []
    let aspectRatio = video.videoWidth / video.videoHeight
    if (
      video.videoWidth < CampaignsCreationConsts.FACEBOOK_THUMBNAIL_VIDEO ||
      video.videoHeight < CampaignsCreationConsts.FACEBOOK_THUMBNAIL_VIDEO
    ) {
      errors.push(FACEBOOK_VIDEO_MAX_SIZE_ERROR)
    }

    if (aspectRatio > 3 || aspectRatio < 0) {
      errors.push(FACEBOOK_THUMBNAIL_RATIO_ERROR)
    }

    if (video.duration < 1 || video.duration > 120) {
      errors.push(FACEBOOK_VIDEO_MIN_LENGTH_ERROR)
    }

    return errors
  }

  static validateDynamicCreativeThumbnail(thumbnailAttributes, id, thumbnailTypeId, groupIndex = null) {
    if (isNullOrEmpty(thumbnailAttributes.thumbnail)) {
      store.dispatch(validateDynamicThumbnail(id, [], thumbnailTypeId, groupIndex))
      return
    }
    // Starting validation, setting thumbnail to loading to the user won't be able to advance while the image is being
    // loaded in which we can't check the sizes.
    store.dispatch(validateDynamicThumbnail(id, [THUMBNAIL_LOADING], thumbnailTypeId, groupIndex))
    let reduxStore = store.getState()

    let googleAdsSource = reduxStore.campaignCreationWizard.campaignSources.sources?.GoogleAds
    let facebookAdsSource = reduxStore.campaignCreationWizard.campaignSources.sources?.Facebook

    let validationResults = []
    if (
      thumbnailAttributes.thumbnail.endsWith(".mp4") ||
      thumbnailAttributes.thumbnail.endsWith(".gif") ||
      thumbnailAttributes.thumbnail.endsWith(".mov")
    ) {
      let video = document.createElement("video")

      video.onloadedmetadata = () => {
        validationResults = this.validateVideoCreativeThumbnail(video)
        store.dispatch(validateDynamicThumbnail(id, validationResults, thumbnailTypeId, groupIndex))
      }
      video.src = thumbnailAttributes.thumbnail
    } else {
      // If the user cropped his original image using our crop tool, we immediately assume that the crop is valid for the
      // cropped source.
      // Otherwise, we validate the original uploaded images
      let img = new Image()

      img.onload = () => {
        if (
          facebookAdsSource?.selected &&
          thumbnailTypeId === ThumbnailTypes.RESPONSIVE_THUMBNAIL.id &&
          isNullOrEmpty(thumbnailAttributes.croppedImagePerRatios?.square) && // If the user cropped his original image using our crop tool, we immediately assume that the crop is valid for the cropped source.
          isNullOrEmpty(thumbnailAttributes.croppedImagePerRatios?.landscape)
        ) {
          // Facebook validation
          // https://www.facebook.com/business/ads-guide/image
          // Documentation test: minimum dimensions: 600x600, desired ratio: 1.0 to 1.91 with a 3% allowed deviation
          // Current test: minimum dimensions: 320x320  (portrait)
          // or: 580x280 with a desired ratio of 0 to 3 with a 0% allowed deviation
          //
          // Note: Facebook's documentation seem to be inaccurate so we made our own assumptions on the minimum sizes and ratios
          // based on analysts trying and succeeding/failing to create campaigns.
          let facebookResult = this.validateCreativeThumbnailAccordingToDimensionsAndRatio(
            img,
            CampaignsCreationConsts.FACEBOOK_THUMBNAIL_LANDSCAPE_BASE_WIDTH,
            CampaignsCreationConsts.FACEBOOK_THUMBNAIL_LANDSCAPE_BASE_HEIGHT,
            0,
            3,
            0,
            FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR,
            FACEBOOK_THUMBNAIL_RATIO_ERROR
          )

          if (facebookResult !== true) {
            facebookResult = this.validateCreativeThumbnailAccordingToDimensionsAndRatio(
              img,
              CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_WIDTH,
              CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_HEIGHT,
              CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_WIDTH /
                CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_HEIGHT,
              CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_WIDTH /
                CampaignsCreationConsts.FACEBOOK_THUMBNAIL_SQUARE_BASE_HEIGHT,
              0,
              FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR,
              FACEBOOK_THUMBNAIL_RATIO_ERROR
            )
          }

          validationResults.push(facebookResult)
        }
        if (
          googleAdsSource?.selected &&
          thumbnailTypeId === ThumbnailTypes.RESPONSIVE_THUMBNAIL.id &&
          !isNullOrEmpty(thumbnailAttributes.croppedImagePerRatios)
        ) {
          if (this.doesDuplicateCropExist(thumbnailAttributes)) {
            validationResults.push(GOOGLE_ADS_DUPLICATE_CROP_ERROR)
          }
        }
        if (
          googleAdsSource?.selected &&
          thumbnailTypeId === ThumbnailTypes.RESPONSIVE_THUMBNAIL.id &&
          Object.keys(thumbnailAttributes.croppedImagePerRatios).length === 0
        ) {
          // Google Ads Discovery Validation
          // https://developers.google.com/google-ads/api/reference/rpc/v12/DiscoveryMultiAssetAdInfo
          // https://developers.google.com/google-ads/api/performance-max/asset-requirements
          // According to task PP-1200: minimum size needs to be 300*300 with aspect ratio of 1:1 in order to support
          // auto cropping
          let googleAdsResult = this.validateCreativeThumbnailAccordingToDimensionsAndRatio(
            img,
            CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_WIDTH + 1,
            CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_HEIGHT + 1,
            CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_WIDTH /
              CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_HEIGHT,
            CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_WIDTH /
              CampaignsCreationConsts.GOOGLE_ADS_THUMBNAIL_SQUARE_BASE_HEIGHT,
            0,
            GOOGLE_ADS_THUMBNAIL_MIN_SIZE_ERROR,
            GOOGLE_ADS_THUMBNAIL_RATIO_ERROR
          )

          validationResults.push(googleAdsResult)
        }
        if (googleAdsSource?.selected && thumbnailTypeId === ThumbnailTypes.DISPLAY_THUMBNAIL.id) {
          let imgRatio = img.naturalWidth / img.naturalHeight
          let imgDimension = CampaignsCreationConsts.DISPLAY_THUMBNAIL_DIMENSIONS.find(
            (obj) => obj.width / obj.height === imgRatio
          )
          if (imgDimension) {
            let googleAdsResult = this.validateCreativeThumbnailAccordingToDimensionsAndRatio(
              img,
              imgDimension.width,
              imgDimension.height,
              imgDimension.ratio,
              imgDimension.ratio,
              0,
              GOOGLE_ADS_THUMBNAIL_MIN_SIZE_ERROR,
              GOOGLE_ADS_DISPLAY_THUMBNAIL_RATIO_ERROR
            )
            validationResults.push(googleAdsResult)
          } else {
            validationResults.push(GOOGLE_ADS_DISPLAY_THUMBNAIL_RATIO_ERROR)
          }
        }
        // Filtering out successful image validation so we will only be left with the errors.
        let validationErrors = validationResults.filter((result) => result !== true)
        store.dispatch(validateDynamicThumbnail(id, validationErrors, thumbnailTypeId, groupIndex))
      }

      img.src = thumbnailAttributes.thumbnail
    }
  }

  static shouldShowDescription(campaignSources) {
    if (isNullOrEmpty(campaignSources)) {
      return false
    }

    let sourcesWithDescription = [
      campaignSources.sources.Facebook,
      campaignSources.sources.GoogleAds,
      campaignSources.sources.Taboola,
      campaignSources.sources.Pinterest,
    ]
    sourcesWithDescription = sourcesWithDescription.filter((s) => !isNullOrUndefined(s))
    return sourcesWithDescription.some((s) => s.selected)
  }

  static shouldShowLongHeadline(campaignSources) {
    if (isNullOrEmpty(campaignSources)) {
      return false
    }

    let sourcesWithLongHeadline = [campaignSources.sources.GoogleAds]
    sourcesWithLongHeadline = sourcesWithLongHeadline.filter((s) => !isNullOrUndefined(s))
    return sourcesWithLongHeadline.some((s) => s.selected)
  }
  static shouldShowHeadline(campaignSources) {
    if (isNullOrEmpty(campaignSources)) {
      return false
    }

    let sourcesWithHeadline = [campaignSources.sources.Facebook, campaignSources.sources.GoogleAds]
    sourcesWithHeadline = sourcesWithHeadline.filter((s) => !isNullOrUndefined(s))
    return sourcesWithHeadline.some((s) => s.selected)
  }
}
