import React from "react"
import { connect } from "react-redux"
import {
  duplicateCreativesGroup,
  removeCreativesGroup,
  updateThumbnailLoadingStatus,
  updateThumbnailWithUrl,
  updateDynamicThumbnailLoadingStatus,
} from "../campaignCreationActions"
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons"
import CreativesList from "./creativesList"
import CreativeCreationButton from "./creativeCreationButton"
import { isNullOrEmpty } from "../../utils/funcUtils"
import DynamicCreative from "./dynamic/dynamicCreative"

class CreativesGroup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      groupOptionsMenuOpen: false,
      groupOptionsMenuAnchorEl: null,
    }
  }

  openGroupOptionsMenu = (event) => {
    event.stopPropagation()

    this.setState({
      groupOptionsMenuOpen: true,
      groupOptionsMenuAnchorEl: event.currentTarget,
    })
  }

  closeGroupOptionsMenu = (event) => {
    event.stopPropagation()

    this.setState({
      groupOptionsMenuOpen: false,
    })
  }

  deleteGroup = (event) => {
    this.closeGroupOptionsMenu(event)
    this.props.dispatch(removeCreativesGroup(this.props.groupIndex))
  }

  duplicateGroup = (event) => {
    this.closeGroupOptionsMenu(event)
    this.props.dispatch(duplicateCreativesGroup(this.props.groupIndex, this.props.creativesGroup))
  }

  renderGroupOptionsMenu = () => {
    return (
      <>
        <div className="group-options-menu">
          <span className="clickable target-group-action-link" onClick={this.duplicateGroup}>
            Duplicate
          </span>
          {this.props.isCanBeDeleted ? (
            <span className="clickable target-group-action-link" onClick={this.deleteGroup}>
              Delete
            </span>
          ) : null}
        </div>
      </>
    )
  }

  renderGroupOpenSwitch = () => {
    let arrow

    if (this.props.creativesGroup.isOpen) {
      arrow = <KeyboardArrowDown />
    } else {
      arrow = <KeyboardArrowRight />
    }

    return <span>{arrow}</span>
  }

  renderGroupFields = () => {
    if (this.props.creativesGroup.isOpen) {
      return (
        <div>
          {this.props.isDynamicCreative ? (
            <DynamicCreative
              dynamicCreative={this.props.creativesGroup.creatives[0]}
              key={"dynamicCreative"}
              validationErrorsVisible={this.state.validationErrorsVisible}
              creativeUpdateFunc={this.props.creativeUpdateFunc}
              thumbnailUpdateFunc={this.props.thumbnailUpdateFunc}
              thumbnailRemoveFunc={this.props.thumbnailRemoveFunc}
              updateThumbnailLoadingStatus={updateDynamicThumbnailLoadingStatus}
              allowThumbnailCrop
              sourceData={this.props.sourceData}
              campaignSources={this.props.campaignSources}
              groupIndex={this.props.groupIndex}
            />
          ) : (
            <>
              <CreativesList
                creatives={this.props.creativesGroup.creatives}
                campaignSources={this.props.campaignSources}
                validationErrorsVisible={this.props.validationErrorsVisible}
                onCreativeUpdated={this.props.onCreativeUpdated}
                onCreativeRemoved={this.props.onCreativeRemoved}
                onCreativeCloned={this.props.onCreativeCloned}
                onThumbnailUpdated={this.props.onThumbnailUpdated}
                onThumbnailRemoved={this.props.onThumbnailRemoved}
                updateThumbnailWithUrl={updateThumbnailWithUrl}
                updateThumbnailLoadingStatus={updateThumbnailLoadingStatus}
                allowThumbnailCrop
                groupIndex={this.props.groupIndex}
              />

              <CreativeCreationButton
                addCreative={this.props.addCreative}
                sources={this.props.campaignSources.sources}
                groupIndex={this.props.groupIndex}
              />
            </>
          )}
        </div>
      )
    } else {
      let groupValidationErrorsIndicator
      let validationErrors = this.props.validationErrors

      if (!isNullOrEmpty(validationErrors) && this.props.validationErrorsVisible) {
        groupValidationErrorsIndicator = <div className="validation-error">Errors exist for this creatives group</div>
      }
      return <div className="group-summary">{groupValidationErrorsIndicator}</div>
    }
  }

  render() {
    return (
      <div
        className={
          "campaign-targeting-group creatives-group " + (this.props.creativesGroup.isOpen ? "open" : "clickable")
        }
        key={this.props.groupIndex}
      >
        <div className="group-title">
          <span>
            {this.renderGroupOpenSwitch()}
            {`Group ${this.props.groupIndex + 1}`}
          </span>
          {this.renderGroupOptionsMenu()}
        </div>
        <div className="creatives-group-fields">{this.renderGroupFields()}</div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(CreativesGroup)
