import React from "react"
import { Chip } from "@material-ui/core"
import ActivitiesIcon from "../../resources/svgs/ActivitiesIcon.svg"
import ActivitiesDarkIcon from "../../resources/svgs/ActivitiesDarkIcon.svg"
import { setAppTitle } from "../../utils/domUtils"
import DrawerTypes from "../../common/consts/drawerTypes"
import Consts from "../../app/consts"
import { eventsTracker } from "../../api/eventsTracker"

export default class NavigationIcons extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate() {
    setAppTitle(this.props.updates.activities)
  }

  toggleDrawerAndTrack = (event, drawerType, numberOfItems) => {
    this.props.toggleDrawer(event, drawerType)
    if (!this.props.drawerIsOpen) {
      eventsTracker.trackDrawerOpen(drawerType, numberOfItems)
    }
  }

  render() {
    let activitiesUpdatesChip = null

    let activitiesIcon = (
      <ActivitiesDarkIcon
        className="navigation-icon activities-icon"
        onClick={(event) => this.toggleDrawerAndTrack(event, DrawerTypes.ACTIVITIES, 0)}
      />
    )

    if (this.props.updates.activities > 0) {
      activitiesUpdatesChip = (
        <Chip
          className="updates-chip"
          classes={{ label: "label" }}
          label={
            this.props.updates.activities >= Consts.DRAWER_PAGE_SIZE
              ? Consts.DRAWER_PAGE_SIZE + "+"
              : this.props.updates.activities
          }
          onClick={(event) => this.toggleDrawerAndTrack(event, DrawerTypes.ACTIVITIES, this.props.updates.activities)}
        />
      )
      activitiesIcon = (
        <ActivitiesIcon
          className="navigation-icon activities-icon"
          onClick={(event) => this.toggleDrawerAndTrack(event, DrawerTypes.ACTIVITIES, this.props.updates.activities)}
        />
      )
    }
    return (
      <div className="navigation-icons-container">
        {activitiesIcon}
        {activitiesUpdatesChip}
      </div>
    )
  }
}
