import React from "react"
import Dropzone from "react-dropzone"
import CropIcon from "../../../resources/svgs/CropIcon.svg"
import Xcreatives from "../../../resources/svgs/Xcreatives.svg"
import { eventsTracker } from "../../../api/eventsTracker"
import ZoomIcon from "../../../resources/svgs/ZoomIcon.svg"
import { CircularProgress, Divider } from "@material-ui/core"
import AddImageIcon from "../../../resources/svgs/AddImageIcon.svg"
import ArrowGalleryIcon from "../../../resources/svgs/ArrowGalleryIcon.svg"
import ReactTooltip from "react-tooltip"
import { connect } from "react-redux"
import DropImageIcon from "../../../resources/svgs/DropImageIcon.svg"
import Videoicon from "../../../resources/svgs/VideoIcon.svg"
import { clearNotifications, notificationAdd } from "../../../common/actions/commonActions"
import SingleCropCreativeDialog from "./singleCropCreativeDialog"
import CreationValidator, {
  GOOGLE_ADS_THUMBNAIL_RATIO_ERROR,
  GOOGLE_ADS_DISPLAY_THUMBNAIL_RATIO_ERROR,
  FACEBOOK_THUMBNAIL_RATIO_ERROR,
  FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR,
} from "../../creationValidator"
import { isNullOrEmpty } from "../../../utils/funcUtils"

class Thumbnail extends React.Component {
  constructor(props) {
    super(props)
    const ratio = Object.keys(this.props.thumbnailAttributes.croppedImagePerRatios)
    let croppedThumbnail = null
    if (ratio.length > 0) {
      croppedThumbnail = this.props.thumbnailAttributes.croppedImagePerRatios[ratio[0]].thumbnail
    }
    this.state = {
      isValid: false,
      thumbnail: this.props.thumbnailAttributes.thumbnail,
      croppedThumbnail: croppedThumbnail,
      isCropOpen: false,
      cropDefaultTab: null,
      validationErrors: new Set(),
      validationWarnings: new Set(),
      isVideo: this.props.thumbnailAttributes.is_video,
    }
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // the initiation of the thumbnail sets the state in the constructor. Since there is an option to update the
    // thumbnails through the global state and the state is set only at the creation we need to update the state..
    const prevErrors = prevProps.thumbnailAttributes.thumbnailErrors
    const currentErrors = this.props.thumbnailAttributes.thumbnailErrors

    const isNewError =
      this.state.validationWarnings.size === 0 && this.state.validationErrors.size === 0 && currentErrors.length > 0
    const haveErrorsChanged =
      prevErrors.length !== currentErrors.length || !prevErrors.every((value, index) => value === currentErrors[index])
    if (isNewError || haveErrorsChanged) {
      this.validateThumbnail(this.props.thumbnailAttributes)
    }
  }

  validateThumbnail = (thumbnailAttributes) => {
    let croppedThumbnail = null
    const { errors: validationErrors, warnings: validationWarnings } =
      CreationValidator.isThumbnailValid(thumbnailAttributes)

    const isValid = validationErrors.size === 0
    const ratio = Object.keys(this.props.thumbnailAttributes.croppedImagePerRatios)
    if (ratio.length > 0) {
      croppedThumbnail = this.props.thumbnailAttributes.croppedImagePerRatios[ratio[0]].thumbnail
    }

    this.setState({
      isValid: isValid,
      validationErrors: validationErrors,
      validationWarnings: validationWarnings,
      thumbnail: thumbnailAttributes.thumbnail,
      croppedThumbnail: croppedThumbnail,
    })
  }

  handleThumbnailRemoval = () => {
    this.setState({
      thumbnail: null,
    })

    this.props.thumbnailRemoveFunc(this.props.index, this.props.thumbnailTypeId, this.props.groupIndex)
    eventsTracker.trackCreativeClicked("Delete")
  }

  handleLocalFileDrop = (files) => {
    this.file = files[0]
    this.props.dispatch(
      this.props.updateThumbnailLoadingStatus(this.props.index, true, this.props.thumbnailTypeId, this.props.groupIndex)
    )
    this.props.thumbnailUpdateFunc(
      this.props.index,
      this.file,
      null,
      null,
      this.props.thumbnailTypeId,
      this.props.groupIndex
    )
    this.setState({
      isVideo: this.file.type.includes("video"),
    })
  }

  handleCropPerRatio = (files, cropDetails, selectedRatio) => {
    this.file = files[0]
    this.props.dispatch(
      this.props.updateThumbnailLoadingStatus(this.props.index, true, this.props.thumbnailTypeId, this.props.groupIndex)
    )
    this.props.thumbnailUpdateFunc(
      this.props.index,
      this.file,
      cropDetails,
      selectedRatio,
      this.props.thumbnailTypeId,
      this.props.groupIndex
    )
  }

  handleDrop = (event, rejected) => {
    // This function will be called regardless if dropped file was accepted or rejected
    // Thus, we would like to make sure that we won't upload invalid file
    if (Array.isArray(rejected) && rejected.length > 0 && rejected[0].preview) {
      this.handleThumbnailRemoval()
      return
    }

    // The following conditions were created in order to recognize when the dropped file
    // came from local directory or from the gallery
    if (event.dataTransfer && event.dataTransfer.types[0] === "Files") {
      this.handleLocalFileDrop(event.dataTransfer.files)
    } else if (event[0]) {
      this.handleLocalFileDrop(event)
    }
  }

  handleDropRejected = (args) => {
    let file = args[0]

    this.props.dispatch(clearNotifications())

    if (file.size > this.props.maxSize) {
      this.props.dispatch(notificationAdd("Image size must be smaller than " + this.props.maxSize + " kilobytes"))
    } else {
      this.props.dispatch(notificationAdd("Invalid image type"))
    }
  }

  openCropDialog = (cropDefaultTab = null) => {
    this.setState({
      isCropOpen: true,
      cropDefaultTab: cropDefaultTab,
    })

    eventsTracker.trackCreativeClicked("Crop")
  }

  updateIsCropOpen = (status) => {
    this.setState({ isCropOpen: status })
  }

  getRelevantSourcesForCropDialog = () => {
    let sources = {}
    Object.keys(this.props.campaignSources.sources).forEach((sourceKey) => {
      let source = this.props.campaignSources.sources[sourceKey]

      if (source.selected) {
        sources[sourceKey] = Object.assign({}, source)
      }
    })

    return sources
  }

  getThumbnailErrors = () => {
    let thumbnailErrors = null
    const errorsList = Array.from(this.state.validationErrors)
    if (errorsList.length > 0 && this.props.validationErrorsVisible) {
      thumbnailErrors = (
        <ul className="creative-errors flex-column">
          {errorsList.map((error, index) => {
            return (
              <li className="d-flex text" key={index}>
                {error}
              </li>
            )
          })}
        </ul>
      )
    }
    return thumbnailErrors
  }

  getThumbnailWarnings = () => {
    let thumbnailWarnings = null
    const warningsList = Array.from(this.state.validationWarnings)

    if (warningsList.length > 0) {
      thumbnailWarnings = (
        <ul className="creative-warnings flex-column">
          {warningsList.map((warning, index) => {
            switch (warning) {
              case GOOGLE_ADS_THUMBNAIL_RATIO_ERROR:
                warning = (
                  <div>
                    Please also crop this image to 1.91:1 ratio,{" "}
                    <span className="link" onClick={() => this.openCropDialog("GoogleAds")}>
                      click here to crop
                    </span>
                    .
                  </div>
                )
                break
              case GOOGLE_ADS_DISPLAY_THUMBNAIL_RATIO_ERROR:
                warning = (
                  <div>
                    Please ensure that the image dimensions are one of the following: 300x600, 728x90, 300x250, or
                    320x100 pixels.
                  </div>
                )
                break
              case FACEBOOK_THUMBNAIL_RATIO_ERROR:
                warning = (
                  <div>
                    Please also crop this image to 1.91:1 ratio,{" "}
                    <span className="link" onClick={() => this.openCropDialog("Facebook")}>
                      click here to crop
                    </span>
                    .
                  </div>
                )
                break
              case FACEBOOK_THUMBNAIL_MIN_SIZE_ERROR:
                warning = <div>Image is smaller than Facebook's recommended size, please upload a larger image.</div>
                break
            }

            return (
              <li className="d-flex text" key={index}>
                {warning}
              </li>
            )
          })}
        </ul>
      )
    }
    return thumbnailWarnings
  }

  getThumbnailElement = (isStaticCreative, getInputProps, openBrowserUpload) => {
    let existingThumbnailActions = null
    let cropIcon = null
    let cropName = !isNullOrEmpty(this.props.thumbnailAttributes.croppedImagePerRatios)
      ? Object.keys(this.props.thumbnailAttributes.croppedImagePerRatios)[0]
      : ""

    if (this.props.allowThumbnailCrop && !this.state?.isVideo) {
      cropIcon = (
        <div
          className="existing-thumbnail-action-button clickable crop-thumbnail"
          onClick={() => this.openCropDialog()}
        >
          <CropIcon width="12" height="11" />
        </div>
      )
    }

    if (!isStaticCreative) {
      existingThumbnailActions = (
        <div className="existing-thumbnail-actions">
          <div
            className="existing-thumbnail-action-button clickable remove-thumbnail"
            onClick={this.handleThumbnailRemoval}
          >
            <Xcreatives width="8" height="8" />
          </div>
          <a
            className="existing-thumbnail-action-button clickable view-thumbnail"
            onClick={() => {
              eventsTracker.trackCreativeClicked("Open in a new window")
            }}
            target="_blank"
            href={!isNullOrEmpty(this.state.croppedThumbnail) ? this.state.croppedThumbnail : this.state.thumbnail}
          >
            <ZoomIcon width="10" height="10" />
          </a>
          {cropIcon}
        </div>
      )
    }

    if (this.state.thumbnail && !this.props.thumbnailAttributes.isThumbnailBeingUploaded) {
      if (isStaticCreative) {
        return (
          <a
            className={"thumbnail dynamic-thumbnail clickable" + (isStaticCreative ? " static-thumbnail" : "")}
            href={this.state.thumbnail}
            target="_blank"
          >
            {this.state?.isVideo ? (
              <>
                <div className="video-icon">
                  <Videoicon />
                </div>
                <iframe src={this.state.thumbnail} frameborder="0"></iframe>
              </>
            ) : (
              <img src={this.state.thumbnail} />
            )}
            {existingThumbnailActions}
          </a>
        )
      }

      return (
        <div className={"thumbnail dynamic-thumbnail" + (isStaticCreative ? " static-thumbnail" : "")}>
          {this.state?.isVideo ? (
            <>
              <div className="video-icon">
                <Videoicon />
              </div>
              <iframe src={this.state.thumbnail} frameborder="0"></iframe>
            </>
          ) : (
            <div className={"thumbnail-background"}>
              <img
                className={cropName}
                src={!isNullOrEmpty(this.state.croppedThumbnail) ? this.state.croppedThumbnail : this.state.thumbnail}
              />
            </div>
          )}
          {existingThumbnailActions}
        </div>
      )
    } else {
      if (this.props.thumbnailAttributes.isThumbnailBeingUploaded) {
        return (
          <div className="thumbnail dynamic-thumbnail uploading">
            <CircularProgress className="loader" size={30} />
          </div>
        )
      } else {
        return (
          <>
            <div className="thumbnail dynamic-thumbnail drop">
              <input {...getInputProps()} />
              <div className="no-image">
                <AddImageIcon />
                <div className="thumbnail-actions">
                  <div className="thumbnail-button" onClick={openBrowserUpload}>
                    <span>
                      <ArrowGalleryIcon />
                      Upload
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    }
  }

  render() {
    const { accept, maxSize, multiple, disabled } = this.props
    let cropCreativeDialog = null
    let thumbnailErrors = this.getThumbnailErrors()
    let thumbnailWarnings = this.getThumbnailWarnings()
    let selectedRatioTitle = null
    const croppedImagePerRatios = Object.keys(this.props.thumbnailAttributes.croppedImagePerRatios)

    if (this.props.allowThumbnailCrop) {
      cropCreativeDialog = (
        <SingleCropCreativeDialog
          image={this.state.thumbnail}
          isOpen={this.state.isCropOpen}
          cropDefaultTab={this.state.cropDefaultTab}
          updatePopupState={this.updateIsCropOpen}
          updateImageFunc={this.handleCropPerRatio}
          isThumbnailBeingUploaded={this.props.isThumbnailBeingUploaded}
          sources={this.getRelevantSourcesForCropDialog()}
          croppedImagePerRatios={this.props.thumbnailAttributes.croppedImagePerRatios}
          thumbnailType={this.props.thumbnailType}
        />
      )
    }
    if (!isNullOrEmpty(this.props.cropDetails) && !isNullOrEmpty(croppedImagePerRatios[0])) {
      selectedRatioTitle = (
        <div className="ratio-title">
          <span>{this.props.cropDetails[croppedImagePerRatios[0]]?.title}</span>
        </div>
      )
    }

    return (
      <>
        <Dropzone
          onDrop={this.handleDrop}
          accept={accept}
          maxSize={maxSize}
          multiple={multiple}
          onDropRejected={this.handleDropRejected}
          onClick={(evt) => evt.preventDefault()}
          disabled={disabled}
        >
          {({ getRootProps, getInputProps, isDragActive, open: openBrowserUpload }) => {
            let dropView = null
            if (isDragActive) {
              dropView = (
                <div className="active-drag">
                  <div className="drop-container">
                    <DropImageIcon className="drop-image" />
                    Drop to attach image
                  </div>
                </div>
              )
            }
            return (
              <div {...getRootProps()}>
                {dropView}
                {this.getThumbnailElement(false, getInputProps, openBrowserUpload)}
                <div className="thumbnail-validation">
                  {selectedRatioTitle}
                  {thumbnailErrors}
                  {thumbnailWarnings}
                </div>
              </div>
            )
          }}
        </Dropzone>
        {cropCreativeDialog}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(Thumbnail)
