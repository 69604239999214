import Moment from "moment"

class Presets {}

Presets.TODAY = "TODAY"
Presets.LAST_HOUR = "LAST_HOUR"
Presets.LAST_2_HOURS = "LAST_2_HOURS"
Presets.YESTERDAY = "YESTERDAY"
Presets.TWO_DAYS_AGO = "TWO_DAYS_AGO"
Presets.THREE_DAYS_AGO = "THREE_DAYS_AGO"
Presets.SEVEN_DAYS_AGO = "SEVEN_DAYS_AGO"
Presets.LAST_7_DAYS = "LAST_7_DAYS"
Presets.LAST_30_DAYS = "LAST_30_DAYS"
Presets.THIS_MONTH = "THIS_MONTH"
Presets.LAST_MONTH = "LAST_MONTH"
Presets.CUSTOM = "CUSTOM"

Presets.FILTER_PRESETS = [
  Presets.TODAY,
  Presets.LAST_HOUR,
  Presets.LAST_2_HOURS,
  Presets.YESTERDAY,
  Presets.LAST_7_DAYS,
  Presets.LAST_30_DAYS,
  Presets.THIS_MONTH,
  Presets.LAST_MONTH,
  Presets.TWO_DAYS_AGO,
  Presets.THREE_DAYS_AGO,
  Presets.SEVEN_DAYS_AGO,
]
Presets.SINGLE_DATE_PRESETS = [
  Presets.TODAY,
  Presets.LAST_HOUR,
  Presets.LAST_2_HOURS,
  Presets.YESTERDAY,
  Presets.TWO_DAYS_AGO,
  Presets.THREE_DAYS_AGO,
  Presets.SEVEN_DAYS_AGO,
]
Presets.RANGE_DATES_PRESETS = [Presets.LAST_7_DAYS, Presets.LAST_30_DAYS, Presets.THIS_MONTH, Presets.LAST_MONTH]

Presets.PRESETS = new Map([
  [Presets.TODAY, { text: "Today", startDate: Moment().startOf("day"), endDate: Moment() }],
  [Presets.LAST_HOUR, { text: "Last Hour", startDate: Moment().subtract(1, "hour"), endDate: Moment() }],
  [
    Presets.LAST_2_HOURS,
    {
      text: "Last 2 Hours",
      startDate: Moment().subtract(2, "hour"),
      endDate: Moment(),
    },
  ],
  [
    Presets.YESTERDAY,
    {
      text: "Yesterday",
      startDate: Moment().subtract(1, "day"),
      endDate: Moment().subtract(1, "day"),
    },
  ],
  [
    Presets.TWO_DAYS_AGO,
    {
      text: "2 days ago",
      startDate: Moment().subtract(2, "day"),
      endDate: Moment().subtract(2, "day"),
    },
  ],
  [
    Presets.THREE_DAYS_AGO,
    {
      text: "3 days ago",
      startDate: Moment().subtract(3, "day"),
      endDate: Moment().subtract(3, "day"),
    },
  ],
  [
    Presets.SEVEN_DAYS_AGO,
    {
      text: "7 days ago",
      startDate: Moment().subtract(7, "day"),
      endDate: Moment().subtract(7, "day"),
    },
  ],
  [
    Presets.LAST_7_DAYS,
    {
      text: "Last 7 Days",
      startDate: Moment().subtract(7, "days"),
      endDate: Moment().subtract(1, "day"),
    },
  ],
  [
    Presets.LAST_30_DAYS,
    {
      text: "Last 30 Days",
      startDate: Moment().subtract(30, "days"),
      endDate: Moment().subtract(1, "day"),
    },
  ],
  [Presets.THIS_MONTH, { text: "This Month", startDate: Moment().startOf("month"), endDate: Moment() }],
  [
    Presets.LAST_MONTH,
    {
      text: "Last Month",
      startDate: Moment().subtract(1, "month").startOf("month"),
      endDate: Moment().subtract(1, "month").endOf("month"),
    },
  ],
  [Presets.CUSTOM, { text: "Custom" }],
])

export default Presets
