class TrackingConsts {}

TrackingConsts.EVENT_TYPES = {
  PAGE_VIEW: "page_view",
  SEARCH: "search",
  MESSAGE_DISPLAYED: "message_displayed",
  MESSAGE_REFRESHED: "message_refreshed",
  MESSAGE_HIDE: "message_hide",
  USER_LOGGED_IN: "user_logged_in",
  USER_LOGGED_OUT: "user_logged_out",
  METRIC_BREAKDOWN: "metric_breakdown",
  SORT_COLUMN: "sort_column",
  STATUS_FILTER: "status_filter",
  FILTER_ADDED: "filter_added",
  FILTER_UPDATE: "filter_update",
  FILTERS_CLEAR: "clear_all_filters",
  CAMPAIGNS_EXPORT: "campaigns_export",
  CAMPAIGN_PERFORMANCE_EXPORT: "campaign_performance_export",
  DRAWER_OPEN: "drawer_open",
  DRAWER_CLOSE: "drawer_close",
  CAMPAIGN_CREATION_WIZARD_LAUNCH: "campaign_creation_wizard_launch",
  CAMPAIGN_CREATION_WIZARD_CLOSE: "campaign_creation_wizard_close",
  CAMPAIGN_CREATION_WIZARD_STEP: "campaign_creation_wizard_step",
  CAMPAIGN_FULL_NAME: "campaign_full_name",
  CAMPAIGN_SUPPORTED_DETAILS: "campaign_supported_details",
  CAMPAIGN_ARTICLE_LINK: "campaign_article_link",
  CAMPAIGN_EDIT_ON_SOURCE: "campaign_edit_on_source",
  CAMPAIGN_CHANGE_BID: "campaign_change_bid",
  CAMPAIGN_DUPLICATE: "campaign_duplicate",
  CAMPAIGN_STATUS: "campaign_status",
  CAMPAIGN_POPUP_OPENED: "campaign_popup_opened",
  CAMPAIGN_POPUP_PAGE_CHANGED: "campaign_popup_page_changed",
  BULK_EDIT_STATUS_CHANGED: "bulk_edit_status_changed",
  BULK_EDIT_BID: "bulk_edit_bid",
  BULK_ADD_TAGS: "bulk_add_tags",
  BULK_REMOVE_TAGS: "bulk_remove_tags",
  CUSTOMIZED_INTERFACE_MENU_TOGGLE: "customized_interface_menu_toggle",
  CUSTOMIZED_INTERFACE_FIELD_TOGGLE: "customized_interface_field_toggle",
  CREATIVE_CLICKED: "creative_clicked",
  CAMPAIGN_OPTIONS_MENU_SHORTCUT: "campaign_options_menu_shortcut",
  CONVERSION_EVENTS_RELOAD: "conversion_events_reload",
  SHORTCUT_USED: "shortcut_used",
  BULK_EDIT: "bulk_edit",
  EXPORT_REPORT: "export_report",
  SAVE_FILTERS_GROUP: "save_filters_group",
  SELECT_FILTERS_GROUP: "select_filters_group",
  DELETE_FILTERS_GROUP: "delete_filters_group",
  CAMPAIGNS_COMPARE: "campaigns_compare",
  CAMPAIGNS_ITEMS_PER_PAGE: "campaigns_items_per_page",
  FILTER_OPERATOR_EDITED: "filter_operator_edited",
  ADVANCED_SEARCH_OPENED: "advanced_search_opened",
  ADVANCED_SEARCH_PERFORMED: "advanced_search_performed",
  ADVANCED_SEARCH_EDIT_CLICKED: "advanced_search_edit_clicked",
  RANDOM_AUTO_ACCOUNT: "random_auto_account",
  AUTOMATION_POPUP_OPENED: "automation_popup_opened",
}

TrackingConsts.FIELD_NAMES = {
  // common tracking fields.
  USER_ID: "user_id",
  EVENT_TYPE: "event_type",
  EVENT_TIME: "event_time",
  DEVICE: "device",
  DEVICE_TYPE: "device_type",
  SITE_ID: "site_id",
  NETWORK_ID: "network_id",

  // date range fields
  FROM_DATE: "from_date",
  TO_DATE: "to_date",
  COMPARE_FROM_DATE: "compare_from_date",
  COMPARE_TO_DATE: "compare_to_date",
  IS_REALTIME: "is_realtime",

  // page view fields.
  URL: "url",

  // search term.
  QUERY: "query",
  SECTION_NAME: "section_name",

  // user click on message refresh button.
  MESSAGE_BODY: "message_body",

  // sorting columns.
  COLUMN_NAME: "column_name",
  ORDER: "order",

  // filtering.
  STATUS: "status",
  FILTER_NAME: "filter_name",
  FILTER_VALUE_NAME: "filter_value_name",
  FILTER_ACTION: "filter_action",
  FILTERS_COUNT: "filters_count",

  // Drawers
  DRAWER_NAME: "drawer_name",
  DRAWER_ITEMS: "drawer_items",

  // campaign creation.
  STEP_NAME: "step_name",

  // campaign.
  CAMPAIGN_STATUS: "campaign_status",
  CAMPAIGN_OLD_STATUS: "campaign_old_status",
  CAMPAIGN_CPC: "campaign_cpc",
  CAMPAIGN_OLD_CPC: "campaign_old_cpc",
  CAMPAIGN_TRACKING_CODE: "campaign_tracking_code",
  CAMPAIGN_TARGET_ROI: "campaign_target_roi",
  CAMPAIGN_TAGS: "tags",

  // Metrics
  METRIC_TYPE: "metric_type",

  // campaign popup
  PAGE_NAME: "page_name",

  // bulk edit
  NUMBER_OF_CAMPAIGNS: "number_of_campaigns",

  // customized interface
  CUSTOMIZED_INTERFACE_MENU_STATUS: "customized_interface_menu_status",
  CUSTOMIZED_INTERFACE_FIELD_NAME: "customized_interface_field_name",
  CUSTOMIZED_INTERFACE_FIELD_STATUS: "customized_interface_field_status",

  // creative
  CREATIVE_BUTTON: "creative_button",
  WITH_SEARCH: "with_search",

  // Campaign options menu shortcut name
  SHORTCUT_NAME: "shortcut_name",

  // Campaign creation settings
  PROVIDER_ACCOUNTS: "provider_accounts",

  // Keyboard shortcut
  KEYBOARD_SHORTCUT_NAME: "keyboard_shortcut_name",

  // Bulk edit
  BULK_PARAMETER: "bulk_parameter",
  BULK_OPERATION: "bulk_operation",
  BULK_VALUE: "bulk_value",
  BULK_NUMBER_OF_CAMPAIGNS: "bulk_number_of_campaigns",

  // Campaign duplicate
  CAMPAIGN_DUPLICATE_ORIGIN: "campaign_duplicate_origin",

  // Export report
  EXPORT_REPORT_TYPE: "export_report_type",

  // campaigns - general
  CAMPAIGNS_PER_PAGE: "campaigns_per_page",

  // automation
  AUTOMATION_NAME: "automation_name",
}

export default TrackingConsts
