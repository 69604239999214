import Moment from "moment"
import ActionTypes from "../common/actions/actionTypes"
import ActivityStatus from "../common/consts/activityStatus"
import NavigationBarActionsTypes from "./navigationBarActionTypes"

const navigationBarInitialState = {
  currentNetwork: null,
  currentSite: null,
  selectedItemName: null,
  adminNotifications: {
    active: [],
    dismissed: [],
  },
  drawer: {
    isOpen: false,
    isLoading: false,
    updates: {
      activities: 0,
    },
    lastObjTime: {
      activities: null,
    },
    lastObjStatus: {},
    drawerType: null,
  },
  allActivities: {
    important: new Map(),
    other: new Map(),
  },
}

function sortDrawerItems(a, b) {
  let itemA = a.updated_at ? a.updated_at : a.created_at
  let itemB = b.updated_at ? b.updated_at : b.created_at

  if (itemA < itemB) {
    return 1
  }
  if (itemA > itemB) {
    return -1
  }
  return 0
}

export function generateActivitiesObject(state, action) {
  let importantActivities = new Map(state.allActivities.important)
  let otherActivities = new Map(state.allActivities.other)

  // We need to save it in order to get more activities from the server
  let oldestActivityTime = state.drawer.lastObjTime.activities

  action.activities.forEach((activity, index) => {
    if (index === action.activities.length - 1) {
      oldestActivityTime = activity.created_at
    }

    if (activity.status === ActivityStatus.FAILED) {
      otherActivities.delete(activity.id)
      importantActivities.set(activity.id, activity)
    } else {
      importantActivities.delete(activity.id)
      otherActivities.set(activity.id, activity)
    }
  })

  if (state.allActivities.important !== null) {
    importantActivities = new Map(
      [...importantActivities.entries()].sort((a, b) => {
        return sortDrawerItems(a[1], b[1])
      })
    )
  }
  if (state.allActivities.other !== null) {
    otherActivities = new Map(
      [...otherActivities.entries()].sort((a, b) => {
        return sortDrawerItems(a[1], b[1])
      })
    )
  }

  return {
    allActivities: {
      important: importantActivities,
      other: otherActivities,
    },
    drawer: {
      updates: {
        activities: importantActivities.size,
      },
      lastObjTime: {
        activities: oldestActivityTime,
      },
    },
  }
}

export function generateAdminNotificationsObject(state, action) {
  let active = []
  let notifications = action.adminNotifications
  for (let i = 0; i < notifications.length; i++) {
    let isDismissed = false
    for (let j = 0; j < state.adminNotifications.dismissed.length; j++) {
      if (notifications[i].id === state.adminNotifications.dismissed[j].id) {
        isDismissed = true
      }
    }
    if (!isDismissed) {
      active.push(notifications[i])
    }
  }

  return {
    adminNotifications: Object.assign({}, state.adminNotifications, {
      active,
    }),
  }
}

function navigationBarReducer(state = navigationBarInitialState, action) {
  if (state === undefined || action === undefined) {
    return []
  }

  switch (action.type) {
    case ActionTypes.SITE_SELECTED:
      return Object.assign({}, state, {
        currentNetwork: action.network,
        currentSite: action.site,
        selectedItemName: action.site.name,
      })

    case ActionTypes.NETWORK_SELECTED:
      return Object.assign({}, state, {
        currentNetwork: action.network,
        currentSite: null,
        selectedItemName: action.network.name,
      })

    case NavigationBarActionsTypes.NAVIGATION_DRAWER_STATE_CHANGE:
      let updates = Object.assign({}, state.drawer.updates)

      if (!action.openDrawer) {
        let time = Moment().unix()

        switch (action.drawerComponent) {
          case "activities":
            break
          default:
            break
        }
      }

      return Object.assign({}, state, {
        drawer: {
          isOpen: action.openDrawer,
          updates,
          lastObjTime: state.drawer.lastObjTime,
          lastObjStatus: state.drawer.lastObjStatus,
          drawerType: action.drawerComponent,
        },
      })

    case NavigationBarActionsTypes.GET_NOTIFICATIONS:
      var adminNotificationsObject = generateAdminNotificationsObject(state, action)

      return Object.assign({}, state, {
        adminNotifications: adminNotificationsObject.adminNotifications,

        drawer: Object.assign({}, state.drawer, {
          isLoading: false,
          lastObjStatus: Object.assign({}, state.drawer.lastObjStatus, {}),
        }),
      })

    case NavigationBarActionsTypes.GET_ACTIVITIES:
    case NavigationBarActionsTypes.SHOW_MORE_ACTIVITIES:
      var activitiesObject = generateActivitiesObject(state, action)

      return Object.assign({}, state, {
        allActivities: activitiesObject.allActivities,
        drawer: Object.assign({}, state.drawer, {
          updates: Object.assign({}, state.drawer.updates, {
            activities: activitiesObject.drawer.updates.activities,
          }),
          lastObjTime: Object.assign({}, state.drawer.lastObjTime, {
            activities: activitiesObject.drawer.lastObjTime.activities,
          }),
        }),
      })

    case NavigationBarActionsTypes.DISMISS_ACTIVITY:
      let important = new Map(state.allActivities.important)
      let other = new Map(state.allActivities.other)

      other.set(action.activityId, important.get(action.activityId))
      important.delete(action.activityId)

      return Object.assign({}, state, {
        allActivities: {
          important,
          other,
        },
        drawer: Object.assign({}, state.drawer, {
          updates: Object.assign({}, state.drawer.updates, {
            activities: important.size,
          }),
        }),
      })

    case NavigationBarActionsTypes.SET_ACTIVITIES_PENDING:
      let pendingActivities = new Map()
      important = new Map(state.allActivities.important)
      other = new Map(state.allActivities.other)

      action.activityIds.forEach((activityId) => {
        pendingActivities.set(
          activityId,
          Object.assign({}, important.get(activityId), other.get(activityId), {
            status: ActivityStatus.PENDING,
          })
        )

        important.delete(activityId)
      })

      if (other) {
        other = new Map([...other, ...pendingActivities])
      } else {
        other = new Map([...pendingActivities])
      }

      return Object.assign({}, state, {
        allActivities: {
          important,
          other,
        },
        drawer: Object.assign({}, state.drawer, {
          updates: Object.assign({}, state.drawer.updates, {
            activities: important !== null ? important.size : 0,
          }),
        }),
      })

    case NavigationBarActionsTypes.DISMISS_ADMIN_NOTIFICATION:
      var active = []
      var isFound = false
      var dismissed = state.adminNotifications.dismissed.slice(0)

      for (var i = 0; i < state.adminNotifications.active.length; i++) {
        if (action.adminNotification.id !== state.adminNotifications.active[i].id) {
          active.push(state.adminNotifications.active[i])
        }
      }

      for (var i = 0; i < state.adminNotifications.dismissed.length; i++) {
        if (action.adminNotification.id === state.adminNotifications.dismissed[i].id) {
          isFound = true
        }
      }
      if (!isFound) {
        dismissed.push(action.adminNotification)
      }

      return Object.assign({}, state, {
        adminNotifications: Object.assign({}, state.adminNotifications, {
          active,
          dismissed,
        }),
      })

    case NavigationBarActionsTypes.DISMISS_ACTIVITIES:
      let allActivities = new Map([...state.allActivities.important, ...state.allActivities.other])

      return Object.assign({}, state, {
        allActivities: {
          important: new Map(),
          other: allActivities,
        },
        drawer: Object.assign({}, state.drawer, {
          updates: Object.assign({}, state.drawer.updates, {
            activities: 0,
          }),
        }),
      })

    case NavigationBarActionsTypes.DRAWER_LOADING:
      return Object.assign({}, state, {
        drawer: Object.assign({}, state.drawer, {
          isLoading: action.isLoading,
        }),
      })

    case NavigationBarActionsTypes.GET_ADMIN_NOTIFICATIONS_RESPONSE:
      var adminNotificationsObject = generateAdminNotificationsObject(state, action)

      return Object.assign({}, state, {
        adminNotifications: adminNotificationsObject.adminNotifications,
      })

    default:
      return state
  }
}

export { navigationBarReducer }
