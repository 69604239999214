import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../common/components/genericDropdown"
import AutomationCreationConsts from "../../automationCreationConsts"

class OperationTypeDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: AutomationCreationConsts.ACTION_OPERATIONS,
      selectedIndexesArr: [0],
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (index) => {
    this.setState({
      selectedIndexesArr: index,
    })

    let selectedActionOperation = this.state.menuItems[index]
    this.props.handleSelect("selectedActionOperation", selectedActionOperation)

    // Call the onSelectionChange function when the selection changes
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(selectedActionOperation)
    }

    this.handleMenuClose()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.automationDetailsState.selectedActionOperation !==
      this.props.automationDetailsState.selectedActionOperation
    ) {
      const selectedIndex = this.state.menuItems.findIndex(
        (item) => item.operation === this.props.automationDetailsState.selectedActionOperation.operation
      )
      if (selectedIndex !== -1) {
        this.handleMenuClick([selectedIndex])
      }
    }
  }

  componentDidMount() {
    if (this.props.automationDetailsState.selectedActionOperation) {
      const selectedIndex = this.state.menuItems.findIndex(
        (item) => item.operation === this.props.automationDetailsState.selectedActionOperation.operation
      )
      if (selectedIndex !== -1) {
        this.handleMenuClick([selectedIndex])
      }
    } else if (!isNaN(this.props.automationDetailsState.actionValue)) {
      this.props.automationDetailsState.actionValue >= 0 ? this.handleMenuClick([0]) : this.handleMenuClick([1])
    } else {
      this.handleMenuClick([0])
    }
  }

  render() {
    let buttonClassName = "creation-generic-dropdown-button value-type-button darker-text "

    return (
      <div className={"text-field rule-field"}>
        <div className="creation-generic-dropdown-label">Increase / Decrease</div>
        <GenericDropdown
          header={this.state.menuItems[this.state.selectedIndexesArr[0]].name}
          selectOptionCallback={(index) => {
            this.handleMenuClick(index)
          }}
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowAutoSuggest={false}
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection={false}
          buttonClassName={buttonClassName}
          menuClassName={"value-type-dropdown-menu"}
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(OperationTypeDropdown)
