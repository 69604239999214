import axios from "axios/index"
import Consts from "../app/consts"
import RequestsService from "./requestsService"

export default class ProviderAccountService {
  static getSiteProviderAccounts(siteId) {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/provider_account/get_site_provider_accounts/" + siteId, {
      cancelToken: RequestsService.cancelToken("site-providers-request"),
    })
  }

  static getSiteIdealProviderAccount(providerId, siteId, url) {
    return axios.get(
      Consts.BASE_API_URL +
        "/api/integrations/provider_account/get_site_ideal_provider_account/" +
        providerId +
        "/" +
        siteId,
      {
        cancelToken: RequestsService.cancelToken("site-providers-request"),
      }
    )
  }

  static getSitesProviderAccounts(providerId, siteIds) {
    return axios.get(
      Consts.BASE_API_URL +
        "/api/integrations/provider_account/get_sites_provider_accounts/" +
        providerId +
        "/" +
        siteIds.toString(),
      {
        cancelToken: RequestsService.cancelToken("site-providers-request"),
      }
    )
  }

  static getNetworkProviderAccounts(networkId) {
    return axios.get(
      Consts.BASE_API_URL + "/api/integrations/provider_account/get_network_provider_accounts/" + networkId
    )
  }
}
