import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../common/components/genericDropdown"
import AutomationCreationConsts from "../../automationCreationConsts"

class SourceDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: AutomationCreationConsts.FREQUENCIES,
      selectedIndexesArr: [0],
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (index) => {
    this.setState({
      selectedIndexesArr: index,
    })

    let selectedFrequency = this.state.menuItems[index]
    this.props.handleSelect("selectedFrequency", selectedFrequency)

    this.handleMenuClose()
  }

  componentDidMount() {
    if (this.props.automationDetailsState.selectedFrequency) {
      const selectedIndex = this.state.menuItems.findIndex(
        (item) => item.frequency === this.props.automationDetailsState.selectedFrequency.frequency
      )
      if (selectedIndex !== -1) {
        this.handleMenuClick([selectedIndex])
      }
    }
  }

  render() {
    let buttonClassName = "creation-generic-dropdown-button darker-text "

    return (
      <div className={"text-field"}>
        <div className="creation-generic-dropdown-label">Frequency</div>
        <GenericDropdown
          header={this.state.menuItems[this.state.selectedIndexesArr[0]].name}
          selectOptionCallback={(index) => {
            this.handleMenuClick(index)
          }}
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowAutoSuggest={false}
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection={false}
          buttonClassName={buttonClassName}
          menuClassName={"creation-generic-dropdown-menu"}
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(SourceDropdown)
