import React from "react"
import { connect } from "react-redux"
import { campaignPopupSortPerformanceList, updateCampaignPopupTablePage } from "../../campaignPopupActions"
import Table from "../../../common/components/table/table"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import Pagination from "../../../common/components/pagination"

class CampaignPopupPerformanceByFunnelList extends React.Component {
  columns = CampaignsConsts.LIST_COLUMNS
  scrollersObject = null

  constructor(props) {
    super(props)

    this.tableName = "performanceByFunnel"
  }

  sortFunnelBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, "performanceByFunnel"))
  }

  getHeader = () => {
    let columns = this.columns

    return {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.keyword.fieldName,
          value: columns.keyword.title,
          clickable: true,
          cellSize: columns.keyword.cellSize,
        },
        {
          fieldName: columns.unique_search_impressions.fieldName,
          value: columns.unique_search_impressions.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.unique_search_impressions.tooltip,
          },
        },
        {
          fieldName: columns.click_out_rate.fieldName,
          value: columns.click_out_rate.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.click_out_rate.tooltip,
          },
        },
        {
          fieldName: columns.click_outs.fieldName,
          value: columns.click_outs.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.click_outs.tooltip,
          },
        },
      ],
    }
  }

  getSummaryRow = (summaryData) => {
    let columns = this.columns

    return {
      cssClasses: ["summary-row"],
      allowRowSelection: false,
      cells: [
        {
          fieldName: columns.keyword.fieldName,
          value: null,
          cellSize: columns.keyword.cellSize,
        },
        {
          fieldName: columns.unique_search_impressions.fieldName,
          value: columns.unique_search_impressions.defaultFormat(summaryData.impressions),
        },
        {
          fieldName: columns.click_out_rate.fieldName,
          value: columns.click_out_rate.defaultFormat(summaryData.click_out_rate),
        },
        {
          fieldName: columns.click_outs.fieldName,
          value: columns.click_outs.defaultFormat(summaryData.click_outs),
        },
      ],
    }
  }

  getRow = (funnel) => {
    let columns = this.columns
    return {
      cells: [
        {
          fieldName: columns.keyword.fieldName,
          value: funnel.keyword,
          cellSize: columns.keyword.cellSize,
          cssClasses: ["funnel-column"],
        },
        {
          fieldName: columns.unique_search_impressions.fieldName,
          value: columns.unique_search_impressions.defaultFormat(funnel.impressions),
        },
        {
          fieldName: columns.click_out_rate.fieldName,
          value: columns.click_out_rate.defaultFormat(funnel.click_out_rate),
        },
        {
          fieldName: columns.click_outs.fieldName,
          value: columns.click_outs.defaultFormat(funnel.click_outs),
        },
      ],
    }
  }

  render() {
    let header = this.getHeader()
    let rows = []
    let pagination = null

    if (this.props.performanceByFunnel.length > 0) {
      rows.push(this.getSummaryRow(this.props.summaryRow))
    }

    for (
      let i = (this.props.currentPage - 1) * this.props.itemsPerPage;
      i < this.props.currentPage * this.props.itemsPerPage && i < this.props.performanceByFunnel.length;
      i++
    ) {
      let Funnel = this.props.performanceByFunnel[i]
      rows.push(this.getRow(Funnel))
    }

    if (this.props.performanceByFunnel.length > this.props.itemsPerPage) {
      pagination = (
        <Pagination
          itemsArr={
            this.props.performanceByFunnel ? this.props.performanceByFunnel.length : this.props.performanceByFunnel
          }
          currentPage={this.props.currentPage}
          itemsPerPage={this.props.itemsPerPage}
          updatePage={(updatedPage) => {
            this.props.dispatch(updateCampaignPopupTablePage(updatedPage, this.tableName))
          }}
        />
      )
    }

    return (
      <div>
        <Table
          header={header}
          rows={rows}
          sort={{
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection,
            sortHandler: this.sortFunnelBy,
          }}
          scrolling={{
            containerClass: ".campaign-popup-container",
            includeNavigationBar: false,
          }}
          cssClasses={["campaign-popup-list"]}
        />
        {pagination}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sortBy: state.campaignPopupV2.performanceByFunnel.sortBy,
    sortDirection: state.campaignPopupV2.performanceByFunnel.sortDirection,
    performanceByFunnel: state.campaignPopupV2.performanceByFunnel.dataForTable,
    summaryRow: state.campaignPopupV2.performanceByFunnel.summaryRow,
    itemsPerPage: state.campaignPopupV2.performanceByFunnel.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceByFunnel.currentPage,
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByFunnelList)
