import React from "react"
import { connect } from "react-redux"
import CreationValidator from "../creationValidator"
import WarningIconSmall from "../../resources/svgs/WarningIconSmall.svg"
import ArticleUrl from "./fields/articleUrl"
import CampaignName from "./fields/campaignName"
import PageDropdown from "./dropdowns/pageDropdown"
import NotificationIcon from "../../resources/svgs/NotificationIcon.svg"
import LanguagesDropdown from "./dropdowns/languagesDropdown"
import NetworkDropdown from "./dropdowns/networkDropdown"
import SourceAdTypeDropdown from "./dropdowns/sourceAdTypeDropDown"
import KeywordChipInput from "./fields/keywordField"

class CampaignDetails extends React.Component {
  generateValidationError = (validationErrors, field) => {
    let error = validationErrors.get(field)
    if (!this.props.campaignDetailsState.validationErrorsVisible) {
      return null
    }

    if (error) {
      return (
        <div className="validation-error">
          <WarningIconSmall />
          <span className="text">{validationErrors.get(field)}</span>
        </div>
      )
    }

    return null
  }

  generateValidationWarning = (field) => {
    if (field === "campaignName") {
      if (!this.props.campaignDetailsState.validationWarningsVisible) {
        return null
      }

      return (
        <div className="validation-warning">
          <WarningIconSmall />
          <span className="text">Notice: name includes extra spaces</span>
        </div>
      )
    }
  }

  render() {
    let validationErrors = new Map()
    let campaignNameValidationErrors = null
    let campaignNameValidationWarnings = null
    let articleUrlValidationErrors = null
    let networkValidationErrors = null
    let languageValidationErrors = null
    let campaignSettingsView = null
    let networkDropdown = null
    let pageValidationErrors = null
    let adTypeValidationErrors = null

    if (this.props.campaignDetailsState.validationErrorsVisible && this.props.sourceData.selected) {
      validationErrors = CreationValidator.validateCampaignDetailsStep(
        {
          name: this.props.campaignDetailsState.campaignName,
          articleUrl: this.props.campaignDetailsState.articleUrl,
          articleIsArchived: this.props.campaignDetailsState.articleIsArchived,
          possibleSitesForDomain: this.props.campaignDetailsState.possibleSitesForDomain,
          siteId: this.props.campaignDetailsState.siteId,
          page: this.props.campaignDetailsState.selectedPage,
          languages: this.props.campaignDetailsState.languages,
        },
        this.props.sites.map((s) => s.domain_name),
        this.props.sourceKey,
        this.props.campaignSources,
        this.props.siteIdToSiteObject
      )

      campaignNameValidationErrors = this.generateValidationError(validationErrors, "campaignName")
      articleUrlValidationErrors = this.generateValidationError(validationErrors, "articleUrl")
      networkValidationErrors = this.generateValidationError(validationErrors, "network")
      languageValidationErrors = this.generateValidationError(validationErrors, "languages")
      pageValidationErrors = this.generateValidationError(validationErrors, "page")
      adTypeValidationErrors = this.generateValidationError(validationErrors, "adType")
    }

    if (this.props.campaignDetailsState.validationWarningsVisible && this.props.sourceData.selected) {
      campaignNameValidationWarnings = this.generateValidationWarning("campaignName")
    }

    if (this.props.campaignSettings.possibleSitesForDomain.length > 1) {
      networkDropdown = (
        <NetworkDropdown
          networkValidationErrors={networkValidationErrors}
          handleNetworkSelection={this.props.handleNetworkSelection}
        />
      )
    }

    if (
      this.props.campaignSettings.siteId &&
      !this.props.isLoadingProviderAccounts &&
      this.props.providerAccounts.length === 0
    ) {
      campaignSettingsView = (
        <div className="no-provider-accounts-message">
          <NotificationIcon className="icon" />
          <div>There are no {this.props.sourceKey} accounts linked.</div>
          <div>Please try again after adding.</div>
        </div>
      )
    } else {
      campaignSettingsView = (
        <>
          <CampaignName
            campaignDetailsState={this.props.campaignDetailsState}
            handleChange={this.props.handleChange}
            campaignNameValidationErrors={campaignNameValidationErrors}
            campaignNameValidationWarnings={campaignNameValidationWarnings}
          />
          {this.props.sourceKey === "Taboola" ? null : (
            <LanguagesDropdown
              languagesValidationErrors={languageValidationErrors}
              handleChange={this.props.handleChange}
              campaignDetailsState={this.props.campaignDetailsState}
              handleLanguageSelection={this.props.handleLanguageSelection}
              selectedLanguages={this.props.campaignSettings.selectedLanguages}
              recentLanguages={this.props.campaignSettings.recentLanguages}
              sourceKey={this.props.sourceKey}
            />
          )}

          {this.props.sourceKey === "Facebook" ? (
            <>
              <PageDropdown
                campaignDetailsState={this.props.campaignDetailsState}
                handleChange={this.props.handleChange}
                pageValidationErrors={pageValidationErrors}
                sourceKey={this.props.sourceKey}
                selectedSource={this.props.selectedSource}
              />
            </>
          ) : null}
          <>
            <SourceAdTypeDropdown
              sourceKey={this.props.sourceKey}
              selectedSource={this.props.selectedSource}
              adTypeValidationErrors={adTypeValidationErrors}
            />
          </>
        </>
      )
    }
    return (
      <div className="settings">
        <ArticleUrl
          campaignDetailsState={this.props.campaignDetailsState}
          handleChange={this.props.handleChange}
          onBlur={this.props.onBlur}
          articleUrlValidationErrors={articleUrlValidationErrors}
        />
        {networkDropdown}
        {this.props.campaignDetailsState.isRsoc && (
          <KeywordChipInput
            label="Keywords"
            handleChange={this.props.handleChange}
            campaignNameValidationErrors={campaignNameValidationErrors}
            campaignNameValidationWarnings={campaignNameValidationWarnings}
            selectedKeywords={this.props.campaignDetailsState.selectedKeywords}
            placeholder="Add search terms (separated by commas)"
            description="If left blank, default search terms from Google will be used"
            articleKeywords={this.props.campaignDetailsState.articleKeywords}
          />
        )}
        {campaignSettingsView}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sites: state.app.sites ? state.app.sites : [],
    parentCampaign: state.campaignCreationWizard.parentCampaign,
    campaignSettings: state.campaignCreationWizard.campaignSettings,
    isOpen: state.campaignCreationWizard.isOpen,
    siteIdToSiteObject: state.app.siteIdToSiteObject,
    userId: state.app.currentUser.id,
    isLoadingProviderAccounts: state.campaignCreationWizard.isLoadingProviderAccounts,
    selectedSource: state.campaignCreationWizard.campaignSources.sources[ownProps.sourceKey],
    campaignSources: state.campaignCreationWizard.campaignSources,
  }
}

export default connect(mapStateToProps)(CampaignDetails)
