import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../../common/components/genericDropdown"
import merge from "deepmerge"
import { updateTargetingGroup } from "../../../campaignCreationActions"

class BrowsersDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      browsersMenuOpen: false,
      browsersMenuAnchorEl: null,
      browsersSelectedIndexesArr: this.props.campaignTargetingGroup.browsers
        .map((browser, index) => {
          return {
            ...browser,
            originalIndex: index,
          }
        })
        .filter((browser) => browser.selected)
        .map((browser) => browser.originalIndex),
      menuItems: this.props.campaignTargetingGroup.browsers,
      includeMenuOpen: false,
      includeMenuAnchorEl: null,
      includeSelectedIndexesArr: [],
    }
  }

  browsersOpenMenu = (event) => {
    this.setState({
      browsersMenuOpen: true,
      browsersMenuAnchorEl: event.currentTarget,
    })
  }

  browsersCloseMenu = () => {
    this.setState({
      browsersMenuOpen: false,
    })
  }

  onBrowserSelected = (browsersSelectedIndexesArr) => {
    let browsers = merge([], this.state.menuItems)

    browsers.forEach((browser, index) => {
      browser.selected = browsersSelectedIndexesArr.includes(index)
    })

    this.setState({
      browsersSelectedIndexesArr,
    })

    let targetingGroupData = Object.assign({}, this.props.campaignTargetingGroup, { browsers })
    if (this.props.onChange) {
      this.props.onChange(targetingGroupData)
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, targetingGroupData))
    }
  }

  render() {
    let browsersHeader = "Choose browser"
    let buttonClassName = "creation-generic-dropdown-button "
    let selectedBrowsers = this.props.campaignTargetingGroup.browsers.filter((browser) => browser.selected)
    if (selectedBrowsers.length > 0) {
      buttonClassName += "selected"
      if (selectedBrowsers.length === 1) {
        browsersHeader = selectedBrowsers[0].name
      } else if (selectedBrowsers.length === this.state.menuItems.length) {
        browsersHeader = "All browsers selected"
      } else {
        browsersHeader = `${selectedBrowsers.length} browsers selected`
      }
    }

    return (
      <div className={"text-field browsers-selection "}>
        <div className="creation-generic-dropdown-label">Browsers</div>
        <GenericDropdown
          header={browsersHeader}
          allowMultiple
          options={this.state.menuItems}
          open={this.state.browsersMenuOpen}
          anchorEl={this.state.browsersMenuAnchorEl}
          openDropdownCallback={this.browsersOpenMenu}
          closeDropdownCallback={this.browsersCloseMenu}
          selectedIndexes={this.state.browsersSelectedIndexesArr}
          selectOptionCallback={this.onBrowserSelected}
          allowSelectAll
          buttonClassName={buttonClassName}
          menuClassName="creation-generic-dropdown-menu"
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(BrowsersDropdown)
