import PolicyReducerActionTypes from "./policyActionTypes"

const policyState = {
  policyReport: null,
  isLoading: true,
  filters: new Map(),
}

const addStatusProps = (drafts) => {
  // For each draft, we add reviewStatus flag
  return Object.keys(drafts).reduce((creatives, draftId) => {
    creatives[draftId] = drafts[draftId].map((creative) => ({
      ...creative,
      imageReviewStatus: false,
    }))
    return creatives
  }, {})
}

function policyReducer(state = policyState, action) {
  if (state == undefined || action == undefined) {
    return []
  }
  switch (action.type) {
    case PolicyReducerActionTypes.FETCH_POLICY_RESPONSE:
      return Object.assign({}, state, {
        policyReport: addStatusProps(action.drafts),
        hasData: action.hasCampaigns,
        isLoading: false,
        draftsSource: action.draftsSource,
      })

    case PolicyReducerActionTypes.UPDATE_POLICY_REPORT:
      return Object.assign({}, state, {
        policyReport: action.drafts,
      })

    case PolicyReducerActionTypes.POLICY_LIST_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      })

    case PolicyReducerActionTypes.ROLLBACK_POLICY_PAGE:
      return Object.assign({}, state, {
        isLoading: false,
        hasCampaigns: true,
        policyReport: null,
        filters: new Map(),
      })

    case PolicyReducerActionTypes.UPDATE_FILTER_POLICY:
      return Object.assign({}, state, {
        filters: action.filters,
      })

    case PolicyReducerActionTypes.CLEAR_POLICY_FILTER:
      return Object.assign({}, state, {
        filters: new Map(),
      })

    default:
      return state
  }
}
export { policyReducer }
