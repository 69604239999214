import React from "react"
import { connect } from "react-redux"
import {
  campaignPopupCancelRequest,
  campaignPopupFetchCampaignBidHistoryAsync,
  campaignPopupLoading,
} from "../campaignPopupActions"
import { CircularProgress } from "@material-ui/core"
import CampaignPopupBidHistoryList from "./lists/bidHistoryList"
import ExportButton from "../../common/components/exportButton"
import { keyboardShortcutsManager } from "../../utils/keyboardShortcutsManager"
import { eventsTracker } from "../../api/eventsTracker"
import { findReactComponentByDomElement } from "../../utils/domUtils"
import Moment from "moment"
import Consts from "../../app/consts"

const EXPORT_TABLE_SHORTCUT_KEY = "export_table"

class CampaignPopupBidHistory extends React.Component {
  getBidHistory = () => {
    campaignPopupCancelRequest("bid-history-request")
    this.props.dispatch(campaignPopupLoading())
    this.props.dispatch(
      campaignPopupFetchCampaignBidHistoryAsync(
        this.props.campaign,
        this.props.datePickerDates.startDate,
        this.props.datePickerDates.endDate,
        false
      )
    )
  }

  componentDidMount() {
    this.getBidHistory()
    keyboardShortcutsManager.addShortcut(EXPORT_TABLE_SHORTCUT_KEY, ["shift", "e"], this.exportCsvFunction)
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      JSON.stringify(prevProps.datePickerDates.startDate) !== JSON.stringify(this.props.datePickerDates.startDate) ||
      JSON.stringify(prevProps.datePickerDates.endDate) !== JSON.stringify(this.props.datePickerDates.endDate)
    ) {
      this.getBidHistory()
    }
  }

  componentWillUnmount() {
    campaignPopupCancelRequest("bid-history-request")
    keyboardShortcutsManager.removeShortcut(EXPORT_TABLE_SHORTCUT_KEY)
  }

  exportCsvFunction = () => {
    let bidHistoryTable = findReactComponentByDomElement(document.querySelector(".bid-history .generic-table"))
    bidHistoryTable.exportToCsv()
    eventsTracker.trackExport(
      "campaign_activity",
      null,
      null,
      Moment(this.props.datePickerDates.startDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2),
      Moment(this.props.datePickerDates.endDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2),
      this.props.campaign.tracking_code
    )
  }

  render() {
    let bidHistory
    let campaignExportButton = <ExportButton downloadFunction={this.exportCsvFunction} />

    if (this.props.isLoading) {
      bidHistory = (
        <div className="campaign-popup-loading d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      bidHistory = (
        <div className="bid-history">
          <CampaignPopupBidHistoryList />
          <div className="row">
            <div className="col-9" />
            <div className="col-3 d-flex justify-content-end">{campaignExportButton}</div>
          </div>
        </div>
      )
    }

    return <div className="campaign-popup-bid-history">{bidHistory}</div>
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    bidHistory: state.campaignPopupV2.bidHistory,
    isLoading: state.campaignPopupV2.isLoading,
  }
}

export default connect(mapStateToProps)(CampaignPopupBidHistory)
