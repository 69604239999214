import axios from "axios"
import Consts from "../app/consts"

export default class NotificationsService {
  static getAllNotifications(fromTime, networkId) {
    return axios.get(Consts.BASE_API_URL + "/api/notifications", {
      params: {
        from_time: fromTime,
        network_id: networkId,
      },
    })
  }

  static getAdminNotifications(scope) {
    return axios.get(Consts.BASE_API_URL + "/api/notifications/admin_notifications", {
      params: {
        scope,
      },
    })
  }
}
