import React from "react"
import { Link } from "react-router"
import { Close } from "@material-ui/icons"
import { Divider, Menu, MenuItem, TextField } from "@material-ui/core"
import Autosuggest from "react-autosuggest"
import NetworkTypes from "../../common/consts/networkTypes"
import { focusParentWithClass } from "../../utils/funcUtils"
import LogoutButton from "./logoutButton"
import SearchIcon from "../../resources/svgs/SearchIcon.svg"

function getSuggestionValue(suggestion) {
  return suggestion
}

function renderSuggestionsContainer(options) {
  return null
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return null
}

export default class NavigationMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      suggestions: [],
      allSuggestions: [],
      currentMenu: [],
    }
  }

  renderInput = (inputProps) => {
    const { autoFocus, value, ref } = inputProps
    let icon = null
    if (this.props.search !== "") {
      icon = (
        <span onClick={this.handleClearSearchFilter}>
          <Close className="clear-filter-text" />
        </span>
      )
    } else {
      icon = (
        <span>
          <SearchIcon className="search-icon" />
        </span>
      )
    }

    return (
      <div className="d-flex align-items-center text-field-wrapper">
        <TextField
          classes={{ root: "text-field" }}
          autoFocus={autoFocus}
          value={value}
          inputRef={ref}
          InputProps={{
            inputProps,
          }}
        />
        {icon}
      </div>
    )
  }

  showSuggestionsMenu = () => {
    let items = []
    let networkItems = []
    let networkGroupItems = []
    let menuItemsToHtml = this.props.menuItemsToHtml
    let networkToSites = this.props.networkToSites
    let suggestions = this.state.suggestions.slice()

    let networkGroupNames = NetworkTypes.NETWORK_GROUP_TYPE_IDS.map(
      (networkGroupTypeId) => NetworkTypes.idToType[networkGroupTypeId].name
    )

    suggestions.forEach((suggestion) => {
      if (networkGroupNames.includes(suggestion)) {
        networkGroupItems.push(menuItemsToHtml.get(suggestion))
      }
    })

    suggestions = suggestions.filter((suggestion) => !networkGroupNames.includes(suggestion))

    if (networkGroupItems.length > 0) {
      items.push(...networkGroupItems, <div className="divider network-group-divider" key="networkGroupDivider" />)
    }

    suggestions.forEach((suggestion) => {
      // If the current suggestion is a site - get the object of that site
      let site = this.props.sites.filter((currentSite) => {
        return currentSite.name + " " + currentSite.id === suggestion
      })

      if (site.length > 0) {
        let network = networkToSites.filter((currentNetwork) => {
          let currentNetworkId = parseInt(currentNetwork.key)
          if (isNaN(currentNetworkId)) {
            return false
          }

          return currentNetworkId === site[0].network_id
        })

        if (network.length > 0) {
          // Make sure we add the site's network first
          if (
            suggestions.indexOf(network[0].data[0].network.name + " " + network[0].data[0].network.id) === -1 &&
            networkItems.indexOf(network[0].data[0].network.name) === -1
          ) {
            networkItems.push(network[0].data[0].network.name)
            items.push(menuItemsToHtml.get(network[0].data[0].network.name + " " + network[0].data[0].network.id))
          }
        }
      }

      items.push(menuItemsToHtml.get(suggestion))
    })

    return items
  }

  handleSuggestionsFetchRequested = (input) => {
    this.setState(
      {
        suggestions: this.getSuggestions(input.value),
      },
      () =>
        this.setState({
          currentMenu: this.showSuggestionsMenu(),
        })
    )
  }

  handleSuggestionsClearRequested = () => {
    let arr = this.initSuggestions()
    this.setState(
      {
        suggestions: arr,
        allSuggestions: arr,
      },
      () => {
        if (this.props.search.length === 0) {
          this.setState({
            currentMenu: this.showSuggestionsMenu(),
          })
        }
      }
    )
  }

  handleClearSearchFilter = () => {
    this.focusTextField()
    this.setState(
      {
        suggestions: this.state.allSuggestions,
      },
      () => {
        this.props.searchCallback("")
        this.setState({
          currentMenu: this.showSuggestionsMenu(),
        })
      }
    )
  }

  handleChange = (event) => {
    this.props.searchCallback(event.target.value)
  }

  getSuggestions = (value) => {
    if (value !== undefined) {
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length

      if (inputLength === 0) {
        return []
      }

      let suggestions = this.state.allSuggestions.filter((suggestion) => {
        return suggestion.toLowerCase().includes(inputValue)
      })

      return suggestions
    }
  }

  handleFocusChange = (event) => {
    focusParentWithClass(event, "react-autosuggest-input-menu-item")
  }

  initSuggestions = () => {
    return Array.from(this.props.menuItemsToHtml.keys())
  }

  focusTextField = () => {
    document.querySelector(".navigation-menu input[type=text]").focus()
  }

  render() {
    return (
      <Menu
        classes={{ paper: "default-menu navigation-menu" }}
        anchorEl={this.props.anchorEl}
        open={this.props.open}
        transformOrigin={{
          vertical: -42,
          horizontal: 0,
        }}
        onEntered={this.focusTextField}
        onClose={this.props.closeMenu}
      >
        <MenuItem button key="settings">
          <Link className="settings-button" to="/settings/profile" onClick={this.props.closeMenu}>
            Settings
          </Link>
        </MenuItem>
        <LogoutButton />
        <Divider classes={{ root: "divider" }} />
        <MenuItem classes={{ root: "react-autosuggest-input-menu-item" }}>
          <Autosuggest
            renderInputComponent={this.renderInput}
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
            renderSuggestionsContainer={renderSuggestionsContainer}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              autoFocus: true,
              placeholder: "Search",
              value: this.props.search,
              onChange: this.handleChange,
              onKeyDown: this.handleFocusChange,
            }}
          />
        </MenuItem>
        {this.state.currentMenu}
      </Menu>
    )
  }
}
