import { TrueSpec } from "./generalSpecs"
import Specification from "./Specification"
import { FilterCategorySpec } from "./filterCategorySpec"

export class FilterContainerSpec extends Specification {
  constructor(filterContainer) {
    super()
    let spec = new TrueSpec()

    filterContainer.forEach((filter, key) => {
      spec = spec.and(new FilterCategorySpec(filter))
    })

    this.spec = spec
  }

  isSatisfied(candidate) {
    return this.spec.isSatisfied(candidate)
  }
}
