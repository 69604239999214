import React from "react"
import { connect } from "react-redux"
import { Button } from "@material-ui/core"
import {
  changeWizardStep,
  fetchRecentCountriesAsync,
  addTargetingGroup,
  loadThumbnails,
} from "../campaignCreationActions"
import CampaignsCreationConsts from "../campaignCreationConsts"
import CreationValidator from "../creationValidator"
import WarningIconSmall from "../../resources/svgs/WarningIconSmall.svg"
import { eventsTracker } from "../../api/eventsTracker"
import CampaignCreationTargetingGroup from "./campaignCreationTargetingGroup"
import PlusIcon from "../../resources/svgs/PlusIcon.svg"
import GenericDropdown from "../../common/components/genericDropdown"
import CampaignCreationService from "../../api/campaignCreationService"
import { Link } from "react-router"
import Consts from "../../app/consts"
import CampaignsConsts from "../../campaignsV2/campaignsConsts"

class CampaignCreationTargeting extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      validationErrorsVisible: false,
      targetingGroupPresetsMenuOpen: false,
      targetingGroupPresetsLoading: false,
      targetingGroupPresetsOptions: [],
      targetingGroupPresetsSelectedIndexesArr: [],
      targetingGroupPresetsMenuAnchorEl: null,
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchRecentCountriesAsync())
  }

  moveToCreativesStep = () => {
    let adTypeId = this.props.sourceData.adTypes.filter((adType) => adType.selected)[0].id

    if ([CampaignsConsts.GOOGLE_PROVIDER_ID, CampaignsConsts.FACEBOOK_PROVIDER_ID].includes(this.props.sourceData.id)) {
      this.props.dispatch(loadThumbnails(adTypeId))
    }
    this.props.dispatch(changeWizardStep(CampaignsCreationConsts.CREATION_WIZARD_STEPS.creatives))
    eventsTracker.trackCampaignCreationStep(CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.creatives)
  }

  moveToSourcesStep = () => {
    this.props.dispatch(changeWizardStep(CampaignsCreationConsts.CREATION_WIZARD_STEPS.sources))

    eventsTracker.trackCampaignCreationStep(CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.sources)
  }

  generateValidationError = (validationErrors, field) => {
    let error = validationErrors.get(field)

    if (!this.state.validationErrorsVisible) {
      return null
    }

    if (error) {
      return (
        <div className="validation-error">
          <WarningIconSmall />
          <span className="text">{validationErrors.get(field)}</span>
        </div>
      )
    }

    return null
  }

  handleChange = (name, event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  getValidationErrors = () => {
    let validationErrors = CreationValidator.validateTargetingStep(
      this.props.campaignSettings,
      this.props.campaignSources,
      this.props.campaignTargetingGroups,
      this.props.providerIdToProvider
    )

    return validationErrors
  }

  handleNextClick = () => {
    this.setState(
      {
        validationErrorsVisible: true,
      },
      () => {
        let validationErrors = this.getValidationErrors()

        if (validationErrors.size === 0) {
          this.moveToCreativesStep()
        } else {
          setTimeout(() => {
            document
              .querySelector(".campaign-creation-wizard-popup .validation-error")
              .scrollIntoView({ behavior: "smooth" })
          }, 200)
        }
      }
    )
  }

  renderCampaignTargetingGroups = () => {
    let campaignTargetingGroups = []
    let validationErrors = this.getValidationErrors()

    this.props.campaignTargetingGroups.forEach((campaignTargetingGroup, index) => {
      let targetingGroupValidationErrors = validationErrors.get(`targetingGroup${index}`)

      campaignTargetingGroups.push(
        <CampaignCreationTargetingGroup
          key={index}
          campaignTargetingGroup={campaignTargetingGroup}
          validationErrors={targetingGroupValidationErrors}
          validationErrorsVisible={this.state.validationErrorsVisible}
          groupIndex={index}
        />
      )
    })

    return campaignTargetingGroups
  }

  openTargetingGroupPresetsMenu = (event) => {
    this.setState(
      {
        targetingGroupPresetsMenuOpen: true,
        targetingGroupPresetsSelectedIndexesArr: [],
        targetingGroupPresetsLoading: true,
        targetingGroupPresetsMenuAnchorEl: event.currentTarget.parentElement,
      },
      () => {
        CampaignCreationService.getNetworkTargetingGroupPresets(
          this.props.campaignSettings.networkId,
          this.props.sourceData.id
        ).then((result) => {
          this.setState({
            targetingGroupPresetsOptions: result.data.network_targeting_group_presets.map((presetItem) => {
              return {
                ...presetItem,
                name: presetItem.group_name,
              }
            }),
            targetingGroupPresetsLoading: false,
          })
        })
      }
    )
  }

  closeTargetingGroupPresetsMenu = () => {
    this.setState({
      targetingGroupPresetsMenuOpen: false,
      targetingGroupPresetsLoading: false,
    })

    this.addTargetingGroupPresets()
  }

  selectTargetingGroupPresets = (selectedIndexArr) => {
    this.setState({
      targetingGroupPresetsSelectedIndexesArr: selectedIndexArr,
    })
  }

  addTargetingGroupPresets = () => {
    this.state.targetingGroupPresetsSelectedIndexesArr.forEach((selectedIndex) => {
      let targetingGroupPreset = this.state.targetingGroupPresetsOptions[selectedIndex].targeting_json
      this.props.dispatch(addTargetingGroup(this.props.sourceKey, JSON.parse(targetingGroupPreset)))
    })
  }

  renderAddCampaignTargetingGroupButton = () => {
    return (
      <div className="add-creative selection clickable">
        <span>
          <PlusIcon className="plus-icon" />
        </span>
        <div className="ad-type-selection" onClick={() => this.props.dispatch(addTargetingGroup(this.props.sourceKey))}>
          Add new group
        </div>
        <div className="ad-type-selection" onClick={this.openTargetingGroupPresetsMenu}>
          Add from presets
        </div>
      </div>
    )
  }

  openCampaignCreationSettings = (event) => {
    event.preventDefault()
    window.open(Consts.BASE_URL + "/settings/campaignCreationSettings", "_blank")
  }

  renderTargetingGroupPresetsDropdown = () => {
    let editButton

    if (!this.state.targetingGroupPresetsLoading) {
      editButton = (
        <Link
          className="default-menu-item refresh-btn"
          target="_blank"
          key="refresh-btn"
          to="/settings/campaignCreationSettings"
          onClick={(event) => this.openCampaignCreationSettings(event)}
        >
          Edit
        </Link>
      )
    }

    return (
      <GenericDropdown
        options={this.state.targetingGroupPresetsOptions}
        open={this.state.targetingGroupPresetsMenuOpen}
        anchorEl={this.state.targetingGroupPresetsMenuAnchorEl}
        selectedIndexes={this.state.targetingGroupPresetsSelectedIndexesArr}
        isLoading={this.state.targetingGroupPresetsLoading}
        openDropdownCallback={this.openTargetingGroupPresetsMenu}
        closeDropdownCallback={this.closeTargetingGroupPresetsMenu}
        buttonClassName={"hidden-menu-button"}
        transformOrigin={{ vertical: -30, horizontal: -40 }}
        menuClassName={"creation-generic-dropdown-menu"}
        selectOptionCallback={(selectedIndexesArr) => this.selectTargetingGroupPresets(selectedIndexesArr)}
        menuCustomAdditionalElements={[editButton]}
        allowMultiple
        allowAutoSuggest
      />
    )
  }

  render() {
    let view = null
    let targetingGroupsValidationErrors = null

    if (this.state.validationErrorsVisible) {
      let validationErrors = this.getValidationErrors()

      targetingGroupsValidationErrors = this.generateValidationError(validationErrors, "targetingGroups")
    }

    view = (
      <>
        <div className="step-title">Targeting</div>

        <div className="campaign-targeting-groups">
          {this.renderCampaignTargetingGroups()}
          {this.renderAddCampaignTargetingGroupButton()}
          {this.renderTargetingGroupPresetsDropdown()}
          {targetingGroupsValidationErrors}
        </div>
      </>
    )

    return (
      <div className="campaign-creation-targeting">
        <div className="settings">
          {view}

          <div className="creation-wizard-navigation settings-navigation column-buttons">
            <Button
              variant="contained"
              color="primary"
              className="round-button green next-button"
              onClick={this.handleNextClick}
            >
              {"Next"}
            </Button>
            <div onClick={this.moveToSourcesStep} className="clickable cancel-wizard">
              Back
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let sourceData = {}
  let selectedSourceKey = null

  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
    if (state.campaignCreationWizard.campaignSources.sources[sourceKey].selected) {
      selectedSourceKey = sourceKey
      sourceData = state.campaignCreationWizard.campaignSources.sources[sourceKey]
    }
  })

  return {
    campaignCreationWizard: state.campaignCreationWizard,
    campaignSettings: state.campaignCreationWizard.campaignSettings,
    campaignSources: state.campaignCreationWizard.campaignSources,
    campaignCreatives: state.campaignCreationWizard.campaignCreatives,
    sourceData,
    sourceKey: selectedSourceKey,
    isOpen: state.campaignCreationWizard.isOpen,
    isDuplication: state.campaignCreationWizard.isDuplication,
    siteIdToSiteObject: state.app.siteIdToSiteObject,
    siteIdToNetwork: state.app.siteIdToNetwork,
    providerIdToProvider: state.app.providerIdToProvider,
    userId: state.app.currentUser.id,
    isLoadingCreationSettings: state.campaignCreationWizard.isLoadingCreationSettings,
    wasDuplicateReviewWarningShown: state.campaignCreationWizard.wasDuplicateReviewWarningShown,
    parentCampaign: state.campaignCreationWizard.parentCampaign,
    campaignTargetingGroups: state.campaignCreationWizard.campaignTargetingGroups,
  }
}

export default connect(mapStateToProps)(CampaignCreationTargeting)
