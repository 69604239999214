import React from "react"
import { connect } from "react-redux"
import { Button } from "@material-ui/core"
import CampaignsCreationConsts from "../campaignCreationConsts"
import Source from "./source"
import CreationValidator from "../creationValidator"
import WarningIconSmall from "../../resources/svgs/WarningIconSmall.svg"
import {
  fetchRecentLanguagesAsync,
  getRelevantCreationSettingsForSite,
  updateCampaignDetails,
} from "../campaignCreationActions"
import { getProviderColor } from "../../utils/providerUtils"
import { eventsTracker } from "../../api/eventsTracker"
import { isNullOrEmpty } from "../../utils/funcUtils"
import ActionTypes from "../../common/actions/actionTypes"
import CampaignCreationService from "../../api/campaignCreationService"
import articlesTypeConsts from "../../articles/components/articlesType"
import NetworkTypes from "../../common/consts/networkTypes"

class CampaignCreationSources extends React.Component {
  constructor(props) {
    super(props)

    let languages = props.languages.map((language) => {
      return {
        code: language.code,
        name: language.name,
        selected: props.campaignSettings.selectedLanguages.includes(language.code),
      }
    })

    this.state = {
      validationErrorsVisible: false,
      validationWarningsVisible: false,
      isRsoc: props.campaignSettings.isRsoc,
      articleIsArchived: props.campaignSettings.articleIsArchived,
      // Loading data from the store (this will happen every time we enter the settings step, like when we press "back"
      // from the sources step).
      campaignName: props.campaignSettings.name,
      campaignId: props.campaignSettings.campaignId,
      articleUrl: props.campaignSettings.articleUrl,
      selectedKeywords: props.campaignSettings.selectedKeywords,
      articleKeywords: props.campaignSettings.articleKeywords,
      siteId: props.campaignSettings.siteId,
      networkCode: props.campaignSettings.networkCode,
      siteDomain: props.campaignSettings.siteDomain,
      possibleSitesForDomain: props.campaignSettings.possibleSitesForDomain,
      languages,
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchRecentLanguagesAsync())

    if (this.props.isDuplication) {
      if (!this.props.providerAccounts || this.props.providerAccounts.length === 0) {
        this.getCreationSettingsForSite()
      }
    }

    if (this.state.campaignName !== "") {
      this.handleDoubleSpaceValidation(this.state.campaignName)
    }
    this.updateSiteDetailsInState(() => {
      this.updateStore()
    })
  }

  getCreationSettingsForSite = () => {
    if (this.state.siteId) {
      this.props.dispatch(
        getRelevantCreationSettingsForSite(this.state.siteId, this.props.siteIdToNetwork.get(this.state.siteId))
      )
    }
  }

  handleLanguageSelection = (languageOption) => {
    let languagesState = this.state.languages
    languagesState[languagesState.indexOf(languageOption)].selected = !languageOption.selected

    this.setState(
      {
        languages: languagesState,
      },
      () => {
        this.updateStore()
      }
    )
  }

  getSiteAccountsAndSubAccounts = (providerIds) => {
    let accountsIds = []
    let subAccountsIds = []

    this.props.providerAccounts.forEach((provider) => {
      if (providerIds.includes(provider.provider_id)) {
        accountsIds.push(provider.account_id)
        subAccountsIds.push(provider.sub_account_id)
      }
    })

    return { accountsIds, subAccountsIds }
  }

  getSiteAccounts = (providerId) => {
    let accountsIds = []

    this.props.providerAccounts.forEach((provider) => {
      if (providerId === provider.provider_id) {
        if (accountsIds.length === 0) {
          accountsIds.push(provider.account_id)
        }
      }
    })

    return { accountsIds }
  }

  updateSiteDetailsInState = (callback) => {
    let sites = this.extractSitesFromArticleUrl()

    let selectedSiteAndNetworkDetails = {
      siteId: "",
      siteDomain: "",
      networkId: "",
      networkCode: "",
    }

    if (sites.length === 1) {
      // One possible site for the current domain
      let site = sites[0]
      selectedSiteAndNetworkDetails = {
        siteId: site.id,
        siteDomain: site.domain_name,
        networkId: site.network_id,
        networkCode: site.id ? site.network.code : "",
      }
    } else {
      // Multiple sites for the current domain
      if (
        (this.props.campaignSettings.articleUrl && this.props.campaignSettings.articleUrl === this.state.articleUrl) ||
        !this.props.campaignSettings.articleUrl
      ) {
        if (this.props.campaignSettings.siteId && this.props.campaignSettings.networkCode) {
          // But one was selected since the user chose a network in the network dropdown
          selectedSiteAndNetworkDetails = {
            siteId: this.props.campaignSettings.siteId,
            siteDomain: this.props.campaignSettings.siteDomain,
            networkId: this.props.campaignSettings.networkId,
            networkCode: this.props.campaignSettings.networkCode,
          }
        } else {
          if (
            isNullOrEmpty(this.props.campaignSettings.networkId) ||
            ![NetworkTypes.ALL_NETWORKS.code, NetworkTypes.INTERNAL.code, NetworkTypes.PODS.code].includes(
              this.props.currentNetwork.code
            )
          ) {
            let networkId = this.props.currentNetwork.id
            sites.map((site) => {
              if (site.network_id === networkId) {
                selectedSiteAndNetworkDetails = {
                  siteId: site.id,
                  siteDomain: site.domain_name,
                  networkId: site.network_id,
                  networkCode: site.network.code,
                }
              }
            })
          }
        }
      }

      // If the previous condition was not met, there are multiple sites for the current domain and the use has yet
      // made a network choice
    }

    this.setState(
      {
        ...selectedSiteAndNetworkDetails,
        possibleSitesForDomain: sites,
      },
      () => {
        if (typeof callback === "function") {
          callback()
        }
      }
    )
  }

  extractSitesFromArticleUrl = () => {
    let sitesResult = this.props.sites.filter((s) => this.state.articleUrl.toLowerCase().includes(s.domain_name))

    if (sitesResult.length > 0) {
      return sitesResult
    }

    return [{ id: "", domain_name: "" }]
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (!this.props.isLoading && this.props.isLoading !== prevProps.isLoading) ||
      (this.state.siteId && this.state.siteId !== prevState.siteId)
    ) {
      if (this.state.siteId) {
        this.getCreationSettingsForSite()
      }
      this.props.dispatch({ type: ActionTypes.RESET_NETWORK_TAGS })
    }
    if (
      !isNullOrEmpty(this.state.articleUrl) &&
      !isNullOrEmpty(this.state.networkCode) &&
      this.state.networkCode !== prevState.networkCode
    ) {
      this.handleArticleDetails()
    }
  }

  getSelectedLanguageCodes = () => {
    let selectedLanguages = this.state.languages
      .filter((language) => {
        return language.selected
      })
      .map((language) => {
        return language.code
      })

    return selectedLanguages
  }

  updateStore = () => {
    let selectedLanguages = this.getSelectedLanguageCodes()
    if (this.state.siteId) {
      this.props.dispatch(
        updateCampaignDetails(
          this.state.campaignName,
          this.state.articleUrl,
          this.state.siteId,
          this.state.siteDomain,
          this.state.possibleSitesForDomain,
          this.state.networkId,
          this.state.networkCode,
          selectedLanguages,
          this.state.isRsoc,
          this.state.selectedKeywords,
          this.state.articleIsArchived,
          this.state.articleKeywords
        )
      )
    } else if (this.state.possibleSitesForDomain.length > 1) {
      // More than one site exists for the current domain (through multiple networks), update the redux store in order
      // to display the network dropdown
      this.props.dispatch(
        updateCampaignDetails(
          this.state.campaignName,
          this.state.articleUrl,
          this.state.siteId,
          this.state.siteDomain,
          this.state.possibleSitesForDomain,
          "",
          "",
          selectedLanguages,
          this.state.isRsoc,
          this.state.selectedKeywords,
          this.state.articleIsArchived,
          this.state.articleKeywords
        )
      )
    }
  }

  handleArticleDetails = async () => {
    try {
      const response = await CampaignCreationService.getArticleDetails(this.state.articleUrl, this.state.networkCode)
      if (response.data?.article_details.post_style === articlesTypeConsts.ads_for_search.name) {
        this.setState({
          articleIsArchived: false,
          isRsoc: true,
          articleKeywords: response.data.article_details.keywords,
          selectedKeywords: [...new Set([...this.state.selectedKeywords, ...response.data.article_details.keywords])],
        })
      } else {
        this.setState({ articleIsArchived: false })
      }

      this.updateStore()
    } catch (error) {
      if (this.state.isRsoc) {
        this.setState({ isRsoc: false })
      }
      this.setState({ articleIsArchived: true })
      this.updateStore()
    }
  }

  handleOnBlur = (name, event) => {
    let value = event.target.value
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "articleUrl" && this.state.networkCode !== "") {
          this.handleArticleDetails()
        }
      }
    )
  }
  handleNetworkSelection = (selected) => {
    this.setState(
      {
        networkCode: selected.networkCode,
        networkId: selected.networkId,
        siteId: selected.siteId,
        siteDomain: selected.siteDomain,
      },
      () => {
        this.updateStore()
      }
    )
  }
  handleChange = (name, event, inputValue = null) => {
    let value = inputValue || event.target.value
    this.setState(
      {
        [name]: value,
      },
      () => {
        if ("articleUrl" == name) {
          this.updateSiteDetailsInState(() => {
            this.updateStore()
          })
        } else if (name === "campaignName") {
          this.handleDoubleSpaceValidation(value)
        } else {
          this.updateStore()
        }
      }
    )
  }

  moveNext = () => {
    this.updateStore()
    this.props.onNextClickedFunc(CampaignsCreationConsts.CREATION_WIZARD_STEPS.targeting)
    eventsTracker.trackCampaignCreationStep(CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.targeting)
  }

  handleDoubleSpaceValidation = (campaignName) => {
    this.setState({
      validationWarningsVisible: campaignName.includes("  "),
    })
  }

  generateValidationError = (validationErrors, field) => {
    let error = validationErrors.get(field)

    if (!this.state.validationErrorsVisible) {
      return null
    }

    if (error) {
      return (
        <div className="validation-error">
          <WarningIconSmall />
          <span className="text">{validationErrors.get(field)}</span>
        </div>
      )
    }

    return null
  }

  generateSourcesList = () => {
    let sources = []
    let allProviderAccountsForSite = this.props.providerAccounts

    Object.keys(this.props.campaignSources.sources).forEach((sourceKey, index) => {
      let providerId = this.props.campaignSources.sources[sourceKey].id
      let providerColor = getProviderColor(this.props.providers, sourceKey)

      let providerAccounts = allProviderAccountsForSite.filter((providerAccount) => {
        // Filtering only the provider accounts that part of this provider
        if (providerId === providerAccount.provider_id) {
          return providerAccount
        }
      })

      sources.push(
        <Source
          key={index}
          sourceKey={sourceKey}
          providerColor={providerColor}
          providerAccounts={providerAccounts}
          campaignDetailsState={this.state}
          handleChange={this.handleChange}
          onBlur={this.handleOnBlur}
          handleLanguageSelection={this.handleLanguageSelection}
          handleNetworkSelection={this.handleNetworkSelection}
          sourceData={this.props.campaignSources.sources[sourceKey]}
        />
      )
    })

    return sources
  }

  getValidationErrors = () => {
    let validationErrors = CreationValidator.validateSourcesStep(this.props.campaignSources)
    validationErrors = new Map([
      ...validationErrors,
      ...CreationValidator.validateCampaignDetailsStep(
        {
          name: this.state.campaignName,
          articleUrl: this.state.articleUrl,
          articleIsArchived: this.state.articleIsArchived,
          possibleSitesForDomain: this.state.possibleSitesForDomain,
          siteId: this.state.siteId,
          languages: this.state.languages,
        },
        this.props.sites.map((s) => s.domain_name),
        this.props.selectedSourceKey,
        this.props.campaignSources,
        this.props.siteIdToSiteObject
      ),
    ])
    return validationErrors
  }

  handleNextClick = async () => {
    this.setState(
      {
        validationErrorsVisible: true,
      },
      async () => {
        let validationErrors = this.getValidationErrors()
        if (validationErrors.size === 0) {
          this.moveNext()
        }
      }
    )
  }

  render() {
    let sourcesValidationErrors = null

    if (this.state.validationErrorsVisible) {
      let validationErrors = this.getValidationErrors()
      sourcesValidationErrors = this.generateValidationError(validationErrors, "sources")
    }

    let view = (
      <>
        <div className="step-title">Select Source</div>
        <div className="campaign-creation-sources">{this.generateSourcesList()}</div>
      </>
    )

    return (
      <div>
        {view}
        {sourcesValidationErrors}
        <div className="creation-wizard-navigation settings-navigation column-buttons">
          <Button
            color="primary"
            variant="contained"
            className="round-button green next-button"
            onClick={this.handleNextClick}
          >
            Next
          </Button>
          <div onClick={() => this.props.closeWizardFunc(false)} className="clickable cancel-wizard">
            Back
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let selectedSource = null
  let selectedSourceKey = null
  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
    let source = state.campaignCreationWizard.campaignSources.sources[sourceKey]

    if (source.selected) {
      selectedSource = source
      selectedSourceKey = sourceKey
    }
  })
  return {
    sites: state.app.sites ? state.app.sites : [],
    campaignSettings: state.campaignCreationWizard.campaignSettings,
    campaignSources: state.campaignCreationWizard.campaignSources,
    currentNetwork: state.navigationBar.currentNetwork,
    providers: state.app.providers,
    providerAccounts: state.campaignCreationWizard.providerAccounts,
    siteIdToSiteObject: state.app.siteIdToSiteObject,
    siteIdToNetwork: state.app.siteIdToNetwork,
    isDuplication: state.campaignCreationWizard.isDuplication,
    isLoading: state.campaignCreationWizard.isLoading,
    languages: state.app.languages,
    selectedSource,
    selectedSourceKey,
  }
}

export default connect(mapStateToProps)(CampaignCreationSources)
