import React from "react"
import NumberFormat from "../../../common/components/numberFormat"
import { getProviderBidStepSize } from "../../../utils/providerUtils"

export default class ActionValue extends React.Component {
  handleValueChange = (values) => {
    const { value, floatValue } = values

    let numberValue = value
    if (floatValue.toString() === value) {
      numberValue = floatValue
    } else if (value[value.length - 1] === "0" && floatValue !== 0) {
      numberValue = floatValue
    }

    // Call the onValueChange function when the value changes
    if (this.props.onValueChange) {
      this.props.onValueChange(numberValue)
    }
  }

  render() {
    const numberValue = this.props.automationDetailsState.actionValue
    const prefix = parseFloat(numberValue) > 0 ? "+" : ""
    let decimalScale = 2
    if (this.props.automationDetailsState.selectedSource?.providerId) {
      decimalScale = getProviderBidStepSize(this.props.automationDetailsState.selectedSource.providerId).countDecimals()
    }

    return (
      <div className={"text-field value-field " + (this.props.validationErrors ? "error " : "")}>
        <NumberFormat
          value={numberValue}
          displayType={"input"}
          onValueChange={this.handleValueChange}
          className="number-text-field"
          decimalScale={decimalScale}
          prefix={prefix}
        />
        {this.props.validationErrors}
      </div>
    )
  }
}
