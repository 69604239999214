import AutomationService from "../api/automationService"
import { notificationAdd } from "../common/actions/commonActions"
import RequestsService from "../api/requestsService"
import AutomationActionTypes from "./automationActionTypes"
import AutomationsConsts from "./automationsConsts"

export function fetchAutomationsResponse(automations, isLoading) {
  return {
    type: AutomationActionTypes.FETCH_AUTOMATIONS_RESPONSE,
    automations,
    isLoading,
  }
}
export function automationsListLoading() {
  return { type: AutomationActionTypes.AUTOMATIONS_LIST_LOADING, isLoading: true }
}

export function fetchAutomationsAsync(networkCode) {
  return (dispatch) => {
    RequestsService.cancelRequest("automations-list-request")
    return AutomationService.getNetworkAutomations(networkCode)
      .then((result) => {
        dispatch(fetchAutomationsResponse(result.data.rules, false))
      })
      .catch((error) => {
        if (error) {
          dispatch(fetchAutomationsResponse([], false))
          dispatch(notificationAdd(AutomationsConsts.AUTOMATIONS_ERROR_MESSAGE))
        }
      })
  }
}

export function rollbackAutomationsPage() {
  return { type: AutomationActionTypes.ROLLBACK_AUTOMATIONS_PAGE }
}

export function updateItemsPerPage(itemsPerPage) {
  return (dispatch) => {
    dispatch({ type: AutomationActionTypes.UPDATE_ITEMS_PER_PAGE, itemsPerPage })
    dispatch(resetAutomationsPagination())
  }
}

export function resetAutomationsPagination() {
  return { type: AutomationActionTypes.RESET_AUTOMATIONS_PAGINATION }
}

export function updateAutomationsPage(currentPage) {
  return { type: AutomationActionTypes.UPDATE_AUTOMATIONS_PAGINATION, currentPage }
}

export function pauseAutomationAsync(automation) {
  return (dispatch) => {
    AutomationService.pauseAutomation(automation.id)
      .then((result) => {
        dispatch(pauseAutomationResponse(automation))
      })
      .catch((error) => {
        dispatch(pauseAutomationErrorResponse(automation))
      })
  }
}

export function activateAutomationAsync(automation) {
  return (dispatch) => {
    AutomationService.activateAutomation(automation.id, automation.network_code)
      .then(() => {
        dispatch(activateAutomationResponse(automation))
      })
      .catch(() => {
        dispatch(activateAutomationErrorResponse(automation))
      })
  }
}

export function automationStatusLoading(automation) {
  return { type: AutomationActionTypes.AUTOMATION_STATUS_LOADING, automation, isAutomationStatusLoading: true }
}

export function pauseAutomationResponse(automation) {
  return {
    type: AutomationActionTypes.PAUSE_AUTOMATION_RESPONSE,
    automation,
    isAutomationStatusLoading: false,
    status: AutomationsConsts.AUTOMATION_STATUS_PAUSED,
  }
}

export function pauseAutomationErrorResponse(automation) {
  return {
    type: AutomationActionTypes.PAUSE_AUTOMATION_ERROR_RESPONSE,
    automation,
    isAutomationStatusLoading: false,
    status: AutomationsConsts.AUTOMATION_STATUS_RUNNING,
  }
}

export function activateAutomationResponse(automation) {
  return {
    type: AutomationActionTypes.ACTIVATE_AUTOMATION_RESPONSE,
    automation,
    isAutomationStatusLoading: false,
    status: AutomationsConsts.AUTOMATION_STATUS_RUNNING,
  }
}

export function activateAutomationErrorResponse(automation) {
  return {
    type: AutomationActionTypes.ACTIVATE_AUTOMATION_ERROR_RESPONSE,
    automation,
    isAutomationStatusLoading: false,
    status: AutomationsConsts.AUTOMATION_STATUS_PAUSED,
  }
}
