export function gcd(a, b) {
  if (!b) {
    return a
  }

  return gcd(b, a % b)
}

export function mod(n, m) {
  return ((n % m) + m) % m
}
