import React from "react"
import { Checkbox } from "@material-ui/core"
import OsTypes from "../../common/consts/osTypes"

export default class Platform extends React.Component {
  DEFAULT_ANDROID_PLATFORMS = ["Mobile", "Tablet"]

  render() {
    let items = []
    let numSelected = 0
    let subOptionsKeys = Object.keys(this.props.platformData.values)

    if (subOptionsKeys.length > 0) {
      subOptionsKeys.forEach((subOptionsKey, subOptionsIndex) => {
        if (this.props.platformData.values[subOptionsKey]) {
          numSelected++
        }

        items.push(
          <label className="sub-section-item clickable" key={subOptionsKey}>
            <Checkbox
              checked={this.props.platformData.values[subOptionsKey]}
              classes={{ root: "checkbox", checked: "checked" }}
              onChange={() => {
                this.props.onSubPlatformSelect(this.props.platformKey, subOptionsKey)
              }}
              value="selected"
            />
            <span>{OsTypes.nameToDisplayName[subOptionsKey]}</span>
          </label>
        )
      })
    }

    let platformSettings = null

    if (items.length > 0) {
      let subPlatforms = null

      if (items.length > 0) {
        subPlatforms = <div className="sub-platforms">{items}</div>
      }

      platformSettings = (
        <div className={"platform-settings" + (this.props.platformData.selected ? " open" : "")}>{subPlatforms}</div>
      )
    }

    return (
      <div className="multi-selection" key={this.props.platformKey}>
        <label className="platform clickable" key={this.props.platformKey}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < subOptionsKeys.length && subOptionsKeys.length > 0}
            checked={this.props.platformData.selected}
            classes={{ root: "checkbox", checked: "checked" }}
            onChange={() => this.props.onPlatformSelect(this.props.platformKey)}
            value="selected"
          />
          {this.props.platformData.displayedName || this.props.platformKey}
        </label>
        {platformSettings}
      </div>
    )
  }
}
