import React from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import { CircularProgress } from "@material-ui/core"
import { disableFixedHeaderHandler, handleDataListStickyElements } from "../../../utils/domUtils"
import Row from "./row"
import { downloadFile } from "../../../utils/funcUtils"
import ExportButton from "../exportButton"

export default class Table extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      columnIndexToSize: {},
    }

    this.table = null
    this.scrollersObject = null
  }

  handleTableStickyElements = () => {
    disableFixedHeaderHandler(this.scrollersObject)
    let containerClass = this.props.scrolling.containerClass
    let containerElement = this.props.scrolling.containerElement
    let includeNavigationBar = this.props.scrolling.includeNavigationBar

    if (containerClass || containerElement) {
      this.scrollersObject = handleDataListStickyElements(
        containerClass ? document.querySelector(containerClass) : containerElement,
        this.table,
        includeNavigationBar,
        true,
        false
      )
    }
  }

  componentWillMount() {
    let columnIndexToSize = this.getColumnIndexToCellSize()

    this.setState({
      columnIndexToSize,
    })
  }

  componentDidMount() {
    ReactTooltip.rebuild()
    this.handleTableStickyElements()
  }

  componentWillUnmount() {
    disableFixedHeaderHandler(this.scrollersObject)
  }

  componentDidUpdate(prevProps) {
    ReactTooltip.rebuild()
    this.handleTableStickyElements()
    let columnIndexToSize = this.getColumnIndexToCellSize()

    if (JSON.stringify(this.state.columnIndexToSize) !== JSON.stringify(columnIndexToSize)) {
      this.setState({
        columnIndexToSize,
      })
    }
  }

  getHeader = () => {
    if (Array.isArray(this.props.header)) {
      return this.props.header.map((row, index) => {
        return (
          <Row
            key={"header" + index}
            allowRowSelection={false}
            stickyCells={row.stickyCells}
            cells={row.cells}
            cssClasses={row.cssClasses}
          />
        )
      })
    }
    return (
      <Row
        key="header"
        allowRowSelection={false}
        stickyCells={this.props.header.stickyCells}
        cells={this.props.header.cells}
        sort={this.props.sort}
        cssClasses={this.props.header.cssClasses}
      />
    )
  }

  getRows = (includeNotVisible = false) => {
    let rows = []

    this.props.rows.forEach((row, index) => {
      rows.push(
        <Row
          key={index}
          includeNotVisible={includeNotVisible}
          columnIndexToSize={this.state.columnIndexToSize}
          {...row}
        />
      )
    })

    return rows
  }

  getColumnIndexToCellSize = () => {
    let i = 0
    let columnIndexToSize = {}
    let header = this.props.header

    if (Array.isArray(this.props.header)) {
      header = this.props.header[0]
    }

    if (header.stickyCells) {
      header.stickyCells.forEach((cell) => {
        columnIndexToSize[i] = cell.cellSize ? cell.cellSize : "small"
        i += 1
      })
    }

    header.cells.forEach((cell) => {
      columnIndexToSize[i] = cell.cellSize ? cell.cellSize : "small"
      i += 1
    })

    return columnIndexToSize
  }

  exportToCsv = () => {
    let header = this.getHeader()
    let rows

    if (this.props.getAllRows) {
      rows = this.props.getAllRows()
    } else {
      rows = this.getRows()
    }

    let headerValues = [...header.props.stickyCells, ...header.props.cells]
      .filter((headerCell) => headerCell.visibleOnExport !== false)
      .map((headerCell) => {
        if (headerCell.title) {
          return headerCell.title
        }

        if (headerCell.value) {
          return headerCell.value
        }

        return headerCell.fieldName
      })

    headerValues.unshift("key")

    let rowsValues = []

    rows.forEach((row) => {
      if (row.key && row.key.includes("no-export")) {
        return
      }

      let rowValues = []
      rowValues.push(row.key)
      let cells

      if (row.stickyCells && row.cells) {
        cells = [...row.stickyCells, ...row.cells]
      } else {
        cells = [...row.props.stickyCells, ...row.props.cells]
      }

      cells.forEach((cell) => {
        if (cell.visibleOnExport === false) {
          return
        }

        if (cell.rawValue) {
          rowValues.push(cell.rawValue)
        } else {
          rowValues.push("")
        }
      })

      rowsValues.push(rowValues)
    })

    let csvFile = []
    csvFile.push(headerValues.join(","))
    rowsValues.forEach((rowValues) => csvFile.push(rowValues.join(",")))

    let downloadData = csvFile.join("\n")
    downloadFile(downloadData, this.props.csvFileName + ".csv")
  }

  render() {
    let rows = []
    let loader = null
    let noDataElement = null
    let exportToCsvButton = null
    let header = this.getHeader()

    if (this.props.isLoadingData) {
      loader = (
        <div className="d-flex align-items-center page-loading">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      rows = this.getRows()

      if (rows.length === 0) {
        noDataElement = this.props.noDataElement
      }
    }

    if (this.props.isExportableToCsv && this.props.showExportToCsvButton) {
      exportToCsvButton = <ExportButton downloadFunction={this.exportToCsv} />
    }

    return (
      <>
        <div
          className={"generic-table " + this.props.cssClasses.join(" ")}
          ref={(table) => {
            this.table = table
          }}
        >
          <div className="header sticky-header">{header}</div>
          {loader}
          {rows}
          {noDataElement}
          <div className="sticky-bottom-scrollbar">
            <div className="handle" />
          </div>
        </div>
        {exportToCsvButton}
      </>
    )
  }
}

Table.defaultProps = {
  header: {},
  rows: [],
  scrolling: {
    containerClass: "",
    containerElement: null,
    includeNavigationBar: true,
  },
  cssClasses: [],
  isLoadingData: false,
  noDataElement: null,
  isExportableToCsv: false,
  showExportToCsvButton: false,
  csvFileName: "table-export",
  getAllRows: null,
}

Table.propTypes = {
  // The header of the table. can contain sticky cells, regular cells and css classes
  header: PropTypes.oneOfType([
    PropTypes.shape({
      stickyCells: PropTypes.arrayOf(PropTypes.object),
      cells: PropTypes.arrayOf(PropTypes.object),
    }),

    // In order to support multiple rows in one sticky header, header can also get an array
    PropTypes.arrayOf(
      PropTypes.shape({
        stickyCells: PropTypes.arrayOf(PropTypes.object),
        cells: PropTypes.arrayOf(PropTypes.object),
      })
    ),
  ]),

  // Array of row elements. Each row can contain stickyCells, cells and css classes
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      stickyCells: PropTypes.arrayOf(PropTypes.object),
      cells: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  scrolling: PropTypes.shape({
    containerClass: PropTypes.string,
    containerElement: PropTypes.object,
    includeNavigationBar: PropTypes.bool,
  }),

  // Css classes which will be applied on the top element of the table
  cssClasses: PropTypes.arrayOf(PropTypes.string),

  // Indicated whether we should present a loader
  isLoadingData: PropTypes.bool,

  // The element to show when there is no data
  noDataElement: PropTypes.element,

  // Params for CSV export: if it's allowed, if we need to show the button and a custom function to get all
  // the rows for the table if it happens to have pagination
  isExportableToCsv: PropTypes.bool,
  showExportToCsvButton: PropTypes.bool,
  csvFileName: PropTypes.string,
  getAllRows: PropTypes.func,
}
