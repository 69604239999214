import React from "react"
import { connect } from "react-redux"
import CountriesDropdown from "../countriesDropdown"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import { isNullOrUndefined } from "../../../../utils/funcUtils"

class CountriesAndCountryGroupsDropdown extends React.Component {
  constructor(props) {
    super(props)

    let countries = []
    props.countries.forEach((country) => {
      if (country.allow_creation) {
        countries.push({
          code: country.code,
          name: country.name,
          selected: isNullOrUndefined(props.campaignTargetingGroup.selectedCountries)
            ? false
            : props.campaignTargetingGroup.selectedCountries.includes(country.code),
        })
      }
    })

    this.state = {
      countries,
      countryGroups: [],
    }
  }

  componentWillMount() {
    this.setState(
      {
        countryGroups: this.getCountryGroups(),
      },
      () => this.updateTargetingGroupStore()
    ) // To remove unavailable selected group from duplication
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.campaignTargetingGroup.selectedCountries) !==
        JSON.stringify(prevProps.campaignTargetingGroup.selectedCountries) ||
      JSON.stringify(this.props.campaignTargetingGroup.selectedCountryGroups) !==
        JSON.stringify(prevProps.campaignTargetingGroup.selectedCountryGroups)
    ) {
      let countries = this.state.countries
      countries.forEach((country) => {
        country.selected = this.props.campaignTargetingGroup.selectedCountries.includes(country.code)
      })

      this.setState({
        countries,
        countryGroups: this.getCountryGroups(),
      })
    }

    if (JSON.stringify(this.props.campaignTargetingGroup.id) !== JSON.stringify(prevProps.campaignTargetingGroup.id)) {
      this.setState({
        countryGroups: this.getCountryGroups(),
      })
    }
  }

  getCountryGroups = () => {
    const selectedSourceId = this.props.campaignTargetingGroup.id
    let countryGroups = []
    if (this.props.campaignTargetingGroup.selectedCountryGroups.length > 0) {
      if (this.props.campaignTargetingGroup.selectedCountryGroups[0].name === "Multiple Countries") {
        countryGroups.push({ ...this.props.campaignTargetingGroup.selectedCountryGroups[0], selected: true })
      }
    }
    if (!isNullOrUndefined(this.props.countryGroups[selectedSourceId])) {
      Object.values(this.props.countryGroups[selectedSourceId]).forEach((countryGroup) => {
        countryGroups.push({
          ...countryGroup,
          selected:
            this.props.campaignTargetingGroup.selectedCountryGroups.filter((cg) => {
              return countryGroup.name === cg.name
            }).length > 0,
        })
      })
    }

    return countryGroups
  }

  updateTargetingGroupStore = () => {
    let countryCodes = this.getSelectedCountryCodes()

    let newTargetingGroupDataData = {
      selectedCountries: countryCodes,
      selectedCountryGroups: this.getSelectedCountryGroups(),
    }

    if (this.props.onChange) {
      this.props.onChange(newTargetingGroupDataData)
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, newTargetingGroupDataData))
    }
  }

  handleCountrySelection = (countryOption) => {
    let countriesState = this.state.countries
    countriesState[countriesState.indexOf(countryOption)].selected = !countryOption.selected

    this.setState(
      {
        countries: countriesState,
      },
      () => {
        this.updateTargetingGroupStore()
      }
    )
  }

  handleCountryGroupSelection = (countryGroup) => {
    let countryGroupsState = this.state.countryGroups.slice()
    let countryGroupIndex = -1

    countryGroupsState.forEach((cg, index) => {
      if (countryGroup.name === cg.name) {
        countryGroupIndex = index
      }
    })

    countryGroupsState[countryGroupIndex] = Object.assign({}, countryGroupsState[countryGroupIndex], {
      selected: !countryGroup.selected,
    })

    this.setState(
      {
        countryGroups: countryGroupsState,
      },
      () => {
        this.updateTargetingGroupStore()
      }
    )
  }

  getSelectedCountryCodes = () => {
    let selectedCountries = this.state.countries
      .filter((country) => {
        return country.selected
      })
      .map((country) => {
        return country.code
      })

    return selectedCountries
  }

  getSelectedCountryGroups = () => {
    let selectedCountryGroups = this.state.countryGroups.filter((countryGroup) => {
      return countryGroup.selected
    })

    return selectedCountryGroups
  }

  render() {
    return (
      <div className="duplicate-warning">
        <CountriesDropdown
          networkId={this.props.networkId}
          countriesValidationErrors={this.props.countriesValidationErrors}
          handleChange={() => {}}
          countries={this.state.countries}
          countryGroups={this.state.countryGroups}
          handleCountrySelection={this.handleCountrySelection}
          handleCountryGroupSelection={this.handleCountryGroupSelection}
          recentCountries={this.props.campaignSettings.recentCountries}
        />
        <HintsAndOptions
          getSelectedCountryGroups={this.getSelectedCountryGroups}
          countries={this.state.countries}
          sourceKey={this.props.sourceKey}
        />
      </div>
    )
  }
}

function HintsAndOptions({ getSelectedCountryGroups, countries, sourceKey }) {
  const selectedCountryGroups = getSelectedCountryGroups()
  const selectedCountries = countries.filter((country) => country.selected)
  const showWWSelectionMessage =
    selectedCountryGroups.length + selectedCountries.length === 0 &&
    ["Facebook", "GoogleAds", "Pinterest"].includes(sourceKey)

  if (showWWSelectionMessage) {
    return <span style={{ color: "#999999" }}>Leave country selection empty to set World Wide targeting</span>
  }

  return null
}

function mapStateToProps(state, ownProps) {
  let selectedSourceKey = null

  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
    if (state.campaignCreationWizard.campaignSources.sources[sourceKey].selected) {
      selectedSourceKey = sourceKey
    }
  })

  return {
    countries: state.app.countries,
    countryGroups: state.app.countryGroups,
    campaignSettings: state.campaignCreationWizard.campaignSettings,
    networkId: ownProps.networkId ? ownProps.networkId : state.campaignCreationWizard.campaignSettings.networkId,
    sourceKey: selectedSourceKey,
  }
}

export default connect(mapStateToProps)(CountriesAndCountryGroupsDropdown)
