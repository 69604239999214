class Browsers {}

Browsers.types = {
  safari: {
    name: "Safari",
    tracking_code: "SA",
  },
  opera: {
    name: "Opera",
    tracking_code: "OP",
  },
  chrome: {
    name: "Chrome",
    tracking_code: "CH",
  },
  uc_browser: {
    name: "UC Browser",
    tracking_code: "UC",
  },
  in_app: {
    name: "InApp",
    tracking_code: "IA",
  },
  samsung: {
    name: "Samsung",
    tracking_code: "SM",
  },
  firefox: {
    name: "Firefox",
    tracking_code: "FF",
  },
  internet_explorer: {
    name: "Internet Explorer",
    tracking_code: "IE",
  },
  edge: {
    name: "Edge",
    tracking_code: "ED",
  },
  chromium: {
    name: "Chromium",
    tracking_code: "CM",
  },
  facebook: {
    name: "Facebook Browser",
    tracking_code: "FB",
  },
  ios_webview: {
    name: "iOS WebView",
    tracking_code: "WV",
  },
  android_app: {
    name: "Android App",
    tracking_code: "AN",
  },
  miui_browser: {
    name: "MIUI Browser",
    tracking_code: "MU",
  },
  amazon_silk: {
    name: "Amazon Silk",
    tracking_code: "AS",
  },
  yandex: {
    name: "Yandex",
    tracking_code: "YX",
  },
  google_search_app: {
    name: "Google Search App",
    tracking_code: "GO",
  },
}

Browsers.typeToName = {}
for (const type in Browsers.types) {
  Browsers.typeToName[type] = Browsers.types[type].name
}

Browsers.typeToNameList = Object.keys(Browsers.types).map((type) => ({
  name: Browsers.types[type].name,
  value: type,
}))

Browsers.multipleBrowsers = "Multiple browsers"

Browsers.outbrain = {
  safari: { ...Browsers.types.safari, api_code: "Safari", selected: false },
  opera: { ...Browsers.types.opera, api_code: "Opera", selected: false },
  chrome: { ...Browsers.types.chrome, api_code: "Chrome", selected: false },
  uc_browser: { ...Browsers.types.uc_browser, api_code: "UCBrowser", selected: false },
  in_app: { ...Browsers.types.in_app, api_code: "InApp", selected: false },
  samsung: { ...Browsers.types.samsung, api_code: "Samsung", selected: false },
  firefox: { ...Browsers.types.firefox, api_code: "Firefox", selected: false },
  internet_explorer: { ...Browsers.types.internet_explorer, api_code: "InternetExplorer", selected: false },
  edge: { ...Browsers.types.edge, api_code: "Edge", selected: false },
}

Browsers.taboola = {
  safari: { ...Browsers.types.safari, api_code: "Safari", selected: false },
  opera: { ...Browsers.types.opera, api_code: "Opera", selected: false },
  chrome: { ...Browsers.types.chrome, api_code: "Chrome", selected: false },
  chromium: { ...Browsers.types.chromium, api_code: "Chromium", selected: false },
  uc_browser: { ...Browsers.types.uc_browser, api_code: "UC Browser", selected: false },
  facebook: { ...Browsers.types.facebook, api_code: "Facebook In-App Browser", selected: false },
  ios_webview: { ...Browsers.types.ios_webview, api_code: "WebView", selected: false },
  android_app: { ...Browsers.types.android_app, api_code: "Android App", selected: false },
  miui_browser: { ...Browsers.types.miui_browser, api_code: "MIUI Browser", selected: false },
  amazon_silk: { ...Browsers.types.amazon_silk, api_code: "Amazon Silk", selected: false },
  samsung: { ...Browsers.types.samsung, api_code: "Samsung Browser", selected: false },
  firefox: { ...Browsers.types.firefox, api_code: "Firefox", selected: false },
  internet_explorer: { ...Browsers.types.internet_explorer, api_code: "Internet Explorer", selected: false },
  edge: { ...Browsers.types.edge, api_code: "Edge", selected: false },
  yandex: { ...Browsers.types.yandex, api_code: "Yandex", selected: false },
  google_search_app: { ...Browsers.types.google_search_app, api_code: "Google Search App", selected: false },
}

export default Browsers
