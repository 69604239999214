import axios from "axios"
import Consts from "../app/consts"

export default class subSourceService {
  static pauseSubSource(campaignId, subSourceName) {
    return axios.put(
      Consts.BASE_API_URL + "/api/integrations/sub_source_management/" + campaignId + "/" + subSourceName + "/pause"
    )
  }

  static activateSubSource(campaignId, subSourceName) {
    return axios.put(
      Consts.BASE_API_URL + "/api/integrations/sub_source_management/" + campaignId + "/" + subSourceName + "/activate"
    )
  }

  static changeSubSourceBid(campaignId, subSourceName, currentBid, desiredBid) {
    return axios.put(
      Consts.BASE_API_URL + "/api/integrations/sub_source_management/" + campaignId + "/" + subSourceName + "/bid",
      {
        bid: parseFloat(desiredBid.toFixed(3)),
        current_bid: parseFloat(currentBid.toFixed(3)),
      }
    )
  }
}
