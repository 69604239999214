import React from "react"
import { connect } from "react-redux"
import { campaignPopupSortPerformanceList, updateCampaignPopupTablePage } from "../../campaignPopupActions"
import Table from "../../../common/components/table/table"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import Pagination from "../../../common/components/pagination"

class CampaignPopupPerformanceByItemList extends React.Component {
  columns = CampaignsConsts.LIST_COLUMNS
  scrollersObject = null

  constructor(props) {
    super(props)

    this.tableName = "performanceByItem"
  }

  sortCountriesBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, this.tableName))
  }

  getHeader = () => {
    let columns = this.columns

    return {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.image.fieldName,
          value: columns.image.title,
          clickable: false,
        },
        {
          fieldName: columns.item.fieldName,
          value: columns.item.title,
          clickable: true,
          cellSize: columns.item.cellSize,
        },
        {
          fieldName: columns.impressions.fieldName,
          value: columns.impressions.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.impressions?.tooltip,
          },
        },
        {
          fieldName: columns.data_columns.clicks.fieldName,
          value: columns.data_columns.clicks.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.clicks?.tooltip,
          },
        },
        {
          fieldName: columns.data_columns.ctr.fieldName,
          value: columns.data_columns.ctr.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.ctr?.tooltip,
          },
        },
        {
          fieldName: columns.item_rpm.fieldName,
          value: columns.item_rpm.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.item_rpm?.tooltip,
          },
        },
        {
          fieldName: columns.orders.fieldName,
          value: columns.orders.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.orders?.tooltip,
          },
        },
        {
          fieldName: columns.order_rate.fieldName,
          value: columns.order_rate.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.order_rate?.tooltip,
          },
        },
        {
          fieldName: columns.estimated_revenue.fieldName,
          value: columns.estimated_revenue.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.estimated_revenue?.tooltip,
          },
        },
        {
          fieldName: columns.item_click_value.fieldName,
          value: columns.item_click_value.title,
          clickable: true,
          containerProps: {
            "data-tip": columns.item_click_value?.tooltip,
          },
        },
      ],
    }
  }

  getSummaryRow = (summaryData) => {
    let columns = this.columns.data_columns

    return {
      cssClasses: ["summary-row"],
      allowRowSelection: false,
      cells: [
        {
          fieldName: this.columns.image.fieldName,
          value: "",
        },
        {
          fieldName: this.columns.item.fieldName,
          value: "",
          cellSize: this.columns.item.cellSize,
        },
        {
          fieldName: this.columns.impressions.fieldName,
          value: this.columns.impressions.defaultFormat(summaryData.impressions),
        },
        {
          fieldName: columns.clicks.fieldName,
          value: columns.clicks.defaultFormat(summaryData.clicks),
        },
        {
          fieldName: columns.ctr.fieldName,
          value: columns.ctr.defaultFormat(summaryData.ctr),
        },
        {
          fieldName: this.columns.item_rpm.fieldName,
          value: this.columns.item_rpm.defaultFormat(summaryData.item_rpm),
        },
        {
          fieldName: this.columns.orders.fieldName,
          value: this.columns.orders.defaultFormat(summaryData.orders),
        },
        {
          fieldName: this.columns.order_rate.fieldName,
          value: this.columns.order_rate.defaultFormat(summaryData.order_rate),
        },
        {
          fieldName: this.columns.estimated_revenue.fieldName,
          value: this.columns.estimated_revenue.defaultFormat(summaryData.estimated_revenue),
        },
        {
          fieldName: this.columns.item_click_value.fieldName,
          value: this.columns.item_click_value.defaultFormat(summaryData.click_value),
        },
      ],
    }
  }

  getRow = (item) => {
    let columns = this.columns
    return {
      cells: [
        {
          fieldName: this.columns.image.fieldName,
          value: this.columns.image.campaignCustomFormat(item),
          cssClasses: ["item-row"],
        },
        {
          fieldName: this.columns.item.fieldName,
          value: this.columns.item.campaignCustomFormat(item),
          cssClasses: ["item-row"],
          cellSize: this.columns.item.cellSize,
        },
        {
          fieldName: this.columns.impressions.fieldName,
          value: this.columns.impressions.defaultFormat(item.impressions),
          cssClasses: ["item-row"],
        },
        {
          fieldName: columns.data_columns.clicks.fieldName,
          value: columns.data_columns.clicks.defaultFormat(item.clicks),
          cssClasses: ["item-row"],
        },
        {
          fieldName: columns.data_columns.ctr.fieldName,
          value: columns.data_columns.ctr.defaultFormat(item.ctr),
          cssClasses: ["item-row"],
        },
        {
          fieldName: this.columns.item_rpm.fieldName,
          value: this.columns.item_rpm.defaultFormat(item.item_rpm),
          cssClasses: ["item-row"],
        },
        {
          fieldName: this.columns.orders.fieldName,
          value: this.columns.orders.defaultFormat(item.orders),
          cssClasses: ["item-row"],
        },
        {
          fieldName: this.columns.order_rate.fieldName,
          value: this.columns.order_rate.defaultFormat(item.order_rate),
          cssClasses: ["item-row"],
        },
        {
          fieldName: this.columns.estimated_revenue.fieldName,
          value: this.columns.estimated_revenue.defaultFormat(item.estimated_revenue),
          cssClasses: ["item-row"],
        },
        {
          fieldName: this.columns.item_click_value.fieldName,
          value: this.columns.item_click_value.defaultFormat(item.click_value),
          cssClasses: ["item-row"],
        },
      ],
    }
  }

  render() {
    let header = this.getHeader()
    let rows = []
    let pagination = null

    if (this.props.performanceByItem.length > 0) {
      rows.push(this.getSummaryRow(this.props.summaryRow))
    }

    for (
      let i = (this.props.currentPage - 1) * this.props.itemsPerPage;
      i < this.props.currentPage * this.props.itemsPerPage && i < this.props.performanceByItem.length;
      i++
    ) {
      let item = this.props.performanceByItem[i]
      rows.push(this.getRow(item))
    }

    if (this.props.performanceByItem.length > this.props.itemsPerPage) {
      pagination = (
        <Pagination
          itemsArr={this.props.performanceByItem ? this.props.performanceByItem.length : this.props.performanceByItem}
          currentPage={this.props.currentPage}
          itemsPerPage={this.props.itemsPerPage}
          updatePage={(updatedPage) => {
            this.props.dispatch(updateCampaignPopupTablePage(updatedPage, this.tableName))
          }}
        />
      )
    }

    return (
      <div>
        <Table
          header={header}
          rows={rows}
          sort={{
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection,
            sortHandler: this.sortCountriesBy,
          }}
          scrolling={{
            containerClass: ".campaign-popup-container",
            includeNavigationBar: false,
          }}
          cssClasses={["campaign-popup-list"]}
        />
        {pagination}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sortBy: state.campaignPopupV2.performanceByItem.sortBy,
    sortDirection: state.campaignPopupV2.performanceByItem.sortDirection,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    performanceByItem: state.campaignPopupV2.performanceByItem.dataForTable,
    summaryRow: state.campaignPopupV2.performanceByItem.summaryRow,
    itemsPerPage: state.campaignPopupV2.performanceByItem.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceByItem.currentPage,
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByItemList)
