import axios from "axios"
import Consts from "../app/consts"

export default class ActivityService {
  static getActivities(fromTime) {
    return axios.get(Consts.BASE_API_URL + "/api/activities", {
      from_time: fromTime,
    })
  }

  static updateActivity(activityId, newStatus) {
    return axios.post(Consts.BASE_API_URL + "/api/activities/" + activityId + "/update", {
      new_status: newStatus,
    })
  }

  static retryActivityWithMinderRequest(requestId) {
    return axios.post(Consts.BASE_API_URL + "/api/requests/" + requestId + "/retry")
  }

  static retryActivity(activityId) {
    return axios.post(Consts.BASE_API_URL + "/api/activities/" + activityId + "/retry")
  }

  static dismissAllActivities() {
    return axios.post(Consts.BASE_API_URL + "/api/activities/dismiss")
  }

  static showMoreActivities(oldestActivityTimestamp) {
    return axios.get(Consts.BASE_API_URL + "/api/activities/more", {
      params: {
        oldest_activity_timestamp: oldestActivityTimestamp,
      },
    })
  }

  static retryMultipleActivities(activityIds, minderRequestIds) {
    return axios.post(Consts.BASE_API_URL + "/api/activities/retry", {
      activity_ids: activityIds,
      minder_request_ids: minderRequestIds,
    })
  }
}
