import React from "react"

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="container full-width in-app-footer">
        <div className="row">
          <div className="main-margins-1"></div>
          <div className="main-body-1">
            <div className="copyright">All rights reserved, PubPlus {new Date().getFullYear()} ©</div>
            <div className="pages">
              <a href="http://www.pubplus.com/terms-of-service/" target="_blank">
                Terms Of Service
              </a>
              <a href="http://www.pubplus.com/privacy-policy/" target="_blank">
                Privacy Policy
              </a>
            </div>
          </div>
          <div className="main-margins-1"></div>
        </div>
      </footer>
    )
  }
}
