class AdminNotificationScope {}

AdminNotificationScope.all = 0
AdminNotificationScope.pubplus = 1
AdminNotificationScope.sudo = 2

AdminNotificationScope.idToName = {
  0: { title: "All" },
  1: { title: "PubPlus" },
  2: { title: "Sudo" },
}

export default AdminNotificationScope
