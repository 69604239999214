import AdminPageActionTypes from "../admin/adminPageActionTypes"
import FilterTypes from "../common/consts/filterTypesV2"
import merge from "deepmerge"
import Consts from "../app/consts"
import Moment from "moment"
import { retrieveSortedAndFiltered } from "../utils/funcUtils"
import { store } from "../app/store"
import ActionTypes from "../common/actions/actionTypes"

const adminPageInitialState = {
  users: {
    userItems: [],
    visibleUserItems: [],
    itemsPerPage: 50,
    currentPage: 1,
    isLoading: false,
    filters: new Map(),
  },
  sites: new Map(),
  networks: new Map(),
  roles: new Map(),
  modules: new Map(),
  accountManagers: new Map(),
  rolesPage: {
    visibleRoles: [],
    filters: new Map(),
  },
  modulesPage: {
    visibleModules: [],
    filters: new Map(),
  },
  sitesPage: {
    visibleSites: [],
    filters: new Map(),
  },
  networksPage: {
    visibleNetworks: [],
    filters: new Map(),
  },
  popupUser: {
    isPopupOpen: false,
    general: null,
    roles: null,
    sites: null,
  },
  popupRole: {
    isPopupOpen: false,
    role: null,
    roleModules: new Set(),
    isLoading: false,
  },
  popupModule: {
    isPopupOpen: false,
    module: null,
    isLoading: false,
    rolesUsingModule: [],
  },
  popupSite: {
    isPopupOpen: false,
    site: null,
    isLoading: false,
    suggestedUsers: null,
  },
  popupNetwork: {
    isPopupOpen: false,
    network: null,
    isLoading: false,
  },
  messages: {
    adminNotificationItems: [],
    isLoading: false,
  },
  popupAdminNotification: {
    isPopupOpen: false,
    data: null,
  },
  config: [],
  isLoading: false,
}

function adminPageReducer(state = adminPageInitialState, action) {
  if (state == undefined || action == undefined) {
    return []
  }

  let retrieveSortedAndFilteredUsers = (nextState) => {
    if (!nextState.users.userItems) {
      return null
    }

    // Start with the full list of unfiltered and unsorted users
    let count
    let filterMap = nextState.users.filters
    let sortedAndFilteredUsers = nextState.users.userItems.slice()
    if (filterMap.size !== 0) {
      sortedAndFilteredUsers = sortedAndFilteredUsers.filter((user) => {
        count = 0
        for (let [filterType, values] of filterMap.entries()) {
          for (let value in values) {
            let searchValue
            let id
            let firstValue
            let secondValue
            if (values[value].filterOperator !== FilterTypes.filterOperators.BETWEEN) {
              searchValue = values[value].filterValue
              id = parseFloat(searchValue)
            } else {
              firstValue = parseFloat(values[value].filterValue.first)
              secondValue = parseFloat(values[value].filterValue.second)
            }

            if (
              (isNaN(id) && values[value].filterOperator === FilterTypes.filterOperators.EQUAL) ||
              values[value].filterOperator === FilterTypes.filterOperators.STRING_EQUAL
            ) {
              if (user[filterType].toLowerCase().includes(searchValue.toLowerCase())) {
                count++
                break
              }
            } else {
              let isPassedTheFilter = false
              let factor = 1
              if (values[value].filterValueFactor) {
                factor = values[value].filterValueFactor
              }
              switch (values[value].filterOperator) {
                case FilterTypes.filterOperators.EQUAL:
                  let filterPlacesAfterTheDot = values[value].filterPlacesAfterTheDot
                  if (typeof filterPlacesAfterTheDot != "undefined") {
                    if (
                      (user[filterType] * factor).toFixed(filterPlacesAfterTheDot) ===
                      id.toFixed(filterPlacesAfterTheDot)
                    ) {
                      isPassedTheFilter = true
                    }
                  } else {
                    if (user[filterType] * factor === id) {
                      isPassedTheFilter = true
                    }
                  }
                  break
                case FilterTypes.filterOperators.LESS_THAN:
                  if (user[filterType] * factor <= id) {
                    isPassedTheFilter = true
                  }
                  break
                case FilterTypes.filterOperators.MORE_THAN:
                  if (user[filterType] * factor >= id) {
                    isPassedTheFilter = true
                  }
                  break
                case FilterTypes.filterOperators.BETWEEN:
                  if (user[filterType] * factor >= firstValue && user[filterType] * factor <= secondValue) {
                    isPassedTheFilter = true
                  }
                  break
              }
              if (isPassedTheFilter) {
                count++
                break
              }
            }
          }
        }

        // Users search will retrieve the result in case AT LEAST one of the filters exist
        if (count > 0) {
          return true
        }
        return false
      })
    }

    return sortedAndFilteredUsers
  }

  switch (action.type) {
    case AdminPageActionTypes.ADMIN_RESOURCES_LOADED:
      let roles = new Map()
      action.roles.forEach((entry) => {
        roles.set(entry.id, entry)
      })

      let modules = new Map()
      action.modules.forEach((entry) => {
        modules.set(entry.id, entry)
      })

      let sites = new Map()
      action.sites.forEach((entry) => {
        sites.set(entry.id, entry)
      })

      let networks = new Map()
      action.networks.forEach((network) => {
        networks.set(network.id, network)
      })
      let accountManagers = new Map()
      action.accountManagers.forEach((accountManager) => {
        accountManagers.set(accountManager.id, accountManager)
      })

      return Object.assign({}, state, {
        sites: sites,
        networks: networks,
        roles: roles,
        modules: modules,
        accountManagers: accountManagers,
        rolesPage: Object.assign({}, state.rolesPage, {
          visibleRoles: [...roles.values()],
        }),
        modulesPage: Object.assign({}, state.modulesPage, {
          visibleModules: [...modules.values()],
        }),
        sitesPage: Object.assign({}, state.sitesPage, {
          visibleSites: [...sites.values()],
        }),
        networksPage: Object.assign({}, state.networksPage, {
          visibleNetworks: [...networks.values()],
        }),
      })

    case AdminPageActionTypes.ADMIN_USERS_LOADING:
      return Object.assign({}, state, {
        users: Object.assign({}, state.users, {
          isLoading: true,
        }),
      })

    case AdminPageActionTypes.ADMIN_FETCH_USERS_RESPONSE:
      var nextState = Object.assign({}, state, {
        users: Object.assign({}, state.users, {
          userItems: action.userItems,
          isLoading: false,
        }),
      })
      nextState.users.visibleUserItems = retrieveSortedAndFilteredUsers(nextState)
      return nextState

    case AdminPageActionTypes.UPDATE_USERS_PAGE:
      return Object.assign({}, state, {
        users: Object.assign({}, state.users, {
          currentPage: action.currentPage,
        }),
      })

    case AdminPageActionTypes.RESET_USERS_PAGINATION:
      return Object.assign({}, state, {
        users: Object.assign({}, state.users, {
          currentPage: 1,
        }),
      })

    case AdminPageActionTypes.USER_POPUP_OPEN:
      return Object.assign({}, state, {
        popupUser: Object.assign({}, state.popupUser, {
          general: action.user,
          isPopupOpen: true,
        }),
      })

    case AdminPageActionTypes.USER_POPUP_CLOSE:
      return Object.assign({}, state, {
        popupUser: {
          isPopupOpen: false,
          general: null,
          roles: null,
        },
      })

    case AdminPageActionTypes.NETWORK_POPUP_OPEN:
      return Object.assign({}, state, {
        popupNetwork: Object.assign({}, state.popupNetwork, {
          network: action.network,
          isPopupOpen: true,
        }),
      })

    case AdminPageActionTypes.NETWORK_POPUP_CLOSE:
      return Object.assign({}, state, {
        popupNetwork: Object.assign({}, state.popupNetwork, {
          network: action.network,
          isPopupOpen: false,
        }),
      })

    case AdminPageActionTypes.ADD_NETWORK_RESPONSE:
    case AdminPageActionTypes.UPDATE_NETWORK_RESPONSE:
      let networkId = action.network.id
      let updatedNetworks = new Map(state.networks)
      let currentNetwork = updatedNetworks.get(networkId) ? updatedNetworks.get(networkId) : {}
      let updatedNetwork = merge(currentNetwork, action.network)
      updatedNetworks.set(updatedNetwork.id, updatedNetwork)

      return Object.assign({}, state, {
        networks: updatedNetworks,
        popupNetwork: Object.assign({}, state.popupNetwork, {
          network: updatedNetwork,
          isPopupOpen: false,
          isLoading: false,
        }),
        networksPage: Object.assign({}, state.networksPage, {
          visibleNetworks: retrieveSortedAndFiltered(
            [...updatedNetworks.values()],
            state.networksPage.filters,
            "id",
            "ASC"
          ),
        }),
      })

    case AdminPageActionTypes.ALTER_NETWORK_ERROR:
      return Object.assign({}, state, {
        popupNetwork: Object.assign({}, state.popupNetwork, {
          isLoading: false,
        }),
      })

    case AdminPageActionTypes.START_MAIN_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
      })

    case AdminPageActionTypes.STOP_MAIN_LOADING:
      return Object.assign({}, state, {
        isLoading: false,
      })

    case AdminPageActionTypes.ALTER_USER_RESPONSE:
      return Object.assign({}, state, {
        isLoading: false,
      })

    case AdminPageActionTypes.ALTER_USER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      })

    case AdminPageActionTypes.UPDATE_USER_DATA:
      var users = state.users.userItems.slice(0)
      let found = false

      for (var i = 0; i < users.length; i++) {
        if (users[i].id === action.user.id) {
          users[i] = action.user
          found = true
        }
      }

      if (!found) {
        users.push(action.user)
      }

      let popupData = action.isUpdateOpenUser
        ? Object.assign({}, state.popupUser, { general: action.user })
        : state.popupUser
      var nextState = Object.assign({}, state, {
        users: Object.assign({}, state.users, {
          userItems: users,
        }),
        popupUser: popupData,
      })
      nextState.users.visibleUserItems = retrieveSortedAndFilteredUsers(nextState)
      return nextState

    case AdminPageActionTypes.UPDATE_USER_ROLES:
      return Object.assign({}, state, {
        popupUser: Object.assign({}, state.popupUser, { roles: action.userRoles }),
      })

    case AdminPageActionTypes.ADD_USER_ROLE:
      roles = state.popupUser.roles.slice(0)

      action.userRoles.forEach((userRole) => {
        roles.push(userRole)
      })

      return Object.assign({}, state, {
        popupUser: Object.assign({}, state.popupUser, { roles: roles }),
      })

    case AdminPageActionTypes.UPDATE_USER_SITES:
      return Object.assign({}, state, {
        popupUser: Object.assign({}, state.popupUser, { sites: action.sites }),
      })

    case AdminPageActionTypes.ADD_USER_SITES:
      sites = state.popupUser.sites.slice(0)
      var newSites = action.sites

      return Object.assign({}, state, {
        popupUser: Object.assign({}, state.popupUser, { sites: sites.concat(newSites) }),
      })

    case AdminPageActionTypes.REMOVE_USER_SITE:
      sites = []

      for (var i = 0; i < state.popupUser.sites.length; i++) {
        if (state.popupUser.sites[i].id !== action.site_id) {
          sites.push(state.popupUser.sites[i])
        }
      }

      return Object.assign({}, state, {
        popupUser: Object.assign({}, state.popupUser, { sites }),
      })

    case AdminPageActionTypes.REMOVE_USER_ROLE:
      roles = []

      state.popupUser.roles.forEach((role) => {
        if (role.network_id !== action.networkId || role.role_id !== action.roleId) {
          roles.push(role)
        }
      })

      return Object.assign({}, state, {
        popupUser: Object.assign({}, state.popupUser, { roles }),
      })

    case AdminPageActionTypes.RESET_USER_SITES:
      return Object.assign({}, state, {
        popupUser: Object.assign({}, state.popupUser, { sites: null }),
      })

    case AdminPageActionTypes.ADMIN_NOTIFICATION_POPUP_OPEN:
      return Object.assign({}, state, {
        popupAdminNotification: {
          data: action.adminNotification,
          isPopupOpen: true,
        },
      })

    case AdminPageActionTypes.ADMIN_NOTIFICATION_POPUP_CLOSE:
      return Object.assign({}, state, {
        popupAdminNotification: {
          isPopupOpen: false,
          data: null,
        },
      })

    case AdminPageActionTypes.ADMIN_NOTIFICATIONS_LOADING:
      return Object.assign({}, state, {
        messages: Object.assign({}, state.messages, {
          isLoading: true,
        }),
      })

    case AdminPageActionTypes.FETCH_ADMIN_NOTIFICATIONS_RESPONSE:
      return Object.assign({}, state, {
        messages: Object.assign({}, state.messages, {
          adminNotificationItems: action.adminNotifications,
          isLoading: false,
        }),
      })

    case AdminPageActionTypes.CREATE_ADMIN_NOTIFICATION_RESPONSE:
      var adminNotifications = state.messages.adminNotificationItems.slice(0)
      adminNotifications.push(action.adminNotification)
      return Object.assign({}, state, {
        messages: Object.assign({}, state.messages, {
          adminNotificationItems: adminNotifications,
        }),
      })

    case AdminPageActionTypes.UPDATE_ADMIN_NOTIFICATION_RESPONSE:
      var adminNotifications = state.messages.adminNotificationItems.slice(0)
      for (var i = 0; i < adminNotifications.length; i++) {
        if (adminNotifications[i].id === action.adminNotification.id) {
          adminNotifications[i] = action.adminNotification
        }
      }
      return Object.assign({}, state, {
        messages: Object.assign({}, state.messages, { adminNotificationItems: adminNotifications }),
      })

    case AdminPageActionTypes.FILTER_USERS:
      var nextState = Object.assign({}, state, {
        users: Object.assign({}, state.users, {
          filters: action.filters,
        }),
      })
      nextState.users.visibleUserItems = retrieveSortedAndFilteredUsers(nextState)
      return nextState

    case AdminPageActionTypes.ROLE_POPUP_OPEN:
      return Object.assign({}, state, {
        popupRole: Object.assign({}, state.popupRole, {
          isPopupOpen: true,
          role: action.role,
        }),
      })

    case AdminPageActionTypes.ROLE_POPUP_CLOSE:
      return Object.assign({}, state, {
        popupRole: adminPageInitialState.popupRole,
      })

    case AdminPageActionTypes.ROLE_POPUP_LOADING:
      return Object.assign({}, state, {
        popupRole: Object.assign({}, state.popupRole, {
          isLoading: true,
        }),
      })

    case AdminPageActionTypes.GET_ROLE_MODULES_RESPONSE:
      return Object.assign({}, state, {
        popupRole: Object.assign({}, state.popupRole, {
          roleModules: new Set(action.roleModules),
          isLoading: false,
        }),
      })

    case AdminPageActionTypes.UPDATE_ROLE_MODULES:
      let roleModules = new Set(state.popupRole.roleModules)
      if (action.checked) {
        roleModules.add(action.moduleId)
      } else {
        roleModules.delete(action.moduleId)
      }

      return Object.assign({}, state, {
        popupRole: Object.assign({}, state.popupRole, {
          roleModules: roleModules,
        }),
      })

    case AdminPageActionTypes.ADD_ROLE_RESPONSE:
    case AdminPageActionTypes.UPDATE_ROLE_RESPONSE:
      let updatedRoles = new Map(state.roles)
      updatedRoles.set(action.role.id, action.role)

      return Object.assign({}, state, {
        roles: updatedRoles,
        popupRole: Object.assign({}, state.popupRole, {
          role: action.role,
          isLoading: false,
        }),
        rolesPage: Object.assign({}, state.rolesPage, {
          visibleRoles: retrieveSortedAndFiltered([...updatedRoles.values()], state.rolesPage.filters, "id", "ASC"),
        }),
      })

    case AdminPageActionTypes.MODULE_POPUP_OPEN:
      return Object.assign({}, state, {
        popupModule: Object.assign({}, state.popupModule, {
          isPopupOpen: true,
          module: action.module,
        }),
      })

    case AdminPageActionTypes.MODULE_POPUP_CLOSE:
      return Object.assign({}, state, {
        popupModule: adminPageInitialState.popupModule,
      })

    case AdminPageActionTypes.MODULE_POPUP_LOADING:
      return Object.assign({}, state, {
        popupModule: Object.assign({}, state.popupModule, {
          isLoading: true,
        }),
      })

    case AdminPageActionTypes.ADD_MODULE_RESPONSE:
    case AdminPageActionTypes.UPDATE_MODULE_RESPONSE:
      let updatedModules = new Map(state.modules)
      updatedModules.set(action.module.id, action.module)

      return Object.assign({}, state, {
        modules: updatedModules,
        popupModule: Object.assign({}, state.popupModule, {
          module: action.module,
          isLoading: false,
        }),
        modulesPage: Object.assign({}, state.modulesPage, {
          visibleModules: retrieveSortedAndFiltered(
            [...updatedModules.values()],
            state.modulesPage.filters,
            "id",
            "ASC"
          ),
        }),
      })

    case AdminPageActionTypes.GET_ROLES_USING_MODULE_RESPONSE:
      return Object.assign({}, state, {
        popupModule: Object.assign({}, state.popupModule, {
          rolesUsingModule: action.roles,
          isLoading: false,
        }),
      })

    case AdminPageActionTypes.ALTER_ROLE_ERROR:
      return Object.assign({}, state, {
        popupRole: Object.assign({}, state.popupRole, {
          isLoading: false,
        }),
      })

    case AdminPageActionTypes.ALTER_MODULE_ERROR:
      return Object.assign({}, state, {
        popupModule: Object.assign({}, state.popupModule, {
          isLoading: false,
        }),
      })

    case AdminPageActionTypes.SITE_POPUP_OPEN:
      return Object.assign({}, state, {
        popupSite: Object.assign({}, state.popupSite, {
          isPopupOpen: true,
          site: action.site,
        }),
      })

    case AdminPageActionTypes.SITE_POPUP_CLOSE:
      return Object.assign({}, state, {
        popupSite: adminPageInitialState.popupSite,
      })

    case AdminPageActionTypes.SITE_POPUP_LOADING:
      return Object.assign({}, state, {
        popupSite: Object.assign({}, state.popupSite, {
          isLoading: true,
        }),
      })

    case AdminPageActionTypes.ADD_SITE_RESPONSE:
    case AdminPageActionTypes.UPDATE_SITE_RESPONSE:
      let updatedSites = new Map(state.sites)
      let currentSite = updatedSites.get(action.siteId) ? updatedSites.get(action.siteId) : {}
      let updatedSite = merge(currentSite, action.site)
      updatedSite.network = state.networks.get(updatedSite.network_id)
      updatedSite.id = action.siteId
      updatedSites.set(action.siteId, updatedSite)

      return Object.assign({}, state, {
        sites: updatedSites,
        popupSite: Object.assign({}, state.popupSite, {
          site: updatedSite,
          isLoading: false,
        }),
        sitesPage: Object.assign({}, state.sitesPage, {
          visibleSites: [...updatedSites.values()],
        }),
      })

    case AdminPageActionTypes.ALTER_SITE_ERROR:
      return Object.assign({}, state, {
        popupSite: Object.assign({}, state.popupSite, {
          isLoading: false,
        }),
      })

    case AdminPageActionTypes.FILTER_ROLES:
      var nextState = Object.assign({}, state, {
        rolesPage: Object.assign({}, state.rolesPage, {
          filters: action.filters,
        }),
      })

      nextState.rolesPage.visibleRoles = retrieveSortedAndFiltered(
        [...state.roles.values()],
        nextState.rolesPage.filters,
        "id",
        "ASC"
      )

      return nextState

    case AdminPageActionTypes.FILTER_MODULES:
      var nextState = Object.assign({}, state, {
        modulesPage: Object.assign({}, state.modulesPage, {
          filters: action.filters,
        }),
      })

      nextState.modulesPage.visibleModules = retrieveSortedAndFiltered(
        [...state.modules.values()],
        nextState.modulesPage.filters,
        "id",
        "ASC"
      )

      return nextState

    case AdminPageActionTypes.FILTER_SITES:
      var nextState = Object.assign({}, state, {
        sitesPage: Object.assign({}, state.sitesPage, {
          filters: action.filters,
        }),
      })

      nextState.sitesPage.visibleSites = retrieveSortedAndFiltered(
        [...state.sites.values()],
        nextState.sitesPage.filters,
        "id",
        "ASC"
      )

      return nextState

    case AdminPageActionTypes.FILTER_NETWORKS:
      var nextState = Object.assign({}, state, {
        networksPage: Object.assign({}, state.networksPage, {
          filters: action.filters,
        }),
      })

      nextState.networksPage.visibleNetworks = retrieveSortedAndFiltered(
        [...state.networks.values()],
        nextState.networksPage.filters,
        "id",
        "ASC"
      )

      return nextState

    case AdminPageActionTypes.GET_SITE_SUGGESTED_USERS_RESPONSE:
      var users = new Map()

      if (action.users) {
        action.users.forEach((user) => {
          user.selected = true
          users.set(user.id, user)
        })
      }

      return Object.assign({}, state, {
        popupSite: Object.assign({}, state.popupSite, {
          suggestedUsers: users,
        }),
      })

    case AdminPageActionTypes.SET_USER_SELECTED:
      var users = new Map(state.popupSite.suggestedUsers)
      var user = users.get(action.userId)

      user.selected = action.selected
      users.set(user.id, user)

      return Object.assign({}, state, {
        popupSite: Object.assign({}, state.popupSite, {
          suggestedUsers: users,
        }),
      })

    case AdminPageActionTypes.SET_USERS_SELECTED:
      var users = new Map(state.popupSite.suggestedUsers)

      users.forEach((user) => {
        user.selected = action.selected
      })

      return Object.assign({}, state, {
        popupSite: Object.assign({}, state.popupSite, {
          suggestedUsers: users,
        }),
      })

    case AdminPageActionTypes.RESET_SUGGESTED_USERS:
      return Object.assign({}, state, {
        popupSite: Object.assign({}, state.popupSite, {
          suggestedUsers: null,
        }),
      })

    default:
      return state
  }
}

export { adminPageReducer }
