import AdvancedSearchActionTypes from "./advancedSearchActionTypes"
import CampaignActionTypes from "../campaignActionTypes"
import { deepCompare } from "../../utils/arrayUtils"
import {
  bulkCampaignActivationResponse,
  bulkCampaignsActivationLoading,
  bulkCampaignsBidLoading,
  bulkCampaignsDailyBudgetLoading,
  bulkCampaignStatusLoading,
  bulkCampaignsTotalBudgetLoading,
  bulkEditCampaignsBidResponse,
  bulkEditCampaignsBudgetResponse,
  bulkEditCampaignsTargetingResponse,
  bulkStartStopCampaignsResponse,
  bulkUpdateCampaignsTags,
  campaignBidLoading,
  campaignDailyBudgetLoading,
  campaignStatusLoading,
  campaignTotalBudgetLoading,
  changeBidResponse,
  changeCampaignDailyBudgetResponse,
  changeCampaignTotalBudgetResponse,
  changeRoasResponse,
  startCampaignErrorResponse,
  activeCampaignResponse,
  stopCampaignErrorResponse,
  pauseCampaignResponse,
  updateCampaignTags,
} from "../campaignReducerOperations"
import { overrideCampaignAttributes } from "../../utils/campaignUtilsV2"
import CampaignPopupActionTypes from "../../campaignPopupV2/campaignPopupActionTypes"

const campaignsInitialState = {
  isDrawerOpen: false,
  isAdvancedSearch: false,
  filters: new Map(),
  tempFilters: new Map(),
  creators: [],
  campaigns: [],
  isLoading: true,
  allCampaignsSelected: false,
  campaignsSelectionOrder: [],
  currentPage: 1,
  campaignTags: [],
}

function advancedSearchReducerV2(state = campaignsInitialState, action) {
  if (state == undefined || action == undefined) {
    return []
  }

  let updateCampaignProps = (campaignObj, updatedProps) => {
    return { ...campaignObj, ...updatedProps }
  }

  let updateCampaign = (campaignKey, updatedProperties) => {
    return state.campaigns.map((campaignObj) => {
      if (campaignObj.key === campaignKey) {
        return updateCampaignProps(campaignObj, updatedProperties)
      }

      return campaignObj
    })
  }

  let updateCampaigns = (campaignKeyToUpdatedProperties) => {
    let campaignKeys = Array.from(campaignKeyToUpdatedProperties.keys())

    return state.campaigns.map((campaignObj) => {
      if (campaignKeys.includes(campaignObj.key)) {
        let updatedProps = campaignKeyToUpdatedProperties.get(campaignObj.key)

        return updateCampaignProps(campaignObj, updatedProps)
      }

      return campaignObj
    })
  }

  let filtersDeepCopy = (filters) => {
    if (!filters) {
      return null
    }

    let filterMap = new Map()

    filters.forEach((value, key) => {
      filterMap.set(
        key,
        Object.assign({}, value, {
          includeFilters: value.includeFilters.map((item) => {
            return Object.assign({}, item)
          }),
          excludeFilters: value.excludeFilters.map((item) => {
            return Object.assign({}, item)
          }),
        })
      )
    })

    return filterMap
  }

  switch (action.type) {
    case AdvancedSearchActionTypes.IS_DRAWER_OPEN:
      return Object.assign({}, state, {
        isDrawerOpen: action.isDrawerOpen,
      })

    case AdvancedSearchActionTypes.SET_TEMP_FILTERS:
      var newFilter = filtersDeepCopy(action.filters)

      return Object.assign({}, state, {
        tempFilters: newFilter,
      })

    case AdvancedSearchActionTypes.ADVANCED_SEARCH_RESET_PAGE:
      var newFilter = filtersDeepCopy(action.filters)

      return Object.assign({}, state, {
        isAdvancedSearch: true,
        filters: newFilter,
        isDrawerOpen: false,
        currentPage: action.currentPage,
        allCampaignsSelected: false,
        campaignsSelectionOrder: [],
        isLoading: true,
      })

    case AdvancedSearchActionTypes.SET_ADVANCED_SEARCH_VIEW:
      return Object.assign({}, state, {
        isAdvancedSearch: action.isAdvancedSearch,
      })

    case AdvancedSearchActionTypes.SET_ADVANCED_SEARCH_CREATORS:
      return Object.assign({}, state, {
        creators: action.creators,
      })

    case AdvancedSearchActionTypes.GET_ADVANCED_SEARCH_REPORT_RESPONSE:
      var tags = new Set()
      let siteIdToSiteObject = action.siteIdToSiteObject
      let campaigns = action.report
        ? Object.keys(action.report).map((key) => {
            // For each campaign, we add client entities (key and isLoading flag)
            var campaign = Object.assign({}, action.report[key])
            campaign.key = key
            campaign.isCampaignStatusLoading = false
            campaign.isCampaignBidLoading = false
            campaign.rpmData = []
            campaign.isCampaignRpmLoading = false
            campaign.newRoi = null
            campaign.selected = false
            campaign.external_id = key.split(":")[1]
            overrideCampaignAttributes(campaign)

            if (campaign.tags.length > 0) {
              campaign.tags.forEach((tag) => {
                tags.add(tag.name)
              })
            }
            return campaign
          })
        : null

      if (action.filters) {
        action.filters.forEach((filter) => {
          if (!filter.isExcluded) {
            let stateFilter = state.filters.get(filter.filterType)

            if (stateFilter) {
              stateFilter.includeFilters.forEach((includeFilter) => {
                if (deepCompare(includeFilter.filterValue, filter.filterValue)) {
                  includeFilter.count = filter.count
                }
              })
            }
          }
        })
      }

      return Object.assign({}, state, {
        campaigns,
        totalSize: action.totalSize,
        isLoading: false,
        campaignTags: Array.from(tags).map((tag) => {
          return { name: tag }
        }),
      })

    case AdvancedSearchActionTypes.ADVANCED_SEARCH_CAMPAIGN_MARK_SELECTED:
      campaigns = state.campaigns.slice()
      var campaignsSelectionOrder = state.campaignsSelectionOrder.slice()
      var selectedCampaignIndex = campaigns.findIndex((campaign) => campaign.key === action.campaign?.key)

      if (action.event?.nativeEvent?.shiftKey) {
        // Shift key is selected, we're selecting all the campaigns from the last selected campaign up to the current one
        // (works just like in Mac's finder or Windows's file explorer).
        let lastSelectedCampaignIndex =
          state.campaignsSelectionOrder.length > 0
            ? state.campaignsSelectionOrder[state.campaignsSelectionOrder.length - 1]
            : 0

        let minCampaignIndex = Math.min(lastSelectedCampaignIndex, selectedCampaignIndex)
        let maxCampaignIndex = Math.max(lastSelectedCampaignIndex, selectedCampaignIndex)

        for (let i = minCampaignIndex; i <= maxCampaignIndex; i++) {
          campaigns[i] = { ...campaigns[i], selected: true }
        }

        campaignsSelectionOrder = campaignsSelectionOrder.filter((index) => index !== selectedCampaignIndex)
        campaignsSelectionOrder.push(selectedCampaignIndex)
      } else {
        // No shift key, we're selecting / deselecting one campaign and updating the selection order
        if (action.campaign?.key) {
          campaigns = updateCampaign(action.campaign.key, { selected: action.selected })
        }
        if (action.selected) {
          campaignsSelectionOrder.push(selectedCampaignIndex)
        } else {
          campaignsSelectionOrder = campaignsSelectionOrder.filter((index) => index !== selectedCampaignIndex)
        }
      }

      // Checking if the allCampaignsSelected checkbox should be checked
      var allCampaignsSelected = !campaigns.some((campaign) => campaign.selected === false)

      return Object.assign({}, state, {
        campaigns,
        allCampaignsSelected,
        campaignsSelectionOrder,
      })

    case AdvancedSearchActionTypes.ADVANCED_SEARCH_CAMPAIGN_MARK_ALL_SELECTED:
      campaigns = state.campaigns
        .slice()
        .map((campaign) => Object.assign({}, campaign, { selected: !state.allCampaignsSelected }))
      var campaignsSelectionOrder = state.campaignsSelectionOrder.slice()

      if (state.allCampaignsSelected) {
        // Next state of allCampaignsSelected will be false, so we clear the selection order
        campaignsSelectionOrder = []
      }

      return Object.assign({}, state, {
        campaigns,
        allCampaignsSelected: !state.allCampaignsSelected,
        campaignsSelectionOrder,
      })

    case CampaignActionTypes.PAUSE_CAMPAIGN_RESPONSE:
      var updatedCampaignsLists = pauseCampaignResponse(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.ACTIVE_CAMPAIGN_RESPONSE:
      var updatedCampaignsLists = activeCampaignResponse(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.STOP_CAMPAIGN_ERROR_RESPONSE:
      var updatedCampaignsLists = stopCampaignErrorResponse(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.START_CAMPAIGN_ERROR_RESPONSE:
      var updatedCampaignsLists = startCampaignErrorResponse(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_START_CAMPAIGNS_RESPONSE:
    case CampaignActionTypes.BULK_STOP_CAMPAIGNS_RESPONSE:
    case CampaignActionTypes.BULK_START_CAMPAIGNS_ERROR_RESPONSE:
    case CampaignActionTypes.BULK_STOP_CAMPAIGNS_ERROR_RESPONSE:
      var updatedCampaignsLists = bulkStartStopCampaignsResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_UPDATE_CAMPAIGN_TAGS:
      var result = bulkUpdateCampaignsTags(action, updateCampaigns, state.campaignTags)

      return Object.assign({}, state, {
        campaigns: result.updatedCampaigns,
        campaignTags: Array.from(result.campaignTags).map((tag) => {
          return { name: tag }
        }),
      })

    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BUDGET_RESPONSE:
    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BUDGET_ERROR_RESPONSE:
      var updatedCampaignsLists = bulkEditCampaignsBudgetResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BID_RESPONSE:
    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BID_ERROR_RESPONSE:
      var updatedCampaignsLists = bulkEditCampaignsBidResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_TARGETING_RESPONSE:
      var updatedCampaignsLists = bulkEditCampaignsTargetingResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignPopupActionTypes.UPDATE_CAMPAIGN_TAGS:
      var result = updateCampaignTags(action, updateCampaign, state.campaignTags)

      return Object.assign({}, state, {
        campaigns: result.updatedCampaigns,
        campaignTags: Array.from(result.campaignTags).map((tag) => {
          return { name: tag }
        }),
      })

    case CampaignActionTypes.CHANGE_BID_RESPONSE:
    case CampaignActionTypes.CHANGE_BID_ERROR_RESPONSE:
      var updatedCampaignsLists = changeBidResponse(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.CHANGE_ROAS_RESPONSE:
    case CampaignActionTypes.CHANGE_ROAS_ERROR_RESPONSE:
      var updatedCampaignsLists = changeRoasResponse(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.CAMPAIGN_STATUS_LOADING:
      var updatedCampaignsLists = campaignStatusLoading(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_CAMPAIGN_STATUS_LOADING:
      var updatedCampaignsLists = bulkCampaignStatusLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_ACTIVATE_CAMPAIGNS_LOADING:
      var updatedCampaignsLists = bulkCampaignsActivationLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_SMART_ACTIVATION_CAMPAIGNS_RESPONSE:
    case CampaignActionTypes.BULK_ACTIVATE_CAMPAIGNS_ERROR_RESPONSE:
      var updatedCampaignsLists = bulkCampaignActivationResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.CAMPAIGN_BID_LOADING:
      var updatedCampaignsLists = campaignBidLoading(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.CAMPAIGN_DAILY_BUDGET_LOADING:
      var updatedCampaignsLists = campaignDailyBudgetLoading(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.CAMPAIGN_TOTAL_BUDGET_LOADING:
      var updatedCampaignsLists = campaignTotalBudgetLoading(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_CAMPAIGNS_DAILY_BUDGET_LOADING:
      var updatedCampaignsLists = bulkCampaignsDailyBudgetLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_CAMPAIGNS_BID_LOADING:
      var updatedCampaignsLists = bulkCampaignsBidLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.BULK_CAMPAIGNS_TOTAL_BUDGET_LOADING:
      var updatedCampaignsLists = bulkCampaignsTotalBudgetLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.CHANGE_DAILY_BUDGET_RESPONSE:
    case CampaignActionTypes.CHANGE_DAILY_BUDGET_ERROR_RESPONSE:
      var updatedCampaignsLists = changeCampaignDailyBudgetResponse(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    case CampaignActionTypes.CHANGE_TOTAL_BUDGET_RESPONSE:
    case CampaignActionTypes.CHANGE_TOTAL_BUDGET_ERROR_RESPONSE:
      var updatedCampaignsLists = changeCampaignTotalBudgetResponse(action, updateCampaign)

      return Object.assign({}, state, {
        campaigns: updatedCampaignsLists,
      })

    default:
      return state
  }
}

export { advancedSearchReducerV2 }
