import React from "react"
import { ListFilter } from "../components/filterV2/menus/listFilter"
import { store } from "../../app/store"
import Consts from "../../app/consts"
import DesktopIcon from "../../resources/svgs/DesktopIcon.svg"
import TabletIcon from "../../resources/svgs/TabletIcon.svg"
import MobileIcon from "../../resources/svgs/MobileIcon.svg"
import AppleIcon from "../../resources/svgs/AppleIcon.svg"
import AndroidIcon from "../../resources/svgs/AndroidIcon.svg"
import MultipleDevicesIcon from "../../resources/svgs/MultipleDevicesIcon.svg"
import CampaignsConsts from "../../campaignsV2/campaignsConsts"
import { ValueFilter } from "../components/filterV2/menus/valueFilter"
import NetworkTypes from "./networkTypes"
import { SavedFilter } from "../components/filterV2/menus/savedFilter"
import { isNullOrUndefined } from "../../utils/funcUtils"
import { getBrowsersFilterValue, getOperatingSystemsFilterValue, getPlatformValue } from "../../utils/campaignUtilsV2"
import { alpha3ToAlpha2 } from "i18n-iso-countries"
import { getLangNameFromCode } from "language-name-map"
import AdTypes from "../../common/consts/adTypes"
import Browsers from "./browsers"
import OsTypes from "./osTypes"
import { draftReviewStatus } from "../../drafts/components/draftReviewStatus"
import { draftCreationStatus } from "../../drafts/components/draftCreationStatus"
import articlesTypeConsts from "../../articles/components/articlesType"
import { getArticleTypeFilterValue } from "../../utils/articleUtils"
import PlatformTypes from "./platformTypes"
import BidTypes from "./bidTypes"

class FilterTypes {}

FilterTypes.filterOperators = {
  EQUAL: 0,
  LESS_THAN: 1,
  MORE_THAN: 2,
  BETWEEN: 3,
  STRING_EQUAL: 4,
  NOT_EQUAL: 5,
  IN: 6,
  NOT_IN: 7,
  INCLUDE: 8,
  NOT_BETWEEN: 9,
  IN_ARRAY: 10,
  NOT_EXIST: 11,
}

FilterTypes.SOURCE = "Source"
FilterTypes.COUNTRY = "Country"
FilterTypes.DEVICE = "Device"
FilterTypes.NETWORK = "Network"
FilterTypes.SITE = "Site"
FilterTypes.TARGETING_LANGUAGE = "Targeting Language"
FilterTypes.ARTICLE_LANGUAGE = "Article Language"
FilterTypes.LOSING_IN_A_ROW = "Days Losing in a Row"
FilterTypes.CREATED_BY = "Created by"
FilterTypes.TAGS = "Tags"
FilterTypes.CREATE_DATE = "Creation Date"
FilterTypes.LAST_MODIFIED = "Last Modified"
FilterTypes.LAST_MODIFIED_ACTION = "Last Modified Action"
FilterTypes.FIRST_DAY = "First Day Spending"
FilterTypes.VISITS = "Visits"
FilterTypes.AVERAGE_CPC = "Avg. CPC"
FilterTypes.REAL_TIME_CPC = "Current Bid"
FilterTypes.VISIT_VALUE = "Visit Value"
FilterTypes.ROI = "ROI"
FilterTypes.ATTRIBUTED_ROI = "ROI (Attributed)"
FilterTypes.TARGET_ROI = "Target ROI"
FilterTypes.ESTIMATED_PROFIT = "Estimated Profit"
FilterTypes.RPM = "RPM"
FilterTypes.CTR = "Click Through Rate"
FilterTypes.PPS = "Pages per Visit"
FilterTypes.BOUNCE_RATE = "Bounce Rate"
FilterTypes.TOTAL_BUDGET = "Total Budget"
FilterTypes.DAILY_BUDGET = "Daily Budget"
FilterTypes.CPC_TYPE = "Bid Type"
FilterTypes.NETWORK_TYPE = "Customer Type"
FilterTypes.CPM = "CPM"
FilterTypes.MARGIN = "Margin"
FilterTypes.VISIT_PROFIT = "Visit Profit"
FilterTypes.VISITS_COST = "Visit Cost"
FilterTypes.CLICK_VALUE = "Click Value"
FilterTypes.AD_TYPE = "Ad Type"
FilterTypes.CONVERSION_GOAL = "Conversion Goal"
FilterTypes.CONVERSION_GOAL_VALUE = "Conversion Goal Value"
FilterTypes.RPM_PAGE_VIEWS = "RPM Page Views"
FilterTypes.ACCOUNT_ID = "Account ID"
FilterTypes.RANGE_FILTER_OPERATORS = [FilterTypes.filterOperators.BETWEEN, FilterTypes.filterOperators.NOT_BETWEEN]

FilterTypes.filterValueTypes = {
  NUMBER: 0,
  DATE: 1,
  STRING: 2,
  ARRAY: 3,
  ID: 4,
}

FilterTypes.filterOperatorsNamesByType = {
  [FilterTypes.filterValueTypes.NUMBER]: {
    [FilterTypes.filterOperators.EQUAL]: "Equal",
    [FilterTypes.filterOperators.MORE_THAN]: "Greater than",
    [FilterTypes.filterOperators.LESS_THAN]: "Less than",
    [FilterTypes.filterOperators.BETWEEN]: "Between",
    [FilterTypes.filterOperators.NOT_BETWEEN]: "Not Between",
  },
  [FilterTypes.filterValueTypes.DATE]: {
    [FilterTypes.filterOperators.EQUAL]: "Exact date",
    [FilterTypes.filterOperators.MORE_THAN]: "After",
    [FilterTypes.filterOperators.LESS_THAN]: "Before",
    [FilterTypes.filterOperators.BETWEEN]: "Between",
    [FilterTypes.filterOperators.NOT_BETWEEN]: "Not Between",
    [FilterTypes.filterOperators.NOT_EXIST]: "Is None",
  },
  [FilterTypes.filterValueTypes.ID]: {
    [FilterTypes.filterOperators.EQUAL]: "Equal",
    [FilterTypes.filterOperators.NOT_EQUAL]: "Not Equal",
  },
}

FilterTypes.filterSections = {
  CAMPAIGNS: 0,
}

export default FilterTypes

let CampaignFilters = {
  CONVERSION_GOAL_VALUE: {
    filterName: "Conversion Goal Value",
    filterType: "CONVERSION_GOAL_VALUE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign?.conversion_value,
    fieldName: "conversion_value",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CONVERSION_GOAL: {
    filterName: "Conversion Goal",
    filterType: "CONVERSION_GOAL",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => campaign?.conversion_name,
    fieldName: "conversion_name",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let conversions = []
      let reduxStore = store.getState()
      let conversion_obj = reduxStore.app.conversionEvents

      if (!isNullOrUndefined(conversion_obj)) {
        let uniqueNames = new Set()

        for (let provider_id in conversion_obj) {
          if (conversion_obj.hasOwnProperty(provider_id)) {
            for (let conversion_name in conversion_obj[provider_id]) {
              if (conversion_obj[provider_id].hasOwnProperty(conversion_name)) {
                let conversion_value = conversion_obj[provider_id][conversion_name]["conversion_value"]

                // Check if the name is not in the set, add it to the result list and set
                if (!uniqueNames.has(conversion_name)) {
                  conversions.push({
                    name: conversion_name,
                    value: conversion_name,
                    conversion_value: conversion_value,
                  })
                  uniqueNames.add(conversion_name)
                }
              }
            }
          }
        }

        // Sort the result list by conversion_value
        conversions.sort((a, b) => a.conversion_value - b.conversion_value)
      }
      return conversions
    },
    showFilter: () => {
      let reduxStore = store.getState()

      return !isNullOrUndefined(reduxStore.app.conversionEvents)
    },
    editable: false,
  },
  AD_TYPE: {
    filterName: "Ad Type",
    filterType: "AD_TYPE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign?.ad_type,
    fieldName: "ad_type",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        { name: AdTypes.regular_ad.name, value: AdTypes.regular_ad.id },
        { name: AdTypes.dynamic_ad.name, value: AdTypes.dynamic_ad.id },
        { name: AdTypes.discovery_ad.name, value: AdTypes.discovery_ad.id },
        { name: AdTypes.display_ad.name, value: AdTypes.display_ad.id },
        { name: AdTypes.pmax.name, value: AdTypes.pmax.id },
      ]
    },
    editable: false,
  },

  CAMPAIGN_COUNTRY_GROUP: {
    filterName: "Country Group",
    filterType: "CAMPAIGN_COUNTRY_GROUP",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.country_group_id,
    fieldName: "country_group_id",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let countryGroupsList = []
      let reduxStore = store.getState()
      if (reduxStore.app.countryGroups) {
        Object.values(reduxStore.app.countryGroups).forEach((countryGroups) => {
          Object.values(countryGroups).forEach((countryGroup) => {
            countryGroupsList.push({ name: countryGroup.name, value: countryGroup.id })
          })
        })
      }

      return countryGroupsList
    },
    showFilter: () => {
      let reduxStore = store.getState()
      return !isNullOrUndefined(reduxStore.app.countryGroups)
    },
    editable: false,
  },

  CAMPAIGN_SOURCE: {
    filterName: "Source",
    filterType: "CAMPAIGN_SOURCE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.provider_id,
    fieldName: "provider_id",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let providers = []
      let reduxStore = store.getState()
      Object.values(reduxStore.app.providersList).forEach((provider) => {
        providers.push({
          name: provider.name,
          value: provider.id,
          display_order: provider.display_order,
          textColor: provider.color,
        })
      })

      providers.push({ name: "Untracked Source", value: Consts.UNKNOWN_PROVIDER_ID })

      return providers.sort((providerA, providerB) => {
        return providerA.display_order - providerB.display_order
      })
    },
    editable: false,
  },

  CAMPAIGN_COUNTRY: {
    filterName: "Country",
    filterType: "CAMPAIGN_COUNTRY",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.ARRAY,
    valueResolver: (campaign) => {
      if (campaign?.countries && campaign.countries.length === 1) {
        return campaign.countries
      } else {
        return ["worldwide"]
      }
    },
    fieldName: "countries",
    defaultOperator: FilterTypes.filterOperators.INCLUDE,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [{ name: "World Wide", value: "worldwide" }].concat(
        Array.from(store.getState().app.countries)
          .sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
          .map((country) => {
            return { name: country.name, value: alpha3ToAlpha2(country.code) }
          })
      )
    },
    editable: false,
  },

  CAMPAIGN_DEVICE: {
    filterName: "Device",
    filterType: "CAMPAIGN_DEVICE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => {
      return getPlatformValue(campaign.device_platforms)
    },
    fieldName: "device_platforms",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        { ...PlatformTypes.DESKTOP, icon: <DesktopIcon className="platform-icon" /> },
        { ...PlatformTypes.MOBILE, icon: <MobileIcon className="platform-icon" /> },
        { ...PlatformTypes.TABLET, icon: <TabletIcon className="platform-icon tablet-icon" /> },
        { ...PlatformTypes.ANDROID_MOBILE, icon: <AndroidIcon className="platform-icon" /> },
        { ...PlatformTypes.ANDROID_TABLET, icon: <AndroidIcon className="platform-icon" /> },
        { ...PlatformTypes.IPHONE, icon: <AppleIcon className="platform-icon" /> },
        { ...PlatformTypes.IPAD, icon: <AppleIcon className="platform-icon" /> },
        {
          ...PlatformTypes.MULTIPLE_DEVICES,
          icon: <MultipleDevicesIcon className="platform-icon tablet-icon" />,
        },
      ]
    },
    editable: false,
  },

  CAMPAIGN_NETWORK: {
    filterName: "Network",
    filterType: "CAMPAIGN_NETWORK",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => campaign.network_code,
    fieldName: "network_code",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let networks = []
      let reduxStore = store.getState()

      if (reduxStore.navigationBar.currentNetwork) {
        if (reduxStore.navigationBar.currentNetwork.id === NetworkTypes.ALL_NETWORKS.id) {
          reduxStore.app.networkToSites.forEach((network) => {
            networks.push({ name: network.data[0].network.name, value: network.data[0].network.code })
          })
        } else if (NetworkTypes.NETWORK_GROUP_TYPE_IDS.includes(reduxStore.navigationBar.currentNetwork.id)) {
          let networksWithNetworkGroupType = new Map()

          reduxStore.app.sites.forEach((site) => {
            if (site.network.type === reduxStore.navigationBar.currentNetwork.id) {
              networksWithNetworkGroupType.set(site.network.id, site.network)
            }
          })

          networksWithNetworkGroupType.forEach((network) => {
            networks.push({
              name: network.name,
              value: network.code,
            })
          })
        }
      }

      return networks
    },
    showFilter: () => {
      let reduxStore = store.getState()

      return (
        reduxStore.navigationBar.currentNetwork &&
        NetworkTypes.SPECIAL_NETWORK_IDS.includes(reduxStore.navigationBar.currentNetwork.id)
      )
    },
    editable: false,
  },

  CAMPAIGN_SITE: {
    filterName: "Site",
    filterType: "CAMPAIGN_SITE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => campaign.site_code,
    fieldName: "site_code",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let sites = []
      let reduxStore = store.getState()

      if (reduxStore.navigationBar.currentNetwork.id === NetworkTypes.ALL_NETWORKS.id) {
        sites = reduxStore.app.sites
      } else if (NetworkTypes.NETWORK_GROUP_TYPE_IDS.includes(reduxStore.navigationBar.currentNetwork.id)) {
        sites = reduxStore.app.sites.filter((site) => site.network.type === reduxStore.navigationBar.currentNetwork.id)
      } else {
        sites = reduxStore.app.networkToSites.filter((networkToSites) => {
          return parseInt(networkToSites.key) === reduxStore.navigationBar.currentNetwork.id
        })
        sites = sites[0].data
      }

      return sites.map((site) => {
        return { name: site.name, value: site.code }
      })
    },
    showFilter: () => {
      return !store.getState().navigationBar.currentSite
    },
    editable: false,
  },

  CAMPAIGN_ARTICLE_LANGUAGE: {
    filterName: "Article Language",
    filterType: "CAMPAIGN_ARTICLE_LANGUAGE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => {
      if (campaign.lang) {
        return getLangNameFromCode(campaign.lang)?.name
      } else {
        return null
      }
    },
    fieldName: "article_language",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let languages = []
      let reduxStore = store.getState()

      if (
        reduxStore.campaignsV2.campaignArticleLanguages &&
        reduxStore.campaignsV2.campaignArticleLanguages.length > 1
      ) {
        languages.push({ name: "No language", value: undefined })
        Array.from(reduxStore.campaignsV2.campaignArticleLanguages)
          .sort((a, b) => {
            return a.localeCompare(b)
          })
          .forEach((language) => {
            languages.push({ name: language, value: language })
          })
      }

      return languages
    },
    showFilter: () => {
      let reduxStore = store.getState()

      return reduxStore.campaignsV2.campaignLanguages && reduxStore.campaignsV2.campaignLanguages.length > 1
    },
    editable: false,
  },

  CAMPAIGN_TARGETING_LANGUAGE: {
    filterName: "Targeting Language",
    filterType: "CAMPAIGN_TARGETING_LANGUAGE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => {
      if (campaign?.targeting?.locales?.length > 0) {
        return getLangNameFromCode(campaign.targeting.locales[0])?.name
      } else {
        return null
      }
    },
    fieldName: "language",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let languages = []
      let reduxStore = store.getState()

      if (reduxStore.campaignsV2.campaignLanguages && reduxStore.campaignsV2.campaignLanguages.length > 1) {
        languages.push({ name: "No language", value: undefined })
        Array.from(reduxStore.campaignsV2.campaignLanguages)
          .sort((a, b) => {
            return a.localeCompare(b)
          })
          .forEach((language) => {
            languages.push({ name: language, value: language })
          })
      }

      return languages
    },
    showFilter: () => {
      let reduxStore = store.getState()

      return reduxStore.campaignsV2.campaignLanguages && reduxStore.campaignsV2.campaignLanguages.length > 1
    },
    editable: false,
  },

  CAMPAIGN_CREATED_BY: {
    filterName: "Created by",
    filterType: "CAMPAIGN_CREATED_BY",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.created_by,
    fieldName: "created_by",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let creators = []
      let reduxStore = store.getState()

      if (reduxStore.campaignsV2.campaignCreators) {
        creators.push({ name: "No creator", value: undefined })
        new Map(
          [...reduxStore.campaignsV2.campaignCreators.entries()].sort((a, b) => {
            return a[1].localeCompare(b[1])
          })
        ).forEach((creatorName, creatorId) => {
          creators.push({ name: creatorName, value: creatorId })
        })
      }

      return creators
    },
    showFilter: () => {
      let reduxStore = store.getState()

      return reduxStore.campaignsV2.campaignCreators && reduxStore.campaignsV2.campaignCreators.size > 0
    },
    editable: false,
  },
  CAMPAIGN_ADVANCED_SEARCH_CREATED_BY: {
    filterName: "Created by",
    filterType: "CAMPAIGN_ADVANCED_SEARCH_CREATED_BY",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => {
      return campaign.user_id
    },
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let creators = []
      let reduxStore = store.getState()

      if (reduxStore.advancedSearchV2.creators) {
        creators.push({ name: "No creator", value: undefined })
        reduxStore.advancedSearchV2.creators.forEach((creator) => {
          creators.push({ name: creator.name, value: creator.id })
        })
      }

      return creators
    },
    showFilter: () => {
      let reduxStore = store.getState()

      return reduxStore.advancedSearchV2.creators && reduxStore.advancedSearchV2.creators.length > 0
    },
    editable: false,
  },

  CAMPAIGN_TAGS: {
    filterName: "Tags",
    filterType: "CAMPAIGN_TAGS",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.ARRAY,
    valueResolver: (campaign) => {
      if (!isNullOrUndefined(campaign.tags) && campaign.tags.length > 0) {
        return campaign.tags.map((tag) => {
          return tag.name
        })
      }
      return [undefined]
    },
    fieldName: "tags",
    defaultOperator: FilterTypes.filterOperators.INCLUDE,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let tags = []
      let reduxStore = store.getState()

      if (reduxStore.campaignsV2.campaignTags) {
        tags.push({ name: "No tags", value: undefined })
        ;[...reduxStore.campaignsV2.campaignTags]
          .sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
          .forEach((tag) => {
            tags.push({ name: tag.name, value: tag.name })
          })
      }

      return tags
    },
    showFilter: () => {
      let reduxStore = store.getState()

      return reduxStore.campaignsV2.campaignTags && reduxStore.campaignsV2.campaignTags.length > 0
    },
    editable: false,
  },

  CAMPAIGN_CREATE_DATE: {
    filterName: "Creation Date",
    filterType: "CAMPAIGN_CREATE_DATE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.DATE,
    valueResolver: (campaign) => campaign.provider_created_at,
    fieldName: "provider_created_at",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_LAST_MODIFIED: {
    filterName: "Last Modified",
    filterType: "CAMPAIGN_LAST_MODIFIED",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.DATE,
    valueResolver: (campaign) => campaign.last_modified,
    fieldName: "last_modified",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_VISITS: {
    filterName: "Visits",
    filterType: "CAMPAIGN_VISITS",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.visits,
    fieldName: "visits",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_CLICKS: {
    filterName: "Clicks",
    filterType: "CAMPAIGN_CLICKS",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.clicks,
    fieldName: "clicks",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_RESULTS: {
    filterName: "Results",
    filterType: "CAMPAIGN_RESULTS",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.results,
    fieldName: "results",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_RESULTS_RATE: {
    filterName: "Results Rate",
    filterType: "CAMPAIGN_RESULTS_RATE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.results_rate * 100,
    fieldName: "results_rate",
    filterPlacesAfterTheDot: 2,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_AVERAGE_CPC: {
    filterName: "Avg. CPC",
    filterType: "CAMPAIGN_AVERAGE_CPC",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.cost_per_click,
    fieldName: "cost_per_click",
    filterPlacesAfterTheDot: 3,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_BID: {
    filterName: "Current Bid",
    filterType: "CAMPAIGN_BID",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.bid,
    fieldName: "bid",
    filterPlacesAfterTheDot: 3,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_VISIT_VALUE: {
    filterName: "Visit Value",
    filterType: "CAMPAIGN_VISIT_VALUE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.visit_value,
    fieldName: "visit_value",
    filterPlacesAfterTheDot: 3,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_VISIT_PROFIT: {
    filterName: "Visit Profit",
    filterType: "CAMPAIGN_VISIT_PROFIT",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.visit_profit,
    fieldName: "visit_profit",
    filterPlacesAfterTheDot: 3,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_ROI: {
    filterName: "ROI",
    filterType: "CAMPAIGN_ROI",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => (campaign.roi ? Math.round(campaign.roi * 100) : campaign.roi),
    fieldName: "roi",
    filterPlacesAfterTheDot: 0,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_PROFIT: {
    filterName: "Profit",
    filterType: "CAMPAIGN_PROFIT",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => (campaign.profit ? Math.round(campaign.profit) : campaign.profit),
    fieldName: "profit",
    filterPlacesAfterTheDot: 0,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_RPM: {
    filterName: "RPM",
    filterType: "CAMPAIGN_RPM",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.rpm,
    fieldName: "rpm",
    filterPlacesAfterTheDot: 2,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },
  CAMPAIGN_RPM_PAGE_VIEWS: {
    filterName: "RPM (Page Views)",
    filterType: "CAMPAIGN_RPM_PAGE_VIEWS",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.rpm_page_views,
    fieldName: "rpm_page_views",
    filterPlacesAfterTheDot: 2,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_CTR: {
    filterName: "CTR",
    filterType: "CAMPAIGN_CTR",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.ctr * 100,
    fieldName: "ctr",
    filterPlacesAfterTheDot: 2,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_PPS: {
    filterName: "Pages per Visit",
    filterType: "CAMPAIGN_PPS",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.pages_per_visit,
    fieldName: "pages_per_visit",
    filterPlacesAfterTheDot: 2,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_BOUNCE_RATE: {
    filterName: "Bounce Rate",
    filterType: "CAMPAIGN_BOUNCE_RATE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.bounce_rate * 100,
    fieldName: "bounce_rate",
    filterPlacesAfterTheDot: 1,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_TOTAL_BUDGET: {
    filterName: "Total Budget",
    filterType: "CAMPAIGN_TOTAL_BUDGET",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.total_budget,
    fieldName: "total_budget",
    filterPlacesAfterTheDot: 0,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_DAILY_BUDGET: {
    filterName: "Daily Budget",
    filterType: "CAMPAIGN_DAILY_BUDGET",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.daily_budget,
    fieldName: "daily_budget",
    filterPlacesAfterTheDot: 0,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_SPEND: {
    filterName: "Spend",
    filterType: "CAMPAIGN_SPEND",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign["daily_spent"],
    fieldName: "daily_spent",
    filterPlacesAfterTheDot: 2,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_CLICK_VALUE: {
    filterName: "Click Value",
    filterType: "CAMPAIGN_CLICK_VALUE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.click_value,
    fieldName: "click_value",
    filterPlacesAfterTheDot: 3,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_VISIT_COST: {
    filterName: "Visit Cost",
    filterType: "CAMPAIGN_VISIT_COST",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.visit_cost,
    fieldName: "visit_cost",
    filterPlacesAfterTheDot: 3,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_COST_PER_RESULT: {
    filterName: "Cost Per Result",
    filterType: "CAMPAIGN_COST_PER_RESULT",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.cost_per_result,
    fieldName: "cost_per_result",
    filterPlacesAfterTheDot: 3,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  ARTICLE_LINK: {
    filterName: "Article link",
    filterType: "ARTICLE_LINK",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => campaign.trimmedUrl,
    fieldName: "link_url",
    defaultOperator: FilterTypes.filterOperators.INCLUDE,
    editable: false,
  },

  CAMPAIGN_STATUS: {
    filterName: "Status",
    filterType: "CAMPAIGN_STATUS",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => campaign.status,
    fieldName: "status",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        { name: CampaignsConsts.CAMPAIGN_STATUS_NAMES.ACTIVE, value: CampaignsConsts.CAMPAIGN_STATUS_RUNNING },
        { name: CampaignsConsts.CAMPAIGN_STATUS_NAMES.PAUSED, value: CampaignsConsts.CAMPAIGN_STATUS_PAUSED },
        { name: CampaignsConsts.CAMPAIGN_STATUS_NAMES.DELETED, value: CampaignsConsts.CAMPAIGN_STATUS_DELETED },
        { name: CampaignsConsts.CAMPAIGN_STATUS_NAMES.WITH_ISSUES, value: CampaignsConsts.CAMPAIGN_STATUS_REJECTED },
      ]
    },
    editable: false,
  },

  CAMPAIGN_ADS_STATUS: {
    filterName: "Ads Status",
    filterType: "CAMPAIGN_ADS_STATUS",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => {
      if (campaign.ads_status) {
        let total_ads =
          campaign.ads_status.ERROR +
          campaign.ads_status.ACTIVE +
          campaign.ads_status.PAUSED +
          campaign.ads_status.REVIEW +
          campaign.ads_status.OTHER
        if (campaign.ads_status.ERROR === 0 && campaign.ads_status.OTHER === 0)
          return CampaignsConsts.CAMPAIGN_ADS_STATUS.ALL_APPROVED
        if (campaign.ads_status.ERROR === total_ads) return CampaignsConsts.CAMPAIGN_ADS_STATUS.ALL_REJECTED
        if (campaign.ads_status.REVIEW === total_ads) return CampaignsConsts.CAMPAIGN_ADS_STATUS.ALL_REVIEW
        return CampaignsConsts.CAMPAIGN_ADS_STATUS.SOME_REJECTED
      }
    },
    fieldName: "ads_status",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        { name: "All ads rejected", value: CampaignsConsts.CAMPAIGN_ADS_STATUS.ALL_REJECTED },
        { name: "Some ads rejected ", value: CampaignsConsts.CAMPAIGN_ADS_STATUS.SOME_REJECTED },
        { name: "All ads approved", value: CampaignsConsts.CAMPAIGN_ADS_STATUS.ALL_APPROVED },
        { name: "All ads in review", value: CampaignsConsts.CAMPAIGN_ADS_STATUS.ALL_REVIEW },
      ]
    },
    editable: false,
  },

  CAMPAIGN_QUALITY_RANKING: {
    filterName: "Quality Ranking",
    filterType: "CAMPAIGN_QUALITY_RANKING",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.quality_ranking,
    fieldName: "quality_ranking",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return Object.keys(CampaignsConsts.CAMPAIGN_RANKINGS).map((qualityRankingKey) => {
        return { name: CampaignsConsts.CAMPAIGN_RANKINGS[qualityRankingKey], value: qualityRankingKey }
      })
    },
    editable: false,
    showFilter: () => {
      return true
    },
  },

  CAMPAIGN_ENGAGEMENT_RATE_RANKING: {
    filterName: "Engagement Rate Ranking",
    filterType: "CAMPAIGN_ENGAGEMENT_RATE_RANKING",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.engagement_rate_ranking,
    fieldName: "engagement_rate_ranking",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return Object.keys(CampaignsConsts.CAMPAIGN_RANKINGS).map((engagementRateRanking) => {
        return { name: CampaignsConsts.CAMPAIGN_RANKINGS[engagementRateRanking], value: engagementRateRanking }
      })
    },
    editable: false,
    showFilter: () => {
      return true
    },
  },

  CAMPAIGN_VISIT_DURATION: {
    filterName: "Visit Duration (Seconds)",
    filterType: "CAMPAIGN_VISIT_DURATION",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.visit_duration,
    fieldName: "visit_duration",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_BID_TYPE: {
    filterName: "Bid Type",
    filterType: "CAMPAIGN_BID_TYPE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.bid_mode,
    fieldName: "bid_mode",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [BidTypes.lowest_cost, BidTypes.bid_cap, BidTypes.roas, BidTypes.cost_cap]
    },
    editable: false,
  },

  CAMPAIGN_CONVERSION_RATE_RANKING: {
    filterName: "Conversion Rate Ranking",
    filterType: "CAMPAIGN_CONVERSION_RATE_RANKING",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.conversion_rate_ranking,
    fieldName: "conversion_rate_ranking",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return Object.keys(CampaignsConsts.CAMPAIGN_RANKINGS).map((conversion_rate_ranking) => {
        return { name: CampaignsConsts.CAMPAIGN_RANKINGS[conversion_rate_ranking], value: conversion_rate_ranking }
      })
    },
    editable: false,
    showFilter: () => {
      return true
    },
  },

  CAMPAIGN_SAVED_FILTERS: {
    filterName: "Groups",
    filterType: "CAMPAIGN_SAVED_FILTERS",
    getMenu: SavedFilter.getMenu,
    getSuggestions: SavedFilter.getSuggestions,
    getOptions: () => {
      let savedFiltersItems = []
      let reduxStore = store.getState()

      if (reduxStore.profile.savedFilters) {
        reduxStore.profile.savedFilters.forEach((savedFiltersObj) => {
          if (savedFiltersObj.section_id === FilterTypes.filterSections.CAMPAIGNS) {
            savedFiltersItems.push({ name: savedFiltersObj.group_name, value: savedFiltersObj })
          }
        })
      }

      return savedFiltersItems
    },
    showFilter: () => {
      let reduxStore = store.getState()

      return (
        reduxStore.profile.savedFilters &&
        reduxStore.profile.savedFilters.filter(
          (savedFiltersObj) => savedFiltersObj.section_id === FilterTypes.filterSections.CAMPAIGNS
        ).length > 0
      )
    },
    editable: false,
  },
  CAMPAIGN_PROVIDER_PUBLISHER_ID: {
    filterName: "Facebook Page",
    filterType: "CAMPAIGN_PROVIDER_PUBLISHER_ID",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => campaign.page_id,
    fieldName: "page_id",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let pages = []
      let reduxStore = store.getState()

      function addSitePages(site) {
        if (site.provider_pages) {
          site.provider_pages.forEach((providerPage) => {
            pages.push({ name: providerPage.page_name, value: providerPage.page_id })
          })
        }
      }

      if (reduxStore.navigationBar.currentSite) {
        addSitePages(reduxStore.navigationBar.currentSite)
      } else if (reduxStore.navigationBar.currentNetwork.id === NetworkTypes.ALL_NETWORKS.id) {
        reduxStore.app.networkToSites.forEach((network) => {
          network.data.forEach(addSitePages)
        })
      } else if (NetworkTypes.NETWORK_GROUP_TYPE_IDS.includes(reduxStore.navigationBar.currentNetwork.id)) {
        let networksWithNetworkGroupType = new Map()

        reduxStore.app.sites.forEach((site) => {
          if (site.network.type === reduxStore.navigationBar.currentNetwork.id) {
            networksWithNetworkGroupType.set(site.network.id, site.network)
          }
        })

        reduxStore.app.networkToSites
          .filter((network) => Array.from(networksWithNetworkGroupType.keys()).includes(parseInt(network.key)))
          .forEach((network) => {
            network.data.forEach(addSitePages)
          })
      } else {
        let network = reduxStore.app.networkToSites.filter(
          (network) => network.key == reduxStore.navigationBar.currentNetwork.id
        )

        if (network[0] && network[0].data) {
          network[0].data.forEach(addSitePages)
        }
      }

      return pages
    },
    editable: false,
  },

  CAMPAIGN_ADVANCED_SEARCH_LANGUAGE: {
    filterName: "Language",
    filterType: "CAMPAIGN_ADVANCED_SEARCH_LANGUAGE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    valueResolver: (campaign) => campaign.language,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let reduxStore = store.getState()

      let languages = reduxStore.app.languages.map((language) => {
        return { ...language, value: language.code }
      })

      languages.unshift({ name: "No language", value: undefined })

      return languages
    },
    editable: false,
  },

  CAMPAIGN_ADVANCED_SEARCH_TAGS: {
    filterName: "Tags",
    filterType: "CAMPAIGN_ADVANCED_SEARCH_TAGS",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.ARRAY,
    defaultOperator: FilterTypes.filterOperators.IN_ARRAY,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let tags = []
      let reduxStore = store.getState()

      tags.push({ name: "No tags", value: null })
      ;[...reduxStore.campaignsV2.networkTags]
        .sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        .forEach((tag) => {
          tags.push({ name: tag.name, value: tag.name })
        })

      return tags
    },
    showFilter: () => {
      return true
    },
    editable: false,
  },

  CAMPAIGN_LEARNING_STATUS: {
    filterName: "Learning Status",
    filterType: "CAMPAIGN_LEARNING_STATUS",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => {
      return campaign?.learning_stage_info?.status || "SUCCESS"
    },
    fieldName: "learning_stage_info",
    defaultOperator: FilterTypes.filterOperators.IN_ARRAY,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        { name: "Learning", value: ["LEARNING"] },
        { name: "Learning Limited", value: ["FAIL", "LEARNING_LIMITED"] },
        { name: "Learning Expedited", value: ["EXPEDITED_LEARNING"] },
      ]
    },
    editable: false,
  },

  CAMPAIGN_NAME_AND_EXTERNAL_ID: {
    filterName: "Campaign name \\ External ID",
    filterType: "CAMPAIGN_NAME_AND_EXTERNAL_ID",
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => (campaign.tracking_code ? campaign.tracking_code.concat(campaign.external_id) : null),
    fieldName: "utm_campaign",
    defaultOperator: FilterTypes.filterOperators.INCLUDE,
    editable: false,
  },

  CAMPAIGN_LAST_MODIFIED_ACTION: {
    filterName: "Last Modified (Action)",
    filterType: "CAMPAIGN_LAST_MODIFIED_ACTION",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => {
      if (campaign.last_modified_action) {
        return campaign.last_modified_action.type
      }
      return undefined
    },
    fieldName: "last_modified_action",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        { name: "Bid Change", value: CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_BID },
        { name: "Status Change", value: CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_STATUS },
        { name: "Daily Budget Change", value: CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_DAILY_BUDGET },
        { name: "Total Budget Change", value: CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_TOTAL_BUDGET },
        { name: "ROAS Change", value: CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_ROAS },
        { name: "FB Placements Change", value: CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.SET_TARGETING },
      ]
    },
    editable: false,
  },

  CAMPAIGN_ACTIVATION_DATE: {
    filterName: "Activation Date",
    filterType: "CAMPAIGN_ACTIVATION_DATE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.DATE,
    valueResolver: (campaign) => campaign.activation_date,
    fieldName: "activation_date",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  CAMPAIGN_INITIAL_ACTIVATION_DATE: {
    filterName: "Initial Activation Date",
    filterType: "CAMPAIGN_INITIAL_ACTIVATION_DATE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.DATE,
    valueResolver: (campaign) => campaign.initial_activation_date,
    fieldName: "initial_activation_date",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  ACCOUNT_ID: {
    filterName: "Account ID",
    filterType: "ACCOUNT_ID",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.ID,
    valueResolver: (campaign) => (campaign.external_account_id ? campaign.external_account_id : null),
    fieldName: "external_account_id",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    editable: true,
    getSuggestions: ValueFilter.getSuggestions,
    getMenu: ValueFilter.getMenu,
  },

  BROWSERS: {
    filterName: "Browsers",
    filterType: "BROWSERS",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => {
      return getBrowsersFilterValue(campaign.browsers)
    },
    fieldName: "browsers",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [...Browsers.typeToNameList, { name: "Multiple browsers", value: Browsers.multipleBrowsers }]
    },
    editable: false,
  },

  OPERATING_SYSTEMS: {
    filterName: "Operating Systems",
    filterType: "OPERATING_SYSTEMS",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    valueResolver: (campaign) => {
      return getOperatingSystemsFilterValue(campaign.operating_systems)
    },
    fieldName: "operating_systems",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [...OsTypes.typeToNameList, { name: "Multiple operating systems", value: OsTypes.multipleOs }]
    },
    editable: false,
  },

  DRAFT_REVIEW_STATUS: {
    filterName: "Status",
    filterType: "DRAFT_REVIEW_STATUS",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    fieldName: "status",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    editable: false,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        { name: draftReviewStatus.in_review.name, value: draftReviewStatus.in_review.value },
        { name: draftReviewStatus.approved.name, value: draftReviewStatus.approved.value },
        { name: draftReviewStatus.partially_approved.name, value: draftReviewStatus.partially_approved.value },
        { name: draftReviewStatus.rejected.name, value: draftReviewStatus.rejected.value },
      ]
    },
  },

  DRAFT_CREATION_STATUS: {
    filterName: "Creation Status",
    filterType: "DRAFT_CREATION_STATUS",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    fieldName: "status",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    editable: false,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        { name: draftCreationStatus.created.name, value: draftCreationStatus.created.value },
        { name: draftCreationStatus.failed.name, value: draftCreationStatus.failed.value },
        { name: draftCreationStatus.pending.name, value: draftCreationStatus.pending.value },
        { name: draftCreationStatus.in_progress.name, value: draftCreationStatus.in_progress.value },
      ]
    },
  },

  DRAFTS_CREATE_DATE: {
    filterName: "Creation Date",
    filterType: "DRAFTS_CREATE_DATE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.DATE,
    fieldName: "created_at",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  DRAFTS_REVIEW_DATE: {
    filterName: "Review Date",
    filterType: "DRAFTS_REVIEW_DATE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.DATE,
    fieldName: "review_at",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },

  DRAFTS_LANGUAGE: {
    filterName: "Language",
    filterType: "DRAFTS_LANGUAGE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let reduxStore = store.getState()

      let languages = reduxStore.app.languages.map((language) => {
        return { ...language, value: language.code }
      })

      languages.unshift({ name: "No language", value: undefined })

      return languages
    },
    editable: false,
  },

  DRAFTS_NAME: {
    filterName: "Draft name",
    filterType: "DRAFTS_NAME",
    filterValueType: FilterTypes.filterValueTypes.STRING,
    fieldName: "tracking_code",
    defaultOperator: FilterTypes.filterOperators.INCLUDE,
    editable: false,
  },

  ARTICLE_LANGUAGE: {
    filterName: "Language",
    filterType: "ARTICLE_LANGUAGE",
    fieldName: "lang",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let languages = store.getState().articles.languages
      languages = languages.map((language) => {
        return { name: language.name, value: language.code }
      })
      languages.unshift({ name: "No language", value: undefined })

      return languages
    },
    editable: false,
  },

  PUBLISH_DATE: {
    filterName: "Publish Date",
    filterType: "PUBLISH_DATE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.DATE,
    fieldName: "publish_date",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },
  ARTICLE_PAGES: {
    filterName: "Pages",
    filterType: "ARTICLE_PAGES",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    fieldName: "pages",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },
  ARTICLE_SYNDICATION: {
    filterName: "Syndication",
    filterType: "ARTICLE_SYNDICATION",
    fieldName: "tags",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    editable: false,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        {
          name: "Original",
          value: "original",
        },
        {
          name: "Syndicated",
          value: "Syndicated",
        },
      ]
    },
  },
  ARTICLE_POST_STYLE: {
    filterName: "Article Post Style",
    filterType: "ARTICLE_POST_STYLE",
    fieldName: "post_style",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    editable: false,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      return [
        {
          name: "List",
          value: "list",
        },
        {
          name: "Story",
          value: "story",
        },
      ]
    },
  },
  ARTICLE_CATEGORY: {
    filterName: "Category",
    filterType: "ARTICLE_CATEGORY",
    fieldName: "catalog_category_name",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    editable: false,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let categories = store.getState().articles.categories
      return categories.map((category) => {
        return { name: category.display_name, value: category.slug }
      })
    },
  },
  ARTICLE_SITE: {
    filterName: "Site",
    filterType: "ARTICLE_SITE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    fieldName: "site_code",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    getOptions: () => {
      let sites = []
      let reduxStore = store.getState()

      if (reduxStore.navigationBar.currentNetwork.id === NetworkTypes.ALL_NETWORKS.id) {
        sites = reduxStore.app.sites
      } else if (NetworkTypes.NETWORK_GROUP_TYPE_IDS.includes(reduxStore.navigationBar.currentNetwork.id)) {
        sites = reduxStore.app.sites.filter((site) => site.network.type === reduxStore.navigationBar.currentNetwork.id)
      } else {
        sites = reduxStore.app.networkToSites.filter((networkToSites) => {
          return parseInt(networkToSites.key) === reduxStore.navigationBar.currentNetwork.id
        })
        sites = sites[0].data
      }

      return sites.map((site) => {
        return { name: site.name, value: site.code }
      })
    },
    editable: false,
  },
  ARTICLE_FULL_TEXT: {
    filterName: "Article full text",
    filterType: "ARTICLE_FULL_TEXT",
    filterValueType: FilterTypes.filterValueTypes.STRING,
    fieldName: "full_text_search",
    defaultOperator: FilterTypes.filterOperators.INCLUDE,
    editable: false,
  },
  ARTICLE_STYLE: {
    filterName: "Article Style",
    filterType: "ARTICLE_STYLE",
    shouldOverrideFilter: false,
    filterValueType: FilterTypes.filterValueTypes.STRING,
    fieldName: "post_style",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ListFilter.getMenu,
    getSuggestions: ListFilter.getSuggestions,
    valueResolver: (campaign) => {
      return getArticleTypeFilterValue(campaign.post_style)
    },
    getOptions: () => {
      return [
        { name: articlesTypeConsts.affiliation.title, value: articlesTypeConsts.affiliation.name },
        { name: articlesTypeConsts.ads_for_search.title, value: articlesTypeConsts.ads_for_search.name },
        { name: articlesTypeConsts.content.title, value: articlesTypeConsts.content.name },
      ]
    },
    editable: false,
  },
}

export const SubSourceFilters = {
  SUB_SOURCE_ROI: {
    filterName: "Sub-Source ROI",
    filterType: "SUB_SOURCE_ROI",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    fieldName: "sub_source_roi",
    filterPlacesAfterTheDot: 0,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },
  SUB_SOURCE_BOUNCE_RATE: {
    filterName: "Sub-Source Bounce Rate",
    filterType: "SUB_SOURCE_BOUNCE_RATE",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    fieldName: "sub_source_bounce_rate",
    filterPlacesAfterTheDot: 1,
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },
  SUB_SOURCE_VISITS: {
    filterName: "Sub-Source Visits",
    filterType: "SUB_SOURCE_VISITS",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    fieldName: "sub_source_visits",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },
  SUB_SOURCE_CLICKS: {
    filterName: "Sub-Source Clicks",
    filterType: "SUB_SOURCE_CLICKS",
    shouldOverrideFilter: true,
    filterValueType: FilterTypes.filterValueTypes.NUMBER,
    fieldName: "sub_source_clicks",
    defaultOperator: FilterTypes.filterOperators.EQUAL,
    getMenu: ValueFilter.getMenu,
    getSuggestions: ValueFilter.getSuggestions,
    editable: true,
  },
}

export let FiltersFieldNamesToFilter = Object.entries({ ...CampaignFilters, ...SubSourceFilters }).reduce(
  (result, [key, value]) => {
    if (!isNullOrUndefined(value.fieldName)) {
      result[value.fieldName] = value
    }
    return result
  },
  {}
)

CampaignFilters.CAMPAIGN_SAVED_FILTERS.filtersTemplates = CampaignFilters

export let Filters = CampaignFilters
