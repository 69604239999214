import axios from "axios"
import Consts from "../app/consts"
import awsIot from "aws-iot-device-sdk"
import { store } from "../app/store"
import { isNullOrUndefined } from "../utils/funcUtils"
import { getAllActivitiesResponse } from "../navigationBar/navigationBarActions"

export class ServerUpdatesDispatcher {
  constructor() {
    this.client = null
    this.rooms = null
  }

  connect() {
    axios
      .get(Consts.BASE_API_URL + "/api/server_updates/connect/")
      .then((response) => {
        this.client = awsIot.device({
          region: response.data.region,
          protocol: "wss",
          accessKeyId: response.data.access_key,
          secretKey: response.data.secret_key,
          sessionToken: response.data.token,
          port: 443,
          host: response.data.endpoint,
        })

        this.client.on("connect", this.onConnect)
        this.client.on("close", this.onClose)
        this.client.on("message", this.onMessage)
        this.client.on("reconnect", this.onReconnect)
        this.client.on("error", () => {})

        response.data.allowed_topics.forEach((topic) => {
          this.client.subscribe(topic)
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  onConnect() {}

  onReconnect() {}

  onClose() {}

  onMessage(topic, payload) {
    let message = new TextDecoder("utf-8").decode(payload)
    payload = JSON.parse(message)

    if (payload.initiator !== Consts.CLIENT_ID) {
      switch (payload.type) {
        case "PUSH_NOTIFICATION":
          let notificationData = payload.payload

          switch (payload.notification_type) {
            case "activity":
              if (!isNullOrUndefined(notificationData)) {
                store.dispatch(getAllActivitiesResponse(notificationData))
              }
              break
            default:
              break
          }
          break
        default:
          store.dispatch(JSON.parse(message))
          break
      }
    } else {
      console.log("skipping since client is the initiator...")
    }
  }

  send(room, payload) {
    this.client.rooms.get(room).forEach((topic) => {
      this.client.publish(topic, JSON.stringify(payload))
    })
  }
}

export const serverUpdatesDispatcher = new ServerUpdatesDispatcher()
