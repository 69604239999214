import React from "react"
import { connect } from "react-redux"
import { campaignPopupSortPerformanceList, updateCampaignPopupTablePage } from "../../campaignPopupActions"
import Table from "../../../common/components/table/table"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import Pagination from "../../../common/components/pagination"
import articlesTypeConsts from "../../../articles/components/articlesType"

class CampaignPopupPerformanceByDeviceList extends React.Component {
  columns = CampaignsConsts.LIST_COLUMNS

  constructor(props) {
    super(props)

    this.tableName = "performanceByDevice"
  }

  sortCountriesBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, "performanceByDevice"))
  }

  getHeader = () => {
    let columns = this.columns

    return {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.device.fieldName,
          value: columns.device.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.visits.fieldName,
          value: columns.data_columns.visits.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.pagesPerVisit.fieldName,
          value: columns.data_columns.pagesPerVisit.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.bounceRate.fieldName,
          value: columns.data_columns.bounceRate.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.visitValue.fieldName,
          value: `Visit Value${
            this.props?.campaign?.post_style === articlesTypeConsts.ads_for_search.name ? "" : " (Predicted)"
          }`,
          clickable: true,
        },
      ],
    }
  }

  getSummaryRow = (summaryData) => {
    let columns = this.columns.data_columns

    return {
      cssClasses: ["summary-row"],
      allowRowSelection: false,
      cells: [
        {
          fieldName: "total",
          value: "Total",
        },
        {
          fieldName: columns.visits.fieldName,
          value: columns.visits.defaultFormat(summaryData.visits),
        },
        {
          fieldName: columns.pagesPerVisit.fieldName,
          value: columns.pagesPerVisit.defaultFormat(summaryData.pages_per_visit),
        },
        {
          fieldName: columns.bounceRate.fieldName,
          value: columns.bounceRate.defaultFormat(summaryData.bounce_rate),
        },
        {
          fieldName: columns.visitValue.fieldName,
          value: columns.visitValue.defaultFormat(summaryData.visit_value),
        },
      ],
    }
  }

  getRow = (device) => {
    let columns = this.columns
    return {
      cells: [
        {
          fieldName: columns.device.fieldName,
          value: device.name,
        },
        {
          fieldName: columns.data_columns.visits.fieldName,
          value: columns.data_columns.visits.defaultFormat(device.visits),
        },
        {
          fieldName: columns.data_columns.pagesPerVisit.fieldName,
          value: columns.data_columns.pagesPerVisit.defaultFormat(device.pages_per_visit),
        },
        {
          fieldName: columns.data_columns.bounceRate.fieldName,
          value: columns.data_columns.bounceRate.defaultFormat(device.bounce_rate),
        },
        {
          fieldName: columns.data_columns.visitValue.fieldName,
          value: columns.data_columns.visitValue.defaultFormat(device.visit_value),
        },
      ],
    }
  }

  render() {
    let header = this.getHeader()
    let rows = []
    let pagination = null

    if (this.props.performanceByDevice.length > 0) {
      rows.push(this.getSummaryRow(this.props.summaryRow))
    }

    for (
      let i = (this.props.currentPage - 1) * this.props.itemsPerPage;
      i < this.props.currentPage * this.props.itemsPerPage && i < this.props.performanceByDevice.length;
      i++
    ) {
      let device = this.props.performanceByDevice[i]
      rows.push(this.getRow(device))
    }

    if (this.props.performanceByDevice.length > this.props.itemsPerPage) {
      pagination = (
        <Pagination
          itemsArr={
            this.props.performanceByDevice ? this.props.performanceByDevice.length : this.props.performanceByDevice
          }
          currentPage={this.props.currentPage}
          itemsPerPage={this.props.itemsPerPage}
          updatePage={(updatedPage) => {
            this.props.dispatch(updateCampaignPopupTablePage(updatedPage, this.tableName))
          }}
        />
      )
    }

    return (
      <div>
        <Table
          header={header}
          rows={rows}
          sort={{
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection,
            sortHandler: this.sortCountriesBy,
          }}
          scrolling={{
            containerClass: ".campaign-popup-container",
            includeNavigationBar: false,
          }}
          cssClasses={["campaign-popup-list"]}
        />
        {pagination}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sortBy: state.campaignPopupV2.performanceByDevice.sortBy,
    sortDirection: state.campaignPopupV2.performanceByDevice.sortDirection,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    performanceByDevice: state.campaignPopupV2.performanceByDevice.dataForTable,
    summaryRow: state.campaignPopupV2.performanceByDevice.summaryRow,
    itemsPerPage: state.campaignPopupV2.performanceByDevice.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceByDevice.currentPage,
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByDeviceList)
