import React from "react"
import { TextField } from "@material-ui/core"

export default class Text extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: this.props.text,
    }
  }

  handleChange = (name, event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  updateCreativeInStore = () => {
    this.props.creativeUpdateFunc(this.props.index, this.props.type, this.state.text, this.props.groupIndex)
  }

  render() {
    return (
      <span className="creative-text">
        <TextField
          onChange={(event) => this.handleChange("text", event)}
          onBlur={() => this.updateCreativeInStore()}
          classes={{ root: "creative-textfield creative-multiline" }}
          placeholder={this.props.placeHolder}
          value={this.state.text}
          helperText={
            this.props.maxCharsAllowed === Infinity
              ? null
              : (this.props.maxCharsAllowed - this.state.text.length).toString()
          }
          InputProps={{
            disableUnderline: true,
            inputProps: {
              maxLength: String(this.props.maxCharsAllowed),
              className: this.props.inputClassName,
            },
          }}
          multiline={true}
        />
      </span>
    )
  }
}
