class UnintegratedCampaignsActionTypes {}
UnintegratedCampaignsActionTypes.FETCH_UNINTEGRATED_CAMPAIGNS_RESPONSE = "FETCH_UNINTEGRATED_CAMPAIGNS_RESPONSE"
UnintegratedCampaignsActionTypes.RESET_UNINTEGRATED_CAMPAIGNS_DATES = "RESET_UNINTEGRATED_CAMPAIGNS_DATES"
UnintegratedCampaignsActionTypes.CAMPAIGNS_LIST_LOADING = "CAMPAIGNS_LIST_LOADING_V2"
UnintegratedCampaignsActionTypes.CAMPAIGNS_DATEPICKER_DATES_SELECTED = "CAMPAIGNS_DATEPICKER_DATES_SELECTED"
UnintegratedCampaignsActionTypes.ROLLBACK_CAMPAIGNS_PAGE = "ROLLBACK_CAMPAIGNS_PAGE"
UnintegratedCampaignsActionTypes.UPDATE_ITEMS_PER_PAGE = "UPDATE_ITEMS_PER_PAGE"
UnintegratedCampaignsActionTypes.RESET_CAMPAIGNS_PAGINATION = "RESET_CAMPAIGNS_PAGINATION"
UnintegratedCampaignsActionTypes.UPDATE_CAMPAIGNS_PAGE = "UPDATE_CAMPAIGNS_PAGE"
UnintegratedCampaignsActionTypes.SHOULD_UPDATE_CAMPAIGNS_PAGE = "SHOULD_UPDATE_CAMPAIGNS_PAGE"
export default UnintegratedCampaignsActionTypes
