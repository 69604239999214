import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../common/components/genericDropdown"
import AutomationCreationConsts from "../../automationCreationConsts"

class SourceDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: AutomationCreationConsts.SOURCES,
      selectedIndexesArr: [0],
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (index, resetValues = true) => {
    this.setState({
      selectedIndexesArr: index,
    })

    let selectedSource = this.state.menuItems[index]
    this.props.handleSelect("selectedSource", selectedSource)

    if (resetValues) {
      this.props.handleSelectMultiple({
        selectedActionType: null,
        actionValue: null,
        selectedValueType: null,
        selectedActionOperation: null,
        actionLimit: null,
      })
    }

    this.handleMenuClose()
  }

  componentDidMount() {
    if (this.props.automationDetailsState.selectedSource) {
      const selectedIndex = this.state.menuItems.findIndex(
        (item) => item.providerId === this.props.automationDetailsState.selectedSource.providerId
      )
      if (selectedIndex !== -1) {
        this.handleMenuClick([selectedIndex], false)
      }
    }
  }

  render() {
    let buttonClassName = "creation-generic-dropdown-button darker-text "

    return (
      <div className={"text-field"}>
        <div className="creation-generic-dropdown-label">Source</div>
        <GenericDropdown
          header={this.state.menuItems[this.state.selectedIndexesArr[0]].name}
          selectOptionCallback={this.handleMenuClick}
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowAutoSuggest={false}
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection={false}
          buttonClassName={buttonClassName}
          menuClassName={"creation-generic-dropdown-menu"}
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(SourceDropdown)
