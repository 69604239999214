import React from "react"
import Moment from "moment"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import { campaignPopupBidHistoryShowMore, campaignPopupBidHistorySortBy } from "../../campaignPopupActions"
import { disableInfiniteScrollHandler, handleInfiniteScroll } from "../../../utils/domUtils"
import Consts from "../../../app/consts"
import Table from "../../../common/components/table/table"
import { buildActionHistoryDisplay } from "../../../utils/actionHistoryUtils"
import { isNullOrUndefined } from "../../../utils/funcUtils"
import AutomationPopupLink from "../../../automationPopup/automationPopupLink"
import { automationPopupOpen } from "../../../automationPopup/automationPopupActions"
class CampaignPopupBidHistoryList extends React.Component {
  columns = CampaignsConsts.LIST_COLUMNS

  sortItemsBy = (column) => {
    this.props.dispatch(campaignPopupBidHistorySortBy(column))
  }

  getMoreItemsIfNeeded = () => {
    let scroller = document.querySelector(".campaign-popup-container")
    let offsetFix = 50 // bugFix - sometimes the scroll top + offset height is lower than scroll height even if the user scrolled to the bottom
    if (scroller.scrollTop + scroller.offsetHeight >= scroller.scrollHeight - offsetFix) {
      if (this.props.bidHistory.length >= this.props.itemsPerPage * this.props.currentPage) {
        this.props.dispatch(campaignPopupBidHistoryShowMore())
      }
    }
  }

  handleItemsInfiniteScroll = () => {
    handleInfiniteScroll(".campaign-popup-container", this.getMoreItemsIfNeeded)
  }

  disableItemsInfiniteScrollHandler = () => {
    disableInfiniteScrollHandler(".campaign-popup-container", this.getMoreItemsIfNeeded)
  }

  openAutomationPopup = (event) => {
    this.props.dispatch(automationPopupOpen({ id: item.rule_id }))
  }

  componentDidUpdate() {
    this.disableItemsInfiniteScrollHandler()
    this.handleItemsInfiniteScroll()
    ReactTooltip.rebuild()
  }

  componentDidMount() {
    this.disableItemsInfiniteScrollHandler()
    this.handleItemsInfiniteScroll()
    ReactTooltip.rebuild()
  }

  componentWillUnmount() {
    this.disableItemsInfiniteScrollHandler()
  }

  getHeader = () => {
    let columns = this.columns
    return {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.time.fieldName,
          value: columns.time.title,
          cssClasses: ["no-grow"],
          cellSize: "medium",
          clickable: true,
        },
        {
          fieldName: columns.user.fieldName,
          value: columns.user.title,
          cssClasses: ["no-grow"],
          clickable: true,
        },
        {
          fieldName: columns.campaignActivity.fieldName,
          value: columns.campaignActivity.title,
        },
      ],
    }
  }

  getAdditionalRowCells = (item) => {
    return [
      {
        fieldName: "action-change",
        rawValue: `${item.action_type}: ${item.old_value} to ${item.new_value}`,
        value: buildActionHistoryDisplay(item, "bidHistory"),
        cssClasses: ["large-filler"],
      },
    ]
  }

  getRow = (item) => {
    if (item.additional_info?.sub_sources?.length > 0) {
      return null
    }

    let columns = this.columns
    let row = {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.time.fieldName,
          rawValue: Moment(item.time, Consts.SERVER_DATETIME_FORMAT).toISOString(),
          value: <span>{Moment(item.time, Consts.SERVER_DATETIME_FORMAT).format(Consts.CLIENT_DATETIME_FORMAT)}</span>,
          cssClasses: ["no-grow"],
          cellSize: "medium",
        },
        {
          fieldName: columns.user.fieldName,
          rawValue: item.username,
          value:
            item.username === "Rule" ? (
              <AutomationPopupLink
                data-class="tooltip-custom type-icon-info shrink-tip"
                automation={{ id: item.rule_id }}
                className="fake-link clickable"
                onClick={this.openAutomationPopup}
              >
                <span>{item.username}</span>
              </AutomationPopupLink>
            ) : (
              <span>{item.username}</span>
            ),
          cssClasses: ["no-grow"],
          containerProps:
            item.username === "Rule"
              ? {
                  "data-offset": "{'top': -12}",
                  "data-tip": item.rule_name,
                }
              : null,
        },
      ],
    }

    row = {
      cssClasses: [],
      stickyCells: [],
      cells: [...row.cells, ...this.getAdditionalRowCells(item)],
    }

    return row
  }

  getAdditionalRows = (item) => {
    let rows = []
    if (!isNullOrUndefined(item.additional_info)) {
      let columns = this.columns

      let row = {
        stickyCells: [],
        cells: [
          {
            fieldName: columns.time.fieldName,
            rawValue: Moment(item.time, Consts.SERVER_DATETIME_FORMAT).toISOString(),
            value: (
              <span>{Moment(item.time, Consts.SERVER_DATETIME_FORMAT).format(Consts.CLIENT_DATETIME_FORMAT)}</span>
            ),
            cssClasses: ["no-grow"],
            cellSize: "medium",
          },
          {
            fieldName: columns.user.fieldName,
            rawValue: item.username,
            value: <span>{item.username}</span>,
            cssClasses: ["no-grow"],
          },
        ],
      }

      if (
        item.status === true &&
        item.action_type === Consts.ACTION_TYPE_SUB_SOURCE_BLOCK &&
        item.additional_info?.sub_sources?.length > 0
      ) {
        const subSources = item.additional_info.sub_sources
        subSources.forEach((subSource) => {
          const additionalCell = {
            fieldName: "action-change",
            rawValue: `${item.action_type}: ${subSource}`,
            value: (
              <>
                <div className="action-text">Blocked: "{subSource}"</div>
              </>
            ),
            cssClasses: ["large-filler"],
          }
          rows.push({
            cssClasses: [],
            stickyCells: [],
            cells: [...row.cells, additionalCell],
          })
        })
      }
    }

    return rows
  }

  getCsvFileName = () => {
    let csvFileName =
      "campaign-bid-history-" +
      this.props.campaign.campaign_id +
      "-" +
      this.props.datePickerDates.startDate.format(Consts.SERVER_DATE_FORMAT) +
      "-" +
      this.props.datePickerDates.endDate.format(Consts.SERVER_DATE_FORMAT)
    return csvFileName
  }

  getRows = (usePagination = true) => {
    let rows = []
    let lastIndex = this.props.bidHistory.length

    if (lastIndex > 0) {
      if (usePagination) {
        lastIndex = Math.min(this.props.itemsPerPage * this.props.currentPage, lastIndex)
      }

      for (let i = 0; i < lastIndex; i++) {
        let item = this.props.bidHistory[i]
        let row = this.getRow(item)
        if (row) {
          rows.push(row)
        }

        // Check if there are additional rows within the item and add them
        let additionalRows = this.getAdditionalRows(item)
        if (additionalRows.length > 0) {
          rows.push(...additionalRows)
        }
      }
    }

    return rows
  }

  getAllRows = () => {
    return this.getRows(false)
  }

  render() {
    let rows = this.getRows(true)
    let header = this.getHeader()

    return (
      <Table
        header={header}
        rows={rows}
        sort={{
          sortBy: this.props.sortBy,
          sortDirection: this.props.sortDirection,
          sortHandler: this.sortItemsBy,
        }}
        scrolling={{
          containerClass: ".campaign-popup-container",
          includeNavigationBar: false,
        }}
        cssClasses={["campaign-popup-list"]}
        isExportableToCsv={true}
        csvFileName={this.getCsvFileName()}
        getAllRows={this.getAllRows}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    bidHistory: state.campaignPopupV2.bidHistory.dataForTable,
    sortBy: state.campaignPopupV2.bidHistory.sortBy,
    sortDirection: state.campaignPopupV2.bidHistory.sortDirection,
    itemsPerPage: state.campaignPopupV2.bidHistory.itemsPerPage,
    currentPage: state.campaignPopupV2.bidHistory.currentPage,
    reportType: state.campaignsV2.reportType,
    campaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
  }
}

export default connect(mapStateToProps)(CampaignPopupBidHistoryList)
