import axios from "axios"
import Consts from "../app/consts"
import { history, store } from "../app/store"
import { isNullOrUndefined } from "../utils/funcUtils"
import WebStorageConsts from "../common/consts/webStorageConsts"
import { clearNotifications, notificationAdd } from "../common/actions/commonActions"
import { eventsTracker } from "./eventsTracker"
import ModuleTypes from "../common/consts/moduleTypes"
import { webStorage } from "./webStorage"
import { redirectToLogin } from "../utils/routingUtils"

export default class AuthApi {
  static googleAuthSuccess(response) {
    var params = {
      clientId: Consts.GOOGLE_AUTH_CLIENT_ID,
      code: response.code,
      redirectUri: Consts.BASE_URL,
    }

    axios
      .post(Consts.BASE_API_URL + "/auth/google", params)
      .then(function (response) {
        webStorage.set(WebStorageConsts.STORAGE_KEYS.PUBPLUS_AUTH_TOKEN, response.data.token)
        history.replace("/")
        store.dispatch(clearNotifications())

        eventsTracker.trackLogin()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch(
            notificationAdd("Oops! It looks like you don't have a user, please contact your account manager")
          )
        }
      })
  }

  static googleAuthFail(response) {
    console.log("google auth failed")
  }

  static logout() {
    redirectToLogin()
  }

  static hasModule(requiredModule, networkId = null) {
    let state = store.getState()
    let user = state.app.currentUser

    if (isNullOrUndefined(user)) {
      return false
    }

    if (user.is_admin) {
      return true
    }

    let currentNetworkId = networkId !== null ? networkId : state.navigationBar.currentNetwork.id

    let userNetworksToModules = user.userNetworksToModules
    return userNetworksToModules.get(currentNetworkId).has(requiredModule)
  }

  static hasModuleAtLeastInOneNetwork(requiredModule) {
    let state = store.getState()
    let user = state.app.currentUser

    if (isNullOrUndefined(user)) {
      return false
    }

    if (user.is_admin) {
      return true
    }

    return Array.from(user.userNetworksToModules.values()).some((item) => {
      return item.has(requiredModule)
    })
  }
}
