import React from "react"
import { connect } from "react-redux"
import { Button, CircularProgress, Menu, MenuItem, TextField } from "@material-ui/core"
import { findDOMNode } from "react-dom"
import { addSavedFiltersGroup } from "../../actions/commonActions"
import { eventsTracker } from "../../../api/eventsTracker"
import { serializeFilters } from "../../../utils/funcUtils"

class SaveFilterMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      anchorEl: null,
      name: "",
      isLoading: false,
    }
  }

  openMenu = () => {
    if (this.state.open) {
      this.handleClose()
    } else {
      this.setState({
        open: true,
        anchorEl: findDOMNode(this.button),
      })
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
      isLoading: false,
    })
  }

  setNewValue = (name, event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  saveFilters = () => {
    let filters = serializeFilters(this.props.filters)

    this.setState({ isLoading: true })
    this.props
      .dispatch(addSavedFiltersGroup(this.props.sectionId, this.state.name, filters))
      .then(() => {
        this.setState(
          {
            name: "",
            isLoading: false,
          },
          () => {
            eventsTracker.trackSaveFiltersGroup()
            this.handleClose()
          }
        )
      })
      .catch((error) => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    let content = null
    if (this.state.isLoading) {
      content = (
        <div className="save-filters-loading">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    }

    return (
      <>
        <div
          className="group-filters clickable d-flex align-items-center"
          onClick={this.openMenu}
          ref={(node) => {
            this.button = node
          }}
        >
          Group
        </div>
        <Menu
          classes={{ paper: "default-menu filter-window" }}
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          transformOrigin={{
            vertical: -8,
            horizontal: 0,
          }}
          onClose={this.handleClose}
          onEntered={() => {
            let textField = document.querySelector("#group-name")
            if (textField) {
              textField.focus()
            }
          }}
        >
          <MenuItem classes={{ root: "menu-item search-divider" }}>Create a new group</MenuItem>
          <MenuItem
            key="value-menu-body"
            classes={{ root: "unaryFilterWindow" + (this.state.name !== "" ? " open" : "") }}
            onClick={(event) => {}}
          >
            <div className="text-field-container">
              <TextField
                id="group-name"
                classes={{ root: "text-field" }}
                autoFocus={true}
                disabled={this.state.isLoading}
                onChange={(event) => this.setNewValue("name", event)}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    this.saveFilters()
                  }
                }}
                InputProps={{
                  className: "small-text",
                }}
              />
            </div>
            <div className="button-container">
              <Button disabled={this.state.name === ""} className="round-button blue" onClick={this.saveFilters}>
                Add
              </Button>
            </div>
          </MenuItem>
          {content}
        </Menu>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(SaveFilterMenu)
