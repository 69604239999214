import React from "react"
import { CircularProgress } from "@material-ui/core"
import { connect } from "react-redux"
import GenericDropdown from "../../../../common/components/genericDropdown"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import Consts from "../../../../app/consts"
import ProviderAccountService from "../../../../api/providerAccountService"
import { notificationAdd } from "../../../../common/actions/commonActions"
import { isNullOrUndefined } from "../../../../utils/funcUtils"

class ProviderAccountDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: props.providerAccounts.map((providerAccount) => {
        return { name: providerAccount.name }
      }),
      selectedIndexesArr: [],
      isLoadingAutoSelectProviderAccount: false,
    }
  }

  componentDidMount() {
    if (this.props.providerAccounts.length > 0) {
      this.autoSelectProviderAccount()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.isLoadingProviderAccounts && prevProps.isLoadingProviderAccounts) {
      this.setState({
        menuItems: this.props.providerAccounts.map((providerAccount) => {
          return { name: providerAccount.name }
        }),
      })

      this.autoSelectProviderAccount()
    }
  }

  autoSelectProviderAccount = () => {
    if (this.props.providerAccounts.length === 1) {
      if (
        JSON.stringify(this.props.campaignTargetingGroup.providerAccount) !==
        JSON.stringify(this.props.providerAccounts[0])
      ) {
        this.selectProviderAccount(this.props.providerAccounts[0])
      }
    } else if (
      this.props.providerAccounts.length > 1 &&
      isNullOrUndefined(this.props.campaignTargetingGroup.providerAccount)
    ) {
      // Select randomly provider account
      const randomIndex = Math.floor(Math.random() * this.props.providerAccounts.length)
      this.selectProviderAccount(this.props.providerAccounts[randomIndex])
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  updateProviderAccount = (selectedProviderAccount) => {
    const targetingGroupData = this.props.campaignTargetingGroups[this.props.groupIndex]
    const newTargetingGroupDataData = Object.assign({}, targetingGroupData, {
      providerAccount: selectedProviderAccount,
    })
    this.props.dispatch(updateTargetingGroup(this.props.groupIndex, newTargetingGroupDataData))
  }

  selectProviderAccount = (providerAccount) => {
    if (providerAccount.account_id === Consts.PROVIDER_ACCOUNT_AUTO_SELECT_OBJ.account_id) {
      this.setState({
        isLoadingAutoSelectProviderAccount: true,
      })

      ProviderAccountService.getSiteIdealProviderAccount(this.props.providerId, this.props.campaignSettings.siteId)
        .then((result) => {
          if (result?.data?.provider_account) {
            for (let i = 0; i < this.props.providerAccounts.length; i++) {
              let providerAccount = this.props.providerAccounts[i]
              if (providerAccount.sub_account_id === result.data.provider_account.sub_account_id) {
                this.selectProviderAccount(providerAccount)
                break
              }
            }
          }
        })
        .catch((error) => {
          this.props.dispatch(notificationAdd("An error occurred while trying to auto-select provider account"))
        })
        .finally(() => {
          this.setState({
            isLoadingAutoSelectProviderAccount: false,
          })
        })
    } else {
      this.updateProviderAccount(providerAccount)
    }
  }

  handleMenuClick = (index) => {
    this.setState({
      selectedIndexesArr: index,
    })

    this.selectProviderAccount(this.props.providerAccounts[index])
    this.handleMenuClose()
  }

  render() {
    let isFieldDisabled = !this.props.campaignSettings.siteId
    let header = "Choose account"
    let buttonClassName = "creation-generic-dropdown-button "
    let campaignTargetingGroup = this.props.campaignTargetingGroups[this.props.groupIndex]

    if (campaignTargetingGroup.providerAccount) {
      buttonClassName += "selected"
      header = campaignTargetingGroup.providerAccount.name
    }

    if (this.props.isLoadingProviderAccounts || this.state.isLoadingAutoSelectProviderAccount) {
      header = <CircularProgress size={20} />
    }

    return (
      <div
        className={
          "text-field provider-account-selection " +
          (this.props.providerAccountValidationErrors ? "error " : "") +
          (isFieldDisabled ? "disabled " : "")
        }
      >
        <div className="creation-generic-dropdown-label">Provider account</div>
        <GenericDropdown
          header={header}
          selectOptionCallback={(index) => {
            this.handleMenuClick(index)
          }}
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowAutoSuggest={true}
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection={false}
          buttonClassName={buttonClassName}
          menuClassName={"creation-generic-dropdown-menu"}
        />
        {this.props.providerAccountValidationErrors}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let allProviderAccountsForSite = state.campaignCreationWizard.providerAccounts
  let providerAccounts = []
  let sourceData = {}
  let selectedSourceKey = null
  let providerId = null

  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey, index) => {
    if (!state.campaignCreationWizard.campaignSources.sources[sourceKey].selected) {
      return
    }

    selectedSourceKey = sourceKey
    sourceData = state.campaignCreationWizard.campaignSources.sources[sourceKey]
    providerId = sourceData.id

    providerAccounts = allProviderAccountsForSite.filter((providerAccount) => {
      // Filtering only the provider accounts that part of this provider
      if (providerId === providerAccount.provider_id) {
        return providerAccount
      }
    })

    if (
      selectedSourceKey === "Facebook" &&
      state.campaignCreationWizard.campaignSettings.siteId &&
      providerAccounts.length > 0
    ) {
      providerAccounts.unshift({
        ...Consts.PROVIDER_ACCOUNT_AUTO_SELECT_OBJ,
        site_id: state.campaignCreationWizard.campaignSettings.siteId,
      })
    }
  })

  return {
    isLoadingProviderAccounts: state.campaignCreationWizard.isLoadingProviderAccounts,
    campaignSettings: state.campaignCreationWizard.campaignSettings,
    campaignTargetingGroups: state.campaignCreationWizard.campaignTargetingGroups,
    providerAccounts,
    selectedSourceKey,
    providerId,
  }
}

export default connect(mapStateToProps)(ProviderAccountDropdown)
