import React from "react"
import { connect } from "react-redux"
import { campaignPopupSortPerformanceList, updateCampaignPopupTablePage } from "../../campaignPopupActions"
import Table from "../../../common/components/table/table"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import Pagination from "../../../common/components/pagination"
import articlesTypeConsts from "../../../articles/components/articlesType"

class CampaignPopupPerformanceByBrowserList extends React.Component {
  columns = CampaignsConsts.LIST_COLUMNS
  scrollersObject = null

  constructor(props) {
    super(props)

    this.tableName = "performanceByBrowser"
  }

  sortBrowsersBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, "performanceByBrowser"))
  }

  getHeader = () => {
    let columns = this.columns

    return {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.browser.fieldName,
          value: columns.browser.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.visits.fieldName,
          value: columns.data_columns.visits.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.pagesPerVisit.fieldName,
          value: columns.data_columns.pagesPerVisit.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.bounceRate.fieldName,
          value: columns.data_columns.bounceRate.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.visitValue.fieldName,
          value: "Visit Value (Predicted)",
          clickable: true,
          isVisible: this.props.campaign?.post_style !== articlesTypeConsts.ads_for_search.name,
        },
      ],
    }
  }

  getSummaryRow = (summaryData) => {
    let columns = this.columns.data_columns
    return {
      cssClasses: ["summary-row"],
      allowRowSelection: false,
      cells: [
        {
          fieldName: "total",
          value: "Total",
        },
        {
          fieldName: columns.visits.fieldName,
          value: columns.visits.defaultFormat(summaryData.visits),
        },
        {
          fieldName: columns.pagesPerVisit.fieldName,
          value: columns.pagesPerVisit.defaultFormat(summaryData.pages_per_visit),
        },
        {
          fieldName: columns.bounceRate.fieldName,
          value: columns.bounceRate.defaultFormat(summaryData.bounce_rate),
        },
        {
          fieldName: columns.visitValue.fieldName,
          value: columns.visitValue.defaultFormat(summaryData.visit_value),
          isVisible: this.props.campaign?.post_style !== articlesTypeConsts.ads_for_search.name,
        },
      ],
    }
  }

  getRow = (browser) => {
    let columns = this.columns

    return {
      cells: [
        {
          fieldName: columns.browser.fieldName,
          value: browser.browser,
        },
        {
          fieldName: columns.data_columns.visits.fieldName,
          value: columns.data_columns.visits.defaultFormat(browser.visits),
        },
        {
          fieldName: columns.data_columns.pagesPerVisit.fieldName,
          value: columns.data_columns.pagesPerVisit.defaultFormat(browser.pages_per_visit),
        },
        {
          fieldName: columns.data_columns.bounceRate.fieldName,
          value: columns.data_columns.bounceRate.defaultFormat(browser.bounce_rate),
        },
        {
          fieldName: columns.data_columns.visitValue.fieldName,
          value: columns.data_columns.visitValue.defaultFormat(browser.visit_value),
          isVisible: this.props.campaign?.post_style !== articlesTypeConsts.ads_for_search.name,
        },
      ],
    }
  }

  render() {
    let header = this.getHeader()
    let rows = []
    let pagination = null

    if (this.props.performanceByBrowser.length > 0) {
      rows.push(this.getSummaryRow(this.props.summaryRow))
    }

    for (
      let i = (this.props.currentPage - 1) * this.props.itemsPerPage;
      i < this.props.currentPage * this.props.itemsPerPage && i < this.props.performanceByBrowser.length;
      i++
    ) {
      let browser = this.props.performanceByBrowser[i]
      rows.push(this.getRow(browser))
    }

    if (this.props.performanceByBrowser.length > this.props.itemsPerPage) {
      pagination = (
        <Pagination
          itemsArr={
            this.props.performanceByBrowser ? this.props.performanceByBrowser.length : this.props.performanceByBrowser
          }
          currentPage={this.props.currentPage}
          itemsPerPage={this.props.itemsPerPage}
          updatePage={(updatedPage) => {
            this.props.dispatch(updateCampaignPopupTablePage(updatedPage, this.tableName))
          }}
        />
      )
    }

    return (
      <div>
        <Table
          header={header}
          rows={rows}
          sort={{
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection,
            sortHandler: this.sortBrowsersBy,
          }}
          scrolling={{
            containerClass: ".campaign-popup-container",
            includeNavigationBar: false,
          }}
          cssClasses={["campaign-popup-list"]}
        />
        {pagination}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sortBy: state.campaignPopupV2.performanceByBrowser.sortBy,
    sortDirection: state.campaignPopupV2.performanceByBrowser.sortDirection,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    performanceByBrowser: state.campaignPopupV2.performanceByBrowser.dataForTable,
    summaryRow: state.campaignPopupV2.performanceByBrowser.summaryRow,
    itemsPerPage: state.campaignPopupV2.performanceByBrowser.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceByBrowser.currentPage,
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByBrowserList)
