import React from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router"
import { AppBar } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import logoAdmin from "../../resources/images/LogoAdmin.png"
import Consts from "../../app/consts"
import { dismissAdminNotification, getAdminNotifications } from "../../navigationBar/navigationBarActions"
import { isAuthenticated } from "../../utils/authUtils"
import AdminNotificationMessageType from "../../common/consts/adminNotificationMessageType"
import AdminNotificationScope from "../../common/consts/adminNotificationScope"

class AdminNavigationBar extends React.Component {
  ADMIN_NOTIFICATION_HEIGHT = 40

  componentWillMount() {
    if (isAuthenticated()) {
      this.activateUpdates(this.props)
    }
  }
  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  activateUpdates = (props) => {
    props.dispatch(getAdminNotifications(AdminNotificationScope.sudo))
    clearInterval(this.intervalId)
    this.intervalId = setInterval(() => {
      if (this.props.isBrowserTabActive) {
        props.dispatch(getAdminNotifications(AdminNotificationScope.sudo))
      }
    }, Consts.NOTIFICATION_CHECK_INTERVAL)
  }

  dismissNotification = (notification) => {
    this.props.dispatch(dismissAdminNotification(notification))
  }

  getNavigationItems = () => {
    let navigationItems = []
    let navigationItemsData = [
      { name: "Users", url: "users" },
      { name: "Roles & Modules", url: "roles" },
      { name: "Messages", url: "messages" },
      { name: "Sites", url: "sites" },
      { name: "Networks", url: "networks" },
    ]

    navigationItemsData.forEach((navItem) => {
      navigationItems.push(
        <Link
          key={navItem.url}
          className={"navigation-item " + (navItem.url === this.props.currentPage ? "selected" : "")}
          to={"/sudo/" + navItem.url}
        >
          {navItem.name}
        </Link>
      )
    })

    return navigationItems
  }

  render() {
    let notifications = []
    let dismissComp = null

    if (this.props.adminNotifications) {
      this.props.adminNotifications.forEach((item, index) => {
        if (AdminNotificationMessageType.idToName[item.severity].isDismissable) {
          dismissComp = (
            <span className="dismiss-button clickable" onClick={() => this.dismissNotification(item)}>
              dismiss
            </span>
          )
        } else {
          dismissComp = null
        }
        notifications.push(
          <div
            className="single-notification"
            key={index}
            style={{
              backgroundColor: AdminNotificationMessageType.idToName[item.severity].color,
              borderBottom: AdminNotificationMessageType.idToName[item.severity].borderBottom,
            }}
          >
            <span
              style={{
                fontSize: AdminNotificationMessageType.idToName[item.severity].fontSize,
                textShadow: AdminNotificationMessageType.idToName[item.severity].textShadow,
              }}
              className="notification-text"
            >
              {item.message}
            </span>
            {dismissComp}
          </div>
        )
      })
    }
    return (
      <AppBar
        classes={{ positionFixed: "appbar" }}
        style={{ marginBottom: 40 + this.ADMIN_NOTIFICATION_HEIGHT * notifications.length }}
      >
        <div className="admin-notification" style={{ height: this.ADMIN_NOTIFICATION_HEIGHT * notifications.length }}>
          {notifications}
        </div>
        <div
          className="navigation-bar admin-navigation-bar"
          style={{ top: this.ADMIN_NOTIFICATION_HEIGHT * notifications.length }}
        >
          <div className="main-margins-1" />
          <div className="admin-navigation-tabs">
            <Link to="/sudo">
              <img className="logo" src={logoAdmin} />
            </Link>
            <div className="navigation d-flex">{this.getNavigationItems()}</div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Link className="back-home" key="back-home" to={"/" + Consts.ALL_NETWORKS_OBJ.NAME + "/overview"}>
              <div>
                <ArrowBack id="back-home-btn" />
                <span id="back-home-txt">Back home</span>
              </div>
            </Link>
          </div>
          <div className="main-margins-1" />
        </div>
      </AppBar>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.app.currentUser,
    currentPage: ownProps.routes[ownProps.routes.length - 1].path,
    isBrowserTabActive: state.app.isBrowserTabActive,
    adminNotifications: state.navigationBar.adminNotifications.active,
  }
}

const connectedNavigationBar = connect(mapStateToProps)(AdminNavigationBar)
export default withRouter(connectedNavigationBar)
