import React from "react"
import TableSortDownIcon from "../resources/svgs/TableSortDownIcon.svg"
import TableSortUpIcon from "../resources/svgs/TableSortUpIcon.svg"
import TableConsts from "../common/components/table/tableConsts"

export function getSortIcon(column, sortedBy, sortDirection) {
  if (column !== sortedBy) {
    return null
  }

  if (sortDirection === TableConsts.DESC) {
    return <TableSortDownIcon className="sort-icon" />
  }
  return <TableSortUpIcon className="sort-icon" />
}
