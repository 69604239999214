import React from "react"
import NumberFormat from "./numberFormat"
import { connect } from "react-redux"
import TableConsts from "./table/tableConsts"

export const defaultRoiColors = { orange_min_value: 1.05, red_min_value: 1.2 }
class CampaignROI extends React.Component {
  getRoiColor = (roiValue) => {
    let color = null
    if (roiValue >= defaultRoiColors.orange_min_value) {
      roiValue < defaultRoiColors.red_min_value ? (color = "orange") : (color = "green")
    } else {
      color = "red"
    }
    return color
  }

  render() {
    let roi = this.props.roi
    let roiTextComponent = null
    let color = this.getRoiColor(roi)

    // Handling "new roi" for live calculations
    let newRoiColor = null
    let newRoiComponent = null

    if (this.props.newRoi) {
      if (this.props.newRoi === "Infinity") {
        newRoiComponent = <div>{TableConsts.EMPTY_CELL}</div>
      } else {
        newRoiColor = this.getRoiColor(this.props.newRoi)
        newRoiComponent = (
          <div className={newRoiColor}>
            <NumberFormat value={Math.round(this.props.newRoi * 100)} displayType="text" suffix="%" />
          </div>
        )
      }
    }

    if (this.props.roiText) {
      roiTextComponent = <div className={"data-cell-small-text"}>{this.props.roiText}</div>
    }

    return (
      <div>
        <div className={"campaign-roi " + color}>
          <NumberFormat value={Math.round(roi * 100)} displayType="text" suffix="%" />
          {newRoiComponent}
        </div>
        {roiTextComponent}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sites: state.app.sites,
  }
}

export default connect(mapStateToProps)(CampaignROI)
