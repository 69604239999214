import { FilterFunctions } from "../filterFunctions"
import Specification from "./Specification"

export class FilterItemSpec extends Specification {
  constructor(filterItem) {
    super()

    this.filterItem = filterItem
  }

  isSatisfied(candidate) {
    let value = this.filterItem.valueResolver(candidate)
    value =
      value && this.filterItem.filterPlacesAfterTheDot ? value.toFixed(this.filterItem.filterPlacesAfterTheDot) : value
    return FilterFunctions[this.filterItem.filterValueType][this.filterItem.filterOperator /* equal/bigger/lower*/](
      value,
      ...this.filterItem.filterValue /*...this.filterItem.params*/
    )
  }
}
