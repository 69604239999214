import React from "react"
import { connect } from "react-redux"
import { chartAxisStyleUpdater, generateChart, generateChartSeries, onLegendItemClick } from "../../../utils/chartUtils"
import ChartColors from "../../../common/consts/chartColors"
import EmptyChartIndicator from "../../../common/components/emptyChartIndicator"
import articlesTypeConsts from "../../../articles/components/articlesType"

const verticalAxes = {
  "sessions-axis": {
    title: { text: "" },
    visible: true,
    selectionIndex: 1,
    id: "sessions-axis",
    labels: { align: "left", x: 3, y: -3 },
  },
  "pps-axis": {
    title: { text: "" },
    visible: false,
    id: "pps-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}" },
  },
  "bounce-rate-axis": {
    title: { text: "" },
    visible: false,
    id: "bounce-rate-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
  },
  "visit-value-axis": {
    title: { text: "" },
    visible: false,
    id: "visit-value-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value:,.0f}" },
  },
}

class CampaignPopupPerformanceByDeviceChart extends React.Component {
  componentDidMount() {
    this.drawChart()
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.drawChart()
    }
  }

  drawChart = () => {
    let generateSeries = (title, dataItem, multiplyDataBy, axisName, visible, color, moreOptions) => {
      let moreChartOptions = Object.assign({}, moreOptions, {
        data: this.props.data.map((item) => {
          return [item.name, item[dataItem] * multiplyDataBy]
        }),
        color: color,
        events: {
          show: function (event) {
            let chart = this.chart,
              series = chart.series,
              i = series.length,
              otherSeries

            while (i--) {
              otherSeries = series[i]
              if (otherSeries != this) {
                if (otherSeries.visible) {
                  otherSeries.hide()
                  verticalAxes[otherSeries.userOptions.yAxis].visible = false
                }
              } else {
                verticalAxes[otherSeries.userOptions.yAxis].visible = true
              }
            }

            onLegendItemClick(event, null)
          },
        },
      })

      return generateChartSeries(title, axisName, visible, moreChartOptions, { showCheckBox: false })
    }

    let chartSpecificOptions = {
      chart: {
        type: "column",
        marginTop: 1,
        marginLeft: 1,
        marginRight: 140,
      },
      legend: {
        enabled: true,
        useHTML: true,
        symbolHeight: 0.001,
        symbolRadius: 0.001,
        symbolWidth: 0.001,
        itemMarginTop: 2,
        itemMarginBottom: 2,
        y: -30,
        x: 10,
        labelFormatter: function () {
          return (
            '<div class="legend-item-wrapper">' +
            '<div class="legend-item-symbol" style="border:5px solid ' +
            this.color +
            '"></div>' +
            '<div class="legend-item-text">' +
            this.name +
            "</div>" +
            "</div>"
          )
        },
      },
      xAxis: {
        type: "category",
      },
      yAxis: [
        verticalAxes["sessions-axis"],
        verticalAxes["pps-axis"],
        verticalAxes["bounce-rate-axis"],
        verticalAxes["visit-value-axis"],
      ],
      series: [
        generateSeries(
          "Visits",
          "visits",
          1,
          "sessions-axis",
          verticalAxes["sessions-axis"].visible,
          ChartColors.SESSIONS
        ),
        generateSeries(
          "Pages Per Visit",
          "pages_per_visit",
          1,
          "pps-axis",
          verticalAxes["pps-axis"].visible,
          ChartColors.PAGES_PER_SESSION,
          { tooltip: { valueDecimals: 2 } }
        ),
        generateSeries(
          "Bounce Rate",
          "bounce_rate",
          1,
          "bounce-rate-axis",
          verticalAxes["bounce-rate-axis"].visible,
          ChartColors.BOUNCE_RATE,
          { tooltip: { valueSuffix: "%", valueDecimals: 1 } }
        ),
        generateSeries(
          `Visit Value${
            this.props?.campaign?.post_style === articlesTypeConsts.ads_for_search.name ? "" : " (Predicted)"
          }`,
          "visit_value",
          1,
          "visit-value-axis",
          verticalAxes["visit-value-axis"].visible,
          ChartColors.ROI,
          { tooltip: { valuePrefix: "$", valueDecimals: 3 } }
        ),
      ],
      tooltip: {
        style: {
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.14)",
        },
        borderColor: "#ffffff",
        backgroundColor: "white",
        borderRadius: 6,
        borderWidth: 0,
        padding: 10,
        shadow: true,
        headerFormat: '<span style="font-size:10px;color:#999999;font-weight:400">{point.key}</span><br/>',
        pointFormat: '<span style="font-size:13px;color:#666666;font-weight:600">{point.y} {series.name}</span>',
      },
      plotOptions: {
        column: {
          borderRadiusTopLeft: 4,
          borderRadiusTopRight: 4,
        },
        series: {
          events: {
            legendItemClick: function (event) {
              if (this.visible) {
                return false
              }
            },
          },
          states: {
            hover: {
              brightness: -0.3,
            },
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: "top",
                verticalAlign: "bottom",
                layout: "horizontal",
              },
              yAxis: {
                labels: {
                  align: "left",
                  x: 0,
                  y: -5,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
            },
          },
        ],
      },
    }

    let chart = generateChart("performance-by-device", chartSpecificOptions)
    chartAxisStyleUpdater(chart)
  }

  componentWillUnmount() {
    Object.keys(verticalAxes).forEach((series, index) => {
      let visible = false
      if (index === 0) {
        visible = true
      }
      verticalAxes[series].visible = visible
    })
  }

  render() {
    let emptyChartIndicator = null

    if (!this.props.hasData) {
      emptyChartIndicator = <EmptyChartIndicator />
    }

    return (
      <div className="campaign-popup-chart-wrapper">
        {emptyChartIndicator}
        <div className="campaign-popup-chart" id="performance-by-device" />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    hasData: state.campaignPopupV2.performanceByDevice.hasData,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByDeviceChart)
