const draftCreationStatus = {
  created: {
    name: "Created",
    value: "CREATED",
  },
  failed: {
    name: "Failed",
    value: "FAILED",
  },
  pending: {
    name: "Pending",
    value: "PENDING",
  },
  in_progress: {
    name: "In Progress",
    value: "IN_PROGRESS",
  },
}

const draftCreationStatusByValue = {}
for (const key in draftCreationStatus) {
  draftCreationStatusByValue[draftCreationStatus[key].value] = draftCreationStatus[key]
}

export { draftCreationStatus, draftCreationStatusByValue }
