import React from "react"
import { MenuItem, MenuList } from "@material-ui/core"

export class ListFilter {
  static getMenu = (filter) => {
    return (mainFilterComponent) => {
      let items = [mainFilterComponent.getIncludeExcludeMenu()]

      filter.getOptions().forEach((option) => {
        items.push(
          <MenuItem
            classes={{ root: "menu-item filter-results d-flex" }}
            key={mainFilterComponent.state.filter.filterType + option.name}
            onClick={(event) =>
              mainFilterComponent.onItemAdded(
                event,
                Object.assign({}, mainFilterComponent.state.filter, {
                  filterValue: [option.value],
                  filterOperator: filter.defaultOperator,
                  filterValueName: option.name,
                })
              )
            }
          >
            <span className="filter-result-text" style={option.textColor ? { color: option.textColor } : null}>
              {option.icon}
              {option.name}
            </span>
            <span className="plus-icon"> + </span>
          </MenuItem>
        )
      })

      return {
        body: (
          <MenuList>
            <MenuItem classes={{ root: "menu-item search-divider" }}>
              {mainFilterComponent.getMenuHeader(mainFilterComponent.getFilterName())}
            </MenuItem>
            {items}
          </MenuList>
        ),
      }
    }
  }

  static getSuggestions = (mainFilterComponent, filter) => {
    let items = []

    if (!filter.showFilter || filter.showFilter()) {
      filter.getOptions().forEach((option) => {
        items.push({
          name: option.name,
          key: filter.filterType + option.value,
          filter: Object.assign({}, filter, {
            filterValue: [option.value],
            filterOperator: filter.defaultOperator,
            filterValueName: option.name,
          }),
        })
      })

      items.push({
        name: filter.filterName,
        key: filter.filterType,
        filter: {
          filterName: filter.filterName,
          handleClick: () => {
            mainFilterComponent.setState(
              {
                search: "",
                filter: Object.assign({}, mainFilterComponent.state.filter, filter, {
                  filterOperator: filter.defaultOperator,
                }),
              },
              () => mainFilterComponent.pushMenu(filter.getMenu(filter))
            )
          },
        },
      })
    }

    return items
  }
}
