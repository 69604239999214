import Consts from "../app/consts"
import CampaignsConsts from "../campaignsV2/campaignsConsts"
import PlayIcon from "../resources/svgs/PlayIcon.svg"
import React from "react"
import PauseIcon from "../resources/svgs/PauseIcon.svg"
import CommonDataFormat from "../common/components/table/commonDataFormat"
import { isNullOrUndefined } from "./funcUtils"
import SubSourcesConsts from "../subSources/subSourcesConsts"

export function buildActionHistoryDisplay(actionHistory, screen) {
  let icon = null
  let text = null
  const actionType = actionHistory.action_type
  const previousValue = actionHistory.old_value
  const newValue = actionHistory.new_value
  const isSuccessful = actionHistory.status === true
  const errorMessage = actionHistory.failure_message

  switch (actionType) {
    case Consts.ACTION_TYPE_STATUS: {
      if (newValue === CampaignsConsts.CAMPAIGN_STATUS_RUNNING) {
        if (isSuccessful) {
          icon = <PlayIcon className={"campaign-status-icon play-icon"} />
          text = "Campaign activated"
        } else {
          text = "Failed to activate"
        }
      } else if (newValue === CampaignsConsts.CAMPAIGN_STATUS_PAUSED) {
        if (isSuccessful) {
          icon = <PauseIcon className={"campaign-status-icon pause-icon"} />
          text = "Campaign paused"
        } else {
          text = "Failed to pause"
        }
      }
      break
    }
    case Consts.ACTION_TYPE_BID: {
      const previousBid = CommonDataFormat.floatCurrency(previousValue, 3)
      const newBid = CommonDataFormat.floatCurrency(newValue, 3)
      if (isSuccessful) {
        text = (
          <>
            Bid changed from {previousBid} to {newBid}
          </>
        )
      } else {
        text = (
          <>
            Failed to change bid from {previousBid} to {newBid}
          </>
        )
      }
      break
    }
    case Consts.ACTION_TYPE_ROAS: {
      const previousRoas = CommonDataFormat.intPercentage(previousValue)
      const newRoas = CommonDataFormat.intPercentage(newValue)
      if (isSuccessful) {
        text = (
          <>
            Roas changed from {previousRoas} to {newRoas}
          </>
        )
      } else {
        text = (
          <>
            Failed to change roas from {previousRoas} to {newRoas}
          </>
        )
      }
      break
    }
    case Consts.ACTION_TYPE_BUDGET: {
      const previousBudget = CommonDataFormat.floatCurrency(previousValue)
      const newBudget = CommonDataFormat.floatCurrency(newValue)
      if (isSuccessful) {
        text = (
          <>
            Daily budget changed from {previousBudget} to {newBudget}
          </>
        )
      } else {
        text = (
          <>
            Failed to change daily budget from {previousBudget} to {newBudget}
          </>
        )
      }
      break
    }
    case Consts.ACTION_TYPE_SUB_SOURCE_BID: {
      if (isSuccessful) {
        const sourceName = newValue.split(":")[0]
        const prevSourceBid = CommonDataFormat.floatCurrency(previousValue.split(":")[1])
        const newSourceBid = CommonDataFormat.floatCurrency(newValue.split(":")[1])

        text = (
          <>
            Sub-Source Bid: {sourceName} changed from {prevSourceBid} to {newSourceBid}
          </>
        )
      }
      break
    }
    case Consts.ACTION_TYPE_SUB_SOURCE_BLOCK: {
      if (
        isSuccessful &&
        (screen === "actionHistory" || isNullOrUndefined(actionHistory.additional_info?.sub_sources))
      ) {
        const blockedSubSources = newValue - previousValue
        if (!isNaN(blockedSubSources)) {
          text = <>Blocked {blockedSubSources} sources</>
        }
      }
      break
    }
    case Consts.ACTION_TYPE_SUB_SOURCE_STATUS: {
      if (isSuccessful) {
        const sourceName = newValue.split(":")[0]
        const newStatus = newValue.split(":")[1]
        if (newStatus === SubSourcesConsts.SUB_SOURCE_STATUS_RUNNING) {
          text = `Activated: "${sourceName}"`
        } else if (newStatus === SubSourcesConsts.SUB_SOURCE_STATUS_PAUSED) {
          text = `Blocked: "${sourceName}"`
        }
      }
      break
    }
  }

  return (
    <>
      {icon}
      <div className="action-text">
        <span data-tip={errorMessage} data-class="tooltip-custom wide">
          {text}
        </span>
      </div>
    </>
  )
}
