import React from "react"

let TableConsts = {
  ASC: "ASC",
  DESC: "DESC",

  EMPTY_CELL: <span>--</span>,
}

Object.freeze(TableConsts)
export default TableConsts
