import React from "react"
import { connect } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import CampaignPopupPerformanceByKeywordList from "./lists/performanceByKeywordList"
import CampaignPopupPerformanceByKeywordChart from "./charts/performanceByKeywordChart"
import {
  campaignPopupLoading,
  fetchCampaignPerformanceByKeywordAsync,
  resetCampaignPopupTable,
} from "../campaignPopupActions"
import RequestsService from "../../api/requestsService"

class CampaignPopupPerformanceByKeyword extends React.Component {
  constructor() {
    super()
    this.state = {
      mainSubSection: "clicks",
    }
  }

  componentDidMount() {
    this.getPerformanceByKeyword()
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      JSON.stringify(prevProps.datePickerDates.startDate) !== JSON.stringify(this.props.datePickerDates.startDate) ||
      JSON.stringify(prevProps.datePickerDates.endDate) !== JSON.stringify(this.props.datePickerDates.endDate)
    ) {
      this.getPerformanceByKeyword()
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetCampaignPopupTable("performanceByKeyword"))
    RequestsService.cancelRequest("performance-by-keyword-request")
  }

  getPerformanceByKeyword = () => {
    RequestsService.cancelRequest("performance-by-keyword-request")
    this.props.dispatch(campaignPopupLoading())
    this.props.dispatch(
      fetchCampaignPerformanceByKeywordAsync(
        this.props.campaign,
        this.props.datePickerDates.startDate,
        this.props.datePickerDates.endDate
      )
    )
  }

  render() {
    let loadingIndicator = null
    let performanceByKeyword = null

    if (this.props.isLoading) {
      loadingIndicator = (
        <div className="campaign-popup-loading d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      performanceByKeyword = (
        <div className="main-body-1" style={{ width: "100%" }}>
          <div className="d-flex align-items-center main-keyword-sections-switcher">
            Daily breakdown by keyword's:
            <ul className="keyword-sections-switcher">
              <li
                className={"clickable " + (this.state.mainSubSection === "searched" ? "selected" : "")}
                onClick={() => this.setState({ mainSubSection: "searched" })}
              >
                Searches
              </li>
              <li
                className={"clickable " + (this.state.mainSubSection === "clicks" ? "selected" : "")}
                onClick={() => this.setState({ mainSubSection: "clicks" })}
              >
                Clicks
              </li>
            </ul>
          </div>
          <div className="campaign-popup-performance-by-Keyword">
            <CampaignPopupPerformanceByKeywordChart />
            <CampaignPopupPerformanceByKeywordList mainSubSection={this.state.mainSubSection} />
          </div>
        </div>
      )
    }

    return (
      <>
        {loadingIndicator}
        {performanceByKeyword}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    isLoading: state.campaignPopupV2.isLoading,
    performanceByKeyword: state.campaignPopupV2.performanceByKeyword.dataForTable,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByKeyword)
