import React from "react"
import { connect } from "react-redux"
import { campaignPopupSortPerformanceList, updateCampaignPopupTablePage } from "../../campaignPopupActions"
import Table from "../../../common/components/table/table"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import Moment from "moment"
import Consts from "../../../app/consts"
import { isNullOrUndefined } from "../../../utils/funcUtils"
import PopupReportNoItems from "../popupReportNoItems"

class CampaignPopupPerformanceByKeywordList extends React.Component {
  columns = CampaignsConsts.LIST_COLUMNS
  scrollersObject = null

  constructor(props) {
    super(props)

    this.tableName = "performanceByKeyword"
  }

  sortCountriesBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, "performanceByKeyword"))
  }

  getHeader = () => {
    let columns = this.columns
    let cells = [
      {
        fieldName: columns.keyword.fieldName,
        value: columns.keyword.title,
        clickable: true,
        cellSize: columns.keyword.cellSize,
      },
    ]

    Object.keys(this.props.totelPerDay).forEach((date) => {
      cells.push({
        fieldName: columns.date.fieldName,
        value: (
          <>
            <span>{Moment(date, Consts.SERVER_DATE_FORMAT).format(Consts.CLIENT_DATE_FORMAT)}</span>
          </>
        ),
        cssClasses: ["date-cell", "font-semibold"],
        cellSize: columns.tableValue.cellSize,
      })
    })

    return {
      stickyCells: [],
      cells: cells,
    }
  }

  getSummaryRow = (summaryData) => {
    let columns = this.columns

    let cells = [
      {
        fieldName: columns.data_columns.rpc.fieldName,
        value: "Avg. RPC",
        cellSize: columns.keyword.cellSize,
        cssClasses: ["date-cell", "font-semibold"],
      },
    ]

    Object.values(summaryData).forEach((day) => {
      cells.push({
        fieldName: columns.data_columns.rpc.fieldName,
        value: columns.data_columns.rpc.defaultFormat(day.rpc),
        cellSize: columns.tableValue.cellSize,
      })
    })

    return {
      cssClasses: ["summary-row"],
      allowRowSelection: false,
      cells: cells,
    }
  }

  getRow = (keywordRow, keyword, totelPerDay, mainSubSection) => {
    let columns = this.columns
    let cells = [
      {
        fieldName: columns.keyword.fieldName,
        value: keyword,
        cellSize: columns.keyword.cellSize,
        cssClasses: ["date-cell", "font-semibold"],
      },
    ]

    Object.keys(totelPerDay).forEach((day) => {
      cells.push({
        fieldName: mainSubSection,
        value: columns.tableValue.defaultFormat(
          day in keywordRow ? (keywordRow[day][mainSubSection] * 100) / totelPerDay[day][mainSubSection] : 0
        ),
        cellSize: columns.tableValue.cellSize,
      })
    })

    return {
      cells: cells,
    }
  }

  render() {
    let header = this.getHeader()
    let rows = []
    if (!isNullOrUndefined(this.props.summaryRow)) {
      rows.push(this.getSummaryRow(this.props.summaryRow))
    }

    let performanceByKeyword = this.props.performanceByKeyword
    let mainSubSection = this.props.mainSubSection.toLowerCase()
    let totelPerDay = this.props.totelPerDay
    Object.keys(this.props.performanceByKeyword).forEach((keyword) => {
      rows.push(this.getRow(performanceByKeyword[keyword], keyword, totelPerDay, mainSubSection))
    })

    if (Object.keys(performanceByKeyword).length === 0) {
      return <PopupReportNoItems />
    }

    return (
      <div>
        <Table
          header={header}
          rows={rows}
          sort={{
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection,
            sortHandler: this.sortCountriesBy,
          }}
          scrolling={{
            containerClass: ".campaign-popup-container",
            includeNavigationBar: false,
          }}
          cssClasses={["campaign-popup-list"]}
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sortBy: state.campaignPopupV2.performanceByKeyword.sortBy,
    totelPerDay: state.campaignPopupV2.performanceByKeyword.totelPerDay,
    sortDirection: state.campaignPopupV2.performanceByKeyword.sortDirection,
    performanceByKeyword: state.campaignPopupV2.performanceByKeyword.dataForTable,
    summaryRow: state.campaignPopupV2.performanceByKeyword.summaryRow,
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByKeywordList)
