import React from "react"
import TableConsts from "../common/components/table/tableConsts"
import AutomationsConsts from "./automationsConsts"
import { buildActionChangeText, buildConditionChipItems } from "../utils/automationUtils"

let AutomationsTableConsts = {
  headerColumns: {
    status: { title: "Status", fieldName: "status", classList: "cell x-small mobile-campaign-status" },
    source: { title: "Source", fieldName: "provider_name", classList: "cell small font-semibold" },
    actionType: { title: "Action Type", fieldName: "action_type", classList: "cell small font-semibold" },
    name: { title: "Rules", fieldName: "name", classList: "cell large" },
  },
  dataColumns: {
    actionChange: {
      title: "Action Change",
      isDefaultField: true,
      fieldName: "action",
      tooltip: "<div><strong>Action Change</strong> is the rule action.</div>",
      customFormat: (automation) => {
        if (automation.actionValue) {
          return buildActionChangeText(automation.actionType, automation.actionValue, automation.valueType)
        }

        return TableConsts.EMPTY_CELL
      },
      cellSize: "small-medium",
    },

    frequency: {
      title: "Frequency",
      isDefaultField: true,
      fieldName: "frequency",
      tooltip:
        "<div><strong>Frequency</strong> is the rule action repetition. It can be every hour or once a day " +
        "(as soon as conditions are met)</div>",
      customFormat: (automation) => {
        if (automation.frequency) {
          return AutomationsConsts.FREQUENCY_VALUES[automation.frequency]
        }
        return TableConsts.EMPTY_CELL
      },
    },

    conditions: {
      title: "Conditions",
      isDefaultField: true,
      fieldName: "conditions",
      customFormat: (automation) => {
        const serializedAutomation = JSON.stringify(automation)
        const conditions = buildConditionChipItems(automation, true, "condition-chip-item")
        if (conditions.length > 0) {
          return (
            <span>
              {conditions.slice(0, 2)}

              {conditions.length < 3 ? null : (
                <>
                  <span
                    className="last-tags"
                    data-class="tooltip-custom tags-tooltip"
                    data-place="top"
                    data-tip={serializedAutomation}
                    data-for="rules-tooltip"
                  >
                    <span style={{ whiteSpace: "nowrap" }}>+{conditions.length - 2} more...</span>
                  </span>
                </>
              )}
            </span>
          )
        }
        return TableConsts.EMPTY_CELL
      },
      cellSize: "large",
      cssClasses: ["aligned-left", "conditions-rule", "font-semibold"],
      headerCssClasses: ["aligned-left", "font-semibold"],
    },

    timeFrame: {
      title: "Time Frame",
      isDefaultField: true,
      fieldName: "time_frame",
      customFormat: (automation) => {
        if (automation.time_frame) {
          return AutomationsConsts.TIME_FRAME_VALUES[automation.time_frame]
        }
        return TableConsts.EMPTY_CELL
      },
      cssClasses: ["last-child"],
      headerCssClasses: ["last-child"],
    },
  },
  actionHistoryColumns: {
    time: { title: "Date", fieldName: "created_at", cellSize: "medium", cssClasses: ["no-grow"] },
    campaign: {
      title: "Campaign",
      fieldName: "campaign_name",
      cellSize: "x-large",
      cssClasses: [],
    },
    action: {
      title: "Action",
      fieldName: "action",
      cellSize: "large",
      cssClasses: ["no-grow"],
    },
  },
}

Object.freeze(AutomationsTableConsts)
export default AutomationsTableConsts
