import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../common/components/genericDropdown"
import { isNullOrUndefined } from "../../../utils/funcUtils"

class NetworkDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.menuItems = this.props.networks.map((networkItem) => {
      return { name: networkItem.data[0].network.name, code: networkItem.data[0].network.code }
    })

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: this.menuItems,
      selectedIndexesArr: this.getInitialSelectedIndexes(),
    }
  }

  componentDidMount() {
    const networkToSelect = !isNullOrUndefined(this.props.selectedNetwork?.code)
      ? this.props.selectedNetwork
      : this.props.currentNetwork
    this.updateSelectedNetwork(networkToSelect)
  }

  getInitialSelectedIndexes() {
    if (this.menuItems.length === 1) {
      return [0]
    }
    return []
  }

  updateSelectedNetwork(selectedNetwork) {
    const selectedIndex = this.menuItems.findIndex((item) => item.code === selectedNetwork.code)
    if (selectedIndex !== -1) {
      this.handleMenuClick([selectedIndex])
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (index) => {
    this.setState({
      selectedIndexesArr: index,
    })

    const selectedNetwork = this.state.menuItems[index]
    this.props.handleSelect("selectedNetwork", selectedNetwork)

    this.handleMenuClose()
  }

  render() {
    if (this.props.networks.length > 1) {
      let header = "Choose network"
      if (this.state.selectedIndexesArr.length > 0) {
        header = this.state.menuItems[this.state.selectedIndexesArr[0]].name
      }

      return (
        <div className={"text-field " + (this.props.networkValidationErrors ? "error " : "")}>
          <div className="automation-creation-network-label">Network</div>
          <GenericDropdown
            header={header}
            selectOptionCallback={this.handleMenuClick}
            options={this.state.menuItems}
            open={this.state.menuOpen}
            anchorEl={this.state.menuAnchorEl}
            selectedIndexes={this.state.selectedIndexesArr}
            openDropdownCallback={this.handleMenuOpen}
            closeDropdownCallback={this.handleMenuClose}
            allowNoSelection={false}
            buttonClassName={"creation-generic-dropdown-button darker-text "}
            menuClassName={"creation-generic-dropdown-menu"}
          />
          {this.props.networkValidationErrors}
        </div>
      )
    }
    return null
  }
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(NetworkDropdown)
