import React from "react"
import Moment from "moment"
import CommonDataFormat from "../common/components/table/commonDataFormat"
import {
  getBrowserName,
  getOperatingSystemName,
  getPlatformText,
  isUnknownCampaignTrafficSource,
  momentDateTimeAndUnixTimeStamp,
} from "../utils/campaignUtilsV2"
import Consts from "../app/consts"
import TableConsts from "../common/components/table/tableConsts"
import { getShortMomentFromNow, isNullOrUndefined } from "../utils/funcUtils"
import CampaignROI from "../common/components/campaignRoi"
import CampaignBidPopup from "./components/campaignBidComponents/campaignBidPopup"
import CampaignsConsts from "./campaignsConsts"
import { store } from "../app/store"
import { changeDailyBudgetAsync, changeTotalBudgetAsync } from "./campaignActions"
import StepperPopup from "../common/components/stepperPopup"
import AuthApi from "../api/authService"
import ModuleTypes from "../common/consts/moduleTypes"
import AdTypes from "../common/consts/adTypes"
import { getCountryTextV2 } from "../utils/countryUtils"
import NumberFormat from "../common/components/numberFormat"
import { getLangNameFromCode } from "language-name-map"
import AddImageIcon from "../resources/svgs/AddImageIcon.svg"

let CampaignsTableConsts = {
  bulk_selection: {
    title: "Bulk Selection",
    fieldName: "bulk_selection",
    classList: "cell x-small mobile-campaign-selection",
  },
  header_columns: {
    status: { title: "Status", fieldName: "campaign_status", classList: "cell x-small mobile-campaign-status" },
    siteName: { title: "Site", fieldName: "site_id", classList: "cell x-small", showOnSiteMode: false },
    campaign: { title: "Campaign", fieldName: "tracking_code", classList: "cell big" },
  },
  export_only_columns: {
    reportDateFrom: {
      title: "From Date",
      rawValue: (campaign, context) => {
        return context.props.datePickerDates.startDate.format(Consts.SERVER_DATE_FORMAT)
      },
      isVisibleForAdvancedSearch: false,
    },
    reportDateTo: {
      title: "To Date",
      rawValue: (campaign, context) => {
        return context.props.datePickerDates.endDate.format(Consts.SERVER_DATE_FORMAT)
      },
      isVisibleForAdvancedSearch: false,
    },
    provider: {
      title: "Source",
      fieldName: "provider_name",
      rawValue: (campaign) => campaign.provider_name,
    },
    platform: {
      title: "Device",
      fieldName: "platform",
      rawValue: (campaign) => getPlatformText(campaign.device_platforms),
    },
    country: {
      title: "Country",
      fieldName: "country_code",
      rawValue: (campaign) => getCountryTextV2(campaign.countries),
    },
    language: {
      title: "Language",
      fieldName: "language",
      rawValue: (campaign) => {
        return campaign.targeting?.locales ? campaign.targeting?.locales[0] : null
      },
    },
    articleLink: {
      title: "Article Link",
      fieldName: "url",
      rawValue: (campaign) => campaign.link_url,
    },
    learningStatus: {
      title: "Learning status",
      fieldName: "provider_additional_info.learning_stage_info",
      rawValue: (campaign) => {
        if (campaign.learning_stage_info?.status) return campaign.learning_stage_info.status
        return null
      },
      isVisibleForAdvancedSearch: false,
    },
  },
  data_columns: {
    bid: {
      title: "Current Bid",
      isDefaultField: true,
      fieldName: "bid",
      tooltip: "<div><strong>Current Bid</strong> is the campaign’s current bid per action.</div>",
      rawValue: (campaign) => campaign?.bid,
      defaultFormat: (bid) => {
        if (bid) {
          return CommonDataFormat.intFormat(bid)
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (!isUnknownCampaignTrafficSource(campaign)) {
          return <CampaignBidPopup campaign={campaign} showRoasIndicator />
        }
        return TableConsts.EMPTY_CELL
      },
    },
    dailyBudget: {
      title: "Daily Budget",
      isDefaultField: true,
      fieldName: "daily_budget",
      tooltip:
        "<div><strong>Daily Budget</strong> is the daily budget of the campaign at the source which is will be renewed every day depends on the account timezone settings.</div>",
      rawValue: (campaign) => campaign?.daily_budget,
      defaultFormat: CommonDataFormat.floatCurrency,
      campaignCustomFormat: (campaign) => {
        return (
          <StepperPopup
            startingValue={campaign.daily_budget}
            currency={Consts.DEFAULT_CURRENCY}
            isAllowedToChange={
              !Consts.PROVIDERS_THAT_CANT_CHANGE_BUDGET.includes(campaign.provider_id) &&
              AuthApi.hasModule(ModuleTypes.EDIT_CAMPAIGN)
            }
            stepSize={50}
            campaign={campaign}
            isLoading={campaign.isCampaignDailyBudgetLoading}
            onSubmitClicked={(value, valueInUSD) => {
              store.dispatch(changeDailyBudgetAsync(campaign, value, valueInUSD))
            }}
          />
        )
      },
    },
    visits: {
      title: "Visits",
      isDefaultField: true,
      fieldName: "visits",
      tooltip: "<div><strong>Visits</strong> is the number of measured visits.</div>",
      rawValue: (campaign) => campaign?.visits,
      defaultFormat: (visits) => {
        if (visits) {
          return CommonDataFormat.intFormat(visits)
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign, context) => {
        if (campaign?.visits) {
          return CommonDataFormat.intFormat(campaign.visits)
        }
        return TableConsts.EMPTY_CELL
      },
    },
    clicks: {
      title: "Clicks",
      isDefaultField: true,
      fieldName: "clicks",
      tooltip: "<div><strong>Clicks</strong> is the number of paid clicks on the source.</div>",
      rawValue: (campaign) => campaign.clicks,
      defaultFormat: (clicks) => {
        return CommonDataFormat.intFormat(clicks)
      },
      campaignCustomFormat: (campaign, context) => {
        let clicks = campaign.clicks

        if (!isNullOrUndefined(clicks)) {
          return CommonDataFormat.intFormat(clicks)
        }

        return TableConsts.EMPTY_CELL
      },
    },
    profit: {
      title: "Profit",
      isDefaultField: true,
      fieldName: "profit",
      tooltip: "<div><strong>Profit</strong> is the profit generated by the total visits.</div>",
      rawValue: (campaign) => campaign.profit,
      defaultFormat: (profit) => {
        return CommonDataFormat.intCurrency(profit)
      },
      campaignCustomFormat: (campaign) => {
        if (!isNullOrUndefined(campaign.profit)) {
          return CommonDataFormat.intCurrency(Math.round(campaign.profit))
        }
        return TableConsts.EMPTY_CELL
      },
    },
    roi: {
      title: "ROI",
      isDefaultField: true,
      fieldName: "roi",
      tooltip: "<div><strong>ROI</strong> is the return on investment, calculated as Revenue divided Spend.</div>",
      rawValue: (campaign) => campaign.roi,
      defaultFormat: (roi) => {
        if (!isNullOrUndefined(roi)) {
          return <CampaignROI roi={roi} />
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign, context) => {
        if (!isNullOrUndefined(campaign.roi)) {
          return (
            <CampaignROI
              roi={campaign.roi}
              newRoi={campaign.newRoi}
              siteId={campaign.site_id}
              roiColors={context.props.roiColors}
            />
          )
        }
        return TableConsts.EMPTY_CELL
      },
    },
    dailySpent: {
      title: "Spend",
      isDefaultField: true,
      fieldName: "daily_spent",
      showExceptOnRealtime: true,
      tooltip: "<div><strong>Spend</strong> is the cost of all visits.</div>",
      rawValue: (campaign) => campaign.daily_spent,
      defaultFormat: (daily_spent) => {
        return CommonDataFormat.floatCurrency(daily_spent, 2)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatCurrency(campaign.daily_spent, 2)
      },
    },
    revenue: {
      title: "Revenue",
      isDefaultField: true,
      fieldName: "revenue",
      tooltip: "<div><strong>Revenue</strong> is the user value times the visit.</div>",
      rawValue: (campaign) => campaign.revenue,
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatCurrency(campaign.revenue, 2)
      },
      defaultFormat: (revenue) => {
        return CommonDataFormat.floatCurrency(revenue, 2)
      },
    },
    clickValue: {
      title: "Click Value",
      isDefaultField: true,
      fieldName: "click_value",
      tooltip: "<div><strong>Click Value</strong> is the average value of a click.</div>",
      rawValue: (campaign) => campaign.click_value,
      defaultFormat: CommonDataFormat.floatCurrency,
      campaignCustomFormat: (campaign) => {
        if (!isNullOrUndefined(campaign.click_value)) {
          return CommonDataFormat.floatCurrency(campaign.click_value, 3)
        }
        return TableConsts.EMPTY_CELL
      },
    },
    costPerClick: {
      title: "Avg. CPC",
      isDefaultField: true,
      fieldName: "cost_per_click",
      tooltip: "<div><strong>Avg. CPC</strong> is the Average Cost per Click paid recently.</div>",
      rawValue: (campaign) => campaign.cost_per_click,
      defaultFormat: (costPerClick) => {
        return CommonDataFormat.floatCurrency(costPerClick, 3)
      },
      campaignCustomFormat: (campaign, context) => {
        if (campaign.cost_per_click) {
          return CommonDataFormat.floatCurrency(campaign.cost_per_click, 3)
        }
        return TableConsts.EMPTY_CELL
      },
    },
    visitValue: {
      title: "Visit Value",
      isDefaultField: true,
      fieldName: "visit_value",
      tooltip: "<div><strong>Visit Value</strong> is the total revenue measured per average visit.</div>",
      rawValue: (campaign) => campaign.visit_value,
      defaultFormat: (userValue) => {
        if (!isNullOrUndefined(userValue)) {
          return CommonDataFormat.floatCurrency(userValue, 3)
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (!isNullOrUndefined(campaign.visit_value)) {
          return CommonDataFormat.floatCurrency(campaign.visit_value, 3)
        }
        return TableConsts.EMPTY_CELL
      },
    },
    visitDuration: {
      title: "Visit Duration",
      isDefaultField: false,
      fieldName: "visit_duration",
      tooltip:
        "<div><strong>Visit Duration</strong> is the average time (Hours:Minutes:Seconds) visitors spent in the campaigns.</div>",
      rawValue: (campaign) => campaign.visit_duration,
      defaultFormat: (visit_duration) => {
        return visit_duration
          ? Moment.utc(visit_duration * 1000).format(Consts.SERVER_TIME_FORMAT)
          : TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        return campaign.visit_duration
          ? Moment.utc(campaign.visit_duration * 1000).format(Consts.SERVER_TIME_FORMAT)
          : TableConsts.EMPTY_CELL
      },
    },
    pagesPerVisit: {
      title: "Pages Per Visit",
      isDefaultField: true,
      fieldName: "pages_per_visit",
      tooltip:
        "<div><strong>Pages Per Visit</strong> shows the average pages the users saw.This metric have a strong effect on the Visit Value.</div>",
      rawValue: (campaign) => campaign.pages_per_visit,
      defaultFormat: (pagesPerVisit) => {
        return CommonDataFormat.floatFormat(pagesPerVisit, 2)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatFormat(campaign.pages_per_visit, 2)
      },
    },
    bounceRate: {
      title: "Bounce Rate",
      isDefaultField: true,
      fieldName: "bounce_rate",
      tooltip:
        "<div><strong>Bounce Rate</strong> is the percentage of visitors who navigate away from the site after viewing only one page. This metric will have a significant effect on your pages per visit metric.</div>",
      rawValue: (campaign) => campaign.bounce_rate * 100,
      defaultFormat: (bounceRate) => {
        return CommonDataFormat.floatPercentage(bounceRate * 100, 1)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatPercentage(campaign.bounce_rate * 100, 1)
      },
    },
    rpm: {
      title: "RPM",
      isDefaultField: true,
      fieldName: "rpm",
      tooltip: "<div><strong>RPM</strong> is the average revenue generated per 1000 website ad impressions.</div>",
      rawValue: (campaign) => campaign.rpm,
      defaultFormat: (rpm) => {
        return CommonDataFormat.floatCurrency(rpm, 2)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatCurrency(campaign.rpm, 2)
      },
    },
    affiliateItemRpm: {
      title: "Affiliate Item RPM",
      isDefaultField: false,
      fieldName: "affiliate_item_rpm",
      tooltip: "<div><strong>Affiliate Item RPM</strong> is the revenue per 1000 Items viewed.</div>",
      rawValue: (campaign) => campaign.affiliate_item_rpm,
      defaultFormat: (rpmAffiliateItem) => {
        return CommonDataFormat.floatCurrency(rpmAffiliateItem, 2)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatCurrency(campaign.affiliate_item_rpm, 2)
      },
    },
    ctr: {
      title: "CTR",
      isDefaultField: true,
      fieldName: "ctr",
      tooltip:
        "<div><strong>Click Through Rate</strong> is the percentage of users who clicked through your ads.</div>",
      rawValue: (campaign) => campaign.ctr * 100,
      defaultFormat: (ctr) => {
        if (ctr) {
          return CommonDataFormat.floatPercentage(ctr * 100, 2)
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (campaign.ctr) {
          return CommonDataFormat.floatPercentage(campaign.ctr * 100, 2)
        }
        return TableConsts.EMPTY_CELL
      },
    },
    results: {
      title: "Results",
      isDefaultField: true,
      fieldName: "results",
      tooltip: "<div><strong>Results</strong> is the number of conversions that were tracked by Facebook</div>",
      rawValue: (campaign) => campaign.results,
      defaultFormat: (results) => {
        return CommonDataFormat.intFormat(results)
      },
      campaignCustomFormat: (campaign, context) => {
        if (!isUnknownCampaignTrafficSource(campaign) && !isNullOrUndefined(campaign.results)) {
          return <div className="cell-filler">{CommonDataFormat.intFormat(campaign.results)}</div>
        }
        return TableConsts.EMPTY_CELL
      },
    },
    costPerResult: {
      title: "Cost Per Result",
      isDefaultField: false,
      fieldName: "cost_per_result",
      tooltip: "<div><strong>Cost Per Result</strong> is the average cost for a result.</div>",
      rawValue: (campaign) => campaign.cost_per_result,
      defaultFormat: CommonDataFormat.floatCurrency,
      campaignCustomFormat: (campaign) => {
        if (!isNullOrUndefined(campaign.cost_per_result)) {
          return CommonDataFormat.floatCurrency(campaign.cost_per_result, 3)
        }
        return TableConsts.EMPTY_CELL
      },
    },
    adType: {
      title: "Ad Type",
      isDefaultField: false,
      fieldName: "ad_type",
      tooltip: "<div><strong>Ad Type</strong> is the type of Facebook creatives in this campaign.</div>",
      rawValue: (campaign) => {
        if (!isNullOrUndefined(campaign.ad_type)) {
          return AdTypes.idToType[campaign.ad_type]?.name
            ? AdTypes.idToType[campaign.ad_type]?.name
            : AdTypes.regular_ad.name
        }
        return AdTypes.regular_ad.name
      },
      defaultFormat: (ad_type) => {
        if (!isNullOrUndefined(ad_type)) {
          return AdTypes.idToType[ad_type]?.name ? AdTypes.idToType[ad_type]?.name : AdTypes.regular_ad.name
        }
        return AdTypes.regular_ad.name
      },
      campaignCustomFormat: (campaign) => {
        if (!isNullOrUndefined(campaign.ad_type)) {
          return AdTypes.idToType[campaign.ad_type]?.name
            ? AdTypes.idToType[campaign.ad_type]?.name
            : AdTypes.regular_ad.name
        }
        return TableConsts.EMPTY_CELL
      },
    },
    totalBudget: {
      title: "Total Budget",
      isDefaultField: false,
      fieldName: "total_budget",
      tooltip: "<div><strong>Total Budget</strong> is the lifetime budget of the campaign set at the source.</div>",
      rawValue: (campaign) => campaign?.total_budget,
      defaultFormat: (total_budget) => {
        if (total_budget) {
          return CommonDataFormat.intCurrency(total_budget)
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (Consts.PROVIDERS_WITH_TOTAL_BUDGET.includes(campaign.provider_id)) {
          return (
            <StepperPopup
              startingValue={campaign.total_budget}
              currency={Consts.DEFAULT_CURRENCY}
              isAllowedToChange={
                !Consts.PROVIDERS_THAT_CANT_CHANGE_BUDGET.includes(campaign.provider_id) &&
                AuthApi.hasModule(ModuleTypes.EDIT_CAMPAIGN)
              }
              stepSize={50}
              campaign={campaign}
              isLoading={campaign.isCampaignTotalBudgetLoading}
              onSubmitClicked={(value, valueInUSD) => {
                store.dispatch(changeTotalBudgetAsync(campaign, value, valueInUSD))
              }}
            />
          )
        }
        return TableConsts.EMPTY_CELL
      },
    },
    providerCreatedAt: {
      title: "Creation Date",
      isDefaultField: false,
      fieldName: "provider_created_at",
      tooltip:
        "<div><strong>Creation Date</strong> is the date the campaign was successfully created at the source.</div>",
      comparable: false,
      rawValue: (campaign) =>
        campaign.provider_created_at
          ? Moment(campaign.provider_created_at, "YYYY-MM-DD HH:mm:ss:000000").toISOString()
          : null,
      defaultFormat: (providerCreatedAt) => {
        return providerCreatedAt
          ? Moment(providerCreatedAt, "YYYY-MM-DD 00:00:00:000000").format(Consts.CLIENT_SHORT_DATE_FORMAT)
          : TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        return campaign.provider_created_at
          ? Moment(campaign.provider_created_at, "YYYY-MM-DD 00:00:00:000000").format(Consts.CLIENT_SHORT_DATE_FORMAT)
          : TableConsts.EMPTY_CELL
      },
    },
    pageViews: {
      title: "Page Views",
      isDefaultField: false,
      fieldName: "page_views",
      tooltip:
        "<div><strong>Page Views</strong> are the total number of pages that were views by the visitors on the campaign.</div>",
      rawValue: (campaign) => campaign.page_views,
      defaultFormat: (pageViews) => {
        return CommonDataFormat.intFormat(pageViews)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.intFormat(campaign.page_views)
      },
    },
    visitProfit: {
      title: "Visit Profit",
      isDefaultField: false,
      fieldName: "visit_profit",
      tooltip: "<div><strong>Visit Profit</strong> is calculated as the visit value minus the cost of visit.</div>",
      rawValue: (campaign) => campaign.visit_profit,
      defaultFormat: (visitProfit) => {
        if (!isNullOrUndefined(visitProfit)) {
          return CommonDataFormat.floatCurrency(visitProfit, 3)
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (!isNullOrUndefined(campaign.visit_profit)) {
          return CommonDataFormat.floatCurrency(campaign.visit_profit, 3)
        }
        return TableConsts.EMPTY_CELL
      },
    },
    lastModified: {
      title: "Last Modified",
      isDefaultField: false,
      fieldName: "last_modified",
      tooltip:
        "<div><strong>Last modified</strong> is the most recent time someone changed this campaign's bid, budget, status, etc.</div>",
      comparable: false,
      cellSize: "medium",
      rawValue: (campaign) =>
        campaign.last_modified ? momentDateTimeAndUnixTimeStamp(campaign.last_modified).toISOString() : "",
      defaultFormat: (lastModified) => {
        let now = Moment()
        if (lastModified) {
          let lastModifiedTime = momentDateTimeAndUnixTimeStamp(lastModified)
          lastModifiedTime = Moment.min(now, lastModifiedTime)
          lastModified = getShortMomentFromNow(lastModifiedTime.fromNow())
          let lastModifiedTooltip = lastModifiedTime.format("LLLL")
          return (
            <span data-tip={lastModifiedTooltip} data-class="tooltip-custom type-icon-info">
              {lastModified}
            </span>
          )
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        let now = Moment()
        if (campaign.last_modified) {
          let lastModified = momentDateTimeAndUnixTimeStamp(campaign.last_modified)
          let lastModifiedAction = campaign.last_modified_action
          let action = null

          if (
            lastModifiedAction &&
            lastModifiedAction.type &&
            !isNullOrUndefined(lastModifiedAction.old_value) &&
            !isNullOrUndefined(lastModifiedAction.new_value)
          ) {
            switch (lastModifiedAction.type) {
              case CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_BID:
                action = (
                  <>
                    Bid (
                    <NumberFormat value={lastModifiedAction.old_value} displayType="text" decimalScale={3} prefix="$" />
                    {" → "}
                    <NumberFormat value={lastModifiedAction.new_value} displayType="text" decimalScale={3} prefix="$" />
                    )
                  </>
                )
                break
              case CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_STATUS:
                action =
                  "Status (" +
                  CampaignsConsts.CAMPAIGN_STATUS_NAMES[lastModifiedAction.old_value] +
                  " → " +
                  CampaignsConsts.CAMPAIGN_STATUS_NAMES[lastModifiedAction.new_value] +
                  ")"
                break

              case CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_DAILY_BUDGET:
                action = (
                  <>
                    Daily Budget (
                    <NumberFormat
                      value={lastModifiedAction.old_value}
                      displayType="text"
                      prefix="$"
                      decimalScale={3}
                      thousandSeparator
                    />
                    {" → "}
                    <NumberFormat
                      value={lastModifiedAction.new_value}
                      displayType="text"
                      prefix="$"
                      decimalScale={3}
                      thousandSeparator
                    />
                    )
                  </>
                )
                break
              case CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_TOTAL_BUDGET:
                action = (
                  <>
                    Total Budget (
                    <NumberFormat
                      value={parseInt(lastModifiedAction.old_value)}
                      displayType="text"
                      prefix="$"
                      thousandSeparator
                    />
                    {" → "}
                    <NumberFormat
                      value={parseInt(lastModifiedAction.new_value)}
                      displayType="text"
                      prefix="$"
                      thousandSeparator
                    />
                    )
                  </>
                )
                break
              case CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.TARGET_ROI:
                action = (
                  <>
                    Target ROI (
                    <NumberFormat
                      value={parseFloat(lastModifiedAction.old_value) * 100}
                      displayType="text"
                      decimalScale={0}
                      suffix="%"
                    />
                    {" → "}
                    <NumberFormat
                      value={parseFloat(lastModifiedAction.new_value) * 100}
                      displayType="text"
                      decimalScale={0}
                      suffix="%"
                    />
                    )
                  </>
                )
                break
              case CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_ROAS:
                action = (
                  <>
                    ROAS ROI (
                    <NumberFormat value={lastModifiedAction.old_value} displayType="text" decimalScale={0} suffix="%" />
                    {" → "}
                    <NumberFormat value={lastModifiedAction.new_value} displayType="text" decimalScale={0} suffix="%" />
                    )
                  </>
                )
                break
              case CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.SET_TARGETING:
                action = "FB Placements"
                break
            }
          }
          let lastModifiedTime = Moment.min(now, lastModified)
          lastModified = getShortMomentFromNow(lastModifiedTime.fromNow())
          let lastModifiedTooltip = lastModifiedTime.format("LLLL")
          let actionType = !isNullOrUndefined(action) ? (
            <div className="last-modified-action">
              <span className="last-modified-action-text">{action}</span>
            </div>
          ) : null
          return (
            <div>
              <span data-tip={lastModifiedTooltip} data-class="tooltip-custom type-icon-info">
                {lastModified}
              </span>
              {actionType}
            </div>
          )
        }
        return TableConsts.EMPTY_CELL
      },
    },
    userName: {
      title: "Creator",
      isDefaultField: false,
      fieldName: "user_name",
      tooltip: "<div><strong>Creator</strong> The creator of the campaign.</div>",
      comparable: false,
      rawValue: (campaign) => campaign.user_name,
      defaultFormat: (userName) => {
        if (userName) {
          return userName
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (campaign.user_name) {
          return campaign.user_name
        }
        return TableConsts.EMPTY_CELL
      },
    },
    conversionName: {
      title: "Conversion Goal",
      isDefaultField: false,
      fieldName: "conversion_name",
      tooltip: "<div><strong>Conversion Goal</strong> Is the conversion event the campaign is optimizing for.</div>",
      comparable: false,
      rawValue: (campaign) => campaign.conversion_name,
      defaultFormat: (conversion_name) => {
        if (conversion_name) {
          return conversion_name
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (campaign.conversion_name) {
          return campaign.conversion_name
        }
        return TableConsts.EMPTY_CELL
      },
    },
    // qualityRanking: {
    //   title: "Avg. Quality Ranking",
    //   isDefaultField: false,
    //   fieldName: "quality_ranking",
    //   tooltip:
    //     "<div><strong>Avg. Quality Ranking</strong> is the average ranking for the selected time period of Facebook’s measurement of ad quality.</div>",
    //   cellSize: "medium",
    //   rawValue: (campaign) => {
    //     if (campaign.quality_ranking && CampaignsConsts.CAMPAIGN_RANKINGS[campaign.quality_ranking]) {
    //       return CampaignsConsts.CAMPAIGN_RANKINGS[campaign.quality_ranking]
    //     }
    //     return null
    //   },
    //   defaultFormat: () => {
    //     return TableConsts.EMPTY_CELL
    //   },
    //   campaignCustomFormat: (campaign, context) => {
    //     let qualityRanking = campaign.quality_ranking

    //     if (qualityRanking && CampaignsConsts.CAMPAIGN_RANKINGS[qualityRanking]) {
    //       return CampaignsConsts.CAMPAIGN_RANKINGS[qualityRanking]
    //     }

    //     return TableConsts.EMPTY_CELL
    //   },
    // },
    // engagementRateRanking: {
    //   title: "Avg. Engagement Rate Ranking",
    //   isDefaultField: false,
    //   fieldName: "engagement_rate_ranking",
    //   tooltip:
    //     "<div><strong>Avg. Engagement Rate Ranking</strong> is the average ranking for the selected time period of Facebook’s expected likelihood that a person will engage with your ad.</div>",
    //   cellSize: "medium",
    //   rawValue: (campaign) => {
    //     if (campaign.engagement_rate_ranking && CampaignsConsts.CAMPAIGN_RANKINGS[campaign.engagement_rate_ranking]) {
    //       return CampaignsConsts.CAMPAIGN_RANKINGS[campaign.engagement_rate_ranking]
    //     }
    //     return null
    //   },
    //   defaultFormat: () => {
    //     return TableConsts.EMPTY_CELL
    //   },
    //   campaignCustomFormat: (campaign, context) => {
    //     let engagementRateRanking = campaign.engagement_rate_ranking

    //     if (engagementRateRanking && CampaignsConsts.CAMPAIGN_RANKINGS[engagementRateRanking]) {
    //       return CampaignsConsts.CAMPAIGN_RANKINGS[engagementRateRanking]
    //     }

    //     return TableConsts.EMPTY_CELL
    //   },
    // },
    // conversionRateRanking: {
    //   title: "Avg. Conversion Rate Ranking",
    //   isDefaultField: false,
    //   fieldName: "conversion_rate_ranking",
    //   tooltip:
    //     "<div><strong>Avg. Conversion Rate Ranking</strong> is the average ranking for the selected time period of Facebook’s expected likelihood that an impressions reach your optimization goal.</div>",
    //   cellSize: "medium",
    //   rawValue: (campaign) => {
    //     if (campaign.conversion_rate_ranking && CampaignsConsts.CAMPAIGN_RANKINGS[campaign.conversion_rate_ranking]) {
    //       return CampaignsConsts.CAMPAIGN_RANKINGS[campaign.conversion_rate_ranking]
    //     }
    //     return null
    //   },
    //   defaultFormat: () => {
    //     return TableConsts.EMPTY_CELL
    //   },
    //   campaignCustomFormat: (campaign, context) => {
    //     let conversionRateRanking = campaign.conversion_rate_ranking

    //     if (conversionRateRanking && CampaignsConsts.CAMPAIGN_RANKINGS[conversionRateRanking]) {
    //       return CampaignsConsts.CAMPAIGN_RANKINGS[conversionRateRanking]
    //     }

    //     return TableConsts.EMPTY_CELL
    //   },
    // },
    resultsRate: {
      title: "Results Rate",
      isDefaultField: false,
      fieldName: "results_rate",
      tooltip:
        "<div><strong>Results Rate</strong> is the percentage of converted clicks to the campaign's conversion goal</div>",
      rawValue: (campaign) => campaign.results_rate * 100,
      defaultFormat: (results_rate) => {
        return CommonDataFormat.floatPercentage(results_rate * 100, 2)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatPercentage(campaign.results_rate * 100, 2)
      },
    },
    providerPublisherId: {
      title: "Facebook Page",
      isDefaultField: false,
      fieldName: "provider_publisher_id",
      tooltip: "<div><strong>Facebook Page</strong> is the Facebook Page the campaign was published on</div>",
      cellSize: "medium",
      rawValue: (campaign, context) => {
        let pageName = CampaignsTableConsts.data_columns.providerPublisherId.campaignCustomFormat(campaign, context)

        return typeof pageName === "string" ? pageName : null
      },
      defaultFormat: (pageId) => {
        if (!isNullOrUndefined(pageId)) {
          return pageId
        }

        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign, context) => {
        let pageName = TableConsts.EMPTY_CELL
        let pageId = campaign.page_id

        if (!isNullOrUndefined(pageId)) {
          let site = context.props.siteIdToSiteObject.get(campaign.site_id)

          pageName = pageId

          if (!isNullOrUndefined(site)) {
            let siteProviderPages = site.provider_pages
            if (!isNullOrUndefined(siteProviderPages)) {
              siteProviderPages.forEach((providerPage) => {
                if (providerPage.page_id === pageId) {
                  pageName = providerPage.page_name
                }
              })
            }
          }
        }

        return pageName
      },
    },
    externalId: {
      title: "External ID",
      isDefaultField: false,
      fieldName: "external_id",
      tooltip: "<div><strong>External ID</strong> is the ID this campaign has on Source.</div>",
      cellSize: "medium-large",
      campaignCustomFormat: (campaign) => {
        return campaign.external_id
      },
      rawValue: (campaign) => campaign.external_id,
    },
    externalAccountId: {
      title: "Account ID",
      isDefaultField: false,
      fieldName: "external_account_id",
      tooltip: "<div><strong>Account ID</strong> is the external account ID on the campaign's source.</div>",
      cellSize: "medium-large",
      campaignCustomFormat: (campaign) => {
        return campaign.external_account_id
      },
      defaultFormat: (externalAccountId) => {
        return externalAccountId ? !isNullOrUndefined(externalAccountId) : TableConsts.EMPTY_CELL
      },
      rawValue: (campaign) => campaign.external_account_id,
    },
    activationDate: {
      title: "Activation Date",
      isDefaultField: false,
      fieldName: "activation_date",
      tooltip:
        '<div><strong>Activation Date</strong>  refers to the initial day when the campaign\'s status was switched to "Running" via Pub+.</div>',
      comparable: false,
      rawValue: (campaign) =>
        campaign.activation_date ? Moment(campaign.activation_date, "YYYY-MM-DD").toISOString() : null,
      defaultFormat: (activationDate) => {
        return activationDate
          ? Moment(activationDate, "YYYY-MM-DD").format(Consts.CLIENT_SHORT_DATE_FORMAT)
          : TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        return campaign.activation_date
          ? Moment(campaign.activation_date, "YYYY-MM-DD").format(Consts.CLIENT_SHORT_DATE_FORMAT)
          : TableConsts.EMPTY_CELL
      },
    },
    initialActivationDate: {
      title: "Initial Activation Date",
      isDefaultField: false,
      fieldName: "initial_activation_date",
      tooltip:
        '<div><strong>Initial Activation Date</strong>  refers to the initial day when the campaign\'s status was switched to "Running" via Pub+.</div>',
      comparable: false,
      rawValue: (campaign) =>
        campaign.initial_activation_date ? Moment(campaign.initial_activation_date, "YYYY-MM-DD").toISOString() : null,
      defaultFormat: (initialActivationDate) => {
        return initialActivationDate
          ? Moment(initialActivationDate, "YYYY-MM-DD").format(Consts.CLIENT_SHORT_DATE_FORMAT)
          : TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        return campaign.initial_activation_date
          ? Moment(campaign.initial_activation_date, "YYYY-MM-DD").format(Consts.CLIENT_SHORT_DATE_FORMAT)
          : TableConsts.EMPTY_CELL
      },
    },

    keywordImpressionRate: {
      title: "Keyword Impression Rate",
      fieldName: "keyword_impression_rate",
      defaultFormat: (keywordImpressionRate) => {
        return CommonDataFormat.floatPercentage(keywordImpressionRate * 100, 2)
      },
      rawValue: (campaign) => campaign.keyword_impression_rate,
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatPercentage(campaign.keyword_impression_rate * 100, 2)
      },
      cellSize: "medium",
      isDefaultField: false,
      tooltip:
        "<div><strong>Keyword Impression Rate</strong> is the average number of times visitors saw the related searches widget</div>",
    },
    keywordImpressions: {
      title: "Keyword Impressions",
      fieldName: "keyword_impressions",
      defaultFormat: (keywordImpressions) => {
        return keywordImpressions
      },
      rawValue: (campaign) => campaign.keyword_impressions,
      campaignCustomFormat: (campaign) => {
        return campaign.keyword_impressions
      },
      cellSize: "medium",
      isDefaultField: false,
      tooltip:
        "<div><strong>Keyword Impressions</strong> is the number of impressions of the related searches widget.</div>",
    },
    searchesRate: {
      title: "Searches Rate",
      fieldName: "searches_rate",
      defaultFormat: (searchesRate) => {
        return CommonDataFormat.floatPercentage(searchesRate * 100, 2)
      },
      rawValue: (campaign) => campaign.searches_rate,
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatPercentage(campaign.searches_rate * 100, 2)
      },
      cellSize: "medium",
      isDefaultField: false,
      tooltip: "<div><strong>Searches Rate</strong> is the average number of searches per visit</div>",
    },
    searchesFunnelRpm: {
      title: "Searches Funnel RPM",
      fieldName: "searches_funnel_rpm",
      defaultFormat: (searchesFunnelRpm) => {
        return CommonDataFormat.floatCurrency(searchesFunnelRpm * 100, 2)
      },
      rawValue: (campaign) => campaign.searches_funnel_rpm,
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatCurrency(campaign.searches_funnel_rpm * 100, 2)
      },
      cellSize: "medium",
      isDefaultField: false,
      tooltip:
        "<div><strong>Searches Funnel RPM</strong> is calculated by dividing your estimated earnings resulting from a funnel request by the number of funnel impressions you received, then multiplying by 1000.</div>",
    },

    networkCode: {
      title: "Network",
      isDefaultField: false,
      fieldName: "network_code",
      tooltip: "<div><strong>Network</strong> is the PubPlus network the campaign is running on.</div>",
      comparable: false,
      rawValue: (campaign) => campaign.network_code,
      defaultFormat: (network_code) => {
        if (network_code) {
          return network_code
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (campaign.network_code) {
          return campaign.network_code
        }
        return TableConsts.EMPTY_CELL
      },
    },
    articleLanguage: {
      title: "Article Language",
      isDefaultField: false,
      fieldName: "article_language",
      tooltip: "<div><strong>Article Language</strong> is the language the article is written in.</div>",
      rawValue: (campaign) => campaign.lang,
      defaultFormat: (articleLanguage) => {
        if (articleLanguage) {
          return articleLanguage
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (campaign.lang) {
          return getLangNameFromCode(campaign.lang)?.name
        }
        return TableConsts.EMPTY_CELL
      },
    },
    browsers: {
      title: "Browsers",
      isDefaultField: false,
      cellSize: "large",
      fieldName: "browsers",
      tooltip: "<div><strong>Browsers</strong> are the browsers the campaign is targeting</div>",
      rawValue: (campaign) => (campaign.browsers?.length > 0 ? campaign.browsers.join(" ") : campaign.browsers),
      defaultFormat: (browsers) => {
        return browsers?.length > 0 ? browsers : TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (campaign.browsers?.length > 0) {
          const browsersNameArray = campaign.browsers.map((browser) => getBrowserName(browser))
          return browsersNameArray.join(", ")
        }
        return TableConsts.EMPTY_CELL
      },
    },
    operatingSystems: {
      title: "Operating Systems",
      isDefaultField: false,
      cellSize: "medium",
      fieldName: "operating_systems",
      tooltip: "<div><strong>Operating Systems</strong> are the operating systems the campaign is targeting</div>",
      rawValue: (campaign) =>
        campaign.operating_systems?.length > 0 ? campaign.operating_systems.join(" ") : campaign.operating_systems,
      defaultFormat: (operating_systems) => {
        return operating_systems?.length > 0 ? operating_systems : TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (campaign.operating_systems?.length > 0) {
          const osNamesArray = campaign.operating_systems.map((os) => getOperatingSystemName(os))
          return osNamesArray.join(", ")
        }
        return TableConsts.EMPTY_CELL
      },
    },
    ecomYieldingClicks: {
      title: "Affiliate Clicks",
      isDefaultField: false,
      fieldName: "ecom_yielding_clicks",
      tooltip: "<div><strong>Affiliate Clicks</strong> number of unique users who clicked on affiliate links</div>",
      rawValue: (campaign) => campaign.ecom_yielding_clicks,
      defaultFormat: (ecom_yielding_clicks) => {
        if (ecom_yielding_clicks) {
          return ecom_yielding_clicks
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        if (campaign.ecom_yielding_clicks) {
          return campaign.ecom_yielding_clicks
        }
        return TableConsts.EMPTY_CELL
      },
    },
    revenueEcom: {
      title: "Affiliate Revenue",
      isDefaultField: false,
      fieldName: "revenue_ecom",
      tooltip: "<div><strong>Affiliate Revenue</strong> Is the total revenue generated by affiliate commissions</div>",
      rawValue: (campaign) => campaign.revenue_ecom,
      defaultFormat: (revenue_ecom) => {
        if (revenue_ecom) {
          return CommonDataFormat.floatCurrency(revenue_ecom, 2)
        }
        return TableConsts.EMPTY_CELL
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatCurrency(campaign.revenue_ecom, 2)
      },
    },
    affiliateRpc: {
      title: "Affiliate RPC",
      isDefaultField: false,
      fieldName: "affiliate_rpc",
      tooltip: "<div><strong>Affiliate RPC</strong> is the revenue per (yielding) item click</div>",
      rawValue: (campaign) => campaign.affiliate_rpc,
      defaultFormat: (affiliate_rpc) => {
        return CommonDataFormat.floatCurrency(affiliate_rpc, 2)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatCurrency(campaign.affiliate_rpc, 2)
      },
    },
    rpc: {
      title: "RPC",
      isDefaultField: false,
      fieldName: "rpc",
      tooltip: "<div><strong>RPC</strong> is the revenue per click out</div>",
      rawValue: (campaign) => campaign.rpc,
      defaultFormat: (rpc) => {
        return CommonDataFormat.floatCurrency(rpc, 2)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatCurrency(campaign.rpc, 2)
      },
    },
    rsocYieldingClicks: {
      title: "Click Outs",
      isDefaultField: false,
      fieldName: "rsoc_yielding_clicks",
      tooltip: "<div><strong>Click Outs</strong> is the number of clicks out on search ads</div>",
      rawValue: (campaign) => campaign.rsoc_yielding_clicks,
      defaultFormat: (rsocYieldingClicks) => {
        return rsocYieldingClicks
      },
      campaignCustomFormat: (campaign) => {
        return campaign.rsoc_yielding_clicks
      },
    },
    affiliateOrders: {
      title: "Affiliate Orders",
      isDefaultField: false,
      fieldName: "affiliate_orders",
      tooltip: "<div><strong>Affiliate Orders</strong> is the number of ecommerce orders</div>",
      rawValue: (campaign) => campaign.affiliate_orders,
      defaultFormat: (affiliateOrders) => {
        return affiliateOrders
      },
      campaignCustomFormat: (campaign) => {
        return campaign.affiliate_orders
      },
    },
    affiliateOrderRate: {
      title: "Affiliate Order Rate",
      isDefaultField: true,
      fieldName: "affiliate_order_rate",
      tooltip:
        "<div><strong>Affiliate Order Rate</strong> is the percentage of orders out of item (yielding) clicks </div>",
      rawValue: (campaign) => campaign.affiliate_order_rate * 100,
      defaultFormat: (affiliateOrderRate) => {
        return CommonDataFormat.floatPercentage(affiliateOrderRate * 100, 1)
      },
      campaignCustomFormat: (campaign) => {
        return CommonDataFormat.floatPercentage(campaign.affiliate_order_rate * 100, 1)
      },
    },
  },
  subSource: {
    title: "Sub-Source",
    fieldName: "name",
    cellSize: "medium-large",
  },
  status: {
    title: "Status",
    fieldName: "status",
    cellSize: "x-small",
  },
  subSourceBid: {
    title: "Bid",
    fieldName: "bid",
  },
  country: {
    title: "Country",
    fieldName: "name",
    classList: "cell small",
  },
  keyword: {
    title: "Keyword",
    fieldName: "keyword",
    defaultFormat: (keyword) => {
      return keyword
    },
    cellSize: "medium",
  },
  tableValue: {
    title: "value",
    fieldName: "value",
    defaultFormat: (value) => {
      return <NumberFormat value={value} displayType="text" decimalScale={2} suffix="%" />
    },
    cellSize: "small",
  },
  unique_search_impressions: {
    title: "Searches",
    fieldName: "impressions",
    defaultFormat: (impressions) => {
      return impressions
    },
    tooltip:
      "<div><strong>Searches</strong> is the number of times users have seen the search results page for a keyword.</div>",
  },

  click_out_rate: {
    title: "Click Out Rate",
    fieldName: "click_out_rate",
    defaultFormat: (click_out_rate) => {
      if (click_out_rate) {
        return CommonDataFormat.floatPercentage(click_out_rate * 100, 2)
      }
      return TableConsts.EMPTY_CELL
    },
    tooltip:
      "<div><strong>Click Out Rate</strong> is the total clicks divided by the searches on an ad for a keyword</div>",
  },

  orders: {
    title: "Orders",
    fieldName: "orders",
    defaultFormat: (orders) => {
      return orders
    },
    tooltip:
      "<div><strong>Orders</strong> is the number of times this item was ordered through the campaign or indirectly</div>",
  },

  order_rate: {
    title: "Order Rate",
    fieldName: "order_rate",
    defaultFormat: (orderRate) => {
      if (orderRate) {
        return CommonDataFormat.floatPercentage(orderRate * 100, 2)
      }
      return TableConsts.EMPTY_CELL
    },
    tooltip: "<div><strong>Order Rate</strong> is the percentage of clicks on the item that resulted in an order</div>",
  },
  item_rpm: {
    title: "RPM",
    fieldName: "item_rpm",
    defaultFormat: (itemRpm) => {
      return CommonDataFormat.floatCurrency(itemRpm, 2)
    },
    tooltip: "<div><strong>RPM</strong>is the revenue per 1000 impressions of the item </div>",
  },
  item_click_value: {
    title: "Click Value",
    fieldName: "click_value",
    defaultFormat: (itemClickValue) => {
      return CommonDataFormat.floatCurrency(itemClickValue, 2)
    },
    tooltip: "<div><strong>Click Value</strong> is the revenue per click on the item</div>",
  },
  estimated_revenue: {
    title: "Estimated Revenue",
    fieldName: "estimated_revenue",
    defaultFormat: (estimatedRevenue) => {
      return CommonDataFormat.floatCurrency(estimatedRevenue, 2)
    },
    tooltip: "<div><strong>Estimated Revenue</strong> is the estimated fee for direct AND indirect sales of the item</div>",
  },

  image: {
    title: "Image",
    fieldName: "image_url_small",
    defaultFormat: (image) => {
      return (
        <div className="campaign-image">
          <img src={image} alt="campaign" />
        </div>
      )
    },
    campaignCustomFormat: (campaign) => {
      return (
        <div className="d-flex full-width image-cell">
          {campaign?.image_url_small ? (
            <div>{<img src={campaign?.image_url_small} loading="lazy" />}</div>
          ) : (
            <div className="image-cell">
              <AddImageIcon />
            </div>
          )}
        </div>
      )
    },
  },
  item: {
    title: "Item",
    fieldName: "item",
    cellSize: "large",
    defaultFormat: (item) => {
      return item
    },
    campaignCustomFormat: (campaign) => {
      const amazonLink = `https://www.amazon.com/dp/${campaign.item_code}`
      const displayText = campaign.title || campaign.item_code
      return (
        <div>
          <a href={amazonLink} target="_blank" className="article-link">
            {displayText}
          </a>
        </div>
      )
    },
  },
  impressions: {
    title: "Impressions",
    fieldName: "impressions",
    defaultFormat: (impressions) => {
      return impressions
    },
    tooltip:
      "<div><strong>Impressions</strong> is the number of times the campaign's ads were on screen for the first time.</div>",
  },
  click_outs: {
    title: "Click Outs",
    fieldName: "click_outs",
    defaultFormat: (click_outs) => {
      return click_outs
    },
    tooltip: "<div><strong>Click Outs</strong> is the number of clicks on an ad for a keyword</div>",
  },
  browser: {
    title: "Browser Name",
    fieldName: "name",
    classList: "cell small",
  },
  device: {
    title: "Device Name",
    fieldName: "name",
    classList: "cell small",
  },
  date: { title: "Date", fieldName: "date" },
  time: { title: "Date", fieldName: "time" },
  user: { title: "User", fieldName: "username" },
  campaignActivity: {
    title: "Activities",
    fieldName: "activities",
  },
}

Object.freeze(CampaignsTableConsts)
export default CampaignsTableConsts
