class SettingsPageActionTypes {}
SettingsPageActionTypes.SETTINGS_PAGE_LOADING = "SETTINGS_PAGE_LOADING"
SettingsPageActionTypes.FETCH_ACCOUNT_SETTINGS_RESPONSE = "FETCH_ACCOUNT_SETTINGS_RESPONSE"
SettingsPageActionTypes.UPDATE_ACCOUNT_SETTINGS = "UPDATE_ACCOUNT_SETTINGS"
SettingsPageActionTypes.ACCOUNT_SETTINGS_SELECT_NETWORK = "ACCOUNT_SETTINGS_SELECT_NETWORK"
SettingsPageActionTypes.ACCOUNT_SETTINGS_RESET_NETWORK_PROVIDER_ACCOUNTS =
  "ACCOUNT_SETTINGS_RESET_NETWORK_PROVIDER_ACCOUNTS"
SettingsPageActionTypes.ACCOUNT_SETTINGS_LOADING_NETWORK_PROVIDER_ACCOUNTS =
  "ACCOUNT_SETTINGS_LOADING_NETWORK_PROVIDER_ACCOUNTS"
SettingsPageActionTypes.ACCOUNT_SETTINGS_UPDATE_NETWORK_PROVIDER_ACCOUNTS =
  "ACCOUNT_SETTINGS_UPDATE_NETWORK_PROVIDER_ACCOUNTS"
SettingsPageActionTypes.ADD_PROVIDER_PAGE = "ADD_PROVIDER_PAGE"
SettingsPageActionTypes.DELETE_PROVIDER_PAGE = "DELETE_PROVIDER_PAGE"
SettingsPageActionTypes.ADD_PROVIDER_SUB_ACCOUNTS = "ADD_PROVIDER_SUB_ACCOUNTS"
SettingsPageActionTypes.NETWORK_TARGETING_GROUP_PRESETS_LOADING = "NETWORK_TARGETING_GROUP_PRESETS_LOADING"
SettingsPageActionTypes.FETCH_NETWORK_TARGETING_GROUP_PRESETS_RESPONSE =
  "FETCH_NETWORK_TARGETING_GROUP_PRESETS_RESPONSE"
SettingsPageActionTypes.DELETE_NETWORK_TARGETING_GROUP_PRESET_RESPONSE =
  "DELETE_NETWORK_TARGETING_GROUP_PRESET_RESPONSE"
SettingsPageActionTypes.ADD_NETWORK_TARGETING_GROUP_PRESET_RESPONSE = "ADD_NETWORK_TARGETING_GROUP_PRESET_RESPONSE"
SettingsPageActionTypes.UPDATE_NETWORK_TARGETING_GROUP_PRESET_RESPONSE =
  "UPDATE_NETWORK_TARGETING_GROUP_PRESET_RESPONSE"
export default SettingsPageActionTypes
