export class NumberFilter {
  constructor() {}

  static numberEqual = (x, y) => {
    return Number.parseFloat(x) == Number.parseFloat(y)
  }

  static numberGreaterThan = (x, y) => {
    return Number.parseFloat(x) > Number.parseFloat(y)
  }

  static numberSmallerThan = (x, y) => {
    return Number.parseFloat(x) < Number.parseFloat(y)
  }

  static numberBetween = (x, min, max) => {
    return Number.parseFloat(x) >= Number.parseFloat(min) && x <= Number.parseFloat(max)
  }

  static numberNotBetween = (x, min, max) => {
    return !NumberFilter.numberBetween(x, min, max)
  }

  static numberIn = (x, items) => {
    return items.includes(x)
  }

  static numberNotIn = (x, items) => {
    return !NumberFilter.numberIn(x, items)
  }
}
