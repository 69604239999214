import Consts from "../app/consts"
// todo check how to remove this import
import {} from "../campaignCreation/campaignCreationActions"

// One day we will completely remove jQuery from the project so we won't have .parents()
export function getParents(el, parentSelector /* optional */) {
  // If no parentSelector defined will bubble up all the way to *document*
  if (parentSelector === undefined) {
    parentSelector = document
  }

  let parents = []
  let p = el.parentNode

  while (p !== parentSelector) {
    let o = p
    parents.push(o)
    p = o.parentNode
  }
  parents.push(parentSelector) // Push that parentSelector you wanted to stop at

  return parents
}

export function setAppTitle(activitiesUpdate) {
  if (activitiesUpdate > 0) {
    document.title = "(" + activitiesUpdate + ") " + Consts.DEFAULT_APP_TITLE
  } else {
    document.title = Consts.DEFAULT_APP_TITLE
  }
}

// Disabling the header handler, pass the scrollersObject that we returned from the initialization function
export function disableFixedHeaderHandler(scrollersObject) {
  if (!scrollersObject) {
    return
  }

  scrollersObject.verticalScroller.removeEventListener("scroll", scrollersObject.verticalScrollHandler)

  if (scrollersObject.horizontalScroller) {
    scrollersObject.horizontalScroller.removeEventListener("scroll", scrollersObject.horizontalScrollHandler)
  }

  if (scrollersObject.stickyBottomScrollbarHandler) {
    scrollersObject.stickyBottomScrollbar.removeEventListener("scroll", scrollersObject.stickyBottomScrollbarHandler)
  }

  window.removeEventListener("resize", scrollersObject.verticalScrollHandler)
  window.removeEventListener("resize", scrollersObject.horizontalScrollHandler)

  if (scrollersObject.stickyBottomScrollbarHandler) {
    window.removeEventListener("resize", scrollersObject.stickyBottomScrollbarHandler)
  }
}

// We are going to follow two scroll events: one is the vertical scrolling element (e.g. window)
// and the other is the horizontal scrolling element (e.g. campaigns container).
// we need to follow each of their scrolls to determine whether the headers should be fixed or not, and if yes
// then by how much do we need to reposition them horizontally, since fixed elements are detached from the window
// context and we need to simulate it.
// Also taking care of a bottom sticky bottom scrollbar if needed (especially helpful for people without a trackpad)
export function handleDataListStickyElements(
  verticalScroller,
  horizontalScroller,
  includeNavBar = true,
  handleStickyBottomScrollbar = false,
  focusScrollableElement = false
) {
  if (!verticalScroller || !horizontalScroller) {
    return
  }
  let navigationBarHeight = includeNavBar ? document.querySelector("header .navigation-bar").offsetHeight : 0
  let headers = Array.from(horizontalScroller.querySelectorAll(".sticky-header"))
  let stickyBottomScrollbar = handleStickyBottomScrollbar
    ? horizontalScroller.querySelector(".sticky-bottom-scrollbar")
    : null
  let stickyBottomScrollbarHandle = handleStickyBottomScrollbar ? stickyBottomScrollbar.querySelector(".handle") : null
  let headersHeight = []
  let headersCellChildren = []
  let headersStickyChildren = []
  let headersOriginalOffsetTop = []
  let headersIsFixed = []
  let horizontalScrollerMarginTop = includeNavBar ? parseInt(window.getComputedStyle(horizontalScroller).marginTop) : 0
  let isScrollingStickyScrollbar = false
  let isScrollingHorizontalScroller = false

  // Storing data for each one the headers in the current list (each one of them will be fixed when the screen scrolls
  // past their offsetTop position, which is their original position before we detached them from the document's context
  // using position:fixed)
  headers.forEach((header, index) => {
    headersHeight[index] = header.offsetHeight

    headersCellChildren[index] = Array.from(header.querySelectorAll(".cell")).filter((cell) => {
      return !cell.parentElement.classList.contains("sticky-column")
    })
    headersStickyChildren[index] = Array.from(header.querySelectorAll(".sticky-column"))
    headersOriginalOffsetTop[index] = header.offsetTop
    if (Array.from(header.classList).includes("fixed-header")) {
      headersOriginalOffsetTop[index] -= navigationBarHeight
    }
    headersIsFixed[index] = false
  })

  // Helper function for getting the next NON header element, that element will get a margin-top to compensate for the
  // detachment of the header from the document (the whole height of the table will get smaller when we do position:fixed,
  // so we must add back that height so the user won't notice anything strange)
  let getNextNonHeaderElement = (element) => {
    if (!element || !element.nextSibling) {
      return
    }

    if (!element.nextSibling.classList.contains("header")) {
      return element.nextSibling
    }

    return getNextNonHeaderElement(element.nextSibling)
  }

  let verticalScrollHandler = (event) => {
    let navigationBar = document.querySelector("header .navigation-bar")
    navigationBarHeight = includeNavBar ? navigationBar.offsetHeight + navigationBar.offsetTop : 0
    var horizontalPosLeft = horizontalScroller.getBoundingClientRect().left
    var horizontalPosTop = horizontalScroller.getBoundingClientRect().top
    var horizontalPosBottom = horizontalScroller.getBoundingClientRect().bottom

    // The test will be made for each one of the headers that need to be fixed
    headers.forEach((header, index) => {
      var headerHeight = headersHeight[index]
      var headerCellChildren = headersCellChildren[index]
      var headerStickyChildren = headersStickyChildren[index]
      var headerOffsetTop = headersOriginalOffsetTop[index]
      var nextNonHeaderElement = getNextNonHeaderElement(header)

      if (horizontalPosTop + headerOffsetTop - horizontalScrollerMarginTop < navigationBarHeight) {
        // This header needs to be fixed, set the proper styles
        headersIsFixed[index] = true
        header.classList.add("fixed-header")

        header.style =
          "top:" +
          navigationBarHeight +
          "px;" +
          "left:" +
          horizontalPosLeft +
          "px;" +
          "width:" +
          horizontalScroller.offsetWidth +
          "px;" +
          "min-width: initial;" +
          "z-index:" +
          (index + 2) +
          ";" +
          "overflow:hidden"

        if (nextNonHeaderElement) {
          nextNonHeaderElement.style = "margin-top:" + headerHeight + "px;"
        }
        horizontalScrollHandler()
      } else {
        headersIsFixed[index] = false
        // Else, this header shouldn't be fixed, so we reset the styles
        header.classList.remove("fixed-header")
        header.style = "top:0px;left:0px;width:'';overflow:visible;"
        headerCellChildren.forEach((item) => {
          item.style.right = "0px"
        })
        headerStickyChildren.forEach((item) => {
          item.style = "left:0px;"
        })

        if (nextNonHeaderElement) {
          nextNonHeaderElement.style = ""
        }
      }
    })

    if (handleStickyBottomScrollbar) {
      if (horizontalPosBottom < window.innerHeight || horizontalPosTop > window.innerHeight) {
        stickyBottomScrollbar.style = "display: none;"
      } else {
        stickyBottomScrollbar.style =
          "display:block; left: " + horizontalPosLeft + "px;" + "width: " + horizontalScroller.offsetWidth + "px"
        stickyBottomScrollbarHandle.style = "width: " + horizontalScroller.scrollWidth + "px;"
      }
    }
  }

  let horizontalScrollHandler = (event) => {
    headers.forEach((header, index) => {
      if (headersIsFixed[index]) {
        let headerCellChildren = headersCellChildren[index]
        let headerStickyChildren = headersStickyChildren[index]

        let offset = horizontalScroller.scrollLeft
        headerCellChildren.forEach((item) => {
          item.style.right = offset + "px"
        })

        if (headerStickyChildren.length > 0) {
          let nonHeaderSticky = Array.from(horizontalScroller.querySelectorAll(".sticky-column")).filter(
            (stickyColumn) => {
              let partOfStickyHeader = false
              let parents = getParents(stickyColumn)
              parents.forEach((parent) => {
                if (parent.classList && parent.classList.contains("sticky-header")) {
                  partOfStickyHeader = true
                }
              })

              return !partOfStickyHeader
            }
          )[0]

          let calc = nonHeaderSticky ? horizontalScroller.scrollLeft - nonHeaderSticky.offsetLeft : 0
          if (calc > 0) {
            headerStickyChildren.forEach((item) => {
              item.style = "left:" + -1 * calc + "px;"
            })
          } else {
            headerStickyChildren.forEach((item) => {
              item.style = "left:0px;"
            })
          }
        }
      }
    })

    if (handleStickyBottomScrollbar) {
      if (isScrollingStickyScrollbar) {
        isScrollingStickyScrollbar = false
        return
      }

      // Locking the sticky scrollbar's scroll event when we set its scrollLeft so we won't be stuck in an infinite
      // event handler loop
      isScrollingHorizontalScroller = true
      stickyBottomScrollbar.scrollLeft = horizontalScroller.scrollLeft
    }
  }

  let stickyBottomScrollbarHandler = () => {
    if (isScrollingHorizontalScroller) {
      isScrollingHorizontalScroller = false
      return
    }

    // Locking the horizontal scroller's scroll event when we set its scrollLeft so we won't be stuck in an infinite
    // event handler loop
    isScrollingStickyScrollbar = true
    horizontalScroller.scrollLeft = stickyBottomScrollbar.scrollLeft
  }

  // Actual event binding is here
  verticalScroller.addEventListener("scroll", verticalScrollHandler)
  horizontalScroller.addEventListener("scroll", horizontalScrollHandler)

  if (handleStickyBottomScrollbar) {
    stickyBottomScrollbar.addEventListener("scroll", stickyBottomScrollbarHandler)
  }

  // Adding resizing event listeners in case the user resizes the page
  window.addEventListener("resize", verticalScrollHandler)
  window.addEventListener("resize", horizontalScrollHandler)

  if (handleStickyBottomScrollbar) {
    window.addEventListener("resize", stickyBottomScrollbarHandler)
  }

  // Triggering all handlers for the first time only
  verticalScrollHandler()
  horizontalScrollHandler()

  if (handleStickyBottomScrollbar) {
    stickyBottomScrollbarHandler()
  }

  // Returning a management object in order to disable the events at a later time
  let scrollersObject = {
    horizontalScroller: horizontalScroller,
    horizontalScrollHandler: horizontalScrollHandler,
    verticalScroller: verticalScroller,
    verticalScrollHandler: verticalScrollHandler,
  }

  if (handleStickyBottomScrollbar) {
    scrollersObject.stickyBottomScrollbar = stickyBottomScrollbar
    scrollersObject.stickyBottomScrollbarHandler = stickyBottomScrollbarHandler
  }

  if (focusScrollableElement) {
    // Focusing the horizontal scroller element (usually it's the data list itself) in order for the user to be able
    // to use the arrow buttons immediately).
    horizontalScroller.setAttribute("tabindex", "0")
    let htmlElement = document.querySelector("html")
    let scrollTopBeforeFocus = htmlElement.scrollTop
    horizontalScroller.focus()

    // We want to make this focus silently, but as we focus something on the page the browser will vertically
    // scroll to it, so right after we focus the element we return to the old scrollTop value.
    htmlElement.scrollTop = scrollTopBeforeFocus
  }

  return scrollersObject
}

export function insertAfter(el, referenceNode) {
  referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
}

export function markSelectedRow(event) {
  if (event.currentTarget.classList.contains("selected")) {
    event.currentTarget.classList.remove("selected")
  } else {
    let element = event.currentTarget.parentNode.querySelector("div.selected")
    if (element) {
      element.classList.remove("selected")
    }
    event.currentTarget.classList.add("selected")
  }
}

export function stopPropagationIfParentIsSelected(event) {
  let parents = getParents(event.currentTarget)

  for (let i = 0; i < parents.length; i++) {
    let parent = parents[i]
    if (parent.classList && parent.classList.contains("selected")) {
      event.stopPropagation()
      return
    }
  }
}

export function disableInfiniteScrollHandler(scrollerElement, func) {
  let scroller = document.querySelector(scrollerElement)

  if (scroller) {
    scroller.removeEventListener("scroll", func)
  }
}

export function getMoreElements(scrollerElement, callback) {
  let scroller = document.querySelector(scrollerElement)

  if (scroller) {
    if (Math.ceil(scroller.scrollTop + scroller.offsetHeight) >= scroller.scrollHeight) {
      callback()
    }
  }
}

export function handleInfiniteScroll(scrollerElement, func) {
  let scroller = document.querySelector(scrollerElement)

  if (scroller) {
    scroller.addEventListener("scroll", func)
  }
}

export function findReactComponentByDomElement(el) {
  for (const key in el) {
    if (key.startsWith("__reactInternalInstance$")) {
      const fiberNode = el[key]

      return fiberNode && fiberNode.return && fiberNode.return.stateNode
    }
  }

  return null
}
