import React from "react"
import { connect } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import {
  automationPopupCancelRequest,
  automationPopupFetchActionHistoryAsync,
  automationPopupLoading,
} from "../automationPopupActions"
import AutomationPopupActionHistoryChart from "./charts/actionHistoryChart"
import AutomationPopupActionHistoryList from "./lists/actionHistoryList"

class AutomationPopupActionHistory extends React.Component {
  getActionHistory = () => {
    automationPopupCancelRequest("action-history-request")
    this.props.dispatch(automationPopupLoading())
    this.props.dispatch(
      automationPopupFetchActionHistoryAsync(
        this.props.automation,
        this.props.datePickerDates.startDate,
        this.props.datePickerDates.endDate,
        false
      )
    )
  }

  componentDidMount() {
    this.getActionHistory()
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      JSON.stringify(prevProps.datePickerDates.startDate) !== JSON.stringify(this.props.datePickerDates.startDate) ||
      JSON.stringify(prevProps.datePickerDates.endDate) !== JSON.stringify(this.props.datePickerDates.endDate)
    ) {
      this.getActionHistory()
    }
  }

  componentWillUnmount() {
    automationPopupCancelRequest("action-history-request")
  }

  render() {
    let actionHistory

    if (this.props.isLoading) {
      actionHistory = (
        <div className="campaign-popup-loading d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      actionHistory = (
        <div className="bid-history">
          <AutomationPopupActionHistoryChart />
          <AutomationPopupActionHistoryList />
        </div>
      )
    }

    return <div className="campaign-popup-bid-history">{actionHistory}</div>
  }
}

function mapStateToProps(state, ownProps) {
  return {
    automation: state.automationPopup.automation,
    datePickerDates: {
      startDate: state.automationPopup.datePickerDates.startDate,
      endDate: state.automationPopup.datePickerDates.endDate,
    },
    actionHistory: state.automationPopup.actionHistory,
    isLoading: state.automationPopup.isLoading,
  }
}

export default connect(mapStateToProps)(AutomationPopupActionHistory)
