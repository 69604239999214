import Moment from "moment"
import CampaignsConsts from "./campaignsConsts"
import { deserializeFilters, genericCompareFunc, getDatesRange, isNullOrUndefined } from "../utils/funcUtils"
import ActionTypes from "../common/actions/actionTypes"
import WebStorageConsts from "../common/consts/webStorageConsts"
import { FilterContainerSpec } from "../common/components/filterV2/filterSpecs/filterContainerSpec"
import {
  overrideCampaignAttributes,
  isCampaignPopupRelatedLocationStateV2,
  isUnknownCampaignTrafficSource,
} from "../utils/campaignUtilsV2"
import { webStorage } from "../api/webStorage"
import CampaignActionTypes from "./campaignActionTypes"
import CampaignPopupActionTypes from "../campaignPopupV2/campaignPopupActionTypes"
import { Filters } from "../common/consts/filterTypesV2"
import { FilterItemSpec } from "../common/components/filterV2/filterSpecs/filterItemSpec"
import {
  bulkCampaignActivationResponse,
  bulkCampaignsActivationLoading,
  bulkCampaignsBidLoading,
  bulkCampaignsDailyBudgetLoading,
  bulkCampaignStatusLoading,
  bulkCampaignsTotalBudgetLoading,
  bulkEditCampaignsBidResponse,
  bulkEditCampaignsBudgetResponse,
  bulkEditCampaignsTargetingResponse,
  bulkStartStopCampaignsResponse,
  bulkUpdateCampaignsTags,
  campaignBidLoading,
  campaignDailyBudgetLoading,
  campaignStatusLoading,
  campaignTotalBudgetLoading,
  changeBidResponse,
  changeCampaignDailyBudgetResponse,
  changeCampaignTotalBudgetResponse,
  changeRoasResponse,
  startCampaignErrorResponse,
  activeCampaignResponse,
  stopCampaignErrorResponse,
  pauseCampaignResponse,
  updateCampaignTags,
} from "./campaignReducerOperations"
import { getLangNameFromCode } from "language-name-map"
import { getEndDate, getStartDate, TODAY, CUSTOM } from "../common/components/datePickerV2"

function getDefaultSortByColumn(state = {}) {
  let sortBy = CampaignsConsts.LIST_COLUMNS.data_columns.visits.fieldName

  if (webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_LAST_SORT_BY_COLUMN)) {
    let value = webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_LAST_SORT_BY_COLUMN)
    Object.values({
      ...CampaignsConsts.LIST_COLUMNS.data_columns,
      ...CampaignsConsts.LIST_COLUMNS.header_columns,
    }).forEach((column) => {
      if (column.fieldName === value) {
        if (column.showOnSiteMode !== false || state.isNetworkReport) {
          sortBy = column.fieldName
        }
      }
    })
  }

  return sortBy
}

function getDefaultSortDirection() {
  let sortDirection = "DESC"

  if (webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_LAST_SORT_DIRECTION)) {
    sortDirection = webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_LAST_SORT_DIRECTION)
  }

  return sortDirection
}

function getDefaultItemsPerPage() {
  let itemsPerPage = 50

  if (webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_ITEMS_PER_PAGE)) {
    itemsPerPage = parseInt(webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_ITEMS_PER_PAGE))
  }

  return itemsPerPage
}

function getFiltersToRestore() {
  let filtersToRestore = null
  let campaignFilters = JSON.parse(webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_FILTERS))

  if (Array.isArray(campaignFilters) && campaignFilters.length > 0) {
    filtersToRestore = deserializeFilters(campaignFilters, Filters)
  }

  return filtersToRestore
}

const campaignsInitialState = {
  datePickerDates: {
    startDate: getStartDate(TODAY),
    endDate: getEndDate(TODAY),
    showLastHour: true,
    isGeneratedReport: false,
    dateType: TODAY,
  },
  isToday: false,
  filters: new Map(),
  filtersToRestore: getFiltersToRestore(),
  allCampaigns: [], // Full list of all campaigns for current site & date
  visibleCampaigns: [], // Visible campaigns according to sorting, searching & filtering
  campaignNames: [],
  sortBy: getDefaultSortByColumn(),
  sortDirection: getDefaultSortDirection(),
  itemsPerPage: getDefaultItemsPerPage(),
  currentPage: 1,
  currentFilterStatus: { name: "Active" },
  lastUpdate: null,
  shouldUpdatePage: false,
  isNextResponseUserGeneratedReport: false,
  campaignLanguages: [],
  campaignArticleLanguages: [],
  campaignConversions: [],
  campaignCreators: new Map(),
  campaignTags: [],
  networkTags: [],
  allCampaignsSelected: false,
  campaignsSelectionOrder: [],
  isNetworkReport: false,
  reportType: CampaignsConsts.REPORT_TYPES.PREDICTED_REPORT,
  delayBySource: null,
  isRealtimeUpdateActive: webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_REALTIME_UPDATE)
    ? webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_REALTIME_UPDATE) === "true"
    : true,
  realtimeLastUpdate: null,
  isRealtimePredictionPollingEnabled: true,
  lastCampaignsRoute: null,
  hideClicked: false,
}

function campaignPageReducerV2(state = campaignsInitialState, action) {
  if (state == undefined || action == undefined) {
    return []
  }

  let updateCampaignProps = (campaignObj, updatedProps, isSourceCampaign = true) => {
    if (isSourceCampaign) {
      return { ...campaignObj, ...updatedProps }
    }
  }

  let updateCampaignInVisibleCampaigns = (visibleCampaigns, campaignKey, updatedProps, isSourceCampaign = true) => {
    return visibleCampaigns.map((campaignObj) => {
      if (campaignObj.key === campaignKey) {
        return updateCampaignProps(campaignObj, updatedProps, isSourceCampaign)
      }

      return campaignObj
    })
  }

  let updateCampaignsInVisibleCampaigns = (
    visibleCampaigns,
    campaignKeys,
    campaignKeyToUpdatedProps,
    isSourceCampaign = true
  ) => {
    return visibleCampaigns.map((campaignObj) => {
      if (campaignKeys.includes(campaignObj.key)) {
        let updatedProps = campaignKeyToUpdatedProps.get(campaignObj.key)

        return updateCampaignProps(campaignObj, updatedProps, isSourceCampaign)
      }

      return campaignObj
    })
  }

  let updateCampaignInAllCampaigns = (allCampaigns, campaignKey, updatedProperties) => {
    return allCampaigns.map((campaignObj) => {
      if (campaignObj.key == campaignKey) {
        return updateCampaignProps(campaignObj, updatedProperties)
      }

      return campaignObj
    })
  }

  let updateCampaignsInAllCampaigns = (allCampaigns, campaignKeys, campaignKeyToUpdatedProps) => {
    return allCampaigns.map((campaignObj) => {
      if (campaignKeys.includes(campaignObj.key)) {
        let updatedProps = campaignKeyToUpdatedProps.get(campaignObj.key)

        return updateCampaignProps(campaignObj, updatedProps)
      }

      return campaignObj
    })
  }

  let updateCampaign = (campaignKey, updatedProperties) => {
    return {
      visibleCampaigns: updateCampaignInVisibleCampaigns(state.visibleCampaigns, campaignKey, updatedProperties),
      allCampaigns: updateCampaignInAllCampaigns(state.allCampaigns, campaignKey, updatedProperties),
    }
  }

  let updateCampaigns = (campaignKeyToUpdatedProperties) => {
    let campaignKeys = Array.from(campaignKeyToUpdatedProperties.keys())

    return {
      visibleCampaigns: updateCampaignsInVisibleCampaigns(
        state.visibleCampaigns,
        campaignKeys,
        campaignKeyToUpdatedProperties
      ),
      allCampaigns: updateCampaignsInAllCampaigns(state.allCampaigns, campaignKeys, campaignKeyToUpdatedProperties),
    }
  }

  let retrieveSortedAndFilteredCampaigns = (nextState) => {
    if (!nextState.allCampaigns) {
      return null
    }

    let resolve = (path, obj) => {
      return path.split(".").reduce((prev, curr) => {
        return prev ? prev[curr] : null
      }, obj || self)
    }

    let filterMap = nextState.filters
    let sortedAndFilteredCampaigns = nextState.allCampaigns.slice()

    if (filterMap.size !== 0) {
      let filterSpec = new FilterContainerSpec(filterMap)
      sortedAndFilteredCampaigns = sortedAndFilteredCampaigns.filter((campaign) => {
        return filterSpec.isSatisfied(campaign)
      })
    }

    if (nextState.currentFilterStatus.name !== campaignsInitialState.currentFilterStatus.name) {
      sortedAndFilteredCampaigns = sortedAndFilteredCampaigns.filter((campaign) => {
        let filterValues = nextState.currentFilterStatus.filterValues

        if (filterValues == "current-dates-array") {
          filterValues = getDatesRange(state.datePickerDates.startDate, state.datePickerDates.endDate)
        }

        return filterValues.includes(campaign[nextState.currentFilterStatus.filterType])
      })
    } else {
      sortedAndFilteredCampaigns = sortedAndFilteredCampaigns.filter((campaign) => {
        return !("is_pure_traffic_source" in campaign) || campaign.is_pure_traffic_source === false
      })
    }

    // Finally sort according to the selected sorting field and sorting direction
    let fieldNameToSortBy = nextState.sortBy

    if (fieldNameToSortBy.includes(".")) {
      // If we're sorting by a complex (inner) field, we first resolve the field and "flatten" it so that the campaign
      // will also contain the value flattened so we won't need to repeat this process again while already sorting.
      sortedAndFilteredCampaigns = sortedAndFilteredCampaigns.map((campaign) => {
        return { ...campaign, [fieldNameToSortBy]: resolve(fieldNameToSortBy, campaign) }
      })
    }

    // Using the "timsort" sorting algorithm instead of the native sort for better performance on large arrays
    sortedAndFilteredCampaigns.timsort((a, b) =>
      genericCompareFunc(a[fieldNameToSortBy], b[fieldNameToSortBy], nextState.sortDirection)
    )

    return sortedAndFilteredCampaigns
  }

  let retrieveFiltersWithCount = (nextState) => {
    if (!nextState.allCampaigns) {
      return null
    }

    let sortedAndFilteredCampaigns = nextState.visibleCampaigns
    let filterMap = new Map()

    nextState.filters.forEach((value, key) => {
      filterMap.set(
        key,
        Object.assign({}, value, {
          includeFilters: value.includeFilters.map((item) => {
            let count = 0
            let filterSpec = new FilterItemSpec(item)
            sortedAndFilteredCampaigns.forEach((campaign) => {
              count += filterSpec.isSatisfied(campaign) ? 1 : 0
            })
            return Object.assign({}, item, { count })
          }),
          excludeFilters: value.excludeFilters.map((item) => {
            let count = 0
            let filterSpec = new FilterItemSpec(item)
            sortedAndFilteredCampaigns.forEach((campaign) => {
              count += filterSpec.isSatisfied(campaign) ? 0 : 1
            })
            return Object.assign({}, item, { count })
          }),
        })
      )
    })

    return filterMap
  }

  let calcSummaryRow = (nextState) => {
    let summaryRow = {}
    let totalsObj = {
      visits: 0,
      daily_budget: 0,
      total_budget: 0,
      page_views: 0,
      results: 0,
      ctr: 0,
      clicks: 0,
      roi: 0,
      daily_spent: 0,
      revenue: 0,
      cost_per_click: 0,
      visit_value: 0,
      profit: 0,
      pages_per_visit: 0,
      impressions: 0,
      bounce_rate: 0,
      bounces: 0,
      click_value: 0,
      cost_per_result: 0,
      website_impressions: 0,
      visit_duration: 0,
      results_rate: 0,
      ecom_yielding_clicks: 0,
      affiliate_rpc: 0,
      affiliate_item_rpm: 0,
      affiliate_orders: 0,
      affiliate_order_rate: 0,
      rsoc_yielding_clicks: 0,
      revenue_ecom: 0,
      ads_revenue: 0,
      keyword_impressions: 0,
      keyword_impression_rate: 0,
      searches_rate: 0,
      searches_funnel_rpm: 0,
      searches: 0,
      revenue_rsoc: 0,
    }

    let totals = Object.assign({}, totalsObj)

    let calcTotals = (campaign, totalsObj) => {
      totalsObj.visits += isNaN(campaign.visits) ? 0 : campaign.visits
      totalsObj.visit_duration += isNaN(campaign.visit_duration) ? 0 : campaign.visit_duration
      totalsObj.clicks += isNaN(campaign.clicks) ? 0 : campaign.clicks
      totalsObj.revenue += isNaN(campaign.revenue) ? 0 : campaign.revenue
      totalsObj.daily_budget += campaign.daily_budget
      totalsObj.total_budget += campaign.total_budget
      totalsObj.impressions += isNaN(campaign.impressions) ? 0 : campaign.impressions
      totalsObj.daily_spent += isNaN(campaign.daily_spent) ? 0 : campaign.daily_spent
      totalsObj.page_views += isNaN(campaign.page_views) ? 0 : campaign.page_views
      totalsObj.results += isNaN(campaign.results) ? 0 : campaign.results
      totalsObj.bounces += isNaN(campaign.bounces) ? 0 : campaign.bounces
      totalsObj.website_impressions += isNaN(campaign.website_impressions) ? 0 : campaign.website_impressions
      totalsObj.ecom_yielding_clicks += isNaN(campaign.ecom_yielding_clicks) ? 0 : campaign.ecom_yielding_clicks
      totalsObj.affiliate_orders += isNaN(campaign.affiliate_orders) ? 0 : campaign.affiliate_orders
      totalsObj.rsoc_yielding_clicks += isNaN(campaign.rsoc_yielding_clicks) ? 0 : campaign.rsoc_yielding_clicks
      totalsObj.revenue_ecom += isNaN(campaign.revenue_ecom) ? 0 : campaign.revenue_ecom
      totalsObj.ads_revenue += isNaN(campaign.ads_revenue) ? 0 : campaign.ads_revenue
      totalsObj.keyword_impressions += isNaN(campaign.keyword_impressions) ? 0 : campaign.keyword_impressions
      totalsObj.keyword_impression_rate += isNaN(campaign.keyword_impression_rate)
        ? 0
        : campaign.keyword_impression_rate
      totalsObj.searches += isNaN(campaign.searches) ? 0 : campaign.searches
      totalsObj.searches_rate += isNaN(campaign.searches_rate) ? 0 : campaign.searches_rate
      totalsObj.searches_funnel_rpm += isNaN(campaign.searches_funnel_rpm) ? 0 : campaign.searches_funnel_rpm
      totalsObj.revenue_rsoc += isNaN(campaign.revenue_rsoc) ? 0 : campaign.revenue_rsoc
    }

    nextState.visibleCampaigns.forEach((campaign) => {
      if (!Object.keys(campaign).includes("is_pure_traffic_source")) {
        calcTotals(campaign, totals)
      }
    })
    let calcSummaryRowData = (totals) => {
      let summaryRowData = {}
      let num_of_pure_campaigns = nextState.visibleCampaigns.filter(
        (campaign) => !Object.keys(campaign).includes("is_pure_traffic_source")
      ).length
      summaryRowData.visits = totals.visits
      summaryRowData.visit_duration = totals.visit_duration / num_of_pure_campaigns
      summaryRowData.results_rate = isFinite(totals.results / totals.clicks) ? totals.results / totals.clicks : 0
      summaryRowData.clicks = totals.clicks
      summaryRowData.revenue = totals.revenue
      summaryRowData.page_views = totals.page_views
      summaryRowData.daily_spent = totals.daily_spent
      summaryRowData.roi = isFinite(totals.revenue / totals.daily_spent) ? totals.revenue / totals.daily_spent : 0
      summaryRowData.profit = totals.revenue - totals.daily_spent
      summaryRowData.cost_per_click = isFinite(totals.daily_spent / totals.clicks)
        ? totals.daily_spent / totals.clicks
        : 0
      summaryRowData.visit_value = isFinite(totals.revenue / totals.visits) ? totals.revenue / totals.visits : 0
      summaryRowData.pages_per_visit = isFinite(totals.page_views / totals.visits)
        ? totals.page_views / totals.visits
        : 0
      summaryRowData.ctr = totals.clicks / totals.impressions
      summaryRowData.results = totals.results
      summaryRowData.cost_per_result = isFinite(totals.daily_spent / totals.results)
        ? totals.daily_spent / totals.results
        : 0
      summaryRowData.bounce_rate = isFinite(totals.bounces / totals.visits) ? totals.bounces / totals.visits : 0
      summaryRowData.daily_budget = totals.daily_budget
      summaryRowData.total_budget = totals.total_budget
      summaryRowData.rpm = (totals.ads_revenue / totals.website_impressions) * 1000
      summaryRowData.rpm_page_views = (totals.ads_revenue / totals.page_views) * 1000
      summaryRowData.visit_cost = isFinite(totals.daily_spent / totals.visits) ? totals.daily_spent / totals.visits : 0
      summaryRowData.click_value = isFinite(totals.revenue / totals.clicks) ? totals.revenue / totals.clicks : 0
      summaryRowData.visit_profit = totals.revenue / totals.visits - totals.daily_spent / totals.visits
      summaryRowData.website_impressions = totals.website_impressions
      summaryRowData.ecom_yielding_clicks = totals.ecom_yielding_clicks
      summaryRowData.affiliate_orders = totals.affiliate_orders,
      summaryRowData.affiliate_rpc = isFinite(totals.revenue_ecom / totals.ecom_yielding_clicks) ? totals.revenue_ecom / totals.ecom_yielding_clicks : 0
      summaryRowData.affiliate_item_rpm = isFinite(totals.revenue_ecom * 1000.0 / totals.page_views) ? totals.revenue_ecom * 1000.0 / totals.page_views : 0
      summaryRowData.affiliate_order_rate = isFinite(totals.affiliate_orders / totals.ecom_yielding_clicks) ? totals.affiliate_orders / totals.ecom_yielding_clicks : 0
      summaryRowData.rsoc_yielding_clicks = totals.rsoc_yielding_clicks
      summaryRowData.revenue_ecom = totals.revenue_ecom
      summaryRowData.keyword_impressions = totals.keyword_impressions
      summaryRowData.keyword_impression_rate = totals.keyword_impressions / totals.visits
      summaryRowData.searches_rate = totals.searches / totals.visits
      summaryRowData.searches_funnel_rpm = totals.revenue_rsoc / totals.keyword_impressions
      return summaryRowData
    }

    summaryRow = calcSummaryRowData(totals)
    return summaryRow
  }

  let addCampaignsProps = (campaigns) => {
    return campaigns
      ? Object.keys(campaigns).map((key) => {
          // For each campaign, we add client entities (key and isLoading flag)
          let campaign = Object.assign({}, campaigns[key])
          campaign.key = key
          campaign.isCampaignStatusLoading = false
          campaign.isCampaignBidLoading = false
          campaign.rpmData = []
          campaign.isCampaignRpmLoading = false
          campaign.newRoi = null
          campaign.selected = false
          campaign.external_id = key.split(":")[1]
          overrideCampaignAttributes(campaign)

          if (isUnknownCampaignTrafficSource(campaign)) {
            campaign.visit_profit = null
            campaign.estimated_profit = null
          }

          return campaign
        })
      : null
  }

  // Actual reducer starts here
  switch (action.type) {
    case CampaignActionTypes.RESET_CAMPAIGNS_PAGE_DATES:
      return Object.assign({}, state, {
        datePickerDates: campaignsInitialState.datePickerDates,
      })
    case CampaignActionTypes.CAMPAIGNS_DATEPICKER_DATES_SELECTED:
      let today = Moment()
      let isToday = false
      var dateType = isNullOrUndefined(action.dateType) ? CUSTOM : action.dateType
      var endDate = dateType === CUSTOM ? action.endDate : getEndDate(dateType)
      var startDate = dateType === CUSTOM ? action.startDate : getStartDate(dateType)

      if (today.isSame(action.startDate, "day") && !action.showLastHour) {
        isToday = true
      }

      return Object.assign({}, state, {
        datePickerDates: {
          startDate: startDate,
          endDate: endDate,
          dateType: dateType,
          showLastHour: action.showLastHour,
          isGeneratedReport: false,
        },
        isToday,
      })

    case CampaignActionTypes.FETCH_CAMPAIGNS_RESPONSE_V2:
      let campaigns = null
      let tags = new Set()
      let creators = new Map()
      let languages = new Set()
      let articleLanguages = new Set()
      let campaign_names = new Set()
      let conversions = new Set()

      campaigns = addCampaignsProps(action.campaigns)
      campaigns.forEach((campaign) => {
        if (campaign.tracking_code) {
          campaign_names.add(campaign.tracking_code)
        }

        if (campaign?.targeting?.locales?.length > 0) {
          let parsedLanguageName = getLangNameFromCode(campaign.targeting.locales[0])?.name
          if (parsedLanguageName) {
            languages.add(parsedLanguageName)
          }
        }
        if (campaign?.lang) {
          let parsedArticleLanguage = getLangNameFromCode(campaign.lang)?.name
          if (parsedArticleLanguage) {
            articleLanguages.add(parsedArticleLanguage)
          }
        }
        if (campaign?.conversion_name && campaign.conversion_name !== "--") {
          conversions.add(campaign.conversion_name)
        }

        if (campaign.created_by && campaign.user_name) {
          creators.set(campaign.created_by, campaign.user_name)
        }

        if (campaign.tags.length > 0) {
          campaign.tags.forEach((tag) => {
            tags.add(tag.name)
          })
        }
      })

      let nextState = Object.assign({}, state, {
        // Taking the campaigns object returned from the server and turning it into an array for easier manipulation
        allCampaigns: campaigns,
        campaignNames: [...campaign_names],
        isLoading: action.isLoading,
        sortBy: getDefaultSortByColumn(state),
        sortDirection: getDefaultSortDirection(),
        hasCampaigns: action.hasCampaigns,
        currentPage: 1,
        lastUpdate: Moment().unix(),
        campaignLanguages: [...languages],
        campaignArticleLanguages: [...articleLanguages],
        campaignConversions: [...conversions],
        campaignTags: Array.from(tags).map((tag) => {
          return { name: tag }
        }),
        isNextResponseUserGeneratedReport: false,
        campaignCreators: creators,
        allCampaignsSelected: false,
        campaignsSelectionOrder: [],
        lastCampaignsRoute: window.location.pathname,
      })
      nextState.visibleCampaigns = retrieveSortedAndFilteredCampaigns(nextState)
      nextState.filters = retrieveFiltersWithCount(nextState)
      nextState.summaryRow = calcSummaryRow(nextState)
      return nextState

    case CampaignActionTypes.PAUSE_CAMPAIGN_RESPONSE:
      var updatedCampaignsLists = pauseCampaignResponse(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.ACTIVE_CAMPAIGN_RESPONSE:
      var updatedCampaignsLists = activeCampaignResponse(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.STOP_CAMPAIGN_ERROR_RESPONSE:
      var updatedCampaignsLists = stopCampaignErrorResponse(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.START_CAMPAIGN_ERROR_RESPONSE:
      var updatedCampaignsLists = startCampaignErrorResponse(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.BULK_START_CAMPAIGNS_RESPONSE:
    case CampaignActionTypes.BULK_STOP_CAMPAIGNS_RESPONSE:
    case CampaignActionTypes.BULK_START_CAMPAIGNS_ERROR_RESPONSE:
    case CampaignActionTypes.BULK_STOP_CAMPAIGNS_ERROR_RESPONSE:
      var updatedCampaignsLists = bulkStartStopCampaignsResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.BULK_UPDATE_CAMPAIGN_TAGS:
      var result = bulkUpdateCampaignsTags(action, updateCampaigns, state.campaignTags)

      return Object.assign({}, state, {
        visibleCampaigns: result.updatedCampaigns.visibleCampaigns,
        allCampaigns: result.updatedCampaigns.allCampaigns,
        campaignTags: Array.from(result.campaignTags).map((tag) => {
          return { name: tag }
        }),
      })

    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BUDGET_RESPONSE:
    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BUDGET_ERROR_RESPONSE:
      var updatedCampaignsLists = bulkEditCampaignsBudgetResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BID_RESPONSE:
    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_BID_ERROR_RESPONSE:
      var updatedCampaignsLists = bulkEditCampaignsBidResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.BULK_EDIT_CAMPAIGNS_TARGETING_RESPONSE:
      var updatedCampaignsLists = bulkEditCampaignsTargetingResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignPopupActionTypes.UPDATE_CAMPAIGN_TAGS:
      var result = updateCampaignTags(action, updateCampaign, state.campaignTags)

      return Object.assign({}, state, {
        visibleCampaigns: result.updatedCampaigns.visibleCampaigns,
        allCampaigns: result.updatedCampaigns.allCampaigns,
        campaignTags: Array.from(result.campaignTags).map((tag) => {
          return { name: tag }
        }),
      })

    case CampaignActionTypes.CHANGE_BID_RESPONSE:
    case CampaignActionTypes.CHANGE_BID_ERROR_RESPONSE:
      var updatedCampaignsLists = changeBidResponse(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.CHANGE_ROAS_RESPONSE:
    case CampaignActionTypes.CHANGE_ROAS_ERROR_RESPONSE:
      var updatedCampaignsLists = changeRoasResponse(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.GET_RPM_RESPONSE:
      return Object.assign({}, state, {
        visibleCampaigns: updateCampaignInVisibleCampaigns(
          state.visibleCampaigns,
          action.campaign.campaign_id,
          {
            isCampaignRpmLoading: false,
            rpmData: action.rpmData,
          },
          action.isSourceCampaign
        ),
      })

    case CampaignActionTypes.CAMPAIGN_RPM_LOADING:
      return Object.assign({}, state, {
        visibleCampaigns: updateCampaignInVisibleCampaigns(
          state.visibleCampaigns,
          action.campaign.campaign_id,
          {
            isCampaignRpmLoading: action.isLoading,
          },
          action.isSourceCampaign
        ),
      })

    case CampaignActionTypes.CAMPAIGN_REVENUE_LOADING:
      return Object.assign({}, state, {
        visibleCampaigns: updateCampaignInVisibleCampaigns(
          state.visibleCampaigns,
          action.campaign.key,
          {
            isCampaignRevenueLoading: action.isLoading,
          },
          action.isSourceCampaign
        ),
      })

    case CampaignActionTypes.CAMPAIGN_STATUS_LOADING:
      var updatedCampaignsLists = campaignStatusLoading(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.BULK_CAMPAIGN_STATUS_LOADING:
      var updatedCampaignsLists = bulkCampaignStatusLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.BULK_ACTIVATE_CAMPAIGNS_LOADING: {
      let updatedCampaignsLists = bulkCampaignsActivationLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })
    }

    case CampaignActionTypes.BULK_SMART_ACTIVATION_CAMPAIGNS_RESPONSE:
    case CampaignActionTypes.BULK_ACTIVATE_CAMPAIGNS_ERROR_RESPONSE: {
      let updatedCampaignsLists = bulkCampaignActivationResponse(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })
    }

    case CampaignActionTypes.CAMPAIGN_BID_LOADING:
      var updatedCampaignsLists = campaignBidLoading(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.CAMPAIGNS_SORT_BY:
      // Default sort direction is DESC, unless we're sorting what's already sorted
      let sortDirection = "DESC"
      if (state.sortBy == action.sortBy && state.sortDirection == "DESC") {
        sortDirection = "ASC"
      }

      nextState = Object.assign({}, state, {
        sortBy: action.sortBy,
        sortDirection,
        currentPage: 1,
        allCampaignsSelected: false,
        campaignsSelectionOrder: [],
      })
      nextState.visibleCampaigns = retrieveSortedAndFilteredCampaigns(nextState)
      nextState.filters = retrieveFiltersWithCount(nextState)
      nextState.summaryRow = calcSummaryRow(nextState)
      return nextState

    case CampaignActionTypes.FILTER_CAMPAIGNS:
      nextState = Object.assign({}, state, {
        filters: action.filters,
        allCampaignsSelected: false,
        campaignsSelectionOrder: [],
        filtersToRestore: null,
      })
      nextState.visibleCampaigns = retrieveSortedAndFilteredCampaigns(nextState)
      nextState.filters = retrieveFiltersWithCount(nextState)
      nextState.summaryRow = calcSummaryRow(nextState)

      return nextState

    case CampaignActionTypes.CAMPAIGNS_LIST_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      })

    case CampaignActionTypes.UPDATE_CAMPAIGNS_PAGE:
      var visibleCampaignsList = state.visibleCampaigns.slice()
      visibleCampaignsList = visibleCampaignsList.map((campaign) => {
        return { ...campaign, selected: false }
      })

      return Object.assign({}, state, {
        currentPage: action.currentPage,
        visibleCampaigns: visibleCampaignsList,
        allCampaignsSelected: false,
        campaignsSelectionOrder: [],
      })

    case CampaignActionTypes.RESET_CAMPAIGNS_PAGINATION:
      return Object.assign({}, state, {
        currentPage: 1,
      })

    case CampaignActionTypes.FILTER_CAMPAIGNS_BY_STATUS:
      nextState = Object.assign({}, state, {
        currentFilterStatus: action.currentFilterStatus,
        currentPage: 1,
        allCampaignsSelected: false,
        campaignsSelectionOrder: [],
      })
      nextState.visibleCampaigns = retrieveSortedAndFilteredCampaigns(nextState)
      nextState.filters = retrieveFiltersWithCount(nextState)

      if (action.isUpdatingCampaignsNames) {
        nextState.campaignNames = Array.from(nextState.visibleCampaigns, (campaign) => campaign.tracking_code)
      }
      nextState.summaryRow = calcSummaryRow(nextState)
      return nextState

    case CampaignActionTypes.CAMPAIGN_MARK_SELECTED:
      // We're only updating the visible campaigns, no point in updating even those in the allCampaigns list
      var visibleCampaignsList = state.visibleCampaigns.slice()
      var campaignsSelectionOrder = state.campaignsSelectionOrder.slice()
      var selectedCampaignIndex = visibleCampaignsList.findIndex((campaign) => campaign.key === action.campaign.key)

      if (action.event.nativeEvent.shiftKey) {
        // Shift key is selected, we're selecting all the campaigns from the last selected campaign up to the current one
        // (works just like in Mac's finder or Windows's file explorer).
        let lastSelectedCampaignIndex =
          state.campaignsSelectionOrder.length > 0
            ? state.campaignsSelectionOrder[state.campaignsSelectionOrder.length - 1]
            : 0

        let minCampaignIndex = Math.min(lastSelectedCampaignIndex, selectedCampaignIndex)
        let maxCampaignIndex = Math.max(lastSelectedCampaignIndex, selectedCampaignIndex)

        for (let i = minCampaignIndex; i <= maxCampaignIndex; i++) {
          visibleCampaignsList[i] = { ...visibleCampaignsList[i], selected: true }
        }

        campaignsSelectionOrder = campaignsSelectionOrder.filter((index) => index !== selectedCampaignIndex)
        campaignsSelectionOrder.push(selectedCampaignIndex)
      } else {
        // No shift key, we're selecting / deselecting one campaign and updating the selection order
        visibleCampaignsList = updateCampaignInVisibleCampaigns(state.visibleCampaigns, action.campaign.key, {
          selected: action.selected,
        })

        if (action.selected) {
          campaignsSelectionOrder.push(selectedCampaignIndex)
        } else {
          campaignsSelectionOrder = campaignsSelectionOrder.filter((index) => index !== selectedCampaignIndex)
        }
      }

      // Checking if the allCampaignsSelected checkbox should be checked
      var allCampaignsSelected = true
      var firstCampaignIndex = state.currentPage * state.itemsPerPage - state.itemsPerPage

      for (
        var i = firstCampaignIndex;
        i < Math.min(state.visibleCampaigns.length, firstCampaignIndex + state.itemsPerPage);
        i++
      ) {
        if (!visibleCampaignsList[i].selected) {
          allCampaignsSelected = false
          break
        }
      }

      return Object.assign({}, state, {
        visibleCampaigns: visibleCampaignsList,
        allCampaignsSelected,
        campaignsSelectionOrder,
      })

    case CampaignActionTypes.CAMPAIGN_MARK_ALL_SELECTED:
      var visibleCampaignsList = state.visibleCampaigns.slice()
      var firstCampaignIndex = state.currentPage * state.itemsPerPage - state.itemsPerPage
      var campaignsSelectionOrder = state.campaignsSelectionOrder.slice()

      for (
        var i = firstCampaignIndex;
        i < Math.min(state.visibleCampaigns.length, firstCampaignIndex + state.itemsPerPage);
        i++
      ) {
        visibleCampaignsList[i] = { ...visibleCampaignsList[i], selected: !state.allCampaignsSelected }
      }

      if (state.allCampaignsSelected) {
        // Next state of allCampaignsSelected will be false, so we clear the selection order
        campaignsSelectionOrder = []
      }

      return Object.assign({}, state, {
        visibleCampaigns: visibleCampaignsList,
        allCampaignsSelected: !state.allCampaignsSelected,
        campaignsSelectionOrder,
      })

    case CampaignActionTypes.CLEAR_CAMPAIGNS_FILTER:
      return Object.assign({}, state, {
        filters: new Map(),
        currentFilterStatus: campaignsInitialState.currentFilterStatus,
      })
    case CampaignActionTypes.CAMPAIGN_DAILY_BUDGET_LOADING:
      var updatedCampaignsLists = campaignDailyBudgetLoading(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.CAMPAIGN_TOTAL_BUDGET_LOADING:
      var updatedCampaignsLists = campaignTotalBudgetLoading(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.BULK_CAMPAIGNS_DAILY_BUDGET_LOADING:
      var updatedCampaignsLists = bulkCampaignsDailyBudgetLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.BULK_CAMPAIGNS_BID_LOADING:
      var updatedCampaignsLists = bulkCampaignsBidLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.BULK_CAMPAIGNS_TOTAL_BUDGET_LOADING:
      var updatedCampaignsLists = bulkCampaignsTotalBudgetLoading(action, updateCampaigns)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.CHANGE_DAILY_BUDGET_RESPONSE:
    case CampaignActionTypes.CHANGE_DAILY_BUDGET_ERROR_RESPONSE:
      var updatedCampaignsLists = changeCampaignDailyBudgetResponse(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.CHANGE_TOTAL_BUDGET_RESPONSE:
    case CampaignActionTypes.CHANGE_TOTAL_BUDGET_ERROR_RESPONSE:
      var updatedCampaignsLists = changeCampaignTotalBudgetResponse(action, updateCampaign)

      return Object.assign({}, state, {
        visibleCampaigns: updatedCampaignsLists.visibleCampaigns,
        allCampaigns: updatedCampaignsLists.allCampaigns,
      })

    case CampaignActionTypes.UPDATE_CAMPAIGNS_SUMMARY_RPM_RESPONSE:
      return Object.assign({}, state, {
        summaryRow: Object.assign({}, state.summaryRow, {
          rpmData: action.rpmData,
        }),
      })

    case CampaignActionTypes.UPDATE_CAMPAIGNS_SUMMARY_REVENUE_RESPONSE:
      return Object.assign({}, state, {
        summaryRow: Object.assign({}, state.summaryRow, {
          revenueData: action.revenueData,
        }),
      })

    case CampaignActionTypes.ROLLBACK_CAMPAIGNS_PAGE:
      return Object.assign({}, state, {
        isLoading: false,
        hasCampaigns: true,
        allCampaigns: [],
        visibleCampaigns: [],
        isNextResponseUserGeneratedReport: false,
      })

    case CampaignActionTypes.SHOULD_UPDATE_CAMPAIGNS_PAGE:
      var startDate =
        state.datePickerDates.dateType === CUSTOM
          ? state.datePickerDates.startDate
          : getStartDate(state.datePickerDates.dateType)
      var endDate =
        state.datePickerDates.dateType === CUSTOM && !state.datePickerDates.showLastHour
          ? state.datePickerDates.endDate
          : getEndDate(state.datePickerDates.dateType)
      return Object.assign({}, state, {
        shouldUpdatePage: action.shouldUpdate,
        datePickerDates: {
          startDate: startDate,
          endDate: endDate,
          showLastHour: state.datePickerDates.showLastHour,
          isGeneratedReport: state.datePickerDates.isGeneratedReport,
          dateType: state.datePickerDates.dateType,
        },
      })

    case CampaignActionTypes.HANDLE_HIDE_CAMPAIGNS_V2_PAGE:
      return Object.assign({}, state, {
        hideClicked: action.hideClicked,
      })

    case CampaignActionTypes.USER_GENERATED_REPORT_ON_CAMPAIGNS_PAGE:
      return Object.assign({}, state, {
        isNextResponseUserGeneratedReport: true,
        datePickerDates: {
          startDate: action.startDate,
          endDate: action.endDate,
          showLastHour: false,
          isGeneratedReport: true,
          dateType: state.datePickerDates.dateType,
        },
      })

    case ActionTypes.NETWORK_SELECTED:
      nextState = Object.assign({}, state, {
        datePickerDates: Object.assign({}, state.datePickerDates, {
          isGeneratedReport:
            state.isNextResponseUserGeneratedReport ||
            (state.datePickerDates.isGeneratedReport && isCampaignPopupRelatedLocationStateV2())
              ? state.datePickerDates.isGeneratedReport
              : false,
        }),
        isNetworkReport: true,
      })
      nextState.sortBy = getDefaultSortByColumn(nextState)
      return nextState

    case CampaignActionTypes.SET_CAMPAIGNS_REPORT_TYPE: // need it?!?!?
      var isTodayObj = {
        isToday: false,
      }

      return Object.assign({}, state, {
        reportType: action.reportType,
        datePickerDates: Object.assign({}, state.datePickerDates, {
          isGeneratedReport: action.overrideIsGeneratedReport ? false : state.datePickerDates.isGeneratedReport,
        }),
        ...isTodayObj,
      })

    case CampaignActionTypes.UPDATE_ITEMS_PER_PAGE:
      var visibleCampaignsList = state.visibleCampaigns.slice()

      visibleCampaignsList = visibleCampaignsList.map((campaign) => {
        return { ...campaign, selected: false }
      })

      return Object.assign({}, state, {
        itemsPerPage: action.itemsPerPage,
        visibleCampaigns: visibleCampaignsList,
        allCampaignsSelected: false,
        campaignsSelectionOrder: [],
      })

    case ActionTypes.NETWORK_TAGS_RESPONSE_V2:
      let networkTags = []

      if (action.networkTags) {
        networkTags = action.networkTags.map((tag, index) => {
          return {
            name: tag,
            index,
          }
        })
      }

      return Object.assign({}, state, {
        networkTags,
      })

    default:
      return state
  }
}

export { campaignPageReducerV2 }
