import WebStorageConsts from "../common/consts/webStorageConsts"

// This is an abstraction of localStorage, it is meant for saving localStorage data to the correct storage according to the environment we're in.
// For example: in an historical demo environment we want to save values to sessionStorage so they can be easily discarded when the demo finishes.
// There are some exceptions, for example the auth token must always be saved to localStorage in order for the user's login info to be saved across tabs.
class WebStorage {
  get(storageItem) {
    if (this.shouldUseSessionStorage(storageItem)) {
      return sessionStorage.getItem(storageItem.key)
    }

    return localStorage.getItem(storageItem.key)
  }

  set(storageItem, value) {
    if (this.shouldUseSessionStorage(storageItem)) {
      sessionStorage.setItem(storageItem.key, value)
    } else {
      localStorage.setItem(storageItem.key, value)
    }
  }

  remove(storageItem) {
    if (this.shouldUseSessionStorage(storageItem)) {
      sessionStorage.removeItem(storageItem.key)
    } else {
      localStorage.removeItem(storageItem.key)
    }
  }

  shouldUseSessionStorage(storageItem) {
    return storageItem.type === WebStorageConsts.STORAGE_TYPES.ALWAYS_IN_SESSION_STORAGE
  }

  clear() {
    localStorage.clear()
    sessionStorage.clear()
  }
}

export const webStorage = new WebStorage()
