class ChartColors {}

ChartColors.SESSIONS = "#42a5f5"
ChartColors.BY_HOUR_SESSIONS = "#aed6fb"
ChartColors.BY_PAGE_PAGE_VIEWS = "#aed6fb"
ChartColors.CHURN_RATE = "#0dcb5c"
ChartColors.CHURN_RATE_STD_LINE = "#86e5ad"
ChartColors.USER_VALUE = "#0dcb5c"
ChartColors.USER_VALUE_STD = "#f1fcf5"
ChartColors.USER_VALUE_STD_LINE = "#86e5ad"
ChartColors.ACTUAL_VISIT_VALUE = "#268f4f"
ChartColors.REALTIME_BID = "#ff6f00"
ChartColors.ROI = "#ab47bc"
ChartColors.ESTIMATED_PROFIT = "#00bcd4"
ChartColors.RPM = "#7cb342"
ChartColors.CTR = "#ef3fac"
ChartColors.PAGES_PER_SESSION = "#ebbc3b"
ChartColors.BOUNCE_RATE = "#f12938"
ChartColors.EXIT_RATE = "#00897b"
ChartColors.REALTIME_VISITORS = "#2e92ea"
ChartColors.DAILY_SPEND = "#F74725"
ChartColors.REVENUE = "#0EB99B"
ChartColors.CLICK = "#FFC107"
ChartColors.IMPRESSION = "#FF5722"
ChartColors.TOTAL_CLICK = "#0dcb5c"

export default ChartColors
