import Consts from "../app/consts"
import TrackingConsts from "../common/consts/trackingConsts"
import deviceDetector from "../utils/currentDevice"
import mixpanel from "mixpanel-browser"

class EventsTracker {
  constructor() {
    this.device = navigator.userAgent
    this.deviceType = deviceDetector.type
  }

  trackPageView(url) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.PAGE_VIEW,
      [TrackingConsts.FIELD_NAMES.URL]: url,
    }

    this._trackInternal(event)
  }

  trackSearch(query, section_name) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.SEARCH,
      [TrackingConsts.FIELD_NAMES.QUERY]: query,
      [TrackingConsts.FIELD_NAMES.SECTION_NAME]: section_name,
    }

    this._trackInternal(event)
  }

  trackDrawerOpen(drawerName, numberOfNewItems) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.DRAWER_OPEN,
      [TrackingConsts.FIELD_NAMES.DRAWER_NAME]: drawerName,
      [TrackingConsts.FIELD_NAMES.DRAWER_ITEMS]: numberOfNewItems,
    }

    this._trackInternal(event)
  }

  trackDrawerClose(drawerName) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.DRAWER_CLOSE,
      [TrackingConsts.FIELD_NAMES.DRAWER_NAME]: drawerName,
    }

    this._trackInternal(event)
  }

  trackRefreshMessageDisplayed(message) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.MESSAGE_DISPLAYED,
      [TrackingConsts.FIELD_NAMES.MESSAGE_BODY]: message,
    }

    this._trackInternal(event)
  }

  trackRefreshClicked(message) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.MESSAGE_REFRESHED,
      [TrackingConsts.FIELD_NAMES.MESSAGE_BODY]: message,
    }

    this._trackInternal(event)
  }

  trackHideRefreshMessage(message) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.MESSAGE_HIDE,
      [TrackingConsts.FIELD_NAMES.MESSAGE_BODY]: message,
    }

    this._trackInternal(event)
  }

  trackSortCampaign(columnName, order) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.SORT_COLUMN,
      [TrackingConsts.FIELD_NAMES.COLUMN_NAME]: columnName,
      [TrackingConsts.FIELD_NAMES.ORDER]: order,
    }

    this._trackInternal(event)
  }

  trackAddFilter(filterType, filterOperation, filterValueName) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.FILTER_ADDED,
      [TrackingConsts.FIELD_NAMES.FILTER_NAME]: filterType,
      [TrackingConsts.FIELD_NAMES.FILTER_ACTION]: filterOperation,
      [TrackingConsts.FIELD_NAMES.FILTER_VALUE_NAME]: filterValueName,
    }

    this._trackInternal(event)
  }

  trackClearAllFilters(filterCount) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.FILTERS_CLEAR,
      [TrackingConsts.FIELD_NAMES.FILTERS_COUNT]: filterCount,
    }

    this._trackInternal(event)
  }

  trackUpdateFilter(filterType, filterOperation) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.FILTER_UPDATE,
      [TrackingConsts.FIELD_NAMES.FILTER_NAME]: filterType,
      [TrackingConsts.FIELD_NAMES.FILTER_ACTION]: filterOperation,
    }

    this._trackInternal(event)
  }

  trackStatusFilter(status) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.STATUS_FILTER,
      [TrackingConsts.FIELD_NAMES.STATUS]: status,
    }

    this._trackInternal(event)
  }

  trackChangeCampaignStatus(campaignTrackingCode, oldStatus, newStatus) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_STATUS,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_STATUS]: newStatus,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_OLD_STATUS]: oldStatus,
    }

    this._trackInternal(event)
  }

  trackDuplicateCampaignClicked(campaignTrackingCode, origin) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_DUPLICATE,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_DUPLICATE_ORIGIN]: origin,
    }

    this._trackInternal(event)
  }

  trackEditOnSourceClicked(campaignTrackingCode) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_EDIT_ON_SOURCE,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
    }

    this._trackInternal(event)
  }

  trackArticleLinkClicked(campaignTrackingCode) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_ARTICLE_LINK,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
    }

    this._trackInternal(event)
  }

  trackSupportDetailsClicked(campaignTrackingCode) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_SUPPORTED_DETAILS,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
    }

    this._trackInternal(event)
  }

  trackFullNameClicked(campaignTrackingCode) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_FULL_NAME,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
    }

    this._trackInternal(event)
  }

  trackBidChanged(campaignTrackingCode, bid, oldBid) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_CHANGE_BID,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_CPC]: bid,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_OLD_CPC]: oldBid,
    }

    this._trackInternal(event)
  }

  trackMetricShown(metricType) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.METRIC_BREAKDOWN,
      [TrackingConsts.FIELD_NAMES.METRIC_TYPE]: metricType,
    }

    this._trackInternal(event)
  }

  trackLogin() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.USER_LOGGED_IN,
    }

    this._trackInternal(event)
  }

  trackLogout() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.USER_LOGGED_OUT,
    }

    this._trackInternal(event)
  }

  trackCampaignCreation() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_CREATION_WIZARD_LAUNCH,
    }

    this._trackInternal(event)
  }

  trackCampaignCreationStep(stepName) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_CREATION_WIZARD_STEP,
      [TrackingConsts.FIELD_NAMES.STEP_NAME]: stepName,
    }

    this._trackInternal(event)
  }

  trackCampaignCreationCancellation(stepName) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_CREATION_WIZARD_CLOSE,
      [TrackingConsts.FIELD_NAMES.STEP_NAME]: stepName,
    }

    this._trackInternal(event)
  }

  trackCampaignPopupOpened(campaignTrackingCode) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_POPUP_OPENED,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
    }

    this._trackInternal(event)
  }

  trackAutomationPopupOpened(automationName) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.AUTOMATION_POPUP_OPENED,
      [TrackingConsts.FIELD_NAMES.AUTOMATION_NAME]: automationName,
    }

    this._trackInternal(event)
  }

  trackCampaignPopupChangePage(campaignTrackingCode, pageName) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_POPUP_PAGE_CHANGED,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
      [TrackingConsts.FIELD_NAMES.PAGE_NAME]: pageName,
    }

    this._trackInternal(event)
  }

  trackBulkEditStatusChange(numberOfCampaigns, oldStatus, newStatus) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.BULK_EDIT_STATUS_CHANGED,
      [TrackingConsts.FIELD_NAMES.NUMBER_OF_CAMPAIGNS]: numberOfCampaigns,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_OLD_STATUS]: oldStatus,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_STATUS]: newStatus,
    }

    this._trackInternal(event)
  }

  trackBulkEditBid(numberOfCampaigns, typeOfChange, changeValue) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.BULK_EDIT_BID,
      [TrackingConsts.FIELD_NAMES.BULK_OPERATION]: typeOfChange,
      [TrackingConsts.FIELD_NAMES.BULK_NUMBER_OF_CAMPAIGNS]: numberOfCampaigns,
      [TrackingConsts.FIELD_NAMES.BULK_VALUE]: changeValue,
    }

    this._trackInternal(event)
  }

  trackCustomizeInterfaceMenuToggle(menuStatus) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CUSTOMIZED_INTERFACE_MENU_TOGGLE,
      [TrackingConsts.FIELD_NAMES.CUSTOMIZED_INTERFACE_MENU_STATUS]: menuStatus,
    }

    this._trackInternal(event)
  }

  trackCustomizeInterfaceFieldToggle(fieldName, fieldStatus) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CUSTOMIZED_INTERFACE_FIELD_TOGGLE,
      [TrackingConsts.FIELD_NAMES.CUSTOMIZED_INTERFACE_FIELD_NAME]: fieldName,
      [TrackingConsts.FIELD_NAMES.CUSTOMIZED_INTERFACE_FIELD_STATUS]: fieldStatus,
    }

    this._trackInternal(event)
  }

  trackCreativeClicked(button) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CREATIVE_CLICKED,
      [TrackingConsts.FIELD_NAMES.CREATIVE_BUTTON]: button,
    }

    this._trackInternal(event)
  }

  trackCampaignOptionsMenuShortcutClicked(campaignTrackingCode, shortcutName) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGN_OPTIONS_MENU_SHORTCUT,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TRACKING_CODE]: campaignTrackingCode,
      [TrackingConsts.FIELD_NAMES.SHORTCUT_NAME]: shortcutName,
    }

    this._trackInternal(event)
  }

  trackReloadConversionEvents(networkId, providerAccounts) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CONVERSION_EVENTS_RELOAD,
      [TrackingConsts.FIELD_NAMES.NETWORK_ID]: networkId,
      [TrackingConsts.FIELD_NAMES.PROVIDER_ACCOUNTS]: providerAccounts,
    }

    this._trackInternal(event)
  }

  trackShortcutUsed(shortcut) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.SHORTCUT_USED,
      [TrackingConsts.FIELD_NAMES.KEYBOARD_SHORTCUT_NAME]: shortcut,
    }

    this._trackInternal(event)
  }

  trackBulkEdit(parameter, operationType, value, numberOfCampaigns) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.BULK_EDIT,
      [TrackingConsts.FIELD_NAMES.BULK_PARAMETER]: parameter,
      [TrackingConsts.FIELD_NAMES.BULK_OPERATION]: operationType,
      [TrackingConsts.FIELD_NAMES.BULK_VALUE]: value,
      [TrackingConsts.FIELD_NAMES.BULK_NUMBER_OF_CAMPAIGNS]: numberOfCampaigns,
    }

    this._trackInternal(event)
  }

  trackExport(reportType, networkId, fromDate, toDate) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.EXPORT_REPORT,
      [TrackingConsts.FIELD_NAMES.NETWORK_ID]: networkId,
      [TrackingConsts.FIELD_NAMES.FROM_DATE]: fromDate,
      [TrackingConsts.FIELD_NAMES.TO_DATE]: toDate,
      [TrackingConsts.FIELD_NAMES.EXPORT_REPORT_TYPE]: reportType,
    }

    this._trackInternal(event)
  }

  trackSaveFiltersGroup() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.SAVE_FILTERS_GROUP,
    }

    this._trackInternal(event)
  }

  trackSelectFiltersGroup() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.SELECT_FILTERS_GROUP,
    }

    this._trackInternal(event)
  }

  trackDeleteFiltersGroup() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.DELETE_FILTERS_GROUP,
    }

    this._trackInternal(event)
  }

  trackCampaignCompare(networkId, fromDate = null, toDate = null, compareFromDate = null, compareToDate = null) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGNS_COMPARE,
      [TrackingConsts.FIELD_NAMES.NETWORK_ID]: networkId,
      [TrackingConsts.FIELD_NAMES.FROM_DATE]: fromDate,
      [TrackingConsts.FIELD_NAMES.TO_DATE]: toDate,
      [TrackingConsts.FIELD_NAMES.COMPARE_FROM_DATE]: compareFromDate,
      [TrackingConsts.FIELD_NAMES.COMPARE_TO_DATE]: compareToDate,
    }

    this._trackInternal(event)
  }

  trackCampaignsPerPageChanged(itemsPerPage) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.CAMPAIGNS_ITEMS_PER_PAGE,
      [TrackingConsts.FIELD_NAMES.CAMPAIGNS_PER_PAGE]: itemsPerPage,
    }

    this._trackInternal(event)
  }

  trackBulkAddCampaignTags(tags, numOfCampaigns, networkId) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.BULK_ADD_TAGS,
      [TrackingConsts.FIELD_NAMES.BULK_NUMBER_OF_CAMPAIGNS]: numOfCampaigns,
      [TrackingConsts.FIELD_NAMES.NETWORK_ID]: networkId,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TAGS]: tags,
    }

    this._trackInternal(event)
  }

  trackBulkRemoveCampaignTags(tags, numOfCampaigns, networkId) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.BULK_REMOVE_TAGS,
      [TrackingConsts.FIELD_NAMES.BULK_NUMBER_OF_CAMPAIGNS]: numOfCampaigns,
      [TrackingConsts.FIELD_NAMES.NETWORK_ID]: networkId,
      [TrackingConsts.FIELD_NAMES.CAMPAIGN_TAGS]: tags,
    }

    this._trackInternal(event)
  }

  trackFilterOperatorEditing(filterType, filterOperation) {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.FILTER_OPERATOR_EDITED,
      [TrackingConsts.FIELD_NAMES.FILTER_NAME]: filterType,
      [TrackingConsts.FIELD_NAMES.FILTER_ACTION]: filterOperation,
    }

    this._trackInternal(event)
  }

  trackAdvancedSearchOpened() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.ADVANCED_SEARCH_OPENED,
    }

    this._trackInternal(event)
  }

  trackAdvancedSearchPerformed() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.ADVANCED_SEARCH_PERFORMED,
    }

    this._trackInternal(event)
  }

  trackAdvancedSearchEdit() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.ADVANCED_SEARCH_EDIT_CLICKED,
    }

    this._trackInternal(event)
  }

  trackRandomAutoAccount() {
    let event = {
      [TrackingConsts.FIELD_NAMES.EVENT_TYPE]: TrackingConsts.EVENT_TYPES.RANDOM_AUTO_ACCOUNT,
    }

    this._trackInternal(event)
  }

  _trackInternal(event) {
    event[TrackingConsts.FIELD_NAMES.DEVICE] = this.device
    event[TrackingConsts.FIELD_NAMES.DEVICE_TYPE] = this.deviceType

    mixpanel.track(event[TrackingConsts.FIELD_NAMES.EVENT_TYPE], event)
  }
}

export const eventsTracker = new EventsTracker()
