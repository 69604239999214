import React from "react"
import { connect } from "react-redux"
import Moment from "moment"
import Consts from "../../../app/consts"
import {
  chartAxisStyleUpdater,
  generateChart,
  generateChartSeries,
  positionCheckboxes,
} from "../../../utils/chartUtils"
import ChartColors from "../../../common/consts/chartColors"
import EmptyChartIndicator from "../../../common/components/emptyChartIndicator"
import { isNullOrUndefined } from "../../../utils/funcUtils"

const verticalAxes = {
  "sessions-axis": {
    title: { text: "" },
    selectionIndex: 1,
    id: "sessions-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value:,.0f}" },
    maxPadding: 0.1,
  },
  "user-value-axis": {
    title: { text: "" },
    selectionIndex: 0,
    opposite: true,
    id: "user-value-axis",
    labels: { align: "right", x: -3, y: -3, format: "${value}" },
    maxPadding: 0.1,
    min: 0,
  },
  "roi-axis": {
    title: { text: "" },
    visible: false,
    id: "roi-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
  "estimated-profit-axis": {
    title: { text: "" },
    visible: false,
    id: "estimated-profit-axis",
    labels: { align: "left", x: 3, y: -3, format: "${value:,.0f}", overflow: false },
    maxPadding: 0.1,
  },
  "rpm-axis": {
    title: { text: "" },
    visible: false,
    id: "rpm-axis",
    labels: { align: "left", x: 3, y: -3, format: "${value}" },
    maxPadding: 0.1,
  },
  "ctr-axis": {
    title: { text: "" },
    visible: false,
    id: "ctr-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
  "pps-axis": {
    title: { text: "" },
    visible: false,
    id: "pps-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}" },
    maxPadding: 0.1,
  },
  "bounce-rate-axis": {
    title: { text: "" },
    visible: false,
    id: "bounce-rate-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
  "daily-spend-axis": {
    title: { text: "" },
    visible: false,
    id: "daily-spend-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
  "revenue-axis": {
    title: { text: "" },
    visible: false,
    id: "revenue-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
}

class CampaignPopupPerformanceByHourChart extends React.Component {
  chart = null

  componentDidMount() {
    this.drawChart()
  }

  componentDidUpdate() {
    this.drawChart()
  }

  drawChart = () => {
    let generateSeries = (title, dataItem, multiplyDataBy, axisName, visible, color, moreOptions) => {
      let moreChartOptions = Object.assign(
        {},
        {
          data: this.props.performanceByHour
            .filter((item) => !isNullOrUndefined(item[dataItem]))
            .map((item) => {
              return [Moment(item.date).valueOf(), item[dataItem] * multiplyDataBy]
            }),
          color: color,
        },
        moreOptions
      )
      return generateChartSeries(title, axisName, visible, moreChartOptions)
    }

    let generateActualVisitValueSeries = (title, dataItem, multiplyDataBy, axisName, visible, color, moreOptions) => {
      let moreChartOptions = Object.assign(
        {},
        {
          data: this.props.performanceByHour
            .filter(
              (item) =>
                (!item.incomplete_visit_value && !isNullOrUndefined(item[dataItem])) ||
                (!isNullOrUndefined(item.raw_visit_value) &&
                  !isNullOrUndefined(item.estimated_vv) &&
                  Math.trunc(item.raw_visit_value * 1000) === Math.trunc(item.estimated_vv * 1000))
            )
            .map((item) => {
              return {
                x: Moment(item.date).valueOf(),
                y: item[dataItem] * multiplyDataBy,
              }
            }),
          color: color,
        },
        moreOptions
      )
      return generateChartSeries(title, axisName, visible, moreChartOptions)
    }

    let generateActualVisitValueDumbbellSeries = (title, axisName, visible, color, moreOptions) => {
      let moreChartOptions = Object.assign(
        {},
        {
          data: this.props.performanceByHour
            .filter(
              (item) =>
                item.incomplete_visit_value &&
                !isNullOrUndefined(item.raw_visit_value) &&
                !isNullOrUndefined(item.estimated_vv) &&
                Math.trunc(item.raw_visit_value * 1000) !== Math.trunc(item.estimated_vv * 1000)
            )
            .map((item) => {
              return {
                x: Moment(item.date).valueOf(),
                low: item.raw_visit_value,
                high: item.estimated_vv,
              }
            }),
        },
        moreOptions
      )

      return generateChartSeries(title, axisName, visible, moreChartOptions)
    }

    positionCheckboxes()

    let xAxisArr = [
      generateSeries("Visits", "visits", 1, "sessions-axis", true, ChartColors.BY_HOUR_SESSIONS, { type: "column" }),
      generateSeries("Visit Value", "visit_value", 1, "user-value-axis", true, ChartColors.USER_VALUE, {
        tooltip: { valuePrefix: "$", valueDecimals: 3 },
        zIndex: 4,
      }),
      generateSeries("Avg. CPC", "cost_per_click", 1, "user-value-axis", true, ChartColors.REALTIME_BID, {
        tooltip: { valuePrefix: "$", valueDecimals: 3 },
      }),
      generateSeries("ROI", "roi", 100, "roi-axis", false, ChartColors.ROI, {
        tooltip: { valueSuffix: "%", valueDecimals: 0 },
      }),
      generateSeries("Profit", "profit", 1, "estimated-profit-axis", false, ChartColors.ESTIMATED_PROFIT, {
        tooltip: { valuePrefix: "$", valueDecimals: 0 },
      }),
      generateSeries("RPM", "rpm", 1, "rpm-axis", false, ChartColors.RPM, {
        tooltip: { valuePrefix: "$", valueDecimals: 2 },
      }),
      generateSeries("Pages Per Visit", "visit_cost", 1, "pps-axis", false, ChartColors.PAGES_PER_SESSION, {
        tooltip: { valueDecimals: 2 },
      }),
      generateSeries("Spend", "daily_spent", 1, "daily-spend-axis", false, ChartColors.DAILY_SPEND, {
        tooltip: { valueSuffix: "%", valueDecimals: 2 },
      }),
      generateSeries("Revenue", "revenue", 1, "revenue-axis", false, ChartColors.REVENUE, {
        tooltip: { valueSuffix: "%", valueDecimals: 2 },
      }),
    ]

    let yAxisArr = [
      verticalAxes["sessions-axis"],
      verticalAxes["user-value-axis"],
      verticalAxes["roi-axis"],
      verticalAxes["estimated-profit-axis"],
      verticalAxes["rpm-axis"],
      verticalAxes["pps-axis"],
      verticalAxes["revenue-axis"],
      verticalAxes["daily-spend-axis"],
    ]

    let chartSpecificOptions = {
      tooltip: {
        dateTimeLabelFormats: {
          day: "%A, %b %e, %H:%M",
        },
        useHTML: true,
        followPointer: true,
        outside: true,
        distance: 40,
        hideDelay: 0,
      },
      chart: {
        marginTop: 1,
        marginLeft: 1,
        marginRight: 170,
        zoomType: "xy",
      },
      legend: {
        x: 45,
        y: -24,
        itemMarginBottom: 8,
      },
      yAxis: yAxisArr,
      series: xAxisArr,
      xAxis: {
        minPadding: 0.05,
        maxPadding: 0.05,
        tickInterval: 3600 * 1000, // one hour
        tickWidth: 1,
        minRange: 1,
        tickmarkPlacement: "on",
        tickLength: 5,
        labels: {
          formatter() {
            let label = this.axis.defaultLabelFormatter.call(this)

            if (new Date(this.value).getUTCHours() === 0) {
              return `<span style="font-weight: 800; color: #777">${label}</span>`
            }

            return label
          },
        },
      },
    }

    let chart = generateChart("performance-by-hour", chartSpecificOptions)
    this.chart = chart
    chartAxisStyleUpdater(chart)
  }

  mouseDown = (event) => {
    this.chart.tooltip.options.enabled = false
    this.chart.tooltip.hide()
  }

  mouseUp = (event) => {
    this.chart.tooltip.options.enabled = true
  }

  render() {
    let emptyChartIndicator = null

    if (!this.props.hasData) {
      emptyChartIndicator = <EmptyChartIndicator />
    }

    return (
      <div className="campaign-popup-chart-wrapper">
        {emptyChartIndicator}
        <div
          className="performance-by-hour-chart campaign-popup-chart"
          id="performance-by-hour"
          onMouseDown={this.mouseDown}
          onMouseUp={this.mouseUp}
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    hasData: state.campaignPopupV2.performanceByHour.hasData,
    performanceByHour: state.campaignPopupV2.performanceByHour.dataForChart,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByHourChart)
