export function pauseAutomationResponse(action, updateAutomationFunction) {
  return updateAutomationFunction(action.automation.key, {
    isAutomationStatusLoading: action.isAutomationStatusLoading,
    status: action.status,
  })
}

export function activateAutomationResponse(action, updateAutomationFunction) {
  return updateAutomationFunction(action.automation.key, {
    isAutomationStatusLoading: action.isAutomationStatusLoading,
    status: action.status,
  })
}

export function pauseAutomationErrorResponse(action, updateAutomationFunction) {
  return updateAutomationFunction(action.automation.key, {
    isAutomationStatusLoading: action.isAutomationStatusLoading,
    status: action.status,
  })
}

export function activateAutomationErrorResponse(action, updateAutomationFunction) {
  return updateAutomationFunction(action.automation.key, {
    isAutomationStatusLoading: action.isAutomationStatusLoading,
    status: action.status,
  })
}

export function automationStatusLoading(action, updateAutomationFunction) {
  return updateAutomationFunction(action.automation.key, {
    isAutomationStatusLoading: action.isAutomationStatusLoading,
  })
}
