import React from "react"
import { connect } from "react-redux"
import { Close } from "@material-ui/icons"
import Chip from "@material-ui/core/Chip"
import PlusIcon from "../../resources/svgs/PlusIcon.svg"
import { addCampaignTag, removeCampaignTag } from "../campaignPopupActions"
import {
  getNetworkTagsV2,
  notificationAdd,
  validateMaxSelectedTagsPerCampaign,
  validateMaxTagsPerCampaign,
  validateTagText,
} from "../../common/actions/commonActions"
import GenericDropdown from "../../common/components/genericDropdown"
import AuthApi from "../../api/authService"
import { store } from "../../app/store"
import ModuleTypes from "../../common/consts/moduleTypes"
import Consts, { MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE } from "../../app/consts"

class CampaignTags extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      open: false,
      anchorEl: null,
      selectedIndexesArr: [],
    }
  }

  openMenu = (event) => {
    let networkCode = this.props.campaign.network_code
    let localTags = store.getState().campaignsV2.campaignTags.map((tag) => {
      return tag.name
    })

    if (this.props.networkTags.length === 0) {
      this.setState(
        {
          open: true,
          anchorEl: event.currentTarget,
          isLoading: true,
        },
        () => {
          this.props.dispatch(getNetworkTagsV2(networkCode, localTags)).then(() => {
            this.setState({ isLoading: false })
          })
        }
      )
    } else {
      this.setState({
        open: true,
        anchorEl: event.currentTarget,
      })
    }
  }

  closeMenu = () => {
    this.setState({
      open: false,
    })
  }

  createTag = (tagName) => {
    tagName = tagName.trim()

    if (tagName.length > 0) {
      // Validations
      if (validateTagText(tagName)) {
        return
      }

      if (validateMaxSelectedTagsPerCampaign(this.props.dynamicTags)) {
        this.props.dispatch(notificationAdd(MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE))
        return
      }

      this.closeMenu()

      let isSelectedTag =
        this.props.dynamicTags.filter((tag) => {
          return tag.name === tagName
        }).length > 0

      // Check if the tag was already selected
      if (!isSelectedTag) {
        let selectedIndex = null

        // Check if the tag is unique in the current network
        this.props.networkTags.forEach((tag, index) => {
          if (tag.name === tagName) {
            selectedIndex = index
          }
        })

        if (selectedIndex !== null) {
          if (!this.state.selectedIndexesArr.includes(selectedIndex)) {
            // If this tag already exists in the current network - select the right checkbox
            this.onOptionSelected([...this.state.selectedIndexesArr, selectedIndex])
          }
        } else {
          let newTag = { name: tagName, isNew: true }
          let selectedTags = this.props.dynamicTags ? [...this.props.dynamicTags, newTag] : [newTag]
          this.props.dispatch(addCampaignTag(this.props.campaign, this.props.dynamicTags, selectedTags, tagName))
        }
      }
    }
  }

  removeTag = (tagName) => {
    let selectedCampaignTags = this.props.dynamicTags.filter((tag) => {
      return tag.name !== tagName
    })

    if (this.state.selectedIndexesArr.length > 0) {
      let selectedIndexesArr = this.state.selectedIndexesArr.filter((index) => {
        return this.props.networkTags[index].name !== tagName
      })

      this.setState({ selectedIndexesArr })
    }

    this.props.dispatch(removeCampaignTag(this.props.campaign, this.props.dynamicTags, selectedCampaignTags, tagName))
  }

  onOptionSelected = (selectedIndexesArr) => {
    let newTags = this.props.dynamicTags.filter((tag) => {
      return tag.isNew
    })
    let selectedCampaignTags = []
    selectedIndexesArr.forEach((selectedIndex) => {
      let selectedCampaignTag = this.props.networkTags.find((tag, index) => {
        return selectedIndex === index
      })

      if (selectedCampaignTag) {
        selectedCampaignTags.push(selectedCampaignTag)
      }
    })
    // Bugfix - in case there are duplicates in newTags and selectedCampaignTags
    newTags = newTags.filter(
      ({ name: tagName }) => !selectedCampaignTags.some(({ name: tagName2 }) => tagName2 === tagName)
    )
    let newCampaignTags = [...selectedCampaignTags, ...newTags]

    if (validateMaxTagsPerCampaign(newCampaignTags)) {
      this.props.dispatch(notificationAdd(MAX_TAGS_PER_CAMPAIGN_ERROR_MESSAGE))
      return
    }

    this.setState({ selectedIndexesArr })
    let newCampaignTagsNames = newCampaignTags.map((tag) => tag.name)
    let dynamicTagsNames = this.props.dynamicTags.map((tag) => tag.name)

    // Find the difference between original and new
    let selectedTag = dynamicTagsNames
      .filter((tag) => !newCampaignTagsNames.includes(tag))
      .concat(newCampaignTagsNames.filter((tag) => !dynamicTagsNames.includes(tag)))

    if (selectedTag?.length > 0) {
      if (newCampaignTagsNames.length > dynamicTagsNames.length) {
        this.props.dispatch(
          addCampaignTag(this.props.campaign, this.props.dynamicTags, newCampaignTags, selectedTag[0])
        )
      } else {
        this.props.dispatch(
          removeCampaignTag(this.props.campaign, this.props.dynamicTags, newCampaignTags, selectedTag[0])
        )
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.networkTags !== prevProps.networkTags) {
      let selectedIndexesArr = []
      let dynamicTags = this.props.dynamicTags.map((tag) => {
        return tag.name
      })

      this.props.networkTags.forEach((networkTag, index) => {
        if (dynamicTags.includes(networkTag.name)) {
          selectedIndexesArr.push(index)
        }
      })

      this.setState({ selectedIndexesArr })
    }
  }

  render() {
    let dynamicChips = []
    let constantChips = []
    let addTagButton = null
    let allowDeleteTags = false

    if (AuthApi.hasModule(ModuleTypes.EDIT_CAMPAIGN)) {
      addTagButton = (
        <div className="add-tag clickable" onClick={this.openMenu}>
          <PlusIcon className="plus-icon" />
        </div>
      )

      allowDeleteTags = true
    }

    this.props.constantTags.forEach((tag) => {
      if (tag) {
        constantChips.push(<Chip label={tag} classes={{ root: "chip-item" }} key={"constant-" + tag} />)
      }
    })

    this.props.dynamicTags.forEach((tag) => {
      let deleteTagProps = {}

      if (allowDeleteTags) {
        deleteTagProps = {
          deleteIcon: <Close className="delete-icon" />,
          onDelete: () => {
            this.removeTag(tag.name)
          },
        }
      }

      if (tag) {
        dynamicChips.push(
          <Chip label={tag.name} classes={{ root: "chip-item" }} key={"dynamic-" + tag.name} {...deleteTagProps} />
        )
      }
    })

    return (
      <div className="campaign-tags">
        {constantChips}
        {dynamicChips}
        {addTagButton}
        <GenericDropdown
          header=""
          allowMultiple
          allowAutoSuggest
          minItemsForAutosuggest={0}
          isLoading={this.state.isLoading}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          options={this.props.networkTags}
          openDropdownCallback={this.openMenu}
          onEnterPressCallback={this.createTag}
          closeDropdownCallback={this.closeMenu}
          selectOptionCallback={this.onOptionSelected}
          selectedIndexes={this.state.selectedIndexesArr}
          allowScrollPaging
          itemsPerPage={Consts.TAGS_ITEMS_PER_PAGE}
          buttonClassName="hidden"
          menuClassName="tags-menu campaign-popup-tags-menu"
          autoSuggestPlaceholder="Search tags or create new..."
          transformOrigin={{
            vertical: -55,
            horizontal: 120,
          }}
          searchErrorCallback={validateTagText}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    networkTags: state.campaignPopupV2.networkTags,
  }
}

export default connect(mapStateToProps)(CampaignTags)
