import { eventsTracker } from "../api/eventsTracker"

const KEY_MAP = {
  shift: 16,
  alt: 18,
  control: 17,
  command: 91,
  capslock: 20,
  backspace: 8,
  tab: 9,
  clear: 12,
  enter: 13,
  escape: 27,
  space: 32,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  delete: 46,
  home: 36,
  end: 35,
  pageup: 33,
  pagedown: 34,
  ",": 188,
  ".": 190,
  "/": 191,
  "`": 192,
  "-": 189,
  "=": 187,
  ";": 186,
  "'": 222,
  "[": 219,
  "]": 221,
  "\\": 220,
}

const NAME_MAP = {
  16: "shift",
  18: "alt",
  17: "control",
  91: "command",
  20: "capslock",
  8: "backspace",
  9: "tab",
  12: "clear",
  13: "enter",
  27: "escape",
  32: "space",
  37: "left",
  38: "up",
  39: "right",
  40: "down",
  46: "delete",
  36: "home",
  35: "end",
  34: "pagedown",
  33: "pageup",
  188: ",",
  190: ".",
  191: "/",
  192: "`",
  189: "-",
  187: "=",
  186: ";",
  222: "\\",
  219: "[",
  221: "]",
  220: "\\",
}

/**
 * Valid modifier keys.
 */
const MODIFIERS = [16, 17, 18, 91, 93, 224]

/**
 * Continue propagating the event to older listeners.
 */
const SHOULD_PROPAGATE = true

/**
 * Stringify a keyboard event.
 */
function keyboardEventCombo(e) {
  let keys = []

  if (e.shiftKey) {
    keys.push(KEY_MAP.shift)
  }

  if (e.ctrlKey) {
    keys.push(KEY_MAP.control)
  }

  if (e.altKey) {
    keys.push(KEY_MAP.alt)
  }

  if (e.metaKey) {
    keys.push(KEY_MAP.command)
  }

  if (!MODIFIERS.includes(e.keyCode)) {
    keys.push(e.keyCode)
  }

  return keys.sort().join(" ")
}

/**
 * Map keys to string.
 */
function stringifyKey(...keyCombo) {
  return keyCombo
    .map((key) => {
      if (typeof key === "number") {
        return key
      }

      if (KEY_MAP[key]) {
        return KEY_MAP[key]
      }

      if (key.length !== 1) {
        throw new TypeError('Unknown key "' + key + '"')
      }

      return key.toUpperCase().charCodeAt(0)
    })
    .sort()
    .join(" ")
}

/**
 * Create a listener function from shortcuts.
 */
function createShortcuts(shortcuts, returnValue) {
  if (returnValue === undefined) {
    returnValue = SHOULD_PROPAGATE
  }

  return (event, combo) => {
    if (shortcuts[combo]) {
      let keys = combo.split(" ")

      keys = keys.map((key) => {
        if (NAME_MAP[key]) {
          return NAME_MAP[key]
        } else {
          return String.fromCharCode(key)
        }
      })
      eventsTracker.trackShortcutUsed(keys.join(" "))

      return shortcuts[combo](event, combo)
    } else {
      return returnValue
    }
  }
}

/**
 * Check if a keyboard event originated from an input.
 */
function isInputEvent(event) {
  let target = event.target

  return (
    target.tagName === "INPUT" ||
    target.tagName === "SELECT" ||
    target.tagName === "TEXTAREA" ||
    target.isContentEditable
  )
}

/**
 * Function to wrap listener by filtering input events.
 */
function filterInputEvent(listener) {
  return (event, combo) => {
    return isInputEvent(event) ? SHOULD_PROPAGATE : listener(event, combo)
  }
}

/**
 * Keyboard manager library for mapping key events.
 */
class KeyboardShortcutsManager {
  constructor() {
    this.listeners = {}
    // here we add the KeyboardManager main 'keydown' listener to the window
    window.addEventListener("keydown", this.getHandler(), false)
  }

  addShortcut(key, shortcut, callback, isFilterInput = true) {
    let func = null

    if (isFilterInput) {
      func = filterInputEvent((e) => {
        return callback(e)
      })
    } else {
      func = callback
    }

    this.listeners[key] = createShortcuts({
      [stringifyKey(...shortcut)]: func,
    })
  }

  removeShortcut(key) {
    delete this.listeners[key]
  }

  getHandler() {
    let listener = this._getListener()

    return (event) => {
      return listener(event, keyboardEventCombo(event))
    }
  }

  _getListener(returnValue) {
    let listeners = this.listeners

    if (returnValue === undefined) {
      returnValue = SHOULD_PROPAGATE
    }

    return (event, combo) => {
      let currentListeners = Object.values(listeners)
      let len = currentListeners.length

      while (len--) {
        if (currentListeners[len](event, combo) !== SHOULD_PROPAGATE) {
          return undefined
        }
      }

      return returnValue
    }
  }
}

export const keyboardShortcutsManager = new KeyboardShortcutsManager()
