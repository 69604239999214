import React from "react"
import { TextField } from "@material-ui/core"
import Consts from "../../../app/consts"

export default class ArticleUrl extends React.Component {
  render() {
    let assetsLinks = this.props.campaignDetailsState.siteId ? (
      <a
        href={Consts.BASE_URL + `/assets/?url=${this.props.campaignDetailsState.articleUrl}`}
        className="assets-url"
        target="_blank"
      >
        {" "}
        Assets{" "}
      </a>
    ) : (
      ""
    )
    return (
      <div className={"text-field article-url-input " + (this.props.articleUrlValidationErrors ? "error" : "")}>
        <div className={"text-field article-url-input"}>
          <TextField
            label="Article URL"
            InputProps={{
              className: "text-field-line",
            }}
            InputLabelProps={{
              classes: { shrink: "text-field-color" },
            }}
            value={this.props.campaignDetailsState.articleUrl}
            onChange={(event) => this.props.handleChange("articleUrl", event)}
            onBlur={(event) => this.props.onBlur("articleUrl", event)}
            margin="normal"
          />
          {assetsLinks}
        </div>
        {this.props.articleUrlValidationErrors}
      </div>
    )
  }
}
