const throttled = {}

const middleware = () => (next) => (action) => {
  const time = action.meta && action.meta.throttle
  const key = action.meta && action.meta.key

  if (!time || !key) {
    return next(action)
  }

  // Just ignore the action if its already throttled
  if (throttled[key]) {
    return
  }

  throttled[key] = true

  setTimeout(() => delete throttled[key], time)

  return next(action)
}

export default middleware
