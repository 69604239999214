import React from "react"
import { connect } from "react-redux"
import { CircularProgress, ListItem, Menu, MenuItem } from "@material-ui/core"
import PlayIcon from "../../resources/svgs/PlayIcon.svg"
import PauseIcon from "../../resources/svgs/PauseIcon.svg"
import SubSourcesConsts from "../subSourcesConsts"
import { subSourceStatusLoading, activateSubSourceAsync, pauseSubSourceAsync } from "../subSourceActions"
import ModuleTypes from "../../common/consts/moduleTypes"
import AuthApi from "../../api/authService"
import { stopPropagationIfParentIsSelected } from "../../utils/domUtils"
import { isNullOrEmpty } from "../../utils/funcUtils"
import Consts from "../../app/consts"

class SubSourceActionsMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuAnchorEl: null,
      menuOpen: false,
    }
  }

  getStatusIcon = () => {
    let statusIcon
    let status_info = ""
    switch (this.props.subSource.status) {
      case SubSourcesConsts.SUB_SOURCE_STATUS_RUNNING:
        statusIcon = (
          <div className="status_tags_popup">
            <PlayIcon className={"play-icon green"} />
            {this.props.statusTextVisible ? (
              <span className={status_info}>{SubSourcesConsts.SUB_SOURCE_STATUS_NAMES.ACTIVE}</span>
            ) : null}
          </div>
        )
        break
      case SubSourcesConsts.SUB_SOURCE_STATUS_PAUSED:
        statusIcon = (
          <div className="status_tags_popup">
            <PauseIcon className={"pause-icon gray"} />
            {this.props.statusTextVisible ? (
              <span className={status_info}>{SubSourcesConsts.SUB_SOURCE_STATUS_NAMES.PAUSED}</span>
            ) : null}
          </div>
        )
        break
      default:
        statusIcon = null
    }
    return statusIcon
  }

  getActionMenuItems = () => {
    let menuItems
    switch (this.props.subSource.status) {
      case SubSourcesConsts.SUB_SOURCE_STATUS_RUNNING:
        menuItems = [
          <ListItem key={1} className="current-campaign-status">
            <PlayIcon className="play-icon green" />
            <span className="green">Running</span>
          </ListItem>,
          <MenuItem key={3} className="campaign-actions-menu-item" onClick={this.pauseSubSource}>
            <PauseIcon />
            <span>Pause</span>
          </MenuItem>,
        ]
        break

      case SubSourcesConsts.SUB_SOURCE_STATUS_PAUSED:
        menuItems = [
          <ListItem key={1} className="current-campaign-status">
            <PauseIcon />
            <span>Paused</span>
          </ListItem>,
          <MenuItem key={2} className="campaign-actions-menu-item" onClick={this.activeSubSource}>
            <PlayIcon />
            <span>Run</span>
          </MenuItem>,
        ]
        break

      default:
        menuItems = null
    }

    return menuItems
  }

  pauseSubSource = () => {
    let subSource = this.props.subSource
    this.props.dispatch(subSourceStatusLoading(subSource))
    this.props.dispatch(pauseSubSourceAsync(this.props.campaign, subSource))
    this.closeMenu()
  }

  activeSubSource = () => {
    let subSource = this.props.subSource
    this.props.dispatch(subSourceStatusLoading(subSource))
    this.props.dispatch(activateSubSourceAsync(this.props.campaign, subSource))
    this.closeMenu()
  }

  openMenu = (event) => {
    stopPropagationIfParentIsSelected(event)
    this.setState({ menuOpen: true, menuAnchorEl: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  render() {
    let isClickable = false
    let actionsMenu = null
    let statusIcon = this.getStatusIcon()
    let loaderDiv = (
      <div className="loading-indicator">
        <CircularProgress size={20} />
      </div>
    )
    let statusLoadingIndicator = this.props.subSource.isStatusLoading ? loaderDiv : null

    if (
      AuthApi.hasModule(ModuleTypes.CAMPAIGN_STATUS_CHANGE) &&
      !isNullOrEmpty(this.props.subSource.status) &&
      Consts.PROVIDERS_THAT_CAN_CHANGE_SUB_SOURCES.includes(this.props.campaign.provider_id)
    ) {
      isClickable = true
      actionsMenu = (
        <Menu
          id="campaign-actions-menu"
          classes={{ paper: "default-menu campaign-actions-menu" }}
          anchorEl={this.state.menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: -16,
          }}
          open={this.state.menuOpen}
          onClose={this.closeMenu}
          onClick={(event) => event.stopPropagation()}
        >
          {this.getActionMenuItems()}
        </Menu>
      )
    }

    return (
      <div className={"campaign-actions" + (isClickable ? " clickable" : "")}>
        <div aria-owns={this.state.open ? "campaign-actions-menu" : null} aria-haspopup="true" onClick={this.openMenu}>
          {statusIcon}
        </div>
        {actionsMenu}
        {statusLoadingIndicator}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(SubSourceActionsMenu)
