import React from "react"
import NoDataLine from "../../resources/images/NoDataLine.png"

export default class NoData extends React.Component {
  render() {
    return (
      <div className="no-data" style={{ backgroundImage: "url(" + NoDataLine + ")" }}>
        <span className="no-data-text">{this.props.text}</span>
      </div>
    )
  }
}
