import React from "react"
import NumberFormat from "../numberFormat"

let CommonDataFormat = {
  intFormat: (number) => {
    return <NumberFormat value={number} displayType="text" decimalScale={0} thousandSeparator />
  },
  floatFormat: (number, decimalScale = 2) => {
    return <NumberFormat value={number} displayType="text" decimalScale={decimalScale} />
  },
  intPercentage: (number) => {
    return <NumberFormat value={number} displayType="text" fixedDecimalScale suffix="%" />
  },
  floatPercentage: (number, decimalScale = 1) => {
    return <NumberFormat value={number} displayType="text" decimalScale={decimalScale} fixedDecimalScale suffix="%" />
  },
  intCurrency: (number, prefix = "$") => {
    return <NumberFormat value={number} thousandSeparator displayType="text" decimalScale={0} prefix={prefix} />
  },
  floatCurrency: (number, decimalScale = 3, prefix = "$") => {
    return (
      <NumberFormat value={number} displayType="text" decimalScale={decimalScale} prefix={prefix} thousandSeparator />
    )
  },
}

Object.freeze(CommonDataFormat)
export default CommonDataFormat
