import React from "react"
import { connect } from "react-redux"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import Platform from "../../platform"
import { Checkbox } from "@material-ui/core"
import CampaignsConsts from "../../../../campaignsV2/campaignsConsts"
import { areAllPlatformsSelected } from "../../../../utils/campaignUtilsV2"

class PlatformSelection extends React.Component {
  constructor(props) {
    super(props)
  }

  selectPlatform = (platformKey) => {
    let newSourceData = null
    let subValues = this.props.campaignTargetingGroup.platforms[platformKey].values

    if (Object.keys(subValues).length > 0) {
      let numOfSelected = 0
      Object.keys(subValues).forEach((subOptionsKey, subOptionsIndex) => {
        if (subValues[subOptionsKey]) {
          numOfSelected++
        }
      })

      let values = {}
      let selectedStatus = numOfSelected < Object.keys(subValues).length

      Object.keys(subValues).forEach((subOptionsKey, subOptionsIndex) => {
        values[subOptionsKey] = selectedStatus
      })

      newSourceData = Object.assign({}, this.props.campaignTargetingGroup, {
        platforms: Object.assign({}, this.props.campaignTargetingGroup.platforms, {
          [platformKey]: Object.assign({}, this.props.campaignTargetingGroup.platforms[platformKey], {
            selected: selectedStatus,
            values,
          }),
        }),
      })
    } else {
      newSourceData = Object.assign({}, this.props.campaignTargetingGroup, {
        platforms: Object.assign({}, this.props.campaignTargetingGroup.platforms, {
          [platformKey]: Object.assign({}, this.props.campaignTargetingGroup.platforms[platformKey], {
            selected: !this.props.campaignTargetingGroup.platforms[platformKey].selected,
          }),
        }),
      })
    }

    if (this.props.onChange) {
      this.props.onChange(newSourceData)
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, newSourceData))
    }
  }

  selectSubPlatform = (platformKey, subSection) => {
    let values = {}
    let numOfSelected = 0
    let subValues = Object.assign({}, this.props.campaignTargetingGroup.platforms[platformKey].values, {
      [subSection]: !this.props.campaignTargetingGroup.platforms[platformKey].values[subSection],
    })

    Object.keys(subValues).forEach((subOptionsKey, subOptionsIndex) => {
      values[subOptionsKey] = subValues[subOptionsKey]
      if (subValues[subOptionsKey]) {
        numOfSelected++
      }
    })

    let selectedStatus = numOfSelected > 0
    let newSourceData = Object.assign({}, this.props.campaignTargetingGroup, {
      platforms: Object.assign({}, this.props.campaignTargetingGroup.platforms, {
        [platformKey]: Object.assign({}, this.props.campaignTargetingGroup.platforms[platformKey], {
          selected: selectedStatus,
          values,
        }),
      }),
    })

    if (this.props.onChange) {
      this.props.onChange(newSourceData)
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, newSourceData))
    }
  }

  selectAllPlatforms = (selectAll) => {
    const newSourceData = {
      ...this.props.campaignTargetingGroup,
      platforms: Object.keys(this.props.campaignTargetingGroup.platforms).reduce((result, platformKey) => {
        const platform = this.props.campaignTargetingGroup.platforms[platformKey]
        const subValues = platform.values || {}

        // Update the selected status of the platform
        result[platformKey] = {
          ...platform,
          selected: selectAll,
          values: {},
        }

        // Update the selected status of sub-platforms
        if (Object.keys(subValues).length > 0) {
          let values = {}
          Object.keys(subValues).forEach((subOptionsKey) => {
            values[subOptionsKey] = selectAll
          })

          result[platformKey].values = values
        }

        return result
      }, {}),
    }

    if (this.props.onChange) {
      this.props.onChange(newSourceData)
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, newSourceData))
    }
  }

  generatePlatformInputs = () => {
    let platformElements = []
    const platforms = this.props.campaignTargetingGroup.platforms

    // Add an "All" checkbox
    if (Object.keys(platforms).length > 1) {
      const selectAllName = this.props.selectedSource.id === CampaignsConsts.FACEBOOK_PROVIDER_ID ? "Automatic" : "All"
      platformElements.push(
        <div className="multi-selection" key={selectAllName}>
          <label className="platform select-all clickable" key={selectAllName}>
            <Checkbox
              checked={areAllPlatformsSelected(platforms)}
              classes={{ root: "checkbox", checked: "checked" }}
              onChange={() => this.selectAllPlatforms(!Object.values(platforms).every((platform) => platform.selected))}
              value="selected"
              key={selectAllName}
            />
            {selectAllName}
          </label>
        </div>
      )
    }

    Object.keys(platforms).forEach((platformKey, index) => {
      let platform = platforms[platformKey]

      platformElements.push(
        <Platform
          key={platformKey}
          platformKey={platformKey}
          platformData={platform}
          onPlatformSelect={this.selectPlatform}
          onSubPlatformSelect={this.selectSubPlatform}
          // onBrowsersSelect={this.selectBrowsers}
        />
      )
    })

    return platformElements
  }

  render() {
    return (
      <div className="platforms">
        <div className="field-title">Devices</div>
        {this.generatePlatformInputs()}
        {this.props.platformsValidationErrors}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let sourceData = {}
  let selectedSourceKey = null

  if (ownProps.selectedSource) {
    sourceData = ownProps.selectedSource
  } else {
    Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
      if (state.campaignCreationWizard.campaignSources.sources[sourceKey].selected) {
        selectedSourceKey = sourceKey
        sourceData = state.campaignCreationWizard.campaignSources.sources[sourceKey]
      }
    })
  }

  return {
    selectedSource: sourceData,
  }
}

export default connect(mapStateToProps)(PlatformSelection)
