import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import { appReducer } from "./appReducer"
import { navigationBarReducer } from "../../navigationBar/navigationBarReducer"
import { campaignCreationReducer } from "../../campaignCreation/campaignCreationReducer"
import { adminPageReducer } from "../../admin/adminPageReducer"
import { settingsPageReducer } from "../../settings/settingsPageReducer"
import { profilePageReducer } from "../../settings/sections/profile/profilePageReducer"
import { advancedSearchReducerV2 } from "../../campaignsV2/advancedSearch/advancedSearchReducer"
import { campaignPageReducerV2 } from "../../campaignsV2/campaignsPageReducer"
import { campaignPopupReducerV2 } from "../../campaignPopupV2/campaignPopupReducer"
import { unintegratedReducer } from "../../unintegratedCampaigns/unintegratedCampaignsReducer"
import { automationsReducer } from "../../automations/automationsReducer"
import { automationPopupReducer } from "../../automationPopup/automationPopupReducer"
import { automationCreationReducer } from "../../automationCreation/automationCreationReducer"
import { dashboardReducer } from "../../dashboard/dashboardReducer"
import { policyReducer } from "../../policyReview/policyReducer"
import { draftsReducer } from "../../drafts/draftsReducer"
import { articlesReducer } from "../../articles/articlesReducer"
// main reducers
export const reducers = combineReducers({
  routing: routerReducer,
  app: appReducer,
  navigationBar: navigationBarReducer,
  campaignCreationWizard: campaignCreationReducer,
  settings: settingsPageReducer,
  admin: adminPageReducer,
  profile: profilePageReducer,
  campaignsV2: campaignPageReducerV2,
  campaignPopupV2: campaignPopupReducerV2,
  advancedSearchV2: advancedSearchReducerV2,
  unintegratedCampaigns: unintegratedReducer,
  automations: automationsReducer,
  automationPopup: automationPopupReducer,
  automationCreation: automationCreationReducer,
  dashboard: dashboardReducer,
  policyReview: policyReducer,
  drafts: draftsReducer,
  articles: articlesReducer,
})
