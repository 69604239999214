import React from "react"
import { connect } from "react-redux"
import { Menu, MenuItem } from "@material-ui/core"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ThreeDotsIcon from "../../resources/svgs/ThreeDotsIcon.svg"
import { stopPropagationIfParentIsSelected } from "../../utils/domUtils"
import { generateAutomationUrl } from "../../utils/automationUtils"
import {
  openAutomationDuplicatePopup,
  openAutomationEditPopup,
} from "../../automationCreation/automationCreationActions"
import AuthApi from "../../api/authService"
import ModuleTypes from "../../common/consts/moduleTypes"

class AutomationOptionsMenu extends React.Component {
  constructor() {
    super()

    this.state = {
      menuAnchorEl: null,
      menuOpen: false,
    }
  }

  openMenu = (event) => {
    stopPropagationIfParentIsSelected(event)
    this.setState({ menuOpen: true, menuAnchorEl: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  edit = () => {
    this.props.dispatch(openAutomationEditPopup(this.props.automation))
    this.closeMenu()
  }

  duplicate = () => {
    this.props.dispatch(openAutomationDuplicatePopup(this.props.automation))
  }

  getSupportDetails = () => {
    let details = {
      automation_id: this.props.automation.id,
      provider_id: this.props.automation.provider_id,
      network_code: this.props.automation.network_code,
      automation_link: generateAutomationUrl(this.props.automation, true),
    }

    return JSON.stringify(details)
  }

  copySupportDetails = () => {
    this.closeMenu()
  }

  copyFullName = () => {
    this.closeMenu()
  }

  render() {
    let editAutomationBtn = null
    let duplicateAutomationBtn = null

    if (AuthApi.hasModule(ModuleTypes.AUTOMATION_CREATION)) {
      editAutomationBtn = (
        <MenuItem className="default-menu-item" onClick={this.edit}>
          Edit
        </MenuItem>
      )
      duplicateAutomationBtn = (
        <MenuItem className="default-menu-item" onClick={this.duplicate}>
          Duplicate
        </MenuItem>
      )
    }

    return (
      <div>
        <div
          aria-owns={this.state.open ? "campaign-options-menu" : null}
          aria-haspopup="true"
          className="d-flex options-icon-wrapper align-items-center justify-content-center"
          onClick={this.openMenu}
        >
          <ThreeDotsIcon className="three-dots" />
        </div>

        <Menu
          id="campaign-options-menu"
          classes={{
            paper: "default-menu campaign-options-menu",
          }}
          anchorEl={this.state.menuAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -5,
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          open={this.state.menuOpen}
          onClose={this.closeMenu}
          onClick={(event) => event.stopPropagation()}
        >
          <MenuItem key="placeholder" className="menu-item-placeholder" onClick={this.closeMenu} />
          {editAutomationBtn}
          {duplicateAutomationBtn}
          <CopyToClipboard text={this.props.automation.name}>
            <MenuItem className="default-menu-item" onClick={this.copyFullName}>
              Copy Full Name
            </MenuItem>
          </CopyToClipboard>
          <CopyToClipboard text={this.getSupportDetails()}>
            <MenuItem className="default-menu-item" onClick={this.copySupportDetails}>
              Copy Support Details
            </MenuItem>
          </CopyToClipboard>
        </Menu>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(AutomationOptionsMenu)
