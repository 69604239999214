import { isNullOrEmpty } from "./funcUtils"
import articlesTypeConsts from "../articles/components/articlesType"

export function getArticleTypeTag(postStyle) {
  if (isNullOrEmpty(postStyle)) {
    return articlesTypeConsts.content.tag
  }
  switch (postStyle) {
    case articlesTypeConsts.affiliation.name:
      return articlesTypeConsts.affiliation.tag
    case articlesTypeConsts.ads_for_search.name:
      return articlesTypeConsts.ads_for_search.tag
    default:
      return articlesTypeConsts.content.tag
  }
}

export function getArticleTypeFilterValue(postStyle) {
  if (isNullOrEmpty(postStyle)) {
    return articlesTypeConsts.content.name
  }
  switch (postStyle) {
    case articlesTypeConsts.affiliation.name:
      return postStyle
    case articlesTypeConsts.ads_for_search.name:
      return postStyle
    default:
      return articlesTypeConsts.content.name
  }
}
