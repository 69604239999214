import axios from "axios"
import Consts from "../app/consts"

export default class SavedFiltersService {
  static addSavedFilters(sectionId, groupName, filterData) {
    return axios.post(Consts.BASE_API_URL + "/api/saved_filters/add", {
      group_name: groupName,
      filter_data: filterData,
      section_id: sectionId,
    })
  }

  static deleteSavedFilters(id) {
    return axios.delete(Consts.BASE_API_URL + "/api/saved_filters/delete/" + id)
  }

  static getSavedFilters() {
    return axios.get(Consts.BASE_API_URL + "/api/saved_filters")
  }
}
