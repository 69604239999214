import React from "react"
import { connect } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import {
  campaignPopupCancelRequest,
  campaignPopupFetchCampaignPerformanceByHourAsync,
  campaignPopupLoading,
} from "../campaignPopupActions"
import CampaignPopupPerformanceByHourList from "./lists/performanceByHourList"
import CampaignPopupPerformanceByHourChart from "./charts/performanceByHourChart"

class CampaignPopupPerformanceByHour extends React.Component {
  componentDidMount() {
    this.getPerformanceByHour()
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      JSON.stringify(prevProps.datePickerDates.startDate) !== JSON.stringify(this.props.datePickerDates.startDate) ||
      JSON.stringify(prevProps.datePickerDates.endDate) !== JSON.stringify(this.props.datePickerDates.endDate)
    ) {
      this.getPerformanceByHour()
    }
  }

  componentWillUnmount() {
    campaignPopupCancelRequest("performance-by-hour-request")
  }

  getPerformanceByHour = () => {
    campaignPopupCancelRequest("performance-by-hour-request")
    this.props.dispatch(campaignPopupLoading())
    this.props.dispatch(
      campaignPopupFetchCampaignPerformanceByHourAsync(
        this.props.campaign,
        this.props.datePickerDates.startDate,
        this.props.datePickerDates.endDate
      )
    )
  }

  render() {
    let performanceByHour = null

    if (this.props.isLoading) {
      performanceByHour = (
        <div className="campaign-popup-loading d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      performanceByHour = (
        <div className="performance-by-hour">
          <CampaignPopupPerformanceByHourChart />
          <CampaignPopupPerformanceByHourList />
        </div>
      )
    }

    return <div className="campaign-popup-performance-by-hour">{performanceByHour}</div>
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    isLoading: state.campaignPopupV2.isLoading,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByHour)
