import React from "react"
import { connect } from "react-redux"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import GenericDropdown from "../../../../common/components/genericDropdown"
class CampaignTypeDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      selectedIndexesArr: [],
    }
  }
  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }
  handleCampaignTypeSelection = (selectedTypeIndex) => {
    let campaignTypes = this.props.campaignTargetingGroup.campaignTypes
    campaignTypes = campaignTypes.map((type, index) => {
      return {
        ...type,
        selected: index === selectedTypeIndex,
      }
    })
    if (this.props.isPreset) {
      let targetingGroupData = Object.assign({}, this.props.campaignTargetingGroup, { campaignTypes })
      this.props.onChange(targetingGroupData)
    } else {
      this.props.handleBidTypeSelection(campaignTypes[selectedTypeIndex].value)
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, { campaignTypes }))
    }
    this.setState({
      selectedIndexesArr: [selectedTypeIndex],
    })

    this.handleMenuClose()
  }

  autoSelectCampaignTypeIfSingle = () => {
    const selectedCampaignTypeIndex = this.props.campaignTargetingGroup.campaignTypes.findIndex((type) => type.selected)
    if (selectedCampaignTypeIndex >= 0) {
      this.handleCampaignTypeSelection(selectedCampaignTypeIndex)
    }
  }

  componentDidMount() {
    this.autoSelectCampaignTypeIfSingle()
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  render() {
    let buttonClassName = "creation-generic-dropdown-button "
    let campaignTypeSelected = this.props.campaignTargetingGroup.campaignTypes.filter((type) => type.selected)

    let campaignTypesHeader = "Choose Bid Type"
    if (campaignTypeSelected.length > 0) {
      campaignTypesHeader = campaignTypeSelected[0].name
    }

    return (
      <>
        <div
          className={"text-field conversion-event-selection " + (this.props.bidTypeValidationErrors ? "error " : "")}
        >
          <div className="creation-generic-dropdown-label">Bid Type</div>
          <GenericDropdown
            header={campaignTypesHeader}
            selectOptionCallback={(item) => {
              this.handleCampaignTypeSelection(item[0])
            }}
            options={this.props.campaignTargetingGroup.campaignTypes}
            open={this.state.menuOpen}
            anchorEl={this.state.menuAnchorEl}
            selectedIndexes={this.state.selectedIndexesArr}
            openDropdownCallback={(event) => {
              this.handleMenuOpen(event)
            }}
            allowAutoSuggest={true}
            showEmptyOption={false}
            closeDropdownCallback={this.handleMenuClose}
            allowNoSelection={false}
            buttonClassName={buttonClassName}
            menuClassName="creation-generic-dropdown-menu"
          />
          {this.props.bidTypeValidationErrors}
        </div>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isLoadingProviderAccounts: state.campaignCreationWizard.isLoadingProviderAccounts,
    siteId: state.campaignCreationWizard.campaignSettings.siteId,
  }
}

export default connect(mapStateToProps)(CampaignTypeDropdown)
