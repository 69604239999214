import axios from "axios"
import localforage from "localforage"
import { webStorage } from "../api/webStorage"
import WebStorageConsts from "../common/consts/webStorageConsts"
import { redirectToLogin, redirectToUrl } from "./routingUtils"
import Consts from "../app/consts"
import AuthService from "../api/authService"
import { history, store } from "../app/store"
import { notificationAdd } from "../common/actions/commonActions"

export let interceptors = {
  requestInterceptor: null,
  responseInterceptor: null,
  isIntercepting: false,
}

function getAccessToken() {
  return webStorage.get(WebStorageConsts.STORAGE_KEYS.PUBPLUS_AUTH_TOKEN)
}

export function isAuthenticated() {
  let token = getAccessToken()

  // A token is present
  if (token) {
    // Token with a valid JWT format XXX.YYY.ZZZ
    if (token.split(".").length === 3) {
      // Could be a valid JWT or an access token with the same format
      try {
        let base64Url = token.split(".")[1]
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
        let exp = JSON.parse(window.atob(base64)).exp
        // JWT with an optonal expiration claims
        if (exp) {
          let isExpired = Math.round(new Date().getTime() / 1000) >= exp

          if (isExpired) {
            // FAIL: Expired token
            return false
          } else {
            // PASS: Non-expired token
            return true
          }
        }
      } catch (e) {
        // PASS: Non-JWT token that looks like JWT
        return true
      }
    }
    // PASS: All other tokens
    return true
  }
  // FAIL: No token at all
  return false
}

export function checkAuth() {
  if (!isAuthenticated()) {
    redirectToLogin()
  } else if (!interceptors.isIntercepting) {
    interceptRequestsAndResponses()
  }
}

export function checkIsAdmin(user) {
  if (!user.is_admin) {
    redirectToUrl("/")
  }
}

function interceptRequestsAndResponses() {
  interceptors.requestInterceptor = axios.interceptors.request.use(
    (config) => {
      // Re-checking the authentication on every ajax request in case the token expired
      if (!isAuthenticated()) {
        redirectToLogin()
        return config
      }

      // s3 requires only one way of authentication and its not using Authorization header,
      // so we must remove it when we upload thumbnails.
      if (
        config.method !== "OPTIONS" &&
        !config.url.includes("s3.amazonaws.com") &&
        !config.url.includes("graph.facebook.com")
      ) {
        config.headers.Authorization = "Bearer " + getAccessToken()
        config.headers["X-PP-Client-Id"] = Consts.CLIENT_ID
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  interceptors.responseInterceptor = axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject()
      }

      if (error.response) {
        if (error.response.status === 401) {
          AuthService.logout()
        }
        if (error.response.data.user_friendly_message && error.response.data.show_client_error) {
          store.dispatch(notificationAdd(error.response.data.user_friendly_message))
        } else if (error.response.data.message && error.response.data.show_client_error) {
          store.dispatch(notificationAdd(error.response.data.message))
        }
      }

      return Promise.reject(error)
    }
  )

  interceptors.isIntercepting = true
}
