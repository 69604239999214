import axios from "axios"
import localforage from "localforage"
import { webStorage } from "../api/webStorage"
import { history, store } from "../app/store"
import { resetApplication } from "../common/actions/commonActions"
import AuthService from "../api/authService"
import ModuleTypes from "../common/consts/moduleTypes"
import { interceptors, isAuthenticated } from "./authUtils"
import Consts from "../app/consts"

export function redirectToLogin() {
  // Shutting down / disabling third-party user-specific additions
  window.Intercom("shutdown")

  // Removing axios request / response interceptors
  axios.interceptors.request.eject(interceptors.requestInterceptor)
  axios.interceptors.response.eject(interceptors.responseInterceptor)
  interceptors.requestInterceptor = null
  interceptors.responseInterceptore = null
  interceptors.isIntercepting = false

  // Removing user's localStorage
  webStorage.clear()
  localforage.clear()

  // Actual redirect
  redirectToUrl("/login")

  // Resetting redux application state (initial state, sites, user etc)
  store.dispatch(resetApplication())
}

export function redirectToDefaultPage(network, site) {
  if (isAuthenticated()) {
    let hasCampaignManagementAccess = AuthService.hasModule(ModuleTypes.CAMPAIGN_MANAGEMENT)
    let hasTrafficSourcesAccess = AuthService.hasModule(ModuleTypes.TRAFFIC_SOURCES)
    let prefix = "/" + network.name + (site ? "/" + site.code : "")

    if (hasCampaignManagementAccess) {
      // Redirect to campaign-management page
      redirectToUrl(prefix + "/" + Consts.CAMPAIGN_MANAGEMENT)
    } else if (hasTrafficSourcesAccess) {
      // Redirect to traffic sources page if the user doesn't have campaign-management permission
      redirectToUrl(prefix + "/" + Consts.TRAFFIC_SOURCES)
    }
  }
}

export function redirectToDefaultSudoPage() {
  let hasSudoAccess = AuthService.hasModule(ModuleTypes.SUDO_USERS)

  if (hasSudoAccess) {
    // Redirect to users page
    redirectToUrl("/sudo/users")
  }
}

export function redirectToUrl(url) {
  history.replace(url)
}
