import React from "react"
import NumberFormat from "../../../common/components/numberFormat"
import AutomationsConsts from "../../../automations/automationsConsts"
import { isNullOrUndefined } from "../../../utils/funcUtils"
import { getProviderBidStepSize } from "../../../utils/providerUtils"

export default class ActionLimit extends React.Component {
  handleValueChange = (values) => {
    const { value, floatValue } = values

    let numberValue = value
    if (floatValue.toString() === value) {
      numberValue = floatValue
    } else if (value[value.length - 1] === "0" && floatValue !== 0) {
      numberValue = floatValue
    }

    this.props.handleSelect("actionLimit", numberValue)
  }

  getLabelName = () => {
    let label = ""
    this.props.automationDetailsState.selectedActionOperation?.operation ===
    AutomationsConsts.AUTOMATION_OPERATION_INCREASE
      ? (label += "Maximum ")
      : (label += "Minimum ")

    let actionTypeName =
      AutomationsConsts.ACTION_TYPE_VALUE[this.props.automationDetailsState.selectedActionType?.actionType]
    if (!isNullOrUndefined(actionTypeName)) {
      label += actionTypeName
    }

    return label
  }

  render() {
    const numberValue = this.props.automationDetailsState.actionLimit
    let decimalScale = 2
    if (this.props.automationDetailsState.selectedSource?.providerId) {
      decimalScale = getProviderBidStepSize(this.props.automationDetailsState.selectedSource.providerId).countDecimals()
    }

    return (
      <div className={"text-field action-limit-field " + (this.props.validationErrors ? "error " : "")}>
        <div className="creation-generic-dropdown-label">{this.getLabelName()}</div>
        <NumberFormat
          value={numberValue}
          displayType={"input"}
          onValueChange={this.handleValueChange}
          className="number-text-field limit"
          decimalScale={decimalScale}
          thousandSeparator
          prefix={"$"}
        />
        {this.props.validationErrors}
      </div>
    )
  }
}
