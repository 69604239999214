import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../genericDropdown"
import { keyboardShortcutsManager } from "../../../../utils/keyboardShortcutsManager"
import { mod } from "../../../../utils/mathUtils"

const OPTIONS_LEFT_SHORTCUT_KEY = "campaign_options_left"
const OPTIONS_RIGHT_SHORTCUT_KEY = "campaign_options_right"

class OptionsDropdownMenu extends React.Component {
  constructor(props) {
    super(props)

    let selectedIndexesArr = 0
    this.props.options.forEach((option, index) => {
      if (this.props.currentValue === option.value) {
        selectedIndexesArr = index
      }
    })

    this.state = {
      open: false,
      selectedIndexesArr: [selectedIndexesArr],
      anchorEl: null,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.currentValue !== nextProps.currentValue || this.props.options !== nextProps.options) {
      let selectedIndexesArr = 0
      nextProps.options.forEach((option, index) => {
        if (nextProps.currentValue === option.value) {
          selectedIndexesArr = index
        }
      })

      this.setState({
        selectedIndexesArr: [selectedIndexesArr],
      })
    }
  }

  handleMenuOpen = (event) => {
    if (!this.state.open) {
      this.setState({
        open: true,
        anchorEl: event.currentTarget,
      })
    }
  }

  handleMenuClose = () => {
    this.setState({
      open: false,
    })
  }

  onOptionSelected = (selectedIndexes) => {
    this.setState({
      selectedIndexesArr: selectedIndexes,
      open: false,
    })
    this.props.selectionCallback(selectedIndexes[0])
  }

  onOperatorMenuFocused = () => {
    keyboardShortcutsManager.addShortcut(
      OPTIONS_LEFT_SHORTCUT_KEY,
      ["shift", "left"],
      () => this.handleOptionsShortcutUsed("left"),
      false
    )
    keyboardShortcutsManager.addShortcut(
      OPTIONS_RIGHT_SHORTCUT_KEY,
      ["shift", "right"],
      () => this.handleOptionsShortcutUsed("right"),
      false
    )
  }

  onOperatorMenuBlur = () => {
    keyboardShortcutsManager.removeShortcut(OPTIONS_LEFT_SHORTCUT_KEY)
    keyboardShortcutsManager.removeShortcut(OPTIONS_RIGHT_SHORTCUT_KEY)
  }

  handleOptionsShortcutUsed = (direction) => {
    let length = this.props.options.length
    let index = this.state.selectedIndexesArr[0]
    let newIndex = index

    if (index !== -1) {
      switch (direction) {
        case "left":
          newIndex = mod(index - 1, length)
          break
        case "right":
          newIndex = mod(index + 1, length)
          break
      }
    }

    newIndex = Math.max(Math.min(length, newIndex), 0)

    this.onOptionSelected([newIndex])
  }

  render() {
    return (
      <div
        key="operation-selection"
        className="operators-container"
        tabIndex="1"
        onFocus={this.onOperatorMenuFocused}
        onBlur={this.onOperatorMenuBlur}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            this.handleMenuOpen(event)
          }
        }}
      >
        <GenericDropdown
          buttonClassName="operators-dropdown"
          header={this.props.options[this.state.selectedIndexesArr[0]].name}
          selectOptionCallback={this.onOptionSelected}
          options={this.props.options}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => this.handleMenuOpen(event)}
          closeDropdownCallback={() => this.handleMenuClose()}
          allowNoSelection={false}
          allowAutoSuggest={false}
          menuItemClasses={{ selected: "selected-option" }}
          menuClassName="generic-filter-dropdown"
          transformOrigin={{ vertical: -31, horizontal: 0 }}
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sites: state.app.sites,
  }
}

export default connect(mapStateToProps)(OptionsDropdownMenu)
