import axios from "axios"
import Consts from "../app/consts"

export default class LanguageService {
  static getLanguages() {
    return axios.get(Consts.BASE_API_URL + "/api/languages")
  }

  static getRecentLanguages() {
    return axios.get(Consts.BASE_API_URL + "/api/integrations/languages/recent_languages")
  }

  static increaseRecentLanguages(languageCodes) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/languages/increase_recent_languages", {
      language_codes: languageCodes,
    })
  }
}
