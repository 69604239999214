import React from "react"
import { connect } from "react-redux"
import { Menu, MenuItem } from "@material-ui/core"
import ThreeDotsIcon from "../../../resources/svgs/ThreeDotsIcon.svg"
import { dismissActivity, retryActivity, retryActivityWithMinderRequest } from "../../navigationBarActions"
import ActivityStatus from "../../../common/consts/activityStatus"
import { isNullOrUndefined } from "../../../utils/funcUtils"
import { openWizardFromCreationRequest } from "../../../campaignCreation/campaignCreationActions"
import { eventsTracker } from "../../../api/eventsTracker"

class ActivityOptionsMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuAnchorEl: null,
      menuOpen: false,
    }
  }

  openMenu = (event) => {
    this.setState({ menuOpen: true, menuAnchorEl: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  retryWithMinderRequest = (activityId, requestId) => {
    this.props.dispatch(retryActivityWithMinderRequest(activityId, requestId))
    this.closeMenu()
  }

  retry = (activityId) => {
    this.props.dispatch(retryActivity(activityId))
    this.closeMenu()
  }

  dismiss = (activityId) => {
    this.props.dispatch(dismissActivity(activityId))
    this.closeMenu()
  }

  goToProvider = () => {
    this.closeMenu()
  }

  openCampaignCreationWizardFromRequestData(requestData) {
    this.props.dispatch(openWizardFromCreationRequest(requestData))
    eventsTracker.trackDuplicateCampaignClicked(requestData?.utm_campaign, "Drawer")
  }

  render() {
    let isPendingActivity = this.props.activityStatus === ActivityStatus.PENDING
    let isSuccessfulActivity = this.props.activityStatus === ActivityStatus.SUCCESS
    let retryBtn = null
    let goToProviderBtn = null
    let dismissBtn = null
    let duplicateBtn = null
    let isGoogleAdsCreation = this.props.providerName === "GoogleAds"

    if (this.props.providerName && this.props.managementUrl && !isPendingActivity && !isSuccessfulActivity) {
      goToProviderBtn = (
        <a className="default-menu-item" href={this.props.managementUrl} target="_blank" onClick={this.goToProvider}>
          Go to {this.props.providerName}
        </a>
      )
    }

    if (!isPendingActivity && !isSuccessfulActivity) {
      let retryFunc
      // Retry using minder request id (only for old activities of campaign management)
      if (this.props.requestId && this.props.activityType !== 2) {
        retryFunc = () => this.retryWithMinderRequest(this.props.activityId, this.props.requestId)
        // Retry using activity id
      } else if (this.props.activityData?.request_data) {
        retryFunc = () => this.retry(this.props.activityId)
      }

      if (retryFunc) {
        retryBtn = (
          <MenuItem className="default-menu-item" onClick={retryFunc}>
            Retry
          </MenuItem>
        )
      }
    }

    if (this.props.isImportant && !isPendingActivity && !isSuccessfulActivity) {
      dismissBtn = (
        <MenuItem className="default-menu-item" onClick={() => this.dismiss(this.props.activityId)}>
          Dismiss
        </MenuItem>
      )
    }

    // GoogleAds doesn't support duplicate at the moment
    if (!isNullOrUndefined(this.props.activityStatus) && this.props.activityType === 2 && !isGoogleAdsCreation) {
      let duplicateFunc
      if (this.props.activityData?.request_data) {
        duplicateFunc = () => this.openCampaignCreationWizardFromRequestData(this.props.activityData.request_data)
      }

      if (duplicateFunc) {
        duplicateBtn = (
          <MenuItem className="default-menu-item" onClick={duplicateFunc}>
            Duplicate
          </MenuItem>
        )
      }
    }

    if ([goToProviderBtn, retryBtn, dismissBtn, duplicateBtn].every((btn) => btn === null)) {
      // prevent empty menu items
      return null
    }

    return (
      <div>
        <div
          aria-owns={this.state.open ? "activity-options-menu" : null}
          aria-haspopup="true"
          className="d-flex options-icon-wrapper align-items-center justify-content-center"
          onClick={this.openMenu}
        >
          <ThreeDotsIcon className="three-dots" />
        </div>

        <Menu
          id="activity-options-menu"
          classes={{ paper: "default-menu" }}
          anchorEl={this.state.menuAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          open={this.state.menuOpen}
          onClose={this.closeMenu}
        >
          {retryBtn}
          {goToProviderBtn}
          {duplicateBtn}
          {dismissBtn}
        </Menu>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(ActivityOptionsMenu)
