import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import { Switch } from "@material-ui/core"

const StatusSwitch = (props) => {
  const [isActive, setIsActive] = useState(props.campaignTargetingGroup.isActive)

  useEffect(() => {
    setIsActive(props.campaignTargetingGroup.isActive)
  }, [props.campaignTargetingGroup.isActive])

  const onChangeEvent = (event) => {
    const newStatus = event.target.checked
    setIsActive(newStatus)

    const updatedValues = { isActive: newStatus }

    if (props.onChange) {
      props.onChange(updatedValues)
    } else {
      props.dispatch(updateTargetingGroup(props.groupIndex, updatedValues))
    }
  }

  return (
    <div>
      <div className="status-field">
        <div className="status-label">Status on creation</div>
        <div className="status-switch">
          <Switch
            data-class="tooltip-custom type-icon-info"
            classes={{
              root: "switch",
              switchBase: "button-base",
              checked: "green",
              icon: "icon",
              bar: "bar",
            }}
            checked={isActive}
            onChange={onChangeEvent}
          />
          <span className="status-text">{isActive ? "Active" : "Paused"}</span>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(StatusSwitch)
