import React from "react"
import { connect } from "react-redux"
import { Button, CircularProgress } from "@material-ui/core"
import NumberFormat from "../../../common/components/numberFormat"
import { subSourceBidLoading, changeSubSourceBidAsync } from "../../subSourceActions"
import Consts from "../../../app/consts"
import { getProviderBidStepSize } from "../../../utils/providerUtils"

class SubSourceManualBid extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      localBid: 0,
      disableSaveButton: false,
      bidStepperDelta: Consts.STEPPER_STEP_REALTIME_BID_SUB_CENT,
      isLoading: false,
      maxBid: parseFloat((props.campaign.bid * 2).toFixed(3)),
      minBid: parseFloat((props.campaign.bid * 0.011).toFixed(3)),
    }
  }

  stepperPlus = (callback) => {
    let localBid = parseFloat(
      (this.state.localBid + this.state.bidStepperDelta).toFixed(this.state.bidStepperDelta.countDecimals())
    )
    this.setState(
      {
        localBid,
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  stepperMinus = (callback) => {
    let localBid = parseFloat(
      (this.state.localBid - this.state.bidStepperDelta).toFixed(this.state.bidStepperDelta.countDecimals())
    )

    this.setState(
      {
        localBid,
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  stepperClick = (type) => {
    switch (type) {
      case "plus":
        this.stepperPlus()
        break
      case "minus":
        this.stepperMinus()
        break
      default:
        break
    }
  }

  handleKeyPress = (event) => {
    if (!event.key) {
      return
    }

    switch (event.key) {
      case "ArrowUp":
        this.stepperPlus()
        break
      case "ArrowDown":
        this.stepperMinus()
        break
      default:
        return
    }
  }

  focusBidChangeInput = () => {
    let input = document.querySelector(".bid-change-box input")

    if (input) {
      input.focus()

      // A workaround to set the text cursor at the last character position
      input.value = input.value + " "
      setTimeout(() => {
        input.value = input.value.trim()
      }, 0)
    }
  }

  componentWillMount() {
    let localBid = parseFloat(
      (this.props.subSource.bid ? this.props.subSource.bid : 0).toFixed(this.state.bidStepperDelta.countDecimals())
    )

    this.setState({
      localBid,
      bidStepperDelta: getProviderBidStepSize(this.props.campaign.provider_id),
    })
  }

  componentDidMount() {
    this.focusBidChangeInput()
  }

  getBidValidationText() {
    let bidValidationText = null
    const { localBid, maxBid, minBid } = this.state

    if (localBid > 0) {
      if (localBid > maxBid) {
        bidValidationText = `Bid exceeds the maximum boost of +100% ($${maxBid})`
      } else if (localBid < minBid) {
        bidValidationText = `Bid is below the minimum of -99% ($${minBid})`
      }
    }

    return bidValidationText
  }

  isSaveBidButtonDisabled = () => {
    if (
      this.state.localBid > this.state.maxBid ||
      this.state.localBid < this.state.minBid ||
      this.state.localBid.countDecimals() > getProviderBidStepSize(this.props.campaign.provider_id).countDecimals() ||
      this.state.localBid <= 0 ||
      this.state.disableSaveButton
    ) {
      return true
    }

    return false
  }

  saveBidChangeButton = () => {
    return (
      <>
        <Button
          disabled={this.isSaveBidButtonDisabled()}
          className="round-button green"
          onClick={(event) => this.saveChangeBidForm(event)}
        >
          Save
        </Button>
      </>
    )
  }

  changeBid = (event) => {
    event.preventDefault() // Preventing form submission (which is the default form behaviour when "enter" is pressed)
    if (!this.props.isSaveBidAllowed) {
      return false
    }

    this.props.dispatch(subSourceBidLoading(this.props.subSource))
    this.props.dispatch(changeSubSourceBidAsync(this.props.campaign, this.props.subSource, this.state.localBid))
    this.props.closePopup()
  }

  saveChangeBidForm = (event) => {
    event.preventDefault()
    const subSourceBid = this.props.subSource.bid ? this.props.subSource.bid : 0

    if (parseFloat(subSourceBid.toFixed(this.state.bidStepperDelta.countDecimals())) !== this.state.localBid) {
      this.changeBid(event)
      this.props.closePopup()
    } else {
      this.props.closePopup()
    }
  }

  handleBidChange = (values) => {
    const { value, floatValue } = values
    let localBid = null

    if (floatValue.toString() === value) {
      localBid = floatValue
    }
    // When value (which is string) ends with zero - it won't be equal to the float value (since float value will never end with zero)
    // For example: "0.010" !== "0.01"
    else if (value[value.length - 1] === "0") {
      // If the float value is not zero - we would like to update the value of the local bid
      if (floatValue !== 0) {
        localBid = floatValue
      }

      // If the float value equals to zero - we would like to disable the save button
      // We won't update the local bid in order to avoid change of "0.0" to "0"
      else {
        this.setState({
          disableSaveButton: true,
        })
      }
    }
    // If the user deleted all the characters in this popup - we will set the bid to zero and it will change the
    // save button to be disabled
    else if (value === "") {
      localBid = 0
    }

    if (localBid !== null) {
      this.setState({
        localBid,
        disableSaveButton: false,
      })
    }
  }

  render() {
    if (!this.props.isChangeBidAllowed) {
      return null
    }

    if (this.state.isLoading) {
      return (
        <div className="bid-change-box loading open d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={30} />
        </div>
      )
    }

    const bidValidationText = this.getBidValidationText()

    return (
      <>
        <form
          className={
            "bid-change-box open " +
            (this.props.isSaveBidAllowed ? "" : "save-bid-disabled ") +
            (bidValidationText ? "validation-sub-source-bid-change " : "")
          }
          onSubmit={
            this.isSaveBidButtonDisabled()
              ? (event) => event.preventDefault()
              : (event) => this.saveChangeBidForm(event)
          }
        >
          <div className="bid-change-box-title d-flex align-items-center">
            <div className="text">Manual Bid</div>
          </div>
          <div className="d-flex">
            <div className="stepper-button" onClick={() => this.stepperClick("minus")}>
              −
            </div>
            <NumberFormat
              value={this.state.localBid}
              displayType="input"
              prefix={"$"}
              onKeyDown={this.handleKeyPress}
              onValueChange={this.handleBidChange}
              className="textbox"
            />
            <div className="stepper-button" onClick={() => this.stepperClick("plus")}>
              +
            </div>
          </div>
          {this.props.isSaveBidAllowed ? (
            <div className="d-flex flex-column save-button-wrapper">
              {this.saveBidChangeButton()}
              <div className="bid-validation-text">{bidValidationText}</div>
            </div>
          ) : null}
        </form>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(SubSourceManualBid)
