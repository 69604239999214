class AdminNotificationMessageType {}

AdminNotificationMessageType.info = 0
AdminNotificationMessageType.warning = 1
AdminNotificationMessageType.error = 2
AdminNotificationMessageType.announcement = 3

AdminNotificationMessageType.idToName = {
  0: {
    title: "Info",
    color: "#0dcb5c",
    fontSize: null,
    textShadow: null,
    borderBottom: "1px solid #0cb150",
    isDismissable: true,
  },
  1: {
    title: "Warning",
    color: "#ff6f00",
    fontSize: null,
    textShadow: null,
    borderBottom: "1px solid #e06609",
    isDismissable: true,
  },
  2: {
    title: "Error",
    color: "#f22939",
    fontSize: null,
    textShadow: null,
    borderBottom: "1px solid #e02635",
    isDismissable: true,
  },
  3: {
    title: "Announcement",
    color: "#FF6F00",
    fontSize: "14px",
    textShadow: "0px 1px 3px #00000052",
    borderBottom: "1px solid #e02635",
    isDismissable: false,
  },
}

AdminNotificationMessageType.severities = [0, 1, 2, 3]

export default AdminNotificationMessageType
