import React from "react"
import { connect } from "react-redux"
import { Menu, MenuItem } from "@material-ui/core"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ThreeDotsIcon from "../../resources/svgs/ThreeDotsIcon.svg"
import { campaignPopupEdit, campaignPopupOpen } from "../../campaignPopupV2/campaignPopupActions"
import AuthApi from "../../api/authService"
import ModuleTypes from "../../common/consts/moduleTypes"
import { getCreativesAsync, openCampaignDuplicationWizard } from "../../campaignCreation/campaignCreationActions"
import { generateCampaignUrl, isUnknownCampaignTrafficSource } from "../../utils/campaignUtilsV2"
import { eventsTracker } from "../../api/eventsTracker"
import ActivitiesIconSmall from "../../resources/svgs/ActivitiesIconSmall.svg"
import { findReactComponentByDomElement, stopPropagationIfParentIsSelected } from "../../utils/domUtils"
import CampaignPopupLink from "../../campaignPopupV2/campaignPopupLink"
import CampaignsConsts from "../campaignsConsts"
import Consts from "../../app/consts"
import Moment from "moment"

class CampaignOptionsMenu extends React.Component {
  constructor() {
    super()

    this.state = {
      menuAnchorEl: null,
      menuOpen: false,
    }
  }

  openMenu = (event) => {
    stopPropagationIfParentIsSelected(event)
    this.setState({ menuOpen: true, menuAnchorEl: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  edit = () => {
    window.Intercom("trackEvent", "edit-campaign-opened")
    this.props.dispatch(campaignPopupEdit(this.props.campaign))
    this.closeMenu()
  }

  duplicate = () => {
    this.props.dispatch(openCampaignDuplicationWizard(this.props.campaign, true))
    this.props.dispatch(getCreativesAsync(this.props.campaign))
    this.closeMenu()

    eventsTracker.trackDuplicateCampaignClicked(this.props.campaign.tracking_code, this.props.sourceComponent)
  }

  getManagementUrl = () => {
    let campaign = this.props.campaign
    switch (campaign.provider_id) {
      case CampaignsConsts.PINTEREST_PROVIDER_ID:
        return (
          "https://ads.pinterest.com/advertiser/" +
          campaign.external_account_id +
          "/ads/edit/?adGroupId=" +
          campaign.external_adgroup_id +
          "&view=adgroup"
        )
      case CampaignsConsts.GOOGLE_PROVIDER_ID:
        return (
          "https://ads.google.com/aw/adgroups?campaignId=" +
          campaign.external_campaign_id +
          "&__e=" +
          campaign.external_account_id
        )
      case CampaignsConsts.FACEBOOK_PROVIDER_ID:
        let managementUrlForTodayParam =
          Moment().subtract(1, "days").format("YYYY-MM-DD") + "_" + Moment().format("YYYY-MM-DD") + ",today"
        return (
          "https://business.facebook.com/adsmanager/manage/adsets/?act=" +
          campaign.external_account_id +
          "&column_preset=PERFORMANCE_LEGACY" +
          "&attribution_windows=default&date=" +
          managementUrlForTodayParam +
          "&impression_breakdown=hourly_stats_aggregated_by_advertiser_time_zone&" +
          "selected_campaign_ids=" +
          campaign.external_campaign_id
        )
      case CampaignsConsts.TABOOLA_PROVIDER_ID:
        let today_date = Moment().format("YYYY-MM-DD")
        return `https://ads.taboola.com/campaigns?locale=en&accountId=${campaign.external_secondary_account_id}&campaignId=${campaign.external_campaign_id}&dimension=SPONSORED&startDate=${today_date}&endDate=${today_date}&datePreset=CUSTOM&reportId=day&reportViewName=All&page=1`
    }
  }

  editOnSource = () => {
    this.closeMenu()

    eventsTracker.trackEditOnSourceClicked(this.props.campaign.tracking_code)
  }

  articleLink = () => {
    this.closeMenu()

    eventsTracker.trackArticleLinkClicked(this.props.campaign.tracking_code)
  }

  getSupportDetails = () => {
    let details = {
      provider_id: this.props.campaign.provider_id,
      external_id: this.props.campaign.external_id,
      site_id: this.props.campaign.site_id,
      platform: this.props.campaign.device_platforms,
      campaign_link: generateCampaignUrl(this.props.campaign, true),
    }

    return JSON.stringify(details)
  }

  copySupportDetails = () => {
    eventsTracker.trackSupportDetailsClicked(this.props.campaign.tracking_code)

    this.closeMenu()
  }

  copyFullName = () => {
    eventsTracker.trackFullNameClicked(this.props.campaign.tracking_code)

    this.closeMenu()
  }

  openCampaignPopupOnTab = (tab) => {
    this.props.dispatch(campaignPopupOpen(this.props.campaign, tab))
    eventsTracker.trackCampaignOptionsMenuShortcutClicked(this.props.campaign.tracking_code, tab)

    this.closeMenu()
  }

  render() {
    let duplicateCampaignBtn = null
    let editOnSourceCampaignBtn = null
    let articleUrlBtn = null
    let isUnknownTrafficSource = isUnknownCampaignTrafficSource(this.props.campaign)
    let campaignShortcuts = null
    let exportPerformanceByDayBtn = null
    let exportBidHistoryBtn = null

    let url = this.props.campaign.link_url
    let managementUrl = this.getManagementUrl()

    if (
      AuthApi.hasModule(ModuleTypes.CAMPAIGN_CREATION) &&
      !isUnknownTrafficSource &&
      !Consts.PROVIDERS_THAT_CANT_CREATE_CAMPAIGNS.includes(this.props.campaign.provider_id)
    ) {
      duplicateCampaignBtn = (
        <MenuItem className="default-menu-item" onClick={this.duplicate}>
          Duplicate
        </MenuItem>
      )
    }

    if (managementUrl) {
      editOnSourceCampaignBtn = (
        <a className="default-menu-item" href={managementUrl} target="_blank" onClick={this.editOnSource}>
          Edit On Source
        </a>
      )
    }

    if (url) {
      articleUrlBtn = (
        <a className="default-menu-item" href={url} target="_blank" onClick={this.articleLink}>
          Article Link
        </a>
      )
    }

    if (this.props.showCampaignShortcuts) {
      campaignShortcuts = (
        <div className="campaign-shortcuts">
          <CampaignPopupLink campaign={this.props.campaign} onClick={() => this.openCampaignPopupOnTab("BidHistory")}>
            <ActivitiesIconSmall />
          </CampaignPopupLink>
        </div>
      )
    }

    if (this.props.showExportButton) {
      if (this.props.campaignPopupMainSubSection === "Day") {
        exportPerformanceByDayBtn = (
          <MenuItem
            className="default-menu-item"
            onClick={() => {
              let performanceByDayTable = findReactComponentByDomElement(
                document.querySelector(".campaign-popup-performance-by-day .generic-table")
              )
              performanceByDayTable.exportToCsv()
              eventsTracker.trackExport(
                "campaign_performance",
                null,
                null,
                Moment(this.props.datePickerDates.startDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2),
                Moment(this.props.datePickerDates.endDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2),
                this.props.campaignPopupCampaign.tracking_code
              )
              this.closeMenu()
            }}
          >
            Export table
          </MenuItem>
        )
      } else if (this.props.campaignPopupMainSubSection === "BidHistory") {
        exportBidHistoryBtn = (
          <MenuItem
            className="default-menu-item"
            onClick={() => {
              let bidHistoryTable = findReactComponentByDomElement(
                document.querySelector(".bid-history .generic-table")
              )
              bidHistoryTable.exportToCsv()
              eventsTracker.trackExport(
                "campaign_activity",
                null,
                null,
                Moment(this.props.datePickerDates.startDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2),
                Moment(this.props.datePickerDates.endDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2),
                this.props.campaignPopupCampaign.tracking_code
              )
              this.closeMenu()
            }}
          >
            Export table
          </MenuItem>
        )
      }
    }

    return (
      <div>
        <div
          aria-owns={this.state.open ? "campaign-options-menu" : null}
          aria-haspopup="true"
          className="d-flex options-icon-wrapper align-items-center justify-content-center"
          onClick={this.openMenu}
        >
          <ThreeDotsIcon className="three-dots" />
        </div>

        <Menu
          id="campaign-options-menu"
          classes={{
            paper:
              "default-menu campaign-options-menu " +
              (this.props.showCampaignShortcuts ? "show-campaign-shortcuts" : ""),
          }}
          anchorEl={this.state.menuAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -5,
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          open={this.state.menuOpen}
          onClose={this.closeMenu}
          onClick={(event) => event.stopPropagation()}
        >
          {/* <MenuItem className="default-menu-item" onClick={this.edit}> */}
          {/* Edit */}
          {/* </MenuItem>, */}
          <MenuItem key="placeholder" className="menu-item-placeholder" onClick={this.closeMenu} />
          {duplicateCampaignBtn}
          {editOnSourceCampaignBtn}
          {articleUrlBtn}
          <CopyToClipboard text={this.props.campaign.tracking_code}>
            <MenuItem className="default-menu-item" onClick={this.copyFullName}>
              Copy Full Name
            </MenuItem>
          </CopyToClipboard>
          <CopyToClipboard text={this.getSupportDetails()}>
            <MenuItem className="default-menu-item" onClick={this.copySupportDetails}>
              Copy Support Details
            </MenuItem>
          </CopyToClipboard>
          {exportPerformanceByDayBtn}
          {exportBidHistoryBtn}
          {campaignShortcuts}
        </Menu>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaignPopupMainSubSection: state.campaignPopupV2.mainSubSection,
    campaignPopupCampaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
  }
}

export default connect(mapStateToProps)(CampaignOptionsMenu)
