import React from "react"
import { connect } from "react-redux"
import Moment from "moment"
import Consts from "../../../app/consts"
import {
  chartAxisStyleUpdater,
  generateChart,
  generateChartSeries,
  positionCheckboxes,
} from "../../../utils/chartUtils"
import ChartColors from "../../../common/consts/chartColors"
import EmptyChartIndicator from "../../../common/components/emptyChartIndicator"

const verticalAxes = {
  "sessions-axis": {
    title: { text: "" },
    selectionIndex: 1,
    id: "sessions-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value:,.0f}" },
    maxPadding: 0.1,
  },
  "user-value-axis": {
    title: { text: "" },
    selectionIndex: 0,
    opposite: true,
    id: "user-value-axis",
    labels: { align: "right", x: -3, y: -3, format: "${value}" },
    maxPadding: 0.1,
  },
  "roi-axis": {
    title: { text: "" },
    visible: false,
    id: "roi-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
  "estimated-profit-axis": {
    title: { text: "" },
    visible: false,
    id: "estimated-profit-axis",
    labels: { align: "left", x: 3, y: -3, format: "${value:,.0f}", overflow: false },
    maxPadding: 0.1,
  },
  "rpm-axis": {
    title: { text: "" },
    visible: false,
    id: "rpm-axis",
    labels: { align: "left", x: 3, y: -3, format: "${value}" },
    maxPadding: 0.1,
  },
  "ctr-axis": {
    title: { text: "" },
    visible: false,
    id: "ctr-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
  "pps-axis": {
    title: { text: "" },
    visible: false,
    id: "pps-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}" },
    maxPadding: 0.1,
  },
  "bounce-rate-axis": {
    title: { text: "" },
    visible: false,
    id: "bounce-rate-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
  "daily-spend-axis": {
    title: { text: "" },
    visible: false,
    id: "daily-spend-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
  "revenue-axis": {
    title: { text: "" },
    visible: false,
    id: "revenue-axis",
    labels: { align: "left", x: 3, y: -3, format: "{value}%" },
    maxPadding: 0.1,
  },
}

class CampaignPopupPerformanceByDayChart extends React.Component {
  render() {
    let emptyChartIndicator = null

    if (!this.props.hasData) {
      emptyChartIndicator = <EmptyChartIndicator />
    }

    return (
      <div className="campaign-popup-chart-wrapper">
        {emptyChartIndicator}
        <div className="performance-by-day-chart campaign-popup-chart" id="performance-by-day" />
      </div>
    )
  }

  drawChart = () => {
    let generateSeries = (title, dataItem, multiplyDataBy, axisName, visible, color, moreOptions) => {
      let moreChartOptions = Object.assign({}, moreOptions, {
        data: this.props.performanceByDay.map((item) => {
          return [Moment(item.date, Consts.SERVER_DATE_FORMAT).valueOf(), item[dataItem] * multiplyDataBy]
        }),
        color: color,
      })
      return generateChartSeries(title, axisName, visible, moreChartOptions)
    }

    positionCheckboxes()

    let xAxisArr = [
      generateSeries("Visits", "visits", 1, "sessions-axis", true, ChartColors.SESSIONS),
      generateSeries("Visit Value", "visit_value", 1, "user-value-axis", true, ChartColors.USER_VALUE, {
        tooltip: { valuePrefix: "$", valueDecimals: 3 },
      }),
      generateSeries("Avg. CPC", "cost_per_click", 1, "user-value-axis", false, ChartColors.REALTIME_BID, {
        tooltip: { valuePrefix: "$", valueDecimals: 3 },
      }),
      generateSeries("ROI", "roi", 100, "sessions-axis", false, ChartColors.ROI, {
        tooltip: { valueSuffix: "%", valueDecimals: 0 },
      }),
      generateSeries("Profit", "profit", 1, "user-value-axis", false, ChartColors.ESTIMATED_PROFIT, {
        tooltip: { valuePrefix: "$", valueDecimals: 0 },
      }),
      generateSeries("RPM", "rpm", 1, "sessions-axis", false, ChartColors.RPM, {
        tooltip: { valuePrefix: "$", valueDecimals: 2 },
      }),
      generateSeries("Click Through Rate", "ctr", 100, "user-value-axis", false, ChartColors.CTR, {
        tooltip: { valueSuffix: "%", valueDecimals: 2 },
      }),
      generateSeries("Pages Per Visit", "pages_per_visit", 1, "sessions-axis", false, ChartColors.PAGES_PER_SESSION, {
        tooltip: { valueDecimals: 2 },
      }),
      generateSeries("Bounce Rate", "bounce_rate", 1, "user-value-axis", false, ChartColors.BOUNCE_RATE, {
        tooltip: { valueSuffix: "%", valueDecimals: 1 },
      }),
      generateSeries("Spend", "daily_spent", 1, "user-value-axis", false, ChartColors.DAILY_SPEND, {
        tooltip: { valueSuffix: "%", valueDecimals: 2 },
      }),
      generateSeries("Revenue", "revenue", 1, "user-value-axis", false, ChartColors.REVENUE, {
        tooltip: { valueSuffix: "%", valueDecimals: 2 },
      }),
    ]

    let yAxisArr = [
      verticalAxes["sessions-axis"],
      verticalAxes["user-value-axis"],
      verticalAxes["roi-axis"],
      verticalAxes["estimated-profit-axis"],
      verticalAxes["rpm-axis"],
      verticalAxes["ctr-axis"],
      verticalAxes["pps-axis"],
      verticalAxes["bounce-rate-axis"],
      verticalAxes["revenue-axis"],
      verticalAxes["daily-spend-axis"],
    ]

    let chartSpecificOptions = {
      chart: {
        marginTop: 1,
        marginLeft: 1,
        marginRight: 170,
      },
      legend: {
        x: 30,
        y: -24,
        itemMarginBottom: 8,
      },
      yAxis: yAxisArr,
      series: xAxisArr,
      xAxis: {
        minPadding: 0.05,
        maxPadding: 0.05,
        tickInterval: 24 * 3600 * 1000, // one day
      },
    }

    let chart = generateChart("performance-by-day", chartSpecificOptions)
    chartAxisStyleUpdater(chart)
  }

  componentDidMount() {
    this.drawChart()
  }

  componentDidUpdate() {
    this.drawChart()
  }
}

function mapStateToProps(state, ownProps) {
  return {
    hasData: state.campaignPopupV2.performanceByDay.hasData,
    performanceByDay: state.campaignPopupV2.performanceByDay.dataForChart,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByDayChart)
