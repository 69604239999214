import axios from "axios"
import Consts from "../app/consts"

export default class ProviderService {
  static getProviders() {
    return axios.post(Consts.BASE_API_URL + "/api/traffic_providers")
  }

  static setFacebookAccessToken(network, code, redirectUri) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/providers/set_facebook_access_token", {
      network,
      network_id: network.id,
      code,
      redirect_uri: redirectUri,
    })
  }

  static setPinterestAccessToken(code, network) {
    return axios.post(Consts.BASE_API_URL + "/api/integrations/providers/set_pinterest_access_token", {
      redirect_uri: Consts.REDIRECT_URI,
      code,
      network,
    })
  }
}
