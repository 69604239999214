import React from "react"
import { connect } from "react-redux"
import Autosuggest from "react-autosuggest"
import { findDOMNode } from "react-dom"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import Close from "@material-ui/icons/Close"
import { Button, MenuItem, MenuList, Popover, TextField } from "@material-ui/core"
import Moment from "moment"
import ReactTooltip from "react-tooltip"
import Consts from "../../app/consts"
import FilterTypes, { Filters } from "../../common/consts/filterTypesV2"
import FilterDatePicker from "../../common/components/filterV2/filterDatePicker"
import EditableChip from "../../common/components/editableChip"
import { focusParentWithClass, isNullOrUndefined } from "../../utils/funcUtils"
import { keyboardShortcutsManager } from "../../utils/keyboardShortcutsManager"
import { deepCompare } from "../../utils/arrayUtils"
import OptionsDropdownMenu from "../../common/components/filterV2/menus/optionsDropdownMenu"
import Presets from "../../common/consts/presets"
import { KeyboardArrowDown } from "@material-ui/icons"
import { formatValueOrReturnOriginal } from "../../utils/automationUtils"

function getSuggestionValue(suggestion) {
  return suggestion.name
}

function renderSuggestionsContainer(options) {
  return null
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  // This function is required by react-autosuggest
  return null
}

const FILTER_OPERATORS_LEFT_SHORTCUT_KEY = "campaign_filter_operators_left"
const FILTER_OPERATORS_RIGHT_SHORTCUT_KEY = "campaign_filter_operators_right"

class Condition extends React.Component {
  constructor() {
    super()

    this.state = {
      search: "",
      suggestions: [],
      allSuggestions: [],
      open: false,
      anchorEl: null,
      menuArr: [],
      filter: null,
      primaryValue: "",
      secondaryValue: "",
    }
  }

  componentWillMount() {
    this.setState({ menuArr: [this.createRootMenu] })
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  componentDidUpdate(prevProps, prevState) {
    ReactTooltip.rebuild()
    if (prevProps && prevProps.currentNetwork && prevProps.currentNetwork.id !== this.props.currentNetwork.id) {
      this.resetSiteBasedFilters()
    }
    if (prevProps && JSON.stringify(prevProps.filterTypes) !== JSON.stringify(this.props.filterTypes)) {
      this.clearAllFilters()
    }
  }

  static getDisplayName(filter) {
    let displayName = ""

    if (filter.editable) {
      if (filter.filterValueType === FilterTypes.filterValueTypes.DATE) {
        let firstValue
        let secondValue

        if (Presets.FILTER_PRESETS.includes(filter.filterValue[0])) {
          firstValue = Presets.PRESETS.get(filter.filterValue[0]).text
        } else {
          firstValue = Moment(filter.filterValue[0])
          if (
            [FilterTypes.filterOperators.BETWEEN, FilterTypes.filterOperators.NOT_BETWEEN].includes(
              filter.filterOperator
            )
          ) {
            secondValue = Moment(filter.filterValue[1])
          }
        }

        if (
          [FilterTypes.filterOperators.BETWEEN, FilterTypes.filterOperators.NOT_BETWEEN].includes(
            filter.filterOperator
          ) &&
          Presets.FILTER_PRESETS.includes(filter.filterValue[0])
        ) {
          displayName = filter.filterName + " is [operator] " + formatValueOrReturnOriginal(firstValue)
        } else if (
          [FilterTypes.filterOperators.BETWEEN, FilterTypes.filterOperators.NOT_BETWEEN].includes(filter.filterOperator)
        ) {
          displayName =
            filter.filterName +
            " is [operator] " +
            firstValue.format(Consts.CLIENT_SHORT_DATE_FORMAT) +
            " - " +
            secondValue.format(Consts.CLIENT_SHORT_DATE_FORMAT)
        } else if (filter.filterOperator === FilterTypes.filterOperators.NOT_EXIST) {
          displayName = filter.filterName + " [operator]"
        } else {
          displayName = filter.filterName + " [operator] " + formatValueOrReturnOriginal(firstValue)
        }
      } else if (
        [FilterTypes.filterOperators.BETWEEN, FilterTypes.filterOperators.NOT_BETWEEN].includes(filter.filterOperator)
      ) {
        displayName = filter.filterName + " is [operator] [PrimaryValue]- [SecondaryValue]"
      } else {
        displayName = filter.filterName + " [operator] [PrimaryValue]"
      }
    } else {
      if (filter.isExclude) {
        displayName = "Not "
      }

      // Handle case of missing filterValueName attribute (conditions deserialization)
      if (
        isNullOrUndefined(filter.filterValueName) &&
        !isNullOrUndefined(filter.getOptions) &&
        Array.isArray(filter.filterValue)
      ) {
        const filterValue = filter.filterValue[0]
        const options = filter.getOptions()
        const item = options.find((obj) => obj.value === filterValue)
        if (item?.name) {
          filter.filterValueName = item.name
        }
      }

      displayName += filter.filterValueName ? filter.filterValueName : filter.filterName
    }

    return displayName
  }

  getMenu() {
    let menuFunc = this.state.menuArr[this.state.menuArr.length - 1]
    return menuFunc(this)
  }

  addArrayOfFilters = (filtersArr) => {
    let filterMap = new Map(this.props.filters)

    filtersArr.forEach((value) => {
      let filterByType
      let foundSameFilter = false

      if (value.shouldOverrideFilter || !filterMap.get(value.filterType)) {
        filterByType = {
          includeFilters: [],
          excludeFilters: [],
        }
      } else {
        let currentFilters
        filterByType = filterMap.get(value.filterType)

        if (value.isExclude) {
          currentFilters = filterByType.excludeFilters
        } else {
          currentFilters = filterByType.includeFilters
        }

        currentFilters.forEach((filter) => {
          if (deepCompare(filter.filterValue, value.filterValue)) {
            foundSameFilter = true
          }
        })
      }

      if (!foundSameFilter) {
        if (this.props.onFilterItemAdd) {
          this.props.onFilterItemAdd(value)
        }

        if (value.isExclude) {
          filterByType.excludeFilters.push(value)
        } else {
          filterByType.includeFilters.push(value)
        }

        filterMap.set(value.filterType, filterByType)
      }
    })

    this.props.filterCallback(filterMap)
  }

  onItemAdded = (event, value) => {
    this.addArrayOfFilters([value])
  }

  updateFilter = (event, value) => {
    this.onItemAdded(event, value)
  }

  onItemRemoved = (event, filter) => {
    let newMap = new Map(this.props.filters)
    let filterByType = newMap.get(filter.filterType)

    if (filterByType) {
      if (filter.isExclude) {
        filterByType.excludeFilters = filterByType.excludeFilters.filter(
          (filterItem) => !deepCompare(filter.filterValue, filterItem.filterValue)
        )
      } else {
        filterByType.includeFilters = filterByType.includeFilters.filter(
          (filterItem) => !deepCompare(filter.filterValue, filterItem.filterValue)
        )
      }
    }

    if (filterByType.includeFilters.length === 0 && filterByType.excludeFilters.length === 0) {
      newMap.delete(filter.filterType)
    } else {
      newMap.set(filter.filterType, filterByType)
    }

    this.props.filterCallback(newMap)
  }

  resetSiteBasedFilters = () => {
    let newMap = new Map(this.props.filters)
    newMap.delete(Filters.CAMPAIGN_SITE.filterType)
    newMap.delete(Filters.CAMPAIGN_NAME_AND_EXTERNAL_ID.filterType)
    newMap.delete(Filters.CAMPAIGN_NETWORK.filterType)
    this.props.filterCallback(newMap)
  }

  goBack = (event) => {
    let menu = this.state.menuArr.slice(0)

    menu.pop()
    this.setState(
      {
        search: "",
        menuArr: menu,
        primaryValue: "",
        secondaryValue: "",
      },
      () => {
        if (menu.length === 1) {
          document.querySelector("#filter-text-field").focus()
        } else {
          document.querySelector(".search-divider").focus()
        }
      }
    )
  }

  clearAllFilters = () => {
    let count = 0

    this.props.filters.forEach((value, key) => {
      value.includeFilters.forEach(() => (count += 1))
      value.excludeFilters.forEach(() => (count += 1))
    })

    this.props.filterCallback(new Map())
  }

  handleMenuRequestClose = () => {
    this.setState(
      {
        open: false,
        search: "",
        isEditing: false,
      },
      () => {
        this.setState({ menuArr: [this.createRootMenu] })
      }
    )
  }

  handleSuggestionsFetchRequested = (input) => {
    this.setState({ suggestions: this.getSuggestions(input.value) })
  }

  handleSuggestionsClearRequested = () => {
    this.setState({}, () => {
      if (this.state.search.length === 0) {
        this.setState({
          search: "",
        })
      }
    })
  }

  handleClearSearchFilter = () => {
    this.setState(
      {
        suggestions: this.state.allSuggestions,
      },
      () => {
        this.setState({
          search: "",
        })
      }
    )
  }

  handleChange = (event, newValue) => {
    this.setState({
      search: event.target.value,
    })
  }

  setNewValue = (name, event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  getSuggestions = (value) => {
    if (value !== undefined) {
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length

      if (inputLength === 0) {
        return []
      }

      let suggestions = this.state.allSuggestions.filter((suggestion) => {
        return suggestion.name.toLowerCase().includes(inputValue)
      })

      return suggestions.slice(0, Consts.SEARCH_MAX_RESULTS)
    }
  }

  initSuggestions = () => {
    let filterItems = []

    this.props.filterTypes.forEach((section) => {
      section.forEach((filter) => {
        filterItems.push(...filter.getSuggestions(this, filter))
      })
    })

    return filterItems
  }

  handleFocusChange = (event) => {
    focusParentWithClass(event, "search-divider")
  }

  handleClickButton = () => {
    let menu = [this.createRootMenu] // remove all menus beside the root menu
    if (this.state.open) {
      this.setState(
        {
          open: false,
          anchorEl: findDOMNode(this.button),
          filter: null,
          primaryValue: "",
          secondaryValue: "",
          isEditing: false,
        },
        () => {
          this.removeArrowsShortcuts()
          this.setState({ menuArr: menu })
        }
      )
    } else {
      let arr = this.initSuggestions()
      this.setState({
        open: true,
        anchorEl: findDOMNode(this.button),
        menuArr: menu,
        suggestions: arr,
        allSuggestions: arr,
        filter: null,
        primaryValue: "",
        secondaryValue: "",
      })
    }
  }

  renderInput = (inputProps) => {
    const { autoFocus, value, ref } = inputProps
    let closeButton = null

    if (this.state.search && this.state.search.length > 0) {
      closeButton = <Close className="clear-filter-text" />
    }

    return (
      <div className="d-flex align-items-center">
        <TextField
          classes={{ root: "text-field" }}
          id="filter-text-field"
          autoFocus={autoFocus}
          value={value}
          inputRef={ref}
          InputProps={{ inputProps }}
        />
        <span onClick={this.handleClearSearchFilter}>{closeButton}</span>
      </div>
    )
  }

  createRootMenu = () => {
    let items = []

    this.props.filterTypes.forEach((section, sectionIndex) => {
      section.forEach((filter, filterIndex) => {
        let dateValues = {}

        if (filter?.filterValueType === FilterTypes.filterValueTypes.DATE) {
          dateValues.primaryValue = Moment().startOf("day")
          dateValues.secondaryValue = Moment().startOf("day")
        }

        if (!filter?.showFilter || filter?.showFilter()) {
          items.push(
            <MenuItem
              classes={{
                root: "menu-item filter-results d-flex" + (filterIndex === 0 && sectionIndex !== 0 ? " separator" : ""),
              }}
              key={filter.filterType + filter.filterName}
              onClick={(event) => {
                this.setState(
                  {
                    filter: Object.assign({}, this.state.filter, filter, {
                      filterOperator: filter.defaultOperator,
                    }),
                    ...dateValues,
                  },
                  () => this.pushMenu(filter.getMenu(filter))
                )
              }}
            >
              {filter.filterName}
            </MenuItem>
          )
        }
      })
    })

    return {
      body: (
        <MenuList>
          <MenuItem classes={{ root: "menu-item search-divider" }}>{this.getSuggestionsHeader()}</MenuItem>
          {items}
        </MenuList>
      ),
    }
  }

  getSuggestionsMenu = () => {
    let items = []
    this.state.suggestions.forEach((suggestion) => {
      items.push(
        <MenuItem
          classes={{ root: "menu-item filter-results d-flex" }}
          key={
            suggestion.filter.filterType +
            (suggestion.filter.filterValueName ? suggestion.filter.filterValueName : suggestion.filter.filterName)
          }
          onClick={(event) =>
            suggestion.filter.handleClick ? suggestion.filter.handleClick() : this.onItemAdded(event, suggestion.filter)
          }
        >
          <span className="filter-result-text">
            {suggestion.filter.filterValueName ? suggestion.filter.filterValueName : suggestion.filter.filterName}
          </span>
          <span className="plus-icon"> + </span>
        </MenuItem>
      )
    })
    return (
      <MenuList>
        <MenuItem classes={{ root: "menu-item search-divider" }}>{this.getSuggestionsHeader()}</MenuItem>
        {items}
      </MenuList>
    )
  }

  getSuggestionsHeader = () => {
    return (
      <Autosuggest
        renderInputComponent={this.renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          autoFocus: true,
          placeholder: "Search",
          value: this.state.search,
          onChange: this.handleChange,
          onKeyDown: this.handleFocusChange,
        }}
      />
    )
  }

  getMenuHeader = (headerValue) => {
    return (
      <div
        className="d-flex filter-header"
        onClick={(event) => {
          if (this.state.menuArr.length > 1) {
            this.goBack(event)
          }
        }}
      >
        {this.state.menuArr.length > 1 ? <KeyboardArrowLeft className="back-btn" /> : null}
        <span className="filter-header-title">{headerValue}</span>
      </div>
    )
  }

  showSingleValueMenu = () => {
    let postfix
    let regexValidator
    let inputProps = {}
    let isAccountId = this.state.filter.filterType === Filters.ACCOUNT_ID.filterType
    const { primaryValue } = this.state

    let onClick = (event) => {
      if (
        isAccountId
          ? primaryValue !== ""
          : regexValidator.test(primaryValue) && primaryValue !== "" && !isNaN(primaryValue)
      ) {
        if (!this.state.filter.filterName.includes(postfix)) {
          this.setState(
            {
              filter: Object.assign({}, this.state.filter, {
                filterValue: [primaryValue],
                isExclude: false,
              }),
            },
            () => this.onItemAdded(null, this.state.filter)
          )
        }
      }
    }

    regexValidator = Consts.WHOLE_NUMBER_REGEX
    if (typeof this.state.filter.filterPlacesAfterTheDot !== "undefined") {
      regexValidator = RegExp(
        Consts.REAL_NUMBER_REGEX_WITH_PLACES.replace("[PLACES]", this.state.filter.filterPlacesAfterTheDot)
      )
    }

    postfix = ""
    switch (this.state.filter.filterOperator) {
      case FilterTypes.filterOperators.EQUAL:
        postfix = " equals"
        break
      case FilterTypes.filterOperators.LESS_THAN:
        postfix = " is less than"
        break
      case FilterTypes.filterOperators.MORE_THAN:
        postfix = " is greater than"
        break
      case FilterTypes.filterOperators.BETWEEN:
        postfix = " is between"
        break
      case FilterTypes.filterOperators.NOT_BETWEEN:
        postfix = " is not between"
        break
      case FilterTypes.filterOperators.NOT_EQUAL:
        postfix = " is not equals"
    }
    let item = (
      <div
        key="value-menu-body"
        className={"unaryFilterWindow" + (primaryValue !== "" ? " open" : "")}
        onClick={(event) => {}}
      >
        <div className="text-field-container">
          <TextField
            id="firstTextField"
            classes={{ root: "text-field" }}
            defaultValue={primaryValue}
            // autoFocus={true}
            placeholder="Enter a number"
            onChange={(event) => this.setNewValue("primaryValue", event)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                onClick()
              }
            }}
            InputProps={{
              className: "small-text",
              ...inputProps,
            }}
            inputProps={{
              tabIndex: "2",
            }}
          />
        </div>
        <div className="button-container">
          <Button
            disabled={
              isAccountId
                ? primaryValue === ""
                : primaryValue === "" || isNaN(primaryValue) || !regexValidator.test(primaryValue)
            }
            className="round-button blue add-button"
            onClick={onClick}
          >
            Add
          </Button>
        </div>
      </div>
    )
    return {
      body: (
        <div>
          <MenuItem classes={{ root: "menu-item search-divider" }}>
            {this.getMenuHeader(this.state.filter.filterName + postfix)}
          </MenuItem>
          {this.getOperatorMenu(false)}
          {item}
        </div>
      ),
    }
  }

  showDoubleValueMenu = () => {
    let inputProps = {}

    let regexValidator = Consts.WHOLE_NUMBER_REGEX
    if (typeof this.state.filter.filterPlacesAfterTheDot !== "undefined") {
      regexValidator = RegExp(
        Consts.REAL_NUMBER_REGEX_WITH_PLACES.replace("[PLACES]", this.state.filter.filterPlacesAfterTheDot)
      )
    }

    let onClick = () => {
      if (
        regexValidator.test(this.state.primaryValue) &&
        regexValidator.test(this.state.secondaryValue) &&
        this.state.primaryValue !== "" &&
        !isNaN(this.state.primaryValue) &&
        this.state.secondaryValue !== "" &&
        !isNaN(this.state.secondaryValue)
      ) {
        let val1 = parseFloat(this.state.primaryValue)
        let val2 = parseFloat(this.state.secondaryValue)
        let compareFrom = Math.min(val1, val2)
        let compareTo = Math.max(val1, val2)

        if (!this.state.filter.filterName.includes(" between ")) {
          this.setState(
            {
              filter: Object.assign({}, this.state.filter, {
                filterValue: [compareFrom, compareTo],
                isExclude: false,
              }),
            },
            () => this.onItemAdded(null, this.state.filter)
          )
        }
      }
    }

    let isDisabled = [this.state.primaryValue, this.state.secondaryValue].some((item) => {
      return isNaN(item) || !regexValidator.test(item)
    })

    let item = (
      <div
        key="value-menu-body"
        className={
          "binaryFilterWindow" + (this.state.primaryValue !== "" && this.state.secondaryValue !== "" ? " open" : "")
        }
        onClick={(event) => {}}
      >
        <div className="text-field-container">
          <TextField
            id="firstTextField"
            classes={{ root: "text-field" }}
            defaultValue={this.state.primaryValue}
            // autoFocus={true}
            placeholder="Enter a number"
            onChange={(event) => this.setNewValue("primaryValue", event)}
            InputProps={{
              className: "small-text",
              ...inputProps,
            }}
            inputProps={{
              tabIndex: "2",
            }}
          />
        </div>
        <span className="to-text">to</span>
        <div className="text-field-container">
          <TextField
            id="secondTextField"
            classes={{ root: "text-field" }}
            defaultValue={this.state.secondaryValue}
            placeholder="Enter a number"
            onChange={(event) => this.setNewValue("secondaryValue", event)}
            InputProps={{
              className: "small-text",
              ...inputProps,
            }}
            inputProps={{
              tabIndex: "3",
            }}
          />
        </div>
        <div className="button-container">
          <Button disabled={isDisabled} className="round-button blue add-button" onClick={onClick}>
            Add
          </Button>
        </div>
      </div>
    )
    return {
      body: (
        <div>
          <MenuItem classes={{ root: "menu-item search-divider" }}>
            {this.getMenuHeader(
              this.state.filter.filterName +
                " is " +
                (this.state.filter.filterOperator === FilterTypes.filterOperators.NOT_BETWEEN ? "not " : "") +
                "between"
            )}
          </MenuItem>
          {this.getOperatorMenu(false)}
          {item}
        </div>
      ),
    }
  }

  getFilterName = () => {
    let suffix = this.state.filter.filterName
    let prefix = ""

    if (this.state.filter.isExclude) {
      prefix = "Exclude"
    } else {
      prefix = "Include"
    }

    return prefix + " " + suffix
  }

  getOperatorMenu = (isIncludeNotExist = true) => {
    let operators = [
      FilterTypes.filterOperators.EQUAL,
      FilterTypes.filterOperators.LESS_THAN,
      FilterTypes.filterOperators.MORE_THAN,
      FilterTypes.filterOperators.BETWEEN,
      FilterTypes.filterOperators.NOT_BETWEEN,
      FilterTypes.filterOperators.NOT_EXIST,
    ]

    if (this.state.filter.filterValueType === FilterTypes.filterValueTypes.ID) {
      operators = [FilterTypes.filterOperators.EQUAL, FilterTypes.filterOperators.NOT_EQUAL]
    } else if (!isIncludeNotExist) {
      operators = operators.slice(0, -1)
    }

    return (
      <OptionsDropdownMenu
        currentValue={this.state.filter.filterOperator}
        options={operators.map((operator) => {
          return {
            name: FilterTypes.filterOperatorsNamesByType[this.state.filter.filterValueType][operator],
            value: operator,
          }
        })}
        selectionCallback={(newIndex) => {
          let primaryValue = this.state.primaryValue
          let secondaryValue = this.state.secondaryValue

          // We can't have a range operator for a single date preset,
          // and we can't have a single date operator for a date range preset,
          // so we're resetting the date values and by that changing the preset to "Custom"

          if (operators[newIndex] === FilterTypes.filterOperators.NOT_EXIST) {
            primaryValue = ""
            secondaryValue = ""
          } else if (this.state.filter.filterOperator === FilterTypes.filterOperators.NOT_EXIST) {
            primaryValue = Moment().startOf("day")
            secondaryValue = Moment().startOf("day")
          } else if (
            Presets.FILTER_PRESETS.includes(this.state.primaryValue) &&
            ((Presets.SINGLE_DATE_PRESETS.includes(this.state.primaryValue) &&
              FilterTypes.RANGE_FILTER_OPERATORS.includes(operators[newIndex])) ||
              (!Presets.SINGLE_DATE_PRESETS.includes(this.state.primaryValue) &&
                !FilterTypes.RANGE_FILTER_OPERATORS.includes(operators[newIndex])))
          ) {
            primaryValue = Moment().startOf("day")
            secondaryValue = Moment().startOf("day")
          }
          this.setState((state) => ({
            filter: Object.assign({}, state.filter, {
              filterOperator: operators[newIndex],
            }),
            primaryValue,
            secondaryValue,
          }))
        }}
      />
    )
  }

  showNumberValueMenu = () => {
    let menu

    if (FilterTypes.RANGE_FILTER_OPERATORS.includes(this.state.filter.filterOperator)) {
      menu = this.showDoubleValueMenu()
    } else {
      menu = this.showSingleValueMenu()
    }

    return menu
  }

  showDateValueMenu = () => {
    let postfix = ""

    switch (this.state.filter.filterOperator) {
      case FilterTypes.filterOperators.EQUAL:
        postfix = " exact date "
        break
      case FilterTypes.filterOperators.LESS_THAN:
        postfix = " is before "
        break
      case FilterTypes.filterOperators.MORE_THAN:
        postfix = " is after "
        break
      case FilterTypes.filterOperators.BETWEEN:
        postfix = " is between "
        break
      case FilterTypes.filterOperators.NOT_BETWEEN:
        postfix = " is not between "
        break
      case FilterTypes.filterOperators.NOT_EXIST:
        postfix = " is none "
        break
    }

    let onClick = () => {
      if (
        FilterTypes.filterOperators.NOT_EXIST ||
        Presets.FILTER_PRESETS.includes(this.state.primaryValue) ||
        (this.state.primaryValue !== "" &&
          this.state.primaryValue.isValid() &&
          this.state.secondaryValue !== "" &&
          this.state.secondaryValue.isValid())
      ) {
        this.setState(
          {
            filter: Object.assign({}, this.state.filter, {
              filterValue: [this.state.primaryValue, this.state.secondaryValue],
              isExclude: false,
            }),
          },
          () => this.onItemAdded(null, this.state.filter)
        )
      }
    }

    let isDisabled =
      !FilterTypes.filterOperators.NOT_EXIST &&
      !Presets.FILTER_PRESETS.includes(this.state.primaryValue) &&
      [this.state.primaryValue, this.state.secondaryValue].some((item) => {
        return item === "" || !item.isValid()
      })

    return {
      body: (
        <div>
          <MenuItem classes={{ root: "menu-item search-divider" }}>
            {this.getMenuHeader(this.state.filter.filterName + postfix)}
          </MenuItem>
          {this.getOperatorMenu()}
          <FilterDatePicker
            filter={this.state.filter}
            startDate={this.state.primaryValue}
            endDate={this.state.secondaryValue}
            isSingleValue={!FilterTypes.RANGE_FILTER_OPERATORS.includes(this.state.filter.filterOperator)}
            onDateChange={this.onDateChange}
            onPresetSelected={this.onPresetSelected}
            updatePositionFunction={this.updatePositionFunction}
          />
          <div className="button-container">
            <Button
              tabIndex="2"
              key="date-add-filter"
              disabled={isDisabled}
              className="date-form-button round-button blue add-button"
              onClick={onClick}
            >
              Add
            </Button>
          </div>
        </div>
      ),
    }
  }

  onDateChange = (startDate, endDate) => {
    this.setState({
      primaryValue: startDate,
      secondaryValue: endDate,
    })
  }

  onPresetSelected = (preset) => {
    let filter = null

    // We can't have a range operator for a single date,
    // and we can't have a single date operator for a date range,
    // so we're resetting the operator in these cases to maintain the logic
    if (
      Presets.SINGLE_DATE_PRESETS.includes(preset) &&
      FilterTypes.RANGE_FILTER_OPERATORS.includes(this.state.filter.filterOperator)
    ) {
      filter = { filterOperator: FilterTypes.filterOperators.EQUAL }
    } else if (
      !Presets.SINGLE_DATE_PRESETS.includes(preset) &&
      !FilterTypes.RANGE_FILTER_OPERATORS.includes(this.state.filter.filterOperator)
    ) {
      filter = { filterOperator: FilterTypes.filterOperators.BETWEEN }
    }

    this.setState({
      primaryValue: preset,
      secondaryValue: "",
      filter: Object.assign({}, this.state.filter, filter),
    })
  }

  getIncludeExcludeMenu = () => {
    keyboardShortcutsManager.addShortcut(
      FILTER_OPERATORS_LEFT_SHORTCUT_KEY,
      ["shift", "left"],
      () => this.handleIncludeExcludeShortcutUsed("left"),
      false
    )
    keyboardShortcutsManager.addShortcut(
      FILTER_OPERATORS_RIGHT_SHORTCUT_KEY,
      ["shift", "right"],
      () => this.handleIncludeExcludeShortcutUsed("right"),
      false
    )

    return (
      <div key="operation-selection" className="operators-container">
        <Button
          key="include"
          className={!this.state.filter.isExclude ? "round-button operator selected" : "round-button operator"}
          onClick={() => {
            this.setState({
              filter: Object.assign({}, this.state.filter, {
                isExclude: false,
              }),
            })
          }}
        >
          Include
        </Button>
        <Button
          key="exclude"
          className={this.state.filter.isExclude ? "round-button operator selected" : "round-button operator"}
          onClick={() => {
            this.setState({
              filter: Object.assign({}, this.state.filter, {
                isExclude: true,
              }),
            })
          }}
        >
          Exclude
        </Button>
      </div>
    )
  }

  handleIncludeExcludeShortcutUsed = (direction) => {
    let isExclude = null

    if (direction === "left") {
      isExclude = false
    } else {
      isExclude = true
    }

    this.setState({
      filter: Object.assign({}, this.state.filter, {
        isExclude,
      }),
    })
  }

  pushMenu(menuFunction) {
    let menu = this.state.menuArr.slice(0)

    menu.push(menuFunction)
    this.setState({ menuArr: menu }, () => {
      let textField = document.querySelector("#firstTextField")
      if (textField) {
        textField.focus()
      } else {
        document.querySelector(".search-divider").focus()
      }
    })
  }

  editChip(event, chipFilter) {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      menuArr: [
        chipFilter.filterValueType === FilterTypes.filterValueTypes.DATE
          ? this.showDateValueMenu
          : this.showNumberValueMenu,
      ],
      primaryValue: chipFilter.filterValue[0],
      secondaryValue: chipFilter.filterValue.length > 0 ? chipFilter.filterValue[1] : "",
      filter: Object.assign({}, this.state.filter, chipFilter),
      isEditing: true,
    })
  }

  render() {
    let chips = []
    let menu = this.getMenu()
    let conditionClasses = "condition-chip"

    this.props.filters.forEach((value, key) => {
      value.excludeFilters.forEach((filter) => {
        chips.push(
          <EditableChip
            key={filter.filterName + "-" + filter.filterValue}
            filter={filter}
            onDelete={(event) => {
              this.onItemRemoved(event, filter)
            }}
            onFilterValueChanged={(event, newFilter) => {
              this.updateFilter(event, newFilter)
            }}
            onFilterChanged={(event, chipFilter) => {
              this.editChip(event, chipFilter)
            }}
            isLoading={this.props.isLoading}
            useCondition={true}
            classes={conditionClasses}
          />
        )
      })

      value.includeFilters.forEach((filter) => {
        chips.push(
          <EditableChip
            key={filter.filterName + "-" + filter.filterValue}
            filter={filter}
            onDelete={(event) => {
              this.onItemRemoved(event, filter)
            }}
            onFilterValueChanged={(event, newFilter) => {
              this.updateFilter(event, newFilter)
            }}
            onFilterChanged={(event, chipFilter) => {
              this.editChip(event, chipFilter)
            }}
            isLoading={this.props.isLoading}
            useCondition={true}
            classes={conditionClasses}
          />
        )
      })
    })

    return (
      <div className={"filters" + this.props.rootClassName}>
        {this.props.filterTypes && this.props.filterTypes.length > 0 ? (
          <div
            className={
              "text-field generic-dropdown-selection fit-content clickable creation-generic-dropdown-button " +
              "condition-creation-field " +
              (this.props.conditionsValidationErrors ? "error " : "")
            }
            onClick={this.handleClickButton}
            ref={(node) => {
              this.button = node
            }}
          >
            <div>Filters</div>
            <KeyboardArrowDown className="arrow" />
          </div>
        ) : null}
        {this.props.conditionsValidationErrors}
        <Popover
          classes={{ paper: "default-menu filter-window" }}
          anchorEl={this.state.anchorEl}
          getContentAnchorEl={null}
          action={(actions) => {
            this.updatePositionFunction = actions.updatePosition
          }}
          open={this.state.open}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={this.handleMenuRequestClose}
          onEntered={() => {
            let textField = document.querySelector("#filter-text-field")
            if (textField) {
              textField.focus()
            }
          }}
        >
          <div>{this.state.search !== "" ? this.getSuggestionsMenu() : menu.body}</div>
        </Popover>
        <div className="tags-chips-container chips-container flex-wrap d-flex">
          {chips}
          {chips.length > 0 && this.props.isClearAvailable ? (
            <>
              <div className="clear-filters clickable d-flex align-items-center" onClick={this.clearAllFilters}>
                Clear filters
              </div>
            </>
          ) : null}
        </div>
      </div>
    )
  }
}

Condition.defaultProps = {
  isSaveFilterAvailable: false,
  rootClassName: "",
  isClearAvailable: true,
}

function mapStateToProps(state, ownProps) {
  return {
    currentNetwork: state.navigationBar.currentNetwork,
  }
}

export default connect(mapStateToProps)(Condition)
