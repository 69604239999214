const draftReviewStatus = {
  approved: {
    name: "Approved",
    color: "green",
    value: "APPROVED",
  },
  rejected: {
    name: "Rejected",
    color: "red",
    value: "REJECTED",
  },
  in_review: {
    name: "In Review",
    color: "dark-gray",
    value: "IN_REVIEW",
  },
  partially_approved: {
    name: "Partially Approved",
    color: "orange",
    value: "PARTIALLY_APPROVED",
  },
  auto_approved: {
    name: "--",
    value: "AUTO_APPROVED",
  },
  skipped: {
    name: "--",
    value: "SKIPPED",
  },
}

const draftReviewStatusByValue = {}
for (const key in draftReviewStatus) {
  draftReviewStatusByValue[draftReviewStatus[key].value] = draftReviewStatus[key]
}

export { draftReviewStatus, draftReviewStatusByValue }
