import SettingsService from "../api/settingsService"
import ProviderAccountService from "../api/providerAccountService"
import SettingsPageActionTypes from "./settingsPageActionTypes"
import CampaignCreationService from "../api/campaignCreationService"
import { notificationAdd } from "../common/actions/commonActions"

export function settingsPageLoading() {
  return { type: SettingsPageActionTypes.SETTINGS_PAGE_LOADING }
}

export function fetchAccountSettingsResponse(campaignSettings) {
  return { type: SettingsPageActionTypes.FETCH_ACCOUNT_SETTINGS_RESPONSE, campaignSettings }
}

export function fetchAccountSettingsAsync(networkId) {
  return (dispatch) => {
    dispatch(settingsPageLoading())
    SettingsService.getCampaignSettings(networkId).then((result) => {
      dispatch(fetchAccountSettingsResponse(result.data))
    })
  }
}

export function networkTargetingGroupPresetsLoading() {
  return { type: SettingsPageActionTypes.NETWORK_TARGETING_GROUP_PRESETS_LOADING }
}

export function fetchNetworkTargetingGroupPresetsResponse(networkTargetingGroupPresets) {
  return { type: SettingsPageActionTypes.FETCH_NETWORK_TARGETING_GROUP_PRESETS_RESPONSE, networkTargetingGroupPresets }
}

export function fetchNetworkTargetingGroupPresetsAsync(networkId) {
  return (dispatch) => {
    dispatch(networkTargetingGroupPresetsLoading())
    return CampaignCreationService.getNetworkTargetingGroupPresets(networkId).then((result) => {
      return dispatch(fetchNetworkTargetingGroupPresetsResponse(result.data.network_targeting_group_presets))
    })
  }
}

export function deleteNetworkTargetingGroupPresetResponse(networkId, groupId) {
  return { type: SettingsPageActionTypes.DELETE_NETWORK_TARGETING_GROUP_PRESET_RESPONSE, networkId, groupId }
}

export function deleteNetworkTargetingGroupPresetAsync(networkId, groupId) {
  return (dispatch) => {
    return CampaignCreationService.deleteNetworkTargetingGroupPreset(networkId, groupId).then(() => {
      return dispatch(deleteNetworkTargetingGroupPresetResponse(networkId, groupId))
    })
  }
}

export function addNetworkTargetingGroupPresetResponse(networkTargetingGroupPreset) {
  return { type: SettingsPageActionTypes.ADD_NETWORK_TARGETING_GROUP_PRESET_RESPONSE, networkTargetingGroupPreset }
}

export function updateNetworkTargetingGroupPresetResponse(groupId, networkTargetingGroupPreset) {
  return {
    type: SettingsPageActionTypes.UPDATE_NETWORK_TARGETING_GROUP_PRESET_RESPONSE,
    groupId,
    networkTargetingGroupPreset,
  }
}

export function addNetworkTargetingGroupPresetAsync(networkId, providerId, targetingJson) {
  return (dispatch) => {
    return CampaignCreationService.addNetworkTargetingGroupPreset(networkId, providerId, targetingJson).then(
      (result) => {
        return dispatch(addNetworkTargetingGroupPresetResponse(result.data.network_targeting_group_preset))
      }
    )
  }
}

export function updateNetworkTargetingGroupPreseAsync(groupId, networkId, providerId, targetingJson) {
  return (dispatch) => {
    return CampaignCreationService.updateNetworkTargetingGroupPreset(
      groupId,
      networkId,
      providerId,
      targetingJson
    ).then((result) => {
      return dispatch(updateNetworkTargetingGroupPresetResponse(groupId, result.data.network_targeting_group_preset))
    })
  }
}

export function updateAccountSettings(campaignSettings) {
  return { type: SettingsPageActionTypes.UPDATE_ACCOUNT_SETTINGS, campaignSettings }
}

export function saveAccountSettingsAsync(networkId, settings) {
  return (dispatch) => {
    return SettingsService.saveCampaignSettings(networkId, settings)
  }
}

export function accountSettingsSelectNetwork(selectedNetwork) {
  return { type: SettingsPageActionTypes.ACCOUNT_SETTINGS_SELECT_NETWORK, selectedNetwork }
}

export function resetNetworkProviderAccounts() {
  return { type: SettingsPageActionTypes.ACCOUNT_SETTINGS_RESET_NETWORK_PROVIDER_ACCOUNTS }
}

export function networkProviderAccountsIsLoading(isLoading) {
  return { type: SettingsPageActionTypes.ACCOUNT_SETTINGS_LOADING_NETWORK_PROVIDER_ACCOUNTS, isLoading }
}

export function updateNetworkProviderAccounts(providerAccounts) {
  return { type: SettingsPageActionTypes.ACCOUNT_SETTINGS_UPDATE_NETWORK_PROVIDER_ACCOUNTS, providerAccounts }
}

export function fetchNetworkProviderAccounts(networkId) {
  return (dispatch) => {
    dispatch(resetNetworkProviderAccounts())
    dispatch(networkProviderAccountsIsLoading(true))
    ProviderAccountService.getNetworkProviderAccounts(networkId).then((response) => {
      dispatch(updateNetworkProviderAccounts(response.data.provider_accounts))
    })
  }
}

export function addProviderPageAsync(siteId, providerId, pageId, pageName, pageLanguage) {
  return (dispatch) => {
    return SettingsService.addProviderPage(siteId, providerId, pageId, pageName, pageLanguage).then((response) => {
      return dispatch(addProviderPageResponse(siteId, providerId, pageId, pageName, pageLanguage))
    })
  }
}

export function addProviderPageResponse(siteId, providerId, pageId, pageName, pageLanguage) {
  return { type: SettingsPageActionTypes.ADD_PROVIDER_PAGE, siteId, pageId, pageName, pageLanguage }
}

export function deleteProviderPageAsync(siteId, providerId, pageId) {
  return (dispatch) => {
    return SettingsService.deleteProviderPage(siteId, providerId, pageId).then((response) => {
      return dispatch(deleteProviderPageResponse(siteId, providerId, pageId))
    })
  }
}

export function deleteProviderPageResponse(siteId, providerId, pageId) {
  return { type: SettingsPageActionTypes.DELETE_PROVIDER_PAGE, siteId, providerId, pageId }
}

export function addProviderSubAccountsAsync(providerId, networkId, siteIds, subAccountIds) {
  return (dispatch) => {
    return SettingsService.addProviderSubAccounts(providerId, networkId, siteIds, subAccountIds).then((response) => {
      return dispatch(addProviderSubAccountsResponse(providerId, networkId, siteIds, subAccountIds))
    })
  }
}

export function addProviderSubAccountsResponse(providerId, networkId, siteIds, subAccountIds) {
  return { type: SettingsPageActionTypes.ADD_PROVIDER_SUB_ACCOUNTS, providerId, networkId, siteIds, subAccountIds }
}

export function updateAmazonItems() {
  return (dispatch) => {
    return SettingsService.updateAmazonItems()
      .then((response) => {
        // update request to return response
      })
      .catch((error) => {
        if (error) {
          dispatch(notificationAdd("There was an error updating the items"))
        }
      })
  }
}

export function updateAmazonTableFromCsv(urls) {
  return (dispatch) => {
    return SettingsService.updateAmazonTableFromCsv(urls)
      .then((response) => {
        // update request to return response
      })
      .catch((error) => {
        if (error) {
          dispatch(notificationAdd("There was an error upload the file"))
        }
      })
  }
}
