import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../common/components/genericDropdown"

class NetworkDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: props.campaignSettings.possibleSitesForDomain.map((site) => {
        return {
          site,
          name: site.network.name,
        }
      }),
      selectedIndexesArr: [],
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
      menuItems: this.props.campaignSettings.possibleSitesForDomain.map((site) => {
        return {
          site,
          name: site.network.name,
        }
      }),
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (index) => {
    this.setState({
      selectedIndexesArr: index,
    })

    let selectedSiteWithNetwork = this.state.menuItems[index].site
    this.props.handleNetworkSelection({
      networkCode: selectedSiteWithNetwork.network.code,
      networkId: selectedSiteWithNetwork.network_id,
      siteId: selectedSiteWithNetwork.id,
      siteDomain: selectedSiteWithNetwork.domain_name,
    })

    this.handleMenuClose()
  }

  render() {
    let buttonClassName = "creation-generic-dropdown-button "
    let header = "Choose network"

    if (this.props.campaignSettings.networkId) {
      header = this.props.campaignSettings.possibleSitesForDomain.filter(
        (site) => site.network_id === this.props.campaignSettings.networkId
      )[0].network.name
    }

    return (
      <div className={"text-field network-selection " + (this.props.networkValidationErrors ? "error " : "")}>
        <div className="creation-generic-dropdown-label">Network</div>
        <GenericDropdown
          header={header}
          selectOptionCallback={(index) => {
            this.handleMenuClick(index)
          }}
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowAutoSuggest={false}
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection={false}
          buttonClassName={buttonClassName}
          menuClassName={"creation-generic-dropdown-menu"}
        />
        {this.props.networkValidationErrors}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaignSettings: state.campaignCreationWizard.campaignSettings,
  }
}

export default connect(mapStateToProps)(NetworkDropdown)
