import React from "react"
import { default as OriginalNumberFormat } from "react-number-format"

export default class NumberFormat extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      return true
    }

    return false
  }

  render() {
    let value = this.props.value ? this.props.value : 0

    return <OriginalNumberFormat {...this.props} value={value} />
  }
}
