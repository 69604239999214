import React from "react"
import SadRobotImage from "../../resources/images/SadRobot.png"

export default class PopupReportNoItems extends React.Component {
  constructor() {
    super()
  }
  render() {
    return (
      <div className="no-results-container no-results-container-sad-robot-background d-flex">
        <img src={SadRobotImage} className="no-campaigns-img" />
        <div className="no-campaigns-title">No results found</div>
        <div className="no-campaigns-body">Please try a different date</div>
      </div>
    )
  }
}
