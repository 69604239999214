import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../../common/components/genericDropdown"
import { updateTargetingGroup } from "../../../campaignCreationActions"

class ConversionEventsDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: this.props.conversionEvents.map((conversionEvent) => ({
        name: conversionEvent.conversion_name,
        value: conversionEvent.conversion_value,
      })),
      selectedIndexesArr: [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.conversionEvents !== prevProps.conversionEvents) {
      this.setState({
        menuItems: this.props.conversionEvents.map((conversionEvent) => ({
          name: conversionEvent.conversion_name,
          value: conversionEvent.conversion_value,
        })),
        selectedIndexesArr: [],
      })
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (selectedIndexesArr) => {
    let selectedConversionEvents = []

    this.state.menuItems.forEach((conversionEvent, index) => {
      if (selectedIndexesArr.includes(index)) {
        selectedConversionEvents.push(conversionEvent)
      }
    })

    let newSourceData = Object.assign({}, this.props.campaignTargetingGroup, {
      selectedConversionEvents,
    })

    if (this.props.onChange) {
      this.props.onChange(newSourceData)
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, newSourceData))
    }

    this.setState({
      selectedIndexesArr: selectedIndexesArr,
    })

    this.handleMenuClose()
  }

  render() {
    let header = "Choose Event"
    let buttonClassName = "creation-generic-dropdown-button "
    let selectedConversionEvents = this.props.campaignTargetingGroup.selectedConversionEvents

    if (selectedConversionEvents.length > 0) {
      buttonClassName += "selected"

      if (selectedConversionEvents.length === 1) {
        header = selectedConversionEvents[0].name
      } else {
        header = selectedConversionEvents.length + " conversion events selected"
      }
    }

    return (
      <div
        className={
          "text-field conversion-event-selection " + (this.props.conversionEventsValidationErrors ? "error " : "")
        }
      >
        <div className="creation-generic-dropdown-label">Conversion Event</div>
        <GenericDropdown
          header={header}
          selectOptionCallback={(index) => {
            this.handleMenuClick(index)
          }}
          allowSelectAll={false}
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowMultiple={false}
          allowAutoSuggest={true}
          showEmptyOption={false}
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection={false}
          buttonClassName={buttonClassName}
          menuClassName="creation-generic-dropdown-menu"
        />
        {this.props.conversionEventsValidationErrors}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(ConversionEventsDropdown)
