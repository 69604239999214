import ArticlesActionTypes from "./articlesActionTypes"
import Moment from "moment"
import WebStorageConsts from "../common/consts/webStorageConsts"
import { webStorage } from "../api/webStorage"

function getDefaultItemsPerPage() {
  let itemsPerPage = 50

  if (webStorage.get(WebStorageConsts.STORAGE_KEYS.ARTICLES_ITEMS_PER_PAGE)) {
    itemsPerPage = parseInt(webStorage.get(WebStorageConsts.STORAGE_KEYS.ARTICLES_ITEMS_PER_PAGE))
  }

  return itemsPerPage
}

let addArticlesProps = (articles) => {
  return articles
    ? Object.keys(articles).map((id) => {
        var article = Object.assign({}, articles[id])
        article.key = id
        return article
      })
    : null
}

const articlesState = {
  allArticles: [], // Visible Articles according to sorting, searching & filtering
  totalArticles: 0,
  itemsPerPage: getDefaultItemsPerPage(),
  currentPage: 1,
  lastUpdate: null,
  shouldUpdatePage: false,
  filters: new Map(),
  languages: [],
  categories: [],
  isRsoc: false,
}

function articlesReducer(state = articlesState, action) {
  if (state == undefined || action == undefined) {
    return []
  }
  switch (action.type) {
    case ArticlesActionTypes.FETCH_ARTICLES_RESPONSE:
      return Object.assign({}, state, {
        allArticles: addArticlesProps(action.articles),
        hasArticles: action.hasArticles,
        totalArticles: action.totalArticles,
        lastUpdate: Moment().unix(),
        isLoading: false,
        isRsoc: action.isRsoc,
      })

    case ArticlesActionTypes.FETCH_ARTICLES_FILTERS_RESPONS:
      return Object.assign({}, state, {
        languages: action.languages,
        categories: action.categories,
      })

    case ArticlesActionTypes.ARTICLES_LIST_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      })

    case ArticlesActionTypes.UPDATE_FILTER_ARTICLES:
      return Object.assign({}, state, {
        filters: action.filters,
      })

    case ArticlesActionTypes.ROLLBACK_ARTICLES_PAGE:
      return Object.assign({}, state, {
        isLoading: false,
        hasArticles: true,
        allArticles: [],
        totalArticles: 0,
        filters: new Map(),
        isRsoc: false,
      })

    case ArticlesActionTypes.UPDATE_ARTICLES_PAGE:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
        shouldUpdatePage: true,
      })

    case ArticlesActionTypes.RESET_ARTICLES_PAGINATION:
      return Object.assign({}, state, {
        currentPage: 1,
        shouldUpdatePage: true,
      })

    case ArticlesActionTypes.UPDATE_ITEMS_PER_PAGE:
      return Object.assign({}, state, {
        itemsPerPage: action.itemsPerPage,
      })
    case ArticlesActionTypes.SHOULD_UPDATE_ARTICLES_PAGE:
      return Object.assign({}, state, {
        shouldUpdatePage: action.shouldUpdate,
      })
    default:
      return state
  }
}
export { articlesReducer }
