import OsTypes from "../common/consts/osTypes"
import AdTypes from "../common/consts/adTypes"
import Browsers from "../common/consts/browsers"
import CampaignsCreationConsts from "./campaignCreationConsts"
import BidTypes from "../common/consts/bidTypes"

let CampaignsCreationSourcesInitialState = {
  CAMPAIGN_CREATION_SOURCES: {
    Taboola: {
      id: 1,
      selected: false,
      providerAccount: null,
      campaignTypes: BidTypes.getBidTypesByProvider(1),
      adTypes: [{ ...AdTypes.regular_ad, selected: true }],
      imageSizeValidationMB: 2.5,
      selectedCustomAudiences: null,
      bulkPlatforms: {
        Desktop: [
          OsTypes.sub_platforms.all.name,
          OsTypes.sub_platforms.linux_and_windows.name,
          OsTypes.sub_platforms.linux.name,
          OsTypes.sub_platforms.windows.name,
          OsTypes.sub_platforms.macos.name,
        ],
        Mobile: [OsTypes.sub_platforms.both.name, OsTypes.sub_platforms.ios.name, OsTypes.sub_platforms.android.name],
        Tablet: [OsTypes.sub_platforms.both.name, OsTypes.sub_platforms.ios.name, OsTypes.sub_platforms.android.name],
      },
      platforms: {
        Desktop: {
          selected: false,
          values: {
            [OsTypes.sub_platforms.macos.name]: false,
            [OsTypes.sub_platforms.windows.name]: false,
            [OsTypes.sub_platforms.linux.name]: false,
          },
          displayedName: "Desktop",
        },
        Mobile: {
          selected: false,
          values: {
            [OsTypes.sub_platforms.ios.name]: false,
            [OsTypes.sub_platforms.android.name]: false,
          },
          displayedName: "Mobile",
        },
        Tablet: {
          selected: false,
          values: {
            [OsTypes.sub_platforms.ios.name]: false,
            [OsTypes.sub_platforms.android.name]: false,
          },
          displayedName: "Tablet",
        },
      },
      browsers: Object.values(Browsers.taboola),
    },
    Facebook: {
      id: 9,
      selected: false,
      providerAccount: null,
      campaignTypes: BidTypes.getBidTypesByProvider(9),
      adTypes: [{ ...AdTypes.regular_ad }, { ...AdTypes.dynamic_ad }],
      cropDetails: CampaignsCreationConsts.CROP_RATIOS["Facebook"],
      selectedPlacementsIndexesArr: [],
      imageSizeValidationMB: 30,
      selectedPlacements: null,
      selectedAdTypesIndexesArr: [],
      selectedPage: null,
      isCreativesGroup: true,
      primaryTextsAllowed: true,
      bulkPlatforms: {
        Desktop: [],
        Mobile: [OsTypes.sub_platforms.both.name, OsTypes.sub_platforms.ios.name, OsTypes.sub_platforms.android.name],
      },
      platforms: {
        Desktop: { selected: false, values: {}, displayedName: "Desktop" },
        Mobile: {
          selected: false,
          values: {
            [OsTypes.sub_platforms.ios.name]: false,
            [OsTypes.sub_platforms.android.name]: false,
          },
          displayedName: "Mobile",
        },
      },
    },
    GoogleAds: {
      id: 91,
      selected: false,
      providerAccount: null,
      adTypes: [{ ...AdTypes.display_ad }, { ...AdTypes.discovery_ad }],
      campaignTypes: BidTypes.getBidTypesByProvider(91),
      isDynamicCreative: true,
      cropDetails: CampaignsCreationConsts.CROP_RATIOS["GoogleAds"],
      imageSizeValidationMB: 5, // Max file size 5120 KB
      selectedAdTypesIndexesArr: [],
      primaryTextsAllowed: false,
      platforms: {
        Desktop: { selected: false, values: {}, displayedName: "Desktop" },
        Mobile: {
          selected: false,
          values: {},
          displayedName: "Mobile",
        },
        Tablet: { selected: false, values: {}, displayedName: "Tablet" },
      },
    },
    Pinterest: {
      id: 102,
      selected: false,
      providerAccount: null,
      isCreativesGroup: true,
      campaignTypes: BidTypes.getBidTypesByProvider(102),
      cropDetails: CampaignsCreationConsts.CROP_RATIOS["Pinterest"],
      adTypes: [{ ...AdTypes.regular_ad, selected: true }],
      imageSizeValidationMB: 20,
      platforms: {
        Mobile: {
          selected: false,
          values: {
            [OsTypes.sub_platforms.android_mobile.name]: false,
            [OsTypes.sub_platforms.iphone.name]: false,
            [OsTypes.sub_platforms.web_mobile.name]: false,
          },
          displayedName: "Mobile",
        },
        Tablet: {
          selected: false,
          values: {
            [OsTypes.sub_platforms.iPad.name]: false,
            [OsTypes.sub_platforms.android_tablet.name]: false,
          },
          displayedName: "Tablet",
        },
        Web: {
          selected: false,
          values: {
            [OsTypes.sub_platforms.web.name]: false,
          },
          displayedName: "Web",
        },
      },
      bulkPlatforms: {
        Mobile: [
          OsTypes.sub_platforms.android_mobile.name,
          OsTypes.sub_platforms.iphone.name,
          OsTypes.sub_platforms.web_mobile.name,
        ],
        Tablet: [OsTypes.sub_platforms.iPad.name, OsTypes.sub_platforms.android_tablet.name],
        Web: [OsTypes.sub_platforms.web.name],
      },
    },
  },
}

Object.freeze(CampaignsCreationSourcesInitialState)
export default CampaignsCreationSourcesInitialState
