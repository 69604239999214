import axios from "axios"
import Consts from "../app/consts"

export default class AppService {
  static getClientVersion() {
    let options = {
      headers: {
        "Cache-Control": "no-cache",
      },
    }

    // Making a request to see if the current version that the user has is the actual version on the s3 bucket
    return axios.get(Consts.BASE_URL + "/version.json", options)
  }
}
