import UnintegratedCampaignsActionTypes from "./unintegratedCampaignsActionTypes"
import { getEndDate, getStartDate, TODAY } from "../common/components/datePickerV2"
import Moment from "moment"
import WebStorageConsts from "../common/consts/webStorageConsts"
import { webStorage } from "../api/webStorage"

function getDefaultItemsPerPage() {
  let itemsPerPage = 50

  if (webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_ITEMS_PER_PAGE)) {
    itemsPerPage = parseInt(webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_ITEMS_PER_PAGE))
  }

  return itemsPerPage
}

const unintegratedCampaignsState = {
  datePickerDates: {
    startDate: getStartDate(TODAY),
    endDate: getEndDate(TODAY),
    showLastHour: true,
    isGeneratedReport: false,
    dateType: TODAY,
  },
  isToday: false,
  visibleCampaigns: [], // Visible campaigns according to sorting, searching & filtering
  itemsPerPage: getDefaultItemsPerPage(),
  currentPage: 1,
  lastUpdate: null,
  shouldUpdatePage: false,
}

let addCampaignsProps = (campaigns) => {
  return campaigns
    ? Object.keys(campaigns).map((key) => {
        // For each campaign, we add client entities (key and isLoading flag)
        var campaign = Object.assign({}, campaigns[key])
        campaign.key = key
        return campaign
      })
    : null
}

function unintegratedReducer(state = unintegratedCampaignsState, action) {
  if (state == undefined || action == undefined) {
    return []
  }
  switch (action.type) {
    case UnintegratedCampaignsActionTypes.RESET_UNINTEGRATED_CAMPAIGNS_DATES:
      return Object.assign({}, state, {
        datePickerDates: unintegratedCampaignsState.datePickerDates,
      })

    case UnintegratedCampaignsActionTypes.CAMPAIGNS_DATEPICKER_DATES_SELECTED:
      let today = Moment()
      let isToday = false

      if (today.isSame(action.startDate, "day")) {
        isToday = true
      }

      return Object.assign({}, state, {
        datePickerDates: {
          startDate: action.startDate,
          endDate: action.endDate,
          isGeneratedReport: false,
        },
        isToday,
      })

    case UnintegratedCampaignsActionTypes.FETCH_UNINTEGRATED_CAMPAIGNS_RESPONSE:
      return Object.assign({}, state, {
        allCampaigns: addCampaignsProps(action.campaigns),
        summaryRow: action.summaryRow,
        hasCampaigns: action.hasCampaigns,
        currentPage: 1,
        lastUpdate: Moment().unix(),
        visibleCampaigns: addCampaignsProps(action.campaigns),
        isLoading: false,
      })

    case UnintegratedCampaignsActionTypes.CAMPAIGNS_LIST_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      })

    case UnintegratedCampaignsActionTypes.ROLLBACK_CAMPAIGNS_PAGE:
      return Object.assign({}, state, {
        isLoading: false,
        hasCampaigns: true,
        allCampaigns: [],
        visibleCampaigns: [],
        isNextResponseUserGeneratedReport: false,
      })

    case UnintegratedCampaignsActionTypes.UPDATE_CAMPAIGNS_PAGE:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
      })

    case UnintegratedCampaignsActionTypes.RESET_CAMPAIGNS_PAGINATION:
      return Object.assign({}, state, {
        currentPage: 1,
      })

    case UnintegratedCampaignsActionTypes.UPDATE_ITEMS_PER_PAGE:
      return Object.assign({}, state, {
        itemsPerPage: action.itemsPerPage,
      })
    case UnintegratedCampaignsActionTypes.SHOULD_UPDATE_CAMPAIGNS_PAGE:
      return Object.assign({}, state, {
        shouldUpdatePage: action.shouldUpdate,
      })

    default:
      return state
  }
}
export { unintegratedReducer }
