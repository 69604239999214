let SubSourcesConsts = {
  SUB_SOURCE_STATUS_RUNNING: "ACTIVE",
  SUB_SOURCE_STATUS_PAUSED: "PAUSED",

  SUB_SOURCE_STATUS_NAMES: {
    ACTIVE: "Active",
    PAUSED: "Paused",
  },
}

Object.freeze(SubSourcesConsts)
export default SubSourcesConsts
