import React from "react"
import ReactDOM from "react-dom"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import JssProvider from "react-jss/lib/JssProvider"
import { create } from "jss"
import preset from "jss-preset-default"
import createGenerateClassName from "@material-ui/core/styles/createGenerateClassName"
import RouterComponent from "./router.js"
import { GoogleOAuthProvider } from "@react-oauth/google"
import Consts from "./consts"

const jss = create(preset())
// Custom Material-UI class name generator for better debug and performance.
jss.options.createGenerateClassName = createGenerateClassName
// We define a custom insertion point JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = "insertion-point-jss"

const theme = createMuiTheme({
  props: {
    MuiModal: {
      disableRestoreFocus: true,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Open Sans, sans-serif",
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "2px solid transparent",
        },
        "&:after": {
          borderBottom: "2px solid #efefef",
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "2px solid inherit",
        },
        "&:hover:not($disabled):not($focused):not($error):after": {
          borderBottom: "2px solid transparent",
        },
      },
    },
    MuiBackdrop: {
      root: {
        touchAction: "initial",
      },
    },
  },
})

export function renderAppTree() {
  // render the main component
  // JssProvider: Prevents material-ui classes collision, once material-ui comes out of beta we'll need to see if everything works well without it
  // MuiThemeProvider: Overriding material-ui default theme with a custom one (check theme function above)
  // AppContainer: Everything under this container will be react-hot-loaded
  // Provider: Redux provider
  // router: The actual app is there, once we'll upgrade to react-router v4 the structure might change
  ReactDOM.render(
    <GoogleOAuthProvider clientId={Consts.GOOGLE_AUTH_CLIENT_ID}>
      <JssProvider jss={jss}>
        <MuiThemeProvider theme={theme}>
          <RouterComponent />
        </MuiThemeProvider>
      </JssProvider>
    </GoogleOAuthProvider>,
    document.getElementById("app")
  )
}

renderAppTree()

if (module.hot) {
  module.hot.accept()

  // TODO: temporarily removing the console error (which is actually a warning) about the routes until we move to router v4. Yeah.. I am not proud of this.
  const orgError = console.error
  console.error = (...args) => {
    if (
      args &&
      args.length === 1 &&
      typeof args[0] == "string" &&
      args[0].indexOf("You cannot change <Router routes>;") > -1
    ) {
      // React route changed
    } else {
      // Log the error as normally
      orgError.apply(console, args)
    }
  }
}
