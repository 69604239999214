import AutomationActionTypes from "./automationActionTypes"
import Moment from "moment"
import WebStorageConsts from "../common/consts/webStorageConsts"
import { webStorage } from "../api/webStorage"
import { addAutomationsProps } from "../utils/automationUtils"
import {
  activateAutomationErrorResponse,
  activateAutomationResponse,
  automationStatusLoading,
  pauseAutomationErrorResponse,
  pauseAutomationResponse,
} from "./automationReducerOperations"
import AutomationCreationActionTypes from "../automationCreation/automationCreationActionTypes"

function getDefaultItemsPerPage() {
  let itemsPerPage = 50

  if (webStorage.get(WebStorageConsts.STORAGE_KEYS.AUTOMATIONS_ITEMS_PER_PAGE)) {
    itemsPerPage = parseInt(webStorage.get(WebStorageConsts.STORAGE_KEYS.AUTOMATIONS_ITEMS_PER_PAGE))
  }

  return itemsPerPage
}

const automationsState = {
  visibleAutomations: [], // Visible automations according to sorting, searching & filtering
  itemsPerPage: getDefaultItemsPerPage(),
  currentPage: 1,
  lastUpdate: null,
}

function automationsReducer(state = automationsState, action) {
  if (state === undefined || action === undefined) {
    return []
  }

  let updateAutomationProps = (automationObj, updatedProps) => {
    return { ...automationObj, ...updatedProps }
  }

  let updateAutomationInVisibleAutomations = (visibleAutomations, automationKey, updatedProps) => {
    return visibleAutomations.map((automationObj) => {
      if (automationObj.key === automationKey) {
        return updateAutomationProps(automationObj, updatedProps)
      }

      return automationObj
    })
  }

  let updateAutomationInAllAutomations = (allAutomations, automationKey, updatedProperties) => {
    return allAutomations.map((automationObj) => {
      if (automationObj.key === automationKey) {
        return updateAutomationProps(automationObj, updatedProperties)
      }

      return automationObj
    })
  }

  let updateAutomation = (automationKey, updatedProperties) => {
    return {
      visibleAutomations: updateAutomationInVisibleAutomations(
        state.visibleAutomations,
        automationKey,
        updatedProperties
      ),
      allAutomations: updateAutomationInAllAutomations(state.allAutomations, automationKey, updatedProperties),
    }
  }

  switch (action.type) {
    case AutomationActionTypes.FETCH_AUTOMATIONS_RESPONSE:
      return Object.assign({}, state, {
        allAutomations: addAutomationsProps(action.automations),
        currentPage: 1,
        lastUpdate: Moment().unix(),
        visibleAutomations: addAutomationsProps(action.automations),
        isLoading: false,
      })

    case AutomationActionTypes.AUTOMATIONS_LIST_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      })

    case AutomationActionTypes.ROLLBACK_AUTOMATIONS_PAGE:
      return Object.assign({}, state, {
        isLoading: false,
        allAutomations: [],
        visibleAutomations: [],
        isNextResponseUserGeneratedReport: false,
      })

    case AutomationActionTypes.UPDATE_AUTOMATIONS_PAGE:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
      })

    case AutomationActionTypes.RESET_AUTOMATIONS_PAGINATION:
      return Object.assign({}, state, {
        currentPage: 1,
      })

    case AutomationActionTypes.UPDATE_ITEMS_PER_PAGE:
      return Object.assign({}, state, {
        itemsPerPage: action.itemsPerPage,
      })

    case AutomationActionTypes.AUTOMATION_STATUS_LOADING:
      var updatedAutomationsLists = automationStatusLoading(action, updateAutomation)

      return Object.assign({}, state, {
        visibleAutomations: updatedAutomationsLists.visibleAutomations,
        allAutomations: updatedAutomationsLists.allAutomations,
      })

    case AutomationActionTypes.ACTIVATE_AUTOMATION_RESPONSE:
      var updatedAutomationsLists = activateAutomationResponse(action, updateAutomation)

      return Object.assign({}, state, {
        visibleAutomations: updatedAutomationsLists.visibleAutomations,
        allAutomations: updatedAutomationsLists.allAutomations,
      })

    case AutomationActionTypes.PAUSE_AUTOMATION_RESPONSE:
      var updatedAutomationsLists = pauseAutomationResponse(action, updateAutomation)

      return Object.assign({}, state, {
        visibleAutomations: updatedAutomationsLists.visibleAutomations,
        allAutomations: updatedAutomationsLists.allAutomations,
      })

    case AutomationActionTypes.PAUSE_AUTOMATION_ERROR_RESPONSE:
      var updatedAutomationsLists = pauseAutomationErrorResponse(action, updateAutomation)

      return Object.assign({}, state, {
        visibleAutomations: updatedAutomationsLists.visibleAutomations,
        allAutomations: updatedAutomationsLists.allAutomations,
      })

    case AutomationActionTypes.ACTIVATE_AUTOMATION_ERROR_RESPONSE:
      var updatedAutomationsLists = activateAutomationErrorResponse(action, updateAutomation)

      return Object.assign({}, state, {
        visibleAutomations: updatedAutomationsLists.visibleAutomations,
        allAutomations: updatedAutomationsLists.allAutomations,
      })

    case AutomationCreationActionTypes.CREATE_AUTOMATION_RESPONSE:
      const automation = addAutomationsProps([action.creationData])

      return Object.assign({}, state, {
        visibleAutomations: [...automation, ...state.visibleAutomations],
        allAutomations: [...automation, ...state.allAutomations],
      })

    case AutomationCreationActionTypes.EDIT_AUTOMATION_RESPONSE:
      let automationEditData = addAutomationsProps([Object.assign({}, action.editData, { id: action.ruleId })])[0]
      var updatedAutomationsLists = updateAutomation(action.ruleId, automationEditData)

      return Object.assign({}, state, {
        visibleAutomations: updatedAutomationsLists.visibleAutomations,
        allAutomations: updatedAutomationsLists.allAutomations,
      })

    default:
      return state
  }
}
export { automationsReducer }
