import React from "react"
import { connect } from "react-redux"
import {
  campaignPopupCancelRequest,
  campaignPopupFetchCampaignPerformanceByDayAsync,
  campaignPopupLoading,
} from "../campaignPopupActions"
import { CircularProgress } from "@material-ui/core"
import CampaignPopupPerformanceByDayChart from "./charts/performanceByDayChart"
import CampaignPopupPerformanceByDayList from "./lists/performanceByDayList"
import ExportButton from "../../common/components/exportButton"
import AuthApi from "../../api/authService"
import ModuleTypes from "../../common/consts/moduleTypes"
import { eventsTracker } from "../../api/eventsTracker"
import { keyboardShortcutsManager } from "../../utils/keyboardShortcutsManager"
import { findReactComponentByDomElement } from "../../utils/domUtils"

const EXPORT_TABLE_SHORTCUT_KEY = "export_table"

class CampaignPopupPerformanceByDay extends React.Component {
  getPerformanceByDay = () => {
    let startDate = null
    let endDate = null

    if (!this.props.isAllTime) {
      startDate = this.props.datePickerDates.startDate
      endDate = this.props.datePickerDates.endDate
    }

    this.props.dispatch(campaignPopupLoading())
    this.props.dispatch(campaignPopupFetchCampaignPerformanceByDayAsync(this.props.campaign, startDate, endDate))
  }

  componentDidMount() {
    this.getPerformanceByDay()
    keyboardShortcutsManager.addShortcut(EXPORT_TABLE_SHORTCUT_KEY, ["shift", "e"], this.exportTableCsv)
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      JSON.stringify(prevProps.datePickerDates.startDate) !== JSON.stringify(this.props.datePickerDates.startDate) ||
      JSON.stringify(prevProps.datePickerDates.endDate) !== JSON.stringify(this.props.datePickerDates.endDate)
    ) {
      this.getPerformanceByDay()
    }
  }

  componentWillUnmount() {
    campaignPopupCancelRequest("performance-by-day-request")
    keyboardShortcutsManager.removeShortcut(EXPORT_TABLE_SHORTCUT_KEY)
  }

  exportTableCsv = () => {
    let performanceByDayTable = findReactComponentByDomElement(
      document.querySelector(".campaign-popup-performance-by-day .generic-table")
    )
    performanceByDayTable.exportToCsv()
    eventsTracker.trackExport(
      "campaign_performance",
      null,
      null,
      Moment(this.props.datePickerDates.startDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2),
      Moment(this.props.datePickerDates.endDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2),
      this.props.campaign.tracking_code
    )
  }

  render() {
    let performanceByDay
    let campaignExportButton = null

    if (AuthApi.hasModule(ModuleTypes.CAMPAIGNS_EXPORT)) {
      campaignExportButton = <ExportButton downloadFunction={this.exportTableCsv} />
    }

    if (this.props.isLoading) {
      performanceByDay = (
        <div className="campaign-popup-loading d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      performanceByDay = (
        <div className="performance-by-day">
          <CampaignPopupPerformanceByDayChart />
          <CampaignPopupPerformanceByDayList />

          <div className="row">
            <div className="col-9" />
            <div className="col-3 d-flex justify-content-end">{campaignExportButton}</div>
          </div>
        </div>
      )
    }

    return <div className="campaign-popup-performance-by-day">{performanceByDay}</div>
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    performanceByDay: state.campaignPopupV2.performanceByDay,
    isLoading: state.campaignPopupV2.isLoading,
    reportType: state.campaignsV2.reportType,
  }
}

CampaignPopupPerformanceByDay.defaultProps = {
  isAllTime: false,
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByDay)
