import React from "react"
import CampaignCreationTargeting from "./components/campaignCreationTargeting"
import { Dialog, CircularProgress } from "@material-ui/core"
import { connect } from "react-redux"
import {
  changeWizardStep,
  closeCampaignCreationWizard,
  updateDuplicateReviewWarningState,
} from "./campaignCreationActions"
import CampaignsCreationConsts from "./campaignCreationConsts"
import CampaignCreationSources from "./components/campaignCreationSources"
import CampaignCreationCreatives from "./components/campaignCreationCreatives"
import CampaignCreationLaunch from "./components/campaignCreationLaunch"
import Xicon from "../resources/svgs/Xicon.svg"
import { eventsTracker } from "../api/eventsTracker"
import CampaignsConsts from "../campaignsV2/campaignsConsts"

class CampaignCreationWizard extends React.Component {
  closeWizard = () => {
    this.props.dispatch(closeCampaignCreationWizard(true))

    let stepName = ""
    switch (this.props.currentStep) {
      case CampaignsCreationConsts.CREATION_WIZARD_STEPS.sources:
        stepName = CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.sources
        break
      case CampaignsCreationConsts.CREATION_WIZARD_STEPS.targeting:
        stepName = CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.targeting
        break
      case CampaignsCreationConsts.CREATION_WIZARD_STEPS.creatives:
        stepName = CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.creatives
        break
      case CampaignsCreationConsts.CREATION_WIZARD_STEPS.launch:
        stepName = CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.launch
        break
    }

    eventsTracker.trackCampaignCreationCancellation(stepName)
  }

  moveToStep = (stepNum) => {
    this.props.dispatch(changeWizardStep(stepNum))
  }

  shouldShowDuplicateReviewWarning = () => {
    if (
      this.props.parentCampaign &&
      this.props.parentCampaign.providerId === CampaignsConsts.TABOOLA_PROVIDER_ID &&
      this.props.selectedSource &&
      this.props.selectedSource.id === CampaignsConsts.TABOOLA_PROVIDER_ID &&
      !this.props.wasDuplicateReviewWarningShown
    ) {
      return true
    }

    return false
  }

  showDuplicateReviewWarning = () => {
    let userConfirmed = window.confirm("Are you sure? changing this will require review from Taboola for this campaign")

    if (userConfirmed) {
      this.props.dispatch(updateDuplicateReviewWarningState())
    }
  }

  getContent = () => {
    if (this.props.isLoading) {
      return (
        <div className="loader-container">
          <CircularProgress className="loader" size={50} />
        </div>
      )
    }

    switch (this.props.currentStep) {
      case CampaignsCreationConsts.CREATION_WIZARD_STEPS.sources:
        return <CampaignCreationSources onNextClickedFunc={this.moveToStep} closeWizardFunc={this.closeWizard} />
      case CampaignsCreationConsts.CREATION_WIZARD_STEPS.targeting:
        return <CampaignCreationTargeting onNextClickedFunc={this.moveToStep} />
      case CampaignsCreationConsts.CREATION_WIZARD_STEPS.creatives:
        return (
          <CampaignCreationCreatives
            onNextClickedFunc={this.moveToStep}
            shouldShowDuplicateReviewWarning={this.shouldShowDuplicateReviewWarning}
            showDuplicateReviewWarning={this.showDuplicateReviewWarning}
          />
        )
      case CampaignsCreationConsts.CREATION_WIZARD_STEPS.launch:
        return <CampaignCreationLaunch onNextClickedFunc={this.moveToStep} />
    }
  }

  createBreadCrumbs = () => {
    let breadCrumbsElement

    breadCrumbsElement = (
      <div className="breadcrumbs">
        <div
          className={
            "step " +
            (this.props.currentStep === CampaignsCreationConsts.CREATION_WIZARD_STEPS.sources ? "selected" : "")
          }
        >
          <span className="step-number">{CampaignsCreationConsts.CREATION_WIZARD_STEPS.sources}</span>
          <span className="step-name">Source</span>
        </div>
        <div
          className={
            "step " +
            (this.props.currentStep === CampaignsCreationConsts.CREATION_WIZARD_STEPS.targeting ? "selected" : "")
          }
        >
          <span className="step-number">{CampaignsCreationConsts.CREATION_WIZARD_STEPS.targeting}</span>
          <span className="step-name">Targeting</span>
        </div>
        <div
          className={
            "step " +
            (this.props.currentStep === CampaignsCreationConsts.CREATION_WIZARD_STEPS.creatives ? "selected" : "")
          }
        >
          <span className="step-number">{CampaignsCreationConsts.CREATION_WIZARD_STEPS.creatives}</span>
          <span className="step-name">Creative</span>
        </div>
        <div
          className={
            "step " +
            (this.props.currentStep === CampaignsCreationConsts.CREATION_WIZARD_STEPS.launch ? "selected" : "")
          }
        >
          <span className="step-number">{CampaignsCreationConsts.CREATION_WIZARD_STEPS.launch}</span>
          <span className="step-name">Launch</span>
        </div>
      </div>
    )

    return breadCrumbsElement
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.isOpen}
        disableEnforceFocus={true}
        className="wizard-container"
        onClose={() => this.closeWizard()}
        BackdropProps={{
          classes: { root: "campaign-creation-wizard-backdrop" },
        }}
        classes={{ paper: "campaign-creation-wizard-popup", root: "campaign-creation-wizard-container" }}
      >
        <Xicon
          className="campaign-creation-wizard-exit-button clickable"
          width="16"
          height="16"
          onClick={() => this.closeWizard()}
        />
        <div className="central-wrapper">
          {" "}
          {/*wrap everything*/}
          <div className="step-header">
            <div className="title">Create new campaigns</div>
            {this.createBreadCrumbs()}
          </div>
          <div className="step-content">{this.getContent()}</div>
        </div>
      </Dialog>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let selectedSource = null

  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
    let source = state.campaignCreationWizard.campaignSources.sources[sourceKey]

    if (source.selected) {
      selectedSource = source
    }
  })

  return {
    countries: state.app.countries,
    currentNetwork: state.navigationBar.currentNetwork,
    currentSite: state.navigationBar.currentSite,
    isOpen: state.campaignCreationWizard.isOpen,
    currentStep: state.campaignCreationWizard.currentStep,
    isLoading: state.campaignCreationWizard.isLoading,
    parentCampaign: state.campaignCreationWizard.parentCampaign,
    wasDuplicateReviewWarningShown: state.campaignCreationWizard.wasDuplicateReviewWarningShown,
    selectedSource,
  }
}

export default connect(mapStateToProps)(CampaignCreationWizard)
