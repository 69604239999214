import NetworkTypes from "../common/consts/networkTypes"

export function getPermittedNetwork(networkToSites, wantedNetwork) {
  if (NetworkTypes.SPECIAL_NETWORK_LINKS.includes(wantedNetwork)) {
    return NetworkTypes.linkToType[wantedNetwork]
  }

  let permittedNetwork = networkToSites.filter((network) => {
    return network.data[0].network.name === wantedNetwork
  })

  if (permittedNetwork.length === 1) {
    return permittedNetwork[0]
  }

  return null
}

export function getPermittedSite(sites, wantedSite, wantedNetwork) {
  let permittedSite = sites.filter((site) => {
    return site.code === wantedSite && site.network.name === wantedNetwork
  })

  if (permittedSite.length === 1) {
    return permittedSite[0]
  }

  return null
}
