import React from "react"
import { ShowChart } from "@material-ui/icons"

export default class EmptyChartIndicator extends React.Component {
  render() {
    return (
      <div className="empty-chart-indicator">
        <div className="chart-icon">
          <ShowChart className="show-chart-icon" />
        </div>
        <span className="font-bold">No available data</span>
        <span className="font-regular">for the selected date range</span>
      </div>
    )
  }
}
