import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../../common/components/genericDropdown"
import CampaignsCreationConsts from "../../../campaignCreationConsts"
import { updateTargetingGroup } from "../../../campaignCreationActions"

class PlacementsDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: this.getPlacements(),
    }
  }

  getPlacements = () => {
    let placements = CampaignsCreationConsts.FACEBOOK_PLACEMENTS

    placements = placements.map((placement) => {
      return { name: placement.name, isTitle: placement.isTitle }
    })

    return placements
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (selectedIndexes) => {
    let selectedPlacements = []

    selectedIndexes.forEach((index) => {
      selectedPlacements.push(CampaignsCreationConsts.FACEBOOK_PLACEMENTS[index])
    })

    let newSourceData = Object.assign({}, this.props.campaignTargetingGroup, {
      selectedPlacements,
      selectedPlacementsIndexesArr: selectedIndexes,
    })

    if (this.props.onChange) {
      this.props.onChange(newSourceData)
    } else {
      this.props.dispatch(updateTargetingGroup(this.props.groupIndex, newSourceData))
    }
  }

  render() {
    let isFieldDisabled = !this.props.siteId
    let header = "Choose placement"
    let buttonClassName = "creation-generic-dropdown-button "

    if (
      this.props.campaignTargetingGroup.selectedPlacements &&
      this.props.campaignTargetingGroup.selectedPlacements.length > 0
    ) {
      buttonClassName += "selected"

      if (this.props.campaignTargetingGroup.selectedPlacementsIndexesArr.length === 1) {
        header = this.props.campaignTargetingGroup.selectedPlacements[0].name
      } else {
        header = this.props.campaignTargetingGroup.selectedPlacementsIndexesArr.length + " placements selected"
      }
    }

    return (
      <div className={"text-field placements-selection " + (isFieldDisabled ? "disabled " : "")}>
        <div className="creation-generic-dropdown-label">Placements</div>
        <GenericDropdown
          header={header}
          selectOptionCallback={this.handleMenuClick}
          allowSelectAll
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.props.campaignTargetingGroup.selectedPlacementsIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowMultiple
          allowAutoSuggest
          showEmptyOption
          emptyOptionText="None"
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection
          buttonClassName={buttonClassName}
          menuClassName="creation-generic-dropdown-menu"
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    siteId: ownProps.siteId ? ownProps.siteId : state.campaignCreationWizard.campaignSettings.siteId,
  }
}

export default connect(mapStateToProps)(PlacementsDropdown)
