import React from "react"
import { TextField } from "@material-ui/core"

export default class AutomationName extends React.Component {
  render() {
    return (
      <div className={"text-field " + (this.props.automationNameValidationErrors ? "error " : "")}>
        <TextField
          label="Rule name"
          InputProps={{
            className: "text-field-line",
          }}
          InputLabelProps={{
            classes: { shrink: "text-field-color" },
          }}
          placeholder=""
          value={this.props.automationDetailsState.automationName}
          onChange={(event) => this.props.handleChange("automationName", event)}
          margin={this.props.reduceMargin ? "dense" : "normal"}
        />
        {this.props.automationNameValidationErrors}
      </div>
    )
  }
}
