import React from "react"
import ReactTooltip from "react-tooltip"
import { Chip, MenuItem, TextField } from "@material-ui/core"
import { Close, KeyboardArrowDown } from "@material-ui/icons"
import Autosuggest from "react-autosuggest"
import PropTypes from "prop-types"
import SearchIcon from "../../../resources/svgs/SearchIcon.svg"
import { getCountryText } from "../../../utils/countryUtils"
import AuthApi from "../../../api/authService"
import ModuleTypes from "../../../common/consts/moduleTypes"

function getSuggestionValue(suggestion) {
  return suggestion
}

function renderSuggestionsContainer(options) {
  return null
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return null
}

export default class CountriesDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      search: "",
      suggestions: [],
      allSuggestions: [],
      hasAccountPermissions: props.networkId ? AuthApi.hasModule(ModuleTypes.ACCOUNT_SETTINGS, props.networkId) : false,
    }
  }

  componentWillMount() {
    this.handleSuggestionsClearRequested()
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    ReactTooltip.rebuild()

    if (
      JSON.stringify(this.props.countryGroups) !== JSON.stringify(prevProps.countryGroups) ||
      JSON.stringify(this.props.countries) !== JSON.stringify(prevProps.countries)
    ) {
      this.handleSuggestionsClearRequested()
    }
    if (this.props.networkId && this.props.networkId !== prevProps.networkId) {
      this.setState({
        hasAccountPermissions: AuthApi.hasModule(ModuleTypes.ACCOUNT_SETTINGS, this.props.networkId),
      })
    }
  }

  initSuggestions = () => {
    if (this.props.countryGroups) {
      return [...this.props.countries, ...this.props.countryGroups]
    }
    return this.props.countries
  }

  getSuggestions = (value) => {
    if (value !== undefined) {
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length

      if (inputLength === 0) {
        return Array.from(this.state.allSuggestions)
      }

      return this.state.allSuggestions.filter((suggestion) => {
        return suggestion.name.toLowerCase().includes(inputValue)
      })
    }

    return []
  }

  showSuggestionsMenu = () => {
    let items = []
    let output = []
    let recentItems = []
    let recentItemsObj = {}
    let countryGroupItems = []

    this.state.suggestions.forEach((suggestion) => {
      // only country group have id
      if (!suggestion.hasOwnProperty("id")) {
        // Country suggestion
        let item = (
          <div key={suggestion.code}>
            <MenuItem onClick={() => this.props.handleCountrySelection(suggestion)}>
              <input type="checkbox" checked={suggestion.selected} value={suggestion.code} readOnly />
              {suggestion.name}
            </MenuItem>
          </div>
        )

        // If the country is suggested but is recent, it will be placed in the recent countries section
        if (this.props.recentCountries && this.props.recentCountries.includes(suggestion.code)) {
          recentItemsObj[suggestion.code] = item
        } else {
          items.push(item)
        }
      } else {
        // Country group suggestion
        countryGroupItems.push(
          <div key={"country-group-" + suggestion.name}>
            <MenuItem onClick={() => this.props.handleCountryGroupSelection(suggestion)}>
              <input type="checkbox" checked={suggestion.selected} readOnly />
              {suggestion.name}
            </MenuItem>
          </div>
        )
      }
    })

    if (this.props.recentCountries) {
      this.props.recentCountries.forEach((item) => {
        if (recentItemsObj[item]) {
          recentItems.push(recentItemsObj[item])
        }
      })
    }

    if (recentItems.length > 0) {
      output.push(
        <div className="subsection-header" key="recentHeader">
          Recent
        </div>
      )
      output.push(...recentItems)
    }

    if (countryGroupItems.length > 0) {
      output.push(
        <div className="subsection-header" key="Groups">
          Groups
        </div>
      )
      output.push(...countryGroupItems)
    }

    if (items.length > 0) {
      output.push(
        <div className="subsection-header" key="allHeader">
          All
        </div>
      )
    }

    output.push(...items)
    return output
  }

  renderInput = (inputProps) => {
    const { autoFocus, value, ref } = inputProps
    let icon = null

    if (this.state.search !== "") {
      icon = (
        <span className="d-flex" onClick={this.handleClearSearchFilter}>
          <Close className="clear-filter-text" />
        </span>
      )
    } else {
      icon = (
        <span className="d-flex">
          <SearchIcon className="search-icon" />
        </span>
      )
    }

    return (
      <div className="d-flex align-items-center text-field-wrapper">
        <TextField
          id="country-text-field"
          classes={{ root: "text-field" }}
          autoFocus={autoFocus}
          value={value}
          inputRef={ref}
          InputProps={{
            className: "text-field-line",
            inputProps,
          }}
        />
        {icon}
      </div>
    )
  }

  handleSearchChange = (event) => {
    this.setState({
      search: event.target.value,
    })
  }

  handleSuggestionsFetchRequested = (input) => {
    this.setState({
      suggestions: this.getSuggestions(input.value),
    })
  }

  handleSuggestionsClearRequested = () => {
    let arr = this.initSuggestions()

    this.setState(
      {
        suggestions: arr,
        allSuggestions: arr,
      },
      () => {
        if (this.state.search.length === 0) {
          this.setState({
            search: "",
          })
        }
      }
    )
  }

  handleClearSearchFilter = () => {
    this.setState(
      {
        suggestions: this.state.allSuggestions,
      },
      () => {
        this.setState({
          search: "",
        })
      }
    )
  }

  focusAutosuggest = () => {
    let textField = document.querySelector("#country-text-field")

    if (textField) {
      textField.focus()
    }
  }

  uncheckCountryGroup = (countryGroup) => {
    ReactTooltip.hide()
    this.props.handleCountryGroupSelection(countryGroup)
  }

  render() {
    let selectedCountries = this.props.countries.filter((country) => {
      return country.selected
    })
    let selectedCountryGroups = this.props.countryGroups
      ? this.props.countryGroups.filter((countryGroup) => {
          return countryGroup.selected
        })
      : []
    let isCountrySelected = selectedCountries.length > 0 || selectedCountryGroups.length > 0

    let dropdownText = this.props.dropdownText

    if (this.props.showSelectedText) {
      let totalSelected = [...selectedCountryGroups, ...selectedCountries]

      if (totalSelected.length > 0) {
        if (totalSelected.length === 1) {
          dropdownText = totalSelected[0].name
        } else {
          dropdownText = totalSelected.length + " selected"
        }
      }
    }

    return (
      <>
        <div
          className={
            "text-field countries-selection " +
            (this.props.countriesValidationErrors ? "error" : "") +
            " " +
            this.props.menuClassName
          }
        >
          <TextField
            select
            label=" "
            SelectProps={{
              multiple: true,
              value: [dropdownText],
              renderValue: (val) => val,
              MenuProps: {
                transformOrigin: {
                  vertical: 0,
                  horizontal: 0,
                },
                classes: { paper: "creation-dropdown countries-dropdown" },
                onExit: this.handleClearSearchFilter,
                onEntered: this.focusAutosuggest,
              },
              classes: {
                select: "creation-select" + (!isCountrySelected ? " unselected" : ""),
                icon: "creation-select-icon",
              },
            }}
            InputProps={{
              className: "text-field-line",
            }}
            InputLabelProps={{
              classes: { root: "text-field-color" },
            }}
            classes={{ root: "creation-select-wrapper" }}
            onChange={(event) => this.props.handleChange("selectedCountry", event)}
            margin="normal"
          >
            {[
              <MenuItem key="auto-suggest" classes={{ root: "react-autosuggest-input-wrapper" }}>
                <div className="react-autosuggest-input">
                  <Autosuggest
                    renderInputComponent={this.renderInput}
                    suggestions={this.state.suggestions}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    alwaysRenderSuggestions
                    inputProps={{
                      autoFocus: true,
                      placeholder: "Search",
                      value: this.state.search,
                      onChange: this.handleSearchChange,
                      onKeyDown: this.handleFocusChange,
                    }}
                  />
                </div>
              </MenuItem>,
              ...this.showSuggestionsMenu(),
            ]}
          </TextField>
          <KeyboardArrowDown className="arrow" />
        </div>
        {this.props.showSelectedCountriesChips ? (
          <div className="countries-chips-container chips-container">
            {selectedCountryGroups.map((countryGroup) => {
              let countryGroupTooltip = ""
              if (!countryGroup.countries) {
                return (
                  <Chip
                    classes={{ root: "chip-item" }}
                    label={countryGroup.name}
                    key={countryGroup.id}
                    deleteIcon={<Close className="delete-icon" />}
                    onDelete={() => this.props.handleCountryGroupSelection(countryGroup)}
                  />
                )
              }
              let additionalCountries = countryGroup.countries.length - 10
              countryGroupTooltip = countryGroup.countries
                .slice(0, 10)
                .map((c) => {
                  return getCountryText(c)
                })
                .join(", ")

              if (additionalCountries > 0) {
                countryGroupTooltip += ",<br/>" + additionalCountries + " more..."
              }

              countryGroupTooltip = "<div>" + countryGroupTooltip + "</div>"

              // in case that auto select multi countries in the campaign
              if (this.state.hasAccountPermissions & (countryGroup.name !== "Multiple Countries")) {
                countryGroupTooltip += '<a href="/settings/campaignCreationSettings" target="_blank">Edit</a>'
              }

              return (
                <Chip
                  data-tip={countryGroupTooltip}
                  data-class="tooltip-custom country-group-tooltip"
                  data-delay-hide={200}
                  classes={{ root: "chip-item" }}
                  label={countryGroup.name}
                  key={countryGroup.name}
                  deleteIcon={<Close className="delete-icon" />}
                  onDelete={() => this.uncheckCountryGroup(countryGroup)}
                />
              )
            })}
            {selectedCountries.map((country) => {
              return (
                <Chip
                  classes={{ root: "chip-item" }}
                  label={country.name}
                  key={country.code}
                  deleteIcon={<Close className="delete-icon" />}
                  onDelete={() => this.props.handleCountrySelection(country)}
                />
              )
            })}
          </div>
        ) : null}
        {this.props.countriesValidationErrors}
      </>
    )
  }
}

CountriesDropdown.propTypes = {
  menuClassName: PropTypes.string,
  showSelectedCountriesChips: PropTypes.bool,
  dropdownText: PropTypes.string,
  showSelectedText: PropTypes.bool,
}

CountriesDropdown.defaultProps = {
  menuClassName: "",
  showSelectedCountriesChips: true,
  dropdownText: "Country",
  showSelectedText: false,
}
