import React from "react"
import { connect } from "react-redux"
import { Dialog, CircularProgress } from "@material-ui/core"
import { withRouter } from "react-router"
import { campaignPopupClose, campaignPopupDataLoaded, resetCampaignPopupDates } from "./campaignPopupActions"
import CampaignPopupMain from "./components/popupMain"
import CampaignPopupEdit from "./components/popupEdit"
import CampaignServiceV2 from "../api/campaignServiceV2"
import { notificationAdd } from "../common/actions/commonActions"
import { eventsTracker } from "../api/eventsTracker"
import CampaignsConsts from "../campaignsV2/campaignsConsts"

class CampaignPopup extends React.Component {
  isCurrentlyClosingPopup = false

  componentDidMount() {
    if (!this.props.campaign && this.props.isOpen) {
      // Campaign popup will load on mount only if we're in a direct campaign popup route (browsing directly to /campaign)
      this.isCurrentlyClosingPopup = false

      this.getCampaignData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let isCampaignPopupLocation = Boolean(this.props.location.state && this.props.location.state.isCampaignPopupV2)

    if (!prevProps.isOpen && this.props.isOpen) {
      // Campaign popup is open
      this.isCurrentlyClosingPopup = false

      if (isCampaignPopupLocation) {
        if (this.props.campaign) {
          eventsTracker.trackCampaignPopupOpened(this.props.campaign.tracking_code)
        } else {
          this.getCampaignData()
        }
      }
    } else if (prevProps.isOpen && !this.props.isOpen && !this.isCurrentlyClosingPopup) {
      // Campaign popup is closed by moving through history (browser's back button)
      this.props.dispatch(campaignPopupClose(false))
    }
  }

  getCampaignData = () => {
    let campaign = {
      campaign_id: this.props.routeParams.campaignId,
    }

    CampaignServiceV2.getSingleCampaign(campaign)
      .then((result) => {
        this.props.dispatch(campaignPopupDataLoaded(result.data.campaign))
      })
      .catch((error) => {
        if (error) {
          this.props.dispatch(notificationAdd("There was an error loading the campaign"))
          this.closePopup()
        }
      })
  }

  closePopup = () => {
    if (!this.isCurrentlyClosingPopup) {
      let userConfirmed = true

      if (this.props.userConfirmationBeforeLeaving) {
        userConfirmed = window.confirm("Are you sure? you will lose your unsaved changes")
      }

      if (userConfirmed) {
        this.isCurrentlyClosingPopup = true
        this.props.dispatch(campaignPopupClose())
      }
    }
  }

  render() {
    let popupContent = <div />

    if (this.props.campaign) {
      if (this.props.section === "Edit") {
        popupContent = <CampaignPopupEdit />
      } else {
        popupContent = <CampaignPopupMain closeFunction={this.closePopup} />
      }
    } else {
      popupContent = (
        <div className="d-flex align-items-center justify-content-center" style={{ flex: 1 }}>
          <CircularProgress className="loader" size={40} />
        </div>
      )
    }

    return (
      <Dialog
        fullScreen
        open={this.props.isOpen}
        BackdropProps={{
          classes: { root: "campaign-popup-backdrop" },
        }}
        onClose={this.closePopup}
        onEnter={() => {
          if (this.props.reportType === CampaignsConsts.REPORT_TYPES.PREDICTED_REPORT) {
            this.props.dispatch(resetCampaignPopupDates())
          }
        }}
        classes={{ paper: "campaign-popup", root: "campaign-popup-container" }}
      >
        {popupContent}
      </Dialog>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
    mainSubSection: state.campaignPopupV2.mainSubSection,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    providers: state.app.providers,
    section: state.campaignPopupV2.section,
    routeParams: ownProps.params,
    reportType: state.campaignPopupV2.reportType,
    userConfirmationBeforeLeaving: state.campaignPopupV2.userConfirmationBeforeLeaving,
  }
}

const connectedCampaignPopup = connect(mapStateToProps)(CampaignPopup)
export default withRouter(connectedCampaignPopup)
