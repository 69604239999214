import React from "react"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Button, CircularProgress } from "@material-ui/core"
import merge from "deepmerge"
import CampaignsCreationConsts from "../campaignCreationConsts"
import {
  increaseRecentCountriesAsync,
  increaseRecentLanguagesAsync,
  launchCampaignCreationAsync,
} from "../campaignCreationActions"
import Creative from "./creative"
import ExternalLink from "../../resources/images/ExternalLink.png"
import { eventsTracker } from "../../api/eventsTracker"
import CopyClipboardIcon from "../../resources/svgs/CopyClipboardIcon.svg"
import CampaignCreationService from "../../api/campaignCreationService"
import { isNullOrUndefined } from "../../utils/funcUtils"

class CampaignCreationLaunch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      campaignNames: [],
      campaignItemsHtml: [],
      numOfCampaigns: 0,
      copiedToClipboard: false,
      isLoading: true,
      creationRequests: [],
    }
  }

  moveBack = () => {
    this.props.onNextClickedFunc(CampaignsCreationConsts.CREATION_WIZARD_STEPS.creatives)

    eventsTracker.trackCampaignCreationStep(CampaignsCreationConsts.CREATION_WIZARD_STEP_NAMES.creatives)
  }

  getCampaignCreationData = () => {
    // Deep copying the reducer data (working on copy of the immutable reducer, the copy is mutable in order to delete values easier)
    let creationData = merge({}, this.props.campaignCreationWizard)

    // Removing large mappings that were only necessary for the reducer in the UI, so they won't be sent as part of the request
    delete creationData.providerAccounts
    delete creationData.networkTags
    delete creationData.campaignSettings?.possibleSitesForDomain

    creationData.campaignSettings.selectedTags = creationData.campaignSettings.selectedTags.map((tag) => tag.name)
    creationData.campaignSettings.name = creationData.campaignSettings.name.trim()

    creationData.campaignTargetingGroups.forEach((targetingGroup) => {
      targetingGroup.selectedTags = targetingGroup.selectedTags.map((tag) => tag.name)
      targetingGroup.selectedConversionEvents = targetingGroup.selectedConversionEvents.map((event) => event.value)
    })
    return creationData
  }

  launch = () => {
    this.props.dispatch(
      increaseRecentLanguagesAsync(this.props.campaignCreationWizard.campaignSettings.selectedLanguages)
    )

    let countryCodes = this.props.campaignCreationWizard.campaignTargetingGroups
      .map((targetingGroup) => targetingGroup.selectedCountries)
      .flat()
    countryCodes = Array.from(new Set(countryCodes))
    this.props.dispatch(increaseRecentCountriesAsync(countryCodes))

    this.props.dispatch(launchCampaignCreationAsync(this.state.creationRequests))

    eventsTracker.trackCampaignCreation()
  }

  generateCampaignsSummary = () => {
    return (
      <div className="launch-box flex-column campaigns-summary">
        {this.state.campaignItemsHtml}
        <div className="copy-to-clipboard">
          <CopyToClipboard text={this.state.campaignNames.join("\n")} onCopy={this.onCopyToClipboard}>
            <div
              id="copy-to-clipboard-btn"
              className="clickable"
              data-tip={this.state.copiedToClipboard ? "Copied to clipboard" : ""}
            >
              <CopyClipboardIcon />
              Copy to clipboard
            </div>
          </CopyToClipboard>
        </div>
      </div>
    )
  }

  generateCampaignItems = (creationRequests) => {
    let campaignNames = []
    let campaignItemsHtml = []
    const campaignSources = this.props.campaignCreationWizard.campaignSources
    const selectedSource = Object.values(campaignSources.sources).filter((s) => s.selected)[0]
    const provider = this.props.providerIdToProvider.get(selectedSource.id)
    creationRequests.forEach((creationRequest, index) => {
      const campaignIndex = "Campaign #" + (index + 1)
      let campaignName = creationRequest.utm_campaign
      campaignItemsHtml.push(
        <div className="d-flex campaign-item" key={index}>
          <span className="row-title font-semibold">{campaignIndex}</span>
          <span className="text">
            <span className="font-semibold" style={{ color: provider.providerColor }}>
              {provider.providerName}
            </span>
            {campaignName}
          </span>
        </div>
      )
      const conversionEventName = creationRequest?.conversion_event?.conversion_name
      const languageCode = creationRequest?.language_code
      if (conversionEventName) {
        campaignName = `${campaignName},${conversionEventName}`
      }
      if (languageCode) {
        campaignName = `${campaignName},${languageCode.toUpperCase()}`
      }
      campaignNames.push(`${campaignName},`.trim())
    })

    this.setState({
      campaignItemsHtml,
      campaignNames,
      numOfCampaigns: campaignNames.length,
      creationRequests,
    })
  }

  generateTitle = () => {
    let title = "Create " + this.state.numOfCampaigns + " campaign" + (this.state.numOfCampaigns > 1 ? "s" : "")
    return <div className="step-title">{title}</div>
  }

  generateCampaignName = () => {
    return (
      <>
        <div className="launch-box first article-name">
          <span className="row-title font-semibold">Campaign Name</span>
          <span className="text">{this.props.campaignCreationWizard.campaignSettings.name}</span>
        </div>
        <div className="launch-box first article-name">
          <span className="row-title font-semibold">Article URL</span>
          <span className="text">{this.props.campaignCreationWizard.campaignSettings.articleUrl}</span>
          <a className="icon" href={this.props.campaignCreationWizard.campaignSettings.articleUrl} target="_blank">
            <img src={ExternalLink} alt="" />
          </a>
        </div>
      </>
    )
  }

  generateCreatives = () => {
    // Static creatives (regular creatives only)
    if (this.props.campaignCreationWizard.campaignCreatives.creatives.length > 0) {
      return (
        <div className="launch-box flex-column">
          <div className="font-semibold">Creatives</div>
          <div className="creatives-list">
            {this.props.campaignCreationWizard.campaignCreatives.creatives.map((creative) => (
              <Creative
                className="creative"
                creative={creative}
                key={creative.id}
                static
                campaignSources={this.props.campaignCreationWizard.campaignSources}
              />
            ))}
          </div>
        </div>
      )
    } else if (
      this.props.campaignCreationWizard.campaignCreatives.creativesGroups.length > 0 &&
      !isNullOrUndefined(this.props.campaignCreationWizard.campaignCreatives.isDynamicCreative) &&
      !this.props.campaignCreationWizard.campaignCreatives?.isDynamicCreative
    ) {
      return (
        <div className="creatives-group-box flex-column">
          {this.props.campaignCreationWizard.campaignCreatives.creativesGroups.map((creativesGroup, index) => (
            <div key={index} className="creatives-group-static">
              <div className="font-semibold">{`Creatives Group #${index + 1}`}</div>
              <div className="creatives-list">
                {creativesGroup.creatives.map((creative) => (
                  <Creative
                    className="creative"
                    creative={creative}
                    key={creative.id}
                    static
                    campaignSources={this.props.campaignCreationWizard.campaignSources}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )
    }
  }

  componentDidMount() {
    ReactTooltip.rebuild()
    this.loadData()
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  generateCreationRequests = () => {
    return CampaignCreationService.generateCampaignCreationRequests(this.getCampaignCreationData())
  }

  loadData = () => {
    this.setState({ isLoading: true })
    this.generateCreationRequests()
      .then((result) => {
        const creationRequests = result.data?.creation_requests
        if (creationRequests.length > 0) {
          this.generateCampaignItems(creationRequests)
        } else {
          this.moveBack()
        }
      })
      .catch(() => {
        this.moveBack()
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  onCopyToClipboard = () => {
    this.setState(
      {
        copiedToClipboard: true,
      },
      () => {
        ReactTooltip.show(document.getElementById("copy-to-clipboard-btn"))
        setTimeout(() => {
          this.setState({
            copiedToClipboard: false,
          })
        }, 1000)
      }
    )
  }

  render() {
    const { isLoading } = this.state
    let creationContent

    let loadingIndicator = this.props.campaignCreationWizard.isLaunching ? (
      <div className="launching d-flex align-items-center justify-content-center">
        <CircularProgress size={40} />
      </div>
    ) : null

    if (!isLoading) {
      creationContent = (
        <div className="launch-boxes">
          {this.generateTitle()}
          {this.generateCampaignName()}
          {this.generateCampaignsSummary()}
          {this.generateCreatives()}
        </div>
      )
    } else {
      creationContent = (
        <div className="">
          <div className="step-title">Generating campaigns preview</div>
          <div className="creatives-loading d-flex justify-content-center align-items-center">
            <CircularProgress size={40} />
          </div>
        </div>
      )
    }

    return (
      <div className="launch">
        {creationContent}
        <div className="creation-wizard-navigation">
          <Button variant="contained" color="primary" className="round-button gray back-button" onClick={this.moveBack}>
            Back
          </Button>
          <div className="launch-button-container">
            <Button
              variant="contained"
              color="primary"
              className="round-button green next-button"
              disabled={this.state.isLoading}
              onClick={this.launch}
            >
              Create campaigns
            </Button>
            {loadingIndicator}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    campaignCreationWizard: state.campaignCreationWizard,
    providerIdToProvider: state.app.providerIdToProvider,
  }
}

export default connect(mapStateToProps)(CampaignCreationLaunch)
