import React from "react"
import { genericCompareFunc } from "../utils/funcUtils"
import AutomationPopupActionTypes from "./automationPopupActionTypes"
import AutomationActionTypes from "../automations/automationActionTypes"
import TableConsts from "../common/components/table/tableConsts"
import AutomationsConsts from "../automations/automationsConsts"
import { getEndDate, getStartDate, CUSTOM, SEVEN_DAYS } from "../common/components/datePickerV2"
import { addAutomationsProps } from "../utils/automationUtils"

const automationPopupInitialState = {
  automation: null,
  datePickerDates: {
    text: "7 Days",
    startDate: getStartDate(SEVEN_DAYS),
    endDate: getEndDate(SEVEN_DAYS),
    showLastHour: false,
    selected: true,
    dateType: SEVEN_DAYS,
  },
  section: "Main",
  networkTags: [],
  actionHistory: {
    dataForChart: [],
    dataForTable: [],
    sortBy: AutomationsConsts.LIST_COLUMNS.actionHistoryColumns.time.fieldName,
    sortDirection: TableConsts.DESC,
    itemsPerPage: 30,
    currentPage: 1,
    hasData: false,
  },
  isLoading: false,
}

function automationPopupReducer(state = automationPopupInitialState, action) {
  if (state === undefined || action === undefined) {
    return []
  }

  // If the popup doesn't have an automation yet, the only valid actions are 'AUTOMATION_POPUP_OPEN'
  // and 'AUTOMATION_POPUP_DATA_LOADED'
  if (
    !state.automation &&
    ![
      AutomationPopupActionTypes.AUTOMATION_POPUP_OPEN,
      AutomationPopupActionTypes.AUTOMATION_POPUP_DATA_LOADED,
      AutomationPopupActionTypes.AUTOMATION_POPUP_RESET_DATES,
    ].includes(action.type)
  ) {
    return state
  }

  // If the automation popup is open and the action contains automation object, we want to make sure
  // that it's still the same automation to avoid sync problems
  if (action.automation && action.automation.key !== state.automation.key) {
    return state
  }

  switch (action.type) {
    case AutomationPopupActionTypes.AUTOMATION_POPUP_OPEN:
      return Object.assign({}, state, {
        datePickerDates: {
          text: "7 Days",
          startDate: getStartDate(SEVEN_DAYS),
          endDate: getEndDate(SEVEN_DAYS),
          showLastHour: false,
          dateType: SEVEN_DAYS,
        },
        automation: action.automationData,
      })

    case AutomationPopupActionTypes.AUTOMATION_POPUP_DATA_LOADED:
      return Object.assign({}, state, {
        automation: addAutomationsProps([action.automationData])[0],
      })

    case AutomationPopupActionTypes.AUTOMATION_POPUP_CLOSE:
      return Object.assign({}, automationPopupInitialState)

    case AutomationPopupActionTypes.AUTOMATION_POPUP_DATES_SELECTED:
      var endDate = action.dateType === CUSTOM ? action.endDate : getEndDate(action.dateType)
      var startDate = action.dateType === CUSTOM ? action.startDate : getStartDate(action.dateType)

      return Object.assign({}, state, {
        datePickerDates: {
          startDate: startDate,
          endDate: endDate,
          dateType: action.dateType,
          showLastHour: action.showLastHour,
        },
      })

    case AutomationActionTypes.AUTOMATION_STATUS_LOADING:
      return Object.assign({}, state, {
        automation: Object.assign({}, state.automation, {
          isAutomationStatusLoading: action.isAutomationStatusLoading,
        }),
      })

    case AutomationActionTypes.PAUSE_AUTOMATION_ERROR_RESPONSE:
    case AutomationActionTypes.ACTIVATE_AUTOMATION_RESPONSE:
    case AutomationActionTypes.PAUSE_AUTOMATION_RESPONSE:
    case AutomationActionTypes.ACTIVATE_AUTOMATION_ERROR_RESPONSE:
      return Object.assign({}, state, {
        automation: Object.assign({}, state.automation, {
          status: action.status,
          isAutomationStatusLoading: action.isAutomationStatusLoading,
        }),
      })

    case AutomationPopupActionTypes.AUTOMATION_POPUP_FETCH_ACTION_HISTORY_RESPONSE:
      var actionHistoryData = action.actionHistory.rule_actions.map((actionHistoryItem, index) => {
        var item = Object.assign({}, actionHistoryItem)
        item.key = index
        return item
      })

      var actionsCountByHour = Object.keys(action.actionHistory.rule_actions_count_by_hour).map((key, index) => {
        var oneHour = Object.assign({}, action.actionHistory.rule_actions_count_by_hour[key])
        oneHour.key = key
        return oneHour
      })

      var fieldNameToSortBy = automationPopupInitialState.actionHistory.sortBy

      return Object.assign({}, state, {
        actionHistory: Object.assign({}, state.actionHistory, {
          dataForChart: actionsCountByHour
            .slice()
            .sort((b, a) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                automationPopupInitialState.actionHistory.sortDirection
              )
            ),
          dataForTable: actionHistoryData
            .slice()
            .sort((a, b) =>
              genericCompareFunc(
                a[fieldNameToSortBy],
                b[fieldNameToSortBy],
                automationPopupInitialState.actionHistory.sortDirection
              )
            ),
          sortBy: action.firstRequest ? automationPopupInitialState.actionHistory.sortBy : state.actionHistory.sortBy,
          sortDirection: action.firstRequest
            ? automationPopupInitialState.actionHistory.sortDirection
            : state.actionHistory.sortDirection,
          currentPage: action.firstRequest ? 1 : state.actionHistory.currentPage,
          hasData: actionHistoryData.length !== 0,
        }),
        isLoading: false,
      })

    case AutomationPopupActionTypes.AUTOMATION_POPUP_ACTION_HISTORY_SORT_BY:
      var sortDirection = TableConsts.DESC

      if (state.actionHistory.sortBy === action.sortBy && state.actionHistory.sortDirection === TableConsts.DESC) {
        sortDirection = TableConsts.ASC
      }
      var fieldNameToSortBy = action.sortBy

      return Object.assign({}, state, {
        actionHistory: Object.assign({}, state.actionHistory, {
          sortBy: action.sortBy,
          sortDirection,
          dataForTable: state.actionHistory.dataForTable
            .slice()
            .sort((a, b) => genericCompareFunc(a[fieldNameToSortBy], b[fieldNameToSortBy], sortDirection)),
          currentPage: 1,
        }),
      })

    case AutomationPopupActionTypes.AUTOMATION_POPUP_ACTION_HISTORY_SHOW_MORE:
      return Object.assign({}, state, {
        actionHistory: Object.assign({}, state.actionHistory, {
          currentPage: state.actionHistory.currentPage + 1,
        }),
      })

    case AutomationPopupActionTypes.AUTOMATION_POPUP_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      })

    case AutomationPopupActionTypes.AUTOMATION_POPUP_RESET_DATES:
      return Object.assign({}, state, {
        datePickerDates: {
          text: "7 Days",
          startDate: getStartDate(SEVEN_DAYS),
          endDate: getEndDate(SEVEN_DAYS),
          showLastHour: false,
          dateType: SEVEN_DAYS,
        },
      })

    default:
      return state
  }
}

export { automationPopupReducer }
