import React from "react"
import { Link } from "react-router"
import { generateCampaignUrl } from "../utils/campaignUtilsV2"

export default class CampaignPopupLink extends React.Component {
  render() {
    let linkProps = { ...this.props }
    let returnTo = this.props.returnTo ? this.props.returnTo : window.location.pathname
    // We don't need the campaign prop inside the <Link>, we only use it to generate the campaign url
    delete linkProps.campaign

    return (
      <Link
        to={{ pathname: generateCampaignUrl(this.props.campaign), state: { isCampaignPopupV2: true, returnTo } }}
        {...linkProps}
      >
        {this.props.children}
      </Link>
    )
  }
}
