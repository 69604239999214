import React from "react"
import { connect } from "react-redux"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import { campaignPopupSortPerformanceList, updateCampaignPopupTablePage } from "../../campaignPopupActions"
import Pagination from "../../../common/components/pagination"
import Table from "../../../common/components/table/table"
import TableConsts from "../../../common/components/table/tableConsts"
import CampaignROI from "../../../common/components/campaignRoi"
import { isNullOrUndefined } from "../../../utils/funcUtils"
import SubSourceActionsMenu from "../../../subSources/components/subSourceActionsMenu"
import SubSourceBidPopup from "../../../subSources/components/subSourceBidComponents/subSourceBidPopup"
import SearchIcon from "../../../resources/svgs/SearchIcon.svg"
import { TextField } from "@material-ui/core"

class CampaignPopupPerformanceBySubSourceList extends React.Component {
  columns = CampaignsConsts.LIST_COLUMNS

  constructor(props) {
    super(props)

    this.tableName = "performanceBySubSource"
    this.state = {
      searchSubSource: null,
    }
  }

  sortSubSourcesBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, "performanceBySubSource"))
  }
  setSearchSubSourceValue = (event) => {
    this.setState({ searchSubSource: event.target.value })
  }

  getHeader = () => {
    let columns = this.columns

    return {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.status.fieldName,
          value: columns.status.title,
          clickable: true,
          cellSize: this.columns.status.cellSize,
        },
        {
          fieldName: columns.subSource.fieldName,
          value: columns.subSource.title,
          clickable: true,
          cellSize: this.columns.subSource.cellSize,
        },
        {
          fieldName: columns.subSourceBid.fieldName,
          value: columns.subSourceBid.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.clicks.fieldName,
          value: columns.data_columns.clicks.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.visits.fieldName,
          value: columns.data_columns.visits.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.costPerClick.fieldName,
          value: columns.data_columns.costPerClick.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.visitValue.fieldName,
          value: "Visit Value (Predicted)",
          clickable: true,
        },
        {
          fieldName: columns.data_columns.roi.fieldName,
          value: "ROI (Predicted)",
          clickable: true,
        },
        {
          fieldName: columns.data_columns.profit.fieldName,
          value: "Profit (Predicted)",
          clickable: true,
        },
        {
          fieldName: columns.data_columns.dailySpent.fieldName,
          value: columns.data_columns.dailySpent.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.revenue.fieldName,
          value: "Revenue (Predicted)",
          clickable: true,
        },
        {
          fieldName: columns.data_columns.pagesPerVisit.fieldName,
          value: columns.data_columns.pagesPerVisit.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.bounceRate.fieldName,
          value: columns.data_columns.bounceRate.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.visitDuration.fieldName,
          value: columns.data_columns.visitDuration.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.ctr.fieldName,
          value: columns.data_columns.ctr.title,
          clickable: true,
        },
      ],
    }
  }

  getSummaryRow = (summaryData) => {
    let columns = this.columns.data_columns

    return {
      cssClasses: ["summary-row"],
      allowRowSelection: false,
      cells: [
        {
          fieldName: this.columns.status.fieldName,
          value: "",
          cellSize: this.columns.status.cellSize,
        },
        {
          fieldName: this.columns.subSource.fieldName,
          value: "",
          cellSize: this.columns.subSource.cellSize,
        },
        {
          fieldName: this.columns.subSourceBid.fieldName,
          value: TableConsts.EMPTY_CELL,
        },
        {
          fieldName: columns.clicks.fieldName,
          value: columns.visits.defaultFormat(summaryData.clicks),
        },
        {
          fieldName: columns.visits.fieldName,
          value: columns.visits.defaultFormat(summaryData.visits),
        },
        {
          fieldName: columns.costPerClick.fieldName,
          value: columns.costPerClick.defaultFormat(summaryData.cost_per_click),
        },
        {
          fieldName: columns.visitValue.fieldName,
          value: columns.visitValue.defaultFormat(summaryData.visit_value),
        },
        {
          fieldName: columns.roi.fieldName,
          value: isNullOrUndefined(summaryData.roi) ? (
            TableConsts.EMPTY_CELL
          ) : (
            <CampaignROI roi={summaryData.roi} siteId={this.props.campaign.site_id} />
          ),
        },
        {
          fieldName: columns.profit.fieldName,
          value: columns.profit.defaultFormat(summaryData.profit),
        },
        {
          fieldName: columns.dailySpent.fieldName,
          value: columns.dailySpent.defaultFormat(summaryData.daily_spent),
        },
        {
          fieldName: columns.revenue.fieldName,
          value: columns.revenue.defaultFormat(summaryData.revenue),
        },
        {
          fieldName: columns.pagesPerVisit.fieldName,
          value: columns.pagesPerVisit.defaultFormat(summaryData.pages_per_visit),
        },
        {
          fieldName: columns.bounceRate.fieldName,
          value: columns.bounceRate.defaultFormat(summaryData.bounce_rate),
        },
        {
          fieldName: columns.visitDuration.fieldName,
          value: columns.visitDuration.defaultFormat(summaryData.visit_duration),
        },
        {
          fieldName: columns.ctr.fieldName,
          value: columns.ctr.defaultFormat(summaryData.ctr),
        },
      ],
    }
  }

  getRow = (subSource) => {
    let columns = this.columns.data_columns

    return {
      cells: [
        {
          fieldName: this.columns.status.fieldName,
          value: <SubSourceActionsMenu subSource={subSource} campaign={this.props.campaign} />,
          rawValue: subSource.status,
          cssClasses: ["campaign-status"],
          cellSize: this.columns.status.cellSize,
        },
        {
          fieldName: this.columns.subSource.fieldName,
          value: subSource.sub_source,
          cellSize: this.columns.subSource.cellSize,
        },
        {
          fieldName: this.columns.subSourceBid.fieldName,
          value: <SubSourceBidPopup campaign={this.props.campaign} subSource={subSource} />,
          rawValue: subSource.bid,
        },
        {
          fieldName: columns.clicks.fieldName,
          value: columns.visits.defaultFormat(subSource.clicks),
        },
        {
          fieldName: columns.visits.fieldName,
          value: columns.visits.defaultFormat(subSource.visits),
        },
        {
          fieldName: columns.costPerClick.fieldName,
          value: columns.costPerClick.defaultFormat(subSource.cost_per_click),
        },
        {
          fieldName: columns.visitValue.fieldName,
          value: columns.visitValue.defaultFormat(subSource.visit_value),
        },
        {
          fieldName: columns.roi.fieldName,
          rawValue: subSource.roi,
          value: isNullOrUndefined(subSource.roi) ? (
            TableConsts.EMPTY_CELL
          ) : (
            <CampaignROI roi={subSource.roi} siteId={this.props.campaign.site_id} />
          ),
          cssClasses: ["font-bold"],
        },
        {
          fieldName: columns.profit.fieldName,
          value: columns.profit.defaultFormat(subSource.profit),
        },
        {
          fieldName: columns.dailySpent.fieldName,
          value: columns.dailySpent.defaultFormat(subSource.daily_spent),
        },
        {
          fieldName: columns.revenue.fieldName,
          value: columns.revenue.defaultFormat(subSource.revenue),
        },
        {
          fieldName: columns.pagesPerVisit.fieldName,
          value: columns.pagesPerVisit.defaultFormat(subSource.pages_per_visit),
        },
        {
          fieldName: columns.bounceRate.fieldName,
          value: columns.bounceRate.defaultFormat(subSource.bounce_rate),
        },
        {
          fieldName: columns.visitDuration.fieldName,
          value: columns.visitDuration.defaultFormat(subSource.visit_duration),
        },
        {
          fieldName: columns.ctr.fieldName,
          value: columns.ctr.defaultFormat(subSource.ctr),
        },
      ],
    }
  }

  render() {
    let header = this.getHeader()
    let rows = []
    let pagination = null
    let filteredPerformanceBySubSource = this.props.performanceBySubSource

    if (this.props.performanceBySubSource.length > 0) {
      rows.push(this.getSummaryRow(this.props.summaryRow))
    }
    if (!isNullOrUndefined(this.state.searchSubSource)) {
      filteredPerformanceBySubSource = this.props.performanceBySubSource.filter((performance) =>
        performance.sub_source.includes(this.state.searchSubSource)
      )
    }

    for (
      let i = (this.props.currentPage - 1) * this.props.itemsPerPage;
      i < this.props.currentPage * this.props.itemsPerPage && i < filteredPerformanceBySubSource.length;
      i++
    ) {
      let subSource = filteredPerformanceBySubSource[i]
      rows.push(this.getRow(subSource))
    }

    if (filteredPerformanceBySubSource.length > this.props.itemsPerPage) {
      pagination = (
        <Pagination
          itemsArr={
            filteredPerformanceBySubSource ? filteredPerformanceBySubSource.length : filteredPerformanceBySubSource
          }
          currentPage={this.props.currentPage}
          itemsPerPage={this.props.itemsPerPage}
          updatePage={(updatedPage) => {
            this.props.dispatch(updateCampaignPopupTablePage(updatedPage, this.tableName))
          }}
        />
      )
    }

    return (
      <div>
        <div className={"align-items-end justify-content-end sub-source-search"}>
          <TextField
            id="sub-source-search-text-field"
            label="Search by Sub-Source"
            classes={{ root: "text-field" }}
            onChange={this.setSearchSubSourceValue}
            value={this.state.searchSubSource}
          />
          <span>
            <SearchIcon className={"search-icon"} style={{ marginLeft: "-10px", marginBottom: "4px" }} />
          </span>
        </div>
        <Table
          header={header}
          rows={rows}
          sort={{
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection,
            sortHandler: this.sortSubSourcesBy,
          }}
          scrolling={{
            containerClass: ".campaign-popup-container",
            includeNavigationBar: false,
          }}
          cssClasses={["campaign-popup-list"]}
        />
        {pagination}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    sortBy: state.campaignPopupV2.performanceBySubSource.sortBy,
    sortDirection: state.campaignPopupV2.performanceBySubSource.sortDirection,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    performanceBySubSource: state.campaignPopupV2.performanceBySubSource.dataForTable,
    summaryRow: state.campaignPopupV2.performanceBySubSource.summaryRow,
    itemsPerPage: state.campaignPopupV2.performanceBySubSource.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceBySubSource.currentPage,
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceBySubSourceList)
