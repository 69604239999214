import React from "react"
import { MenuItem, MenuList } from "@material-ui/core"
import { deleteSavedFilters } from "../../../actions/commonActions"
import { eventsTracker } from "../../../../api/eventsTracker"
import { deserializeFilters } from "../../../../utils/funcUtils"

export class SavedFilter {
  static getMenu = (filter) => {
    return (mainFilterComponent) => {
      let items = []

      function savedFiltersClick(option) {
        let filters = deserializeFilters(option.value.filter_data, filter.filtersTemplates)

        mainFilterComponent.addArrayOfFilters(filters)
        eventsTracker.trackSelectFiltersGroup()
      }

      filter.getOptions().forEach((option) => {
        items.push(
          <MenuItem
            classes={{ root: "menu-item filter-results d-flex" }}
            key={mainFilterComponent.state.filter.filterType + option.value.id}
            onClick={(event) => {
              savedFiltersClick(option)
            }}
          >
            <span className="filter-result-text">{option.name}</span>
            <span
              className="delete"
              onClick={(event) => {
                event.stopPropagation()
                mainFilterComponent.props.dispatch(deleteSavedFilters(option.value.id))
                mainFilterComponent.handleMenuRequestClose()
                eventsTracker.trackDeleteFiltersGroup()
              }}
            >
              Delete
            </span>
          </MenuItem>
        )
      })

      return {
        body: (
          <MenuList>
            <MenuItem classes={{ root: "menu-item search-divider" }}>
              {mainFilterComponent.getMenuHeader(mainFilterComponent.getFilterName())}
            </MenuItem>
            {items}
          </MenuList>
        ),
      }
    }
  }

  static getSuggestions = (mainFilterComponent, filter) => {
    let items = []

    function suggestionClicked() {
      mainFilterComponent.setState(
        {
          search: "",
          filter: Object.assign({}, mainFilterComponent.state.filter, filter, {
            filterOperator: filter.defaultOperator,
          }),
        },
        () => mainFilterComponent.pushMenu(filter.getMenu(filter))
      )
    }

    if (!filter.showFilter || filter.showFilter()) {
      items.push({
        name: filter.filterName,
        key: filter.filterType,
        filter: {
          filterName: filter.filterName,
          handleClick: () => suggestionClicked(),
        },
      })
    }

    return items
  }
}
