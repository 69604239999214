import React from "react"
import Consts from "../app/consts"
import DesktopIcon from "../resources/svgs/DesktopIcon.svg"
import TabletIcon from "../resources/svgs/TabletIcon.svg"
import MobileIcon from "../resources/svgs/MobileIcon.svg"
import AppleIcon from "../resources/svgs/AppleIcon.svg"
import AndroidIcon from "../resources/svgs/AndroidIcon.svg"
import MultipleDevicesIcon from "../resources/svgs/MultipleDevicesIcon.svg"
import AppleSmallIcon from "../resources/svgs/AppleSmallIcon.svg"
import AndroidSmallIcon from "../resources/svgs/AndroidSmallIcon.svg"
import CampaignsConsts from "../campaignsV2/campaignsConsts"
import { store, history } from "../app/store"
import { getProviderBidStepSize } from "./providerUtils"
import { isNullOrUndefined } from "./funcUtils"
import OsTypes from "../common/consts/osTypes"
import CampaignsCreationConsts from "../campaignCreation/campaignCreationConsts"
import Browsers from "../common/consts/browsers"
import Moment from "moment/moment"
import PlatformTypes from "../common/consts/platformTypes"
import BidTypes from "../common/consts/bidTypes"

function formatPlatformNames(platforms) {
  return platforms
    .map((platform) => {
      return platform ? getPlatformText([platform]) : null
    })
    .filter(Boolean)
    .join(", ")
}

export function getPlatformIconV2(originalPlatform, osType = null) {
  let platform = originalPlatform
  if (
    isNullOrUndefined(platform) ||
    platform.length > 1 ||
    isNullOrUndefined(platform[0]) ||
    typeof platform[0] !== "string"
  ) {
    let platformsNames =
      !isNullOrUndefined(platform) &&
      platform.length > 1 &&
      !isNullOrUndefined(platform[0]) &&
      !typeof platform[0] !== "string"
        ? formatPlatformNames(platform.slice().sort())
        : PlatformTypes.MULTIPLE_DEVICES.name

    switch (platformsNames) {
      case formatPlatformNames(PlatformTypes.iosPlatforms.sort()):
        return (
          <AppleIcon
            data-class="tooltip-custom type-icon-info shrink-tip"
            data-tip={platformsNames}
            className="platform-icon"
          />
        )
      case formatPlatformNames(PlatformTypes.androidPlatforms.sort()):
        return (
          <AndroidIcon
            data-class="tooltip-custom type-icon-info shrink-tip"
            data-tip={platformsNames}
            className="platform-icon"
          />
        )
      case PlatformTypes.MULTIPLE_DEVICES.name:
      default:
        return (
          <MultipleDevicesIcon
            data-class="tooltip-custom type-icon-info shrink-tip"
            data-tip={platformsNames}
            className="platform-icon"
          />
        )
    }
  }
  switch (platform[0].toLowerCase()) {
    case PlatformTypes.DESKTOP.value:
      return (
        <DesktopIcon
          data-class="tooltip-custom type-icon-info shrink-tip"
          data-tip={PlatformTypes.DESKTOP.name}
          className="platform-icon desktop-icon"
        />
      )
    case PlatformTypes.MOBILE.value:
      switch (osType) {
        case Consts.ANDROID:
          return <AndroidSmallIcon />
        case Consts.IOS:
          return (
            <AppleSmallIcon
              data-class="tooltip-custom type-icon-info shrink-tip"
              data-tip={Consts.IOS}
              className="platform-icon"
            />
          )
        default:
          return (
            <MobileIcon
              data-class="tooltip-custom type-icon-info shrink-tip"
              data-tip={PlatformTypes.MOBILE.name}
              className="platform-icon mobile-icon"
            />
          )
      }
    case PlatformTypes.TABLET.value:
      return (
        <TabletIcon
          data-class="tooltip-custom type-icon-info shrink-tip"
          data-tip={PlatformTypes.TABLET.name}
          className="platform-icon tablet-icon"
        />
      )
    case PlatformTypes.ANDROID_MOBILE.value:
    case PlatformTypes.ANDROID_TABLET.value:
      return (
        <AndroidIcon
          data-class="tooltip-custom type-icon-info shrink-tip"
          data-tip={getPlatformText(platform)}
          className="platform-icon"
        />
      )
    case PlatformTypes.IPAD.value:
    case PlatformTypes.IPHONE.value:
      return (
        <AppleIcon
          data-class="tooltip-custom type-icon-info shrink-tip"
          data-tip={getPlatformText(platform)}
          className="platform-icon"
        />
      )
    default:
      return null
  }
}

export function getPlatformText(devicePlatforms, isDuplicate = false) {
  if (!devicePlatforms || (Array.isArray(devicePlatforms) && devicePlatforms.length > 1)) {
    return isDuplicate ? PlatformTypes.UNKNOWN.name : PlatformTypes.MULTIPLE_DEVICES.name
  }
  const platform = Object.values(PlatformTypes).find((pt) => pt.value === devicePlatforms[0])
  return platform ? platform.name : null
}

export function getPlatformValue(devicePlatforms) {
  if (!devicePlatforms || (Array.isArray(devicePlatforms) && devicePlatforms.length > 1)) {
    return PlatformTypes.MULTIPLE_DEVICES.value
  }
  return devicePlatforms[0] ? devicePlatforms[0] : null
}

export function isRoasAllowed(campaign) {
  // Only Facebook has support for ROAS
  return CampaignsConsts.FACEBOOK_PROVIDER_ID === campaign.provider_id
}

export function hasRoasEnabled(campaign) {
  return campaign.bid_mode === BidTypes.roas.id
}

export function getSiteCode(campaign, siteIdToSiteObject) {
  if (campaign?.site_code) {
    return campaign.site_code
  }

  return siteIdToSiteObject.get(campaign.site_id) ? siteIdToSiteObject.get(campaign.site_id).code : "--"
}

export function getSiteName(campaign, siteIdToSiteObject) {
  if (campaign?.site_name) {
    return campaign.site_name
  }
  return siteIdToSiteObject.get(campaign.site_id) ? siteIdToSiteObject.get(campaign.site_id).name : "--"
}

export function isUnknownCampaignTrafficSource(campaign) {
  return campaign.provider_id === Consts.UNKNOWN_PROVIDER_ID
}

export function generateCampaignKey(campaign) {
  return campaign.campaign_id
}

export function generateCampaignUrl(campaign, hasBaseUrl = false) {
  let campaignUrl = "/campaign/" + campaign.campaign_id

  if (hasBaseUrl) {
    campaignUrl = Consts.BASE_URL + campaignUrl
  }

  return campaignUrl
}

export function isHiddenColumn(customInterface, section, column) {
  let data = customInterface.get(section).get(column)
  return data && data.status === Consts.CUSTOM_INTERFACE_FIELD_STATUS.HIDE
}

/*
 * According to MDN - A Map object iterates its elements in insertion order.
 * */
export function sortCustomInterfaceColumns(map) {
  return new Map(
    [...map.entries()].sort((keyValA, keyValB) => {
      if (keyValA[1].order < keyValB[1].order) {
        return -1
      }
      if (keyValA[1].order > keyValB[1].order) {
        return 1
      }

      return 0
    })
  )
}

export function isCampaignPopupOpenV2() {
  return history.getCurrentLocation().pathname.includes("/campaign/")
}

export function isCampaignPopupLocationStateV2() {
  let locationState = history.getCurrentLocation().state

  return locationState && locationState.isCampaignPopupV2
}

export function isReturningFromCampaignPopupLocationState() {
  let locationState = history.getCurrentLocation().state

  return locationState && locationState.isReturningFromCampaignPopup
}

export function isCampaignPopupRelatedLocationStateV2() {
  return isCampaignPopupLocationStateV2() || isReturningFromCampaignPopupLocationState()
}

export function isConversionNameNotExist(conversion_name, provider_id) {
  let conversionEventsObj = store.getState().app.conversionEvents
  return isNullOrUndefined(conversionEventsObj?.[provider_id]?.[conversion_name])
}

export function getMaximumBidByConversionName(conversion_name, provider_id) {
  if (isConversionNameNotExist(conversion_name, provider_id)) return Consts.DEFAULT_MAX_BID
  return store.getState().app.conversionEvents[provider_id][conversion_name].maximum_bid
}

export function getMaximumBudgetByConversionName(conversion_name, provider_id) {
  if (isConversionNameNotExist(conversion_name, provider_id)) {
    if (provider_id === CampaignsConsts.FACEBOOK_PROVIDER_ID) {
      return Consts.FACEBOOK_DEFAULT_MAX_BUDGET
    } else {
      return Consts.DEFAULT_MAX_BUDGET
    }
  }
  return store.getState().app.conversionEvents[provider_id][conversion_name].maximum_budget
}

export function isCampaignBidChangeAllowed(campaign, bid) {
  if (Consts.PROVIDERS_THAT_CANT_CHANGE_BID.includes(campaign.provider_id)) {
    return false
  }

  let bidStep = getProviderBidStepSize(campaign.provider_id)

  return bid >= bidStep && bid <= getMaximumBidByConversionName(campaign.conversion_name, campaign.provider_id)
}

export function isCampaignDailyBudgetChangeAllowed(campaign, dailyBudget) {
  if (campaign.provider_id === CampaignsConsts.FACEBOOK_PROVIDER_ID) {
    return dailyBudget <= getMaximumBudgetByConversionName(campaign.conversion_name, campaign.provider_id)
  }

  return true
}

export function getCampaignBidByDesiredRoi(campaign, desiredRoi) {
  // Given a campaign, we will calculate the bid that will satisfy the given desiredRoi.
  let bidStepSize = getProviderBidStepSize(campaign.provider_id)
  let newBid = bidStepSize
  let tolerance = Consts.CHANGE_BID_BY_ROI_PERCENT_TOLERANCE / 100
  let desiredRoiWithTolerance = Math.max(0, desiredRoi - tolerance)

  if (campaign.visit_value > 0 && desiredRoi > 0) {
    // Starting with the highest possible bid for this campaign, we calculate the ROI (visit_value / bid), this also means that
    // we start with the lowest possible ROI and work our way up to the wanted one.
    // In each iteration the bid is lowered by the step size that is allowed for the campaign's provider (0.01 or 0.001), thus increasing the ROI each time.
    for (
      newBid = getMaximumBidByConversionName(campaign.conversion_name, campaign.provider_id);
      newBid >= bidStepSize;
      newBid -= bidStepSize
    ) {
      let roi = parseFloat((campaign.visit_value / newBid).toFixed(2))
      let nextRoi = newBid > bidStepSize ? parseFloat((campaign.visit_value / (newBid - bidStepSize)).toFixed(2)) : 0

      // If the ROI is bigger or equal to the wanted one, we break the calculation and we will return the bid that the loop stopped on.
      // Tolerance:
      // It can sometimes be dangerous to set the bid too low, for example:
      // If the desiredRoi is 155% and the calculation is currently on 151%, and the next roi is 189%, we will want to select 151% because 189%
      // sets the bid too low, and 151% is close enough to 155.
      if (
        roi >= desiredRoi ||
        (roi <= desiredRoi && roi >= desiredRoiWithTolerance && nextRoi > desiredRoi + tolerance)
      ) {
        break
      }
    }
  }

  return newBid
}

export function isCampaignRoiChangeAllowed(campaign) {
  return campaign.visit_value > 0
}

export function bulkGetSelectedCampaigns(campaigns) {
  return campaigns.filter((campaign) => campaign.selected)
}

export function bulkGetSelectedCampaignsWithAllowedCurrencyValueChange(campaigns) {
  return campaigns.filter((campaign) => getCampaignCurrency() === Consts.DEFAULT_CURRENCY)
}

export function bulkGetSelectedCampaignsWithAllowedBidChange(
  selectedOption,
  inputValue,
  inputValueSelectedType,
  changeBidRule,
  campaignsToCheck
) {
  let campaigns = bulkGetSelectedCampaigns(campaignsToCheck)
  let campaignsToChange = []
  let value = parseFloat(inputValue)

  if (selectedOption === "BidFixedAmount") {
    campaigns.forEach((campaign) => {
      let bidStepSize = getProviderBidStepSize(campaign.provider_id)
      let newBid = parseFloat(value.toFixed(bidStepSize.countDecimals()))

      if (isCampaignBidChangeAllowed(campaign, newBid)) {
        campaignsToChange.push({ ...campaign, newBid })
      }
    })
  } else if (selectedOption === "BidAdjustByInputValueTypes") {
    if (inputValueSelectedType === "$") {
      campaigns.forEach((campaign) => {
        let bidStepSize = getProviderBidStepSize(campaign.provider_id)
        let newBid = parseFloat((campaign.bid + value).toFixed(bidStepSize.countDecimals()))

        if (isCampaignBidChangeAllowed(campaign, newBid)) {
          campaignsToChange.push({ ...campaign, newBid })
        }
      })
    } else if (inputValueSelectedType === "%") {
      campaigns.forEach((campaign) => {
        let bidStepSize = getProviderBidStepSize(campaign.provider_id)
        let newBid = parseFloat((campaign.bid * (value / 100 + 1)).toFixed(bidStepSize.countDecimals()))

        if (isCampaignBidChangeAllowed(campaign, newBid)) {
          campaignsToChange.push({ ...campaign, newBid })
        }
      })
    }
  } else if (selectedOption === "BidFixedRoi") {
    let desiredRoi = inputValue / 100

    campaigns.forEach((campaign) => {
      let bidStepSize = getProviderBidStepSize(campaign.provider_id)
      let newBid = parseFloat(getCampaignBidByDesiredRoi(campaign, desiredRoi).toFixed(bidStepSize.countDecimals()))

      if (isCampaignRoiChangeAllowed(campaign) && isCampaignBidChangeAllowed(campaign, newBid)) {
        campaignsToChange.push({ ...campaign, newBid })
      }
    })

    switch (changeBidRule) {
      case "only_raise_bids":
        campaignsToChange = campaignsToChange.filter((campaign) => campaign.bid <= campaign.newBid)
        break
      case "only_lower_bids":
        campaignsToChange = campaignsToChange.filter((campaign) => campaign.bid >= campaign.newBid)
        break
    }
  }

  return campaignsToChange
}

export function bulkGetSelectedCampaignsWithAllowedDailyBudgetChange(selectedOption, inputValue, campaignsToCheck) {
  const campaigns = bulkGetSelectedCampaigns(campaignsToCheck)
  let campaignsToChange = []
  const value = parseFloat(inputValue)

  if (selectedOption === "BudgetFixedAmount") {
    campaigns.forEach((campaign) => {
      if (isCampaignDailyBudgetChangeAllowed(campaign, value)) {
        campaignsToChange.push({ ...campaign, newDailyBudget: value })
      }
    })
  } else {
    campaignsToChange = campaigns
  }

  return campaignsToChange
}

export function getCampaignCurrency() {
  //  not needed anymore
  return Consts.DEFAULT_CURRENCY
}

export function getCurrencySymbol(currency) {
  return Consts.CURRENCY_SYMBOLS[currency] ? Consts.CURRENCY_SYMBOLS[currency] : "$"
}

export function getCampaignPlacementChanges(campaignTargeting) {
  let positionToPlacementNames = {}

  {
    CampaignsCreationConsts.FACEBOOK_POSITIONS.map((targetingSetting) => {
      if (!campaignTargeting[targetingSetting]) {
        return null
      }

      let placementNames = campaignTargeting[targetingSetting]
        .map((placemenKey) => {
          if (CampaignsCreationConsts.FACEBOOK_POSITION_KEY_TO_POSITION[`${targetingSetting}-${placemenKey}`]) {
            return CampaignsCreationConsts.FACEBOOK_POSITION_KEY_TO_POSITION[`${targetingSetting}-${placemenKey}`].name
          }

          return placemenKey
        })
        .join(", ")

      positionToPlacementNames[
        CampaignsCreationConsts.FACEBOOK_POSITION_KEY_TO_POSITION[`${targetingSetting}-${targetingSetting}`].name
      ] = placementNames
    })
  }

  return positionToPlacementNames
}

export function getTargetingGroupSelectedPlatformsSummaryText(providerId, targetingGroup) {
  let selectedPlatformsTextArr = []

  if (!targetingGroup || !targetingGroup.platforms) {
    return ""
  }

  if (areAllPlatformsSelected(targetingGroup.platforms)) {
    return providerId === CampaignsConsts.FACEBOOK_PROVIDER_ID ? "Automatic" : "All"
  }

  Object.keys(targetingGroup.platforms).forEach((platformKey) => {
    let platform = targetingGroup.platforms[platformKey]
    if (platform.selected) {
      let platformText = ""
      platformText += targetingGroup.platforms[platformKey].displayedName || platformKey
      let subPlatformKeys = Object.keys(platform.values)

      if (subPlatformKeys.length > 0) {
        let selectedSubPlatformsTextArr = []

        subPlatformKeys.forEach((subPlatformKey) => {
          let subPlatform = platform.values[subPlatformKey]

          if (subPlatform) {
            selectedSubPlatformsTextArr.push(OsTypes.nameToDisplayName[subPlatformKey])
          }
        })

        platformText += ` (${selectedSubPlatformsTextArr.join(", ")})`
      }

      selectedPlatformsTextArr.push(platformText)
    }
  })

  return selectedPlatformsTextArr.join(", ")
}

export function getTargetingGroupSelectedCountriesSummaryText(targetingGroup, isShortString = false) {
  if (!targetingGroup || !targetingGroup.selectedCountries || !targetingGroup.selectedCountryGroups) {
    return ""
  }

  let selectedCountriesAndGroupsString = ""

  let selectedCountriesAndGroups = [
    ...targetingGroup.selectedCountries,
    ...targetingGroup.selectedCountryGroups.map((countryGroup) => countryGroup.name),
  ]

  if (isShortString) {
    selectedCountriesAndGroupsString = selectedCountriesAndGroups
      .slice(0, Consts.NUMBER_OF_DISPLAYED_COUNTRIES_IN_SHORT_STRING)
      .join(", ")

    if (selectedCountriesAndGroups.length > Consts.NUMBER_OF_DISPLAYED_COUNTRIES_IN_SHORT_STRING) {
      selectedCountriesAndGroupsString +=
        " +" + (selectedCountriesAndGroups.length - Consts.NUMBER_OF_DISPLAYED_COUNTRIES_IN_SHORT_STRING) + " more"
    }
  } else {
    selectedCountriesAndGroupsString = selectedCountriesAndGroups.join(", ")
  }

  return selectedCountriesAndGroupsString
}

export function getPresetConversionEventsOptions(provider_id = "9", includeTitles = false) {
  const conversionEventsOptions = []

  if (includeTitles) {
    conversionEventsOptions.push({ name: "VV>X", key: "VV>X", isTitle: true })
  }

  const conversionEventsObj = store.getState().app.conversionEvents?.[provider_id]

  if (conversionEventsObj) {
    Object.entries(conversionEventsObj).forEach(([event, conversionEventObj]) => {
      conversionEventsOptions.push({
        ...conversionEventObj,
        name: event,
        key: event,
      })
    })
  }

  return conversionEventsOptions
}

export function momentDateTimeAndUnixTimeStamp(time) {
  if (Moment.unix(time).isValid()) {
    return Moment.unix(time)
  }

  return Moment(time)
}

export function buildCampaignName(campaignName, provider) {
  // Google or Taboola: delete only the UUID (4 char) if exist
  let regex = [
    CampaignsConsts.TABOOLA_PROVIDER_ID,
    CampaignsConsts.GOOGLE_PROVIDER_ID,
    CampaignsConsts.PINTEREST_PROVIDER_ID,
  ].includes(provider)
    ? /\s+\b\w{4}\b$/g
    : /( Dup.*| DUP.*| MSN.*| SAF.*| EDG.*| CHR.*)|( Dynamic.*| ROAS.*)|( VV[0-9]{1}).*|([0-9]{5}).*|( pnp.*)|( phs.*)|( PNP.*)|( PHS.*)|( BGT.*)|(\s[0-9]{4}).*/g
  return campaignName.replaceAll(regex, "")
}

export function extractTrimmedUrl(url) {
  if (!isNullOrUndefined(url)) {
    let isIncludeQueryParams = url.indexOf("?")

    if (isIncludeQueryParams !== -1) {
      url = url.slice(0, isIncludeQueryParams)
    }
  }

  return url
}

export function overrideCampaignAttributes(campaign) {
  campaign.trimmedUrl = extractTrimmedUrl(campaign.link_url)
  campaign.tracking_code = campaign.url_params?.utm_campaign
  campaign.device_platforms = campaign.targeting?.device_platforms
  campaign.countries = campaign.targeting?.geo_locations?.countries
  campaign.browsers = campaign.targeting?.browsers
  campaign.operating_systems = campaign.targeting?.operating_systems
  campaign.article_language = campaign.lang
  campaign.tags = campaign.tags
    ? campaign.tags.map((tag) => {
        return { name: tag }
      })
    : []
}

export function getBrowserName(browser) {
  return Browsers.typeToName[browser]
}

export function getOperatingSystemName(operatingSystem) {
  return OsTypes.typeToName[operatingSystem]
}

export function getBrowsersFilterValue(browsers) {
  if (isNullOrUndefined(browsers) || browsers.length !== 1) {
    return Browsers.multipleBrowsers
  } else {
    return browsers[0]
  }
}

export function getOperatingSystemsFilterValue(operatingSystems) {
  if (isNullOrUndefined(operatingSystems) || operatingSystems.length !== 1) {
    return OsTypes.multipleOs
  } else {
    return operatingSystems[0]
  }
}

export function areAllPlatformsSelected(platforms) {
  return (
    Object.keys(platforms).length > 0 &&
    Object.keys(platforms).every((platformKey) => {
      const platform = platforms[platformKey]
      const subValues = platform.values || {}
      return platform.selected && Object.values(subValues).every((subOption) => subOption)
    })
  )
}
