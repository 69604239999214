class NavigationBarActionsTypes {}
NavigationBarActionsTypes.GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
NavigationBarActionsTypes.GET_ACTIVITIES = "GET_ACTIVITIES"
NavigationBarActionsTypes.SHOW_MORE_ACTIVITIES = "SHOW_MORE_ACTIVITIES"
NavigationBarActionsTypes.NAVIGATION_DRAWER_STATE_CHANGE = "NAVIGATION_DRAWER_STATE_CHANGE"
NavigationBarActionsTypes.DISMISS_ACTIVITY = "DISMISS_ACTIVITY"
NavigationBarActionsTypes.PENDING_ACTIVITY = "PENDING_ACTIVITY"
NavigationBarActionsTypes.DISMISS_ADMIN_NOTIFICATION = "DISMISS_ADMIN_NOTIFICATION"
NavigationBarActionsTypes.DISMISS_ACTIVITIES = "DISMISS_ACTIVITIES"
NavigationBarActionsTypes.DRAWER_LOADING = "DRAWER_LOADING"
NavigationBarActionsTypes.SET_ACTIVITIES_PENDING = "SET_ACTIVITIES_PENDING"
NavigationBarActionsTypes.GET_ADMIN_NOTIFICATIONS_RESPONSE = "GET_ADMIN_NOTIFICATIONS_RESPONSE"
export default NavigationBarActionsTypes
