class WebStorageConsts {}

WebStorageConsts.STORAGE_TYPES = {
  DEFAULT: 0,
  ALWAYS_IN_LOCAL_STORAGE: 1,
  ALWAYS_IN_SESSION_STORAGE: 2,
}

WebStorageConsts.STORAGE_KEYS = {
  PUBPLUS_AUTH_TOKEN: {
    key: "pubplus_auth_token",
    type: WebStorageConsts.STORAGE_TYPES.ALWAYS_IN_LOCAL_STORAGE,
  },
  SHOW_OUTDATED_WARNING: {
    key: "show_outdated_warning",
    type: WebStorageConsts.STORAGE_TYPES.DEFAULT,
  },
  CAMPAIGNS_LAST_SORT_BY_COLUMN: {
    key: "campaigns_last_sort_by_column",
    type: WebStorageConsts.STORAGE_TYPES.DEFAULT,
  },
  CAMPAIGNS_LAST_SORT_DIRECTION: {
    key: "campaigns_last_sort_direction",
    type: WebStorageConsts.STORAGE_TYPES.DEFAULT,
  },
  CAMPAIGNS_REPORT_TYPE: {
    key: "campaigns_report_type",
    type: WebStorageConsts.STORAGE_TYPES.DEFAULT,
  },
  CAMPAIGNS_ITEMS_PER_PAGE: {
    key: "campaigns_items_per_page",
    type: WebStorageConsts.STORAGE_TYPES.DEFAULT,
  },
  CAMPAIGNS_FILTERS: {
    key: "campaigns_filters",
    type: WebStorageConsts.STORAGE_TYPES.ALWAYS_IN_SESSION_STORAGE,
  },
  CAMPAIGNS_REALTIME_UPDATE: {
    key: "campaigns_realtime_update",
    type: WebStorageConsts.STORAGE_TYPES.DEFAULT,
  },
  AUTOMATIONS_ITEMS_PER_PAGE: {
    key: "automations_items_per_page",
    type: WebStorageConsts.STORAGE_TYPES.DEFAULT,
  },
  ARTICLES_ITEMS_PER_PAGE: {
    key: "articles_items_per_page",
    type: WebStorageConsts.STORAGE_TYPES.DEFAULT,
  },
}

export default WebStorageConsts
