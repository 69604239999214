import React from "react"
import { connect } from "react-redux"
import ReactPaginate from "react-paginate"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import { isNullOrUndefined } from "../../utils/funcUtils"

class Pagination extends React.Component {
  constructor() {
    super()
  }

  handlePageClick = (data) => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    })
    let selected = data.selected + 1

    let result = this.props.updatePage(selected)
    if (!isNullOrUndefined(result)) {
      this.props.dispatch(result)
    }
  }

  render() {
    let pageCount = 0
    if (!isNullOrUndefined(this.props.itemsArr)) {
      pageCount = Math.ceil(this.props.itemsArr / this.props.itemsPerPage)
    }

    return (
      <div className="campaigns-pagination d-flex flex-row justify-content-center">
        <ReactPaginate
          previousLabel={<KeyboardArrowLeft />}
          nextLabel={<KeyboardArrowRight />}
          breakLabel={<span>...</span>}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={this.props.currentPage - 1}
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(Pagination)
