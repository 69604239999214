import "react-dates/initialize"
import React from "react"
import Moment from "moment"
import { DayPickerRangeController, isInclusivelyBeforeDay } from "react-dates"
import "react-dates/lib/css/_datepicker.css"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import { Button, Popover } from "@material-ui/core"
import Consts from "../../app/consts"
import { isNullOrUndefined } from "../../utils/funcUtils"

const DATEPICKER_INPUT_DATE_FORMAT = "DD/MM/YYYY"
const START_DATE = "startDate"
const END_DATE = "endDate"
const LAST_HOUR = 0
export const TODAY = 1
const YESTERDAY = 2
const LAST_7_DAYS = 3
export const LAST_30_DAYS = 4
const THIS_MONTH = 5
const LAST_MONTH = 6
const ALL_TIME = 7
export const SEVEN_DAYS = 8
export const LAST_3_HOURS = 9
export const CUSTOM = 10
export const LAST_HOUR_THRESHOLD_IN_MINS = 59

export function getStartDate(dateType) {
  switch (dateType) {
    case LAST_HOUR:
      return Moment().minute() < LAST_HOUR_THRESHOLD_IN_MINS
        ? Moment().subtract(2, "hour").startOf("hour")
        : Moment().subtract(1, "hour").startOf("hour")
    case LAST_3_HOURS:
      return Moment().minute() < LAST_HOUR_THRESHOLD_IN_MINS
        ? Moment().subtract(4, "hour").startOf("hour")
        : Moment().subtract(3, "hour").startOf("hour")
    case TODAY:
      return Moment().startOf("day")
    case YESTERDAY:
      return Moment().subtract(1, "day").startOf("day")
    case LAST_7_DAYS:
      return Moment().subtract(7, "days").startOf("day")
    case SEVEN_DAYS:
      return Moment().subtract(6, "days").startOf("day")
    case LAST_30_DAYS:
      return Moment().subtract(30, "days").startOf("day")
    case THIS_MONTH:
      return Moment().startOf("month").startOf("day")
    case LAST_MONTH:
      return Moment().subtract(1, "month").startOf("month").startOf("day")
    case ALL_TIME:
      return Moment("1970-01-01").startOf("day")
    default:
      return Moment().minute() < LAST_HOUR_THRESHOLD_IN_MINS
        ? Moment().subtract(2, "hour").startOf("hour")
        : Moment().subtract(1, "hour").startOf("hour")
  }
}

export function getEndDate(dateType, isDraft = false) {
  switch (dateType) {
    case LAST_HOUR:
    case LAST_3_HOURS:
    case TODAY:
    case SEVEN_DAYS:
    case ALL_TIME:
      return isDraft
        ? Moment().startOf("hour").add(1, "hour")
        : Moment().minute() < LAST_HOUR_THRESHOLD_IN_MINS
        ? Moment().subtract(1, "hour").startOf("hour")
        : Moment().startOf("hour")
    case YESTERDAY:
    case LAST_30_DAYS:
    case LAST_7_DAYS:
      return Moment().subtract(1, "day").endOf("day")
    case LAST_MONTH:
      return Moment().subtract(1, "month").endOf("month").endOf("day")
    case THIS_MONTH:
      if (Moment().date() === 1) {
        return Moment().minute() < LAST_HOUR_THRESHOLD_IN_MINS
          ? Moment().subtract(1, "hour").startOf("hour")
          : Moment().startOf("hour")
      } else {
        return Moment().subtract(1, "day").endOf("day")
      }
    default:
      return Moment().minute() < LAST_HOUR_THRESHOLD_IN_MINS
        ? Moment().subtract(1, "hour").startOf("hour")
        : Moment().startOf("hour")
  }
}

export function getText(dateType) {
  switch (dateType) {
    case LAST_HOUR:
      return "Last Hour"
    case LAST_3_HOURS:
      return "Last 3 Hours"
    case TODAY:
      return "So Far Today"
    case YESTERDAY:
      return "Yesterday"
    case LAST_7_DAYS:
      return "Last 7 Days"
    case SEVEN_DAYS:
      return "7 Days"
    case LAST_30_DAYS:
      return "Last 30 Days"
    case THIS_MONTH:
      return "This Month"
    case LAST_MONTH:
      return "Last Month"
    case ALL_TIME:
      return "All Time"
    case CUSTOM:
      return "Custom"
  }
}

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props)

    if (!this.props.startDate || !this.props.endDate || !this.props.onDatesSelected) {
      console.error("DatePicker widget error: please provide all required props (startDate, endDate, onDatesSelected)")
    }

    this.state = {
      ...this.getInitialDatesState(this.props),
      popupOpen: false,
      popupAnchorEl: null,
      text: "",
    }

    this.presetDates = new Map([
      this.buildPresetDates(LAST_HOUR),
      this.buildPresetDates(LAST_3_HOURS),
      this.buildPresetDates(TODAY),
      this.buildPresetDates(YESTERDAY),
      this.buildPresetDates(LAST_7_DAYS),
      this.buildPresetDates(SEVEN_DAYS),
      this.buildPresetDates(LAST_30_DAYS),
      this.buildPresetDates(THIS_MONTH),
      this.buildPresetDates(LAST_MONTH),
      this.buildPresetDates(ALL_TIME),
      this.buildPresetDates(CUSTOM),
    ])
    this.selectedPresetText = null
  }

  buildPresetDates(dateType) {
    return [
      dateType,
      {
        text: getText(dateType),
        startDate: getStartDate(dateType),
        endDate: getEndDate(dateType, this.props.isDraft),
        selected: false,
        dateType: dateType,
      },
    ]
  }

  getInitialDatesState = (props) => {
    return {
      startDate: props.startDate,
      endDate: props.endDate,
      startDateInput: props.startDate.format(DATEPICKER_INPUT_DATE_FORMAT),
      endDateInput: props.endDate.format(DATEPICKER_INPUT_DATE_FORMAT),
      focusedInput: START_DATE,
      dateType: isNullOrUndefined(props.dateType) ? CUSTOM : props.dateType,
      text: isNullOrUndefined(props.dateType)
        ? getText(props.dateType)
        : isNullOrUndefined(props.text)
        ? getText(props.dateType)
        : props.text,
      isDraft: isNullOrUndefined(props.isDraft) ? false : props.isDraft,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
      if (!this.state.popupOpen) {
        this.setState({
          ...this.getInitialDatesState(nextProps),
        })
      }
    }

    // Setting the text label according to the dates in props (which are the actual dates that the datepicker currently
    // has, not the ones the user is still trying to choose)
    let foundDate = false
    let textToSet = "Custom"
    this.presetDates.forEach((preset, index) => {
      if (preset.selected && preset.text === this.selectedPresetText) {
        textToSet = preset.text
        foundDate = true
      }
    })

    if (!foundDate) {
      this.presetDates.forEach((preset, index) => {
        if (preset.selected) {
          textToSet = preset.text
        }
      })
    }

    this.setState({
      text: textToSet,
    })
  }

  componentWillUpdate(nextProps, nextState) {
    let foundDate = false

    this.presetDates.forEach((preset, index) => {
      if (
        preset.text !== "Custom" &&
        nextState.startDate &&
        nextState.endDate &&
        !isNullOrUndefined(preset.dateType) &&
        preset.dateType === nextState.dateType
      ) {
        preset.selected = true
        preset.startDate = getStartDate(index)
        preset.endDate = getEndDate(index, nextState.isDraft)
        foundDate = true
      } else {
        // Check if its custom date
        if (preset.text === "Custom" && foundDate === false) {
          preset.selected = true
        } else {
          preset.selected = false
        }
      }
    })
  }

  onDatesChange = (datesObj, isPreset = false) => {
    var state = this.state

    // This is part of the "resetting" logic which means that on every odd click, the selection will always be startDate
    state.startDate = datesObj.startDate.clone().startOf("day")
    state.showLastHour = false
    if (state.focusedInput === "startDate") {
      if (Moment().isSame(datesObj.endDate, "day")) {
        state.endDate = getEndDate(state.dateType, this.props.isDraft)
        state.showLastHour = true
      } else {
        state.endDate = datesObj.endDate.clone().endOf("day")
      }
    } else {
      if (Moment().isSame(datesObj.startDate, "day")) {
        state.showLastHour = true
        state.endDate = getEndDate(state.dateType, this.props.isDraft)
      } else {
        state.endDate = datesObj.startDate.clone().endOf("day")
      }
      state.dateType = CUSTOM
      this.selectedPresetText = null
    }

    state.startDateInput = state.startDate.format(DATEPICKER_INPUT_DATE_FORMAT)
    state.endDateInput = state.endDate.format(DATEPICKER_INPUT_DATE_FORMAT)

    this.setState({ state })
  }

  onFocusChange = () => {
    let state = this.state
    state.focusedInput = state.focusedInput === START_DATE ? END_DATE : START_DATE
    this.setState({ state })
  }

  generatePresetDates = () => {
    let presetButtons = []

    let selectCustomMode = () => {
      this.setState({
        focusedInput: START_DATE,
      })
    }

    let selectPresetDate = (preset) => {
      this.selectedPresetText = preset.text
      this.setState(
        {
          focusedInput: START_DATE,
        },
        () => {
          this.onDatesChange(preset, true)
          this.onDatesSelected()
        }
      )
    }

    this.presetDates.forEach((presetDate, index) => {
      if (presetDate.text === "Custom") {
        presetButtons.push(<div key={index} onClick={selectCustomMode}></div>)
      } else {
        // Disable Last Hour and Last 3 Hours only for popup screen
        if (presetDate.text === "Last Hour") {
          if (this.props.allowLastHour === false) {
            return
          }
        }
        if (presetDate.text === "Last 3 Hours") {
          if (this.props.allowLast3Hours === false) {
            return
          }
        }
        if (presetDate.text === "7 Days") {
          if (this.props.allowSevenDays === false) {
            return
          }
        }
        // Disable all time only for campaignv2 screen
        if (presetDate.text === "All Time") {
          if (this.props.allowAllTime === false) {
            return
          }
        }

        if (presetDate.text === "So Far Today") {
          if (this.props.allowToday === false) {
            return
          }
          if (this.props.allowToday === "disabled") {
            presetDate.isDisabled = true
          } else {
            presetDate.isDisabled = false
          }
        }

        presetButtons.push(
          <div
            className={
              "datepicker-preset-date" +
              (presetDate.selected ? " selected" : "") +
              (presetDate.isDisabled ? " gray" : "")
            }
            key={index}
            onClick={() => selectPresetDate(presetDate)}
          >
            {presetDate.text}
          </div>
        )
      }
    })

    return <div>{presetButtons}</div>
  }

  onDatepickerInputChange = (event, field) => {
    let value = event.target.value
    let newDate = Moment(value, DATEPICKER_INPUT_DATE_FORMAT)

    if (!newDate.isValid() || newDate > Moment() || newDate < Moment().subtract("3", "years")) {
      newDate = this.state[field]
    }

    let updatedState = {
      [field]: newDate,
      [field + "Input"]: newDate.format(DATEPICKER_INPUT_DATE_FORMAT),
    }

    if (field === "endDate" && newDate < this.state.startDate) {
      updatedState["startDate"] = newDate
      updatedState["startDateInput"] = newDate.format(DATEPICKER_INPUT_DATE_FORMAT)
    } else if (field === "startDate" && newDate > this.state.endDate) {
      updatedState["endDate"] = newDate
      updatedState["endDateInput"] = newDate.format(DATEPICKER_INPUT_DATE_FORMAT)
    }

    this.setState(updatedState)
  }

  handleInputChange = (event, field) => {
    this.setState({
      [field]: event.target.value,
    })
  }

  blurOnEnterKey = (event) => {
    if (event.key === "Enter") {
      event.target.blur()
    }
  }

  generateCalendarAddons = () => {
    return (
      <div>
        <div className={"datepicker-top-inputs d-flex align-items-center justify-content-center visible"}>
          <input
            className="start-date-input"
            value={this.state.startDateInput}
            onBlur={(event) => this.onDatepickerInputChange(event, "startDate")}
            onChange={(event) => this.handleInputChange(event, "startDateInput")}
            onKeyPress={this.blurOnEnterKey}
          />
          <input
            className="end-date-input"
            value={this.state.endDateInput}
            onBlur={(event) => this.onDatepickerInputChange(event, "endDate")}
            onChange={(event) => this.handleInputChange(event, "endDateInput")}
            onKeyPress={this.blurOnEnterKey}
          />
        </div>

        <div className="datepicker-side-buttons">{this.generatePresetDates()}</div>
      </div>
    )
  }

  onDatesSelected = () => {
    let dateType = CUSTOM
    this.presetDates.forEach((preset, index) => {
      if (preset.text === this.selectedPresetText) {
        dateType = preset.dateType
      }
    })
    var endDate = dateType === CUSTOM ? this.state.endDate : getEndDate(dateType, this.state.isDraft)
    var startDate = dateType === CUSTOM ? this.state.startDate : getStartDate(dateType)
    // Regular request to the onDatesSelected function that was passed to the datepicker
    this.props.onDatesSelected.call(this, startDate, endDate, dateType, this.state.showLastHour)
    this.setState({
      startDate: startDate,
      endDate: endDate,
      dateType: dateType,
      showLastHour: this.state.showLastHour,
      isGeneratedReport: false,
      startDateInput: this.state.startDateInput,
      endDateInput: this.state.endDateInput,
      text: getText(dateType),
    })
    this.closeDatePicker(false)
  }

  closeDatePicker = (resetDatePicker) => {
    let initialDatesState = resetDatePicker ? this.getInitialDatesState(this.props) : {}

    this.setState({
      ...initialDatesState,
      popupOpen: false,
    })
  }

  openDatePicker = (event) => {
    this.setState({
      popupOpen: true,
      focusedInput: START_DATE,
      popupAnchorEl: event.currentTarget,
    })
  }

  isDayOutsideRange = (day) => {
    let today = Moment()

    return !isInclusivelyBeforeDay(day, today)
  }

  render() {
    let applyButton = (
      <Button variant="contained" color="primary" className="round-button green" onClick={this.onDatesSelected}>
        Apply
      </Button>
    )

    return (
      <>
        <div className="datepicker d-flex clickable" onClick={this.openDatePicker}>
          <div className="current-dates font-semibold d-flex flex-column">
            <div className="date-text">
              {" "}
              {this.state.text === "All Time"
                ? "--  --"
                : Moment(this.props.startDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2) +
                  " - " +
                  Moment(this.props.endDate).format(Consts.CLIENT_FULL_DATE_FORMAT_V2)}
            </div>
            <div className="dates-title">{this.state.text}</div>
          </div>
          <KeyboardArrowDown className="down-arrow" />
        </div>

        <Popover
          open={this.state.popupOpen}
          anchorEl={this.state.popupAnchorEl}
          onClose={() => this.closeDatePicker(true)}
          classes={{ paper: "datepicker-popover" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
        >
          <>
            <div className={"datepicker-wrapper"}>
              <DayPickerRangeController
                startDate={this.state.text === "All Time" ? null : this.state.startDate}
                endDate={this.state.text === "All Time" ? null : this.state.endDate}
                onDatesChange={(dateObj) => this.onDatesChange(dateObj)}
                focusedInput={this.state.focusedInput}
                onFocusChange={this.onFocusChange}
                renderCalendarInfo={() => this.generateCalendarAddons()}
                isOutsideRange={(day) => this.isDayOutsideRange(day)}
                minimumNights={0}
                daySize={27}
                numberOfMonths={2}
                initialVisibleMonth={() => Moment().subtract(1, "month")}
                navNext={<KeyboardArrowRight />}
                navPrev={<KeyboardArrowLeft />}
                hideKeyboardShortcutsPanel
                noBorder={true}
              />
            </div>

            <div className="datepicker-confirmation-buttons">
              {applyButton}
              <Button className="flat-button" onClick={() => this.closeDatePicker(true)}>
                Cancel
              </Button>
            </div>
          </>
        </Popover>
      </>
    )
  }
}
