import React from "react"
import { Chip, MenuItem, TextField } from "@material-ui/core"
import { Close, KeyboardArrowDown } from "@material-ui/icons"
import Autosuggest from "react-autosuggest"
import { connect } from "react-redux"
import SearchIcon from "../../../resources/svgs/SearchIcon.svg"

function getSuggestionValue(suggestion) {
  return suggestion
}

function renderSuggestionsContainer(options) {
  return null
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return null
}

class LanguagesDropdown extends React.Component {
  constructor() {
    super()

    this.state = {
      search: "",
      suggestions: [],
      currentMenu: [],
      allSuggestions: [],
    }
  }

  componentWillMount() {
    this.handleSuggestionsClearRequested()
  }

  initSuggestions = () => {
    let items = []

    this.props.campaignDetailsState.languages.map((item) => {
      items.push(item.name)
    })

    return items
  }

  getSuggestions = (value) => {
    if (value !== undefined) {
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length

      if (inputLength === 0) {
        return Array.from(this.state.allSuggestions)
      }
      return this.state.allSuggestions.filter((suggestion) => {
        return suggestion.toLowerCase().includes(inputValue)
      })
    }
  }

  showSuggestionsMenu = () => {
    let items = []
    let output = []
    let recentItems = []
    let recentItemsObj = {}

    let languages = this.props.campaignDetailsState.languages
    this.state.suggestions.forEach((suggestion) => {
      let language = languages.filter((c) => c.name === suggestion)[0]

      let item = (
        <div key={language.code}>
          <MenuItem onClick={() => this.props.handleLanguageSelection(language)}>
            <input type="checkbox" checked={language.selected} value={language.code} readOnly />
            {language.name}
          </MenuItem>
        </div>
      )

      if (this.props.recentLanguages && this.props.recentLanguages.includes(language.code)) {
        recentItemsObj[language.code] = item
      } else {
        items.push(item)
      }
    })

    if (this.props.recentLanguages) {
      this.props.recentLanguages.forEach((item) => {
        if (recentItemsObj[item]) {
          recentItems.push(recentItemsObj[item])
        }
      })
    }

    if (recentItems.length > 0) {
      output.push(
        <div className="subsection-header" key="recentHeader">
          Recent
        </div>
      )
      output.push(...recentItems)
    }

    if (items.length > 0) {
      output.push(
        <div className="subsection-header" key="allHeader">
          All
        </div>
      )
    }

    output.push(...items)
    return output
  }

  renderInput = (inputProps) => {
    const { autoFocus, value, ref } = inputProps
    let icon = null

    if (this.state.search !== "") {
      icon = (
        <span className="d-flex" onClick={this.handleClearSearchFilter}>
          <Close className="clear-filter-text" />
        </span>
      )
    } else {
      icon = (
        <span className="d-flex">
          <SearchIcon className="search-icon" />
        </span>
      )
    }

    return (
      <div className="d-flex align-items-center text-field-wrapper">
        <TextField
          id="language-text-field"
          classes={{ root: "text-field" }}
          autoFocus={autoFocus}
          value={value}
          inputRef={ref}
          InputProps={{
            className: "text-field-line",
            inputProps,
          }}
        />
        {icon}
      </div>
    )
  }

  handleSearchChange = (event, newValue) => {
    this.setState({
      search: event.target.value,
    })
  }

  handleSuggestionsFetchRequested = (input) => {
    this.setState({
      suggestions: this.getSuggestions(input.value),
    })
  }

  handleSuggestionsClearRequested = () => {
    let arr = this.initSuggestions()

    this.setState(
      {
        suggestions: arr,
        allSuggestions: arr,
      },
      () => {
        if (this.state.search.length === 0) {
          this.setState({
            search: "",
          })
        }
      }
    )
  }

  handleClearSearchFilter = () => {
    this.setState(
      {
        suggestions: this.state.allSuggestions,
      },
      () => {
        this.setState({
          search: "",
        })
      }
    )
  }

  focusAutosuggest = () => {
    let textField = document.querySelector("#language-text-field")

    if (textField) {
      textField.focus()
    }
  }

  render() {
    let isLanguageSelected =
      this.props.campaignDetailsState.languages.filter((language) => language.selected).length > 0
    let selectedLanguages = this.props.campaignDetailsState.languages.filter((language) => {
      return language.selected
    })
    let isFieldDisabled = !this.props.campaignDetailsState.siteId

    return (
      <>
        <div
          className={
            "text-field languages-selection " +
            (this.props.languagesValidationErrors ? "error" : "") +
            (isFieldDisabled ? "disabled " : "")
          }
        >
          <TextField
            disabled={isFieldDisabled}
            select
            label=" "
            SelectProps={{
              multiple: true,
              value: ["Language"],
              renderValue: (val) => val,
              MenuProps: {
                transformOrigin: {
                  vertical: 0,
                  horizontal: 0,
                },
                classes: { paper: "creation-dropdown languages-dropdown" },
                onExit: this.handleClearSearchFilter,
                onEntered: this.focusAutosuggest,
              },
              classes: {
                select: "creation-select" + (!isLanguageSelected ? " unselected" : ""),
                icon: "creation-select-icon",
              },
            }}
            InputProps={{
              className: "text-field-line",
            }}
            InputLabelProps={{
              classes: { root: "text-field-color" },
            }}
            classes={{ root: "creation-select-wrapper" }}
            onChange={(event) => this.props.handleChange("selectedLanguage", event)}
            margin="normal"
          >
            {[
              <MenuItem key="auto-suggest" classes={{ root: "react-autosuggest-input-wrapper" }}>
                <div className="react-autosuggest-input">
                  <Autosuggest
                    renderInputComponent={this.renderInput}
                    suggestions={this.state.suggestions}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    alwaysRenderSuggestions
                    inputProps={{
                      autoFocus: true,
                      placeholder: "Search",
                      value: this.state.search,
                      onChange: this.handleSearchChange,
                      onKeyDown: this.handleFocusChange,
                    }}
                  />
                </div>
              </MenuItem>,
              ...this.showSuggestionsMenu(),
            ]}
          </TextField>
          <KeyboardArrowDown className="arrow" />
        </div>
        <div className="languages-chips-container chips-container">
          {selectedLanguages.map((language) => {
            return (
              <Chip
                classes={{ root: "chip-item" }}
                label={language.name}
                key={language.code}
                deleteIcon={<Close className="delete-icon" />}
                onDelete={(event) => this.props.handleLanguageSelection(language)}
              />
            )
          })}
        </div>
        {this.props.selectedLanguages.length === 0 && (
          <div>Notice that &quot;Language&quot; filter won’t work if selection is left empty</div>
        )}
        {this.props.languagesValidationErrors}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isLoadingProviderAccounts: state.campaignCreationWizard.isLoadingProviderAccounts,
  }
}

export default connect(mapStateToProps)(LanguagesDropdown)
