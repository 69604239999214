import React from "react"
import { connect } from "react-redux"

class CampaignPopupEdit extends React.Component {
  render() {
    return <div className="campaign-popup-edit">Editing will be here</div>
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupEdit)
