import React from "react"
import { connect } from "react-redux"
import { Button } from "@material-ui/core"
import ReactTooltip from "react-tooltip"
import CampaignConsts from "../../campaignsConsts"
import NumberFormat from "../../../common/components/numberFormat"
import { hasRoasEnabled, isRoasAllowed } from "../../../utils/campaignUtilsV2"
import { campaignBidLoading, changeRoasAsync } from "../../campaignActions"
import Consts from "../../../app/consts"

class CampaignRoas extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      localTargetRoi: 0,
    }
  }

  focusTargetRoiInput = () => {
    let input = document.querySelector(".roas-bid-box input")

    if (input) {
      input.focus()

      // A workaround to set the text cursor at the last character position
      input.value = input.value + " "
      setTimeout(() => {
        input.value = input.value.trim()
      }, 0)
    }
  }

  getCampaignRoas = () => {
    if (this.props.campaign.roas) return this.props.campaign.roas
    else return null
  }

  componentDidMount() {
    this.focusTargetRoiInput()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.localRoasStatus !== this.props.localRoasStatus && nextProps.localRoasStatus) {
      this.focusTargetRoiInput()
    }
  }

  componentWillMount() {
    let campaign_roas = this.getCampaignRoas()

    this.setState({
      localTargetRoi: campaign_roas ? campaign_roas : CampaignConsts.CAMPAIGN_DEFAULT_CONSTANT_ROAS_ROI,
    })
  }

  handleTargetRoiChange = (values) => {
    const { formattedValue, value, floatValue } = values

    if (floatValue.toString() === value) {
      this.setState({ localTargetRoi: floatValue })
    }
  }

  setTargetRoi = (event) => {
    event.preventDefault()
    this.props.dispatch(campaignBidLoading(this.props.campaign))

    if (this.props.localRoasStatus) {
      this.props.dispatch(changeRoasAsync(this.props.campaign, this.getCampaignRoas(), this.state.localTargetRoi))
    }

    this.props.closePopup()
  }

  saveRoasForm = (event) => {
    this.setTargetRoi(event)
  }

  stepperPlus = (callback) => {
    this.setState(
      {
        localTargetRoi: parseInt((this.state.localTargetRoi + Consts.STEPPER_STEP_TARGET_ROI).toFixed(0)),
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  stepperMinus = (callback) => {
    this.setState(
      {
        localTargetRoi: parseInt((this.state.localTargetRoi - Consts.STEPPER_STEP_TARGET_ROI).toFixed(0)),
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  handleKeyPress = (event) => {
    if (!event.key) {
      return
    }

    switch (event.key) {
      case "ArrowUp":
        this.stepperPlus()
        break
      case "ArrowDown":
        this.stepperMinus()
        break
      default:
        return
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  render() {
    let disabled = false
    let roasAllowed = isRoasAllowed(this.props.campaign)

    if (!roasAllowed || !this.props.isChangeBidAllowed) {
      return null
    }

    let roasEnabled = hasRoasEnabled(this.props.campaign)
    let isRoasBoxOpen = roasEnabled || this.props.localRoasStatus

    if (this.props.localRoasStatus) {
      disabled = true
    }

    return (
      <>
        <form
          className={"roas-bid-box " + (isRoasBoxOpen ? "open" : "")}
          onSubmit={
            !disabled
              ? (event) => this.saveRoasForm(event)
              : (event) => {
                  event.preventDefault()
                }
          }
        >
          <div className="roas-bid-box-title d-flex align-items-center">
            <div className="text">ROAS</div>
          </div>
          <div className="roas-roi-box d-flex align-items-center">
            <div className="text">Target ROI</div>
            <NumberFormat
              value={this.state.localTargetRoi}
              displayType="input"
              suffix="%"
              onKeyDown={this.handleKeyPress}
              onValueChange={this.handleTargetRoiChange}
              className="textbox"
              disabled={!this.props.localRoasStatus}
            />
          </div>
          <div className="d-flex save-button-wrapper">
            <Button disabled={disabled} className="round-button green" onClick={(event) => this.saveRoasForm(event)}>
              Save
            </Button>
          </div>
        </form>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sites: state.app.sites,
  }
}

export default connect(mapStateToProps)(CampaignRoas)
