import React from "react"
import Creative from "./creative"

export default class CreativesList extends React.Component {
  displayCreatives = () => {
    if (!this.props.creatives) {
      return
    }

    return this.props.creatives.map((creative, index) => {
      return (
        <Creative
          creative={creative}
          key={creative.id}
          index={index + 1}
          validationErrorsVisible={this.props.validationErrorsVisible}
          creativeUpdateFunc={this.props.onCreativeUpdated}
          creativeRemoveFunc={this.props.onCreativeRemoved}
          creativeCloneFunc={this.props.onCreativeCloned}
          thumbnailUpdateFunc={this.props.onThumbnailUpdated}
          thumbnailRemoveFunc={this.props.onThumbnailRemoved}
          updateThumbnailWithUrl={this.props.updateThumbnailWithUrl}
          updateThumbnailLoadingStatus={this.props.updateThumbnailLoadingStatus}
          allowThumbnailCrop={this.props.allowThumbnailCrop}
          creatives={this.props.creatives}
          showDescription={this.props.showDescription}
          campaignSources={this.props.campaignSources}
          groupIndex={this.props.groupIndex}
        />
      )
    })
  }

  render() {
    return this.displayCreatives()
  }
}
