Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) {
    return 0
  }

  return this.toString().split(".")[1].length || 0
}

export function range(start, end) {
  if (start === end) {
    return [start]
  }

  return [start, ...range(start + 1, end)]
}
