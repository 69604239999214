import CampaignActionTypes from "./campaignActionTypes"
import CampaignsConsts from "./campaignsConsts"

export function pauseCampaignResponse(action, updateCampaignFunction) {
  return updateCampaignFunction(action.campaign.key, {
    isCampaignStatusLoading: action.isCampaignStatusLoading,
    status: action.status,
    last_modified: action.lastModified,
    last_modified_action: action.lastModifiedAction,
  })
}

export function activeCampaignResponse(action, updateCampaignFunction) {
  return updateCampaignFunction(action.campaign.key, {
    isCampaignStatusLoading: action.isCampaignStatusLoading,
    status: action.status,
    last_modified: action.lastModified,
    last_modified_action: action.lastModifiedAction,
    activation_date: action.activationDate,
  })
}

export function stopCampaignErrorResponse(action, updateCampaignFunction) {
  return updateCampaignFunction(action.campaign.key, {
    isCampaignStatusLoading: false,
    status: CampaignsConsts.CAMPAIGN_STATUS_RUNNING,
  })
}

export function startCampaignErrorResponse(action, updateCampaignFunction) {
  return updateCampaignFunction(action.campaign.key, {
    isCampaignStatusLoading: false,
    status: CampaignsConsts.CAMPAIGN_STATUS_PAUSED,
  })
}

export function bulkStartStopCampaignsResponse(action, updateCampaignsFunction) {
  var campaignKeysToUpdatedProps = new Map()

  if (action.summary) {
    var summaryKeys = Object.keys(action.summary)

    action.campaigns.forEach((campaign) => {
      if (summaryKeys.includes(campaign.key)) {
        campaignKeysToUpdatedProps.set(campaign.key, {
          isCampaignStatusLoading: false,
          status: action.summary[campaign.key].status,
          last_modified: action.summary[campaign.key].last_modified,
          last_modified_action:
            typeof action.summary[campaign.key].last_modified_action === "string"
              ? JSON.parse(action.summary[campaign.key].last_modified_action)
              : null,
        })
      }
    })
  } else {
    action.campaigns.forEach((campaign) => {
      campaignKeysToUpdatedProps.set(campaign.key, {
        isCampaignStatusLoading: false,
      })
    })
  }

  return updateCampaignsFunction(campaignKeysToUpdatedProps)
}

export function bulkUpdateCampaignsTags(action, updateCampaignsFunction, stateCampaignTags) {
  var campaignKeyToUpdatedProps = new Map()
  var campaignTags = new Set(
    stateCampaignTags.map((tag) => {
      return tag.name
    })
  )

  action.campaigns.forEach((campaign) => {
    campaignKeyToUpdatedProps.set(campaign.key, {
      tags: campaign.tags.map((tag) => {
        return { name: tag }
      }),
    })
  })

  action.campaigns.forEach((campaign) => {
    campaign.tags.forEach((tag) => {
      campaignTags.add(tag)
    })
  })

  return {
    updatedCampaigns: updateCampaignsFunction(campaignKeyToUpdatedProps),
    campaignTags,
  }
}

export function bulkEditCampaignsBudgetResponse(action, updateCampaignsFunction) {
  var campaignKeyToUpdatedProps = new Map()

  if (action.summary) {
    var summaryKeys = Object.keys(action.summary)

    action.campaigns.forEach((campaign) => {
      if (summaryKeys.includes(campaign.key)) {
        if (action.summary[campaign.key].operation === "daily budget") {
          campaignKeyToUpdatedProps.set(campaign.key, {
            isCampaignDailyBudgetLoading: false,
            daily_budget: action.summary[campaign.key].value,
            last_modified: action.summary[campaign.key].last_modified,
            last_modified_action:
              typeof action.summary[campaign.key].last_modified_action === "string"
                ? JSON.parse(action.summary[campaign.key].last_modified_action)
                : null,
          })
        } else if (action.summary[campaign.key].operation === "total budget") {
          campaignKeyToUpdatedProps.set(campaign.key, {
            isCampaignTotalBudgetLoading: false,
            total_budget: action.summary[campaign.key].value,
            last_modified: action.summary[campaign.key].last_modified,
            last_modified_action:
              typeof action.summary[campaign.key].last_modified_action === "string"
                ? JSON.parse(action.summary[campaign.key].last_modified_action)
                : null,
          })
        }
      } else {
        action.campaigns.forEach((campaign) => {
          if (action.summary[campaign.key]) {
            if (action.summary[campaign.key].operation === "daily budget") {
              campaignKeyToUpdatedProps.set(campaign.key, { isCampaignDailyBudgetLoading: false })
            } else if (action.summary[campaign.key].operation === "total budget") {
              campaignKeyToUpdatedProps.set(campaign.key, { isCampaignTotalBudgetLoading: false })
            }
          }
        })
      }
    })
  } else {
    action.campaigns.forEach((campaign) => {
      if (action.summary[campaign.key]) {
        if (action.summary[campaign.key].operation === "daily budget") {
          campaignKeyToUpdatedProps.set(campaign.key, { isCampaignDailyBudgetLoading: false })
        } else if (action.summary[campaign.key].operation === "total budget") {
          campaignKeyToUpdatedProps.set(campaign.key, { isCampaignTotalBudgetLoading: false })
        }
      }
    })
  }

  return updateCampaignsFunction(campaignKeyToUpdatedProps)
}

export function bulkEditCampaignsBidResponse(action, updateCampaignsFunction) {
  var campaignKeyToUpdatedProps = new Map()

  if (action.summary) {
    var summaryKeys = Object.keys(action.summary)

    action.campaigns.forEach((campaign) => {
      if (summaryKeys.includes(campaign.key)) {
        if (action.summary[campaign.key].success) {
          campaignKeyToUpdatedProps.set(campaign.key, {
            isCampaignBidLoading: false,
            bid: action.summary[campaign.key].bid,
            last_modified: action.summary[campaign.key].last_modified,
            last_modified_action:
              typeof action.summary[campaign.key].last_modified_action === "string"
                ? JSON.parse(action.summary[campaign.key].last_modified_action)
                : null,
          })
        } else {
          campaignKeyToUpdatedProps.set(campaign.key, { isCampaignBidLoading: false })
        }
      }
    })
  } else {
    action.campaigns.forEach((campaign) => {
      campaignKeyToUpdatedProps.set(campaign.key, { isCampaignBidLoading: false })
    })
  }

  return updateCampaignsFunction(campaignKeyToUpdatedProps)
}

export function bulkEditCampaignsTargetingResponse(action, updateCampaignsFunction) {
  var campaignKeyToUpdatedProps = new Map()
  action.campaigns.forEach((campaign) => {
    campaignKeyToUpdatedProps.set(campaign.key, {
      last_modified: campaign.lastModified,
      last_modified_action: campaign.lastModifiedAction,
    })
  })

  return updateCampaignsFunction(campaignKeyToUpdatedProps)
}

export function updateCampaignTags(action, updateCampaignFunction, stateCampaignTags) {
  let campaignTags = new Set([
    ...stateCampaignTags.map((tag) => {
      return tag.name
    }),
    ...action.campaignTags.map((tag) => {
      return tag.name
    }),
  ])

  var updatedCampaignsLists = updateCampaignFunction(action.campaign.key, {
    tags: action.campaignTags,
  })

  return {
    updatedCampaigns: updatedCampaignsLists,
    campaignTags,
  }
}

export function changeBidResponse(action, updateCampaignFunction) {
  let comparedTo = {}

  if (action.type === CampaignActionTypes.CHANGE_BID_ERROR_RESPONSE) {
    comparedTo = {
      isCampaignBidLoading: action.isCampaignBidLoading,
    }
  } else {
    comparedTo = {
      isCampaignBidLoading: action.isCampaignBidLoading,
      bid: action.bid,
      last_modified: action.lastModified,
      last_modified_action: action.lastModifiedAction,
    }
  }

  return updateCampaignFunction(action.campaign.key, {
    isCampaignBidLoading: action.isCampaignBidLoading,
    bid: action.bid,
    comparedTo,
    last_modified: action.lastModified,
    last_modified_action: action.lastModifiedAction,
  })
}

export function changeRoasResponse(action, updateCampaignFunction) {
  return updateCampaignFunction(action.campaign.key, {
    isCampaignBidLoading: action.isCampaignBidLoading,
    roas: action.roas,
    last_modified: action.lastModified,
    last_modified_action: action.lastModifiedAction,
  })
}

export function campaignStatusLoading(action, updateCampaignFunction) {
  return updateCampaignFunction(action.campaign.key, {
    isCampaignStatusLoading: action.isCampaignStatusLoading,
  })
}

export function bulkCampaignStatusLoading(action, updateCampaignsFunction) {
  var campaignKeyToUpdatedProps = new Map()

  action.campaigns.forEach((campaign) => {
    campaignKeyToUpdatedProps.set(campaign.key, { isCampaignStatusLoading: action.isCampaignStatusLoading })
  })

  return updateCampaignsFunction(campaignKeyToUpdatedProps)
}

export function campaignBidLoading(action, updateCampaignFunction) {
  return updateCampaignFunction(action.campaign.key, {
    isCampaignBidLoading: action.isCampaignBidLoading,
    comparedTo: { isCampaignBidLoading: action.isCampaignBidLoading },
  })
}

export function campaignDailyBudgetLoading(action, updateCampaignFunction) {
  return updateCampaignFunction(action.campaign.key, {
    isCampaignDailyBudgetLoading: action.isCampaignDailyBudgetLoading,
    comparedTo: {
      isCampaignDailyBudgetLoading: action.isCampaignDailyBudgetLoading,
    },
  })
}

export function campaignTotalBudgetLoading(action, updateCampaignFunction) {
  return updateCampaignFunction(action.campaign.key, {
    isCampaignTotalBudgetLoading: action.isCampaignTotalBudgetLoading,
    comparedTo: {
      isCampaignTotalBudgetLoading: action.isCampaignTotalBudgetLoading,
    },
  })
}

export function bulkCampaignsDailyBudgetLoading(action, updateCampaignsFunction) {
  var campaignKeyToUpdatedProps = new Map()

  action.campaigns.forEach((campaign) => {
    campaignKeyToUpdatedProps.set(campaign.key, { isCampaignDailyBudgetLoading: action.isCampaignDailyBudgetLoading })
  })

  return updateCampaignsFunction(campaignKeyToUpdatedProps)
}

export function bulkCampaignsBidLoading(action, updateCampaignsFunction) {
  var campaignKeyToUpdatedProps = new Map()

  action.campaigns.forEach((campaign) => {
    campaignKeyToUpdatedProps.set(campaign.key, { isCampaignBidLoading: action.isCampaignBidLoading })
  })

  return updateCampaignsFunction(campaignKeyToUpdatedProps)
}

export function bulkCampaignsTotalBudgetLoading(action, updateCampaignsFunction) {
  var campaignKeyToUpdatedProps = new Map()

  action.campaigns.forEach((campaign) => {
    campaignKeyToUpdatedProps.set(campaign.key, { isCampaignTotalBudgetLoading: action.isCampaignTotalBudgetLoading })
  })

  return updateCampaignsFunction(campaignKeyToUpdatedProps)
}

export function bulkCampaignsActivationLoading(action, updateCampaignsFunction) {
  let campaignKeyToUpdatedProps = new Map()

  action.campaigns.forEach((campaign) => {
    campaignKeyToUpdatedProps.set(campaign.key, {
      isCampaignBidLoading: campaign.activation_data.bid ? action.isLoading : false,
      isCampaignDailyBudgetLoading: campaign.activation_data.daily_budget ? action.isLoading : false,
      isCampaignStatusLoading: campaign.activation_data.status ? action.isLoading : false,
    })
  })

  return updateCampaignsFunction(campaignKeyToUpdatedProps)
}

export function bulkCampaignActivationResponse(action, updateCampaignsFunction) {
  var campaignKeyToUpdatedProps = new Map()

  if (action.summary) {
    let summaryKeys = Object.keys(action.summary)

    action.campaigns.forEach((campaign) => {
      if (summaryKeys.includes(campaign.key)) {
        let campaignSummary = action.summary[campaign.key]

        campaignKeyToUpdatedProps.set(campaign.key, {
          isCampaignBidLoading: false,
          isCampaignDailyBudgetLoading: false,
          isCampaignStatusLoading: false,

          last_modified: campaignSummary["last_modified"] ? campaignSummary["last_modified"] : campaign.last_modified,
          last_modified_action: campaignSummary["last_modified_action"]
            ? JSON.parse(campaignSummary["last_modified_action"])
            : campaign.additional_info.last_modified_action,
          bid: campaignSummary["bid_change_success"] ? campaign.activation_data.bid : campaign.bid,
          daily_budget: campaignSummary["daily_budget_change_success"]
            ? campaign.activation_data.daily_budget
            : campaign.daily_budget,
          status: campaignSummary["status_change_success"] ? CampaignsConsts.CAMPAIGN_STATUS_RUNNING : campaign.status,
        })
      }
    })
  } else {
    action.campaigns.forEach((campaign) => {
      campaignKeyToUpdatedProps.set(campaign.key, {
        isCampaignBidLoading: false,
        isCampaignDailyBudgetLoading: false,
        isCampaignStatusLoading: false,
      })
    })
  }

  return updateCampaignsFunction(campaignKeyToUpdatedProps)
}

export function changeCampaignDailyBudgetResponse(action, updateCampaignFunction) {
  var updatedCampaignProps = {
    isCampaignDailyBudgetLoading: action.isCampaignDailyBudgetLoading,
    daily_budget: action.daily_budget,
    last_modified: action.lastModified,
    last_modified_action: action.lastModifiedAction,
  }

  return updateCampaignFunction(action.campaign.key, {
    ...updatedCampaignProps,
    comparedTo: updatedCampaignProps,
  })
}

export function changeCampaignTotalBudgetResponse(action, updateCampaignFunction) {
  var updatedCampaignProps = {
    isCampaignTotalBudgetLoading: action.isCampaignTotalBudgetLoading,
    total_budget: action.total_budget,
    last_modified: action.lastModified,
    last_modified_action: action.lastModifiedAction,
  }

  return updateCampaignFunction(action.campaign.key, {
    ...updatedCampaignProps,
    comparedTo: updatedCampaignProps,
  })
}
