import React from "react"
import Close from "@material-ui/icons/Close"
import reactStringReplace from "react-string-replace"
import AutosizeInput from "react-input-autosize"
import Moment from "moment"
import FilterTypes from "../consts/filterTypesV2"
import Consts from "../../app/consts"
import Filter from "./filterV2/filter"
import Condition from "../../automationCreation/components/condition"
import { deserializeDateFilterPreset, isNullOrUndefined } from "../../utils/funcUtils"

class EditableChip extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filterOperandValue: "",
      filterSecondaryOperandValue: "",
    }
  }

  componentWillMount() {
    if (FilterTypes.RANGE_FILTER_OPERATORS.includes(this.props.filter.filterOperator)) {
      if (this.props.filter.filterValueType === FilterTypes.filterValueTypes.DATE) {
        let values = deserializeDateFilterPreset(this.props.filter.filterValue[0], this.props.filter.filterValue[1])

        this.setState({
          filterOperandValue: Moment(values.firstValue),
          filterSecondaryOperandValue: Moment(values.secondValue),
        })
      } else {
        this.setState({
          filterOperandValue: this.props.filter.filterValue[0],
          filterSecondaryOperandValue: this.props.filter.filterValue[1],
        })
      }
    } else {
      if (this.props.filter.filterValueType === FilterTypes.filterValueTypes.DATE) {
        let values = deserializeDateFilterPreset(this.props.filter.filterValue[0])
        this.setState({
          filterOperandValue: Moment(values.firstValue),
        })
      } else {
        this.setState({
          filterOperandValue: this.props.filter.filterValue[0],
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.filter.filterOperator !== nextProps.filter.filterOperator ||
      this.props.filter.filterValue !== nextProps.filter.filterValue
    ) {
      if (FilterTypes.RANGE_FILTER_OPERATORS.includes(nextProps.filter.filterOperator)) {
        this.setState({
          filterOperandValue: nextProps.filter.filterValue[0],
          filterSecondaryOperandValue: nextProps.filter.filterValue[1],
        })
      } else {
        this.setState({
          filterOperandValue: nextProps.filter.filterValue[0],
        })
      }
    }
  }

  primaryValueOnBlur = (event) => {
    let regexValidator = this.getRegex()

    if (
      regexValidator.test(this.state.filterOperandValue) &&
      this.state.filterOperandValue !== "" &&
      !isNaN(this.state.filterOperandValue)
    ) {
      if (FilterTypes.RANGE_FILTER_OPERATORS.includes(this.props.filter.filterOperator)) {
        this.props.onFilterValueChanged(
          event,
          Object.assign({}, this.props.filter, {
            filterValue: [
              Math.min(this.state.filterOperandValue, this.state.filterSecondaryOperandValue),
              Math.max(this.state.filterOperandValue, this.state.filterSecondaryOperandValue),
            ],
          }),
          true
        )
      } else {
        this.props.onFilterValueChanged(
          event,
          Object.assign({}, this.props.filter, { filterValue: [this.state.filterOperandValue] }),
          true
        )
      }
    } else {
      if (FilterTypes.RANGE_FILTER_OPERATORS.includes(this.props.filter.filterOperator)) {
        this.setState({ filterOperandValue: this.props.filter.filterValue[0] })
      } else {
        this.setState({ filterOperandValue: this.props.filter.filterValue[0] })
      }
    }
  }

  secondaryValueOnBlur = (event) => {
    let regexValidator = this.getRegex()

    if (
      regexValidator.test(this.state.filterSecondaryOperandValue) &&
      this.state.filterSecondaryOperandValue !== "" &&
      !isNaN(this.state.filterSecondaryOperandValue)
    ) {
      this.props.onFilterValueChanged(
        event,
        Object.assign({}, this.props.filter, {
          filterValue: [
            Math.min(this.state.filterOperandValue, this.state.filterSecondaryOperandValue),
            Math.max(this.state.filterOperandValue, this.state.filterSecondaryOperandValue),
          ],
        }),
        true
      )
    } else {
      this.setState({ filterSecondaryOperandValue: this.props.filter.filterValue[1] })
    }
  }

  onKeyPress = (event) => {
    if (event.key === "Enter") {
      event.target.blur()
    }
  }

  getRegex = () => {
    let regexValidator = Consts.WHOLE_NUMBER_REGEX

    if (this.props.filter.filterPlacesAfterTheDot !== undefined) {
      regexValidator = RegExp(
        Consts.REAL_NUMBER_REGEX_WITH_PLACES.replace("[PLACES]", this.props.filter.filterPlacesAfterTheDot)
      )
    }

    return regexValidator
  }

  render() {
    let isShowingCount = false
    let counter = null
    const isAccountId = this.props.filter.filterName === FilterTypes.ACCOUNT_ID
    const isConditionChip = this.props.useCondition === true

    const filterDisplayName = isConditionChip
      ? Condition.getDisplayName(this.props.filter)
      : Filter.getDisplayName(this.props.filter)
    // replace the "PrimaryValue" placeholder with an editable input field
    let template = reactStringReplace(filterDisplayName, "[PrimaryValue]", (match, i) => {
      let regexValidator = this.getRegex()

      let inputProps = {}

      if (
        isAccountId
          ? this.state.filterOperandValue === ""
          : !(
              regexValidator.test(this.state.filterOperandValue) &&
              this.state.filterOperandValue !== "" &&
              !isNaN(this.state.filterOperandValue)
            )
      ) {
        inputProps["data-balloon-visible"] = true
        inputProps["data-balloon"] = "Invalid value"
        inputProps["data-balloon-pos"] = "up"
      }

      return (
        <div className="error-balloon" {...inputProps} key="PrimaryValue">
          <AutosizeInput
            disabled={!this.props.onFilterValueChanged}
            className={"editable-field" + (this.props.onFilterValueChanged ? " active" : " not-active")}
            value={this.state.filterOperandValue}
            onChange={(event) => {
              this.setState({ filterOperandValue: event.target.value })
            }}
            onBlur={this.primaryValueOnBlur}
            onKeyPress={this.onKeyPress}
          />
        </div>
      )
    })

    //replace the "SecondaryValue" placeholder with an editable input field
    template = reactStringReplace(template, "[SecondaryValue]", (match, i) => {
      let regexValidator = this.getRegex()

      let inputProps = {}

      if (
        isAccountId
          ? this.state.filterOperandValue === ""
          : !(
              regexValidator.test(this.state.filterSecondaryOperandValue) &&
              this.state.filterSecondaryOperandValue !== "" &&
              !isNaN(this.state.filterSecondaryOperandValue)
            )
      ) {
        inputProps["data-balloon-visible"] = true
        inputProps["data-balloon"] = "Invalid value"
        inputProps["data-balloon-pos"] = "up"
      }

      return (
        <div className="error-balloon" {...inputProps} key="SecondaryValue">
          <AutosizeInput
            disabled={!this.props.onFilterValueChanged}
            className={"editable-field" + (this.props.onFilterValueChanged ? " active" : " not-active")}
            value={this.state.filterSecondaryOperandValue}
            onChange={(event) => {
              this.setState({ filterSecondaryOperandValue: event.target.value })
            }}
            onBlur={this.secondaryValueOnBlur}
            onKeyPress={this.onKeyPress}
          />
        </div>
      )
    })

    //replace the "Operator" placeholder with an editable field
    template = reactStringReplace(template, "[operator]", (match, i) => {
      return (
        <div
          className={this.props.onFilterChanged ? "editable-field active" : "operator-not-active"}
          key="operator"
          onClick={(event) => {
            if (this.props.onFilterChanged) {
              this.props.onFilterChanged(event, this.props.filter)
            }
          }}
        >
          {FilterTypes.filterOperatorsNamesByType[this.props.filter.filterValueType][this.props.filter.filterOperator]}
        </div>
      )
    })

    if (!isNullOrUndefined(this.props.isLoading) && this.props.isLoading === false) {
      isShowingCount = true
      counter = <span className="filter-counter">{this.props.filter.count}</span>
    }

    return (
      <div
        className={
          "editable-chip-item " +
          (this.props.permanentFilter === true ? "permanent-filter " : "") +
          (this.props.filter.count === 0 && isShowingCount ? "no-results" : "") +
          (this.props.classes ? this.props.classes : "")
        }
      >
        <span
          className={
            "editable-field-container " +
            (this.props.onDelete ? null : "no-delete ") +
            (this.props.containerClasses ? this.props.containerClasses : "")
          }
        >
          {template}
          {counter}
        </span>
        {this.props.onDelete ? <Close onClick={this.props.onDelete} className="delete-icon" /> : null}
      </div>
    )
  }
}

EditableChip.defaultProps = {
  isLoading: null,
  onDelete: null,
  onFilterValueChanged: null,
  onFilterChanged: null,
  permanentFilter: null,
  classes: "",
  containerClasses: "",
}

export default EditableChip
